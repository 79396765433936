// Called from panel.vue to represent each paragraph // note - commenting out
the metadata and obligations.

<script>
// state
import { panelComputed } from '@state/helpers'

// other components - for labelling clauses and presenting the text.
import ParaClauseLabel from './para-clauselabel.vue'
import ParaClauseText from './para-clausetext.vue'
import ParaClauseIndicator from './para-clauseindicator.vue'

// import * as ComponentService from '@services/ComponentService'

export default {
  components: {
    ParaClauseLabel,
    ParaClauseText,
    ParaClauseIndicator,
  },

  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    authlevel: {
      type: String,
      default: () => 'A',
    },
    ind: {
      type: Number,
      default: () => 1,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      required: true,
    },
    compressedrec: {
      type: Boolean,
      required: true,
    },
    englishtranslaterec: {
      type: Boolean,
      required: true,
    },
    englishavail: {
      type: Boolean,
      required: true,
    },
    doctranslation: {
      type: Object,
      default: () => {},
    },
    originaltext: {
      type: Object,
      default: () => {},
    },
    showcollaborativeedits: {
      type: Boolean,
      required: true,
    },
    shownegotiationedits: {
      type: Boolean,
      required: true,
    },
    lsearchon: {
      type: Boolean,
      required: true,
    },
    lsearchcapson: {
      type: Boolean,
      required: true,
    },
    lsearchtext: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      // showClauseIndicator: true, // show the Clause Indicators (should be excluded if it's A)
      lcompressed: this.compressedrec, /// this is the local preference to determine if it's compressed.
      lenglishtranslate: this.englishtranslaterec,

      NOTEDITABLEPARATYPES: [
        '<< EMPTYTEXT >>',
        '<< TABLE >>',
        '<< DRAWIING >>',
        '<< PICTURE >>',
        '<< TABLEROW >>',
        '<< EMPTY >>',
        '<< TAB >>',
      ],
      NOTVIEWABLEPARATYPES: [
        '<< EMPTYTEXT >>',
        '<< TABLEROW >>',
        '<< EMPTY >>',
        '<< TAB >>',
      ],
    }
  },

  computed: {
    ...panelComputed,

    label() {
      return this.docRawData[this.panelno].clauseId[this.ind].clauseCategory
    },

    sections() {
      return this.root ? this.root.subcomponents : []
    },
    nonViewableParaType() {
      return !this.NOTVIEWABLEPARATYPES.includes(this.text)
    },
    nonEditableParaType() {
      return !this.NOTEDITABLEPARATYPES.includes(this.text)
    },
    showClauseIndicator() {
      // Add the possibility that it's an ignored type
      return this.authlevel >= 'B'
      // return this.authlevel >= 'B'
    },
    compressed() {
      return this.lcompressed
    },
    englishtranslate() {
      return this.lenglishtranslate
    },

    // input to clausetext
    docedits() {
      if (
        Array.isArray(this.editDocedits[this.panelno]) &&
        this.editDocedits[this.panelno].length > 0
      ) {
        return this.editDocedits[this.panelno]
      } else {
        return []
      }
    },
    text() {
      if (
        this.originaltext === null ||
        this.originaltext === undefined ||
        this.originaltext[this.ind] === undefined
      ) {
        return ''
      }
      return this.originaltext[this.ind].OT
    },
  },

  watch: {
    compressedrec(newValue, oldValue) {
      this.lcompressed = this.compressedrec
    },
    englishtranslaterec(newValue, oldValue) {
      this.lenglishtranslate = this.englishtranslaterec
    },
  },
  methods: {
    setLocalParaContext(data) {
      this.$emit('setLocalParaContext', data)
    },
    showCollabCommentsPanel(data) {
      this.$emit('showCollabCommentsPanel', data)
    },
    showCollabDocEditsPanel(data) {
      this.$emit('showCollabDocEditsPanel', data)
    },
    showNegotCommentsPanel(data) {
      this.$emit('showNegotCommentsPanel', data)
    },
    showNegotDocEditsPanel(data) {
      this.$emit('showNegotDocEditsPanel', data)
    },
    showTagsPanel(data) {
      this.$emit('showTagsPanel', data)
    },
    processnavall() {
      this.$emit('processnavall', this.ind)
    },
    changelcompressed() {
      this.lcompressed = !this.lcompressed
    },
    changelenglishtranslate() {
      this.lenglishtranslate = !this.lenglishtranslate
      if (
        this.lenglishtranslate &&
        Object.keys(this.doctranslation).length === 0
      ) {
        this.$emit('gettranslation')
      }
    },
  },
}
</script>

<template>
  <div :class="$style.paragraph" class="d-flex">
    <v-container v-if="nonViewableParaType" class="container">
      <v-layout row class="mb-n4 mt-n2">
        <v-flex xs1 md1 lg1 xl1>
          <ParaClauseLabel
            v-if="nonEditableParaType"
            :panelno="panelno"
            :ind="ind"
            :class="$style.clauseLabel"
            :localpanelstate="localpanelstate"
            :editable="editable"
            :compressed="compressed"
            :englishavail="englishavail"
            :englishtranslate="englishtranslate"
            :originaltext="originaltext"
            @processnavall="processnavall"
            @changelcompressed="changelcompressed"
            @changelenglishtranslate="changelenglishtranslate"
          />
        </v-flex>
        <v-flex xs10 md10 lg10 xl10>
          <ParaClauseText
            :panelno="panelno"
            :ind="ind"
            :class="$style.clauseText"
            :compressed="compressed"
            :englishtranslate="englishtranslate"
            :doctranslation="doctranslation"
            :originaltext="originaltext"
            :docedits="docedits"
            :lsearchon="lsearchon"
            :lsearchcapson="lsearchcapson"
            :lsearchtext="lsearchtext"
            :showcollaborativeedits="showcollaborativeedits"
            :shownegotiationedits="shownegotiationedits"
            @setLocalParaContext="setLocalParaContext"
          />
        </v-flex>
        <v-flex xs1 md1 lg1 xl1>
          <ParaClauseIndicator
            v-if="showClauseIndicator && nonEditableParaType"
            :panelno="panelno"
            :authlevel="authlevel"
            :ind="ind"
            :class="$style.clauseIndicator"
            :compressed="compressed"
            :originaltext="originaltext"
            :localpanelstate="localpanelstate"
            @showCollabCommentsPanel="showCollabCommentsPanel"
            @showCollabDocEditsPanel="showCollabDocEditsPanel"
            @showNegotCommentsPanel="showNegotCommentsPanel"
            @showNegotDocEditsPanel="showNegotDocEditsPanel"
            @showTagsPanel="showTagsPanel"
            @changelcompressed="changelcompressed"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
