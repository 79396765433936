<script>
import { docroute, comparePara } from '@utils/dialogutils'

// state
import { panelComputed, dbauthComputed } from '@state/helpers'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    eligibledocxin: {
      type: Boolean,
      required: true,
    },
    doctranslation: {
      type: Object,
      default: () => {},
    },
    originaltext: {
      type: Object,
      default: () => {},
    },

    // FYI - Need to pass each field in individually - VuejS objects to mutability of objects and arrays.
    docname: {
      type: String,
      required: true,
    },
    docdescription: {
      type: String,
      required: true,
    },
    docparty: {
      type: String,
      required: true,
    },
    doccounterparty: {
      type: String,
      required: true,
    },
    docallowcollclauselabels: {
      type: Boolean,
      required: true,
    },
    docenableNegotiation: {
      type: Boolean,
      required: true,
    },
    labelinputdata: {
      type: Array,
      default: () => [],
    },
  },

  data: function() {
    return {
      // this never works to pass defaults to local variables/
      // previously, this was just saved in the form, it didn't necessarily retrieve it.
      // when this instantiated, it needs to populated with the right defaults for the panel.
      // save operation works but not the local copy to a default variable.

      // this does not seem to work at all to set the initial defaults.  instead, use setInitialValues()
      ldocname: this.docname,
      ldocdescription: this.docdescription,
      ldocparty: this.docparty,
      ldoccounterparty: this.doccounterparty,
      ldocallowcollclauselabels: this.docallowcollclauselabels,
      ldocenableNegotiation: this.docenableNegotiation,

      lkeepdocnotes: false,

      // default the docrawfile name to the name of document and eliminate after if any
      ldocrawfilename: this.docname.replace('.docx', ''),

      TWOBLANKS: '  ',
      REDLINEAREA: 'redlines',

      selected: 'OwnerOnly',
      overwriteprevious: 'overwrite',

      IOstringdelimiter: '|||MLE|||',

      localdocedits: [],
      processingmessage: '',

      // Copied

      // validation rules for data entry
      rules: {
        required: (value) => !!value || this.$t('VALIDMSGS.REQUIRED'),
        counter80: (value) =>
          value.length <= 80 || this.$t('VALIDMSGS.MAX80CHARS'),
        counter160: (value) =>
          value.length <= 160 || this.$t('VALIDMSGS.MAX160CHARS'),
      },
      valid: false, // check for validation rules
    }
  },

  computed: {
    ...panelComputed,
    ...dbauthComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
    cdocname() {
      return this.docname
    },
    // Copied over from downloadredline
    seldocedits() {
      if (
        Array.isArray(this.editDocedits[this.panelno]) &&
        this.editDocedits[this.panelno].length > 0
      ) {
        // added the filter to only include active edits.
        return this.editDocedits[this.panelno].filter((e) => e.active === true)
      } else {
        return []
      }
    },

    filterdocedits() {
      var returnArray = []
      var index
      for (index in this.localdocedits) {
        if (this.selected === 'OwnerOnly') {
          if (this.localdocedits[index].approved) {
            returnArray.push(this.localdocedits[index])
          }
        } else if (this.selected === 'OwnerAndNegotiator') {
          if (
            this.localdocedits[index].approved &&
            this.localdocedits[index].negotiatorapproved
          ) {
            returnArray.push(this.localdocedits[index])
          }
        } else {
          returnArray.push(this.localdocedits[index])
        }
      }

      return returnArray.sort(comparePara)
    },

    filterparamods() {
      return this.filterdocedits.map((x) => x.paraid)
    },
    filtersentidmods() {
      return this.filterdocedits.map((x) => x.sentid)
    },

    filtermodsource() {
      return this.filterdocedits.map((x) => x.senttext)
    },
    filtermodtype() {
      return this.filterdocedits.map((x) => x.docedittype)
    },

    filtermodtarget() {
      var index
      var returnArray = []
      for (index in this.filterdocedits) {
        if (this.filterdocedits[index].docedittype === 'REPLACE') {
          returnArray.push(
            this.TWOBLANKS + this.filterdocedits[index].docedittext
          )
        } else if (this.filterdocedits[index].docedittype === 'DELETE') {
          returnArray.push(this.TWOBLANKS)
        } else if (this.filterdocedits[index].docedittype === 'ADD-AFTER') {
          // Added 2 spaces after ADD AFTER.
          returnArray.push(
            '<<ADDAFTER>>' +
              this.TWOBLANKS +
              this.filterdocedits[index].docedittext
          )
        } else {
          returnArray.push('MLE ERROR - MERGE ERROR')
        }
      }
      return returnArray
    },
    sizeDocEdits() {
      return this.filterdocedits.length
    },
    sizeDocEditsReplace() {
      return this.filterdocedits.filter((e) => e.docedittype === 'REPLACE')
        .length
    },
    sizeDocEditsDelete() {
      return this.filterdocedits.filter((e) => e.docedittype === 'DELETE')
        .length
    },
    sizeDocEditsAddAfter() {
      return this.filterdocedits.filter((e) => e.docedittype === 'ADD-AFTER')
        .length
    },
    notDocXMessage() {
      if (!this.eligibledocxin) {
        return this.$t('INFO_MESSAGES.SOURCEDOCNOTDOCX')
        // 'The Source document is not a DOCX document.  Redline capabilities are not available.'
      }
      return ''
    },
    // Copied over

    formdata() {
      const formdata = {
        docname: this.ldocname,
        docdescription: this.ldocdescription,
        docparty: this.ldocparty,
        doccounterparty: this.ldoccounterparty,
        docother: '',
        docallowcollclauselabels: this.ldocallowcollclauselabels,
        docenableNegotiation: this.ldocenableNegotiation,
        docrawfilename: this.ldocrawfilename,
        keepdocnotes: this.lkeepdocnotes,
      }
      return formdata
    },
  },

  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
  },
  methods: {
    setInitialValues() {
      this.ldocname = this.docname
      this.ldocdescription = this.docdescription
      this.ldocparty = this.docparty
      this.ldoccounterparty = this.doccounterparty
      this.ldocallowcollclauselabels = this.docallowcollclauselabels
      this.ldocenableNegotiation = this.docenableNegotiation

      this.lkeepdocnotes = false

      this.ldocrawfilename = this.docname.replace('.docx', '')

      this.localdocedits = this.seldocedits

      // Add a condition - if not in negotiation state, switch to Owner only as default
      if (!this.localpanelstate.ifNegotiationState) {
        this.selected = 'OwnerOnly'
      }
    },

    setNullValues() {
      // reset all the local forms for the next time it's accessed.
      this.ldocname = ''
      this.ldocdescription = ''
      this.ldocparty = ''
      this.ldoccounterparty = ''
      this.ldocallowcollclauselabels = false
      this.ldocenableNegotiation = false
      this.ldocrawfilename = ''
      this.lkeepdocnotes = false
    },

    createVersion() {
      this.$emit('close-dialog')
      const MyThis = this

      this.$store
        .dispatch('dbdocument/getDocument', {
          docid: MyThis.localpanelstate.docmeta.docid,
        })
        .then(function(doc) {
          const args = {
            urlname: doc.data.urlname,
            paramods: MyThis.filterparamods.join(MyThis.IOstringdelimiter),
            modsource: MyThis.filtermodsource.join(MyThis.IOstringdelimiter),
            modtarget: MyThis.filtermodtarget.join(MyThis.IOstringdelimiter),
            docid: MyThis.localpanelstate.docmeta.docid,
            uniqid: MyThis.localpanelstate.dbauthuser.uniqid,
            outputFileName: doc.data.filename.replace('.docx', '-CV.docx'),
            outputurlname:
              MyThis.REDLINEAREA +
              '/' +
              doc.data.docid +
              '/' +
              MyThis.createStringDateTime() +
              doc.data.filename.replace('.docx', '-CV.docx'),
          }
          const filename = MyThis.$store.dispatch(
            'docxmodservice/docxCreateFinal',
            args
          )
          filename.then(function(filelocation) {
            MyThis.$emit('create-version', {
              formdata: MyThis.formdata,
              filelocation: filelocation,
              neworiginaltext: MyThis.modifiedOriginalText2(
                MyThis.originaltext
              ),
              newdoctranslation: MyThis.modDocTranslation2(
                MyThis.doctranslation
              ),
              labelinputdata: MyThis.labelinputdata,
              completionaction: 'DOCEVENTS.CREATECLEANVERSION',
              completionmessage: 'MESSAGES.DOCUMENTSUCCESSCLEANVERSIONED',

              // added blank values for templates
              templateorigid: '',
              templateorigdocname: '',
              // neworiginaltext: MyThis.modifiedOriginalText,
              // newdoctranslation: MyThis.modDocTranslation,
            })
          })
        })

      // let This = this

      // This.createVerA(This).then(function(resultA) {
      //   // return This.createVerB(This, id)
      // })
      // .then(function(resultB) {
      //   return This.createVerC(This)
      // })
    },

    modifiedOriginalText2(OT) {
      // this makes a copy to a new array
      var modOriginalText = JSON.parse(JSON.stringify(OT))
      // var text = ''

      for (const index in this.filterdocedits) {
        // newtext = this.originaltext[this.filterdocedits[index].paraid].OT
        if (this.filterdocedits[index].docedittype === 'REPLACE') {
          // text = this.originaltext[this.filterdocedits[index].paraid].OT

          modOriginalText[this.filterdocedits[index].paraid] = {
            OT: modOriginalText[this.filterdocedits[index].paraid].OT.replace(
              this.filterdocedits[index].senttext,
              this.TWOBLANKS + this.filterdocedits[index].docedittext
            ),
          }
        } else if (this.filterdocedits[index].docedittype === 'DELETE') {
          // text = this.originaltext[this.filterdocedits[index].paraid].OT

          modOriginalText[this.filterdocedits[index].paraid] = {
            OT: modOriginalText[this.filterdocedits[index].paraid].OT.replace(
              this.filterdocedits[index].senttext,
              this.TWOBLANKS
            ),
          }
        } else if (this.filterdocedits[index].docedittype === 'ADD-AFTER') {
          // text = this.originaltext[this.filterdocedits[index].paraid].OT

          modOriginalText[this.filterdocedits[index].paraid] = {
            OT: modOriginalText[this.filterdocedits[index].paraid].OT.replace(
              this.filterdocedits[index].senttext,
              this.filterdocedits[index].senttext +
                this.TWOBLANKS +
                this.filterdocedits[index].docedittext
            ),
          }
        }
        // Check if it's empty.  If so, replace with << EMPTYTEXT >>
        if (
          modOriginalText[this.filterdocedits[index].paraid].OT.trim() === ''
        ) {
          modOriginalText[this.filterdocedits[index].paraid] = {
            OT: '<< EMPTYTEXT >>',
          }
        }
      }

      return modOriginalText
    },

    // only necessary for international documents.
    modDocTranslation2(DT) {
      if (Object.keys(DT).length === 0) {
        return {}
      }

      // this makes a copy to a new array
      var moddoctranslation = JSON.parse(JSON.stringify(DT))
      var text = ''

      const OUTDATEDTRANSLATION = '(outdated translation)'

      for (const index in this.filterdocedits) {
        text = this.doctranslation[this.filterdocedits[index].paraid].TT
        if (text !== undefined && !text.startsWith(OUTDATEDTRANSLATION)) {
          moddoctranslation[this.filterdocedits[index].paraid] = {
            TT: OUTDATEDTRANSLATION + text,
          }
        }
      }

      return moddoctranslation
    },

    // copied over
    createStringDateTime() {
      const date = new Date()
      return (
        date.toISOString().slice(0, 10) +
        ':' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds() +
        ':'
      )
    },

    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1400px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.CREATEVERSION')"
        ></PanelCardTitle>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="7">
                <h3 class="ma-1 mt-5">
                  {{ $t('DIALOGS.OPTIONSFORREDLINEINCLUDE') }}:</h3
                >
                <div class="ma-5">
                  <v-radio-group v-model="selected" required row>
                    <v-radio
                      class="mt-2"
                      :label="$t('DIALOGS.ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS')"
                      value="OwnerAndNegotiator"
                    ></v-radio>
                    <v-radio
                      class="mt-2"
                      :label="$t('DIALOGS.ONLYINCLUDEOWNERAPPROVEDEDITS')"
                      value="OwnerOnly"
                    ></v-radio>

                    <v-radio
                      class="mt-2"
                      :label="$t('DIALOGS.INCLUDEALLDOCEDITS')"
                      value="All"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
              <v-col cols="5">
                <h3 class="ma-1 mt-5"
                  >{{ $t('DIALOGS.REDLINECHANGESSELECTED') }}:
                  {{ sizeDocEdits }}</h3
                >
                <div class="ma-5 mt-8">
                  <div class="ma-5">
                    <p></p>
                    <b
                      ><p
                        >{{ $t('DIALOGS.REPLACEREDLINES') }}:
                        {{ sizeDocEditsReplace }}
                      </p>
                      <p
                        >{{ $t('DIALOGS.DELETEREDLINES') }}:
                        {{ sizeDocEditsDelete }}
                      </p>
                      <p
                        >{{ $t('DIALOGS.ADDAFTERREDLINES') }}:
                        {{ sizeDocEditsAddAfter }}
                      </p></b
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="ldocname"
                    :rules="[rules.required, rules.counter80]"
                    :label="$t('DIALOGS.DOCNAME')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldocdescription"
                    :rules="[rules.counter160]"
                    :label="$t('DIALOGS.DOCDESCRIPTION')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldocparty"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.DOCPARTY')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldoccounterparty"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.DOCCOUNTERPARTY')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldocrawfilename"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.DOCRAWFILENAME')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="ldocallowcollclauselabels"
                    :label="$t('DIALOGS.ENABLECOLLABTOMODLABELS')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ldocenableNegotiation"
                    :label="$t('DIALOGS.ENABLENEGOTCAPABILITIES')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="lkeepdocnotes"
                    :label="$t('DIALOGS.KEEPDOCNOTES')"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('file/createcleanversion')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            large
            outlined
            :disabled="!valid"
            class="mx-4"
            @click="createVersion"
            >{{ $t('DIALOGS.CREATE') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
