<script>
import { docroute } from '@utils/dialogutils'

// state
import { panelComputed, dbauthComputed } from '@state/helpers'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    eligibledocxin: {
      type: Boolean,
      required: true,
    },
    doctranslation: {
      type: Object,
      default: () => {},
    },
    originaltext: {
      type: Object,
      default: () => {},
    },

    // FYI - Need to pass each field in individually - VuejS objects to mutability of objects and arrays.
    docname: {
      type: String,
      required: true,
    },
    docdescription: {
      type: String,
      required: true,
    },
    labelinputdata: {
      type: Array,
      default: () => [],
    },
  },

  data: function() {
    return {
      // default file values
      ldocname: '',
      ldocdescription: '',
      ldocparty: '',
      ldoccounterparty: '',
      ldocother: '',
      ldocallowcollclauselabels: false,
      ldocenableNegotiation: false,

      llabelinputdata: [],
      // default the docrawfile name to the name of document and eliminate after if any
      ldocrawfilename: this.docname.replace('.docx', ''),

      TWOBLANKS: '  ',
      REDLINEAREA: 'redlines',

      IOstringdelimiter: '|||MLE|||',

      localdocedits: [],
      ldocedits: [],
      processingmessage: '',

      // Copied

      // validation rules for data entry
      rules: {
        required: (value) => !!value || this.$t('VALIDMSGS.REQUIRED'),
        counter80: (value) =>
          value.length <= 80 || this.$t('VALIDMSGS.MAX80CHARS'),
        counter160: (value) =>
          value.length <= 160 || this.$t('VALIDMSGS.MAX160CHARS'),
      },
      valid: false, // check for validation rules
    }
  },

  computed: {
    ...panelComputed,
    ...dbauthComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
    cdocname() {
      return this.docname
    },

    paramods() {
      return this.ldocedits.map((x) => x.paraid)
    },

    modsource() {
      return this.ldocedits.map((x) => x.expname)
    },

    modtarget() {
      return this.ldocedits.map((x) => x.value)
    },

    formdata() {
      const formdata = {
        docname: this.ldocname,
        docdescription: this.ldocdescription,
        docparty: this.ldocparty,
        doccounterparty: this.ldoccounterparty,
        docother: '',
        docallowcollclauselabels: this.ldocallowcollclauselabels,
        docenableNegotiation: this.ldocenableNegotiation,
        docrawfilename: this.ldocrawfilename,
      }
      return formdata
    },
  },

  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
  },
  methods: {
    setInitialValues() {
      this.populatelabeldata()
      // set defaults
      // this.ldocname = this.docname
      // this.ldocdescription = this.docdescription

      // this.ldocrawfilename = this.docname.replace('.docx', '')
      // this.localdocedits = this.seldocedits
    },

    populatelabeldata() {
      var returnArray = []

      // loads a blank version based on the uniquelabels

      for (var item of this.labelinputdata) {
        item.value = item.default
        returnArray.push(item)
      }
      this.llabelinputdata = returnArray
    },

    setNullValues() {
      // reset all the local forms for the next time it's accessed.
      this.ldocname = ''
      this.ldocdescription = ''
      this.ldocparty = ''
      this.ldoccounterparty = ''
      this.ldocallowcollclauselabels = false
      this.ldocenableNegotiation = false
      this.ldocrawfilename = ''
    },

    formatparalist(d) {
      return d.map((e) => e).join(',')
    },

    writedocedits() {
      // convert to one entry per paraid
      var returnArray = []

      for (const index in this.llabelinputdata) {
        for (const paraindex in this.llabelinputdata[index].paras) {
          returnArray.push({
            paraid: this.llabelinputdata[index].paras[paraindex],
            name: this.llabelinputdata[index].name,
            expname: '{{{' + this.llabelinputdata[index].name + '}}}',
            value: this.llabelinputdata[index].value,
          })
        }
      }

      this.ldocedits = returnArray.sort(function compareParaIDs(a, b) {
        return a.paraid - b.paraid
      })
      // return returnArray.sort(function compareParaIDs(a, b) {
      //   return a.paraid - b.paraid
      // })
    },

    createVersion() {
      const MyThis = this
      // get the documents info - urlname.

      this.writedocedits()

      this.$store
        .dispatch('dbdocument/getDocument', {
          docid: MyThis.localpanelstate.docmeta.docid,
        })
        .then(function(doc) {
          const args = {
            urlname: doc.data.urlname,

            // these three needs to be replaced.
            paramods: MyThis.paramods.join(MyThis.IOstringdelimiter),
            modsource: MyThis.modsource.join(MyThis.IOstringdelimiter),
            modtarget: MyThis.modtarget.join(MyThis.IOstringdelimiter),
            // these needs to be replaced with the new changes.
            docid: MyThis.localpanelstate.docmeta.docid,
            uniqid: MyThis.localpanelstate.dbauthuser.uniqid,
            overwriteprevredlines: 'overwrite', // not necessary, I don't think.
            outputFileName: doc.data.filename.replace('.docx', '-CDT.docx'),
            outputurlname:
              MyThis.REDLINEAREA +
              '/' +
              doc.data.docid +
              '/' +
              MyThis.createStringDateTime() +
              doc.data.filename.replace('.docx', '-CDT.docx'),
          }
          // create the final document.
          const filename = MyThis.$store.dispatch(
            'docxmodservice/docxCreateFinal',
            args
          )
          // trigger create-version in Apanel with updated info.
          filename.then(function(filelocation) {
            MyThis.$emit('create-version', {
              formdata: MyThis.formdata,
              filelocation: filelocation,
              neworiginaltext: MyThis.modifiedOriginalText2(
                MyThis.originaltext
              ),
              newdoctranslation: MyThis.modDocTranslation2(
                MyThis.doctranslation
              ),
              labelinputdata: MyThis.llabelinputdata,
              templateorigid: MyThis.localpanelstate.docmeta.docid,
              templateorigdocname: MyThis.localpanelstate.docmeta.docname,
              completionaction: 'DOCEVENTS.CREATEDOCFROMTEMPLATE',
              completionmessage: 'MESSAGES.DOCUMENTSUCCESSFROMTEMPLATE',
            })
          })
        })

      this.showDialog = false
    },

    modifiedOriginalText2(OT) {
      // this makes a copy to a new array
      var modOriginalText = JSON.parse(JSON.stringify(OT))

      for (const index in this.ldocedits) {
        modOriginalText[this.ldocedits[index].paraid] = {
          OT: modOriginalText[this.ldocedits[index].paraid].OT.replace(
            this.ldocedits[index].expname,
            this.ldocedits[index].value
          ),
        }
      }

      return modOriginalText
    },

    // only necessary for international documents.
    modDocTranslation2(DT) {
      if (Object.keys(DT).length === 0) {
        return {}
      }

      // this makes a copy to a new array
      var moddoctranslation = JSON.parse(JSON.stringify(DT))
      var text = ''

      const OUTDATEDTRANSLATION = '(outdated translation)'

      for (const index in this.ldocedits) {
        text = this.doctranslation[this.ldocedits[index].paraid].TT
        if (text !== undefined && !text.startsWith(OUTDATEDTRANSLATION)) {
          moddoctranslation[this.ldocedits[index].paraid] = {
            TT: OUTDATEDTRANSLATION + text,
          }
        }
      }

      return moddoctranslation
    },

    // copied over
    createStringDateTime() {
      const date = new Date()
      return (
        date.toISOString().slice(0, 10) +
        ':' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds() +
        ':'
      )
    },

    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1400px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.CREATEDOCFROMTEMPLATE')"
        ></PanelCardTitle>
        <v-card-text>
          <v-form v-model="valid">
            <v-container fluid>
              <v-row justify="center">
                <v-simple-table :class="$style.dialogTable" fixed-header>
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                      fixed-header
                    >
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.FIELDNAME') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.PARAGRAPHID') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          >{{ $t('DIALOGS.DESCRIPTION') }}</th
                        >

                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.EXAMPLE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogSmallRowTextWidth"
                          >{{ $t('DIALOGS.VALUE') }}</th
                        ><th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.DEFAULT') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in llabelinputdata"
                        :key="item.name"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellWidth">{{ item.name }}</td>
                        <td :class="$style.dialogBigCellStatus">
                          {{ formatparalist(item.paras) }}</td
                        >
                        <td :class="$style.dialogBigCellStatus">
                          {{ item.description }}</td
                        >

                        <td :class="$style.dialogCellStatus">
                          {{ item.example }}</td
                        >
                        <td :class="$style.dialogSmallRowTextWidth"
                          ><v-text-field
                            v-model="item.value"
                            :rules="[rules.required]"
                            >{{ item.value }}</v-text-field
                          ></td
                        ><td :class="$style.dialogCellStatus">
                          {{ item.default }}</td
                        >
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="ldocname"
                    :rules="[rules.required, rules.counter80]"
                    :label="$t('DIALOGS.DOCNAME')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldocdescription"
                    :rules="[rules.counter160]"
                    :label="$t('DIALOGS.DOCDESCRIPTION')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldocparty"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.DOCPARTY')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldoccounterparty"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.DOCCOUNTERPARTY')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldocrawfilename"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.DOCRAWFILENAME')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="ldocallowcollclauselabels"
                    :label="$t('DIALOGS.ENABLECOLLABTOMODLABELS')"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="ldocenableNegotiation"
                    :label="$t('DIALOGS.ENABLENEGOTCAPABILITIES')"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('file/createdocfromtemplate')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            large
            outlined
            :disabled="!valid"
            class="mx-4"
            @click="createVersion"
            >{{ $t('DIALOGS.CREATE') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
