// Which backend Storage
const STORFIREBASE = process.env.VUE_APP_STORAGE === 'FIREBASE'
const STORMINIO = process.env.VUE_APP_STORAGE === 'MINIO'

// Test
import axios from 'axios'

// FIREBASE
// import firebase from 'firebase/app'
import { storage } from '@/src/dbconfig/FBconfig.js'
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from 'firebase/storage'

import DocumentProcessingService from '@services/DocumentProcessingService'
// const storageRef = ref(storage)

export function STORgetDownloadUrl(filename) {
  const MyThis = this
  // Firebase
  if (STORFIREBASE) {
    // return storage.child(filename).getDownloadURL()
    // return ref(storage, filename).getDownloadURL()
    return getDownloadURL(ref(storage, filename))
  }

  // Minio
  if (STORMINIO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.miniopresignedurl({
        filename: filename,
      }).then(function (url) {
        resolve(url.data.url)
      })
    })
  }
}

// write file to specific location then return downloadUrl when completed.
export function STORcopyFromToFile(from, to) {
  // Firebase
  if (STORFIREBASE) {
    return new Promise(function (resolve, reject) {
      const MyThis = this
      // Create a root reference
      // var storageRef = storage.ref()

      const fileRefSource = ref(storage, from)
      const fileRefTarget = ref(storage, to)

      // copy
      getDownloadURL(fileRefSource)
        .then(function (url) {
          var xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = function (event) {
            uploadBytes(fileRefTarget, xhr.response).then((snapshot) => {
              getDownloadURL(snapshot.ref).then(function (url) {
                resolve({
                  downloadURL: url,
                  filelocation: to,
                })
              })
            })
            // fileRefTarget.put(xhr.response).then((snapshot) => {
            //   getDownloadURL(snapshot.ref).then(function (url) {
            //     resolve({
            //       downloadURL: url,
            //       filelocation: to,
            //     })
            //   })
            // })
          }
          xhr.open('GET', url)
          xhr.send()
        })
        .catch(function (e) {
          MyThis.$store.dispatch('dberror/logError', e)
        })
    })
  }

  // Minio
  if (STORMINIO) {
    DocumentProcessingService.miniocopyobject({
      to: to,
      from: from,
    })

    // MINIOCLIENT.copyObject(
    //   MINIOBUCKET,
    //   to,
    //   '/' + MINIOBUCKET + from,
    //   new Minio.CopyConditions(),
    //   function (e, data) {
    //     if (e) {
    //       MyThis.$store.dispatch('dberror/logError', e)
    //     }
    //   }
    // )
  }
}

// write file to specific location then return downloadUrl when completed.
export function STORwriteFile(filename, e) {
  const MyThis = this

  // Firebase
  if (STORFIREBASE) {
    return new Promise(function (resolve, reject) {
      // var storageRef = storage.ref()
      // Create a reference to the filename
      var fileUploadRef = ref(storage, filename)

      // upload the file
      var fileUploadTask = uploadBytesResumable(fileUploadRef, e)

      // const MyThis = this

      fileUploadTask.on(
        'state_changed',
        function (snapshot) {
          // uploadprogress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        function (error) {
          MyThis.$store.dispatch('dberror/logError', error)
        },
        function () {
          getDownloadURL(fileUploadTask.snapshot.ref).then(function (
            downloadURL
          ) {
            resolve(downloadURL)
          })
        }
      )
    })
  }

  // Minio
  if (STORMINIO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.miniofputobject({
        filename: filename,
        e: e,
      }).then(function (data) {
        resolve(data.data.url)
      })
    })

    // put it and then return the url
    // MINIOCLIENT.fPutObject(
    //   MINIOBUCKET,
    //   filename,
    //   e,
    //   MINIOMETADATA,
    //   function (e, obj) {
    //     if (e) {
    //       MyThis.$store.dispatch('dberror/logError', e)
    //     } else {
    //       // 30 days URL
    //       MINIOCLIENT.presignedUrl(
    //         'GET',
    //         MINIOBUCKET,
    //         filename,
    //         24 * 60 * 60 * 30,
    //         function (e, presignedUrl) {
    //           if (e) {
    //             MyThis.$store.dispatch('dberror/logError', e)
    //           } else {
    //             resolve(presignedUrl)
    //           }
    //         }
    //       )
    //     }
    //   }
    // )
  }
}

export function STORgetJSONFile(filename) {
  if (STORFIREBASE) {
    return new Promise(function (resolve, reject) {
      const MyThis = this
      STORgetDownloadUrl(filename)
        .then((url) => {
          // This can be downloaded directly:
          var xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = (event) => {
            var blob = xhr.response
            const reader = new FileReader()
            reader.readAsText(blob)
            reader.onload = (e) =>
              resolve(JSON.parse(JSON.parse(e.target.result)))
            // Use double parsing to first eliminate the escaped syntax, then convert to object
          }
          xhr.open('GET', url)
          xhr.send()
        })
        .catch(function (e) {
          MyThis.$store.dispatch('dberror/logError', e)
        })
    })
  }

  // Minio
  if (STORMINIO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.miniogetjsonfile({
        filename: filename,
      }).then(function (data) {
        resolve(JSON.parse(JSON.parse(data.data.data)))
      })
    })
  }
}

export function STORdownloadCloudFile(urlname, filename) {
  const MyThis = this

  if (STORFIREBASE) {
    STORgetDownloadUrl(urlname)
      .then(function (url) {
        var xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = function (event) {
          var blob = xhr.response
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch(function (e) {
        MyThis.$store.dispatch('dberror/logError', e)
      })
  }

  // Minio
  if (STORMINIO) {
    DocumentProcessingService.miniogeturlfile({
      urlname: urlname,
    }).then(function (data) {
      let blob = new Blob([data.data], { type: data.headers['content-type'] })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    })
  }
}
