import format from 'date-fns/format'
import '@design'

import store from '@state/store'

const DOCS_HOST = 'https://docs.docgility.com'

// DB Backend
const DBFIREBASE = process.env.VUE_APP_DB === 'FIREBASE'
const DBMONGO = process.env.VUE_APP_DB === 'MONGO'

export function formatDateDialog(d) {
  // Below works for firebase.  - stored as timestamp in nanoseconds
  if (DBFIREBASE) {
    if (d === null || d === 'undefined' || d === '') {
      return ''
    }
    return format(d.toDate(), 'yyyy-MM-dd (HH:mm)')
  }
  // Below works for DBMongo - stored as ISO
  if (DBMONGO) {
    return d
  }
}

export function formatDateDialogISO(d) {
  return format(d.toDate(), 'yyyy-MM-dd')
}

export function formatDateforStorage(d) {
  return format(d.toDate(), 'yyyyMMddHH')
}

export function displayUserName(email, dbauthUserInfoCache) {
  const findIndex = dbauthUserInfoCache.findIndex((e) => e.uniqid === email)
  if (findIndex < 0) {
    store.dispatch('dbauth/addRecordUserInfoAuth', email)
    return email
  } else {
    return dbauthUserInfoCache[findIndex].name
  }
}

export function docroute(link) {
  const docroot = DOCS_HOST + '/documentation/'
  if (link === '') {
    window.open(docroot)
  } else {
    window.open(docroot + link + '.html')
  }
}

// Added .html ending because redirection does not seem to working well in vuepress.
export function docrouteusecase(link) {
  // move to globals later.
  const docroot = DOCS_HOST + '/usecases/'
  window.open(docroot + link + '.html')
}

export function routevideo(link) {
  window.open(link)
}

export function displayUserInfo(email, dbauthUserInfoCache) {
  const findIndex = dbauthUserInfoCache.findIndex((e) => e.uniqid === email)
  var level = ''

  if (findIndex < 0) {
    store.dispatch('dbauth/addRecordUserInfoAuth', email)
    return 'not registered, ' + email
  } else {
    // add a level indicator for user info display.
    if (dbauthUserInfoCache[findIndex].subscriptionid === 'J5') {
      level = 'VIP Access'
    } else if (dbauthUserInfoCache[findIndex].subscriptionid >= 'J') {
      level = 'Umlimited'
    } else if (dbauthUserInfoCache[findIndex].subscriptionid >= 'G') {
      level = 'Premium'
    } else if (dbauthUserInfoCache[findIndex].subscriptionid >= 'C') {
      level = 'Basic'
    } else {
      level = 'Trial'
    }

    var active = dbauthUserInfoCache[findIndex].active ? 'Active' : 'Inactive'

    // format for a more total representation is -
    // Company, title, email
    return (
      dbauthUserInfoCache[findIndex].company +
      ', ' +
      dbauthUserInfoCache[findIndex].title +
      ', ' +
      dbauthUserInfoCache[findIndex].uniqid +
      ', ' +
      level +
      ', ' +
      active
    )
  }
}

export function displayDocID(d) {
  return d.slice(d.length - 4)
}

export function validEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function chNO(obj, key) {
  if (obj === null) {
    return ''
  }
  if (typeof obj === 'undefined') {
    return ''
  }
  if (typeof obj[key] === 'undefined') {
    return ''
  }
  return obj[key]
}

export function chNObool(obj, key) {
  if (obj === null) {
    return false
  }
  if (typeof obj === 'undefined') {
    return false
  }
  if (typeof obj[key] === 'undefined') {
    return false
  }
  return obj[key]
}

// Theres some bug that occurs that prevents the default values from being passed on.
export function chNO2(obj, key) {
  if (obj === null) {
    return ''
  } else if (obj[key]) {
    return obj[key]
  } else {
    return ''
  }
}

export function chNOarray(obj, key) {
  if (obj === null) {
    return []
  }
  if (typeof obj === 'undefined') {
    return []
  }
  if (typeof obj[key] === 'undefined') {
    return []
  }
  return obj[key]
}

export function displayDocType(d, dbauthDocTypes) {
  const doctypes = dbauthDocTypes
  if (d === '1' || d === 1) {
    return '---'
  } else {
    var objIndex = doctypes.findIndex((obj) => obj.keyid === d)
    if (objIndex >= 0) {
      return doctypes[objIndex].doctype
    } else {
      return '--'
    }
  }
}

export function formatDelineatedArray(d) {
  return d.toString()
}

export function statusIndicator(bool) {
  if (bool) {
    return {
      icon: 'mdi-check-bold',
      color: 'green',
    }
  } else {
    return {
      icon: 'mdi-close-outline',
      color: 'red',
    }
  }
}

const mincharsforSentence = 100

export function splitSentences(text) {
  if (text == null) {
    return []
  }
  const findArray = text
    .replace(/\.+/g, '.|')
    .replace(/\?/g, '?|')
    .replace(/!/g, '!|')
    .split('|')

  var iter = 0
  var returnArray = []
  var tempStr = ''
  for (var i = 0; i < findArray.length; i++) {
    tempStr += findArray[i]
    if (tempStr.length > mincharsforSentence) {
      iter += 1
      returnArray.push({ key: iter, sentence: tempStr })
      tempStr = ''
    }
  }
  // if necessary, push the last one.
  if (tempStr.length > 0) {
    iter += 1
    returnArray.push({ key: iter, sentence: tempStr })
  }
  return returnArray
}

// sorting functions
export function compareTitle(a, b) {
  if (a.title > b.title) {
    return 1
  } else {
    return -1
  }
}

// sorting functions
export function compareOrder(a, b) {
  if (a.order > b.order) {
    return 1
  } else {
    return -1
  }
}

// sorting functions
export function comparePara(a, b) {
  if (
    parseInt(a.paraid) * 1000 + parseInt(a.sentid) >
    parseInt(b.paraid) * 1000 + parseInt(b.sentid)
  ) {
    return 1
  } else {
    return -1
  }
}

// sorting functions
// export function comparePara(a, b) {
//   if (parseInt(a.paraid) > parseInt(b.paraid)) {
//     return 2
//   } else if (parseInt(a.paraid) === parseInt(b.paraid)) {
//     if (parseInt(a.sentid) > parseInt(b.sentid)) {
//       return 1
//     } else {
//       return -1
//     }
//   } else {
//     return -2
//   }
// }

// compares arrays and returns add array and del array
export function compareArrays(subarray, allarray) {
  var addArray = []
  var delArray = []
  var position = -1
  allarray.forEach(function (e) {
    if (typeof subarray === 'undefined') {
      position = -1
    } else {
      position = subarray.findIndex((f) => f === e)
    }
    if (position < 0) {
      addArray.push(e)
    } else {
      delArray.push(e)
    }
  })
  return { addArray: addArray, delArray: delArray }
}
