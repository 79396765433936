<script>
import { panelComputed, dbauthComputed } from '@state/helpers'
import {
  formatDateDialog,
  displayDocType,
  displayDocID,
  displayUserName,
  displayUserInfo,
  docroute,
} from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: {
    PanelCardTitle,
  },
  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    confirmDelete: false,
    indexDoc: '',

    docslistOption: false,

    sortcolumn: 'create',
    sortascend: false,
  }),
  computed: {
    ...panelComputed,
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },

    groupAvailable() {
      return this.dbauthPrivileges.type >= 'G'
    },
    inputDocList() {
      if (!this.docslistOption) {
        return this.dbauthAvailDocs
      } else {
        return this.dbauthAvailGroupDocs
      }
    },

    doclist() {
      if (Array.isArray(this.inputDocList) && this.inputDocList.length > 0) {
        return this.inputDocList.filter((e) => e.active === true)
      } else {
        return []
      }
    },

    sorteddoclist() {
      var sorteddoclist = [...this.doclist]
      return sorteddoclist.sort(this.compare(this.sortcolumn, this.sortascend))
    },
  },
  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
  },

  methods: {
    setInitialValues() {
      this.refreshDocList()
    },

    setSortColumn(column) {
      this.sortcolumn = column
      this.sortascend = !this.sortascend
    },

    // ascend changes the direction of the comparison
    compare(value, ascend) {
      if (ascend) {
        return function(a, b) {
          if (a[value] > b[value]) {
            return 1
          } else {
            return -1
          }
        }
      } else {
        return function(a, b) {
          if (a[value] > b[value]) {
            return -1
          } else {
            return +1
          }
        }
      }
    },

    compareDoc(index) {
      this.$emit('compare-document', this.sorteddoclist[index].keyid.toString())
    },

    // relay to use utility functions.
    formatDateDialog(d) {
      // return d
      return formatDateDialog(d)
    },
    displayDocTypeAct(d) {
      return displayDocType(d, this.dbauthDocTypes)
    },
    formatDelineatedArray(d) {
      return d
        .slice(Math.max(d.length - 2, 1))
        .map((e) => e.note)
        .join('\n')
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },
    displayDocID(d) {
      return displayDocID(d)
    },
    displayPrivilege(d) {
      switch (d) {
        // FILE MENU
        case 'owner':
          return this.$t('DIALOGS.OWNER')
        case 'archive(owner)':
          return this.$t('DIALOGS.OWNER')
        case 'collaborator':
          return this.$t('DIALOGS.COLLABORATOR')
        case 'archive(group)':
          return this.$t('DIALOGS.COLLABORATORGROUP')
        case 'negotiator':
          return this.$t('DIALOGS.NEGOTIATOR')
        case 'owner(group)':
          return this.$t('DIALOGS.OWNERGROUP')
        case 'collaborator(group)':
          return this.$t('DIALOGS.COLLABORATORGROUP')
        default:
          return ''
      }
    },
    docroute(d) {
      return docroute(d)
    },

    refreshDocList() {
      this.$store.dispatch('dbauth/getUserAvailDocsAuth')
      this.$store.dispatch('dbauth/getUserAvailGroupDocsAuth')
      this.$store.dispatch('dbauth/getUserAvailHubDocsAuth')
    },

    show(privilege, info) {
      if (privilege === 'negotiator') {
        return '--'
      } else {
        if (['OPEN', 'CLOSED', 'NONE'].includes(info)) {
          return this.$t('DIALOGS.' + info)
        }
      }
      return info
    },
    showinfo(info) {
      if (['OPEN', 'CLOSED', 'NONE'].includes(info)) {
        return this.$t('DIALOGS.' + info)
      }
      return info
    },
  },
}
</script>

<template>
  <v-row>
    <v-dialog
      v-model="showDialog"
      persistent
      :content-class="$style.dialogWidth"
    >
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.COMPAREDOCUMENT')"
        ></PanelCardTitle>
        <div>
          <v-form>
            <v-container fluid>
              <v-row justify="center">
                <v-simple-table :class="$style.dialogOpenTable" fixed-header>
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                      fixed-header
                    >
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellDelete"
                          >{{ $t('DIALOGS.OPEN') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('privilege')"
                          >{{ $t('DIALOGS.PRIVILEGE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('ownerid')"
                          >{{ $t('DIALOGS.OWNERID') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          @click="setSortColumn('doctypeid')"
                          >{{ $t('DIALOGS.DOCTYPE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          @click="setSortColumn('docid')"
                          >{{ $t('DIALOGS.DOCID') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          @click="setSortColumn('docname')"
                          >{{ $t('DIALOGS.DOCNAME') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          @click="setSortColumn('docdescription')"
                          >{{ $t('DIALOGS.DOCDESCRIPTION') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          @click="setSortColumn('docnotes')"
                          >{{ $t('DIALOGS.DOCNOTES') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('collabstatus')"
                          >{{ $t('DIALOGS.COLLABORATIONSTATUS') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('collabend')"
                          >{{ $t('DIALOGS.COLLABORATIONEND') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('negotiationstatus')"
                          >{{ $t('DIALOGS.NEGOTIATIONSTATUS') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('negotiationend')"
                          >{{ $t('DIALOGS.NEGOTIATIONEND') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          @click="setSortColumn('docparty')"
                          >{{ $t('DIALOGS.DOCPARTY') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          @click="setSortColumn('doccounterparty')"
                          >{{ $t('DIALOGS.DOCCOUNTERPARTY') }}</th
                        >

                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellDelete"
                          @click="setSortColumn('create')"
                          >{{ $t('DIALOGS.TIME') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="(item, index) in sorteddoclist"
                        :key="item.docid"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellDelete"
                          ><v-btn x-small text @click="compareDoc(index)">
                            <v-icon>mdi-folder-open-outline</v-icon>
                          </v-btn></td
                        >

                        <td :class="$style.dialogCellStatus">{{
                          displayPrivilege(item.privilege)
                        }}</td>
                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item v-for="it in item.ownerid" :key="it">
                              <v-list-item-subtitle
                                ><v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{
                                      displayUserName(it)
                                    }}</span>
                                  </template>
                                  <span>{{ displayUserInfo(it) }}</span>
                                </v-tooltip></v-list-item-subtitle
                              >
                            </v-list-item>
                          </v-list>
                        </td>
                        <td :class="$style.dialogCellStatus">{{
                          displayDocTypeAct(item.doctypeid)
                        }}</td>
                        <td :class="$style.dialogBigCellStatus">{{
                          displayDocID(item.docid)
                        }}</td>
                        <td
                          :class="$style.dialogBigCellStatus"
                          style="word-wrap: break-word;"
                          >{{ item.docname }}</td
                        >
                        <td
                          :class="$style.dialogBigCellStatus"
                          style="word-wrap: break-word;"
                          >{{ item.docdescription }}</td
                        >
                        <td
                          :class="$style.dialogBigCellStatus"
                          style="word-wrap: break-word;"
                          >{{
                            show(
                              item.privilege,
                              formatDelineatedArray(item.docnotes)
                            )
                          }}</td
                        >
                        <td :class="$style.dialogCellStatus">
                          {{ show(item.privilege, item.collabstatus) }}
                        </td>
                        <td :class="$style.dialogCellStatus">
                          {{ show(item.privilege, item.collabend) }}
                        </td>
                        <td :class="$style.dialogCellStatus">{{
                          showinfo(item.negotiationstatus)
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          showinfo(item.negotiationend)
                        }}</td>
                        <td
                          :class="$style.dialogCellWidth"
                          style="word-wrap: break-word;"
                          >{{ item.docparty }}</td
                        >
                        <td
                          :class="$style.dialogCellWidth"
                          style="word-wrap: break-word;"
                          >{{ item.doccounterparty }}</td
                        >

                        <td :class="$style.dialogCellDelete">{{
                          formatDateDialog(item.create)
                        }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>

        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('file/comparedocument')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-checkbox
            v-if="groupAvailable"
            v-model="docslistOption"
            :label="$t('MESSAGES.VIEWGROUPDOCS')"
          ></v-checkbox>
          <v-btn large outlined class="mx-4" @click="refreshDocList">{{
            $t('DIALOGS.REFRESH')
          }}</v-btn>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
