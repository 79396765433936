// Constants for CLAUSES OOTB MODEL

export const CLAUSESLIST = {
  '0': {
    id: '0',
    short: '--',
    long: 'NONE',
    order: '100',
    description: 'NO CLASSIFICATION',
  },
  '1': {
    id: '1',
    short: 'PR',
    long: 'PREAMBLE',
    order: '010',
    description: 'PREAMBLE CLAUSES',
  },
  '2': {
    id: '2',
    short: 'DF',
    long: 'DEFN',
    order: '015',
    description: 'DEFINITIONS',
  },
  '3': {
    id: '3',
    short: 'PT',
    long: 'PAYMENT',
    order: '065',
    description: 'PAYMENT TERMS',
  },
  '4': {
    id: '4',
    short: 'TX',
    long: 'TAXES',
    order: '070',
    description: 'TAXES',
  },
  '5': {
    id: '5',
    short: 'RW',
    long: 'REPWARR',
    order: '030',
    description: 'REPRESENTATION AND WARRANTIES',
  },
  '6': {
    id: '6',
    short: 'PD',
    long: 'PERIOD',
    order: '060',
    description: 'PERIOD',
  },
  '7': {
    id: '7',
    short: 'TM',
    long: 'TERMINATION',
    order: '040',
    description: 'TERMINATION',
  },
  '8': {
    id: '8',
    short: 'CF',
    long: 'CONFID',
    order: '074',
    description: 'CONFIDENTIALITY',
  },
  '9': {
    id: '9',
    short: 'ID',
    long: 'INDEMNITY',
    order: '025',
    description: 'INDEMNIFICATION',
  },
  '10': {
    id: '10',
    short: 'LB',
    long: 'LIABILITY',
    order: '020',
    description: 'LIMIT OF LIABILITY',
  },
  '11': {
    id: '11',
    short: 'IP',
    long: 'INTELPROP',
    order: '076',
    description: '',
  },
  '12': {
    id: '12',
    short: 'SG',
    long: 'SIGNATURE',
    order: '096',
    description: '',
  },
  '13': {
    id: '13',
    short: 'DT',
    long: 'OTHER',
    order: '099',
    description: 'OTHER ...',
  },
  '14': {
    id: '14',
    short: 'DS',
    long: 'DATASECURITY',
    order: '078',
    description: 'DATA SECURITY',
  },
  '15': {
    id: '15',
    short: 'AS',
    long: 'ASSIGN',
    order: '084',
    description: 'ASSIGNMENT',
  },
  '16': {
    id: '16',
    short: 'NT',
    long: 'NOTICE',
    order: '094',
    description: '',
  },
  '17': {
    id: '17',
    short: 'GL',
    long: 'GOVERNLAW',
    order: '055',
    description: 'GOVERNING LAW',
  },
  '18': {
    id: '18',
    short: 'DR',
    long: 'DISPUTE',
    order: '045',
    description: 'DISPUTE RESOLUTION',
  },
  '19': {
    id: '19',
    short: 'EA',
    long: 'ENTIREAGREE',
    order: '082',
    description: 'ENTIRE AGREEMENT',
  },
  '20': {
    id: '20',
    short: 'SV',
    long: 'SURVIVAL',
    order: '086',
    description: 'SURVIVAL TERMS',
  },
  '21': {
    id: '21',
    short: 'RP',
    long: 'RELATPART',
    order: '092',
    description: 'RELATIONSHIP OF THE PARTIES',
  },
  '22': {
    id: '22',
    short: 'TP',
    long: 'THIRDPARTY',
    order: '090',
    description: 'THIRD PARTY BENEFICIARIES',
  },
  '23': {
    id: '23',
    short: 'FM',
    long: 'FORCEMAJEURE',
    order: '035',
    description: 'FORCE MAJEURE',
  },
  '24': {
    id: '24',
    short: 'SV',
    long: 'SEVERABILITY',
    order: '050',
    description: 'SEVERABILITY',
  },
  '25': {
    id: '25',
    short: 'EC',
    long: 'EXPORTCTRL',
    order: '088',
    description: 'EXPORT CONTROL LAWS',
  },
  '27': {
    id: '27',
    short: 'IN',
    long: 'INTRO',
    order: '005',
    description: 'INTRODUCTION',
  },
  '44': {
    id: '44',
    short: 'IS',
    long: 'INSURANCE',
    order: '072',
    description: 'INSURANCE',
  },
  T: {
    id: 'T',
    short: 'HH',
    long: 'HEADING',
    order: '098',
    description: 'SECTION HEADING',
  },
}
