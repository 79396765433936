<script>
import { getExecuteContract } from '@utils/panel-actions.js'

import PanelCardTitle from './panel-cardtitle.vue'

import { docroute } from '@utils/dialogutils'

import { STORwriteFile } from '@/src/state/io/iostorage.js'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    dbauthuser: {
      type: Object,
      default: () => {},
    },

    // FYI - Need to pass each field in individually - VuejS objects to mutability of objects and arrays.
    docname: {
      type: String,
      required: true,
    },
    docdescription: {
      type: String,
      required: true,
    },
    docparty: {
      type: String,
      required: true,
    },
    doccounterparty: {
      type: String,
      required: true,
    },
    docallowcollclauselabels: {
      type: Boolean,
      required: true,
    },
    docenableNegotiation: {
      type: Boolean,
      required: true,
    },

    archivenotes: {
      type: String,
      required: true,
    },
    // boolean
    executedcontract: {
      type: Boolean,
      required: true,
    },
    executeddate: {
      type: String,
      required: true,
    },
    startdate: {
      type: String,
      required: true,
    },
    enddate: {
      type: String,
      required: true,
    },
    // boolean
    clausetopics: {
      type: Boolean,
      required: true,
    },
    // boolean
    contractattached: {
      type: Boolean,
      required: true,
    },
    contractename: {
      type: String,
      required: true,
    },
    contractdownloadurl: {
      type: String,
      required: true,
    },

    labelinputdata: {
      type: Array,
      default: () => [],
    },

    templateorigid: {
      type: String,
      required: true,
    },

    templateorigdocname: {
      type: String,
      required: true,
    },
  },

  data: function() {
    return {
      // this never works to pass defaults to local variables/
      // previously, this was just saved in the form, it didn't necessarily retrieve it.
      // when this instantiated, it needs to populated with the right defaults for the panel.
      // save operation works but not the local copy to a default variable.

      // this does not seem to work at all to set the initial defaults.  instead, use setInitialValues()
      ldocname: this.docname,
      ldocdescription: this.docdescription,
      ldocparty: this.docparty,
      ldoccounterparty: this.doccounterparty,

      ldocallowcollclauselabels: this.docallowcollclauselabels,
      ldocenableNegotiation: this.docenableNegotiation,

      larchivenotes: '',
      lexecutedcontract: false,
      lexecuteddate: '',
      lstartdate: '',
      lenddate: '',
      lclausetopics: false,

      showUpload: false,
      progresson: false,
      uploadprogress: 0,

      // flags
      noexecdate: false,
      nostartdate: false,
      noenddate: false,

      // validation rules for data entry
      rules: {
        required: (value) => !!value || this.$t('VALIDMSGS.REQUIRED'),
        counter80: (value) =>
          value.length <= 80 || this.$t('VALIDMSGS.MAX80CHARS'),
        counter160: (value) =>
          value.length <= 160 || this.$t('VALIDMSGS.MAX160CHARS'),
      },
      valid: false, // check for validation rules
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
    cdocname() {
      return this.docname
    },

    enableSave() {
      // not enabled for HUB Type transactions, only under owner view within the originating instance.
      if (this.localpanelstate.ifHUBType){
          return false
      }
      if (this.documentType === 'DOCUMENT') {

        return this.localpanelstate.ownerPriv || !this.localpanelstate.ifSaved
      }
      if (this.archiveOwner) {
        return true
      }

      return false
    },

    title() {
      return this.localpanelstate.ifSaved
        ? this.$t('DIALOGS.DOCUMENTSETTINGS') + ' - ' + this.documentTypeDisplay
        : this.$t('DIALOGS.SAVEDOCUMENT')
    },

    archiveOwner() {
      return (
        this.localpanelstate.ownerPriv && this.documentType === 'ARCHIVEDOC'
      )
    },

    documentType() {
      if (this.localpanelstate.ifPlaybook) {
        return 'PLAYBOOK'
      }
      if (this.localpanelstate.ifTemplate) {
        return 'TEMPLATE'
      }
      if (this.localpanelstate.ifArchivedState) {
        return 'ARCHIVEDOC'
      }
      return 'DOCUMENT'
    },

    documentTypeDisplay() {
      return this.$t('DIALOGS.' + this.documentType)
    },

    docFromTemplate() {
      return this.documentType === 'DOCUMENT' && this.templateorigid !== ''
    },
  },

  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
  },
  methods: {
    setInitialValues() {
      this.ldocname = this.docname
      this.ldocdescription = this.docdescription
      this.ldocparty = this.docparty
      this.ldoccounterparty = this.doccounterparty
      this.ldocallowcollclauselabels = this.docallowcollclauselabels
      this.ldocenableNegotiation = this.docenableNegotiation

      // archive fields
      this.larchivenotes = this.archivenotes
      this.lexecutedcontract = this.executedcontract
      this.lexecuteddate = this.executeddate
      this.lstartdate = this.startdate
      this.lenddate = this.enddate
      this.lclausetopics = this.clausetopics
    },

    setNullValues() {
      // reset all the local forms for the next time it's accessed.
      this.ldocname = ''
      this.ldocdescription = ''
      this.ldocparty = ''
      this.ldoccounterparty = ''
      this.ldocallowcollclauselabels = false
      this.ldocenableNegotiation = false

      this.larchivenotes = ''
      this.lexecutedcontract = false
      this.lexecuteddate = ''
      this.lstartdate = ''
      this.lenddate = ''
      this.lclausetopics = false
    },

    saveDocument() {
      const formdata = {
        docname: this.ldocname,
        docdescription: this.ldocdescription,
        docparty: this.ldocparty,
        doccounterparty: this.ldoccounterparty,
        docother: '',
        docallowcollclauselabels: this.ldocallowcollclauselabels,
        docenableNegotiation: this.ldocenableNegotiation,
      }

      // if already saved, do an update
      if (this.localpanelstate.ifSaved) {
        this.$emit('updatedocmeta', formdata)
      } else {
        // save new document.
        this.$emit('save-document', formdata)
      }
      this.setNullValues()
      this.$emit('close-dialog')
    },

    saveArchiveDocument() {
      const formdata = {
        docname: this.ldocname,
        docdescription: this.ldocdescription,
        docparty: this.ldocparty,
        doccounterparty: this.ldoccounterparty,
        archivenotes: this.larchivenotes,
        executedcontract: this.lexecutedcontract,
        executeddate:
          this.noexecdate || !this.lexecutedcontract ? '' : this.lexecuteddate,
        startdate:
          this.nostartdate || !this.lexecutedcontract ? '' : this.lstartdate,
        enddate: this.noenddate || !this.lexecutedcontract ? '' : this.lenddate,
        clausetopics: this.lclausetopics,
      }

      this.$emit('update-archivedocument', formdata)

      this.setNullValues()
      this.$emit('close-dialog')
    },

    uploadExecutedContract() {
      this.showUpload = true
    },

    downloadExecutedContract() {
      getExecuteContract(this.localpanelstate.docmeta.docid)
    },

    uploadContract(e) {
      // do something with the contract.
      this.progression = true

      // do the upload
      // first, write the file to a cloud location
      const fileName = this.createFileName(e)
      const MyThis = this

      STORwriteFile(fileName, e).then(function(downloadURL) {
        // after file is uploaded, log it for the archive document
        MyThis.$store.dispatch('dbdocmeta/attachContracttoArchiveDocMeta', {
          filename: fileName,
          downloadURL: downloadURL,
          ename: e.name,
          docid: MyThis.localpanelstate.docmeta.docid,
          uniqid: MyThis.dbauthuser.uniqid,
        })
      })

      this.progression = false
      this.showUpload = false
    },

    docroute(d) {
      return docroute(d)
    },

    createFileName(e) {
      const date = new Date()
      return (
        this.$t('DIALOGS.EXECUTEDCONTRACT') +
        '/' +
        date.toISOString().slice(0, 10) +
        '/' +
        this.dbauthuser.uniqid +
        ':' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds() +
        ':' +
        e.name
      )
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="900px">
      <v-card>
        <PanelCardTitle :cardtitle="title"></PanelCardTitle>
        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="ldocname"
                    :rules="[rules.required, rules.counter80]"
                    :label="$t('DIALOGS.DOCNAME')"
                    required
                    :readonly="!enableSave"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldocdescription"
                    :rules="[rules.counter160]"
                    :label="$t('DIALOGS.DOCDESCRIPTION')"
                    :readonly="!enableSave"
                  ></v-text-field>
                </v-col>

                <v-col
                  v-if="
                    documentType === 'DOCUMENT' || documentType === 'ARCHIVEDOC'
                  "
                  cols="12"
                >
                  <v-text-field
                    v-model="ldocparty"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.DOCPARTY')"
                    :readonly="!enableSave"
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="
                    documentType === 'DOCUMENT' || documentType === 'ARCHIVEDOC'
                  "
                  cols="12"
                >
                  <v-text-field
                    v-model="ldoccounterparty"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.DOCCOUNTERPARTY')"
                    :readonly="!enableSave"
                  ></v-text-field>
                </v-col>
                <v-col v-if="documentType === 'DOCUMENT'" cols="12">
                  <v-checkbox
                    v-model="ldocallowcollclauselabels"
                    :label="$t('DIALOGS.ENABLECOLLABTOMODLABELS')"
                    :disabled="!enableSave"
                  ></v-checkbox>
                </v-col>
                <v-col v-if="documentType === 'DOCUMENT'">
                  <v-checkbox
                    v-model="ldocenableNegotiation"
                    :label="$t('DIALOGS.ENABLENEGOTCAPABILITIES')"
                    :disabled="!enableSave"
                  ></v-checkbox>
                </v-col>

                <v-col v-if="docFromTemplate" cols="12">
                  <v-text-field
                    v-model="templateorigdocname"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.TEMPLATEORIGDOCNAME')"
                    readonly
                  ></v-text-field>
                </v-col>

                <div v-if="documentType === 'TEMPLATE'">
                  <v-container fluid>
                    <v-row justify="center">
                      <v-simple-table :class="$style.dialogTable" fixed-header>
                        <template>
                          <thead
                            :class="$style.dialogHead"
                            class="sticky-header grey lighten-3"
                            fixed-header
                          >
                            <tr :class="$style.dialogHeader">
                              <th
                                style="font-size:16px"
                                :class="$style.dialogCellWidth"
                                >{{ $t('DIALOGS.FIELDNAME') }}</th
                              >

                              <th
                                style="font-size:16px"
                                :class="$style.dialogBigCellStatus"
                                >{{ $t('DIALOGS.DESCRIPTION') }}</th
                              >

                              <th
                                style="font-size:16px"
                                :class="$style.dialogCellStatus"
                                >{{ $t('DIALOGS.EXAMPLE') }}</th
                              >
                              <th
                                style="font-size:16px"
                                :class="$style.dialogCellStatus"
                                >{{ $t('DIALOGS.DEFAULT') }}</th
                              >
                            </tr>
                          </thead>
                          <tbody :class="$style.dialogBody">
                            <tr
                              v-for="item in labelinputdata"
                              :key="item.name"
                              :class="$style.dialogRow"
                            >
                              <td :class="$style.dialogCellWidth">{{
                                item.name
                              }}</td>

                              <td :class="$style.dialogBigCellStatus">
                                {{ item.description }}</td
                              >

                              <td :class="$style.dialogCellStatus">
                                {{ item.example }}</td
                              >
                              <td :class="$style.dialogCellStatus">
                                {{ item.default }}</td
                              >
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-row>
                  </v-container>
                </div>

                <div v-if="documentType === 'ARCHIVEDOC'">
                  <v-col cols="12">
                    <v-checkbox
                      v-model="lclausetopics"
                      :label="$t('DIALOGS.REVIEWEDCLAUSETOPICS')"
                      :disabled="!enableSave"
                    ></v-checkbox>
                  </v-col>
                  <v-flex xs12 md12 lg12 xl12>
                    <v-textarea
                      v-model="larchivenotes"
                      :placeholder="$t('DIALOGS.ARCHIVENOTES')"
                      outlined
                      solo
                      :disabled="!enableSave"
                      :label="$t('DIALOGS.ARCHIVENOTES')"
                    >
                      ></v-textarea
                    >
                  </v-flex>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="lexecutedcontract"
                      :label="$t('DIALOGS.EXECUTEDCONTRACT')"
                      :disabled="!enableSave"
                    ></v-checkbox>
                  </v-col>

                  <div v-if="lexecutedcontract">
                    <v-row>
                      <v-col cols="4" sm="4">
                        <p> Execution Date: </p>
                        <v-checkbox
                          v-model="noexecdate"
                          :label="$t('DIALOGS.NOEXECUTIONDATE')"
                        >
                        </v-checkbox>
                        <v-date-picker
                          v-if="!noexecdate"
                          v-model="lexecuteddate"
                          :label="$t('DIALOGS.EXECUTIONDATE')"
                          color="green lighten-1"
                        ></v-date-picker>
                      </v-col>
                      <v-col cols="4" sm="4">
                        <p> Contract Start Date: </p>
                        <v-checkbox v-model="nostartdate" label="No Start Date">
                        </v-checkbox>
                        <v-date-picker
                          v-if="!nostartdate"
                          v-model="lstartdate"
                          :label="$t('DIALOGS.CONTRACTSTARTDATE')"
                          color="green lighten-1"
                        ></v-date-picker>
                      </v-col>

                      <v-col cols="4" sm="4">
                        <p> Contract End Date: </p>
                        <v-checkbox v-model="noenddate" label="No End Date">
                        </v-checkbox>
                        <v-date-picker
                          v-if="!noenddate"
                          v-model="lenddate"
                          :label="$t('DIALOGS.CONTRACTENDDATE')"
                          color="green lighten-1"
                        ></v-date-picker>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <v-col v-if="contractattached" cols="12">
                  <v-row>
                    <v-col cols="6" sm="6">
                      <v-text-field
                        v-model="contractename"
                        :label="$t('DIALOGS.EXECCONTRACTNAME')"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4">
                      <v-btn
                        large
                        outlined
                        class="mx-4"
                        @click="downloadExecutedContract"
                        >DOWNLOAD EXECUTED CONTRACT</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <div v-if="showUpload">
          <v-container>
            <v-row class="my-n6">
              <v-col cols="12" sm="11" md="11">
                <v-file-input
                  :label="$t('DIALOGS.SELECTEXECCONTRACT')"
                  @change="uploadContract"
                >
                </v-file-input>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <v-progress-circular
                  v-if="progresson"
                  class="mt-3"
                  indeterminate
                  color="blue"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('file/savedocument')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="archiveOwner"
            large
            outlined
            class="mx-4"
            @click="uploadExecutedContract"
            >{{ $t('DIALOGS.UPLOADEXECUTEDCONTRACT') }}</v-btn
          >

          <v-btn
            v-if="archiveOwner && documentType === 'ARCHIVEDOC'"
            large
            outlined
            class="mx-4"
            @click="saveArchiveDocument"
            >{{ $t('DIALOGS.SAVEARCHIVE') }}</v-btn
          >
          <v-btn
            v-if="enableSave && !archiveOwner && documentType === 'DOCUMENT'"
            large
            outlined
            :disabled="!valid"
            class="mx-4"
            @click="saveDocument"
            >{{ $t('DIALOGS.SAVE') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
