var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"py-3",attrs:{"x-small":"","text":""},on:{"click":_vm.changelcompressed}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v(_vm._s(_vm.icontype))])],1),(_vm.turnOn && _vm.collabAvailPriv && !(_vm.compressed && _vm.collabcommentn === 0))?_c('v-chip',{class:{
      'grey lighten-2': _vm.collabcommentn === 0,
      'blue darken-3': _vm.collabcommentn > 0,
    },attrs:{"text-color":"white"},on:{"click":_vm.showCollabCommentsPanel}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-comment-text-multiple-outline")]),_vm._v(" "+_vm._s(_vm.collabcommentn)+" ")],1):_vm._e(),(_vm.turnOn && _vm.collabAvailPriv && !(_vm.compressed && _vm.collabdoceditn === 0))?_c('v-chip',{class:{
      'grey lighten-2': _vm.collabdoceditn === 0,
      'green darken-3': _vm.collabdoceditn > 0,
    },attrs:{"text-color":"white"},on:{"click":_vm.showCollabDocEditsPanel}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-account-edit-outline")]),_vm._v(" "+_vm._s(_vm.collabdoceditn)+" ")],1):_vm._e(),(_vm.turnOn && _vm.tagsAvailPriv && !(_vm.compressed && _vm.labeln + _vm.riskn === 0))?_c('v-chip',{class:{
      'grey lighten-2': _vm.labeln === 0 && _vm.riskn === 0,
      'green darken-1': _vm.resolven > 0,
      'red darken-2': _vm.riskn > 0 && _vm.resolven === 0,
      'yellow darken-2': _vm.labeln > 0 && _vm.riskn === 0 && _vm.resolven === 0,
    },attrs:{"text-color":"white"},on:{"click":_vm.showTagsPanel}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.labeln + _vm.riskn)+" ")],1):_vm._e(),(
      _vm.turnOn && !(_vm.compressed && _vm.negotcommentn === 0) && _vm.ifNegotiationAvail
    )?_c('v-chip',{class:{
      'grey lighten-2': _vm.negotcommentn === 0,
      'blue darken-3': _vm.negotcommentn > 0,
      'mt-1': true,
    },attrs:{"label":"","text-color":"white"},on:{"click":_vm.showNegotCommentsPanel}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-comment-text-multiple-outline")]),_vm._v(" "+_vm._s(_vm.negotcommentn)+" ")],1):_vm._e(),(
      _vm.turnOn && !(_vm.compressed && _vm.negotdoceditn === 0) && _vm.ifNegotiationAvail
    )?_c('v-chip',{class:{
      'grey lighten-2': _vm.negotdoceditn === 0,
      'green darken-3': _vm.negotdoceditn > 0,
    },attrs:{"label":"","text-color":"white"},on:{"click":_vm.showNegotDocEditsPanel}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-account-edit-outline")]),_vm._v(" "+_vm._s(_vm.negotdoceditn)+" ")],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }