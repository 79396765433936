<script>
import { dbauthComputed } from '@state/helpers'

import {
  formatDateDialog,
  displayUserName,
  displayUserInfo,
  docroute,
} from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },

    // docnotes
    docnotes: {
      type: Array,
      required: true,
    },
  },
  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  data: function() {
    return {
      note: '',
    }
  },

  computed: {
    ...dbauthComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
  },

  methods: {
    // relay to use utility functions.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },

    addDocNote() {
      this.$emit('add-docnote', this.note)
      this.note = ''
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },
    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.DOCUMENTNOTES')"
        ></PanelCardTitle>
        <div v-if="!localpanelstate.ifDocLoaded">
          <p>
            No document loaded!
          </p>
        </div>
        <div v-if="localpanelstate.ifDocLoaded">
          <v-form>
            <v-container fluid>
              <v-row>
                <v-simple-table :class="$style.dialogOpenTable" fixed-header>
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                      fixed-header
                    >
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.USER') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogRowTextWidth"
                          >{{ $t('DIALOGS.NOTE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.TIME') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in docnotes"
                        :key="
                          item.uniqid +
                            item.action +
                            formatDateDialog(item.timestamp)
                        "
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus"
                          ><v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">{{
                                displayUserName(item.uniqid)
                              }}</span>
                            </template>
                            <span>{{ displayUserInfo(item.uniqid) }}</span>
                          </v-tooltip></td
                        >
                        <td :class="$style.dialogRowTextWidth">{{
                          item.note
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          formatDateDialog(item.timestamp)
                        }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <div v-if="!localpanelstate.ifArchivedState">
          <v-container align-center>
            <v-flex xs12 md12 lg12 xl12>
              <v-textarea
                v-model="note"
                :placeholder="$t('DIALOGS.INPUTDOCUMENTNOTEHERE')"
                outlined
                clearable
                solo
                :class="$style.dialogInputText"
                label="Note"
              >
                ></v-textarea
              >
            </v-flex>
          </v-container>
        </div>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('edit/documentnotes')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <div class="mx-5">
            {{ $t('INFO_MESSAGES.COLLABORATIONNOTE') }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!localpanelstate.ifArchivedState"
            large
            outlined
            class="mx-4"
            @click="addDocNote"
            >{{ $t('DIALOGS.SAVE') }}</v-btn
          >

          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
