<script>
import { dbauthComputed } from '@state/helpers'

import { getCategoryNames, getIDFromCategoryName } from '@utils/clauseMapping'
import {
  formatDateDialog,
  displayUserName,
  displayUserInfo,
  compareArrays,
  docroute,
} from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'
import ConfirmAction from '@components/panel/para-dialog-confirmaction.vue'

// capabilities
// add new alert
// delete alert
// assign one group name and one or more document type to each alert (for the alert to be active) and one or more topics for alert to be active.
// add owners
// delete owners
// add and delete doctypes
// add and delete topics
// add and delete users (subset of owners and collaborators)
// set topic selectivity

export default {
  components: {
    ConfirmAction,
    PanelCardTitle,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  data: function() {
    return {
      // email: '', // for adding a new person.
      // valid: false, // for validating if email is valid

      focusAlertItem: { ownerid: [], uniqid: [] }, // this is set when a button is pushed.
      addMode: false,
      showLog: false,

      // dialogs for confirming deletion operations
      confirmDelete: false,
      confirmDeleteitem: null,
      confirmDeleteRecipient: false,

      group: '',
      select: '',

      // add alert
      alertname: '',
      description: '',

      // availTopicSelectivity - list of 4 only, defaults to Strict/0 (ok) hardcoded here.
      selectivity: 'STRICT',
      itemlookup: [
        { label: 'STRICT', value: 0 },
        { label: 'LOW', value: 1 },
        { label: 'MEDIUM', value: 2 },
        { label: 'HIGH', value: 3 },
      ],
      items: ['STRICT', 'LOW', 'MEDIUM', 'HIGH'],

      rules: {
        required: (value) => !!value || this.$t('VALIDMSGS.REQUIRED'),
        counter80: (value) =>
          value.length <= 80 || this.$t('VALIDMSGS.MAX80CHARS'),
        counter160: (value) =>
          value.length <= 160 || this.$t('VALIDMSGS.MAX160CHARS'),
      },
      validAdd: false, // check for validation rules
    }
  },

  computed: {
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
        // this.email = ''
      },
    },

    // availGroups - only owners of groups (ok)
    availGroups() {
      let returnGroups = this.dbauthGroupsAccess
      return (
        returnGroups
          // .filter((e) => e.ownerid.includes(this.dbauthuser.uniqid))
          .filter((e) => e.active === true)
      )
    },

    // availOwnerAlerts - get all alerts that they are an owner (ok)
    availOwnerAlerts() {
      let returnOwnerAlerts = this.dbauthOwnerAlerts
      returnOwnerAlerts = returnOwnerAlerts.filter((e) => e.active === true)
      return returnOwnerAlerts
    },
  },
  methods: {
    // ...dbalertComputed,

    refresh() {
      setTimeout(this.refreshAlerts, 2000)
    },

    refreshAlerts() {
      this.$store.dispatch('dbauth/getUserOwnerAlertsAuth')
    },

    // relay to use utility functions.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    compareArrays(d, e) {
      return compareArrays(d, e)
    },
    docroute(d) {
      return docroute(d)
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },

    getAlertLog(alertitem) {
      this.focusAlertItem = alertitem
      this.showLog = !this.showLog
    },

    lookup(selectivity) {
      return this.itemlookup.filter((e) => e.value === selectivity)[0].label
      // return { label: 'HIGH', value: 3 }
    },

    rlookup(selectivitytext) {
      return this.itemlookup.filter((e) => e.label === selectivitytext)[0].value
      // return { label: 'HIGH', value: 3 }
    },

    // given groupid, gives group name
    getGroup(groupid) {
      let groups = this.availGroups
      var objIndex = groups.findIndex((obj) => obj.keyid === groupid)
      if (objIndex >= 0) {
        return groups[objIndex]
      } else {
        return groupid
      }
    },

    // given doctypeid, gives doctype
    getDocTypeInfo(doctypeid) {
      let doctypes = this.dbauthDocTypes
      var objIndex = doctypes.findIndex((obj) => obj.keyid === doctypeid)
      if (objIndex >= 0) {
        return doctypes[objIndex]
      } else {
        return doctypeid
      }
    },

    // availTopics - core list of topics + addlclauses from DocTypes (ok)
    // for a given groupid, gives all topics available across all doctypes in the groupid.
    availTopics(groupid) {
      let topics = getCategoryNames()
      let group = this.getGroup(groupid)
      let doctypes = this.dbauthDocTypes

      group.doctypeid.forEach(function(e) {
        var objIndex = doctypes.findIndex((obj) => obj.keyid === e)
        if (objIndex >= 0) {
          topics = topics.concat(doctypes[objIndex].addlclauses)
        }
      })
      return topics
    },

    // availUniqID - owners and collaborators in Groups (ok)
    availUniqID(groupid) {
      let group = this.getGroup(groupid)
      if (typeof group !== 'undefined') {
        if (group.ownerid !== undefined) {
          return group.ownerid.concat(group.uniqid)
        }
      } else {
        return []
      }
    },

    // availDocTypes - only owners of Doctypes associated with Groups
    availDocTypes(groupid) {
      let returnArray = []
      let doctypeids = this.getGroup(groupid).doctypeid
      if (typeof doctypeids !== 'undefined') {
        doctypeids.forEach((e) =>
          returnArray.push(this.getDocTypeInfo(e).doctype)
        )
      }
      return returnArray
    },

    // special variant of above method that compares the doctype name (only used for Doctype)
    compareArraysDocType(subarray, allarray) {
      var addArray = []
      var delArray = []
      var position = -1
      var MyThis = this
      allarray.forEach(function(e) {
        if (typeof subarray === 'undefined') {
          position = -1
        } else {
          position = subarray.findIndex((f) => MyThis.getDocTypeName(f) === e)
        }
        if (position < 0) {
          addArray.push(e)
        } else {
          delArray.push(e)
        }
      })
      return { allarray: allarray, addArray: addArray, delArray: delArray }
    },

    // given alertid, gives name
    getAlertName(alertid) {
      let alerts = this.availOwnerAlerts
      var objIndex = alerts.findIndex((obj) => obj.keyid === alertid)
      if (objIndex >= 0) {
        return alerts[objIndex].alert
      } else {
        return alertid
      }
    },

    // given groupid, gives the name of group
    getGroupName(groupid) {
      let groups = this.availGroups
      var objIndex = groups.findIndex((obj) => obj.keyid === groupid)
      if (objIndex >= 0) {
        return groups[objIndex].group
      } else {
        return groupid
      }
    },

    // given doctypeid, will give the doctype name
    getDocTypeName(doctypeid) {
      let doctypes = this.dbauthDocTypes
      var objIndex = doctypes.findIndex((obj) => obj.keyid === doctypeid)
      if (objIndex >= 0) {
        return doctypes[objIndex].doctype
      } else {
        return doctypeid
      }
    },

    // reverse function - given the doctype name, will give the ID.
    getDocTypeID(doctypename) {
      let doctypes = this.dbauthDocTypes
      var objIndex = doctypes.findIndex((obj) => obj.doctype === doctypename)
      if (objIndex >= 0) {
        return doctypes[objIndex].keyid
      } else {
        return doctypename
      }
    },

    // methods for each of the different functions.
    addAlert() {
      if (this.validAdd && this.select.keyid !== undefined) {
        this.$store.dispatch('dbalert/writeAlert', {
          uniqid: this.dbauthuser.uniqid,
          alert: this.alertname,
          description: this.description,
          groupid: this.select.keyid,
          ownerid: this.dbauthuser.uniqid,
        })

        this.refresh()
        // flip it back to list
        this.addMode = !this.addMode
      }

      // set the input to blanks
      this.alertname = ''
      this.description = ''
    },

    deleteAlertAct(alertitem) {
      this.confirmDeleteitem = alertitem
      this.confirmDelete = true
    },

    confirmedDeleteAlertAct() {
      this.$store.dispatch('dbalert/deleteAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: this.confirmDeleteitem.keyid,
      })

      this.refresh()
    },

    addOwnerAlertAct(alertitem, item) {
      this.$store.dispatch('dbalert/addOwnerAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        ownerid: item,
      })

      this.refresh()
    },

    deleteOwnerAlertAct(alertitem, ownerid) {
      this.$store.dispatch('dbalert/deleteOwnerAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        ownerid: ownerid,
      })

      this.refresh()
    },

    addDocTypeAlertAct(alertitem, doctypeid) {
      this.$store.dispatch('dbalert/addDocTypeAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        doctypeid: doctypeid,
      })

      this.refresh()
    },

    deleteDocTypeAlertAct(alertitem, doctypeid) {
      this.$store.dispatch('dbalert/deleteDocTypeAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        doctypeid: doctypeid,
      })

      this.refresh()
    },

    addClauseTopicAlertAct(alertitem, clausetopic) {
      this.$store.dispatch('dbalert/addClauseTopicAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        clausetopic: clausetopic,
        uid: getIDFromCategoryName(clausetopic),
      })

      this.refresh()
    },

    deleteClauseTopicAlertAct(alertitem, clausetopic) {
      this.$store.dispatch('dbalert/deleteClauseTopicAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        clausetopic: clausetopic,
        uid: getIDFromCategoryName(clausetopic),
      })

      this.refresh()
    },

    addRecipientAlertAct(alertitem, uniqidadd) {
      // no need to check on validly since it's only available to the listed participants.

      this.$store.dispatch('dbalert/addRecipientAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        uniqidadd: uniqidadd,
      })

      this.refresh()
    },

    deleteRecipientAlertAct(alertitem, uniqiddel) {
      this.$store.dispatch('dbalert/deleteRecipientAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        uniqiddel: uniqiddel,
      })

      this.refresh()
    },

    // this is used to remove self from an established Group.
    // Group owner can add a participant as collaborator but collaborator can delete themselves as well.
    removeRecipientAlertAct(alertitem) {
      this.confirmDeleteRecipientitem = alertitem
      this.confirmDeleteRecipient = true
    },

    confirmedDeleteRecipientAlertAct() {
      this.$store.dispatch('dbalert/deleteRecipientAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: this.confirmDeleteRecipientitem.keyid,
        uniqiddel: this.dbauthuser.uniqid,
      })

      this.refresh()
    },

    addNotificationRuleAlertAct(alertitem, notificationrule) {
      this.$store.dispatch('dbalert/addNotificationRuleAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        notificationrule: notificationrule,
      })

      this.refresh()
    },

    deleteNotificationRuleAlertAct(alertitem, notificationrule) {
      this.$store.dispatch('dbalert/deleteNotificationRuleAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        notificationrule: notificationrule,
      })

      this.refresh()
    },

    setSelectivitityAlertAct(alertitem, selectivity) {
      this.$store.dispatch('dbalert/setSelectivitityAlert', {
        uniqid: this.dbauthuser.uniqid,
        alertid: alertitem.keyid,
        selectivity: selectivity,
      })

      this.refresh()
    },

    displayAction(action) {
      // backward compartible
      if (action.includes(' ')) {
        return action
      }
      return this.$t(action)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <ConfirmAction
      :dialog="confirmDelete"
      :confirmationmessage="$t('INFO_MESSAGES.CONFIRMDELETEALERT')"
      @close-dialog="confirmDelete = false"
      @confirm="confirmedDeleteAlertAct"
    />
    <ConfirmAction
      :dialog="confirmDeleteRecipient"
      :confirmationmessage="$t('INFO_MESSAGES.CONFIRMDELETERECIPIENTALERT')"
      @close-dialog="confirmDeleteRecipient = false"
      @confirm="confirmedDeleteRecipientAlertAct"
    />
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <PanelCardTitle :cardtitle="$t('DIALOGS.ALERTS')"></PanelCardTitle>
        <div v-if="!addMode">
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-simple-table :class="$style.dialogTable" fixed-header>
                  <template>
                    <thead :class="$style.dialogHead">
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.PRIVILEGE') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellHeading"
                        ><b>{{ $t('DIALOGS.ALERTNAME') }}</b></th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ALERTDESC') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.GROUPNAME') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.LOG') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.DELETE') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.OWNERS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.DOCTYPES') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.RECIPIENTS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.TOPICS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.SELECTIVITY') }}</th
                      >
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="alertitem in availOwnerAlerts"
                        :key="alertitem.alertid"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus">{{
                          alertitem.privilege
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          alertitem.alert
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          alertitem.description
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          getGroupName(alertitem.groupid)
                        }}</td>

                        <td :class="$style.dialogCellStatus"
                          ><v-btn small icon @click="getAlertLog(alertitem)">
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn></td
                        >
                        <td :class="$style.dialogCellStatus"
                          ><v-btn
                            v-if="alertitem.privilege === 'owner'"
                            small
                            text
                            @click="deleteAlertAct(alertitem)"
                          >
                            <v-icon>mdi-delete</v-icon> </v-btn
                          ><v-btn
                            v-if="alertitem.privilege !== 'owner'"
                            small
                            text
                            @click="removeRecipientAlertAct(alertitem)"
                          >
                            <v-icon>mdi-close</v-icon></v-btn
                          ></td
                        >
                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item
                              v-for="owner in alertitem.ownerid"
                              :key="owner"
                            >
                              <v-list-item-subtitle
                                ><v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{
                                      displayUserName(owner)
                                    }}</span>
                                  </template>
                                  <span>{{ displayUserInfo(owner) }}</span>
                                </v-tooltip>
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="alertitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    alertitem.ownerid,
                                    availUniqID(alertitem.groupid)
                                  ).addArray"
                                  :key="item"
                                  @click="addOwnerAlertAct(alertitem, item)"
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                            <!-- <v-btn
                              v-if="alertitem.privilege === 'owner'"
                              small
                              class="white--text black"
                              @click="addOwnerAlertAct(alertitem)"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn> -->
                          </v-row>
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="alertitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="ownerid in alertitem.ownerid"
                                  :key="ownerid"
                                  @click="
                                    deleteOwnerAlertAct(alertitem, ownerid)
                                  "
                                >
                                  <v-list-item-subtitle
                                    >{{ ownerid }}
                                  </v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item
                              v-for="item in alertitem.doctypeid"
                              :key="item"
                            >
                              <v-list-item-subtitle
                                >{{ getDocTypeName(item) }}
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="alertitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArraysDocType(
                                    alertitem.doctypeid,
                                    availDocTypes(alertitem.groupid)
                                  ).addArray"
                                  :key="item"
                                  @click="
                                    addDocTypeAlertAct(
                                      alertitem,
                                      getDocTypeID(item)
                                    )
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="alertitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArraysDocType(
                                    alertitem.doctypeid,
                                    availDocTypes(alertitem.groupid)
                                  ).delArray"
                                  :key="item"
                                  @click="
                                    deleteDocTypeAlertAct(
                                      alertitem,
                                      getDocTypeID(item)
                                    )
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item
                              v-for="item in alertitem.uniqid"
                              :key="item"
                            >
                              <v-list-item-subtitle
                                ><v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{
                                      displayUserName(item)
                                    }}</span>
                                  </template>
                                  <span>{{ displayUserInfo(item) }}</span>
                                </v-tooltip>
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="alertitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    alertitem.uniqid,
                                    availUniqID(alertitem.groupid)
                                  ).addArray"
                                  :key="item"
                                  @click="addRecipientAlertAct(alertitem, item)"
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="alertitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    alertitem.uniqid,
                                    availUniqID(alertitem.groupid)
                                  ).delArray"
                                  :key="item"
                                  @click="
                                    deleteRecipientAlertAct(alertitem, item)
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item
                              v-for="item in alertitem.clausetopic"
                              :key="item"
                            >
                              <v-list-item-subtitle
                                >{{ item }}
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="alertitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    alertitem.clausetopic,
                                    availTopics(alertitem.groupid)
                                  ).addArray"
                                  :key="item"
                                  @click="
                                    addClauseTopicAlertAct(alertitem, item)
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="alertitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    alertitem.clausetopic,
                                    availTopics(alertitem.groupid)
                                  ).delArray"
                                  :key="item"
                                  @click="
                                    deleteClauseTopicAlertAct(alertitem, item)
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>
                        <!--  -->
                        <td :class="$style.dialogCellStatus">
                          {{ lookup(alertitem.selectivity) }}
                          <v-menu offset-x>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-btn
                                v-if="alertitem.privilege === 'owner'"
                                small
                                class="white--text black"
                                v-on="{ ...tooltip }"
                              >
                                <v-icon>mdi-delta</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="item in items"
                                :key="item"
                                @click="
                                  setSelectivitityAlertAct(
                                    alertitem,
                                    rlookup(item)
                                  )
                                "
                              >
                                <v-list-item-subtitle>{{
                                  item
                                }}</v-list-item-subtitle>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-card-text>
          <div v-if="showLog">
            <p>
              Alert Log:
            </p>
            <p>Alert Name: {{ focusAlertItem.alert }} </p>
            <p>Alert Description: {{ focusAlertItem.description }} </p>
            <p>Group Name: {{ getGroupName(focusAlertItem.groupid) }} </p>
            <p>Owners:</p>
            <ul>
              <li v-for="owner in focusAlertItem.ownerid" :key="owner">
                {{ owner }}
              </li>
            </ul>
            <v-container>
              <v-row>
                <v-simple-table :class="$style.dialogOpenTable" fixed-header>
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                      fixed-header
                    >
                      <tr :class="$style.dialogHeader">
                        <th :class="$style.dialogCellStatus">{{
                          $t('DIALOGS.USER')
                        }}</th>
                        <th :class="$style.dialogCellStatus">{{
                          $t('DIALOGS.ACTION')
                        }}</th>

                        <th :class="$style.dialogCellWidth">{{
                          $t('DIALOGS.CONTEXT')
                        }}</th>
                        <th :class="$style.dialogBigCellStatus">{{
                          $t('DIALOGS.TIME')
                        }}</th>
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in focusAlertItem.log"
                        :key="
                          item.uniqid + item.action + item.timestamp.toString()
                        "
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus">{{
                          item.uniqid
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          displayAction(item.action)
                        }}</td>

                        <td :class="$style.dialogCellWidth">{{
                          item.context
                        }}</td>
                        <td :class="$style.dialogBigCellStatus">{{
                          formatDateDialog(item.timestamp)
                        }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </div>

          <!-- <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model.trim="email"
                    :rules="emailrules"
                    :label="$t('DIALOGS.EMAILTOADD')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form> -->
        </div>
        <div v-if="addMode">
          <v-form v-model="validAdd">
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model.trim="alertname"
                    :rules="[rules.required, rules.counter80]"
                    :label="$t('DIALOGS.ALERTNAME')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="description"
                    :rules="[rules.counter160]"
                    :label="$t('DIALOGS.ALERTDESC')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-select
                    v-model="select"
                    :hint="`${select.group}, ${select.description}`"
                    :items="availGroups"
                    item-text="group"
                    item-value="keyid"
                    :label="$t('DIALOGS.GROUPNAME')"
                    persistent-hint
                    return-object
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>

        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('admin/alerts')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer
          ><v-btn v-if="true" large outlined class="mx-4" @click="refresh">{{
            $t('DIALOGS.REFRESH')
          }}</v-btn>

          <v-btn
            v-if="showLog"
            large
            outlined
            class="mx-4"
            @click="showLog = !showLog"
            >HIDE LOG</v-btn
          >
          <v-btn v-if="addMode" large outlined class="mx-4" @click="addAlert">{{
            $t('DIALOGS.SAVE')
          }}</v-btn>

          <v-btn
            v-if="!addMode"
            large
            outlined
            class="mx-4"
            @click="addMode = !addMode"
            >{{ $t('DIALOGS.ADDNEWALERT') }}</v-btn
          >
          <v-btn
            v-if="addMode"
            large
            outlined
            class="mx-4"
            @click="addMode = !addMode"
            >{{ $t('DIALOGS.GROUPSLIST') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
