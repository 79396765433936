<script>
import { panelComputed, dbauthComputed, dbtagComputed } from '@state/helpers'

import PanelCardTitle from './panel-cardtitle.vue'

import {
  splitSentences,
  formatDateDialog,
  statusIndicator,
  displayUserName,
  displayUserInfo,
} from '@utils/dialogutils'

// for now, assume that taglist is for default and only contains high and low risk.
// const taglist = ['highrisk', 'lowrisk']

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    actionpara: {
      type: Object,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    showDeleted: false,
  }),
  computed: {
    ...panelComputed,
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },

    paragraphtext() {
      return this.actionpara.OT
    },
    paragraphindex() {
      return this.actionpara.ind
    },
    paragraphindexstr() {
      if (this.actionpara.ind !== undefined) {
        return this.actionpara.ind.toString()
      }
      return ''
    },
    sentences() {
      return splitSentences(this.actionpara.OT)
    },
    tags() {
      if (
        Array.isArray(this.editTags[this.panelno]) &&
        this.editTags[this.panelno].length > 0
      ) {
        if (this.showDeleted) {
          return this.editTags[this.panelno].filter(
            (e) => e.paraid === this.actionpara.ind
          )
        } else {
          return this.editTags[this.panelno]
            .filter((e) => e.paraid === this.actionpara.ind)
            .filter((e) => e.active === true)
        }
      } else {
        return []
      }
    },
    availTagsForDocType() {
      // if negotiator, does not have access to internal doctype information.
      if (this.localpanelstate.negotiatorPriv) {
        return []
      }

      let returnList = ['HIGH-RISK', 'NEEDS-REVIEW', 'RESOLVED']
      if (this.localpanelstate.ifDocType) {
        if (
          this.localpanelstate.docTypeDetailofDoc !== null &&
          this.localpanelstate.docTypeDetailofDoc !== undefined
        ) {
          let doctypetags = this.localpanelstate.docTypeDetailofDoc.addltags
          doctypetags.forEach((e) => returnList.push(e))
        }
      }
      return returnList
    },
  },
  methods: {
    ...dbtagComputed,
    addTag(tag) {
      this.writeTag({
        docid: this.aPIDjobID[this.panelno],
        paraid: this.paragraphindex,
        sentid: tag.sentid,
        senttext: this.sentences.find((e) => e.key === parseInt(tag.sentid))
          .sentence,
        tag: tag.tag,
        uniqid: this.dbauthuser.uniqid,
        active: true,
        ifHUBType: this.localpanelstate.ifHUBType,
      })
      this.tag = ''

      this.$emit('synctags')
      return true
    },
    deleteTagAct(tag) {
      this.deleteTag({
        uniqid: this.dbauthuser.uniqid,
        docid: this.aPIDjobID[this.panelno],
        tagid: tag.keyid,
        ifHUBType: this.localpanelstate.ifHUBType,
      })
      this.$emit('synctags')
      return true
    },

    // these functions call the utils - not sure why it's not picking up immediately.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    statusIndicator(bool) {
      return statusIndicator(bool)
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },
    // need to implement check to see if someone can delete a comment.
    deleteok(uniqid) {
      return this.dbauthuser.uniqid in uniqid
    },
    // tag, user, date
    tagsent(sentid) {
      if (
        this.editTags[this.panelno] !== null &&
        this.editTags[this.panelno] !== undefined
      ) {
        const tagssentArray = this.editTags[this.panelno]
          // para
          .filter((e) => e.paraid === this.actionpara.ind)
          .filter((e) => e.sentid === sentid.toString())
          .filter((e) => e.active === true)
        return tagssentArray
      } else {
        return []
      }
    },

    // returns the array of available tags to add or delete.
    tagsAvailable(sentid) {
      const tagSentArray = this.tagsent(sentid).filter(
        (e) => e.uniqid === this.dbauthuser.uniqid
      )
      var addArray = []
      var delArray = []
      var position = -1
      this.availTagsForDocType.forEach(function(e) {
        position = tagSentArray.findIndex((f) => f.tag === e)
        if (position < 0) {
          addArray.push({ tag: e, sentid: sentid.toString() })
        } else {
          delArray.push({
            tagobj: tagSentArray[position],
            sentid: sentid.toString(),
          })
        }
      })
      return { addArray: addArray, delArray: delArray }
    },
  },
}
</script>

<template>
  <v-row>
    <v-dialog
      v-model="showDialog"
      persistent
      :content-class="$style.dialogWidth"
    >
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.LABELSRISK')"
          :optnumber="paragraphindexstr"
        ></PanelCardTitle>
        <div>
          <v-form>
            <v-container>
              <v-row>
                <v-flex xs12>
                  <h5 :class="$style.dialogTextStyle">
                    {{ actionpara.OT }}
                  </h5>
                </v-flex>
                <v-simple-table :class="$style.dialogTable" fixed-header>
                  <template>
                    <thead :class="$style.dialogHead">
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.NUMBER') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogRowTextWidth"
                          >{{ $t('DIALOGS.SENTENCE') }}</th
                        >
                        <th style="font-size:16px" :class="$style.dialogTags">{{
                          $t('DIALOGS.LABELSRISK')
                        }}</th>
                        <th
                          v-if="!localpanelstate.ifArchivedState"
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.ACTIONS') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in sentences"
                        :key="item.key"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus">{{ item.key }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          item.sentence
                        }}</td>
                        <td :class="$style.dialogTags">
                          <v-list>
                            <v-list-item
                              v-for="tag in tagsent(item.key)"
                              :key="tag.keyid"
                            >
                              <v-list-item-title
                                >{{ tag.tag }} - by
                                {{ displayUserName(tag.uniqid) }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </td>
                        <td
                          v-if="!localpanelstate.ifArchivedState"
                          :class="$style.dialogCellStatus"
                        >
                          <v-row class="my-2">
                            <v-menu
                              v-if="tagsAvailable(item.key).addArray.length > 0"
                              offset-x
                            >
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item2 in tagsAvailable(item.key)
                                    .addArray"
                                  :key="item2.tag"
                                  @click="addTag(item2)"
                                >
                                  <v-list-item-title>{{
                                    item2.tag
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu
                              v-if="tagsAvailable(item.key).delArray.length > 0"
                              offset-x
                            >
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item2 in tagsAvailable(item.key)
                                    .delArray"
                                  :key="item2.tagobj.tag"
                                  @click="deleteTagAct(item2.tagobj)"
                                >
                                  <v-list-item-title>{{
                                    item2.tagobj.tag
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <v-card-actions class="justify-end">
          <div class="mx-5">
            {{ $t('INFO_MESSAGES.COLLABORATIONNOTE') }}
          </div>

          <v-spacer></v-spacer>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
