<script>
// IMPORTANT - THIS SCRIPT HAS NOT YET BEEN TESTED COMPLETELY

import PanelCardTitle from './panel-cardtitle.vue'

// state
import { panelComputed, dbauthComputed, docxmodMethods } from '@state/helpers'

import {
  formatDateDialog,
  docroute,
  displayUserName,
  comparePara,
} from '@utils/dialogutils'

import { STORwriteFile } from '@/src/state/io/iostorage.js'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },

    // FYI - Need to pass each field in individually - VuejS objects to mutability of objects and arrays.
    sigrequestactive: {
      type: Boolean,
      required: true,
    },
    sigrequestid: {
      type: String,
      required: true,
    },
    sigrequestuniqid: {
      type: String,
      required: true,
    },
    sigrequestcreate: {
      type: String,
      required: true,
    },
  },

  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  data: function() {
    return {
      valid: false,
      sigDetail: '',
      esigdirectory: '',

      overwriteprevious: 'overwrite',
      selected: 'OwnerAndNegotiator',
      localdocedits: [],
      files: [],

      // Globals
      ESIGNATUREAREA: 'esignatures',
      TWOBLANKS: '  ',
      IOstringdelimiter: '|||MLE|||',

      form: {
        doctitle: '',
        docsubject: '',
        docmessage: this.$t('DIALOGS.DEFAULTESIGNOTE'),
        docsigner1name: '',
        docsigner1email: '',
        docsigner2name: '',
        docsigner2email: '',
      },
      emailrules: [
        (v) => !!v || 'Email is required',
        // (v) => !!v || this.$t('VALIDMSGS.EMAILREQUIRED'),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          // this.$t('VALIDMSGS.EMAILVALID'),
          'Email must be valid',
        (v) => v.length <= 60 || this.$t('VALIDMSGS.MAX60CHARS'),
        // (v) => v.length <= 60 || 'Email must be less than 60 characters',
      ],
      namerules: [(v) => !!v || 'Name is required'],
      progressmsg: '',
    }
  },

  computed: {
    ...panelComputed,
    ...dbauthComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
    sigrequested() {
      if (this.sigrequestactive) {
        this.getSigDetails()
        return true
      }
      return false
    },

    // provides permissions for the authorization
    dbauthprivilege() {
      if (this.dbauthPrivileges === null) {
        return 'A'
      } else {
        return this.dbauthPrivileges.type
      }
    },
    eSignatureAvail() {
      return this.dbauthprivilege >= 'G7'
    },
    // Copied over from downloadredline
    seldocedits() {
      if (
        Array.isArray(this.editDocedits[this.panelno]) &&
        this.editDocedits[this.panelno].length > 0
      ) {
        return this.editDocedits[this.panelno].filter((e) => e.active === true)
      } else {
        return []
      }
    },

    filterdocedits() {
      var returnArray = []
      var index
      for (index in this.localdocedits) {
        if (this.selected === 'OwnerOnly') {
          if (this.localdocedits[index].approved) {
            returnArray.push(this.localdocedits[index])
          }
        } else if (this.selected === 'OwnerAndNegotiator') {
          if (
            this.localdocedits[index].approved &&
            this.localdocedits[index].negotiatorapproved
          ) {
            returnArray.push(this.localdocedits[index])
          }
        } else {
          returnArray.push(this.localdocedits[index])
        }
      }

      return returnArray.sort(comparePara)
    },

    filterparamods() {
      return this.filterdocedits.map((x) => x.paraid)
    },
    filtersentidmods() {
      return this.filterdocedits.map((x) => x.sentid)
    },

    filtermodsource() {
      return this.filterdocedits.map((x) => x.senttext)
    },
    filtermodtype() {
      return this.filterdocedits.map((x) => x.docedittype)
    },

    filtermodtarget() {
      var index
      var returnArray = []
      for (index in this.filterdocedits) {
        if (this.filterdocedits[index].docedittype === 'REPLACE') {
          returnArray.push(
            this.TWOBLANKS + this.filterdocedits[index].docedittext
          )
        } else if (this.filterdocedits[index].docedittype === 'DELETE') {
          returnArray.push(this.TWOBLANKS)
        } else if (this.filterdocedits[index].docedittype === 'ADD-AFTER') {
          // Added 2 spaces after ADD AFTER.
          returnArray.push(
            '<<ADDAFTER>>' +
              this.TWOBLANKS +
              this.filterdocedits[index].docedittext
          )
        } else {
          returnArray.push('MLE ERROR - MERGE ERROR')
        }
      }
      return returnArray
    },

    sizeDocEdits() {
      return this.filterdocedits.length
    },
    sizeDocEditsReplace() {
      return this.filterdocedits.filter((e) => e.docedittype === 'REPLACE')
        .length
    },
    sizeDocEditsDelete() {
      return this.filterdocedits.filter((e) => e.docedittype === 'DELETE')
        .length
    },
    sizeDocEditsAddAfter() {
      return this.filterdocedits.filter((e) => e.docedittype === 'ADD-AFTER')
        .length
    },
  },

  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues() // if (this.sigrequestactive) {
        //   this.getSigDetails()
        // }
      }
    },
  },
  methods: {
    ...docxmodMethods,

    setInitialValues() {
      this.localdocedits = this.seldocedits
      var defaultdocsigner1email = ''
      var defaultdocsigner1name = ''

      // Add a condition - if not in negotiation state, switch to Owner only as default
      if (!this.localpanelstate.ifNegotiationState) {
        this.selected = 'OwnerOnly'
      }

      if (
        this.localpanelstate.docmeta.externalnegotiators !== null &&
        this.localpanelstate.docmeta.externalnegotiators !== undefined &&
        this.localpanelstate.docmeta.externalnegotiators[0] !== undefined
      ) {
        defaultdocsigner1email = this.localpanelstate.docmeta
          .externalnegotiators[0]
        defaultdocsigner1name = this.displayUserName(
          this.localpanelstate.docmeta.externalnegotiators[0]
        )
      }

      this.form = {
        doctitle: this.localpanelstate.docmeta.docname,
        docsubject: 'ESignature for ' + this.localpanelstate.docmeta.docname,
        docmessage: this.$t('DIALOGS.DEFAULTESIGNOTE'),
        docsigner1name: defaultdocsigner1name,
        docsigner1email: defaultdocsigner1email,
        docsigner2name: this.displayUserName(this.dbauthuser.uniqid),
        docsigner2email: this.dbauthuser.uniqid,
      }

      // Also set the signature values if changed.
      if (this.sigrequestactive) {
        this.getSigDetails()
        this.$store.dispatch('signesignature/signGetRequestStatus', {
          sigrequestid: this.sigrequestid,
        })
        // Do again to catch updates
        setTimeout(this.getSigDetails, 5000)
      }
      this.progressmsg = ''
    },

    getSigDetails() {
      // Check if previously registered, if not let the user know.
      const MyThis = this

      this.$store
        .dispatch('signesignature/signGetRequestSig', {
          docid: this.sigrequestid,
        })
        .then(function(doc) {
          MyThis.sigDetail = doc.data
        })
    },

    refreshSigDetails() {
      this.$store.dispatch('signesignature/signGetRequestStatus', {
        sigrequestid: this.sigrequestid,
      })
      // Do again to catch updates
      setTimeout(this.getSigDetails, 10000)
    },

    cancelSignatureRequest() {
      const formdata = {
        docid: this.localpanelstate.docmeta.docid,
        uniqid: this.localpanelstate.dbauthuser.uniqid,
        sigrequestid: this.sigrequestid,
      }
      this.$emit('sign-cancelrequest', formdata)
      // Do it again to catch any updates
      setTimeout(this.setInitialValues, 6000)
    },

    signNewRequest() {
      if (this.valid) {
        const MyThis = this
        // This is the esignature directory for signing.
        MyThis.esigdirectory =
          MyThis.ESIGNATUREAREA +
          '/' +
          MyThis.localpanelstate.docmeta.docid +
          '/' +
          MyThis.createStringDateTime()

        MyThis.performFileUpload().then(function(finished) {
          MyThis.$store
            .dispatch('dbdocument/getDocument', {
              docid: MyThis.localpanelstate.docmeta.docid,
            })
            .then(function(doc) {
              MyThis.docxCreateFinal({
                urlname: doc.data.urlname,
                paramods: MyThis.filterparamods.join(MyThis.IOstringdelimiter),
                modsource: MyThis.filtermodsource.join(
                  MyThis.IOstringdelimiter
                ),
                modtarget: MyThis.filtermodtarget.join(
                  MyThis.IOstringdelimiter
                ),
                docid: MyThis.localpanelstate.docmeta.docid,
                uniqid: MyThis.localpanelstate.dbauthuser.uniqid,
                outputFileName: doc.data.filename.replace(
                  '.docx',
                  '-FINAL.docx'
                ),
                outputurlname:
                  MyThis.esigdirectory +
                  '/' +
                  doc.data.filename.replace('.docx', '-FINAL.docx'),
              }).then(function(donename) {
                MyThis.$emit('sign-newrequest', {
                  docid: MyThis.localpanelstate.docmeta.docid,
                  uniqid: MyThis.localpanelstate.dbauthuser.uniqid,
                  doctitle: MyThis.form.doctitle,
                  docsubject: MyThis.form.docsubject,
                  docmessage: MyThis.form.docmessage,
                  docsigner1name: MyThis.form.docsigner1name,
                  docsigner1email: MyThis.form.docsigner1email,
                  docsigner2name: MyThis.form.docsigner2name,
                  docsigner2email: MyThis.form.docsigner2email,
                  dirname: MyThis.esigdirectory,
                  create: '',
                })
                MyThis.progressmsg += MyThis.$t('DIALOGS.SUBMITTEDSIGMESSAGE')
                setTimeout(MyThis.getSigDetails, 10000)
              })
            })
        })
      }
    },

    createStringDateTime() {
      const date = new Date()
      return (
        date.toISOString().slice(0, 10) +
        ':' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds()
      )
    },

    // upload the files to the directory
    performFileUpload() {
      if (this.files.length === 0) {
        // allows returning a resolved promise to continue if no files selected.
        return Promise.resolve('none')
      }

      var finalUploadTask

      // for (let file in this.files) {
      for (var i = 0; i < this.files.length; i++) {
        var fileName =
          this.ESIGNATUREAREA +
          '/' +
          this.localpanelstate.docmeta.docid +
          '/' +
          this.createStringDateTime() +
          '/' +
          this.files[i].name

        // upload and wait (needs more testing)
        finalUploadTask = STORwriteFile(fileName, this.files[i])

        this.progressmsg +=
          this.$t('DIALOGS.UPLOADEDMESSAGE') + ':' + this.files[i].name + '  '
      }
      // returns the last uploadtask which then allows to wait on promise
      return finalUploadTask
    },

    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },

    docroute(d) {
      return docroute(d)
    },
    // relay to use utility functions.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1400px">
      <v-card>
        <PanelCardTitle :cardtitle="$t('DIALOGS.ESIGNATURE')"></PanelCardTitle>

        <div v-if="!eSignatureAvail" class="ma-10">
          <h3>
            {{ $t('DIALOGS.ESIGPREPAIDPLANS') }}
          </h3>
          <p></p>
          <h3>{{ $t('DIALOGS.ESIGPREPAIDPLANSCONTACT') }} </h3>
        </div>
        <div v-if="eSignatureAvail">
          <v-card-text>
            <div v-if="!sigrequestactive">
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.doctitle"
                        :label="$t('DIALOGS.DOCUMENTTITLE')"
                        required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.docsubject"
                        :label="$t('DIALOGS.DOCUMENTSUBJECT')"
                        required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="form.docmessage"
                        :label="$t('DIALOGS.SIGNMESSAGE')"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.docsigner1name"
                        :label="$t('DIALOGS.NAMEPRIMARYSIGNER')"
                        :rules="namerules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.docsigner1email"
                        :label="$t('DIALOGS.EMAILPRIMARYSIGNER')"
                        :rules="emailrules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.docsigner2name"
                        :label="$t('DIALOGS.NAMECOUNTERSIGNER')"
                        :rules="namerules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.docsigner2email"
                        :label="$t('DIALOGS.EMAILCOUNTERSIGNER')"
                        :rules="emailrules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
              <v-container>
                <v-row>
                  <v-col cols="7">
                    <h3 class="ma-1 mt-5"
                      >{{ $t('DIALOGS.OPTIONSFORREDLINEINCLUDE') }}:</h3
                    >
                    <div class="ma-5">
                      <v-radio-group v-model="selected" required row>
                        <v-radio
                          class="mt-2"
                          :label="
                            $t('DIALOGS.ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS')
                          "
                          value="OwnerAndNegotiator"
                        ></v-radio>
                        <v-radio
                          class="mt-2"
                          :label="$t('DIALOGS.ONLYINCLUDEOWNERAPPROVEDEDITS')"
                          value="OwnerOnly"
                        ></v-radio>

                        <v-radio
                          class="mt-2"
                          :label="$t('DIALOGS.INCLUDEALLDOCEDITS')"
                          value="All"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <h3 class="ma-1 mt-5"
                      >{{ $t('DIALOGS.REDLINECHANGESSELECTED') }}:
                      {{ sizeDocEdits }}</h3
                    >
                    <div class="ma-5 mt-8">
                      <div class="ma-5"
                        ><b>
                          <p></p>
                          <p
                            >{{ $t('DIALOGS.REPLACEREDLINES') }}:
                            {{ sizeDocEditsReplace }}
                          </p>
                          <p
                            >{{ $t('DIALOGS.DELETEREDLINES') }}:
                            {{ sizeDocEditsDelete }}
                          </p>
                          <p
                            >{{ $t('DIALOGS.ADDAFTERREDLINES') }}:
                            {{ sizeDocEditsAddAfter }}
                          </p></b
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>

              <h3 class="ma-1 mt-5">{{ $t('DIALOGS.ADDADDLFILES') }}:</h3>
              <div class="ma-5">
                <v-col cols="11">
                  <v-file-input
                    v-model="files"
                    accept=".pdf,.docx,image/*,.xlsx,.docx"
                    show-size
                    counter
                    multiple
                    :label="$t('DIALOGS.ADDFILESMSG')"
                  ></v-file-input>
                </v-col>
              </div>
            </div>
            <div v-if="sigrequestactive" class="pa-5">
              <h3 class="my-3"
                ><b>{{ $t('DIALOGS.SIGREQUESTED') }}:</b></h3
              >
              <v-text-field
                v-model="sigrequestuniqid"
                :label="$t('DIALOGS.SIGREQUESTEDBY')"
                readonly
              ></v-text-field>
              <v-text-field
                v-model="sigrequestid"
                :label="$t('DIALOGS.SIGREQUESTEDID')"
                readonly
              ></v-text-field>
              <v-text-field
                v-model="sigrequestcreate"
                :label="$t('DIALOGS.SIGREQUESTEDAT')"
                readonly
              ></v-text-field>
              <p></p>
              <v-container fluid>
                <v-row justify="center">
                  <v-simple-table :class="$style.dialogOpenTable" fixed-header>
                    <template>
                      <thead
                        :class="$style.dialogHead"
                        class="sticky-header grey lighten-3"
                        fixed-header
                      >
                        <tr :class="$style.dialogHeader">
                          <th
                            style="font-size:16px"
                            :class="$style.dialogCellStatus"
                            >{{ $t('DIALOGS.NAME') }}</th
                          >
                          <th
                            style="font-size:16px"
                            :class="$style.dialogBigCellStatus"
                            >{{ $t('DIALOGS.EMAIL') }}</th
                          >
                          <th
                            style="font-size:16px"
                            :class="$style.dialogCellStatus"
                            >{{ $t('DIALOGS.STATUS') }}</th
                          >

                          <th
                            style="font-size:16px"
                            :class="$style.dialogCellStatus"
                            >{{ $t('DIALOGS.LASTREVIEWED') }}</th
                          >
                          <th
                            style="font-size:16px"
                            :class="$style.dialogCellWidth"
                            >{{ $t('DIALOGS.SIGNEDAT') }}</th
                          >
                        </tr>
                      </thead>
                      <tbody :class="$style.dialogBody">
                        <tr
                          v-for="item in sigDetail.signatures"
                          :key="item.signature_id"
                          :class="$style.dialogRow"
                        >
                          <td :class="$style.dialogCellStatus">
                            {{ item.signer_name }}
                          </td>
                          <td :class="$style.dialogCellStatus">
                            {{ item.signer_email_address }}
                          </td>
                          <td :class="$style.dialogCellStatus">
                            {{ item.status_code }}
                          </td>
                          <td :class="$style.dialogCellStatus">
                            {{ formatDateDialog(item.last_viewed_at) }}
                          </td>
                          <td :class="$style.dialogCellStatus">
                            {{ formatDateDialog(item.signed_at) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
              </v-container>
            </div>
            <div>
              <h3>{{ progressmsg }}</h3>
            </div>
          </v-card-text>
        </div>

        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('act/sendforesignature')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="sigrequestactive && eSignatureAvail"
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="refreshSigDetails"
            >REFRESH</v-btn
          >

          <v-btn
            v-if="!sigrequestactive && eSignatureAvail"
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="signNewRequest"
            >{{ $t('DIALOGS.SUBMITSIG') }}</v-btn
          >
          <v-btn
            v-if="sigrequestactive && eSignatureAvail"
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="cancelSignatureRequest"
            >{{ $t('DIALOGS.CANCELSIG') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
