import store from '@state/store'

class SnackService {
  success(message) {
    store.dispatch('snackbar/setSnackbar', {
      message: message,
      type: 'success',
      length: 15000,
    })
  }

  info(message) {
    store.dispatch('snackbar/setSnackbar', {
      message: message,
      type: 'info',
      length: 15000,
    })
  }

  warning(message) {
    store.dispatch('snackbar/setSnackbar', {
      message: message,
      type: 'warning',
      length: 15000,
    })
  }

  error(message) {
    store.dispatch('snackbar/setSnackbar', {
      message: message,
      type: 'error',
      length: 15000,
    })
  }
}

export default new SnackService()
