import SnackService from '@services/SnackService'

import { DBinsertone } from '@/src/state/io/iodb.js'

const TABLENAME = 'logerror'

export const state = {
  // errors and other info.
  dberror: { data: [], message: '', error: '' },
}

// do I need to add getters here?
export const getters = {
  dberror(state) {
    return state.dberror
  },
}

export const mutations = {
  INIT_USEDB(state) {
    state.dberror = { data: [], message: '', error: '' }
  },

  SET_ERROR(state, error) {
    state.dberror.error = error
  },
}

export const actions = {
  init({ commit }) {},

  logError({ commit, state }, e) {
    SnackService.error(e)

    DBinsertone(TABLENAME, {
      timestamp: new Date(),
      error: e.message,
    })
  },
}
