<script>
// MAIN NAVIGATION BAR

import {
  dbauthComputed,
  dbauthActions,
  syncviewComputed,
  snackbarComputed,
  snackbarMethods,
  panelComputed,
} from '@state/helpers'

import { formatDateDialog, docroute } from '@utils/dialogutils'
import getEnv from '@src/utils/env'

import ParaDialogAdminGroups from '@components/panel/para-dialog-admingroups.vue'
import ParaDialogAdminDocTypes from '@components/panel/para-dialog-admindoctypes.vue'
import ParaDialogAdminAlerts from '@components/panel/para-dialog-adminalerts.vue'

// Register, Login, Change, etc
import ParaDialogAccountSettings from '@components/panel/para-dialog-accountsettings.vue'
import ParaDialogUserLog from '@components/panel/para-dialog-userlog.vue'

import ParaDialogSubscriptionUpgrade from '@components/panel/para-dialog-subscriptionupgrade.vue'
import ParaDialogQuickstart from '@components/panel/para-dialog-quickstart.vue'
import ParaDialogVimeoPlay from '@components/panel/para-dialog-vimeoplay.vue'

import ConfirmAction from '@components/panel/para-dialog-confirmaction.vue'

import SnackService from '@/src/services/SnackService'

export default {
  components: {
    ParaDialogAdminGroups,
    ParaDialogAdminDocTypes,
    ParaDialogAdminAlerts,
    ParaDialogAccountSettings,
    ParaDialogSubscriptionUpgrade,
    ParaDialogQuickstart,
    ParaDialogVimeoPlay,
    ParaDialogUserLog,
    ConfirmAction,
  },
  props: {
    authlevel: {
      type: String,
      default: () => 'A',
    },
  },
  data: () => ({
    PanelsToShow: 2,

    // to show different Admin Dialogs
    showAdminGroupsDialog: false,
    showAdminDocTypesDialog: false,
    showAdminAlertsDialog: false,

    // shows the registration Dialog
    showRegisterDialog: false,
    showLoginDialog: false,
    showUserLogDialog: false,

    // shows the QuickStart
    showQuickstartDialog: false,
    showVimeoPlayDialog: false,
    // vimeoplaytopic: 'quickstart', // default video play topic

    // subscription dialogs
    showUpgradeSubscriptionDialog: false,

    actionmode: 'Login',

    lsearchOn: false,
    lsearchCapsOn: false,
    lsearchText: '',

    // BETAPROGRAM: [
    //   'john@mylegaleinstein.com',
    //   'britany.kerber.llanos@pwc.com',
    //   'jan.sarsanedas.coll@pwc.com',
    //   'lucila.cano.lopez@pwc.com',
    //   'pablo@pablofb.com',
    //   'patricia.manca.diaz@pwc.com',
    //   'pedro.palomino.toledano@pwc.com',
    //   'rocio.catala.martinez@pwc.com',
    //   'sara.depablo.yanguez@pwc.com',
    //   'szilard.k.kaltenecker@pwc.com',
    //   'ngiesecke@aperianglobal.com',
    //   'daniel.trier@nexpert.ch',
    // ],

    // confirm dialogs
    confirmUpgrade: false,
    confirmBilling: false,
  }),
  computed: {
    ...syncviewComputed,
    ...snackbarComputed,
    ...dbauthComputed,

    // computed properties referencing the locale
    adminList() {
      return [
        {
          title: this.$t('MENU.GROUPS'),
        },
        {
          title: this.$t('MENU.DOCUMENTTYPES'),
        },
        {
          title: this.$t('MENU.ALERTS'),
        },
      ]
    },

    // inBetaProgram() {
    //   return (
    //     this.ifLoggedIn && this.BETAPROGRAM.includes(this.dbauthuser.uniqid)
    //   )
    // },

    listAuth() {
      // if ENTERPRISE type, no need to do anything with subscriptions
      if (process.env.VUE_APP_INSTANCETYPE === 'ENTERPRISE') {
        return [
          {
            title: this.$t('MENU.QUICKSTARTTUTORIAL'),
          },
          {
            title: this.$t('MENU.QUICKSTARTVIDEOS'),
          },
          {
            title: this.$t('MENU.ACCOUNTSETTINGS'),
          },
          {
            title: this.$t('MENU.USERLOG'),
          },
          {
            title: this.$t('MENU.CHANGEPASSWORD'),
          },
          {
            title: this.$t('MENU.LOGOUT'),
          },
        ]
      }

      return [
        {
          title: this.$t('MENU.QUICKSTARTTUTORIAL'),
        },
        {
          title: this.$t('MENU.QUICKSTARTVIDEOS'),
        },
        {
          title: this.$t('MENU.UPGRADESUBSCRIPTION'),
        },
        {
          title: this.$t('MENU.SUBSCRIPTIONBILLING'),
        },
        {
          title: this.$t('MENU.ACCOUNTSETTINGS'),
        },
        {
          title: this.$t('MENU.USERLOG'),
        },
        {
          title: this.$t('MENU.CHANGEPASSWORD'),
        },
        {
          title: this.$t('MENU.LOGOUT'),
        },
      ]
    },

    // provides logic for showing admin
    adminAvail() {
      return this.authlevel >= 'G'
    },

    inactiveSubscription() {
      if (this.dbauthProfile !== null && this.dbauthProfile !== undefined) {
        return (
          this.dbauthProfile.stripestatus === undefined ||
          this.dbauthProfile.stripestatus === 'uninitialized' ||
          this.dbauthProfile.subscriptionoverride
        )
      } else {
        return true
      }
    },

    activeSubscription() {
      if (this.dbauthProfile !== null && this.dbauthProfile !== undefined) {
        return (
          this.dbauthProfile.stripestatus === 'active' ||
          this.dbauthProfile.subscriptionoverride
        )
      } else {
        return false
      }
    },

    canceledSubscription() {
      if (this.dbauthProfile !== null && this.dbauthProfile !== undefined) {
        return this.dbauthProfile.stripestatus === 'canceled'
      } else {
        return false
      }
    },

    displayAuthLevel() {
      if (
        this.dbauthPrivileges !== null &&
        this.dbauthPrivileges !== undefined &&
        this.dbauthPrivileges.name !== undefined
      ) {
        return this.$t('MENU.' + this.dbauthPrivileges.name)
      }
      return ''
    },

    filterAuth() {
      var filteredAuth = this.listAuth
      if (this.dbauthuser === null) {
        return filteredAuth
      }

      // Adjust if active Subscription, don't show Upgrade
      if (this.activeSubscription) {
        filteredAuth = filteredAuth.filter(
          (e) => ![this.$t('MENU.UPGRADESUBSCRIPTION')].includes(e.title)
        )
      }

      // if not initialized subscription yet, don't show Billing.  Allows Billing when canceled.
      if (this.inactiveSubscription) {
        filteredAuth = filteredAuth.filter(
          (e) => ![this.$t('MENU.SUBSCRIPTIONBILLING')].includes(e.title)
        )
      }

      // if SSO Login, don't show Change Password, or Logout
      if (this.ssologgedin) {
        filteredAuth = filteredAuth.filter(
          (e) =>
            ![this.$t('MENU.CHANGEPASSWORD'), this.$t('MENU.LOGOUT')].includes(
              e.title
            )
        )
      }

      return filteredAuth
    },

    ifLoggedIn() {
      return this.dbauthuser !== null && this.dbauthuser !== undefined
    },

    shownUser() {
      if (this.ifLoggedIn && this.dbauthProfile !== null) {
        return (
          this.$t('MENU.USER') +
          ': ' +
          this.dbauthProfile.name +
          ', ' +
          this.displayAuthLevel
        )
      } else {
        return ''
      }
    },

    showDOCS() {
      if (this.ifLoggedIn) {
        return this.$t('TOPMENU.DOCS')
      } else {
        return ''
      }
    },

    disableDecrement() {
      return this.PanelsToShow === 1
    },

    disableIncrement() {
      if (this.authlevel === 'A') {
        return this.PanelsToShow === 2
      }
      if (this.authlevel === 'B') {
        return this.PanelsToShow === 2
      }
      if (this.authlevel === 'C') {
        return this.PanelsToShow === 3
      }
      return this.PanelsToShow === 6
    },

    enterpriseAcct() {
      return process.env.VUE_APP_INSTANCETYPE === 'ENTERPRISE'
    },

    expiredTrial() {
      // if it's an enterprise instance, don't consider trial dates
      if (this.enterpriseAcct) {
        return false
      }

      const todaysdate = new Date()
      const todaystring = new Date().toISOString().slice(0, 10)

      if (this.dbauthPrivileges !== null && this.dbauthProfile !== null) {
        // trial user exceeded trial period
        if (
          this.dbauthPrivileges.type < 'C' &&
          // this.dbauthProfile.trailexpdate.toDate() < todaysdate
          formatDateDialog(this.dbauthProfile.trailexpdate) < todaysdate
        ) {
          SnackService.info('INFO_MESSAGES.EXPIREDTRIAL')
          return true
        }

        // subscription exceeded billing date with no update (should not happen if all info is captured via
        if (
          this.dbauthPrivileges.type >= 'C' &&
          this.dbauthProfile.subscriptionend < todaystring
        ) {
          // Should try to ask for Stripe update.  It will take into effect at the next login.
          // Might want to use this update every time someone logs in?
          if (this.dbauthProfile.stripecustid !== undefined) {
            this.$store.dispatch('panels/stripeGetLatest', {
              stripecustid: this.dbauthProfile.stripecustid,
            })
          }
          SnackService.info('INFO_MESSAGES.EXPIREDSUBSCRIPTION')
          return true
        }
      }
      return false
    },
    appTitle() {
      if (getEnv('VUE_APP_MLETITLEENV') !== undefined) {
        return getEnv('VUE_APP_MLETITLEENV')
      }
      if (process.env.VUE_APP_MLETITLE !== undefined) {
        return process.env.VUE_APP_MLETITLE
      }
      return this.$t('DIALOGS.MLE_DESC')
    },
    // instanceID() {
    //   return process.env.VUE_APP_MLECONFIG
    // },
    enterpriseInstance() {
      return process.env.VUE_APP_INSTANCETYPE === 'ENTERPRISE'
    },

    defaultLogo() {
      return process.env.VUE_APP_LOGO === 'MLE'
    },

    altLogoSize() {
      return process.env.VUE_APP_ALTLOGOSIZE
    },

    ssologgedin() {
      return this.dbauthuser.token === 'SSO'
    },
  },
  watch: {
    dbauthuser(newValue, oldValue) {
      if (newValue === null) {
        this.$store.dispatch('dbauth/logoutUserAuth')
      } else {
        if (newValue !== null && newValue !== undefined) {
          this.$store.dispatch('dbauth/getUserProfileAuth') // added this to see if we can authentcate correctly.
        }
      }
    },
    dbauthProfile(newValue, oldValue) {
      if (newValue !== null && newValue !== undefined) {
        this.$store.dispatch('dbauth/getUserPrivilegesAuth') // get privileges from subscription info
        this.$store.dispatch('dbauth/getUserPrivUsageAuth') // get usage info from doclog
        this.$store.dispatch('dbauth/getUserGroupAccessAuth') // generates group access info.

        // adjust the locale as appropriate based on user's previous preferences.
        if (this.dbauthProfile.language !== undefined) {
          this.setLocale(this.dbauthProfile.language)
        }
      }
    },
    dbauthGroupsAccess(newValue, oldValue) {
      if (newValue !== null && newValue !== undefined) {
        this.$store.dispatch('dbauth/getUserDocTypesAuth') // get available doctypes for this user.
      }
    },

    // dbauthPrivUsage(newValue, oldValue) {
    //   if (this.dbauthPrivUsage !== null && this.dbauthPrivUsage !== undefined) {
    //     // kickoff integrations right after privileges are set.
    //     this.$store.dispatch('dbintegration/setIntegrations')
    //   }
    // },

    // if privUsage is loaded and low doc processing count, kick of the Quickstart
    // dbauthPrivUsage(newValue, oldValue) {
    //   if (this.dbauthPrivUsage !== null && this.dbauthPrivUsage !== undefined) {
    //     if (this.dbauthPrivUsage.docsproc < 3) {
    //       this.showQuickstartDialog = true
    //     }
    //   }
    // },
  },
  mounted: function () {
    this.showLoginDialog =
      this.$route.name !== 'Register' &&
      this.$route.name !== 'RegisterABA' &&
      this.$route.name !== 'SSOLogin'
  },

  methods: {
    ...snackbarMethods,
    ...dbauthActions,
    ...panelComputed,
    // toggleHelpDialog() {
    //   this.helpDialog = !this.helpDialog
    // },
    navigate(path) {
      this.$router.push(path)
    },

    doSearch() {
      if (this.lsearchText !== null && this.lsearchText.trim() !== '') {
        this.$store.dispatch('syncview/setSearch', {
          searchOn: this.lsearchOn,
          searchCapsOn: this.lsearchCapsOn,
          searchText: this.lsearchText,
        })
      }
    },
    toggleSearchCaps() {
      this.lsearchCapsOn = !this.lsearchCapsOn
      this.doSearch()
    },
    toggleSearchOn() {
      this.lsearchOn = !this.lsearchOn

      // when turning off, reset to defaults
      if (!this.lsearchOn) {
        this.lsearchCapsOn = false
        this.lsearchText = ''
      }

      this.$store.dispatch('syncview/setSearch', {
        searchOn: this.lsearchOn,
        searchCapsOn: this.lsearchCapsOn,
        searchText: this.lsearchText,
      })
    },

    setLocale(language) {
      this.$i18n.locale = language
      this.$root.$i18n.locale = language
      // sets it but change is not immediate.  Don't see the way to make this an immediate change yet.
    },

    getUserProfileAuthA(user) {
      if (user.title === this.$t('MENU.LOGOUT')) {
        this.$store.dispatch('dbauth/logoutUserAuth')
        return
      }

      if (user.title === this.$t('MENU.QUICKSTARTTUTORIAL')) {
        this.showQuickstartDialog = true
        return
      }

      if (user.title === this.$t('MENU.QUICKSTARTVIDEOS')) {
        this.showVimeoPlayDialog = true
        return
      }

      // Different treatment for CHANGEPASSWORD FOR MONGO
      if (
        user.title === this.$t('MENU.CHANGEPASSWORD') &&
        process.env.VUE_APP_AUTH === 'ENTERPRISE'
      ) {
        this.$store
          .dispatch('dbreg/confirmPasswordResetReg', this.dbauthuser.uniqid)
          .then(SnackService.info('MESSAGES.PASSWORDRESETSENT'))

        return
      }

      if (
        [
          // commented out - I don't think this is necessary
          this.$t('MENU.LREGISTER'),
          this.$t('MENU.LLOGIN'),
          this.$t('MENU.CHANGEPASSWORD'),
          this.$t('MENU.ACCOUNTSETTINGS'),
        ].includes(user.title)
      ) {
        this.actionmode = user.title
        this.showLoginDialog = true
        return
      }

      if (user.title === this.$t('MENU.UPGRADESUBSCRIPTION')) {
        this.confirmUpgrade = true
        return
      }

      if (user.title === this.$t('MENU.SUBSCRIPTIONBILLING')) {
        this.confirmBilling = true
        return
      }

      if (user.title === this.$t('MENU.USERLOG')) {
        this.$store.dispatch('dbauth/getUserPrivUsageAuth', true)
        this.showUserLogDialog = true
        // return
      }
    },

    // relay
    docroute(d) {
      return docroute(d)
    },

    confirmUpgradeAct() {
      this.showUpgradeSubscriptionDialog = true
    },
    confirmBillingAct() {
      if (this.dbauthProfile.stripecustid !== undefined) {
        SnackService.info('MESSAGES.BILLINGEXTERNALPAYMENTS')
        this.stripeGetBillingSession({
          stripecustid: this.dbauthProfile.stripecustid,
        }).then((result) => (window.location.href = result.url))
      }
    },

    // processes navigation capability from main menu
    process(item) {
      switch (item.title) {
        case this.$t('MENU.GROUPS'):
          this.$store.dispatch('dbauth/getUserGroupAccessAuth')
          this.$store.dispatch('dbauth/getUserOwnerDocTypesAuth')
          this.showAdminGroupsDialog = true
          return
        case this.$t('MENU.DOCUMENTTYPES'):
          this.$store.dispatch('dbauth/getUserOwnerDocTypesAuth')
          this.showAdminDocTypesDialog = true
          return
        case this.$t('MENU.ALERTS'):
          this.$store.dispatch('dbauth/getUserOwnerAlertsAuth')
          this.showAdminAlertsDialog = true
          return
        default:
          return 'None'
      }
    },

    incrementPanel() {
      this.PanelsToShow++
      this.$store.dispatch('syncview/setPanelsToShow', this.PanelsToShow)
    },
    decrementPanel() {
      this.PanelsToShow--
      this.$store.dispatch('syncview/setPanelsToShow', this.PanelsToShow)
    },
    launchquickstart() {
      // this.vimeoplaytopic = 'quickstart'
      this.showVimeoPlayDialog = true
    },
    // translate function to convert snackmessages
    translateMessage(snackmessage) {
      //  this language specifically addresses the format coming from the authentication service.
      if (
        snackmessage !== null &&
        snackmessage !== undefined &&
        snackmessage.message !== undefined
      ) {
        if (snackmessage.message.includes(' ')) {
          return snackmessage.message
        }
      }
      return this.$t(snackmessage)
    },
    routehome() {
      this.$router.push('/')
    },
  },
}
</script>

<template>
  <div>
    <header>
      <ParaDialogAdminGroups
        :dialog="showAdminGroupsDialog"
        @close-dialog="showAdminGroupsDialog = false"
      />
      <ParaDialogAdminDocTypes
        :dialog="showAdminDocTypesDialog"
        @close-dialog="showAdminDocTypesDialog = false"
      />
      <ParaDialogAdminAlerts
        :dialog="showAdminAlertsDialog"
        @close-dialog="showAdminAlertsDialog = false"
      />
      <ParaDialogAccountSettings
        :dialog="showLoginDialog"
        :mode="actionmode"
        @close-dialog="showLoginDialog = false"
      />
      <ParaDialogUserLog
        :dialog="showUserLogDialog"
        @close-dialog="showUserLogDialog = false"
      />

      <ParaDialogSubscriptionUpgrade
        :dialog="showUpgradeSubscriptionDialog || expiredTrial"
        @close-dialog="showUpgradeSubscriptionDialog = false"
      />
      <ParaDialogQuickstart
        :dialog="showQuickstartDialog"
        @close-dialog="showQuickstartDialog = false"
        @launchquickstart="launchquickstart"
      />
      <ParaDialogVimeoPlay
        :dialog="showVimeoPlayDialog"
        @close-dialog="showVimeoPlayDialog = false"
      />
      <ConfirmAction
        :dialog="confirmUpgrade"
        :confirmationmessage="$t('MESSAGES.UPGRADEEXTERNALPAYMENTS')"
        @close-dialog="confirmUpgrade = false"
        @confirm="confirmUpgradeAct"
      />
      <ConfirmAction
        :dialog="confirmBilling"
        :confirmationmessage="$t('MESSAGES.BILLINGEXTERNALPAYMENTS')"
        @close-dialog="confirmBilling = false"
        @confirm="confirmBillingAct"
      />
      <v-snackbar
        :value="getSnack.show"
        :color="getSnack.type"
        :timeout="getSnack.length"
        @input="resetSnackbar"
      >
        message: {{ translateMessage(getSnack.message) }}
        <v-btn text @click="resetSnackbar">{{ $t('DIALOGS.CLOSE') }}</v-btn>
      </v-snackbar>
    </header>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      dark
      :class="$style.appBarMle"
    >
      <!-- MLE LOGO -->
      <div v-if="defaultLogo">
        <img
          id="logo"
          src="@assets/images/mlelogo.png"
          height="32"
          style="margin: 0 20px"
          @click="routehome"
        />
      </div>
      <!-- DOCGILITY -->
      <!-- LOGO OPTIONS -->
      <div v-if="!defaultLogo">
        <img
          id="logo"
          src="@assets/images/logo.png"
          :style="{ height: altLogoSize }"
          @click="routehome"
        />
      </div>
      <!-- LOGO OPTIONS END -->
      <h4 :class="$style.headerTitle" class="mt-2">{{ appTitle }}</h4>
      <div v-if="!enterpriseInstance">
        <h6 :class="$style.copyrightNotice" class="mx-1">&copy; 2023</h6>
      </div>
      <v-spacer />
      {{ shownUser }}
      <v-spacer />
      <v-btn v-if="ifLoggedIn" icon large class="mx-3" @click="docroute('')">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
      <v-text-field
        v-if="ifLoggedIn && lsearchOn"
        v-model="lsearchText"
        class="mx-3 pt-4"
        clearable
        label="Search"
        @input="doSearch"
      ></v-text-field>
      <v-btn
        v-if="ifLoggedIn && lsearchOn"
        icon
        large
        depressed
        class="mx-3"
        :class="{
          'grey lighten-2': !lsearchCapsOn,
          'red darken-2': lsearchCapsOn,
        }"
        @click="toggleSearchCaps"
      >
        <v-icon>mdi-format-letter-case</v-icon>
      </v-btn>

      <v-btn v-if="ifLoggedIn" icon large class="mx-3" @click="toggleSearchOn">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <div>
        <v-btn
          v-if="ifLoggedIn"
          class="mx-1"
          :disabled="disableDecrement"
          @click="decrementPanel"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>

        {{ showDOCS }}

        <v-btn
          v-if="ifLoggedIn"
          class="ml-1 mr-9"
          :disabled="disableIncrement"
          @click="incrementPanel"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-menu v-if="adminAvail" offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltipA }">
                <v-btn
                  class="mx-1"
                  dark
                  v-bind="attrs"
                  v-on="{ ...tooltipA, ...menu }"
                >
                  {{ $t('MENU.ADMIN') }}
                </v-btn>
              </template>
              <span>{{ $t('MENU.ADMINEXPLAIN') }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, i) in adminList"
              :key="i"
              @click="process(item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          v-if="!ifLoggedIn && false"
          class="mx-1"
          dark
          @click="getUserProfileAuthA({ title: $t('MENU.LREGISTER') })"
        >
          {{ $t('MENU.LREGISTER') }}
        </v-btn>

        <v-btn
          v-if="!ifLoggedIn"
          class="mx-1"
          dark
          @click="getUserProfileAuthA({ title: $t('MENU.LLOGIN') })"
        >
          {{ $t('MENU.LLOGIN') }}
        </v-btn>

        <v-menu v-if="ifLoggedIn" offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltipA }">
                <v-btn
                  class="mx-1"
                  dark
                  v-bind="attrs"
                  v-on="{ ...tooltipA, ...menu }"
                >
                  {{ $t('MENU.LPROFILE') }}
                </v-btn>
              </template>
              <span>{{ $t('MENU.PROFILEEXPLAIN') }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, i) in filterAuth"
              :key="i"
              @click="getUserProfileAuthA(item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>

<style lang="scss" module>
@import '@design';
.appBarMle {
  right: 0 !important;
  z-index: 20 !important;
  color: $mle-color;
  background: black !important;
}

.link {
  @extend %font-heading;

  color: whitesmoke !important;
  text-decoration: none;
}

.headerTitle {
  @extend %font-heading;

  color: whitesmoke !important;
  letter-spacing: 3px;
}
.copyrightNotice {
  @extend %font-heading;

  color: whitesmoke !important;
  letter-spacing: 1px;
}
</style>
