// Contains all application constants //

export const RU = {
  TOPMENU: {
    FILE: 'FILE',
    EDIT: 'EDIT',
    ACT: 'ACT',
    DOCS: 'DOCS',
  },

  MENU: {
    NEWDOCUMENT: 'Новый документ',
    // ORIGINAL TEXT: New Document
    SAVEDOCUMENT: 'Сохранить документ',
    // ORIGINAL TEXT: Save Document
    COPYDOCUMENT: 'Копировать документ',
    // ORIGINAL TEXT: Copy Document
    CREATEVERSION: 'Создать чистую версию',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: 'Create Doc from Template',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: 'Открытый документ',
    // ORIGINAL TEXT: Open Document
    OPENGROUPDOCUMENT: 'Документ группы открытого состава',
    // ORIGINAL TEXT: Open Group Document
    OPENHUBDOCUMENT: 'Открытый HUB документ',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: 'Открыть архивный документ',
    // ORIGINAL TEXT: Open Archive Document
    OPENPLAYBOOK: 'Открыть альбом',
    // ORIGINAL TEXT: Open Playbook
    OPENTEMPLATE: 'Open Template',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: 'Save As Template',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: 'Сохранить как книгу воспроизведения',
    // ORIGINAL TEXT: Save As Playbook
    MOVETOARCHIVE: 'Перейти в архив',
    // ORIGINAL TEXT: Move to Archive
    CLOSEDOCUMENT: 'Закрыть документ',
    // ORIGINAL TEXT: Close Document
    DOCUMENTLOG: 'Журнал документов',
    // ORIGINAL TEXT: Document Log

    GOTOPARAGRAPH: 'Перейти к пункту',
    // ORIGINAL TEXT: Go to Paragraph
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'Документы',
    // ORIGINAL TEXT: Document Notes
    COLLABCOMMENTS: 'Сотрудничество - замечания',
    // ORIGINAL TEXT: Collaboration - Comments
    COLLABDOCEDITS: 'Сотрудничество - редактирование документов',
    // ORIGINAL TEXT: Collaboration - Document Edits
    COLLABRISKANDLABELS: 'Сотрудничество - риск и маркировка',
    // ORIGINAL TEXT: Collaboration - Risk and Labels
    NEGOTCOMMENTS: 'Переговоры - замечания',
    // ORIGINAL TEXT: Negotiation - Comments
    NEGOTDOCEDITS: 'Переговоры - редактирование документов',
    // ORIGINAL TEXT: Negotiation - Document Edits

    COLLABORATION: 'Сотрудничество',
    // ORIGINAL TEXT: Collaboration
    NEGOTIATION: 'Переговоры',
    // ORIGINAL TEXT: Negotiation
    ADDOWNER: 'Добавить владельца',
    // ORIGINAL TEXT: Add Owner
    ADDCOLLABORATOR: 'Добавить соавтора',
    // ORIGINAL TEXT: Add Collaborator
    ADDNEGOTIATOR: 'Добавить переговорщик',
    // ORIGINAL TEXT: Add Negotiator
    TRIGGERALERTS: 'Тревога',
    // ORIGINAL TEXT: Trigger Alerts
    EXPORTEXCEL: 'Экспорт Excel',
    // ORIGINAL TEXT: Export Excel
    DOWNLOADSOURCE: 'Загрузить источник',
    // ORIGINAL TEXT: Download Source
    SENDTOCLM: 'Отправить на CLM',
    // ORIGINAL TEXT: Send to CLM
    EXPORTJSON: 'Экспортировать СПОН',
    // ORIGINAL TEXT: Export JSON
    ESIGNATURE: 'Отправить для подписания',
    // ORIGINAL TEXT: Send for Esignature
    DOWNLOADREDLINE: 'Загрузка@ info: tooltip',
    // ORIGINAL TEXT: Download Redline DocX
    DOCUMENTSETTINGS: 'Параметры документа',
    // ORIGINAL TEXT: Document Settings
    EXPAND: 'Расширить',
    // ORIGINAL TEXT: Expand
    COLLAPSE: 'Свернуть',
    // ORIGINAL TEXT: Collapse
    TRANSLATETOENGLISH: 'Перевод на английский',
    // ORIGINAL TEXT: Translate to English
    ORIGINALLANGUAGE: 'Вернуть в первоначальную',
    // ORIGINAL TEXT: Revert to Original

    ALLPARAGRAPHS: 'Все пункты',
    // ORIGINAL TEXT: All Paragraphs
    EDITEDPARAGRAPHSONLY: 'Только отредактированные пункты',
    // ORIGINAL TEXT: Edited Paragraphs Only

    NOEDITCLAUSELABELS: 'Отменить маркировку клаузулы',
    // ORIGINAL TEXT: No Edit Clause Labels
    EDITCLAUSELABELS: 'Правка Заголовки',
    // ORIGINAL TEXT: Edit Clause Labels

    HIDEEDITS: 'Скрыть Правки',
    // ORIGINAL TEXT: Hide Edits
    SHOWEDITS: 'Показать Правки',
    // ORIGINAL TEXT: Show Edits

    SHOWALLEDITS: 'Показать все изменения',
    // ORIGINAL TEXT: Show All Edits
    SHOWNEGOTEDITSONLY: 'Показать только изменения в ходе переговоров',
    // ORIGINAL TEXT: Show Negotiation Edits Only

    GROUPS: 'Группы',
    // ORIGINAL TEXT: Groups
    DOCUMENTTYPES: 'Типы документов',
    // ORIGINAL TEXT: Document Types
    ALERTS: 'Оповещения',
    // ORIGINAL TEXT: Alerts
    QUICKSTARTTUTORIAL: 'Быстрое начало обучения',
    // ORIGINAL TEXT: Quick Start Tutorial
    QUICKSTARTVIDEOS: 'Быстрые стартовые видео',
    // ORIGINAL TEXT: Quick Start Videos
    UPGRADESUBSCRIPTION: 'Модернизация подписки',
    // ORIGINAL TEXT: Upgrade Subscription
    SUBSCRIPTIONBILLING: 'Подписка',
    // ORIGINAL TEXT: Subscription Billing
    ACCOUNTSETTINGS: 'Параметры счета',
    // ORIGINAL TEXT: Account Settings
    USERLOG: 'Журнал пользователя',
    // ORIGINAL TEXT: User Log
    CHANGEPASSWORD: 'Изменить пароль',
    // ORIGINAL TEXT: Change Password
    LOGOUT: 'Выход',
    // ORIGINAL TEXT: Logout

    // HEADINGS
    ADMIN: 'АДМИН',
    // ORIGINAL TEXT: ADMIN
    PROFILE: 'ЗАДАЧИ',
    // ORIGINAL TEXT: PROFILE
    LPROFILE: 'Профиль',
    // ORIGINAL TEXT: Profile
    FILE: 'ФИЛЕ',
    // ORIGINAL TEXT: FILE
    EDIT: 'ЭПТ',
    // ORIGINAL TEXT: EDIT
    ACT: 'АСТ',
    // ORIGINAL TEXT: ACT
    TOPIC: 'ТЕМА',
    // ORIGINAL TEXT: TOPIC
    DOCS: 'ДОК',
    // ORIGINAL TEXT: DOCS

    // Field Headings in Navigation Bar
    NAME: 'Имя',
    // ORIGINAL TEXT: Name
    ROLE: 'Роль',
    // ORIGINAL TEXT: Role
    STATE: 'Состояние',
    // ORIGINAL TEXT: State
    GROUP: 'Группа',
    // ORIGINAL TEXT: Group
    TYPE: 'Тип',
    // ORIGINAL TEXT: Type
    SENSIT: 'Чувствительность',
    // ORIGINAL TEXT: Sensitivity
    LTOPIC: 'Тема',
    // ORIGINAL TEXT: Topic
    USER: 'Пользователь',
    // ORIGINAL TEXT: User

    // Other Headings
    REGISTER: 'РЕГИСТР',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'Логин',
    // ORIGINAL TEXT: LOGIN
    LREGISTER: 'Регистрация',
    // ORIGINAL TEXT: Register
    LLOGIN: 'Вход',
    // ORIGINAL TEXT: Login

    ADMINEXPLAIN: 'Административный потенциал',
    // ORIGINAL TEXT: Administrative capabilities
    PROFILEEXPLAIN: 'Управление счетами, подписка, выписка счетов',
    // ORIGINAL TEXT: Account Management, Subscriptions, Billing

    // AUTH LEVELS
    PREMIUM: 'Премиум',
    // ORIGINAL TEXT: Premium
    PREMIUMPREPAID: 'Премиум (предоплаченный)',
    // ORIGINAL TEXT: Premium (Prepaid)
    BASIC: 'Базовые',
    // ORIGINAL TEXT: Basic
    BASICPREPAID: 'Базовая (предоплаченная)',
    // ORIGINAL TEXT: Basic (Prepaid)
    VIPACCESS: 'Доступ VIP',
    // ORIGINAL TEXT: VIP Access
    VIPMEMBER: 'Член ВИП',
    // ORIGINAL TEXT: VIP Member
    UNLIMITED: 'Без ограничений',
    // ORIGINAL TEXT: Unlimited
    TRIALUSER: 'Пользователь испытания',
    // ORIGINAL TEXT: Trial User
    REFERREDUSER: 'Отсроченный пользователь',
    // ORIGINAL TEXT: Referred User
    SOURCEDOCUMENT: 'Исходный документ',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: 'Документ сравнения',
    // ORIGINAL TEXT: Comparison Document
    EXIT: 'ВЫХОД',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: 'Параграф',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: 'РЕЖИМ СРАВНЕНИЯ ДОКУМЕНТОВ',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: 'Нет',
    // ORIGINAL TEXT: NONE
    PREAMBLE: 'ПРЕМЕЛЬ',
    // ORIGINAL TEXT: PREAMBLE
    DEFN: 'ОПРЕДЕЛЕНИЕ',
    // ORIGINAL TEXT: DEFINITION
    PAYMENT: 'ПЛАНИРОВАНИЕ',
    // ORIGINAL TEXT: PAYMENT
    TAXES: 'ТАКСЫ',
    // ORIGINAL TEXT: TAXES
    REPWARR: 'ПРЕДСТАВИТЕЛЬСТВО И ЗАРУШЕНИЯ',
    // ORIGINAL TEXT: REPRESENTATION AND WARRANTIES
    PERIOD: 'Период',
    // ORIGINAL TEXT: PERIOD
    TERMINATION: 'ТЕРМИНАЦИЯ',
    // ORIGINAL TEXT: TERMINATION
    CONFID: 'КОНФИДЕНЦИАЛЬНОСТЬ',
    // ORIGINAL TEXT: CONFIDENTIALITY
    INDEMNITY: 'НЕЗАВИСИМОСТЬ',
    // ORIGINAL TEXT: INDEMNITY
    LIABILITY: 'ОТВЕТСТВИЕ',
    // ORIGINAL TEXT: LIABILITY
    INTELPROP: 'ИНТЕЛЛЕКТУАЛЬНЫЙ ПРОП',
    // ORIGINAL TEXT: INTELLECTUAL PROP
    SIGNATURE: 'ПОДПИСАНИЕ',
    // ORIGINAL TEXT: SIGNATURE
    OTHER: 'ПРОЧИЕ ВОПРОСЫ',
    // ORIGINAL TEXT: OTHER
    DATASECURITY: 'БЕЗОПАСНОСТЬ ДАННЫХ',
    // ORIGINAL TEXT: DATA SECURITY
    ASSIGN: 'УСТРОЙСТВО',
    // ORIGINAL TEXT: ASSIGNMENT
    NOTICE: 'УВЕДОМЛЕНИЕ',
    // ORIGINAL TEXT: NOTICE
    GOVERNLAW: 'ПРАВО УПРАВЛЕНИЯ',
    // ORIGINAL TEXT: GOVERNING LAW
    DISPUTE: 'ДИСКУССИЯ',
    // ORIGINAL TEXT: DISPUTE
    ENTIREAGREE: 'СОГЛАШЕНИЕ',
    // ORIGINAL TEXT: ENTIRE AGREEMENT
    SURVIVAL: 'ОБСЛЕДОВАНИЯ',
    // ORIGINAL TEXT: SURVIVAL
    RELATPART: 'СВЯЗАННЫЕ МЕЖДУ СТОРОНАМИ',
    // ORIGINAL TEXT: RELATION BETWEEN PARTIES
    THIRDPARTY: 'ТРЕТЬЯ СТОРОНА',
    // ORIGINAL TEXT: THIRD PARTY
    FORCEMAJEURE: 'СИЛЫ',
    // ORIGINAL TEXT: FORCE MAJEURE
    SEVERABILITY: 'НАБЛЮДЕНИЕ',
    // ORIGINAL TEXT: SEVERABILITY
    EXPORTCTRL: 'КОНТРОЛЬ ЗА ЭКСПОРТАМИ',
    // ORIGINAL TEXT: EXPORT CONTROLS
    INTRO: 'ВВЕДЕНИЕ',
    // ORIGINAL TEXT: INTRODUCTION
    INSURANCE: 'ВЛИЯНИЕ',
    // ORIGINAL TEXT: INSURANCE
    HEADING: 'ПОДРАЗДЕЛЫ',
    // ORIGINAL TEXT: HEADING
    ALL: 'ВСЕ',
    // ORIGINAL TEXT: ALL
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'Необходима электронная почта',
    // ORIGINAL TEXT: E-mail is required
    EMAILVALID: 'Электронная почта должна быть действительной',
    // ORIGINAL TEXT: E-mail must be valid
    MAX20CHARS: 'Максимум 20 символов',
    // ORIGINAL TEXT: Max 20 characters
    MAX60CHARS: 'Максимум 60 символов',
    // ORIGINAL TEXT: Max 60 characters
    MAX80CHARS: 'Максимум 80 символов',
    // ORIGINAL TEXT: Max 80 characters
    MAX160CHARS: 'Максимум 160 символов',
    // ORIGINAL TEXT: Max 160 characters
    EMAILALREADYOWNER: 'Электронная почта уже является владельцем',
    // ORIGINAL TEXT: Email is already an owner
    EMAILALREADYCOLLABORATOR: 'Электронная почта уже является сообщником',
    // ORIGINAL TEXT: Email is already a collaborator
    EMAILALREADYNEGOTIATOR: 'Электронная почта уже переговорщик.',
    // ORIGINAL TEXT: Email is already a negotiator
    EMAILALREADYOWNERDOCTYPE:
      'Электронная почта уже является владельцем типа документа',
    // ORIGINAL TEXT: Email is already an owner of the document type
    EMAILALREADYCOLLABORATORDOCTYPE:
      'Электронная почта уже является соавтором типа документа',
    // ORIGINAL TEXT: Email is already an collaborator of the document type
    REQUIRED: 'Требуется.',
    // ORIGINAL TEXT: Required.
    CLAUSEFIELDREQUIRED: 'Это поле оговорки требуется для этой операции',
    // ORIGINAL TEXT: This clause field is required for this operation
    CLAUSENOTDEFAULT: 'Невозможно добавить оговорку из списка по умолчанию',
    // ORIGINAL TEXT: Cannot add clause from default clause list
    TAGFIELDREQUIRED: 'Это поле метки требуется для этой операции',
    // ORIGINAL TEXT: This tag field is required for this operation
    TAGNOTDEFAULT: 'Невозможно добавить метки из списка меток по умолчанию',
    // ORIGINAL TEXT: Cannot add tags from default tag list

    PARAINDEXREQUIRED: 'Требуется индекс пункта',
    // ORIGINAL TEXT: Paragraph index is required
    PARAINDEXINVALID: 'Индекса пунктов не существует',
    // ORIGINAL TEXT: Paragraph index does not exist
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'Сохранить документ',
    OPENDOCUMENT: 'открытый документ',
    // ORIGINAL TEXT: open document
    CLOSEDOCUMENT: 'закрытый документ',
    // ORIGINAL TEXT: close document
    ADDNOTE: 'добавить примечание',
    // ORIGINAL TEXT: add note
    ADDCOLLABORATOR: 'добавить соавтора',
    // ORIGINAL TEXT: add collaborator
    DELETECOLLABORATOR: 'Удалить соучредителя',
    // ORIGINAL TEXT: delete collaborator
    ADDNEGOTIATOR: 'добавить переговорщик',
    // ORIGINAL TEXT: add negotiator
    DELETENEGOTIATOR: 'Удалить переговорщика',
    // ORIGINAL TEXT: delete negotiator
    UPDATEARCHIVEDATA: 'обновление архивных данных',
    // ORIGINAL TEXT: update archive data
    UPDATEDOCMETA: 'обновление метаданных документа',
    // ORIGINAL TEXT: update document metadata
    ADDOWNER: 'добавить владельца',
    // ORIGINAL TEXT: add owner
    DELETEOWNER: 'Удалить владельца',
    // ORIGINAL TEXT: delete owner
    CHANGECOLLABORATION: 'изменение условий сотрудничества',
    // ORIGINAL TEXT: changed collaboration settings
    CHANGENEGOTIATION: 'Изменение параметров переговоров',
    // ORIGINAL TEXT: changed negotiation settings
    SAVEPLAYBOOK: 'Сохранить как игровой блок',
    // ORIGINAL TEXT: saved as playbook
    SAVETEMPLATE: 'saved as template',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: 'сохранено в качестве архива',
    // ORIGINAL TEXT: saved as archived
    TRIGGERALERT: 'сигнализация',
    // ORIGINAL TEXT: trigger alert
    TRIGGERALLALERT: 'активировать все предупреждения',
    // ORIGINAL TEXT: trigger all alerts
    TRIGGERCUSTOMALERT: 'запустить заказ',
    // ORIGINAL TEXT: trigger custom alert
    ADDCOMMENT: 'добавить комментарий',
    // ORIGINAL TEXT: add comment
    DELETECOMMENT: 'исключить комментарий',
    // ORIGINAL TEXT: delete comment
    ADDDOCEDIT: 'добавить докедит',
    // ORIGINAL TEXT: add docedit
    ADDAPPROVEDDOCEDIT: 'добавить утвержденный редактирование документов',
    // ORIGINAL TEXT: add approved document edit
    APPROVEDDOCEDIT: 'утвердить редактирование документа',
    // ORIGINAL TEXT: approve document edit
    REJECTDOCEDIT: 'Отключить редактирование документа',
    // ORIGINAL TEXT: reject document edit
    NEGOTIATORAPPROVEDDOCEDIT: 'Утвержденный редактор документа',
    // ORIGINAL TEXT: negotiator approved document edit
    NEGOTIATORREJECTEDDOCEDIT: 'Переговорщик отклонил редактирование документа',
    // ORIGINAL TEXT: negotiator rejected document edit
    DELETEDDOCEDIT: 'Удалить редактирование документа',
    // ORIGINAL TEXT: delete document edit
    ATTACHCONTRACT: 'Приложить контракт экзека',
    // ORIGINAL TEXT: attach exec contract
    ADDTAG: 'добавить метку',
    // ORIGINAL TEXT: add tag
    DELETETAG: 'Удалить тег',
    // ORIGINAL TEXT: delete tag
    NEWSIGNATUREREQUEST: 'Запрошена новая подпись',
    // ORIGINAL TEXT: new signature requested
    CANCELSIGNATUREREQUEST: 'Аннулированная просьба о подписании',
    // ORIGINAL TEXT: cancelled signature request
    CREATEREDLINEREQUEST: 'создать новый красный документ',
    // ORIGINAL TEXT: create new redline document
    CREATEFINALDOCREQUEST:
      'подготовить новый заключительный документ для подписания',
    // ORIGINAL TEXT: create new final document for signature
    COPIEDDOCUMENT: 'скопировано из документа',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: 'создал чистую версию из документа',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: 'created document from template',
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'Создать тип документа',
    // ORIGINAL TEXT: create document type
    DELETEDOCTYPE: 'Удалить тип документа',
    // ORIGINAL TEXT: delete document type
    ADDOWNERTODOCTYPE: 'Добавление владельца к типу документа',
    // ORIGINAL TEXT: added owner to document type
    DELETEOWNERFROMDOCTYPE: 'исключенный владелец типа документа',
    // ORIGINAL TEXT: deleted owner to document type
    ADDCLAUSETODOCTYPE: 'добавление дополнительного положения к типу документа',
    // ORIGINAL TEXT: added addl clause to document type
    DELETECLAUSEDOCTYPE: 'исключить добавление к тексту на русском языке.',
    // ORIGINAL TEXT: deleted addl clause to document type
    ADDTAGDOCTYPE: 'Добавить метку в тип документа',
    // ORIGINAL TEXT: added addl tag to document type
    DELETETAGDOCTYPE: 'Удалить добавленную тег из типа документа',
    // ORIGINAL TEXT: deleted addl tag from document type
    ADDCOLLABRULEDOCTYPE: 'добавить правила коллаба в тип документа',
    // ORIGINAL TEXT: added collab rules to  document type
    DELETECOLLABRULEDOCTYPE: 'исключить правила коллаба из типа документа',
    // ORIGINAL TEXT: deleted collab rules from document type
    ADDNOTIFRULEDOCTYPE: 'добавление правил уведомления к типу документа',
    // ORIGINAL TEXT: added notification rules to  document type
    DELETENOTIFRULEDOCTYPE: 'исключить из вида документов правила уведомления',
    // ORIGINAL TEXT: deleted notification rules from document type
    ADDNEGOTRULEDOCTYPE:
      'добавление правил ведения переговоров в тип документа',
    // ORIGINAL TEXT: added negotiation rules to  document type
    DELETENEGOTRULEDOCTYPE:
      'исключить правила ведения переговоров из типа документа',
    // ORIGINAL TEXT: deleted negotiation rules from document type

    // GROUPS
    CREATEGROUP: 'Созданная группа',
    // ORIGINAL TEXT: created group
    DELETEGROUP: 'исключенная группа',
    // ORIGINAL TEXT: deleted group
    ADDOWNERGROUP: 'добавление собственника в группу',
    // ORIGINAL TEXT: added owner to group
    DELETEOWNERGROUP: 'Удалённый владелец из группы',
    // ORIGINAL TEXT: deleted Owner from group
    ADDCOLLABGROUP: ':: добавление сотрудника в группу',
    // ORIGINAL TEXT: added collaborator to group
    DELETECOLLABGROUP: 'вычеркивание из группы',
    // ORIGINAL TEXT: deleted collaborator from group
    ADDDOCTYPETOGROUP: 'Добавление типа документа в группу',
    // ORIGINAL TEXT: added document type to group
    DELETEDOCTYPEGROUP: 'исключенный тип документа из группы',
    // ORIGINAL TEXT: deleted document type from group

    // ALERTS
    CREATEALERT: 'Создать тревогу',
    // ORIGINAL TEXT: created alert
    DELETEALERT: 'удаляется тревога',
    // ORIGINAL TEXT: deleted alert
    ADDOWNERALERT: 'Владелец был добавлен для предупреждения',
    // ORIGINAL TEXT: added owner to alert
    DELETEOWNERALERT: 'вычеркнутый владелец из предупреждения',
    // ORIGINAL TEXT: deleted owner from alert
    ADDDOCTYPEALERT: 'Добавление доктипа для предупреждения',
    // ORIGINAL TEXT: added doctype to alert
    DELETEDOCTYPEALERT: 'Удалить доктип из предупреждения',
    // ORIGINAL TEXT: deleted doctype from alert
    ADDCLAUSETOPICALERT: 'добавление оговорки для предупреждения',
    // ORIGINAL TEXT: added clause topic to alert
    DELETECLAUSETOPICALERT: 'Известие об исключении оговорки',
    // ORIGINAL TEXT: deleted clause topic from alert
    ADDRECIPIENTALERT: 'дополнительный получатель для оповещения',
    // ORIGINAL TEXT: added recipient to alert
    DELETERECIPIENTALERT: 'вычеркивание получателя из предупреждения',
    // ORIGINAL TEXT: deleted recipient from alert
    ADDNOTIFRULEALERT: 'добавление правил уведомления для предупреждения',
    // ORIGINAL TEXT: added notification rules to alert
    DELETENOTIFRULEALERT: 'исключить из предупреждения правила уведомления',
    // ORIGINAL TEXT: deleted notification rules from alert
    SETSELECTIVITYALERT: 'настроить избирательный подход для предупреждения',
    // ORIGINAL TEXT: set selectivity to  alert
  },

  USEREVENTS: {
    USERSIGNON: 'Пользователь вошел в систему',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT: 'Отправлено запрос на перезагрузку пароля',
    // ORIGINAL TEXT: password reset request sent
    CHANGEDPASSWORD: 'Изменение пароля',
    // ORIGINAL TEXT: changed password
    DELETEDREGISTRATION: 'исключенная регистрация пользователей',
    // ORIGINAL TEXT: deleted user registration
    UPDATEDACCOUNTINFO: 'Обновленная информация о счете',
    // ORIGINAL TEXT: updated account information
    UPGRADEDACCOUNTTOREFERRAL: 'Усовершенствованный счет на указанный счет',
    // ORIGINAL TEXT: upgraded account to referred account
    DELETEDUSERACCOUNT: 'удалённый пользовательский счет',
    // ORIGINAL TEXT: deleted user account
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: 'ДЕЙСТВИЯ ДОКУМЕНТОВ',
    // ORIGINAL TEXT: DOCUMENT ACTIONS
    COMMENTS: 'ЗАМЕЧАНИЯ',
    // ORIGINAL TEXT: COMMENTS
    DOCEDITS: 'ДОКУМЕНТЫ РЕДАКТЫ',
    // ORIGINAL TEXT: DOCUMENT EDITS
    LABELSRISK: 'МАРКИРОВКИ И РИСКИ',
    // ORIGINAL TEXT: LABELS AND RISK
    TEXT: 'ТЕКСТ',
    // ORIGINAL TEXT: TEXT
    MLE_DESC: 'DOCGILITY',
    // ORIGINAL TEXT: DOCGILITY
    CLAUSE: 'Положение',
    // ORIGINAL TEXT: Clause

    // PRIVILEGE
    OWNER: 'Владелец',
    // ORIGINAL TEXT: owner
    COLLABORATOR: 'Сотрудник',
    // ORIGINAL TEXT: collaborator
    NEGOTIATOR: 'Переговорщик',
    // ORIGINAL TEXT: negotiator
    OWNERGROUP: 'Владелец (группа)',
    // ORIGINAL TEXT: owner(group)
    COLLABORATORGROUP: 'Сотрудник (группа)',
    // ORIGINAL TEXT: collaborator(group)

    // TITLES
    SAVEDOCUMENT: 'БАЗОВЫЙ ДОКУМЕНТ',
    // ORIGINAL TEXT: SAVE DOCUMENT
    SAVEARCHIVE: 'ОПАСАТЬ ОРХИВНОСТЬ',
    // ORIGINAL TEXT: SAVE ARCHIVE
    QUICKSTART: 'ОКРУЖАЮЩАЯ СТОЛКА',
    // ORIGINAL TEXT: QUICK START TUTORIAL
    TUTORIALVIDEOS: 'БУТОРИЧЕСКИЕ ВИДЫ',
    // ORIGINAL TEXT: TUTORIAL VIDEOS

    MOVETOARCHIVEDOCUMENT: 'ДВИЖЕНИЕ В РАБОТУ ДОКУМЕНТА',
    // ORIGINAL TEXT: MOVE TO ARCHIVE DOCUMENT
    OPENDOCUMENT: 'ОТКРЫТЫЙ ДОКУМЕНТ',
    // ORIGINAL TEXT: OPEN DOCUMENT
    COPYDOCUMENT: 'ДОКУМЕНТ КОПИ',
    // ORIGINAL TEXT: COPY DOCUMENT
    CREATEVERSION: 'СОЗДАТЬ ЧИСТУЮ ВЕРСИЮ',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: 'CREATE DOCUMENT FROM TEMPLATE',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: 'ДОКУМЕНТ ОТКРЫТОЙ ГРУППЫ',
    // ORIGINAL TEXT: OPEN GROUP DOCUMENT
    OPENHUBDOCUMENT: 'ОТКРЫТЫЙ HUB ДОКУМЕНТ',
    // ORIGINAL TEXT: OPEN HUB DOCUMENT
    OPENARCHIVEDOCUMENT: 'ОТКРЫТЫЙ АРХИВНЫЙ ДОКУМЕНТ',
    // ORIGINAL TEXT: OPEN ARCHIVE DOCUMENT
    OPENPLAYBOOK: 'ОТКРЫТЫЙ ПЛАН',
    // ORIGINAL TEXT: OPEN PLAYBOOK
    OPENTEMPLATE: 'OPEN TEMPLATE',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'SAVE AS TEMPLATE',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: 'СОТРУДНИЧЕСТВО',
    // ORIGINAL TEXT: COLLABORATION
    NEGOTIATION: 'ПЕРЕГОВОРОВ',
    // ORIGINAL TEXT: NEGOTIATION
    ADDOWNER: 'АПОНЕР',
    // ORIGINAL TEXT: ADD OWNER
    ADDNEGOTIATOR: 'NEGOTIATOR',
    // ORIGINAL TEXT: ADD NEGOTIATOR
    ADDCOLLABORATOR: 'КОЛЛАБОРАТОР АДД',
    // ORIGINAL TEXT: ADD COLLABORATOR
    ERRORMESSAGE: 'ТЕРРОРИЗМНОЕ СООБЩЕНИЕ',
    // ORIGINAL TEXT: ERROR MESSAGE
    DOCUMENTINFO: 'ИНФОРМАЦИЯ О ДОКУМЕНТЕ',
    // ORIGINAL TEXT: DOCUMENT INFORMATION
    DOCUMENTLOGS: 'ДОКУМЕНТ',
    // ORIGINAL TEXT: DOCUMENT LOGS
    USERLOGS: 'ПОЛЬЗОВАТЕЛЕЙ',
    // ORIGINAL TEXT: USER LOGS
    DOCUMENTNOTES: 'ПРИМЕЧАНИЯ К ДОКУМЕНТУ',
    // ORIGINAL TEXT: DOCUMENT NOTES
    TRIGGERALERTS: 'ТРИГИРНЫЕ ЗАПРЕЩЕНИЯ',
    // ORIGINAL TEXT: TRIGGER ALERTS
    ALERTS: 'АЛЕРТЫ',
    // ORIGINAL TEXT: ALERTS
    DOCTYPES: 'ТИПЫ ДОКУМЕНТА',
    // ORIGINAL TEXT: DOCUMENT TYPES
    GROUPS: 'ГРУППЫ',
    // ORIGINAL TEXT: GROUPS
    NOTE: 'ПРИМЕЧАНИЕ',
    // ORIGINAL TEXT: NOTE
    GOTOPARAGRAPH: 'ПРАКТИЧЕСКИЕ ВОПРОСЫ',
    // ORIGINAL TEXT: GO TO PARAGRAPH
    ESIGNATURE: 'Э-СИГНАТУРА',
    // ORIGINAL TEXT: E-SIGNATURE
    DOWNLOADREDLINE: 'ДОКЕКС РУЛИНЫ',
    // ORIGINAL TEXT: DOWNLOAD REDLINE DOCX

    // ACTIONS
    ADD: 'ОДЗЗ',
    // ORIGINAL TEXT: ADD
    BACK: 'Вернуться',
    // ORIGINAL TEXT: BACK
    DELETE: 'ДЕЛЕТЕ',
    // ORIGINAL TEXT: DELETE
    SAVE: 'ОПАСНОСТЬ',
    // ORIGINAL TEXT: SAVE
    MOVETOARCHIVE: 'ДАЛЬНЕЙШИЕ ДВИЖЕНИЯ В РАМКАХ',
    // ORIGINAL TEXT: MOVE TO ARCHIVE
    COPY: 'КОПИ',
    // ORIGINAL TEXT: COPY
    CREATE: 'СОЗДАЙТЕ',
    // ORIGINAL TEXT: CREATE
    OPEN: 'Откройте.',
    // ORIGINAL TEXT: OPEN
    CLOSE: 'БОЛЕЕ',
    // ORIGINAL TEXT: CLOSE
    UPDATE: 'ОБНОВЛЕНИЕ',
    // ORIGINAL TEXT: UPDATE
    REGISTER: 'РЕГИСТР',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'Логин',
    // ORIGINAL TEXT: LOGIN
    LOGINSSO: 'Логин SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: 'РЕЗЕРВНЫЙ ПАССАЖИРОВ',
    // ORIGINAL TEXT: RESET PASSWORD
    CHANGEPASSWORD: 'ИЗМЕНЕНИЕ ПАССАЖИРОВ',
    // ORIGINAL TEXT: CHANGE PASSWORD
    CANCELACCOUNT: 'КАНСКИЙ СЧЕТ',
    // ORIGINAL TEXT: CANCEL ACCOUNT
    QUICKSTARTVIDEO: 'ВИДЕО НАСТОЯЩЕГО НАЧАЛА',
    // ORIGINAL TEXT: WATCH QUICK START VIDEO

    CHARGEBASIC: 'УПГРАД ДЛЯ ОСНОВЫ',
    // ORIGINAL TEXT: UPGRADE TO BASIC
    CHARGEPREMIUM: 'УПГРАД В ПРЕМИЙ',
    // ORIGINAL TEXT: UPGRADE TO PREMIUM
    CHARGECORPORATE: 'УПГРАД ДЛЯ КОРПОРАЦИИ',
    // ORIGINAL TEXT: UPGRADE TO CORPORATE
    UPGRADESUBSCRIPTION: 'ПОД ОПИСАНИЕ УПГРАДА',
    // ORIGINAL TEXT: UPGRADE SUBSCRIPTION
    GO: 'Уходи',
    // ORIGINAL TEXT: GO
    PREVIEW_FEEDBACK: 'ИЗМЕНЕНИЯ В ОБЗОРЕ',
    // ORIGINAL TEXT: REVIEW CHANGES
    ACTIONS: 'АСТ',
    // ORIGINAL TEXT: ACT
    REFRESH: 'РЕФРЕШ',
    // ORIGINAL TEXT: REFRESH
    ADDNEWGROUP: 'НОВАЯ ГРУППА ПО ОДЗЗ',
    // ORIGINAL TEXT: ADD NEW GROUP
    ADDNEWDOCTYPE: 'НОВЫЙ ДОКТИП',
    // ORIGINAL TEXT: ADD NEW DOCTYPE
    ADDNEWALERT: 'НОВЫЙ ЗАДАЧ',
    // ORIGINAL TEXT: ADD NEW ALERT
    GROUPSLIST: 'СПИСОК ГРУППЫ ПО ОБЗОРУ',
    // ORIGINAL TEXT: REVIEW GROUP LIST

    DOWNLOADDOCUMENT: 'ДОКУМЕНТ DOWNLAD',
    // ORIGINAL TEXT: DOWNLOAD DOCUMENT
    DOCUMENTTITLE: 'Название документа',
    // ORIGINAL TEXT: Document Title
    DOCUMENTSUBJECT: 'Тема документа',
    // ORIGINAL TEXT: Document Subject
    SIGNMESSAGE: 'Сообщение для подписания',
    // ORIGINAL TEXT: Message for Signing
    NAMEPRIMARYSIGNER: 'Имя: Первичный сигнатур',
    // ORIGINAL TEXT: Name: Primary Signer
    EMAILPRIMARYSIGNER: 'Электронная почта: первичная подпись',
    // ORIGINAL TEXT: Email: Primary Signer
    NAMECOUNTERSIGNER: 'Фамилия: Совместитель',
    // ORIGINAL TEXT: Name: Counter Signer
    EMAILCOUNTERSIGNER: 'Электронная почта: противоподписчик',
    // ORIGINAL TEXT: Email: Counter Signer
    SIGREQUESTED: 'ЗАПРОШЕННЫЙ ПОДПИСАНИЕ',
    // ORIGINAL TEXT: SIGNATURE REQUESTED
    SIGREQUESTEDBY: 'ЗАПРОШЕНЫ',
    // ORIGINAL TEXT: REQUESTED BY
    SIGREQUESTEDID: 'Идентификатор подписи',
    // ORIGINAL TEXT: SIGNATURE ID
    SIGREQUESTEDAT: 'ЗАПРОШЕНЫ НА',
    // ORIGINAL TEXT: REQUESTED AT
    SUBMITSIG: 'ПОДПИСАНИЕ',
    // ORIGINAL TEXT: SUBMIT FOR SIGNATURE
    CANCELSIG: 'ПРОСЬБА О ПОДПИСАНИЕ КАНСИЛА',
    // ORIGINAL TEXT: CANCEL SIGNATURE REQUEST

    // DATA LABELS
    NAME: 'Фамилия',
    // ORIGINAL TEXT: NAME
    EMAIL: 'ЭМАЛИЯ',
    // ORIGINAL TEXT: EMAIL
    STATUS: 'Статус',
    // ORIGINAL TEXT: STATUS
    LASTVIEWED: 'ПОСЛЕДНИЕ СООБРАЖЕНИЯ',
    // ORIGINAL TEXT: LAST VIEWED
    LASTREVIEWED: 'ПОСЛЕДНИЙ ОБЗОР',
    // ORIGINAL TEXT: LAST REVIEWED
    SIGNEDAT: 'ПОДПИСАНИЕ',
    // ORIGINAL TEXT: SIGNED
    OWNERAPPROVAL: 'РУКОВОДЯЩЕЕ УТВЕРЖДЕНИЕ',
    // ORIGINAL TEXT: OWNER APPROVAL
    OWNERACTION: 'МЕРОПРИЯТИЯ В СВЯЗИ',
    // ORIGINAL TEXT: OWNER ACTION
    NEGOTIATORAPPROVAL: 'ОФИЦИАЛЬНОЕ УТВЕРЖДЕНИЕ НЕГОТАТОРА',
    // ORIGINAL TEXT: NEGOTIATOR APPROVAL
    NEGOTIATORACTION: 'Меры, принимаемые с помощью NEGOTIAP',
    // ORIGINAL TEXT: NEGOTIATOR ACTION
    ARCHIVENOTES: 'АРХИВНЫЕ ПРИМЕЧАНИЯ',
    // ORIGINAL TEXT: ARCHIVE NOTES
    EXECUTEDCONTRACT: 'ПОЛУЧЕННЫЙ',
    // ORIGINAL TEXT: EXECUTED
    EXECUTEDDATE: 'СРОКИ ПРОВЕДЕНИЯ',
    // ORIGINAL TEXT: EXECUTED DATE
    CONTRACTATTACHED: 'КОНТРАКТ ПОЛУЧЕН',
    // ORIGINAL TEXT: CONTRACT ATTACHED
    STARTDATE: 'Дата начала',
    // ORIGINAL TEXT: START DATE
    ENDDATE: 'Дата',
    // ORIGINAL TEXT: END DATE
    EDIT: 'ЭПТ',
    // ORIGINAL TEXT: EDIT
    USER: 'ПОЛЬЗОВАНИЕ',
    // ORIGINAL TEXT: USER
    TIME: 'Время',
    // ORIGINAL TEXT: TIME
    CONFIRM: 'КОНФИМ',
    // ORIGINAL TEXT: CONFIRM
    CONFIRMACTION: 'МЕРОПРИЯТИЯ ПО СОДЕЙСТВИЮ',
    // ORIGINAL TEXT: CONFIRM ACTION
    CURRENT_LABEL: 'НЫНЕШНЯЯ ЛАБЕЛЬ',
    // ORIGINAL TEXT: CURRENT LABEL
    NEW_LABEL: 'НОВЫЙ ЛАБЕЛЬ',
    // ORIGINAL TEXT: NEW LABEL
    DESCRIPTION: 'ОПИСАНИЕ',
    // ORIGINAL TEXT: DESCRIPTION
    TYPE: 'ТИПА',
    // ORIGINAL TEXT: TYPE
    SENTENCE: 'ВСТУПЛЕНИЕ',
    // ORIGINAL TEXT: SENTENCE
    NUMBER: '#',
    // ORIGINAL TEXT: #
    PRIVILEGE: 'РОЛЬ',
    // ORIGINAL TEXT: ROLE
    OWNERID: 'ОВНИРИД',
    // ORIGINAL TEXT: OWNERID
    DOCNAME: 'НАЗВАНИЯ ДОКУМЕНТОВ',
    // ORIGINAL TEXT: DOCUMENT NAME
    DOCTYPE: 'ТИПА ДОКУМЕНТА',
    // ORIGINAL TEXT: DOCUMENT TYPE
    DOCDESCRIPTION: 'ОПИСАНИЕ ДОКУМЕНТА',
    // ORIGINAL TEXT: DOCUMENT DESCRIPTION
    DOCNOTES: 'ПРИМЕЧАНИЯ К ДОКУМЕНТУ',
    // ORIGINAL TEXT: DOCUMENT NOTES
    DOCPARTY: 'Сторона',
    // ORIGINAL TEXT: PARTY
    DOCCOUNTERPARTY: 'ВСТУПИТЕЛЬНАЯ ГРУППА',
    // ORIGINAL TEXT: COUNTER PARTY
    DOCRAWFILENAME: 'ИМЯ ФАЙЛА ДОКУМЕНТА',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'ТАГС',
    // ORIGINAL TEXT: TAGS
    COLLABORATIONSTATUS: 'СОСТОЯНИЕ СОТРУДНИЧЕСТВА',
    // ORIGINAL TEXT: COLLABORATION STATUS
    COLLABORATIONEND: 'КОНЕЦ СОТРУДНИЧЕСТВА',
    // ORIGINAL TEXT: COLLABORATION END
    NEGOTIATIONSTATUS: 'СТАТУС ПЕРЕГОВОРОВ',
    // ORIGINAL TEXT: NEGOTIATION STATUS
    NEGOTIATIONEND: 'КОНЕЦ ПЕРЕГОВОРОВ',
    // ORIGINAL TEXT: NEGOTIATION END
    DOCID: 'Идентификационный номер документа',
    // ORIGINAL TEXT: DOCUMENT ID
    PARAGRAPHID: 'ПУНКТ ИД',
    // ORIGINAL TEXT: PARAGRAPH ID
    ACTION: 'ДЕЙСТВИЯ НА МЕСТАХ',
    // ORIGINAL TEXT: LOGGED ACTION
    DETAIL: 'ДЕЙСТВИЯ, ЗАДЕРЖАННЫЕ',
    // ORIGINAL TEXT: ACTION DETAIL
    CONTEXT: 'ДОПОЛНИТЕЛЬНЫЙ КОНТЕКСТ',
    // ORIGINAL TEXT: ADDITIONAL CONTEXT
    GROUPNAME: 'ГРУППА',
    // ORIGINAL TEXT: GROUP NAME
    GROUPDESC: 'ОПИСАНИЕ ГРУППЫ',
    // ORIGINAL TEXT: GROUP DESCRIPTION
    DOCTYPEDESC: 'ОПИСАНИЕ ДОКУМЕНТА',
    // ORIGINAL TEXT: DOCUMENT TYPE DESCRIPTION
    ADDLCLAUSETYPES: 'КУСТОМ ЛАБЕЛЫ',
    // ORIGINAL TEXT: CUSTOM CLAUSE LABELS
    ADDLTAGS: 'ЦЫПЛЕНОК',
    // ORIGINAL TEXT: CUSTOM TAGS
    COLLABORATIONRULES: 'ПРАВИЛА СОТРУДНИЧЕСТВА',
    // ORIGINAL TEXT: COLLABORATION RULES
    NEGOTIATIONRULES: 'ПРАВИЛА ПЕРЕГОВОРОВ',
    // ORIGINAL TEXT: NEGOTIATION RULES
    NOTIFICATIONRULES: 'ПРАВИЛА УВЕДОМЛЕНИЯ',
    // ORIGINAL TEXT: NOTIFICATION RULES
    LOG: 'LOG',
    // ORIGINAL TEXT: LOG
    ALERTNAME: 'АЛЬТЕРТ',
    // ORIGINAL TEXT: ALERT NAME
    ALERTDESC: 'ОПИСАНИЕ АЛЕРТА',
    // ORIGINAL TEXT: ALERT DESCRIPTION
    TOPICS: 'ТЕМЫ',
    // ORIGINAL TEXT: TOPICS
    RECIPIENTS: 'ОФИЦИЕНТЫ',
    // ORIGINAL TEXT: RECIPIENTS
    SELECTIVITY: 'ЭЛЕКТРОННОСТЬ',
    // ORIGINAL TEXT: SELECTIVITY
    FIELDNAME: 'FIELD NAME',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: 'ORDER',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: 'EXAMPLE',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: 'DEFAULT',
    // ORIGINAL TEXT: DEFAULT
    VALUE: 'VALUE',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: 'DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: 'PLAYBOOK',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: 'TEMPLATE',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: 'ARCHIVED DOCUMENT',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: 'ORIGINATED FROM TEMPLATE:',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: 'СОТРУДНИЧЕСТВО',
    // ORIGINAL TEXT: COLLABORATION
    NEGSHORT: 'ПЕРЕГОВОРОВ',
    // ORIGINAL TEXT: NEGOTIATION
    OWNERS: 'УВЕНЬЯ',
    // ORIGINAL TEXT: OWNERS
    COLLABORATORS: 'КОЛЛАБОРАТОРЫ',
    // ORIGINAL TEXT: COLLABORATORS
    NEGOTIATORS: 'ПЕРЕГОВОРОВ',
    // ORIGINAL TEXT: NEGOTIATORS
    // ADDITION
    DOCUMENTDETAILS: 'ЗАДЕРЖАНИЯ ДОКУМЕНТОВ',
    // ORIGINAL TEXT: DOCUMENT DETAILS
    CREATIONDATE: 'Дата ратификации',
    // ORIGINAL TEXT: CREATION DATE
    PARTICIPANTS: 'УЧАСТНИКИ',
    // ORIGINAL TEXT: PARTICIPANTS
    COLLABORATIONANDNEGOTIATION: 'СОТРУДНИЧЕСТВО И ПЕРЕГОВОРОВ',
    // ORIGINAL TEXT: COLLABORATION AND NEGOTIATION
    DOCTYPEANDGROUPACCESS: 'ТИПА ДОКУМЕНТА И ДОСТУП К ГРУППАМ',
    // ORIGINAL TEXT: DOCUMENT TYPE AND GROUP ACCESS
    GROUPOWNERS: 'ГРУППЫ',
    // ORIGINAL TEXT: GROUP OWNERS
    GROUPCOLLABORATORS: 'ГРУППА ЭКСПЕРТОВ',
    // ORIGINAL TEXT: GROUP COLLABORATORS
    DOCUMENTSETTINGS: 'ДОКУМЕНТНЫЕ СВЯЗИ',
    // ORIGINAL TEXT: DOCUMENT SETTINGS
    ENABLECOLLABTOMODLABELS: 'Позволить соавторам изменять знаки клаузулы',
    // ORIGINAL TEXT: Enable Collaborators to Modify Clause Labels
    ENABLENEGOTCAPABILITIES:
      'Включить переговорный потенциал (не переговорный режим)',
    // ORIGINAL TEXT: Enable Negotiation capabilities (Not Negotiation mode)
    KEEPDOCNOTES:
      'Копирование примечаний к документу с помощью операции копирования',
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS: 'Рассмотренные и измененные клаузулы',
    // ORIGINAL TEXT: Reviewed and Amended Clause Topics as Necessary
    NOEXECUTIONDATE: 'Дата неисполнение',
    // ORIGINAL TEXT: No Execution Date
    EXECUTIONDATE: 'Дата исполнения',
    // ORIGINAL TEXT: Execution Date
    CONTRACTSTARTDATE: 'Дата начала контракта',
    // ORIGINAL TEXT: Contract Start Date
    CONTRACTENDDATE: 'Дата окончания контракта',
    // ORIGINAL TEXT: Contract End Date
    EXECCONTRACTNAME: 'Исполняемое имя файла контракта',
    // ORIGINAL TEXT: Executed Contract File Name
    SELECTEXECCONTRACT: 'Выбрать файл для загрузки выполненного контракта',
    // ORIGINAL TEXT: select file to upload executed contract
    UPLOADEXECUTEDCONTRACT: 'КОНТРАКТЫ, ПРОИЗВОДИМЫЕ ЗА НЕСООТВЕТСТВИЕ',
    // ORIGINAL TEXT: UPLOAD EXECUTED CONTRACT
    LOADDEFAULTS: 'ГРУЗОВЫЕ ДЕФАУЛЫ',
    // ORIGINAL TEXT: LOAD DEFAULTS
    CLOSED: 'ЗАКРЫТЫЙ',
    // ORIGINAL TEXT: CLOSED
    NONE: 'Нет',
    // ORIGINAL TEXT: NONE
    NA: 'НА',
    // ORIGINAL TEXT: NA
    RESULTEXECUTEDCONTRACT: 'Выполненный контракт',
    // ORIGINAL TEXT: Resulted in Executed Contract
    NOSTARTDATE: 'Нет даты начала',
    // ORIGINAL TEXT: No Start Date
    NOENDDATE: 'Дата окончания отсутствует',
    // ORIGINAL TEXT: No End Date
    EXCLUDECOLLABORATORSFROMACCESS:
      'Исключая внешних партнеров из входного документа',
    // ORIGINAL TEXT: Exclude External Collaborators From Accessing Document
    EXCLUDENEGOTIATORSFROMACCESS:
      'Исключая внешних участников переговоров из доступного документа',
    // ORIGINAL TEXT: Exclude External Negotiators From Accessing Document
    REVIEWEDAMENDEDCLAUSELABELS: 'Рассмотренные и измененные клаузулы',
    // ORIGINAL TEXT: Reviewed and Amended Clause Labels as Necessary
    ENTERPARAGRAPHBETWEEN: 'Введите номер пункта между:',
    // ORIGINAL TEXT: Enter a paragraph number between:
    GOTOPARAGRAPHINDEX: 'Перейти к индексу пунктов',
    // ORIGINAL TEXT: Go to Paragraph Index
    INPUTDOCUMENTNOTEHERE: 'Вводное примечание',
    // ORIGINAL TEXT: Input Document Note here
    INPUTCOMMENTHERE: 'Ввод здесь',
    // ORIGINAL TEXT: Input Comment here
    SHOWPENDINGONLY: 'Показать только в ожидании',
    // ORIGINAL TEXT: Show Pending Only
    COLLABORATIONSETTING: 'ОБЪЯВЛЕНИЕ',
    // ORIGINAL TEXT: COLLABORATION SETTING
    NEGOTIATIONSETTING: 'ПЕРЕГОВОРОВ',
    // ORIGINAL TEXT: NEGOTIATION SETTING
    NONEGOTWHENAPPROVED:
      'Не может включать переговоры, когда они находятся в утвержденном государстве',
    // ORIGINAL TEXT: Cannot turn on Negotiation when in Approved State
    EMAILTOADD: 'Электронная почта для добавления',
    // ORIGINAL TEXT: Email to add
    CLAUSETOADD: 'Положение о добавлении',
    // ORIGINAL TEXT: Clause to add
    TAGTOADD: 'Метка для добавления',
    // ORIGINAL TEXT: Tag to add
    OPTIONSFORREDLINEINCLUDE: 'Варианты для перезагрузки',
    // ORIGINAL TEXT: Options for Redlines to Include
    ONLYINCLUDEOWNERAPPROVEDEDITS:
      'Включить только одобренный владельцем документ Правка',
    // ORIGINAL TEXT: Only Include Owner-approved Document Edits
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'Включить только редакторы документов, утвержденные владельцем и переговорщиком',
    // ORIGINAL TEXT: Only Include Owner and Negotiator-approved Document Edits
    INCLUDEALLDOCEDITS:
      'Включить все исправления документов (независимо от утверждения)',
    // ORIGINAL TEXT: Include All Document Edits (regardless of approval)
    REDLINECHANGESSELECTED: 'Выбранные изменения',
    // ORIGINAL TEXT: Redlined Changes Selected
    TOTALCHANGES: 'Всего изменений',
    // ORIGINAL TEXT: Total Changes
    REPLACEREDLINES: 'Заменить строки',
    // ORIGINAL TEXT: Replace Redlines
    DELETEREDLINES: 'Удалить реплики',
    // ORIGINAL TEXT: Delete Redlines
    ADDAFTERREDLINES: 'Добавить после строки',
    // ORIGINAL TEXT: Add-After Redlines
    OPTIONSFORPREVREDLINES: 'Варианты предыдущих размежеваний',
    // ORIGINAL TEXT: Options for Previous Redlines
    OVERWRITEPREVREDLINES:
      'Перезаписать все предыдущие разметки в источнике документов (неисполнение)',
    // ORIGINAL TEXT: Overwrite all Previous Redlines in Document Source (default)
    KEEPPREVREDLINES:
      'Сохранить все предыдущие разметки в источнике документов',
    // ORIGINAL TEXT: Keep all Previous Redlines in Document Source
    GENERATEFINALDOC: 'Подготовка заключительного документа (без развязок)',
    // ORIGINAL TEXT: Generate Final Document (without Redlines)
    DEFAULTESIGNOTE:
      'Как уже обсуждалось, просьба представить Вашу подпись на этом контракте.',
    // ORIGINAL TEXT: As discussed, please provide your eSignature to this contract.
    SUBMITTEDSIGMESSAGE: 'Подписывайтесь, пожалуйста.',
    // ORIGINAL TEXT: Submitted for signatures.  Please wait.
    UPLOADEDMESSAGE: 'Загружено',
    // ORIGINAL TEXT: Uploaded
    ADDADDLFILES:
      'Факультативное - добавление дополнительных файлов в запрос на подписание',
    // ORIGINAL TEXT: Optional - Adding additional files to signature request
    ADDFILESMSG: 'добавить дополнительные файлы для подписания',
    // ORIGINAL TEXT: add additional files for signature
    ESIGPREPAIDPLANS:
      'Функция электронной подписи доступна только в наших тарифных планах с предоплатой.',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      'Свяжитесь с нами по адресу billing@mylegaleinstein.com для получения более подробной информации.',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: 'More videos at the ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'Тип документа не поддерживается. MLE принимает только форматы Docx и pdf.',
    // ORIGINAL TEXT: Document type is not supported.  MLE only accepts docx and pdf formats.
    PDFSUPPORTEDFORPREMIUMONLY:
      'Для сканирования PDF пользователь нуждается в модернизации до абонемента Premium или лучше.',
    // ORIGINAL TEXT: For PDF scanning, user needs to upgrade to Premium subscription or better.
    BADDOCFORMAT:
      'Плохой формат документа был передан на обработку документов.',
    // ORIGINAL TEXT: Bad Document format was passed to document processing.
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      'Вы не имеете права вносить изменения в параметры документов. Вы обязаны быть владельцем.',
    // ORIGINAL TEXT: You do not have the privilege to make changes to the Document Settings.  You are required to be an Owner.
    PRIVILEGSNOTAVAIL:
      'Привилегии для этого пользователя отсутствуют. Попробуйте позже.',
    // ORIGINAL TEXT: The privileges for this user are not available.  Try again later.
    EXHAUSTEDTRIALLICENSE:
      'Вы исчерпали количество разрешённых документов в течение вашего испытательного периода. Пожалуйста, выберите в вашем профиле подписку "Повышение класса".',
    // ORIGINAL TEXT: You have exhausted the number of allowed documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    EXHAUSTEDMONTHLYLIMIT:
      'Вы исчерпали количество разрешённых ежемесячных документов в течение вашего испытательного периода.',
    // ORIGINAL TEXT: You have exhausted the number of allowed monthly documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    PASSWORDNOTMATCHING: 'Пароли не совпадают. Проверьте пароли снова.',
    // ORIGINAL TEXT: Passwords do not match.  Check the passwords again.
    ABAMEMBERIDLENGTH:
      'Идентификационные номера членов АБА составляют 7 знаков. Просмотрите и представьте.',
    // ORIGINAL TEXT: ABA Membership IDs are 7 characters long.  Please review and submit.
    TERMSNOTCHECKED:
      'Положения и условия не пересматривались и не проверялись.',
    // ORIGINAL TEXT: The Terms and Conditions have not been reviewed and checked.
    EMAILFORMATBAD:
      'Формат электронной почты недействителен. Проверьте адрес электронной почты.',
    // ORIGINAL TEXT: Email format is not valid.  Please review email address.
    CANCELEDACTION: 'Ваш последний шаг отменен, приложение перезагрузится.',
    // ORIGINAL TEXT: Your last action have been canceled.  Application will now reload.
    SUCCESSACTION: 'Ваш последний шаг был успешным, приложение перезагрузится.',
    // ORIGINAL TEXT: Your last action has been successful.  Application will now reload.
    USERSUCCESSLOGIN: 'Успешно зарегистрировано в качестве',
    // ORIGINAL TEXT: Successfully logged on as
    USERLOGGEDOUT: 'Пользователю выписали',
    // ORIGINAL TEXT: The user has been logged out
    EMAILNOTVERIFIED:
      'Е-мейл-почту еще не проверили. Вам была направлена проверка.',
    // ORIGINAL TEXT: Account email has not yet been verified.  An email verification has been sent to you.
    EMAILALREADYREGISTERED:
      'У этого письма уже есть ранее зарегистрированный счет.',
    // ORIGINAL TEXT: This email already has a previously registered account.
    EMAILNOTVERIFIEDRESENT:
      'Ваша электронная почта не была проверена. Мы возмущаемся тем, что вы получили ссылку на вашу электронную почту, чтобы активировать новый аккаунт.',
    // ORIGINAL TEXT: Your email has not been verified.  We have resent you an email verification link to your email to activate the new account.
    USERLOGFILENOTEXISTS:
      'Ошибка - не существует файла пользователя журнала для:',
    // ORIGINAL TEXT: Error - no log user file exists for:
    PASSWORDRESETSENT:
      'Сообщение о перезагрузке пароля было отправлено на ваш счет.',
    // ORIGINAL TEXT: An email password reset message has been sent for your account.
    PASSWORDCHANGED: 'Твой пароль был изменен.',
    // ORIGINAL TEXT: Your password has been changed.
    REGISTRATIONDELETED: 'исключенная регистрация пользователей',
    // ORIGINAL TEXT: deleted user registration
    USERACCOUNTUPDATED:
      'Вы обновили настройки пользовательских аккаунтов. Изменения вступят в силу в следующий раз.',
    // ORIGINAL TEXT: You have updated your user account settings.  Changes will take into effect the next time you login.
    UPGRADEDTOREFERRED: 'Ваш счет обновлен на отсроченную подписку на суд.',
    // ORIGINAL TEXT: Your account has been updated to Referred Trial subscription.
    REGISTEREDTRIALUSER: 'Новый счет зарегистрирован как подписка на суд.',
    // ORIGINAL TEXT: New account is registered as Trial subscription.
    DELETEDUSERACCOUNT: 'Удалённый аккаунт профиля пользователя',
    // ORIGINAL TEXT: Deleted user profile account
    EMAILALREADYREGISTEREDRESENT:
      'Электронная почта еще не зарегистрирована в "Мой юридический Эйнштейн".',
    // ORIGINAL TEXT: Email has not already been registered with Docgility.  An email invitiation has been sent to
    UPGRADEEXTERNALPAYMENTS:
      'Вы будете перенаправлены во внешнее платежное обслуживание, и после завершения вашей операции вам нужно будет снова войти в систему для начала подписки.',
    // ORIGINAL TEXT: Thanks for upgrading.  You will be rerouted to an external payment service and after completing your transaction, you will need to login again for subscription service to start.
    BILLINGEXTERNALPAYMENTS:
      'Пожалуйста, подтвердите, что вы хотели бы, чтобы вас направили на нашу внешнюю службу оплаты и выставления счетов. После внесения каких-либо изменений вам нужно будет вновь войти в систему для того, чтобы изменения вступили в силу.',
    // ORIGINAL TEXT: Please confirm that you will like to be directed to our external payment and billing service.  After you make any changes, you will need to login again for changes to take effect.
    NOTABLETOLOGINATTHISTIME:
      'Не в состоянии войти в это время. Попробуйте позже.',
    // ORIGINAL TEXT: Not able to login at this time.  Try again later.
    NOTREGISTERED:
      'Пользователь ранее не регистрировался. Зарегистрируйтесь перед тем, как войти в систему.',
    // ORIGINAL TEXT: User has not previously registered.  Register before trying to login.
    NAMETOOLONG: 'Имя, которое вы ввели, слишком длинное. Макс - 60 символов.',
    // ORIGINAL TEXT: The name you entered is too long.  Max is 60 characters.
    COMPANYTOOLONG: 'Компания, в которую вы вошли, слишком длинная.',
    // ORIGINAL TEXT: The company you entered is too long.  Max is 60 characters.
    COMMENTTOOLONG:
      'Комментарии, которые вы ввели, слишком длинные. Макс - 10000 символов.',
    // ORIGINAL TEXT: The comments you entered is too long.  Max is 10000 characters.
    DOCEDITTOOLONG:
      'Редактирование документов, которые вы ввели, слишком длинное. Макс - 10000 символов.',
    // ORIGINAL TEXT: The document edits you entered is too long.  Max is 10000 characters.
    TITLETOOLONG:
      'Заголовок, который вы ввели, слишком длинный. Макс - 60 символов.',
    // ORIGINAL TEXT: The title you entered is too long.  Max is 60 characters.
    PASSWORDTOOSHORT:
      'Пароль, который вы ввели, слишком короткий. Пароль должен быть как минимум 6 символов.',
    // ORIGINAL TEXT: The password you entered is too short.  Password has to be at least 6 characters.
    CONGRATSNEWDOCUMENTPROCESSING:
      'Отлично! Вы обработали новый документ. Это займет несколько минут, и панель должна освежиться, когда документ будет обработан. Пожалуйста, подождите несколько минут.',
    // ORIGINAL TEXT: Great! You have processed a new document.  It takes a few minutes and the panel should refresh when the document is completed processing.  Please wait a few minutes.
    NODOCUMENTLOADED: 'Документ не загружен',
    // ORIGINAL TEXT: No document loaded
    SELECTFILETOUPLOADDOCX: 'Выбрать файл для загрузки (*.docx)',
    // ORIGINAL TEXT: select file to upload (*.docx)
    SELECTFILETOUPLOADDOCXPDF: 'Выберите файл для загрузки (*.docx, *.pdf)',
    // ORIGINAL TEXT: select file to upload (*.docx, *.pdf)
    INPUTCOMMENTHERE: 'Ввод здесь',
    // ORIGINAL TEXT: Input comment here
    AUTOAPPROVEASOWNER: 'Автоматическое утверждение в качестве владельца',
    // ORIGINAL TEXT: Auto Approve as Owner
    INPUTDOCEDITSHERE: 'Введите документ Правка здесь',
    // ORIGINAL TEXT: Input Document Edit here
    TRIGGEREDALLALERTS:
      'Запустила все (если таковые имеются) предупреждения для этого документа доктипа.',
    // ORIGINAL TEXT: Triggered all (if any) Alerts for this doctype document.
    QSINTROMESSAGE:
      'MyLegalEinstein мгновенно анализирует ваши контракты, индексы в разбивке по юридическим темам, и позволяет сопоставлять правовые языки по бок о бок в полной платформе для сотрудничества по контрактам.',
    // ORIGINAL TEXT: MyLegalEinstein instantly analyzes your contracts, indexes by legal topics, and allows side-by-side comparisons of legal language in a complete contract collaboration platform. We will demonstrate the capabilities in four simple steps (detailed instructions are provided in english but similar in all languages.)
    QSDOCMESSAGE:
      'Для вашего удобства, вот несколько контрактов, которые вы можете загрузить и попробовать:',
    // ORIGINAL TEXT: For your convenience, here are some public domain contracts that you can download and try:
    QSHELPMESSAGE:
      'Здесь удобная ссылка на товарную документацию (с словесной версией Quick Start)',
    // ORIGINAL TEXT: Heres a handy link to product documentation (with wordy version of Quick Start)
    DOCUMENTFAILEDTOPROCESS:
      'При обработке документа произошла фатальная ошибка. Попробуйте еще раз. Если проблема сохраняется, обратитесь за поддержкой клиента по адресу support@mylegaleinstein.com.',
    // ORIGINAL TEXT: A fatal error occurred while processing the document.  Please try again.  If problem persists, please contact customer support at support@mylegaleinstein.com.
    // ADDED
    DOCUMENTSUCCESSCOPIED: 'Ваш документ скопирован.',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      'Чистая версия этого документа теперь создана и сохранена.',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      'A document was generated from this template and saved.',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE:
      'A document has been converted into a document template.',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      'Пожалуйста, подтвердите, что вы хотите удалить указанный документ. После того, как документ будет удален, он больше не будет доступен. Вы также можете рассмотреть вопрос о переносе документа в статус архива. nn, пожалуйста, подтвердите операцию по удалению.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified document.  After document is deleted, it will no longer be available.  You may also consider moving the document to Archive status.  \n\n Please confirm delete operation.
    CONFIRMSAVETOPLAYBOOK:
      'Пожалуйста, подтвердите, что для преобразования этого документа в игровую книгу. Это сделает этот документ незыблемым, за исключением владельца. nn, пожалуйста, подтвердите сохранение в игровую книгу.',
    // ORIGINAL TEXT: Please confirm that to convert this document to a playbook.  This would make this document unchangable except for the owner.  \n\n Please confirm the save to playbook operation.
    COLLABORATIONNOTE:
      'ПРИМЕЧАНИЕ: Эта информация передается только назначенным сотрудникам и владельцам документов.',
    // ORIGINAL TEXT: NOTE: This information is shared INTERNALLY ONLY with designated collaborators and document owners only.
    NEGOTIATIONNOTE:
      'ПРИМЕЧАНИЕ: Эта информация передается в устной форме контрсторонам, участвующим в переговорах.',
    // ORIGINAL TEXT: NOTE: This information is shared EXTERNALLY with negotiation counter parties.
    CONFIRMDELETEALERT:
      'Пожалуйста, подтвердите, что вы хотите удалить указанный сигнал тревоги. После того, как предупреждение будет удалено, он больше не будет доступен. Пожалуйста, подтвердите операцию по удалению.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified alert.  After alert is deleted, it will no longer be available.  Please confirm delete operation.
    CONFIRMDELETERECIPIENTALERT:
      'Пожалуйста, подтвердите, что вы больше не хотите быть получателем указанного предупреждения. После того как вы удалите себя как получателя, вы можете попросить, чтобы вас вернули, свяжитесь с владельцем предупреждения. Пожалуйста, подтвердите операцию удаления.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.
    CONFIRMDELETEGROUP:
      'Пожалуйста, подтвердите, что вы хотите удалить указанную группу. После того, как группа будет удалена, она больше не будет доступна. Однако она будет отражать следующий раз, когда вы войдёте обратно. Пожалуйста, подтвердите операцию Удаление.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified group.  After group is deleted, it will no longer be available.  However, it will reflected the next time you log back in.  Please confirm delete operation.
    CONFIRMDELETECOLLABORATORGROUP:
      'Пожалуйста, подтвердите, что вы больше не хотите быть сообщником для указанной группы. После того, как вы удалите себя в качестве сообщника, вы можете попросить, чтобы вас вернули, свяжитесь с владельцем группы. Пожалуйста, подтвердите операцию по удалению.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a collaborator for the specified group.  After removing yourself as a collaborator, you can request to be added back by contacting the owner of the group.  Please confirm delete operation.
    CONFIRMDELETEDOCTYPE:
      'Пожалуйста, подтвердите, что вы хотите удалить указанный доктип. После удаления доктипа он больше не будет доступен. Пожалуйста, подтвердите операцию по удалению.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified doctype.  After doctype is deleted, it will no longer be available.  Please confirm delete operation.
    EXPIREDTRIAL:
      'Срок действия вашей лицензии истек. Пожалуйста, продвиньтесь и продолжайте службу.',
    // ORIGINAL TEXT: Your trial license has expired.  Please upgrade to continue your service.
    EXPIREDSUBSCRIPTION:
      'Срок действия вашей подписной лицензии истек. Пожалуйста, продвиньтесь, чтобы продолжить обслуживание или контактный счет в моем юридическом Эйнштейне.',
    // ORIGINAL TEXT: Your subscription license has expired.  Please upgrade to continue your service or contact billing at Docgility.
    SOURCEDOCNOTDOCX: 'Документ источника не является документом DOCX.',
    // ORIGINAL TEXT: The Source document is not a DOCX document.  Redline capabilities are not available.
  },
}

export default RU
