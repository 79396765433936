<script>
import { dbauthComputed, dbauthActions, dbgroupComputed } from '@state/helpers'

import {
  formatDateDialog,
  displayUserName,
  displayUserInfo,
  docroute,
} from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'
import ConfirmAction from '@components/panel/para-dialog-confirmaction.vue'

// capabilities
// add new group
// assign doctype to group
// add owners
// add collaborators
// delete group

export default {
  components: {
    ConfirmAction,
    PanelCardTitle,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  data: function() {
    return {
      email: '', // for adding a new person.
      valid: false, // for validating if email is valid

      focusGroupItem: { ownerid: [], uniqid: [] }, // this is set when a button is pushed.
      addMode: false,
      showLog: false,

      // dialogs for confirming deletion operations
      confirmDelete: false,
      confirmDeleteitem: null,
      confirmDeleteCollaborator: false,

      // add group filed
      group: '',
      description: '',

      rules: {
        required: (value) => !!value || this.$t('VALIDMSGS.REQUIRED'),
        counter80: (value) =>
          value.length <= 80 || this.$t('VALIDMSGS.MAX80CHARS'),
        counter160: (value) =>
          value.length <= 160 || this.$t('VALIDMSGS.MAX160CHARS'),
      },
      validAdd: false, // check for validation rules

      // validation rules for email
      emailrules: [
        (v) => !!v || this.$t('VALIDMSGS.EMAILREQUIRED'),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t('VALIDMSGS.EMAILVALID'),
        (v) => v.length <= 60 || this.$t('VALIDMSGS.MAX60CHARS'),
        (v) =>
          this.focusGroupItem.ownerid.indexOf(v.toLowerCase()) < 0 ||
          this.$t('VALIDMSGS.EMAILALREADYOWNER'),
        (v) =>
          this.focusGroupItem.uniqid.indexOf(v.toLowerCase()) < 0 ||
          this.$t('VALIDMSGS.EMAILALREADYCOLLABORATOR'),
      ],
    }
  },

  computed: {
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
        this.email = ''
      },
    },

    sortedGroups() {
      let returnGroups = this.dbauthGroupsAccess

      for (var i = 0; i < returnGroups.length; i++) {
        if (returnGroups[i].ownerid.includes(this.dbauthuser.uniqid)) {
          returnGroups[i].privilege = 'owner'
        } else if (returnGroups[i].uniqid.includes(this.dbauthuser.uniqid)) {
          returnGroups[i].privilege = 'collaborator'
        }
      }
      return returnGroups.filter((e) => e.active === true)
    },

    availOwnerDocTypes() {
      let returnOwnerDocTypes = this.dbauthOwnerDocTypes
      returnOwnerDocTypes = returnOwnerDocTypes.filter((e) => e.active === true)
      return returnOwnerDocTypes
    },
  },
  methods: {
    ...dbgroupComputed,
    ...dbauthActions,

    refresh() {
      // this.resetAuth()
      this.$store.dispatch('dbauth/resetUserGroupAccessAuth')

      setTimeout(this.resetAuth, 2000)
    },

    resetAuth() {
      this.$store.dispatch('dbauth/getUserProfileAuth')
      this.$store.dispatch('dbauth/getUserGroupAccessAuth')
      this.$store.dispatch('dbauth/getUserOwnerDocTypesAuth')
    },
    // relay to use utility functions.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    docroute(d) {
      return docroute(d)
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },

    getGroupLog(groupitem) {
      this.focusGroupItem = groupitem
      this.showLog = !this.showLog
    },

    docTypeList(doctypelist) {
      var addArray = []
      var delArray = []
      var position = -1
      this.availOwnerDocTypes.forEach(function(e) {
        position = doctypelist.findIndex((f) => f === e.keyid)
        if (position < 0) {
          addArray.push(e)
        } else {
          delArray.push(e)
        }
      })
      return { addArray: addArray, delArray: delArray }
    },

    getDocTypeName(doctypeid) {
      let doctypes = this.availOwnerDocTypes
      var objIndex = doctypes.findIndex((obj) => obj.keyid === doctypeid)
      if (objIndex >= 0) {
        return doctypes[objIndex].doctype
      } else {
        return doctypeid
      }
    },

    // reverse function - given the doctype name, will give the ID.
    getDocTypeID(doctypename) {
      let doctypes = this.dbauthDocTypes
      var objIndex = doctypes.findIndex((obj) => obj.doctype === doctypename)
      if (objIndex >= 0) {
        return doctypes[objIndex].keyid
      } else {
        return doctypename
      }
    },

    addGroup() {
      if (this.validAdd) {
        this.writeGroup({
          uniqid: this.dbauthuser.uniqid,
          group: this.group,
          description: this.description,
          ownerid: this.dbauthuser.uniqid,
        })

        this.refresh()
        // flip it back to list
        this.addMode = !this.addMode
      }
      // reset the defaults for add
      this.group = ''
      this.description = ''
    },

    deleteGroupAct(groupitem) {
      this.confirmDeleteitem = groupitem
      this.confirmDelete = true
    },
    confirmedDeleteGroupAct() {
      this.deleteGroup({
        uniqid: this.dbauthuser.uniqid,
        groupid: this.confirmDeleteitem.keyid,
      })

      this.refresh()
    },

    addOwnerGroupAct(groupitem) {
      this.focusGroupItem = groupitem

      if (this.valid) {
        const MyThis = this
        this.queryValidIDAuth({ uniqid: this.email.toLowerCase() }).then(
          function(bool) {
            if (bool) {
              MyThis.addOwnerGroup({
                uniqid: MyThis.dbauthuser.uniqid,
                groupid: groupitem.keyid,
                ownerid: MyThis.email.toLowerCase(),
                context: '',
              })
              MyThis.refresh()

              MyThis.refresh()
            } else {
              MyThis.$store.dispatch(
                'dbuserinfo/addEmailReferralIfNotRegisteredUserInfo',
                {
                  referralid: MyThis.email.toLowerCase(),
                  uniqid: MyThis.dbauthuser.uniqid,
                  name: '',
                  docid: '',
                  docname: '',
                  docdescription: '',
                  privilege: 'owner of group',
                }
              )
              return false
            }
          }
        )
      }
    },

    deleteOwnerGroupAct(groupitem, ownerid) {
      this.deleteOwnerGroup({
        uniqid: this.dbauthuser.uniqid,
        groupid: groupitem.keyid,
        ownerid: ownerid,
      })

      this.refresh()
    },

    addCollabGroupAct(groupitem) {
      this.focusGroupItem = groupitem
      if (this.valid) {
        const MyThis = this
        this.queryValidIDAuth({ uniqid: this.email.toLowerCase() }).then(
          function(bool) {
            if (bool) {
              MyThis.addCollabGroup({
                uniqid: MyThis.dbauthuser.uniqid,
                groupid: groupitem.keyid,
                uniqidadd: MyThis.email.toLowerCase(),
                context: '',
              })
              MyThis.refresh()
            } else {
              MyThis.$store.dispatch(
                'dbuserinfo/addEmailReferralIfNotRegisteredUserInfo',
                {
                  referralid: MyThis.email.toLowerCase(),
                  uniqid: MyThis.dbauthuser.uniqid,
                  name: MyThis.displayUserName(MyThis.dbauthuser.uniqid),
                  docid: '',
                  docname: '',
                  docdescription: '',
                  privilege: 'collaborator of group',
                }
              )
              return false
            }
          }
        )
      }
    },

    deleteCollabGroupAct(groupitem, uniqid) {
      this.deleteCollabGroup({
        uniqid: this.dbauthuser.uniqid,
        groupid: groupitem.keyid,
        uniqiddel: uniqid,
      })

      this.refresh()
    },

    // this is used to remove self from an established Group.
    // Group owner can add a participant as collaborator but collaborator can delete themselves as well.
    removeCollaboratorGroupAct(groupitem) {
      this.confirmDeleteitem = groupitem
      this.confirmDeleteCollaborator = true
    },

    confirmedDeleteCollaboratoGroupAct() {
      this.deleteCollabGroup({
        uniqid: this.dbauthuser.uniqid,
        groupid: this.confirmDeleteitem.keyid,
        uniqiddel: this.dbauthuser.uniqid,
      })
      this.refresh()
    },

    addDocTypeGroupAct(groupitem, doctype) {
      this.addDocTypeGroup({
        uniqid: this.dbauthuser.uniqid,
        groupid: groupitem.keyid,
        doctypeid: doctype.keyid,
      })

      this.refresh()
    },

    deleteDocTypeGroupAct(groupitem, doctype) {
      this.deleteDocTypeGroup({
        uniqid: this.dbauthuser.uniqid,
        groupid: groupitem.keyid,
        doctypeid: doctype.keyid,
      })

      this.refresh()
    },
    displayAction(action) {
      // backward compatible
      if (action.includes(' ')) {
        return action
      }
      return this.$t(action)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <ConfirmAction
      :dialog="confirmDelete"
      :confirmationmessage="$t('INFO_MESSAGES.CONFIRMDELETEGROUP')"
      @close-dialog="confirmDelete = false"
      @confirm="confirmedDeleteGroupAct"
    />
    <ConfirmAction
      :dialog="confirmDeleteCollaborator"
      :confirmationmessage="$t('INFO_MESSAGES.CONFIRMDELETECOLLABORATORGROUP')"
      @close-dialog="confirmDeleteCollaborator = false"
      @confirm="confirmedDeleteCollaboratoGroupAct"
    />
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <PanelCardTitle :cardtitle="$t('DIALOGS.GROUPS')"></PanelCardTitle>
        <div v-if="!addMode">
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-simple-table :class="$style.dialogTable" fixed-header>
                  <template>
                    <thead :class="$style.dialogHead">
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.PRIVILEGE') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.GROUPS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.GROUPDESC') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.LOG') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.DELETE') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.OWNERS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.COLLABORATORS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.DOCTYPE') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="groupitem in sortedGroups"
                        :key="groupitem.groupid"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus">{{
                          groupitem.privilege
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          groupitem.group
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          groupitem.description
                        }}</td>
                        <td :class="$style.dialogCellStatus"
                          ><v-btn small icon @click="getGroupLog(groupitem)">
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn></td
                        >
                        <td :class="$style.dialogCellStatus"
                          ><v-btn
                            v-if="groupitem.privilege === 'owner'"
                            small
                            text
                            @click="deleteGroupAct(groupitem)"
                          >
                            <v-icon>mdi-delete</v-icon> </v-btn
                          ><v-btn
                            v-if="groupitem.privilege !== 'owner'"
                            small
                            text
                            @click="removeCollaboratorGroupAct(groupitem)"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn></td
                        >
                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item
                              v-for="owner in groupitem.ownerid"
                              :key="owner"
                            >
                              <v-list-item-subtitle
                                ><v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{
                                      displayUserName(owner)
                                    }}</span>
                                  </template>
                                  <span>{{ displayUserInfo(owner) }}</span>
                                </v-tooltip>
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-row class="my-2">
                            <v-btn
                              v-if="groupitem.privilege === 'owner'"
                              small
                              class="white--text black"
                              @click="addOwnerGroupAct(groupitem)"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu
                              v-if="groupitem.ownerid.length > 0"
                              offset-x
                            >
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="groupitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="ownerid in groupitem.ownerid"
                                  :key="ownerid"
                                  @click="
                                    deleteOwnerGroupAct(groupitem, ownerid)
                                  "
                                >
                                  <v-list-item-subtitle
                                    >{{ ownerid }}
                                  </v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item
                              v-for="uniqid in groupitem.uniqid"
                              :key="uniqid"
                            >
                              <v-list-item-subtitle
                                ><v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{
                                      displayUserName(uniqid)
                                    }}</span>
                                  </template>
                                  <span>{{ displayUserInfo(uniqid) }}</span>
                                </v-tooltip>
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-row class="my-2">
                            <v-btn
                              v-if="groupitem.privilege === 'owner'"
                              small
                              class="white--text black"
                              @click="addCollabGroupAct(groupitem)"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu v-if="groupitem.uniqid.length > 0" offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="groupitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="uniqid in groupitem.uniqid"
                                  :key="uniqid"
                                  @click="
                                    deleteCollabGroupAct(groupitem, uniqid)
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    uniqid
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item
                              v-for="doctypeid in groupitem.doctypeid"
                              :key="doctypeid"
                            >
                              <v-list-item-subtitle
                                >{{ getDocTypeName(doctypeid) }}
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-row class="my-2">
                            <v-menu
                              v-if="
                                docTypeList(groupitem.doctypeid).addArray
                                  .length > 0
                              "
                              offset-x
                            >
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="groupitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="doctype in docTypeList(
                                    groupitem.doctypeid
                                  ).addArray"
                                  :key="doctype.keyid"
                                  @click="
                                    addDocTypeGroupAct(groupitem, doctype)
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    doctype.doctype
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu
                              v-if="
                                docTypeList(groupitem.doctypeid).delArray
                                  .length > 0
                              "
                              offset-x
                            >
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="groupitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="doctype in docTypeList(
                                    groupitem.doctypeid
                                  ).delArray"
                                  :key="doctype.keyid"
                                  @click="
                                    deleteDocTypeGroupAct(groupitem, doctype)
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    doctype.doctype
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-card-text>

          <div v-if="showLog" class="mx-9">
            <p>
              Group Log:
            </p>
            <p>Group Name: {{ focusGroupItem.group }} </p>
            <p>Group Description: {{ focusGroupItem.description }} </p>
            <p>Owners:</p>
            <ul>
              <li v-for="owner in focusGroupItem.ownerid" :key="owner">
                {{ owner }}
              </li>
            </ul>
            <v-container>
              <v-row>
                <v-simple-table :class="$style.dialogOpenTable" fixed-header>
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                      fixed-header
                    >
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.USER') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.ACTION') }}</th
                        >

                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.CONTEXT') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          >{{ $t('DIALOGS.TIME') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in focusGroupItem.log"
                        :key="
                          item.uniqid + item.action + item.timestamp.toString()
                        "
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus">{{
                          item.uniqid
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          displayAction(item.action)
                        }}</td>

                        <td :class="$style.dialogCellWidth">{{
                          item.context
                        }}</td>
                        <td :class="$style.dialogBigCellStatus">{{
                          formatDateDialog(item.timestamp)
                        }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </div>
          <v-row>
            <v-col cols="12" sm="4">
              <v-form v-model="valid">
                <v-text-field
                  v-model="email"
                  class="mx-4"
                  :rules="emailrules"
                  :label="$t('DIALOGS.EMAILTOADD')"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </div>
        <div v-if="addMode">
          <v-form v-model="validAdd">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="group"
                  :rules="[rules.required, rules.counter80]"
                  :label="$t('DIALOGS.GROUPNAME')"
                  required
                  class="mx-9"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="description"
                  :rules="[rules.counter160]"
                  :label="$t('DIALOGS.GROUPDESC')"
                  class="mx-9"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>

        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('admin/groups')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer
          ><v-btn v-if="true" large outlined class="mx-4" @click="refresh">{{
            $t('DIALOGS.REFRESH')
          }}</v-btn>
          <v-btn
            v-if="showLog"
            large
            outlined
            class="mx-4"
            @click="showLog = !showLog"
            >HIDE LOG</v-btn
          >
          <v-btn v-if="addMode" large outlined class="mx-4" @click="addGroup">{{
            $t('DIALOGS.SAVE')
          }}</v-btn>

          <v-btn
            v-if="!addMode"
            large
            outlined
            class="mx-4"
            @click="addMode = !addMode"
            >{{ $t('DIALOGS.ADDNEWGROUP') }}</v-btn
          >
          <v-btn
            v-if="addMode"
            large
            outlined
            class="mx-4"
            @click="addMode = !addMode"
            >{{ $t('DIALOGS.GROUPSLIST') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
