export const state = {
  filterTopicView: false,
  filterTopic: { action: 'ALL' },
  paraContext: '',
  panelsToShow: 2,

  // searchStates
  searchOn: false,
  searchCapsOn: false,
  searchText: '',
  searchChanged: false,

  // compareStates
  comparedocid: '',
  comparepara: '',
  compareChange: false,
}

export const getters = {
  filterTopicView(state) {
    return state.filterTopicView
  },
  filterTopic(state) {
    return state.filterTopic
  },
  paraContext(state) {
    return state.paraContext
  },
  panelsToShow(state) {
    return state.panelsToShow
  },
  searchOn(state) {
    return state.searchOn
  },
  searchCapsOn(state) {
    return state.searchCapsOn
  },
  searchText(state) {
    return state.searchText
  },
  searchChanged(state) {
    return state.searchChanged
  },
  comparedocid(state) {
    return state.comparedocid
  },
  comparepara(state) {
    return state.comparepara
  },
  compareChange(state) {
    return state.compareChange
  },
}

export const mutations = {
  SET_FILTERTOPICVIEW(state, val) {
    state.filterTopicView = val
  },
  SET_FILTERTOPIC(state, val) {
    state.filterTopic = val
  },
  SET_PARACONTEXT(state, val) {
    state.paraContext = val
  },
  SET_PANELSTOSHOW(state, val) {
    state.panelsToShow = val
  },
  SET_SEARCH(state, val) {
    state.searchOn = val.searchOn
    state.searchCapsOn = val.searchCapsOn
    state.searchText = val.searchText
    state.searchChanged = val
  },

  SET_COMPAREIDPARA(state, val) {
    state.comparedocid = val.comparedocid
    state.comparepara = val.comparepara
    state.compareChange = val
  },
}

export const actions = {
  init({ commit }) {},

  setFilterTopicView({ commit, state }, val) {
    commit('SET_FILTERTOPICVIEW', val)
  },
  setFilterTopic({ commit, state }, val) {
    commit('SET_FILTERTOPIC', val)
  },
  setParaContext({ commit, state }, val) {
    commit('SET_PARACONTEXT', val)
  },
  setPanelsToShow({ commit, state }, val) {
    commit('SET_PANELSTOSHOW', val)
  },
  setSearch({ commit, state }, val) {
    commit('SET_SEARCH', val)
  },
  setDocCompareIDpara({ commit, state }, val) {
    commit('SET_COMPAREIDPARA', val)
  },
}
