<script>
import { dbauthComputed, dbauthActions } from '@state/helpers'
import {
  formatDateDialog,
  compareArrays,
  displayUserName,
  displayUserInfo,
  docroute,
} from '@utils/dialogutils'
import { getCategoryNames } from '@utils/clauseMapping'

import PanelCardTitle from './panel-cardtitle.vue'
import ConfirmAction from '@components/panel/para-dialog-confirmaction.vue'

import { getDefaultTagOptions } from '@utils/workflowMapping'

// capabilities
// add new doctype
// delete doctype
// add owners
// delete owners
// add and delete addl clauses
// add and delete addl tags
// add and delete addl collabrules
// add and delete addl notificationrules
// add and delete addl negotiationsrules

// to do -
// validation rules -
// addl clause types cannot be one of the default clause types
// tags cannot be one of the default tag types.

// to assign groups to doctypes, assign via the group.
// only the owner of the doctype can add it to a group (that it also owns)

export default {
  components: {
    ConfirmAction,
    PanelCardTitle,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  data: function() {
    return {
      email: '', // for adding a new person.
      clauseortag: '', // for adding a new clause or tag.
      clause: '',
      tag: '',

      valid: false, // for validating if email is valid
      validClause: false, // valid clause entry
      validTag: false, // valid tag entry

      focusDocTypeItem: { ownerid: [], uniqid: [] }, // this is set when a button is pushed.
      addMode: false,
      showLog: false,

      // dialogs for confirming deletion operations
      confirmDelete: false,
      confirmDeleteitem: null,

      // add doctype
      doctypename: '',
      description: '',

      collaborationOptions: [],
      negotiationOptions: [],
      notificationOptions: [],
      defaultTagOptions: getDefaultTagOptions(),
      defaultClauseList: getCategoryNames(),

      testarray: ['A', 'C'],

      corporateMode: false,

      rules: {
        required: (value) => !!value || this.$t('VALIDMSGS.REQUIRED'),
        counter80: (value) =>
          value.length <= 80 || this.$t('VALIDMSGS.MAX80CHARS'),
        counter160: (value) =>
          value.length <= 160 || this.$t('VALIDMSGS.MAX160CHARS'),
      },
      validAdd: false, // check for validation rules

      // validation rules for email
      emailrules: [
        (v) => !!v || this.$t('VALIDMSGS.EMAILREQUIRED'),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t('VALIDMSGS.EMAILVALID'),
        (v) => v.length <= 60 || this.$t('VALIDMSGS.MAX60CHARS'),
        (v) =>
          this.focusDocTypeItem.ownerid.indexOf(v.toLowerCase()) < 0 ||
          this.$t('VALIDMSGS.EMAILALREADYOWNER'),
      ],

      clauserules: [
        (v) => !!v || this.$t('VALIDMSGS.CLAUSEFIELDREQUIRED'),
        (v) => v.length <= 20 || this.$t('VALIDMSGS.MAX20CHARS'),
        (v) =>
          this.defaultClauseList.indexOf(v) < 0 ||
          this.$t('VALIDMSGS.CLAUSENOTDEFAULT'),
      ],

      tagrules: [
        (v) => !!v || this.$t('VALIDMSGS.TAGFIELDREQUIRED'),
        (v) => v.length <= 20 || this.$t('VALIDMSGS.MAX20CHARS'),
        (v) =>
          this.defaultTagOptions.indexOf(v) < 0 ||
          this.$t('VALIDMSGS.TAGNOTDEFAULT'),
      ],
    }
  },

  computed: {
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
        this.email = ''
      },
    },

    availOwnerDocTypes() {
      let returnOwnerDocTypes = this.dbauthOwnerDocTypes
      returnOwnerDocTypes = returnOwnerDocTypes.filter((e) => e.active === true)
      return returnOwnerDocTypes
    },
  },
  methods: {
    ...dbauthActions,

    refresh() {
      setTimeout(this.refreshDocTypes, 3000)
    },

    refreshDocTypes() {
      this.$store.dispatch('dbauth/getUserOwnerDocTypesAuth')
    },

    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },

    // relay to use utility functions.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    compareArrays(d, e) {
      return compareArrays(d, e)
    },
    docroute(d) {
      return docroute(d)
    },

    getDocTypeLog(doctypeitem) {
      this.focusDocTypeItem = doctypeitem
      this.showLog = !this.showLog
    },

    getDocTypeName(doctypeid) {
      let doctypes = this.availOwnerDocTypes
      var objIndex = doctypes.findIndex((obj) => obj.keyid === doctypeid)
      if (objIndex >= 0) {
        return doctypes[objIndex].doctype
      } else {
        return doctypeid
      }
    },

    addDocType() {
      if (this.validAdd) {
        this.$store.dispatch('dbdoctype/writeDocType', {
          uniqid: this.dbauthuser.uniqid,
          doctype: this.doctypename,
          description: this.description,
          ownerid: this.dbauthuser.uniqid,
        })
        this.refresh()

        // flip it back to list
        this.addMode = !this.addMode
      }

      // reset the defaults
      this.doctypename = ''
      this.description = ''
    },

    deleteDocTypeAct(doctypeitem) {
      this.confirmDeleteitem = doctypeitem
      this.confirmDelete = true
    },

    confirmedDeleteDocTypeAct() {
      this.$store.dispatch('dbdoctype/deleteDocType', {
        uniqid: this.dbauthuser.uniqid,
        doctypeid: this.confirmDeleteitem.keyid,
      })
      this.refresh()
    },

    addOwnerDocTypeAct(doctypeitem) {
      this.focusDocTypeItem = doctypeitem
      if (this.valid) {
        const MyThis = this
        this.queryValidIDAuth({ uniqid: this.email.toLowerCase() }).then(
          function(bool) {
            if (bool) {
              MyThis.$store.dispatch('dbdoctype/addOwnerDocType', {
                uniqid: MyThis.dbauthuser.uniqid,
                doctypeid: doctypeitem.keyid,
                ownerid: MyThis.email.toLowerCase(),
              })

              MyThis.refresh()
            } else {
              MyThis.$store.dispatch(
                'dbuserinfo/addEmailReferralIfNotRegisteredUserInfo',
                {
                  referralid: MyThis.email.toLowerCase(),
                  uniqid: MyThis.dbauthuser.uniqid,
                  name: MyThis.displayUserName(MyThis.dbauthuser.uniqid),
                  docid: '',
                  docname: '',
                  docdescription: '',
                  privilege: 'adding owner to doctype',
                }
              )
              return false
            }
          }
        )
      }
    },

    deleteOwnerDocTypeAct(doctypeitem, ownerid) {
      this.$store.dispatch('dbdoctype/deleteOwnerDocType', {
        uniqid: this.dbauthuser.uniqid,
        doctypeid: doctypeitem.keyid,
        ownerid: ownerid,
      })

      this.refresh()
    },

    addAddlClauseDocTypeAct(doctypeitem) {
      this.focusDocTypeItem = doctypeitem
      if (this.validClause) {
        this.$store.dispatch('dbdoctype/addAddlClauseDocType', {
          uniqid: this.dbauthuser.uniqid,
          doctypeid: doctypeitem.keyid,
          addlclause: this.clause,
        })
        this.refresh()
      }
    },

    deleteAddlClauseDocTypeAct(doctypeitem, addlclause) {
      this.$store.dispatch('dbdoctype/deleteAddlClauseDocType', {
        uniqid: this.dbauthuser.uniqid,
        doctypeid: doctypeitem.keyid,
        addlclause: addlclause,
      })

      this.refresh()
    },

    addAddlTagDocTypeAct(doctypeitem) {
      this.focusDocTypeItem = doctypeitem
      if (this.validTag) {
        this.$store.dispatch('dbdoctype/addAddlTagDocType', {
          uniqid: this.dbauthuser.uniqid,
          doctypeid: doctypeitem.keyid,
          addltag: this.tag,
        })

        this.refresh()
      }
    },

    deleteAddlTagDocTypeAct(doctypeitem, addltag) {
      this.$store.dispatch('dbdoctype/deleteAddlTagDocType', {
        uniqid: this.dbauthuser.uniqid,
        doctypeid: doctypeitem.keyid,
        addltag: addltag,
      })

      this.refresh()
    },

    addCollabRuleDocTypeAct(doctypeitem, collaborationrule) {
      this.$store.dispatch('dbdoctype/addCollabRuleDocType', {
        uniqid: this.dbauthuser.uniqid,
        doctypeid: doctypeitem.keyid,
        collaborationrule: collaborationrule,
      })

      this.refresh()
    },

    deleteCollabRuleDocTypeAct(doctypeitem, collaborationrule) {
      this.$store.dispatch('dbdoctype/deleteCollabRuleDocType', {
        uniqid: this.dbauthuser.uniqid,
        doctypeid: doctypeitem.keyid,
        collaborationrule: collaborationrule,
      })

      this.refresh()
    },

    addNotificationRuleDocTypeAct(doctypeitem, notificationrule) {
      this.$store.dispatch('dbdoctype/addNotificationRuleDocType', {
        uniqid: this.dbauthuser.uniqid,
        doctypeid: doctypeitem.keyid,
        notificationrule: notificationrule,
      })

      this.refresh()
    },

    deleteNotificationRuleDocTypeAct(doctypeitem, notificationrule) {
      this.$store.dispatch('dbdoctype/deleteNotificationRuleDocType', {
        uniqid: this.dbauthuser.uniqid,
        doctypeid: doctypeitem.keyid,
        notificationrule: notificationrule,
      })

      this.refresh()
    },

    addNegotiationRuleDocTypeAct(doctypeitem, negotiationrule) {
      this.$store.dispatch('dbdoctype/addNegotiationRuleDocType', {
        uniqid: this.dbauthuser.uniqid,
        doctypeid: doctypeitem.keyid,
        negotiationrule: negotiationrule,
      })

      this.refresh()
    },

    deleteNegotiationRuleDocTypeAct(doctypeitem, negotiationrule) {
      this.$store.dispatch('dbdoctype/deleteNegotiationRuleDocType', {
        uniqid: this.dbauthuser.uniqid,
        doctypeid: doctypeitem.keyid,
        negotiationrule: negotiationrule,
      })

      this.refresh()
    },
    displayAction(action) {
      if (action.includes(' ')) {
        return action
      }
      return this.$t(action)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <ConfirmAction
      :dialog="confirmDelete"
      :confirmationmessage="$t('INFO_MESSAGES.CONFIRMDELETEDOCTYPE')"
      @close-dialog="confirmDelete = false"
      @confirm="confirmedDeleteDocTypeAct"
    />
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <PanelCardTitle :cardtitle="$t('DIALOGS.DOCTYPES')"></PanelCardTitle>
        <div v-if="!addMode">
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-simple-table :class="$style.dialogTable" fixed-header>
                  <template>
                    <thead :class="$style.dialogHead">
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.PRIVILEGE') }}</th
                      ><th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.DOCTYPE') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.DOCTYPEDESC') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.LOG') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.DELETE') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.OWNERS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ADDLCLAUSETYPES') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ADDLTAGS') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        v-if="corporateMode"
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.COLLABORATIONRULES') }}</th
                      >
                      <th
                        v-if="corporateMode"
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        v-if="corporateMode"
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.NEGOTIATIONRULES') }}</th
                      >
                      <th
                        v-if="corporateMode"
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                      <th
                        v-if="corporateMode"
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.NOTIFICATIONRULES') }}</th
                      >
                      <th
                        v-if="corporateMode"
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="doctypeitem in availOwnerDocTypes"
                        :key="doctypeitem.doctypeid"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus">{{
                          doctypeitem.privilege
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          doctypeitem.doctype
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          doctypeitem.description
                        }}</td>
                        <td :class="$style.dialogCellStatus"
                          ><v-btn
                            small
                            icon
                            @click="getDocTypeLog(doctypeitem)"
                          >
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn></td
                        >
                        <td :class="$style.dialogCellStatus"
                          ><v-btn
                            v-if="doctypeitem.privilege === 'owner'"
                            small
                            text
                            @click="deleteDocTypeAct(doctypeitem)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn></td
                        >
                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item
                              v-for="owner in doctypeitem.ownerid"
                              :key="owner"
                            >
                              <v-list-item-subtitle
                                ><v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{
                                      displayUserName(owner)
                                    }}</span>
                                  </template>
                                  <span>{{ displayUserInfo(owner) }}</span>
                                </v-tooltip>
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-row class="my-2">
                            <v-btn
                              v-if="doctypeitem.privilege === 'owner'"
                              small
                              class="white--text black"
                              @click="addOwnerDocTypeAct(doctypeitem)"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="doctypeitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="ownerid in doctypeitem.ownerid"
                                  :key="ownerid"
                                  @click="
                                    deleteOwnerDocTypeAct(doctypeitem, ownerid)
                                  "
                                >
                                  <v-list-item-subtitle
                                    >{{ ownerid }}
                                  </v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item
                              v-for="item in doctypeitem.addlclauses"
                              :key="item"
                            >
                              <v-list-item-subtitle
                                >{{ item }}
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-row class="my-2">
                            <v-btn
                              v-if="doctypeitem.privilege === 'owner'"
                              small
                              class="white--text black"
                              @click="addAddlClauseDocTypeAct(doctypeitem)"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="doctypeitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in doctypeitem.addlclauses"
                                  :key="item"
                                  @click="
                                    deleteAddlClauseDocTypeAct(
                                      doctypeitem,
                                      item
                                    )
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item
                              v-for="item in doctypeitem.addltags"
                              :key="item"
                            >
                              <v-list-item-subtitle
                                >{{ item }}
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>

                        <td :class="$style.dialogCellStatus">
                          <v-row class="my-2">
                            <v-btn
                              v-if="doctypeitem.privilege === 'owner'"
                              small
                              class="white--text black"
                              @click="addAddlTagDocTypeAct(doctypeitem)"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="doctypeitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in doctypeitem.addltags"
                                  :key="item"
                                  @click="
                                    deleteAddlTagDocTypeAct(doctypeitem, item)
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>

                        <td
                          v-if="corporateMode"
                          :class="$style.dialogCellStatus"
                        >
                          <v-list>
                            <v-list-item
                              v-for="item in doctypeitem.collaborationrules"
                              :key="item"
                            >
                              <v-list-item-subtitle
                                >{{ item }}
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>
                        <td
                          v-if="corporateMode"
                          :class="$style.dialogCellStatus"
                        >
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="doctypeitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    doctypeitem.collaborationrules,
                                    collaborationOptions
                                  ).addArray"
                                  :key="item"
                                  @click="
                                    addCollabRuleDocTypeAct(doctypeitem, item)
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="doctypeitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    doctypeitem.collaborationrules,
                                    collaborationOptions
                                  ).delArray"
                                  :key="item"
                                  @click="
                                    deleteCollabRuleDocTypeAct(
                                      doctypeitem,
                                      item
                                    )
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>

                        <td
                          v-if="corporateMode"
                          :class="$style.dialogCellStatus"
                        >
                          <v-list>
                            <v-list-item
                              v-for="item in doctypeitem.notificationrules"
                              :key="item"
                            >
                              <v-list-item-subtitle
                                >{{ item }}
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>
                        <td
                          v-if="corporateMode"
                          :class="$style.dialogCellStatus"
                        >
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="doctypeitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    doctypeitem.notificationrules,
                                    notificationOptions
                                  ).addArray"
                                  :key="item"
                                  @click="
                                    addNotificationRuleDocTypeAct(
                                      doctypeitem,
                                      item
                                    )
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="doctypeitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    doctypeitem.notificationrules,
                                    notificationOptions
                                  ).delArray"
                                  :key="item"
                                  @click="
                                    deleteNotificationRuleDocTypeAct(
                                      doctypeitem,
                                      item
                                    )
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>

                        <td
                          v-if="corporateMode"
                          :class="$style.dialogCellStatus"
                        >
                          <v-list>
                            <v-list-item
                              v-for="item in doctypeitem.negotiationrules"
                              :key="item"
                            >
                              <v-list-item-subtitle
                                >{{ item }}
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </td>
                        <td
                          v-if="corporateMode"
                          :class="$style.dialogCellStatus"
                        >
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="doctypeitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    doctypeitem.negotiationrules,
                                    negotiationOptions
                                  ).addArray"
                                  :key="item"
                                  @click="
                                    addNegotiationRuleDocTypeAct(
                                      doctypeitem,
                                      item
                                    )
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu offset-x>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  v-if="doctypeitem.privilege === 'owner'"
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in compareArrays(
                                    doctypeitem.negotiationrules,
                                    negotiationOptions
                                  ).delArray"
                                  :key="item"
                                  @click="
                                    deleteNegotiationRuleDocTypeAct(
                                      doctypeitem.negotiationrules,
                                      item
                                    )
                                  "
                                >
                                  <v-list-item-subtitle>{{
                                    item
                                  }}</v-list-item-subtitle>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-card-text>
          <div v-if="showLog" class="mx-9">
            <p>
              Alert Log:
            </p>
            <p>DocType Name: {{ focusDocTypeItem.doctype }} </p>
            <p>DocType Description: {{ focusDocTypeItem.description }} </p>
            <p>Owners:</p>
            <ul>
              <li v-for="owner in focusDocTypeItem.ownerid" :key="owner">
                {{ owner }}
              </li>
            </ul>
            <v-container>
              <v-row>
                <v-simple-table :class="$style.dialogOpenTable" fixed-header>
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                      fixed-header
                    >
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.USER') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.ACTION') }}</th
                        >

                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.CONTEXT') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          >{{ $t('DIALOGS.TIME') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in focusDocTypeItem.log"
                        :key="
                          item.uniqid + item.action + item.timestamp.toString()
                        "
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus">{{
                          item.uniqid
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          displayAction(item.action)
                        }}</td>

                        <td :class="$style.dialogCellWidth">{{
                          item.context
                        }}</td>
                        <td :class="$style.dialogBigCellStatus">{{
                          formatDateDialog(item.timestamp)
                        }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </div>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-form v-model="valid">
                  <v-text-field
                    v-model.trim="email"
                    :rules="emailrules"
                    :label="$t('DIALOGS.EMAILTOADD')"
                  ></v-text-field>
                </v-form>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-form v-model="validClause">
                  <v-text-field
                    v-model.trim="clause"
                    :rules="clauserules"
                    :label="$t('DIALOGS.CLAUSETOADD')"
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-form v-model="validTag">
                  <v-text-field
                    v-model.trim="tag"
                    :rules="tagrules"
                    :label="$t('DIALOGS.TAGTOADD')"
                  ></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <div v-if="addMode">
          <v-form v-model="validAdd">
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model.trim="doctypename"
                    :rules="[rules.required, rules.counter80]"
                    :label="$t('DIALOGS.DOCTYPE')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    v-model="description"
                    :rules="[rules.counter160]"
                    :label="$t('DIALOGS.DOCTYPEDESC')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>

        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('admin/doctypes')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer
          ><v-btn v-if="true" large outlined class="mx-4" @click="refresh">{{
            $t('DIALOGS.REFRESH')
          }}</v-btn>
          <v-btn
            v-if="showLog"
            large
            outlined
            class="mx-4 "
            @click="showLog = !showLog"
            >HIDE LOG</v-btn
          >
          <v-btn
            v-if="addMode"
            large
            outlined
            class="mx-4"
            @click="addDocType"
            >{{ $t('DIALOGS.SAVE') }}</v-btn
          >

          <v-btn
            v-if="!addMode"
            large
            outlined
            class="mx-4"
            @click="addMode = !addMode"
            >{{ $t('DIALOGS.ADDNEWDOCTYPE') }}</v-btn
          >
          <v-btn
            v-if="addMode"
            large
            outlined
            class="mx-4"
            @click="addMode = !addMode"
            >{{ $t('DIALOGS.GROUPSLIST') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
