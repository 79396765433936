// FIREBASE
import { db } from '@/src/dbconfig/FBconfig.js'

import store from '@state/store'

import {
  doc,
  getDoc,
  getDocs,
  collection,
  addDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  where,
  writeBatch,
} from 'firebase/firestore'

import DocumentProcessingService from '@services/DocumentProcessingService'

// import AIHUBDocumentService from '@services/AIHUBDocumentService'

const DBFIREBASE = process.env.VUE_APP_DB === 'FIREBASE'

// MONGO
const DBMONGO = process.env.VUE_APP_DB === 'MONGO'

const TODAYDATE = new Date().toISOString().slice(0, 10)

// async
export function DBinsertone(tablename, obj) {
  // console.log('DBinsertone')
  // Firebase
  if (DBFIREBASE) {
    addDoc(collection(db, tablename), obj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    DocumentProcessingService.mongoinsertone({
      tablename: tablename,
      obj: obj,
    })
  }
}

// async
export function DBsetone(tablename, id, obj) {
  // console.log('DBsetone')
  // Firebase
  if (DBFIREBASE) {
    setDoc(doc(db, tablename, id), obj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  if (DBMONGO) {
    DocumentProcessingService.mongosetonesync({
      tablename: tablename,
      id: id,
      obj: obj,
    })
    // }
  }
}

// changed to sync function
export function DBdeactivate(tablename, id) {
  // Firebase
  if (DBFIREBASE) {
    const docRef = doc(db, tablename, id)
    return updateDoc(docRef, {
      active: false,
      delete: new Date(),
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongodeactivate({
        tablename: tablename,
        id: id,
      }).then(() => resolve())
    })
  }
}

// async
export function DBupdateobj(tablename, id, modobj) {
  // Firebase
  if (DBFIREBASE) {
    const docRef = doc(db, tablename, id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    DocumentProcessingService.mongoupdateobj({
      tablename: tablename,
      id: id,
      modobj: modobj,
    })
  }
}

// async - always based on id
export function DBupdateobjarray(tablename, id, modobj) {
  // console.log('DBupdateobjarray')
  // Firebase
  if (DBFIREBASE) {
    const docRef = doc(db, tablename, id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    DocumentProcessingService.mongoupdateobjarray({
      tablename: tablename,
      id: id,
      modobj: modobj,
    })
  }
}

// async alwsy based on id
export function DBupdateobjarrayUnion(tablename, id, field, value) {
  // console.log('DBupdateobjarrayUnion')
  // Firebase
  if (DBFIREBASE) {
    var modobj = {}
    modobj[field] = arrayUnion(value)

    const docRef = doc(db, tablename, id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    DocumentProcessingService.mongoupdateobjarrayUnion({
      tablename: tablename,
      id: id,
      field: field,
      value: value,
    })
  }
}

// async
export function DBupdateobjarrayRemove(tablename, id, field, value) {
  // console.log('DBupdateobjarrayRemove')
  // Firebase
  if (DBFIREBASE) {
    var modobj = {}
    modobj[field] = arrayRemove(value)

    const docRef = doc(db, tablename, id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }
  // Mongo
  if (DBMONGO) {
    DocumentProcessingService.mongoupdateobjarrayRemove({
      tablename: tablename,
      id: id,
      field: field,
      value: value,
    })
  }
}

// async alwsy based on id
export function DBupdateobjarrayObjUnion(tablename, id, field, value) {
  // Firebase
  if (DBFIREBASE) {
    // const dbtable = db.collection(tablename)

    var modobj = {}
    modobj[field] = arrayUnion(value)

    const docRef = doc(db, tablename, id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    DocumentProcessingService.mongoupdateobjarrayObjUnion({
      tablename: tablename,
      id: id,
      field: field,
      value: value,
    })
  }
}

// async
export function DBupdateobjarrayObjRemove(tablename, id, field, value) {
  // console.log('DBupdateobjarrayObjRemove')
  // Firebase
  if (DBFIREBASE) {
    var modobj = {}
    modobj[field] = arrayRemove(value)
    const docRef = doc(db, tablename, id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo - value is an obj
  if (DBMONGO) {
    DocumentProcessingService.mongoupdateobjarrayObjRemove({
      tablename: tablename,
      id: id,
      field: field,
      value: value,
    })
  }
}

// async
export function DBupdateobjarrayIncrement(tablename, id, field) {
  // console.log('DBupdateobjarrayIncrement')
  // Firebase
  if (DBFIREBASE) {
    var modobj = {}
    modobj[field] = increment(1)

    const docRef = doc(db, tablename, id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    DocumentProcessingService.mongoupdateobjarrayIncrement({
      tablename: tablename,
      id: id,
      field: field,
    })
  }
}

// sync
export function DBgetobjsync(tablename, id) {
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const docRef = doc(db, tablename, id)
      getDoc(doc(db, tablename, id)).then(function (doc) {
        if (doc.exists()) {
          resolve({ exists: doc.exists(), data: doc.data(), id: doc.id })
        }
        resolve({ exists: false })
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongogetobjsync({
        tablename: tablename,
        id: id,
      }).then(function (data) {
        if (Object.keys(data.data).length === 0) {
          resolve({ exists: false })
        }
        resolve({ exists: true, data: data.data, id: data.data['_id'] })
      })
      // }
    })
  }
}

export function DBinsertonesync(tablename, obj) {
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      // dbtable.add(obj)

      addDoc(collection(db, tablename), obj).then(function (doc) {
        resolve({ id: doc.id })
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongoinsertonesync({
        tablename: tablename,
        obj: obj,
      }).then(function (data) {
        resolve({ id: data.data['id'] })
      })
    })
  }
}

// sync - needs to vary for acceptable objid types depending on FB or Mongo.
export function DBsetonesync(tablename, id, obj) {
  // console.log('DBsetonesync')
  // Firebase
  if (DBFIREBASE) {
    return setDoc(doc(db, tablename, id), obj)
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongosetonesync({
        tablename: tablename,
        id: id,
        obj: obj,
      }).then(function (data) {
        resolve(data)
      })
      // }
    })
  }
}

// sync - but return value is ignored on both - this should be fine.
export function DBupdateobjsync(tablename, id, modobj) {
  // console.log('DBupdateobjsync')
  // Firebase
  if (DBFIREBASE) {
    return updateDoc(doc(db, tablename, id), modobj)
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      const { data } = DocumentProcessingService.mongoupdateobjsync({
        tablename: tablename,
        id: id,
        modobj: modobj,
      })
      resolve(data)
    })
  }
}

export function DBownergroup(ownerid, gid, action) {
  // console.log('DBownergroup')
  // Firebase
  if (DBFIREBASE) {
    var batch = writeBatch(db)
    var gidRef = doc(db, 'groups', gid)
    var owneridRef = doc(db, 'users', ownerid)

    if (action === 'Union') {
      batch.update(gidRef, {
        ownerid: arrayUnion(ownerid),
      })
      batch.update(owneridRef, {
        groupid: arrayUnion(gid),
      })
    }
    if (action === 'Remove') {
      batch.update(gidRef, {
        ownerid: arrayRemove(ownerid),
      })
      batch.update(owneridRef, {
        groupid: arrayRemove(gid),
      })
    }

    batch.commit().catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    DocumentProcessingService.mongoownergroup({
      ownerid: ownerid,
      gid: gid,
      action: action,
    })
  }
}

export function DBcollaboratorgroup(uniqid, gid, action) {
  // console.log('DBcollaboratorgroup')
  // Firebase
  if (DBFIREBASE) {
    // Get a new write batch
    var batch = writeBatch(db)

    var gidRef = doc(db, 'groups', gid)
    var owneridRef = doc(db, 'users', uniqid)

    if (action === 'Union') {
      batch.update(gidRef, {
        uniqid: arrayUnion(uniqid),
      })
      batch.update(owneridRef, {
        groupid: arrayUnion(gid),
      })
    }
    if (action === 'Remove') {
      batch.update(gidRef, {
        uniqid: arrayRemove(uniqid),
      })
      batch.update(owneridRef, {
        groupid: arrayRemove(gid),
      })
    }

    batch.commit().catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    DocumentProcessingService.mongocollaboratorgroup({
      uniqid: uniqid,
      gid: gid,
      action: action,
    })
  }
}

// utility functions for queries
function formatFBquerySnapshot(querySnapshot) {
  var objlist = []
  querySnapshot.forEach((doc) => {
    var data = doc.data()
    data._id = doc.id
    objlist.push(data)
  })
  return objlist
}

function formatMongoData(data) {
  var objlist = []
  for (var key in data.data) {
    objlist.push(data.data[key])
  }
  return objlist
}

// testing
export function DBalertsquery1FB(uniqid) {
  // console.log('DBalertsquery1FB')
  return new Promise(function (resolve, reject) {
    const query1 = query(
      collection(db, 'alerts'),
      where('ownerid', 'array-contains', uniqid)
    )

    getDocs(query1).then(function (querySnapshot) {
      resolve(formatFBquerySnapshot(querySnapshot))
    })
  })
}

// testing
export function DBalertsquery1Mongo(uniqid) {
  // console.log('DBalertsquery1Mongo')
  return new Promise(function (resolve, reject) {
    DocumentProcessingService.mongoalertsquery2({
      uniqid: uniqid,
    }).then(function (data) {
      resolve(formatMongoData(data))
    })
  })
}

// all queries are sync
export function DBdoctypesquery1(uniqid) {
  // console.log('DBdoctypesquery1')
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'doctypes'),
        where('ownerid', 'array-contains', uniqid)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongodoctypesquery1({
        uniqid: uniqid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBalertsquery1(uniqid) {
  // console.log('DBalertsquery1')
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'alerts'),
        where('ownerid', 'array-contains', uniqid)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongoalertsquery1({
        uniqid: uniqid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBalertsquery2(uniqid) {
  // console.log('DBalertsquery2')
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'alerts'),
        where('uniqid', 'array-contains', uniqid)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongoalertsquery2({
        uniqid: uniqid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBalertsquery3(doctypeid) {
  // console.log('DBalertsquery3')
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'alerts'),
        where('doctypeid', 'array-contains', doctypeid)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongoalertsquery3({
        doctypeid: doctypeid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBdocmetaquery1(uniqid) {
  // console.log('DBdocmetaquery1')
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'docmeta'),
        where('ownerid', 'array-contains', uniqid),
        where('doccategory', '==', 'STANDARD'),
        where('active', '==', true)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongodocmetaquery1({
        uniqid: uniqid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBdocmetaquery2(uniqid) {
  // console.log('DBdocmetaquery2')
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'docmeta'),
        where('externalcollaborators', 'array-contains', uniqid),
        where('doccategory', '==', 'STANDARD'),
        where('collabstatus', '==', 'OPEN'),
        where('collabend', '>', TODAYDATE),
        where('active', '==', true)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongodocmetaquery2({
        uniqid: uniqid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBdocmetaquery3(uniqid) {
  // console.log('DBdocmetaquery3')
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'docmeta'),
        where('externalnegotiators', 'array-contains', uniqid),
        where('doccategory', '==', 'STANDARD'),
        where('negotiationstatus', '==', 'OPEN'),
        where('negotiationend', '>', TODAYDATE),
        where('active', '==', true)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongodocmetaquery3({
        uniqid: uniqid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBdocmetaquery4(doctypeid, doccategory) {
  // doccategory can be either 'STANDARD' (4), 'ARCHIVE' (6), 'PLAYBOOK' (10), 'TEMPLATE' (12)
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'docmeta'),
        where('doctypeid', '==', doctypeid),
        where('doccategory', '==', doccategory),
        where('active', '==', true)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongodocmetaquery4({
        doctypeid: doctypeid,
        doccategory: doccategory,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBdocmetaquery5(doctypeid) {
  // console.log('DBdocmetaquery5')
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'docmeta'),
        where('doctypeid', '==', doctypeid),
        where('doccategory', '==', 'STANDARD'),
        where('collabstatus', '==', 'OPEN'),
        where('collabend', '>', TODAYDATE),
        where('active', '==', true)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongodocmetaquery5({
        doctypeid: doctypeid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBdocmetaquery6(uniqid, relationship) {
  // console.log('DBdocmetaquery6')
  // relationship can be either 'ownerid', 'externalcollaborators', 'externalnegotiators'
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'docmeta'),
        where(relationship, 'array-contains', uniqid),
        where('doccategory', '==', 'ARCHIVE'),
        where('doctypeid', '==', '1'),
        where('active', '==', true)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongodocmetaquery6({
        uniqid: uniqid,
        relationship: relationship,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBlistenerComments(docid) {
  // Mongo only
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongolistenerComments({
        docid: docid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBlistenerDocEdits(docid) {
  // Mongo only
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongolistenerDocEdits({
        docid: docid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBlistenerTags(docid) {
  // Mongo only
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongolistenerTags({
        docid: docid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBlistenerDocCompleted(docid) {
  // console.log('DBlistenerDocCompleted')
  // Mongo only
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.mongolistenerDocCompleted({
        docid: docid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}
