<script>
import { dbauthComputed } from '@state/helpers'

import NavigationBar from '@components/navigation/Anavigation-bar.vue'

export default {
  components: {
    NavigationBar,
  },
  data: function() {
    return {
      isToolbarOn: true,
    }
  },
  computed: {
    ...dbauthComputed,
    // provides permissions for the authorization
    dbauthprivilege() {
      if (this.dbauthPrivileges === null) {
        return 'A'
      } else {
        return this.dbauthPrivileges.type
      }
    },
  },
  methods: {
    toggleToolbar() {
      this.isToolbarOn = !this.isToolbarOn
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <NavigationBar :authlevel="dbauthprivilege"></NavigationBar>
    <main :class="$style.content">
      <slot />
    </main>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.container {
  width: 100%;
  height: calc(100vh);
  // margin-top: 64px;
  overflow: hidden;
}

.content {
  width: inherit;
  height: inherit;
  padding: 0 20px;
  overflow: auto;
  background-color: #eee;
}
</style>
