// FIREBASE
// import firebase from 'firebase/app'
// Changed the auth interface to v9 for Firebase.
// Test to see if it works.

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  updatePassword,
} from 'firebase/auth'

// GENERAL
import DocumentProcessingService from '@services/DocumentProcessingService'

import store from '@state/store'

// Supports both Firebase or Enterprise Authentication
const AUTHFIREBASE = process.env.VUE_APP_AUTH === 'FIREBASE'
const AUTHENTERPRISE = process.env.VUE_APP_AUTH === 'ENTERPRISE'

const NOREQUIREAUTH = process.env.VUE_APP_NOREQUIREAUTHEMAIL == 'TRU'

const AUTH = getAuth()

// Login - WORKS
export function AUTHsignIn(uniqid, password) {
  // Firebase
  if (AUTHFIREBASE) {
    return new Promise(function (resolve, reject) {
      // AUTH.setPersistence(firebase.auth.Auth.Persistence.SESSION)
      // setPersistence(AUTH, browserSessionPersistence).then(function () {
      signInWithEmailAndPassword(AUTH, uniqid, password)
        .then(function (data) {
          resolve({
            success: true,
            uniqid: data.user.email,
            token: data.user.email,
            oldpassword: password,
            emailVerified: data.user.emailVerified,
            user: data,
          })
        })
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
    // })
  }

  // Enterprise
  if (AUTHENTERPRISE) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.authLogin({
        username: uniqid,
        password: password,
      })
        .then(function (returnobj) {
          if (returnobj.data.auth_token) {
            resolve({
              success: true,
              uniqid: uniqid,
              token: returnobj.data.auth_token,
              oldpassword: password,
              emailVerified: true,
            })
          } else {
            resolve({
              success: false,
              uniqid: '',
              token: '',
              emailVerified: false,
            })
          }
        })
        .catch(function (e) {
          if (
            e.response.data.non_field_errors[0] ===
            'Unable to log in with provided credentials.'
          ) {
            resolve({
              success: false,
              uniqid: '',
              token: '',
              emailVerified: false,
            })
          } else {
            store.dispatch('dberror/logError', e)
          }
        })
    })
  }
}

// Registration - WORKS
export function AUTHcreateUserEmailPassword(uniqid, password) {
  // Firebase
  if (AUTHFIREBASE) {
    return new Promise(function (resolve, reject) {
      createUserWithEmailAndPassword(AUTH, uniqid, password).then((user) =>
        resolve(user)
      )
    })
  }

  // Enterprise
  if (AUTHENTERPRISE) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.authRegistration({
        username: uniqid,
        email: uniqid,
        password: password,
      }).then(function (data) {
        resolve(data)
      })
    })
  }
}

// This works
export function AUTHsendEmailVerification(obj) {
  // Firebase
  if (AUTHFIREBASE) {
    return new Promise(function (resolve, reject) {
      sendEmailVerification(AUTH.currentUser).then(resolve(true))
    })
  }

  // Enterprise
  if (AUTHENTERPRISE) {
    return new Promise(function (resolve, reject) {
      if (!NOREQUIREAUTH) {
        DocumentProcessingService.authRegResendEmail({
          email: obj.payload.uniqid,
        })
      } else {
        resolve(true)
      }
    })
  }
}

// this works
export function AUTHsendPasswordReset(uniqid) {
  // Firebase
  if (AUTHFIREBASE) {
    return new Promise(function (resolve, reject) {
      sendPasswordResetEmail(AUTH, uniqid).then(resolve(true))
    })
  }

  // Enterprise
  if (AUTHENTERPRISE) {
    return new Promise(function (resolve, reject) {
      DocumentProcessingService.authPasswordReset({
        email: uniqid,
      })
    })
  }
}

// this works
export function AUTHchangePassword(obj) {
  // Firebase
  if (AUTHFIREBASE) {
    return new Promise(function (resolve, reject) {
      updatePassword(AUTH.currentUser, obj.newpassword).then(resolve(true))
    })
  }

  // Enterprise
  if (AUTHENTERPRISE) {
    return new Promise(function (resolve, reject) {
      // Can't get this to work correctly - gives 401 error
      // Superceded by code change in nav bar for enterprise accounts
      // to send email reset instead.
      DocumentProcessingService.authRegResetPassword({
        current: obj.oldpassword,
        newpass: obj.newpassword,
        token: obj.token,
      })
    })
  }
}
