<script>
/* eslint-disable */
// disable due to use of v-html in the file.
import {
  panelComputed,
  dbauthComputed,
  dbdoceditComputed,
} from '@state/helpers'

import {
  splitSentences,
  formatDateDialog,
  statusIndicator,
  displayUserName,
  displayUserInfo,
  docroute,
} from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    dialogtype: {
      type: String,
      required: true,
    },
    localpanelstate: {
      type: Object,
      required: true,
    },
    originaltext: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    showDeleted: false,
    docedittext: '',

    // for showing Pending only
    showPendingOnly: false,
    // showPendingOnlyDisplay: 'Show Pending Only',
  }),
  computed: {
    ...panelComputed,
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
        this.docedittext = ''
      },
    },

    cardtitle() {
      return this.dialogtype + ' : ' + this.$t('DIALOGS.DOCEDITS')
    },

    filteredDocEdits() {
      if (this.dialogtype === 'COLLABORATION') {
        return this.editDocedits[this.panelno].filter(
          (e) => e.type === this.dialogtype
        )
        // NEGOTIATION
      } else {
        // negotiation includes collaboration docedits that have been approved by owner.
        return this.editDocedits[this.panelno].filter(
          (e) =>
            e.type === this.dialogtype ||
            (e.type === 'COLLABORATION' && e.approved)
        )
      }
      // return this.editDocedits[this.panelno].filter(
      //   (e) => e.type === this.dialogtype
      // )
    },

    alldocedits() {
      if (
        Array.isArray(this.editDocedits[this.panelno]) &&
        this.editDocedits[this.panelno].length > 0
      ) {
        if (this.showDeleted) {
          return this.filteredDocEdits
        } else {
          return this.filteredDocEdits.filter((e) => e.active === true)
        }
      } else {
        return []
      }
    },

    filtereddocedits() {
      if (this.showPendingOnly) {
        if (this.ownerApprovalAvailable) {
          return this.alldocedits.filter(
            (e) => !e.approved && e.approver === ''
          )
        }
        if (this.negotiatorApprovalAvailable) {
          return this.alldocedits.filter(
            (e) => !e.negotiatorapproved && e.negotiatorapprover === ''
          )
        }
      }
      return this.alldocedits
    },

    sorteddocedits() {
      // let temparray = this.alldocedits
      let temparray = this.filtereddocedits
      let returnarray = temparray.sort(function(a, b) {
        if (parseInt(a.paraid) > parseInt(b.paraid)) {
          return 1
        } else if (parseInt(a.paraid) < parseInt(b.paraid)) {
          return -1
        } else if (parseInt(a.paraid) === parseInt(b.paraid)) {
          return a.create > b.create ? 1 : -1
        } else {
          return 0
        }
      })
      return returnarray
    },

    availDocEdits() {
      return ['DELETE', 'REPLACE', 'ADD-AFTER']
    },

    ownerApprovalAvailable() {
      if (this.localpanelstate === null || this.localpanelstate === undefined) {
        return false
      }
      if (this.localpanelstate.ifArchivedState) {
        return false
      }
      // return this.ownerOptions
      return this.localpanelstate.ownerPriv
    },

    negotiatorApprovalAvailable() {
      if (this.localpanelstate === null || this.localpanelstate === undefined) {
        return false
      }
      if (this.localpanelstate.ifArchivedState) {
        return false
      }
      // return this.negotiatorOptions && this.dialogtype === 'NEGOTIATION'
      return this.localpanelstate.negotiatorPriv
    },
  },
  methods: {
    ...dbdoceditComputed,

    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },

    ownerApproveDocEditAct(docedit) {
      this.ownerApproveDocEdit({
        docid: this.aPIDjobID[this.panelno],
        doceditid: docedit.keyid,
        uniqid: this.dbauthuser.uniqid,
        approver: this.dbauthuser.uniqid,
        detail: docedit.docedittext,
        context: docedit.docedittype,
        ifHUBType: this.localpanelstate.ifHUBType,
      })
      return true
    },

    ownerRejectDocEditAct(docedit) {
      this.ownerRejectDocEdit({
        docid: this.aPIDjobID[this.panelno],
        doceditid: docedit.keyid,
        uniqid: this.dbauthuser.uniqid,
        approver: this.dbauthuser.uniqid,
        detail: docedit.docedittext,
        context: docedit.docedittype,
        ifHUBType: this.localpanelstate.ifHUBType,
      })
      return true
    },

    negotiatorApproveDocEditAct(docedit) {
      this.negotiatorApproveDocEdit({
        docid: this.aPIDjobID[this.panelno],
        doceditid: docedit.keyid,
        uniqid: this.dbauthuser.uniqid,
        approver: this.dbauthuser.uniqid,
        detail: docedit.docedittext,
        context: docedit.docedittype,
        ifHUBType: this.localpanelstate.ifHUBType,
      })
      return true
    },

    negotiatorRejectDocEditAct(docedit) {
      this.negotiatorRejectDocEdit({
        docid: this.aPIDjobID[this.panelno],
        doceditid: docedit.keyid,
        uniqid: this.dbauthuser.uniqid,
        approver: this.dbauthuser.uniqid,
        detail: docedit.docedittext,
        context: docedit.docedittype,
        ifHUBType: this.localpanelstate.ifHUBType,
      })
      return true
    },

    setparagraphhighlight(index) {
      this.$emit('processnavall', index)
      this.$emit('close-dialog')
      // this unsyncs panel, sets topic to ALL, sets paragraph highlight
    },

    // these functions call the utils - not sure why it's not picking up immediately.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    statusIndicator(bool) {
      return statusIndicator(bool)
    },
    docroute(d) {
      return docroute(d)
    },
    // need to implement check to see if someone can delete a comment.
    deleteok(uniqid) {
      return this.dbauthuser.uniqid in uniqid
    },
    // docedit, user, date
    doceditsent(sentid) {
      const doceditArray = this.editDocedits[this.panelno]
        .filter((e) => e.type === this.dialogtype)
        .filter((e) => e.sentid === sentid.toString())
        .filter((e) => e.active === true)
      return doceditArray
    },

    // returns the array of available docedits to add or delete.
    // limited to one of each type for each sentence.
    doceditAvailable(sentid) {
      const doceditArray = this.doceditsent(sentid).filter(
        (e) => e.uniqid === this.dbauthuser.uniqid
      )
      var addArray = []
      var delArray = []
      var position = -1
      this.availDocEdits.forEach(function(e) {
        position = doceditArray.findIndex((f) => f.docedittype === e)
        if (position < 0) {
          addArray.push({ docedittype: e, sentid: sentid.toString() })
        } else {
          delArray.push({
            obj: doceditArray[position],
            sentid: sentid.toString(),
          })
        }
      })
      return { addArray: addArray, delArray: delArray }
    },
    sentenceContext(paraid, sentid) {
      if (this.docRawData[this.panelno] === null) {
        return ''
      }
      if (
        this.originaltext[paraid] === null ||
        this.originaltext[paraid] === undefined
      ) {
        return ''
      }

      let paratext = this.originaltext[paraid].OT
      let sentences = splitSentences(paratext)

      return sentences.find((e) => e.key === parseInt(sentid)).sentence
      // return sentid
    },
    approvaldisplay(approved, approvedtime, approver) {
      if (!approved && approver === '') {
        return 'PENDING'
      }
      if (!approved && approver !== '') {
        return (
          'REJECTED AT: ' +
          this.formatDateDialog(approvedtime) +
          ' BY: ' +
          approver
        )
      }
      return (
        '<b>APPROVED</b> AT: ' +
        this.formatDateDialog(approvedtime) +
        ' BY: ' +
        this.displayUserName(approver)
      )
    },
  },
}
</script>

<template>
  <v-row>
    <v-dialog
      v-model="showDialog"
      persistent
      :content-class="$style.dialogWidth"
    >
      <v-card>
        <PanelCardTitle :cardtitle="cardtitle"></PanelCardTitle>
        <div>
          <v-form>
            <v-container>
              <v-row>
                <v-simple-table :class="$style.dialogTable" fixed-header>
                  <template>
                    <thead :class="$style.dialogHead">
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.PARAGRAPHID') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogDocEdits"
                          >{{ $t('DIALOGS.SENTENCE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogDocEdits"
                          >{{ $t('DIALOGS.DOCEDITS') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.TIME') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellDelete"
                          >{{ $t('DIALOGS.USER') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.STATUS') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.OWNERAPPROVAL') }}</th
                        >
                        <th
                          v-if="ownerApprovalAvailable"
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.OWNERACTION') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.NEGOTIATORAPPROVAL') }}</th
                        >
                        <th
                          v-if="negotiatorApprovalAvailable"
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.NEGOTIATORACTION') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in sorteddocedits"
                        :key="item.key"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellWidth"
                          ><v-btn
                            x-small
                            text
                            outlined
                            class="py-5"
                            @click="setparagraphhighlight(item.paraid)"
                            >{{ item.paraid }}</v-btn
                          ></td
                        >
                        <td :class="$style.dialogDocEdits">{{
                          sentenceContext(item.paraid, item.sentid)
                        }}</td>
                        <td :class="$style.dialogDocEdits">
                          <span
                            v-html="
                              '<b>' +
                                item.docedittype +
                                ' by ' +
                                displayUserName(item.uniqid) +
                                ':' +
                                '</b>' +
                                item.docedittext
                            "
                          >
                          </span>
                        </td>

                        <td :class="$style.dialogCellWidth">{{
                          formatDateDialog(item.create)
                        }}</td>
                        <td :class="$style.dialogCellDelete">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">{{
                                displayUserName(item.uniqid)
                              }}</span>
                            </template>
                            <span>{{ displayUserInfo(item.uniqid) }}</span>
                          </v-tooltip>
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <v-icon
                            large
                            color="statusIndicator(item.active).color"
                            >{{ statusIndicator(item.active).icon }}</v-icon
                          >
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <span
                            v-html="
                              approvaldisplay(
                                item.approved,
                                item.approvedtime,
                                item.approver
                              )
                            "
                          >
                          </span>
                        </td>
                        <td
                          v-if="ownerApprovalAvailable"
                          :class="$style.dialogCellAction"
                        >
                          <v-row>
                            <v-btn
                              v-if="!item.approved || item.approver === ''"
                              small
                              class="white--text black"
                              @click="ownerApproveDocEditAct(item)"
                            >
                              APPROVE
                            </v-btn>
                          </v-row>
                          <v-row class="my-2">
                            <v-btn
                              v-if="item.approved || item.approver === ''"
                              small
                              class="white--text black"
                              @click="ownerRejectDocEditAct(item)"
                            >
                              REJECT
                            </v-btn>
                          </v-row>
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <span
                            v-html="
                              approvaldisplay(
                                item.negotiatorapproved,
                                item.negotiatorapprovedtime,
                                item.negotiatorapprover
                              )
                            "
                          >
                          </span>
                        </td>
                        <td
                          v-if="negotiatorApprovalAvailable"
                          :class="$style.dialogCellAction"
                        >
                          <v-row>
                            <v-btn
                              v-if="
                                !item.negotiatorapproved ||
                                  item.negotiatorapprover === ''
                              "
                              small
                              class="white--text black"
                              @click="negotiatorApproveDocEditAct(item)"
                            >
                              APPROVE
                            </v-btn>
                          </v-row>
                          <v-row class="my-2">
                            <v-btn
                              v-if="
                                item.negotiatorapproved ||
                                  item.negotiatorapprover === ''
                              "
                              small
                              class="white--text black"
                              @click="negotiatorRejectDocEditAct(item)"
                            >
                              REJECT
                            </v-btn>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <v-card-actions class="justify-end">
          <div v-if="dialogtype === 'COLLABORATION'" class="mx-5">
            <v-btn icon @click="docroute('edit/collabdocedits')">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
            {{ $t('INFO_MESSAGES.COLLABORATIONNOTE') }}
          </div>
          <div v-if="dialogtype === 'NEGOTIATION'" class="mx-5">
            <v-btn icon @click="docroute('edit/negotdocedits')">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
            {{ $t('INFO_MESSAGES.NEGOTIATIONNOTE') }}
          </div>
          <v-spacer></v-spacer>
          <v-checkbox
            v-model="showPendingOnly"
            :label="$t('DIALOGS.SHOWPENDINGONLY')"
          >
          </v-checkbox>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
