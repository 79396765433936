import store from '@state/store'

import {
  DBinsertone,
  DBdeactivate,
  DBupdateobj,
  DBinsertonesync,
  DBupdateobjarrayUnion,
  DBupdateobjarrayObjUnion,
  DBupdateobjarrayRemove,
} from '@/src/state/io/iodb.js'

const ALERTSTABLENAME = 'alerts'
const ALERTSOUTTABLENAME = 'alertsout'

// this table is used to create the doctype specific alerts.
// const dbalerts = db.collection('alerts')

// STILL WORK TO BE DONE FOR THIS TABLE.

// this table is used to process the outbound alerts
// const dbalertsout = db.collection('alertsout')
// capabilities
// add new alert
// delete alert
// assign one group name and one or more document type to each alert (for the alert to be active) and one or more topics for alert to be active.
// add owners
// delete owners
// add and delete doctypes
// add and delete topics
// add and delete users (subset of owners and collaborators)
// add and delete addl notificationrules
// set topic selectivity

// processTrigger (generated per trigger and user)
// processAllTriggers (specific to a document type)
// processCustomTrigger (created specific to user)

// Creates and manages the alert state per uniqid and group.
export const state = {
  // debug
  dbalertid: 0,
}

// do I need to add getters here?
export const getters = {
  dbalertid(state) {
    return state.dbalertid
  },
}

export const mutations = {
  // debug
  SET_ALERTID(state, id) {
    state.dbalertid = id
  },
}

export const actions = {
  init({ commit }) {},

  writeAlert({ commit, state }, payload) {
    var alertEx = {
      alert: payload.alert,
      description: payload.description,
      groupid: payload.groupid,

      ownerid: [payload.ownerid],
      doctypeid: [],
      clausetopic: [],
      uniqid: [],
      notificationrules: [], // this is default behavior for all docs of this alert
      selectivity: 0, // default selecitivity = strict.

      log: [],
      create: new Date(),
      createdby: payload.ownerid,
      delete: '',
      active: true,
    }

    DBinsertone(ALERTSTABLENAME, alertEx)

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.CREATEALERT',
        detail: payload.alert,
        context: payload.description,
      },
    })
  },

  deleteAlert({ commit, state }, payload) {
    let alertid = payload.alertid

    DBdeactivate(ALERTSTABLENAME, alertid)

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: alertid,
      logdetail: { action: 'ADMINEVENTS.DELETEALERT', context: '' },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEALERT',
        detail: payload.alertid,
        context: '',
      },
    })
  },

  addOwnerAlert({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      ALERTSTABLENAME,
      payload.alertid,
      'ownerid',
      payload.ownerid
    )

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.ADDOWNERALERT',
        context: payload.ownerid,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDOWNERALERT',
        detail: payload.alertid,
        context: payload.ownerid,
      },
    })
  },

  deleteOwnerAlert({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      ALERTSTABLENAME,
      payload.alertid,
      'ownerid',
      payload.ownerid
    )

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEOWNERALERT',
        context: payload.ownerid,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEOWNERALERT',
        detail: payload.alertid,
        context: payload.ownerid,
      },
    })
  },

  addDocTypeAlert({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      ALERTSTABLENAME,
      payload.alertid,
      'doctypeid',
      payload.doctypeid
    )

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.ADDDOCTYPEALERT',
        context: payload.doctypeid,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDDOCTYPEALERT',
        detail: payload.alertid,
        context: payload.doctypeid,
      },
    })
  },

  deleteDocTypeAlert({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      ALERTSTABLENAME,
      payload.alertid,
      'doctypeid',
      payload.doctypeid
    )

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEDOCTYPEALERT',
        context: payload.doctypeid,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEDOCTYPEALERT',
        detail: payload.alertid,
        context: payload.doctypeid,
      },
    })
  },

  addClauseTopicAlert({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      ALERTSTABLENAME,
      payload.alertid,
      'clausetopic',
      payload.clausetopic
    )
    DBupdateobjarrayUnion(ALERTSTABLENAME, payload.alertid, 'uid', payload.uid)

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.ADDCLAUSETOPICALERT',
        context: payload.clausetopic,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDCLAUSETOPICALERT',
        detail: payload.alertid,
        context: payload.clausetopic,
      },
    })
  },

  deleteClauseTopicAlert({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      ALERTSTABLENAME,
      payload.alertid,
      'clausetopic',
      payload.clausetopic
    )

    DBupdateobjarrayRemove(ALERTSTABLENAME, payload.alertid, 'uid', payload.uid)

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.DELETECLAUSETOPICALERT',
        context: payload.clausetopic,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETECLAUSETOPICALERT',
        detail: payload.alertid,
        context: payload.clausetopic,
      },
    })
  },

  addRecipientAlert({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      ALERTSTABLENAME,
      payload.alertid,
      'uniqid',
      payload.uniqidadd
    )

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.ADDRECIPIENTALERT',
        context: payload.uniqidadd,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDRECIPIENTALERT',
        detail: payload.alertid,
        context: payload.uniqidadd,
      },
    })
  },

  deleteRecipientAlert({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      ALERTSTABLENAME,
      payload.alertid,
      'uniqid',
      payload.uniqiddel
    )

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.DELETERECIPIENTALERT',
        context: payload.uniqiddel,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETERECIPIENTALERT',
        detail: payload.alertid,
        context: payload.uniqiddel,
      },
    })
  },

  addNotificationRuleAlert({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      ALERTSTABLENAME,
      payload.alertid,
      'notificationrules',
      payload.notificationrule
    )

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.ADDNOTIFRULEALERT',
        context: payload.notificationrule,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDNOTIFRULEALERT',
        detail: payload.alertid,
        context: payload.notificationrule,
      },
    })
  },

  deleteNotificationRuleAlert({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      ALERTSTABLENAME,
      payload.alertid,
      'notificationrules',
      payload.notificationrule
    )

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.DELETENOTIFRULEALERT',
        context: payload.notificationrule,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETENOTIFRULEALERT',
        detail: payload.alertid,
        context: payload.notificationrule,
      },
    })
  },

  setSelectivitityAlert({ commit, state }, payload) {
    DBupdateobj(ALERTSTABLENAME, payload.alertid, {
      selectivity: payload.selectivity,
    })

    // logAlert
    store.dispatch('dbalert/logAlert', {
      uniqid: payload.uniqid,
      alertid: payload.alertid,
      logdetail: {
        action: 'ADMINEVENTS.SETSELECTIVITYALERT',
        context: payload.selectivity,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.SETSELECTIVITYALERT',
        detail: payload.alertid,
        context: payload.selectivity,
      },
    })
  },

  // these are document events.
  processTriggerAlert({ commit, state }, payload) {
    // Define the object for creation  ... passed from the payload.
    var processTriggerAlertEx = {
      docid: payload.docid,
      doctypeid: payload.doctypeid,
      alertid: payload.alertid,

      // info
      groupid: payload.groupid,
      alert: payload.alert,

      create: new Date(),
      createdby: payload.uniqid,
      status: 'active',
    }

    DBinsertone(ALERTSOUTTABLENAME, processTriggerAlertEx)

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: payload.docid,
      uniqid: payload.uniqid,
      logdetail: {
        action: 'DOCEVENTS.TRIGGERALERT',
        detail: payload.alert,
        context: payload.alertid,
      },
    })
  },
  processAllTriggerAlert({ commit, state }, payload) {
    var processAllTriggerAlert = {
      docid: payload.docid,
      create: new Date(),
      createdby: payload.uniqid,
      status: 'active',
    }

    DBinsertonesync(ALERTSOUTTABLENAME, processAllTriggerAlert)
      .then((doc) => commit('SET_ALERTID', doc.id))
      .catch(function(e) {
        store.dispatch('dberror/logError', e)
      })

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: payload.docid,
      uniqid: payload.uniqid,
      logdetail: {
        action: 'DOCEVENTS.TRIGGERALLALERT',
        detail: payload.doctypeid,
        context: payload.groupid,
      },
    })
  },
  processCustomTriggerAlert({ commit, state }, payload) {
    // dbalertsout
    // input
    //   docid
    //   uniqid (must be owner or collaborator) - recipients
    //   topic listclausetopic
    //   created;
    // uniqid of requestor:
    // status - ope
    var processCustomTriggerAlert = {
      docid: payload.docid,
      recipients: payload.recipients,
      clausetopic: payload.clausetopic,

      create: new Date(),
      createdby: payload.uniqid,
      status: 'active',
    }

    DBinsertonesync(ALERTSOUTTABLENAME, processCustomTriggerAlert)
      .then((doc) => commit('SET_ALERTID', doc.id))
      .catch(function(e) {
        store.dispatch('dberror/logError', e)
      })

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: payload.docid,
      uniqid: payload.uniqid,
      logdetail: {
        action: 'DOCEVENTS.TRIGGERCUSTOMALERT',
        detail: payload.recipients,
        context: '',
      },
    })
  },

  // processTrigger (generated per trigger and user)
  // processAllTriggers (specific to a document type)
  // processCustomTrigger (created specific to user)

  logAlert({ commit, state }, payload) {
    let insertdetail = payload.logdetail
    insertdetail.alertid = payload.alertid
    insertdetail.uniqid = payload.uniqid
    insertdetail.timestamp = new Date()

    DBupdateobjarrayObjUnion(
      ALERTSTABLENAME,
      payload.alertid,
      'log',
      insertdetail
    )
  },
}
