<script>
import { dbauthComputed } from '@state/helpers'

import { docroute } from '@utils/dialogutils'
import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
  },

  data: function() {
    return {
      errormsg: '',
    }
  },

  computed: {
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
  },

  methods: {
    refresh(doctypeid) {
      // I think I need to reset this as this continued to get bigger.
      this.$store.dispatch('dbauth/getDocTypeAlertsAuth', doctypeid)
    },

    // given groupid, gives the name of group
    getGroupName(groupid) {
      let groups = this.dbauthGroupsAccess
      var objIndex = groups.findIndex((obj) => obj.keyid === groupid)
      if (objIndex >= 0) {
        return groups[objIndex].group
      } else {
        return groupid
      }
    },
    processTriggerAlertAct(alertitem) {
      this.errormsg = 'Triggered the Alert: ' + alertitem.alert
      this.$emit('processTrigger', alertitem)
    },

    processAllTriggerAlertAct() {
      this.errormsg = 'Triggered all Alerts for DocType'
      this.$emit('processAllTrigger')
    },

    resetform() {
      this.showDialog = false
      this.errormsg = ''
    },
    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.TRIGGERALERTS')"
        ></PanelCardTitle>
        <div>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-simple-table :class="$style.dialogTable" fixed-header>
                  <template>
                    <thead :class="$style.dialogHead">
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellHeading"
                        ><b>{{ $t('DIALOGS.ALERTNAME') }}</b></th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ALERTDESC') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.GROUPNAME') }}</th
                      >
                      <th
                        style="font-size:16px"
                        :class="$style.dialogCellStatus"
                        >{{ $t('DIALOGS.ACTIONS') }}</th
                      >
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="alertitem in dbauthDocTypeAlerts"
                        :key="alertitem.alertid"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus">{{
                          alertitem.alert
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          alertitem.description
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          getGroupName(alertitem.groupid)
                        }}</td>

                        <td :class="$style.dialogCellStatus"
                          ><v-btn
                            small
                            text
                            @click="processTriggerAlertAct(alertitem)"
                          >
                            <v-icon>mdi-arrow-right-bold</v-icon>
                          </v-btn></td
                        >
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-card-text>
        </div>
        {{ errormsg }}
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('act/triggeralerts')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer
          ><v-btn large outlined class="mx-4" @click="processAllTriggerAlertAct"
            >TRIGGER ALL</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="resetform"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
