<script>
import Layout from '@layouts/main.vue'

import SnackService from '@services/SnackService'

export default {
  metaInfo: {
    title: 'Success',
    meta: [{ name: 'description', content: 'Success' }],
  },
  components: { Layout },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
  created() {
    SnackService.warning('MESSAGES.SUCCESSACTION')

    this.$router.push({ path: '/' })
  },
}
</script>
<template>
  <Layout>
    <h1 :class="$style.title">
      Success
    </h1>
  </Layout>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
