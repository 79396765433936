import store from '@state/store'
import {
  DBdeactivate,
  DBupdateobjarrayUnion,
  DBupdateobjarrayObjUnion,
  DBupdateobjarrayRemove,
  DBinsertonesync,
} from '@/src/state/io/iodb.js'

const TABLENAME = 'doctypes'

// Creates and manages the doctype state per uniqid and group.
export const state = {
  // debug
  dbdoctypeid: 0,
}

export const getters = {
  dbdoctypeid(state) {
    return state.dbdoctypeid
  },
}

export const mutations = {
  // debug
  SET_DOCTYPEID(state, id) {
    state.dbdoctypeid = id
  },
}

export const actions = {
  init({ commit }) {},

  // writes the doctype for a group.  Defines the custom doctype.
  writeDocType({ commit, state }, payload) {
    var doctypeEx = {
      doctype: payload.doctype,
      description: payload.description,
      ownerid: [payload.ownerid],

      // used in first iteration.
      addlclauses: [],
      addltags: [],
      addlmetadata: [], // not used

      // store for now
      clauses: [],
      metadata: [],
      tags: [],

      // modelid for later.
      modelidclause: '',
      modelidtag: '',
      modelidmetatadata: '',

      // stored for later.
      collaborationrules: [], // default collaboration rules
      negotiationrules: [], // this is default behavior for all doc of this doctype, can override per document.
      notificationrules: [], // this is default behavior for all docs of this doctype

      // list of templates that might work for this document type.
      templateid: [], // list of templates that can be applied to this document.  Can assign from a list.

      log: [],
      create: new Date(),
      createdby: payload.ownerid,
      delete: '',
      active: true,
    }
    // dbdoctypes
    //   .add(doctypeEx)
    DBinsertonesync(TABLENAME, doctypeEx)
      .then((doc) => commit('SET_DOCTYPEID', doc.id))
      .catch(function(e) {
        store.dispatch('dberror/logError', e)
      })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.CREATEDOCTYPE',
        detail: payload.doctype,
        context: payload.description,
      },
    })
  },

  deleteDocType({ commit, state }, payload) {
    DBdeactivate(TABLENAME, payload.doctypeid)

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: { action: 'ADMINEVENTS.DELETEDOCTYPE', context: '' },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEDOCTYPE',
        detail: payload.doctypeid,
        context: '',
      },
    })
  },

  addOwnerDocType({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      TABLENAME,
      payload.doctypeid,
      'ownerid',
      payload.ownerid
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.ADDOWNERTODOCTYPE',
        context: payload.ownerid,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDOWNERTODOCTYPE',
        detail: payload.doctypeid,
        context: payload.ownerid,
      },
    })
  },

  deleteOwnerDocType({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      TABLENAME,
      payload.doctypeid,
      'ownerid',
      payload.ownerid
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEOWNERFROMDOCTYPE',
        context: payload.ownerid,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEOWNERFROMDOCTYPE',
        detail: payload.doctypeid,
        context: payload.ownerid,
      },
    })
  },

  addAddlClauseDocType({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      TABLENAME,
      payload.doctypeid,
      'addlclauses',
      payload.addlclause
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.ADDCLAUSETODOCTYPE',
        context: payload.addlclause,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDCLAUSETODOCTYPE',
        detail: payload.doctypeid,
        context: payload.addlclause,
      },
    })
  },

  deleteAddlClauseDocType({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      TABLENAME,
      payload.doctypeid,
      'addlclauses',
      payload.addlclause
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.DELETECLAUSEDOCTYPE',
        context: payload.addlclause,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETECLAUSEDOCTYPE',
        detail: payload.doctypeid,
        context: payload.addlclause,
      },
    })
  },

  addAddlTagDocType({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      TABLENAME,
      payload.doctypeid,
      'addltags',
      payload.addltag
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.ADDTAGDOCTYPE',
        context: payload.addltag,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDTAGDOCTYPE',
        detail: payload.doctypeid,
        context: payload.addltag,
      },
    })
  },

  deleteAddlTagDocType({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      TABLENAME,
      payload.doctypeid,
      'addltags',
      payload.addltag
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.DELETETAGDOCTYPE',
        context: payload.addltag,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETETAGDOCTYPE',
        detail: payload.doctypeid,
        context: payload.addltag,
      },
    })
  },

  addCollabRuleDocType({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      TABLENAME,
      payload.doctypeid,
      'collaborationrules',
      payload.collaborationrule
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.ADDCOLLABRULEDOCTYPE',
        context: payload.collaborationrule,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDCOLLABRULEDOCTYPE',
        detail: payload.doctypeid,
        context: payload.collaborationrule,
      },
    })
  },

  deleteCollabRuleDocType({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      TABLENAME,
      payload.doctypeid,
      'collaborationrules',
      payload.collaborationrule
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.DELETECOLLABRULEDOCTYPE',
        context: payload.collaborationrule,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETECOLLABRULEDOCTYPE',
        detail: payload.doctypeid,
        context: payload.collaborationrule,
      },
    })
  },

  addNotificationRuleDocType({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      TABLENAME,
      payload.doctypeid,
      'notificationrules',
      payload.notificationrule
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.ADDNOTIFRULEDOCTYPE',
        context: payload.notificationrule,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDNOTIFRULEDOCTYPE',
        detail: payload.doctypeid,
        context: payload.notificationrule,
      },
    })
  },

  deleteNotificationRuleDocType({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      TABLENAME,
      payload.doctypeid,
      'notificationrules',
      payload.notificationrule
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.DELETENOTIFRULEDOCTYPE',
        context: payload.notificationrule,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETENOTIFRULEDOCTYPE',
        detail: payload.doctypeid,
        context: payload.notificationrule,
      },
    })
  },

  addNegotiationRuleDocType({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      TABLENAME,
      payload.doctypeid,
      'negotiationrules',
      payload.negotiationrule
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.ADDNEGOTRULEDOCTYPE',
        context: payload.negotiationrule,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDNEGOTRULEDOCTYPE',
        detail: payload.doctypeid,
        context: payload.negotiationrule,
      },
    })
  },

  deleteNegotiationRuleDocType({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      TABLENAME,
      payload.doctypeid,
      'negotiationrules',
      payload.negotiationrule
    )

    // logDocType
    store.dispatch('dbdoctype/logDocType', {
      uniqid: payload.uniqid,
      doctypeid: payload.doctypeid,
      logdetail: {
        action: 'ADMINEVENTS.DELETENEGOTRULEDOCTYPE',
        context: payload.negotiationrule,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETENEGOTRULEDOCTYPE',
        detail: payload.doctypeid,
        context: payload.negotiationrule,
      },
    })
  },

  logDocType({ commit, state }, payload) {
    let insertdetail = payload.logdetail
    insertdetail.doctypeid = payload.doctypeid
    insertdetail.uniqid = payload.uniqid
    insertdetail.timestamp = new Date()

    DBupdateobjarrayObjUnion(TABLENAME, payload.doctypeid, 'log', insertdetail)
  },
}
