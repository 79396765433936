// Contains all application constants //

export const EN = {
  TOPMENU: {
    FILE: 'FILE',
    EDIT: 'EDIT',
    ACT: 'ACT',
    DOCS: 'DOCS',
  },

  MENU: {
    NEWDOCUMENT: 'New Document',
    SAVEDOCUMENT: 'Save Document',
    COPYDOCUMENT: 'Copy Document',
    CREATEVERSION: 'Create Clean Version',
    CREATEDOCFROMTEMPLATE: 'Create Doc from Template',
    OPENDOCUMENT: 'Open Document',
    OPENGROUPDOCUMENT: 'Open Group Document',
    OPENHUBDOCUMENT: 'Open HUB Document',
    OPENARCHIVEDOCUMENT: 'Open Archive Document',
    OPENPLAYBOOK: 'Open Playbook',
    OPENTEMPLATE: 'Open Template',
    COMPAREDOCUMENT: 'Compare Document',
    SAVEASTEMPLATE: 'Save As Template',
    SAVEASPLAYBOOK: 'Save As Playbook',
    MOVETOARCHIVE: 'Move to Archive',
    CLOSEDOCUMENT: 'Close Document',
    DOCUMENTLOG: 'Document Log',

    GOTOPARAGRAPH: 'Go to Paragraph',
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'Document Notes',
    COLLABCOMMENTS: 'Collab - Comments',
    COLLABDOCEDITS: 'Collab - DocEdits',
    COLLABRISKANDLABELS: 'Collab - Risk and Labels',
    NEGOTCOMMENTS: 'Negot - Comments',
    NEGOTDOCEDITS: 'Negot - DocEdits',

    COLLABORATION: 'Collaboration',
    NEGOTIATION: 'Negotiation',
    ADDOWNER: 'Add Owner',
    ADDCOLLABORATOR: 'Add Collaborator',
    ADDNEGOTIATOR: 'Add Negotiator',
    TRIGGERALERTS: 'Trigger Alerts',
    DOWNLOADSOURCE: 'Download Source',
    SENDTOCLM: 'Send to CLM',
    EXPORTJSON: 'Export JSON',

    EXPORTEXCEL: 'Download Excel',
    ESIGNATURE: 'Send for Esignature',
    DOWNLOADREDLINE: 'Download Redline DocX',

    DOCUMENTSETTINGS: 'Document Settings',
    EXPAND: 'Expand',
    COLLAPSE: 'Collapse',

    TRANSLATETOENGLISH: 'Translate to English',
    ORIGINALLANGUAGE: 'Revert to Original',

    ALLPARAGRAPHS: 'All Paragraphs',
    EDITEDPARAGRAPHSONLY: 'Edited Paragraphs Only',

    NOEDITCLAUSELABELS: 'No Edit Clause Labels',
    EDITCLAUSELABELS: 'Edit Clause Labels',

    HIDEEDITS: 'Hide Edits',
    SHOWEDITS: 'Show Edits',

    SHOWALLEDITS: 'Show All Edits',
    SHOWNEGOTEDITSONLY: 'Show Negot Edits Only',

    GROUPS: 'Groups',
    DOCUMENTTYPES: 'Document Types',
    ALERTS: 'Alerts',
    QUICKSTARTTUTORIAL: 'Quickstart Tutorial',
    QUICKSTARTVIDEOS: 'Quickstart Videos',
    UPGRADESUBSCRIPTION: 'Upgrade Subscription',
    SUBSCRIPTIONBILLING: 'Subscription/Billing',
    ACCOUNTSETTINGS: 'Account Settings',
    USERLOG: 'User Log',
    CHANGEPASSWORD: 'Change Password',
    LOGOUT: 'Logout',

    // HEADINGS
    ADMIN: 'ADMIN',
    PROFILE: 'PROFILE',
    LPROFILE: 'Profile',
    FILE: 'FILE',
    EDIT: 'EDIT',
    ACT: 'ACT',
    TOPIC: 'TOPIC',
    DOCS: 'DOCS',

    // Field Headings in Navigation Bar
    NAME: 'Name',
    ROLE: 'Role',
    STATE: 'State',
    GROUP: 'Group',
    TYPE: 'Type',
    SENSIT: 'Sensit',
    LTOPIC: 'Topic',
    USER: 'User',

    // Other Headings
    REGISTER: 'REGISTER',
    LOGIN: 'LOGIN',

    LREGISTER: 'Register',
    LLOGIN: 'Login',

    ADMINEXPLAIN: 'Administrative capabilities',
    PROFILEEXPLAIN: 'Account Management, Subscriptions, Billing',

    // AUTH LEVELS
    PREMIUM: 'Premium',
    PREMIUMPREPAID: 'Premium (Prepaid)',
    BASIC: 'Basic',
    BASICPREPAID: 'Basic (Prepaid)',
    VIPACCESS: 'VIP Access',
    VIPMEMBER: 'VIP Member',
    UNLIMITED: 'Unlimited',
    TRIALUSER: 'Trial User',
    REFERREDUSER: 'Referred User',

    SOURCEDOCUMENT: 'Source Document',
    COMPARISONDOCUMENT: 'Comparison Document',
    EXIT: 'EXIT',
    EFFECTIVETOPIC: 'EFFECTIVE TOPIC',
    PARAGRAPH: 'PARAGRAPH',
    DOCCOMPMODE: 'DOCUMENT COMPARISON MODE',
  },

  TOPIC: {
    NONE: 'NONE',
    PREAMBLE: 'PREAMBLE',
    DEFN: 'DEFN',
    PAYMENT: 'PAYMENT',
    TAXES: 'TAXES',
    REPWARR: 'REP WARR',
    PERIOD: 'PERIOD',
    TERMINATION: 'TERMINATION',
    CONFID: 'CONFID',
    INDEMNITY: 'INDEMNITY',
    LIABILITY: 'LIABILITY',
    INTELPROP: 'INTEL PROP',
    SIGNATURE: 'SIGNATURE',
    OTHER: 'OTHER',
    DATASECURITY: 'DATA SECURITY',
    ASSIGN: 'ASSIGN',
    NOTICE: 'NOTICE',
    GOVERNLAW: 'GOVERN LAW',
    DISPUTE: 'DISPUTE',
    ENTIREAGREE: 'ENTIRE AGREE',
    SURVIVAL: 'SURVIVAL',
    RELATPART: 'RELAT PART',
    THIRDPARTY: 'THIRD PARTY',
    FORCEMAJEURE: 'FORCE MAJEURE',
    SEVERABILITY: 'SEVERABILITY',
    EXPORTCTRL: 'EXPORT CTRL',
    INTRO: 'INTRO',
    INSURANCE: 'INSURANCE',
    HEADING: 'HEADING',
    ALL: 'ALL',
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'E-mail is required',
    EMAILVALID: 'E-mail must be valid',
    MAX20CHARS: 'Max 20 characters',
    MAX60CHARS: 'Max 60 characters',
    MAX80CHARS: 'Max 80 characters',
    MAX160CHARS: 'Max 160 characters',
    EMAILALREADYOWNER: 'Email is already an owner',
    EMAILALREADYCOLLABORATOR: 'Email is already a collaborator',
    EMAILALREADYNEGOTIATOR: 'Email is already a negotiator',
    EMAILALREADYOWNERDOCTYPE: 'Email is already an owner of the document type',
    EMAILALREADYCOLLABORATORDOCTYPE:
      'Email is already an collaborator of the document type',
    REQUIRED: 'Required.',
    CLAUSEFIELDREQUIRED: 'This clause field is required for this operation',
    CLAUSENOTDEFAULT: 'Cannot add clause from default clause list',
    TAGFIELDREQUIRED: 'This tag field is required for this operation',
    TAGNOTDEFAULT: 'Cannot add tags from default tag list',

    PARAINDEXREQUIRED: 'Paragraph index is required',
    PARAINDEXINVALID: 'Paragraph index does not exist',
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'save document',
    OPENDOCUMENT: 'open document',
    CLOSEDOCUMENT: 'close document',
    ADDNOTE: 'add note',
    ADDCOLLABORATOR: 'add collaborator',
    DELETECOLLABORATOR: 'delete collaborator',
    ADDNEGOTIATOR: 'add negotiator',
    DELETENEGOTIATOR: 'delete negotiator',
    UPDATEARCHIVEDATA: 'update archive data',
    UPDATEDOCMETA: 'update doc metadata',
    ADDOWNER: 'add owner',
    DELETEOWNER: 'delete owner',
    CHANGECOLLABORATION: 'changed collaboration settings',
    CHANGENEGOTIATION: 'changed negotiation settings',
    SAVEPLAYBOOK: 'saved as playbook',
    SAVETEMPLATE: 'saved as template',
    SAVEARCHIVE: 'saved as archived',
    TRIGGERALERT: 'trigger alert',
    TRIGGERALLALERT: 'trigger all alert',
    TRIGGERCUSTOMALERT: 'trigger custom alert',
    ADDCOMMENT: 'add comment',
    DELETECOMMENT: 'delete comment',
    ADDDOCEDIT: 'add docedit',
    ADDAPPROVEDDOCEDIT: 'add approved docedit',
    APPROVEDDOCEDIT: 'approve docedit',
    REJECTDOCEDIT: 'reject docedit',
    NEGOTIATORAPPROVEDDOCEDIT: 'negotiator approved docedit',
    NEGOTIATORREJECTEDDOCEDIT: 'negotiator rejected docedit',
    DELETEDDOCEDIT: 'delete docedit',
    ATTACHCONTRACT: 'attach exec contract',
    ADDTAG: 'add tag',
    DELETETAG: 'delete tag',

    NEWSIGNATUREREQUEST: 'new signature requested',
    CANCELSIGNATUREREQUEST: 'cancelled signature request',

    CREATEREDLINEREQUEST: 'create new redline document',
    CREATEFINALDOCREQUEST: 'create new final document for signature',

    COPIEDDOCUMENT: 'copied from document',
    CREATECLEANVERSION: 'created clean version from document',
    CREATEDOCFROMTEMPLATE: 'created document from template',
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'create doctype',
    DELETEDOCTYPE: 'delete doctype',
    ADDOWNERTODOCTYPE: 'added owner to doctype',
    DELETEOWNERFROMDOCTYPE: 'deleted owner to doctype',
    ADDCLAUSETODOCTYPE: 'added addl clause to doctype',
    DELETECLAUSEDOCTYPE: 'deleted addl clause to doctype',
    ADDTAGDOCTYPE: 'added addl tag to doctype',
    DELETETAGDOCTYPE: 'deleted addl tag from doctype',
    ADDCOLLABRULEDOCTYPE: 'added collab rules to  doctype',
    DELETECOLLABRULEDOCTYPE: 'deleted collab rules from doctype',
    ADDNOTIFRULEDOCTYPE: 'added notification rules to  doctype',
    DELETENOTIFRULEDOCTYPE: 'deleted notification rules from doctype',
    ADDNEGOTRULEDOCTYPE: 'added negotiation rules to  doctype',
    DELETENEGOTRULEDOCTYPE: 'deleted negotiation rules from doctype',

    // GROUPS
    CREATEGROUP: 'created group',
    DELETEGROUP: 'deleted group',
    ADDOWNERGROUP: 'added owner to group',
    DELETEOWNERGROUP: 'deleted Owner from group',
    ADDCOLLABGROUP: 'added collaborator to group',
    DELETECOLLABGROUP: 'deleted collaborator from group',
    ADDDOCTYPETOGROUP: 'added doctype to group',
    DELETEDOCTYPEGROUP: 'deleted doctype from group',

    // ALERTS
    CREATEALERT: 'created alert',
    DELETEALERT: 'deleted alert',
    ADDOWNERALERT: 'added owner to alert',
    DELETEOWNERALERT: 'deleted owner from alert',
    ADDDOCTYPEALERT: 'added doctype to alert',
    DELETEDOCTYPEALERT: 'deleted doctype from alert',
    ADDCLAUSETOPICALERT: 'added clause topic to alert',
    DELETECLAUSETOPICALERT: 'deleted clause topic from alert',
    ADDRECIPIENTALERT: 'added recipient to alert',
    DELETERECIPIENTALERT: 'deleted recipient from alert',
    ADDNOTIFRULEALERT: 'added notification rules to alert',
    DELETENOTIFRULEALERT: 'deleted notification rules from alert',
    SETSELECTIVITYALERT: 'set selectivity to  alert',
  },

  USEREVENTS: {
    USERSIGNON: 'User Login',
    // ORIGINAL TEXT: User Login
    PASSWORDRESETREQUESTSENT: 'password reset request sent',
    CHANGEDPASSWORD: 'changed password',
    DELETEDREGISTRATION: 'deleted user registration',
    UPDATEDACCOUNTINFO: 'updated account information',
    UPGRADEDACCOUNTTOREFERRAL: 'upgraded account to referred account',
    DELETEDUSERACCOUNT: 'deleted user account',
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: 'DOCUMENT ACTIONS',
    COMMENTS: 'COMMENTS',
    DOCEDITS: 'DOCUMENT EDITS',
    LABELSRISK: 'LABELS AND RISK',
    TEXT: 'TEXT',
    MLE_DESC: 'DOCGILITY',
    CLAUSE: 'Clause',

    // PRIVILEGE
    OWNER: 'owner',
    COLLABORATOR: 'collaborator',
    NEGOTIATOR: 'negotiator',
    OWNERGROUP: 'owner(group)',
    COLLABORATORGROUP: 'collaborator(group)',

    // TITLES
    SAVEDOCUMENT: 'SAVE DOCUMENT',
    SAVEARCHIVE: 'SAVE ARCHIVE',
    QUICKSTART: 'QUICKSTART TUTORIAL',
    TUTORIALVIDEOS: 'TUTORIAL VIDEOS',

    MOVETOARCHIVEDOCUMENT: 'MOVE TO ARCHIVE DOCUMENT',
    OPENDOCUMENT: 'OPEN DOCUMENT',
    COPYDOCUMENT: 'COPY DOCUMENT',
    CREATEVERSION: 'CREATE CLEAN VERSION',
    CREATEDOCFROMTEMPLATE: 'CREATE DOCUMENT FROM TEMPLATE',
    OPENGROUPDOCUMENT: 'OPEN GROUP DOCUMENT',
    OPENHUBDOCUMENT: 'OPEN HUB DOCUMENT',
    OPENARCHIVEDOCUMENT: 'OPEN ARCHIVE DOCUMENT',
    OPENPLAYBOOK: 'OPEN PLAYBOOK',
    OPENTEMPLATE: 'OPEN TEMPLATE',
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'SAVE AS TEMPLATE',
    COLLABORATION: 'COLLABORATION',
    NEGOTIATION: 'NEGOTIATION',
    ADDOWNER: 'ADD OWNER',
    ADDNEGOTIATOR: 'ADD NEGOTIATOR',
    ADDCOLLABORATOR: 'ADD COLLABORATOR',
    ERRORMESSAGE: 'ERROR MESSAGE',
    DOCUMENTINFO: 'DOCUMENT INFORMATION',
    DOCUMENTLOGS: 'DOCUMENT LOGS',
    USERLOGS: 'USER LOGS',
    DOCUMENTNOTES: 'DOCUMENT NOTES',
    TRIGGERALERTS: 'TRIGGER ALERTS',
    ALERTS: 'ALERTS',
    DOCTYPES: 'DOCUMENT TYPES',
    GROUPS: 'GROUPS',
    NOTE: 'NOTE',
    GOTOPARAGRAPH: 'GO TO PARAGRAPH',
    ESIGNATURE: 'E-SIGNATURE',
    DOWNLOADREDLINE: 'DOWNLOAD REDLINE DOCX',

    // ACTIONS
    ADD: 'ADD',
    BACK: 'BACK',
    DELETE: 'DELETE',
    SAVE: 'SAVE',
    MOVETOARCHIVE: 'MOVE TO ARCHIVE',
    COPY: 'COPY',
    CREATE: 'CREATE',
    OPEN: 'OPEN',
    CLOSE: 'CLOSE',
    UPDATE: 'UPDATE',
    REGISTER: 'REGISTER',
    LOGIN: 'LOGIN',
    LOGINSSO: 'LOGIN SSO',
    RESETPASSWORD: 'RESET PASSWORD',
    CHANGEPASSWORD: 'CHANGE PASSWORD',
    CANCELACCOUNT: 'CANCEL ACCOUNT',
    QUICKSTARTVIDEO: 'WATCH QUICKSTART VIDEO',

    CHARGEBASIC: 'UPGRADE TO BASIC',
    CHARGEPREMIUM: 'UPGRADE TO PREMIUM',
    CHARGECORPORATE: 'UPGRADE TO CORPORATE',
    UPGRADESUBSCRIPTION: 'UPGRADE SUBSCRIPTION',
    GO: 'GO',
    PREVIEW_FEEDBACK: 'REVIEW CHANGES',
    ACTIONS: 'ACT',
    REFRESH: 'REFRESH',
    ADDNEWGROUP: 'ADD NEW GROUP',
    ADDNEWDOCTYPE: 'ADD NEW DOCTYPE',
    ADDNEWALERT: 'ADD NEW ALERT',
    GROUPSLIST: 'REVIEW GROUP LIST',

    DOWNLOADDOCUMENT: 'DOWNLOAD DOCUMENT',
    DOCUMENTTITLE: 'Document Title',
    DOCUMENTSUBJECT: 'Document Subject',
    SIGNMESSAGE: 'Message for Signing',
    NAMEPRIMARYSIGNER: 'Name: Primary Signer',
    EMAILPRIMARYSIGNER: 'Email: Primary Signer',
    NAMECOUNTERSIGNER: 'Name: Counter Signer',
    EMAILCOUNTERSIGNER: 'Email: Counter Signer',
    SIGREQUESTED: 'SIGNATURE REQUESTED',
    SIGREQUESTEDBY: 'REQUESTED BY',
    SIGREQUESTEDID: 'SIGNATURE ID',
    SIGREQUESTEDAT: 'REQUESTED AT',
    SUBMITSIG: 'SUBMIT FOR SIGNATURE',
    CANCELSIG: 'CANCEL SIGNATURE REQUEST',

    // DATA LABELS
    NAME: 'NAME',

    EMAIL: 'EMAIL',
    STATUS: 'STATUS',
    LASTVIEWED: 'LAST VIEWED',
    LASTREVIEWED: 'LAST REVIEWED',
    SIGNEDAT: 'SIGNED',

    OWNERAPPROVAL: 'OWNER APPROVAL',
    OWNERACTION: 'OWNER ACTION',
    NEGOTIATORAPPROVAL: 'NEGOTIATOR APPROVAL',
    NEGOTIATORACTION: 'NEGOTIATOR ACTION',
    ARCHIVENOTES: 'ARCHIVE NOTES',
    EXECUTEDCONTRACT: 'EXECUTED',
    EXECUTEDDATE: 'EXECUTED DATE',
    CONTRACTATTACHED: 'CONTRACT ATTACHED',
    STARTDATE: 'START DATE',
    ENDDATE: 'END DATE',
    EDIT: 'EDIT',
    USER: 'USER',
    TIME: 'TIME',
    CONFIRM: 'CONFIRM',
    CONFIRMACTION: 'CONFIRM ACTION',
    CURRENT_LABEL: 'CURRENT LABEL',
    NEW_LABEL: 'NEW LABEL',
    DESCRIPTION: 'DESCRIPTION',
    TYPE: 'TYPE',
    SENTENCE: 'SENTENCE',
    NUMBER: '#',
    PRIVILEGE: 'ROLE',
    OWNERID: 'OWNERID',
    DOCNAME: 'DOC NAME',
    DOCTYPE: 'DOC TYPE',
    DOCDESCRIPTION: 'DOC DESCRIPTION',
    DOCNOTES: 'DOC NOTES',
    DOCPARTY: 'PARTY',
    DOCCOUNTERPARTY: 'COUNTER PARTY',
    DOCRAWFILENAME: 'DOC FILE NAME',
    TAGS: 'TAGS',
    COLLABORATIONSTATUS: 'COLLAB STATUS',
    COLLABORATIONEND: 'COLLAB END',
    NEGOTIATIONSTATUS: 'NEGOT STATUS',
    NEGOTIATIONEND: 'NEGOT END',
    DOCID: 'DOC ID',
    PARAGRAPHID: 'PARA ID',
    ACTION: 'LOGGED ACTION',
    DETAIL: 'ACTION DETAIL',
    CONTEXT: 'ADDITIONAL CONTEXT',
    GROUPNAME: 'GROUP NAME',
    GROUPDESC: 'GROUP DESCRIPTION',
    DOCTYPEDESC: 'DOC TYPE DESCRIPTION',
    ADDLCLAUSETYPES: 'CUSTOM CLAUSE LABELS',
    ADDLTAGS: 'CUSTOM TAGS',
    COLLABORATIONRULES: 'COLLABORATION RULES',
    NEGOTIATIONRULES: 'NEGOTIATION RULES',
    NOTIFICATIONRULES: 'NOTIFICATION RULES',
    LOG: 'LOG',
    ALERTNAME: 'ALERT NAME',
    ALERTDESC: 'ALERT DESCRIPTION',
    TOPICS: 'TOPICS',
    RECIPIENTS: 'RECIPIENTS',
    SELECTIVITY: 'SELECTIVITY',

    FIELDNAME: 'FIELD NAME',
    ORDER: 'ORDER',
    EXAMPLE: 'EXAMPLE',
    DEFAULT: 'DEFAULT',
    VALUE: 'VALUE',

    // DOCUMENT TYPES
    DOCUMENT: 'DOCUMENT',
    PLAYBOOK: 'PLAYBOOK',
    TEMPLATE: 'TEMPLATE',
    ARCHIVEDOC: 'ARCHIVEDOC',

    TEMPLATEORIGDOCNAME: 'ORIGINATED FROM TEMPLATE:',

    COLSHORT: 'COL',
    NEGSHORT: 'NEG',
    OWNERS: 'OWNERS',
    COLLABORATORS: 'COLLABORATORS',
    NEGOTIATORS: 'NEGOTIATORS',
    DOCUMENTDETAILS: 'DOCUMENT DETAILS',
    CREATIONDATE: 'CREATION DATE',
    PARTICIPANTS: 'PARTICIPANTS',
    COLLABORATIONANDNEGOTIATION: 'COLLABORATION AND NEGOTIATION',
    DOCTYPEANDGROUPACCESS: 'DOCUMENT TYPE AND GROUP ACCESS',
    GROUPOWNERS: 'GROUP OWNERS',
    GROUPCOLLABORATORS: 'GROUP COLLABORATORS',
    DOCUMENTSETTINGS: 'DOCUMENT SETTINGS',
    ENABLECOLLABTOMODLABELS: 'Enable Collaborators to Modify Clause Labels',
    ENABLENEGOTCAPABILITIES:
      'Enable Negotiation capabilities (Not Negotiation mode)',
    KEEPDOCNOTES: 'Copy Doc Notes with Copy Operation',
    REVIEWEDCLAUSETOPICS: 'Reviewed and Amended Clause Topics as Necessary',
    NOEXECUTIONDATE: 'No Execution Date',
    EXECUTIONDATE: 'Execution Date',
    CONTRACTSTARTDATE: 'Contract Start Date',
    CONTRACTENDDATE: 'Contract End Date',
    EXECCONTRACTNAME: 'Executed Contract File Name',
    SELECTEXECCONTRACT: 'select file to upload executed contract',
    UPLOADEXECUTEDCONTRACT: 'UPLOAD EXECUTED CONTRACT',
    LOADDEFAULTS: 'LOAD DEFAULTS',
    CLOSED: 'CLOSED',
    NONE: 'NONE',
    NA: 'NA',
    RESULTEXECUTEDCONTRACT: 'Resulted in Executed Contract',
    NOSTARTDATE: 'No Start Date',
    NOENDDATE: 'No End Date',
    EXCLUDECOLLABORATORSFROMACCESS:
      'Exclude External Collaborators From Accessing Document',
    EXCLUDENEGOTIATORSFROMACCESS:
      'Exclude External Negotiators From Accessing Document',
    REVIEWEDAMENDEDCLAUSELABELS:
      'Reviewed and Amended Clause Labels as Necessary',
    ENTERPARAGRAPHBETWEEN: 'Enter a paragraph number between:',
    GOTOPARAGRAPHINDEX: 'Go to Paragraph Index',
    INPUTDOCUMENTNOTEHERE: 'Input Document Note here',
    INPUTCOMMENTHERE: 'Input Comment here',
    SHOWPENDINGONLY: 'Show Pending Only',
    COLLABORATIONSETTING: 'COLLABORATION SETTING',
    NEGOTIATIONSETTING: 'NEGOTIATION SETTING',
    NONEGOTWHENAPPROVED: 'Cannot turn on Negotiation when in Approved State',
    EMAILTOADD: 'Email to add',
    CLAUSETOADD: 'Clause to add',
    TAGTOADD: 'Tag to add',
    OPTIONSFORREDLINEINCLUDE: 'Options for Redlines to Include',
    ONLYINCLUDEOWNERAPPROVEDEDITS: 'Only Include Owner-approved Document Edits',
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'Only Include Owner and Negotiator-approved Document Edits',
    INCLUDEALLDOCEDITS: 'Include All Document Edits (regardless of approval)',
    REDLINECHANGESSELECTED: 'Redlined Changes Selected',
    TOTALCHANGES: 'Total Changes',
    REPLACEREDLINES: 'Replace Redlines',
    DELETEREDLINES: 'Delete Redlines',
    ADDAFTERREDLINES: 'Add-After Redlines',
    OPTIONSFORPREVREDLINES: 'Options for Previous Redlines',
    OVERWRITEPREVREDLINES: 'Overwrite all Previous Redlines in Document Source',
    KEEPPREVREDLINES: 'Keep all Previous Redlines in Document Source',
    GENERATEFINALDOC: 'Generate Final Document (without Redlines)',

    DEFAULTESIGNOTE:
      'As discussed, please provide your eSignature to this contract.',
    SUBMITTEDSIGMESSAGE: 'Submitted for signatures.  Please wait.  ',
    UPLOADEDMESSAGE: 'Uploaded',
    ADDADDLFILES: 'Optional - Adding additional files to signature request',
    ADDFILESMSG: 'add additional files for signature',
    ESIGPREPAIDPLANS:
      'eSignature feature is only available through our Prepaid plans.',
    ESIGPREPAIDPLANSCONTACT:
      'Contact us at billing@mylegaleinstein.com for more details.',
    MOREVIDEOSAT: 'More videos at the ',
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'Document type is not supported.  MLE only accepts docx and pdf formats.',
    PDFSUPPORTEDFORPREMIUMONLY:
      'For PDF scanning, user needs to upgrade to Premium subscription or better.',
    BADDOCFORMAT: 'Bad Document format was passed to document processing.',
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      'You do not have the privilege to make changes to the Document Settings.  You are required to be an Owner.',
    PRIVILEGSNOTAVAIL:
      'The privileges for this user are not available.  Try again later.',
    EXHAUSTEDTRIALLICENSE:
      'You have exhausted the number of allowed documents under your trial period.  Please select "Upgrade Subscription" in your profile.',
    EXHAUSTEDMONTHLYLIMIT:
      'You have exhausted the number of allowed monthly documents under your trial period.  Please select "Upgrade Subscription" in your profile.',
    PASSWORDNOTMATCHING: 'Passwords do not match.  Check the passwords again.',
    ABAMEMBERIDLENGTH:
      'ABA Membership IDs are 7 characters long.  Please review and submit.',
    TERMSNOTCHECKED:
      'The Terms and Conditions have not been reviewed and checked.',
    EMAILFORMATBAD: 'Email format is not valid.  Please review email address.',
    CANCELEDACTION:
      'Your last action have been canceled.  Application will now reload.',
    SUCCESSACTION:
      'Your last action has been successful.  Application will now reload.',
    USERSUCCESSLOGIN: 'User has successfully logged on.',
    USERLOGGEDOUT: 'The user has been logged out',
    EMAILNOTVERIFIED:
      'Account email has not yet been verified.  An email verification has been sent to you.',
    EMAILALREADYREGISTERED:
      'This email already has a previously registered account.',
    EMAILNOTVERIFIEDRESENT:
      'Your email has not been verified.  We have resent you an email verification link to your email to activate the new account.',
    USERLOGFILENOTEXISTS: 'Error - no log user file exists.',
    PASSWORDRESETSENT:
      'An email password reset message has been sent for your account.',
    PASSWORDCHANGED: 'Your password has been changed.',
    REGISTRATIONDELETED: 'deleted user registration',
    USERACCOUNTUPDATED:
      'You have updated your user account settings.  Changes will take into effect the next time you login.',
    UPGRADEDTOREFERRED:
      'Your account has been updated to Referred Trial subscription.',
    REGISTEREDTRIALUSER: 'New account is registered as Trial subscription.',
    DELETEDUSERACCOUNT: 'Deleted user profile account',
    EMAILALREADYREGISTEREDRESENT:
      'Email has not already been registered with Docgility.  An email invitation has been sent to the new user.',
    UPGRADEEXTERNALPAYMENTS:
      'Thanks for upgrading.  You will be rerouted to an external payment service and after completing your transaction, you will need to login again for subscription service to start.',
    BILLINGEXTERNALPAYMENTS:
      'Please confirm that you will like to be directed to our external payment and billing service.  After you make any changes, you will need to login again for changes to take effect.',
    NOTABLETOLOGINATTHISTIME:
      'Not able to login at this time.  Try again later.',
    NOTREGISTERED:
      'User has not previously registered.  Register before trying to login.',
    NAMETOOLONG: 'The name you entered is too long.  Max is 60 characters.',
    COMPANYTOOLONG:
      'The company you entered is too long.  Max is 60 characters.',
    COMMENTTOOLONG:
      'The comments you entered is too long.  Max is 10000 characters.',
    DOCEDITTOOLONG:
      'The document edits you entered is too long.  Max is 10000 characters.',
    TITLETOOLONG: 'The title you entered is too long.  Max is 60 characters.',
    PASSWORDTOOSHORT:
      'The password you entered is too short.  Password has to be at least 6 characters.',
    CONGRATSNEWDOCUMENTPROCESSING:
      'Great! You have processed a new document.  It takes a few minutes and the panel should refresh when the document is completed processing.  Please wait a few minutes.',

    NODOCUMENTLOADED: 'No document loaded',
    SELECTFILETOUPLOADDOCX: 'select file to upload (*.docx)',
    SELECTFILETOUPLOADDOCXPDF: 'select file to upload (*.docx, *.pdf)',
    INPUTCOMMENTHERE: 'Input comment here',
    AUTOAPPROVEASOWNER: 'Auto Approve as Owner',
    INPUTDOCEDITSHERE: 'Input Document Edit here',

    TRIGGEREDALLALERTS:
      'Triggered all (if any) Alerts for this doctype document.',
    QSINTROMESSAGE:
      'MyLegalEinstein instantly analyzes your contracts, indexes by legal topics, and allows side-by-side comparisons of legal language in a complete contract collaboration platform.',
    QSDOCMESSAGE:
      'For your convenience, here are some public domain contracts that you can download and try:',
    QSHELPMESSAGE:
      "Here's a handy link to product documentation (with wordy version of Quick Start)",

    DOCUMENTFAILEDTOPROCESS:
      'A fatal error occurred while processing the document.  Please try again.  If problem persists, please contact customer support at support@mylegaleinstein.com.',

    // ADDED
    DOCUMENTSUCCESSCOPIED: 'Your document has now been copied.',

    DOCUMENTSUCCESSCLEANVERSIONED:
      'A clean version of this document has now been generated and saved.',
    DOCUMENTSUCCESSFROMTEMPLATE:
      'A document was generated from this template and saved.',
    DOCUMENTCONVERTEDTOTEMPLATE:
      'A document has been converted into a document template.',

    VIEWGROUPDOCS: 'Access Group Documents',
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      'Please confirm that you want to delete the specified document.  After document is deleted, it will no longer be available.  You may also consider moving the document to Archive status.  \n\n Please confirm delete operation.',
    CONFIRMSAVETOPLAYBOOK:
      'Please confirm that to convert this document to a playbook.  This would make this document unchangable except for the owner.  \n\n Please confirm the save to playbook operation.',
    COLLABORATIONNOTE:
      'NOTE: This information is shared INTERNALLY ONLY with designated collaborators and document owners only.',
    NEGOTIATIONNOTE:
      'NOTE: This information is shared EXTERNALLY with negotiation counter parties.',
    CONFIRMDELETEALERT:
      'Please confirm that you want to delete the specified alert.  After alert is deleted, it will no longer be available.  Please confirm delete operation.',
    CONFIRMDELETERECIPIENTALERT:
      'Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.',
    CONFIRMDELETEGROUP:
      'Please confirm that you want to delete the specified group.  After group is deleted, it will no longer be available.  However, it will reflected the next time you log back in.  Please confirm delete operation.',
    CONFIRMDELETECOLLABORATORGROUP:
      'Please confirm that you no longer want to be a collaborator for the specified group.  After removing yourself as a collaborator, you can request to be added back by contacting the owner of the group.  Please confirm delete operation.',
    CONFIRMDELETEDOCTYPE:
      'Please confirm that you want to delete the specified doctype.  After doctype is deleted, it will no longer be available.  Please confirm delete operation.',
    EXPIREDTRIAL:
      'Your trial license has expired.  Please upgrade to continue your service.',
    EXPIREDSUBSCRIPTION:
      'Your subscription license has expired.  Please upgrade to continue your service or contact billing at Docgility.',
    SOURCEDOCNOTDOCX:
      'The Source document is not a DOCX document.  Redline capabilities are not available.',
  },
}

export default EN
