// Contains all application constants //

export const EL = {
  TOPMENU: {
    FILE: 'FILE',
    EDIT: 'EDIT',
    ACT: 'ACT',
    DOCS: 'DOCS',
  },

  MENU: {
    NEWDOCUMENT: 'Νέο έγγραφο',
    // ORIGINAL TEXT: New Document
    SAVEDOCUMENT: 'Αποθήκευση εγγράφου',
    // ORIGINAL TEXT: Save Document
    COPYDOCUMENT: 'Αντιγραφή εγγράφου',
    // ORIGINAL TEXT: Copy Document
    CREATEVERSION: 'Δημιουργία καθαρής έκδοσης',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: 'Create Doc from Template',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: 'Άνοιγμα εγγράφου',
    // ORIGINAL TEXT: Open Document
    OPENGROUPDOCUMENT: 'Άνοιγμα εγγράφου ομάδας',
    // ORIGINAL TEXT: Open Group Document
    OPENHUBDOCUMENT: 'Άνοιγμα HUB εγγράφου',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: 'Άνοιγμα εγγράφου αρχειοθήκης',
    // ORIGINAL TEXT: Open Archive Document
    OPENPLAYBOOK: 'Open Playbook',
    // ORIGINAL TEXT: Open Playbook
    OPENTEMPLATE: 'Open Template',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: 'Save As Template',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: 'Αποθήκευση ως Playbook',
    // ORIGINAL TEXT: Save As Playbook
    MOVETOARCHIVE: 'Μετακίνηση στο Αρχείο',
    // ORIGINAL TEXT: Move to Archive
    CLOSEDOCUMENT: 'Κλείσιμο εγγράφου',
    // ORIGINAL TEXT: Close Document
    DOCUMENTLOG: 'Ημερολόγιο εγγράφου',
    // ORIGINAL TEXT: Document Log

    GOTOPARAGRAPH: 'Μετάβαση στην παράγραφο',
    // ORIGINAL TEXT: Go to Paragraph
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'Σημειώσεις εγγράφων',
    // ORIGINAL TEXT: Document Notes
    COLLABCOMMENTS: 'Συνεργασία - Σχόλια',
    // ORIGINAL TEXT: Collaboration - Comments
    COLLABDOCEDITS: 'Συνεργασία - Επεξεργασία εγγράφων',
    // ORIGINAL TEXT: Collaboration - Document Edits
    COLLABRISKANDLABELS: 'Συνεργασία - Κίνδυνος και ετικέτες',
    // ORIGINAL TEXT: Collaboration - Risk and Labels
    NEGOTCOMMENTS: 'Διαπραγμάτευση - Σχόλια',
    // ORIGINAL TEXT: Negotiation - Comments
    NEGOTDOCEDITS: 'Διαπραγμάτευση - Επεξεργασία εγγράφων',
    // ORIGINAL TEXT: Negotiation - Document Edits

    COLLABORATION: 'Συνεργασία',
    // ORIGINAL TEXT: Collaboration
    NEGOTIATION: 'Διαπραγμάτευση',
    // ORIGINAL TEXT: Negotiation
    ADDOWNER: 'Προσθήκη ιδιοκτήτη',
    // ORIGINAL TEXT: Add Owner
    ADDCOLLABORATOR: 'Προσθήκη συνεργάτη',
    // ORIGINAL TEXT: Add Collaborator
    ADDNEGOTIATOR: 'Προσθήκη διαπραγματευτή',
    // ORIGINAL TEXT: Add Negotiator
    TRIGGERALERTS: 'Συναγερμοί ενεργοποίησης',
    // ORIGINAL TEXT: Trigger Alerts
    EXPORTEXCEL: 'Εξαγωγή Excel',
    // ORIGINAL TEXT: Export Excel
    DOWNLOADSOURCE: 'Λήψη πηγαίου κώδικα',
    // ORIGINAL TEXT: Download Source
    SENDTOCLM: 'Αποστολή σε CLM',
    // ORIGINAL TEXT: Send to CLM
    EXPORTJSON: 'Εξαγωγή JSON',
    // ORIGINAL TEXT: Export JSON
    ESIGNATURE: 'Αποστολή υπογραφής',
    // ORIGINAL TEXT: Send for Esignature
    DOWNLOADREDLINE: 'Κατεβάστε το Redline DocX',
    // ORIGINAL TEXT: Download Redline DocX
    DOCUMENTSETTINGS: 'Ρυθμίσεις εγγράφων',
    // ORIGINAL TEXT: Document Settings
    EXPAND: 'Επεκτεινε',
    // ORIGINAL TEXT: Expand
    COLLAPSE: 'Σύγκρουση',
    // ORIGINAL TEXT: Collapse
    TRANSLATETOENGLISH: 'Μετάφραση στα αγγλικά',
    // ORIGINAL TEXT: Translate to English
    ORIGINALLANGUAGE: 'Επαναφορά στο αρχικό',
    // ORIGINAL TEXT: Revert to Original

    ALLPARAGRAPHS: 'Όλες οι παράγραφοι',
    // ORIGINAL TEXT: All Paragraphs
    EDITEDPARAGRAPHSONLY: 'Επεξεργασμένες μόνο παράγραφοι',
    // ORIGINAL TEXT: Edited Paragraphs Only

    NOEDITCLAUSELABELS: 'Δεν επεξεργασία ετικετών ρήτρας',
    // ORIGINAL TEXT: No Edit Clause Labels
    EDITCLAUSELABELS: 'Επεξεργασία ετικετών ρήτρας',
    // ORIGINAL TEXT: Edit Clause Labels

    HIDEEDITS: 'Απόκρυψη επεξεργαστών',
    // ORIGINAL TEXT: Hide Edits
    SHOWEDITS: 'Εμφάνιση επεξεργαστών',
    // ORIGINAL TEXT: Show Edits

    SHOWALLEDITS: 'Εμφάνιση όλων των επεξεργαστών',
    // ORIGINAL TEXT: Show All Edits
    SHOWNEGOTEDITSONLY: 'Εμφάνιση μόνο επεξεργαστών διαπραγμάτευσης',
    // ORIGINAL TEXT: Show Negotiation Edits Only

    GROUPS: 'Ομάδες',
    // ORIGINAL TEXT: Groups
    DOCUMENTTYPES: 'Τύποι εγγράφων',
    // ORIGINAL TEXT: Document Types
    ALERTS: 'Συναγερμοί',
    // ORIGINAL TEXT: Alerts
    QUICKSTARTTUTORIAL: 'Γρήγορη εκκίνηση Tutorial',
    // ORIGINAL TEXT: Quick Start Tutorial
    QUICKSTARTVIDEOS: 'Βίντεο γρήγορης εκκίνησης',
    // ORIGINAL TEXT: Quick Start Videos
    UPGRADESUBSCRIPTION: 'Αναβάθμιση συνδρομής',
    // ORIGINAL TEXT: Upgrade Subscription
    SUBSCRIPTIONBILLING: 'Χρέωση συνδρομής',
    // ORIGINAL TEXT: Subscription Billing
    ACCOUNTSETTINGS: 'Ρυθμίσεις λογαριασμού',
    // ORIGINAL TEXT: Account Settings
    USERLOG: 'Καταγραφή χρήστη',
    // ORIGINAL TEXT: User Log
    CHANGEPASSWORD: 'Αλλαγή κωδικού πρόσβασης',
    // ORIGINAL TEXT: Change Password
    LOGOUT: 'Αποσύνδεση',
    // ORIGINAL TEXT: Logout

    // HEADINGS
    ADMIN: 'ADMIN',
    // ORIGINAL TEXT: ADMIN
    PROFILE: 'ΠΡΟΗΓΟΥΜΕΝΟ',
    // ORIGINAL TEXT: PROFILE
    LPROFILE: 'Προφίλ',
    // ORIGINAL TEXT: Profile
    FILE: 'ΑΡΧΕΙΟ',
    // ORIGINAL TEXT: FILE
    EDIT: 'EDIT',
    // ORIGINAL TEXT: EDIT
    ACT: 'ΠΡΑΞΗ',
    // ORIGINAL TEXT: ACT
    TOPIC: 'ΤΟΠΙΚΟΣ',
    // ORIGINAL TEXT: TOPIC
    DOCS: 'DOCS',
    // ORIGINAL TEXT: DOCS

    // Field Headings in Navigation Bar
    NAME: 'Όνομα',
    // ORIGINAL TEXT: Name
    ROLE: 'Ρόλος',
    // ORIGINAL TEXT: Role
    STATE: 'Κατάσταση',
    // ORIGINAL TEXT: State
    GROUP: 'Ομάδα',
    // ORIGINAL TEXT: Group
    TYPE: 'Τύπος',
    // ORIGINAL TEXT: Type
    SENSIT: 'Ευαισθησία',
    // ORIGINAL TEXT: Sensitivity
    LTOPIC: 'Θέμα',
    // ORIGINAL TEXT: Topic
    USER: 'Χρήστη',
    // ORIGINAL TEXT: User

    // Other Headings
    REGISTER: 'ΜΗΤΡΩΟ',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'LOGIN',
    // ORIGINAL TEXT: LOGIN

    LREGISTER: 'Μητρώο',
    // ORIGINAL TEXT: Register
    LLOGIN: 'Σύνδεση',
    // ORIGINAL TEXT: Login

    ADMINEXPLAIN: 'Διοικητικές ικανότητες',
    // ORIGINAL TEXT: Administrative capabilities
    PROFILEEXPLAIN: 'Διαχείριση λογαριασμού, συνδρομές, Χρεώσεις',
    // ORIGINAL TEXT: Account Management, Subscriptions, Billing

    // AUTH LEVELS
    PREMIUM: 'Θετική πριμοδότηση',
    // ORIGINAL TEXT: Premium
    PREMIUMPREPAID: 'Premium (προπληρωμένη)',
    // ORIGINAL TEXT: Premium (Prepaid)
    BASIC: 'Βασικό',
    // ORIGINAL TEXT: Basic
    BASICPREPAID: 'Βασικό (προπληρωμένο)',
    // ORIGINAL TEXT: Basic (Prepaid)
    VIPACCESS: 'Πρόσβαση VIP',
    // ORIGINAL TEXT: VIP Access
    VIPMEMBER: 'VIP Μέλος',
    // ORIGINAL TEXT: VIP Member
    UNLIMITED: 'Απεριόριστα',
    // ORIGINAL TEXT: Unlimited
    TRIALUSER: 'Χρήστης δοκιμής',
    // ORIGINAL TEXT: Trial User
    REFERREDUSER: 'Αναφερόμενος χρήστης',
    // ORIGINAL TEXT: Referred User
    SOURCEDOCUMENT: 'Έγγραφο πηγής',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: 'Έγγραφο σύγκρισης',
    // ORIGINAL TEXT: Comparison Document
    EXIT: 'ΕΞΟΔΟΣ',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: 'Παράγραφος',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: 'ΛΕΙΤΟΥΡΓΙΑ ΣΥΓΚΡΙΣΗΣ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: 'ΝΟΟΥ',
    // ORIGINAL TEXT: NONE
    PREAMBLE: 'ΠΡΟΟΙΜΙΟ',
    // ORIGINAL TEXT: PREAMBLE
    DEFN: 'ΟΡΙΣΜΟΣ',
    // ORIGINAL TEXT: DEFINITION
    PAYMENT: 'ΠΛΗΡΩΜΑ',
    // ORIGINAL TEXT: PAYMENT
    TAXES: 'ΔΑΣΜΟΙ',
    // ORIGINAL TEXT: TAXES
    REPWARR: 'ΑΝΤΑΛΛΑΓΗ ΚΑΙ ΕΠΙΤΥΧΙΑ',
    // ORIGINAL TEXT: REPRESENTATION AND WARRANTIES
    PERIOD: 'ΧΡΟΝΟΣ',
    // ORIGINAL TEXT: PERIOD
    TERMINATION: 'ΠΕΡΑΤΩΣΗ',
    // ORIGINAL TEXT: TERMINATION
    CONFID: 'ΕΜΠΙΣΤΕΥΤΙΚΟΤΗΤΑ',
    // ORIGINAL TEXT: CONFIDENTIALITY
    INDEMNITY: 'ΑΚΑΘΑΡΙΣΤΟ',
    // ORIGINAL TEXT: INDEMNITY
    LIABILITY: 'ΕΥΘΥΝΗ',
    // ORIGINAL TEXT: LIABILITY
    INTELPROP: 'ΠΝΕΥΜΑΤΙΚΟΣ ΠΟΡΟΣ',
    // ORIGINAL TEXT: INTELLECTUAL PROP
    SIGNATURE: 'ΥΠΟΓΡΑΦΗ',
    // ORIGINAL TEXT: SIGNATURE
    OTHER: 'ΑΛΛΑ ΣΤΟΙΧΕΙΑ',
    // ORIGINAL TEXT: OTHER
    DATASECURITY: 'Ασφάλεια των δεδομένων',
    // ORIGINAL TEXT: DATA SECURITY
    ASSIGN: 'ΣΥΜΒΟΥΛΕΣ',
    // ORIGINAL TEXT: ASSIGNMENT
    NOTICE: 'ΕΓΓΡΑΦΗ',
    // ORIGINAL TEXT: NOTICE
    GOVERNLAW: 'ΔΙΟΙΚΗΤΙΚΟ ΔΙΚΑΙΟ',
    // ORIGINAL TEXT: GOVERNING LAW
    DISPUTE: 'ΕΚΠΤΩΣΗ',
    // ORIGINAL TEXT: DISPUTE
    ENTIREAGREE: 'Συμφωνία για το σύνολο',
    // ORIGINAL TEXT: ENTIRE AGREEMENT
    SURVIVAL: 'ΕΠΙΣΚΟΠΗΣΗ',
    // ORIGINAL TEXT: SURVIVAL
    RELATPART: 'ΑΝΤΙΣΤΟΙΧΙΑ ΜΕΤΑΞΥ ΤΩΝ ΜΕΡΩΝ',
    // ORIGINAL TEXT: RELATION BETWEEN PARTIES
    THIRDPARTY: 'ΤΡΙΤΟ ΜΕΡΟΣ',
    // ORIGINAL TEXT: THIRD PARTY
    FORCEMAJEURE: 'ΔΥΝΑΜΙΚΟ ΜΕΓΑΛΥΤΕΡΟ',
    // ORIGINAL TEXT: FORCE MAJEURE
    SEVERABILITY: 'ΔΙΑΚΥΒΕΡΝΗΣΗ',
    // ORIGINAL TEXT: SEVERABILITY
    EXPORTCTRL: 'ΕΛΕΓΧΟΙ ΕΞΑΓΩΓΗΣ',
    // ORIGINAL TEXT: EXPORT CONTROLS
    INTRO: 'ΕΙΣΑΓΩΓΗ',
    // ORIGINAL TEXT: INTRODUCTION
    INSURANCE: 'ΑΣΦΑΛΙΣΗ',
    // ORIGINAL TEXT: INSURANCE
    HEADING: 'ΟΔΗΓΗΣΗ',
    // ORIGINAL TEXT: HEADING
    ALL: 'ΟΛΑ',
    // ORIGINAL TEXT: ALL
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'Απαιτείται ηλεκτρονικό ταχυδρομείο',
    // ORIGINAL TEXT: E-mail is required
    EMAILVALID: 'Το ηλεκτρονικό ταχυδρομείο πρέπει να είναι έγκυρο',
    // ORIGINAL TEXT: E-mail must be valid
    MAX20CHARS: 'Μέγιστος αριθμός 20 χαρακτήρων',
    // ORIGINAL TEXT: Max 20 characters
    MAX60CHARS: 'Μέγιστος αριθμός 60 χαρακτήρων',
    // ORIGINAL TEXT: Max 60 characters
    MAX80CHARS: 'Μεγ. 80 χαρακτήρες',
    // ORIGINAL TEXT: Max 80 characters
    MAX160CHARS: 'Μέγιστο 160 χαρακτήρες',
    // ORIGINAL TEXT: Max 160 characters
    EMAILALREADYOWNER: 'Το email είναι ήδη ιδιοκτήτης',
    // ORIGINAL TEXT: Email is already an owner
    EMAILALREADYCOLLABORATOR: 'Το email είναι ήδη συνεργάτης.',
    // ORIGINAL TEXT: Email is already a collaborator
    EMAILALREADYNEGOTIATOR: 'Το email είναι ήδη διαπραγματευτής.',
    // ORIGINAL TEXT: Email is already a negotiator
    EMAILALREADYOWNERDOCTYPE:
      'Το ηλεκτρονικό ταχυδρομείο είναι ήδη ιδιοκτήτης του τύπου εγγράφου',
    // ORIGINAL TEXT: Email is already an owner of the document type
    EMAILALREADYCOLLABORATORDOCTYPE:
      'Το email είναι ήδη συνεργάτης του τύπου εγγράφου',
    // ORIGINAL TEXT: Email is already an collaborator of the document type
    REQUIRED: 'Απαιτούμενο.',
    // ORIGINAL TEXT: Required.
    CLAUSEFIELDREQUIRED:
      'Το πεδίο αυτό ρήτρας απαιτείται για την εν λόγω λειτουργία',
    // ORIGINAL TEXT: This clause field is required for this operation
    CLAUSENOTDEFAULT:
      'Αδυναμία προσθήκης ρήτρας από τη λίστα προεπιλεγμένων διατάξεων',
    // ORIGINAL TEXT: Cannot add clause from default clause list
    TAGFIELDREQUIRED:
      'Αυτό το πεδίο ετικετών απαιτείται για αυτή την λειτουργία',
    // ORIGINAL TEXT: This tag field is required for this operation
    TAGNOTDEFAULT:
      'Αδυναμία προσθήκης ετικετών από προεπιλεγμένη λίστα ετικετών',
    // ORIGINAL TEXT: Cannot add tags from default tag list

    PARAINDEXREQUIRED: 'Απαιτείται δείκτης παραγράφου',
    // ORIGINAL TEXT: Paragraph index is required
    PARAINDEXINVALID: 'Ο δείκτης παραγράφου δεν υπάρχει',
    // ORIGINAL TEXT: Paragraph index does not exist
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'Αποθήκευση εγγράφου',
    OPENDOCUMENT: 'Ανοικτό έγγραφο',
    // ORIGINAL TEXT: open document
    CLOSEDOCUMENT: 'Κλείσιμο εγγράφου',
    // ORIGINAL TEXT: close document
    ADDNOTE: 'Προσθήκη σημείωσης',
    // ORIGINAL TEXT: add note
    ADDCOLLABORATOR: 'Προσθήκη συνεργάτη',
    // ORIGINAL TEXT: add collaborator
    DELETECOLLABORATOR: 'Διαγράψτε τον συνεργάτη',
    // ORIGINAL TEXT: delete collaborator
    ADDNEGOTIATOR: 'Προσθήκη διαπραγματευτή',
    // ORIGINAL TEXT: add negotiator
    DELETENEGOTIATOR: 'διαγραφή διαπραγματευτή',
    // ORIGINAL TEXT: delete negotiator
    UPDATEARCHIVEDATA: 'ενημέρωση των δεδομένων αρχειοθήκης',
    // ORIGINAL TEXT: update archive data
    UPDATEDOCMETA: 'ενημέρωση των μεταδεδομένα εγγράφων',
    // ORIGINAL TEXT: update document metadata
    ADDOWNER: 'προσθήκη ιδιοκτήτη',
    // ORIGINAL TEXT: add owner
    DELETEOWNER: 'διαγραφή ιδιοκτήτη',
    // ORIGINAL TEXT: delete owner
    CHANGECOLLABORATION: 'αλλαγμένες ρυθμίσεις συνεργασίας',
    // ORIGINAL TEXT: changed collaboration settings
    CHANGENEGOTIATION: 'αλλαγμένες ρυθμίσεις διαπραγμάτευσης',
    // ORIGINAL TEXT: changed negotiation settings
    SAVEPLAYBOOK: 'save as playbook',
    // ORIGINAL TEXT: saved as playbook
    SAVETEMPLATE: 'saved as template',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: 'αποθήκευση ως αρχειοθετημένο',
    // ORIGINAL TEXT: saved as archived
    TRIGGERALERT: 'Συναγερμός ενεργοποίησης',
    // ORIGINAL TEXT: trigger alert
    TRIGGERALLALERT: 'ενεργοποιήστε όλες τις καταχωρίσεις',
    // ORIGINAL TEXT: trigger all alerts
    TRIGGERCUSTOMALERT: 'ενεργοποίηση προσαρμοσμένης ειδοποίησης',
    // ORIGINAL TEXT: trigger custom alert
    ADDCOMMENT: 'προσθήκη σχολίου',
    // ORIGINAL TEXT: add comment
    DELETECOMMENT: 'διαγραφή σχολίου',
    // ORIGINAL TEXT: delete comment
    ADDDOCEDIT: 'add docedit',
    // ORIGINAL TEXT: add docedit
    ADDAPPROVEDDOCEDIT: 'προσθήκη εγκεκριμένης επεξεργασίας εγγράφου',
    // ORIGINAL TEXT: add approved document edit
    APPROVEDDOCEDIT: 'έγκριση επεξεργασίας εγγράφου',
    // ORIGINAL TEXT: approve document edit
    REJECTDOCEDIT: 'απόρριψη επεξεργασίας εγγράφου',
    // ORIGINAL TEXT: reject document edit
    NEGOTIATORAPPROVEDDOCEDIT:
      'Επεξεργασία εγκεκριμένου εγγράφου από διαπραγματευτή',
    // ORIGINAL TEXT: negotiator approved document edit
    NEGOTIATORREJECTEDDOCEDIT:
      'Απορρίφθηκε η επεξεργασία εγγράφου διαπραγμάτευσης',
    // ORIGINAL TEXT: negotiator rejected document edit
    DELETEDDOCEDIT: 'διαγραφή επεξεργασίας εγγράφου',
    // ORIGINAL TEXT: delete document edit
    ATTACHCONTRACT: 'Επισυνάψτε σύμβαση exec',
    // ORIGINAL TEXT: attach exec contract
    ADDTAG: 'προσθήκη ετικέτας',
    // ORIGINAL TEXT: add tag
    DELETETAG: 'διαγραφή ετικέτας',
    // ORIGINAL TEXT: delete tag
    NEWSIGNATUREREQUEST: 'Ζητήθηκε νέα υπογραφή',
    // ORIGINAL TEXT: new signature requested
    CANCELSIGNATUREREQUEST: 'ακυρωθείσα αίτηση υπογραφής',
    // ORIGINAL TEXT: cancelled signature request
    CREATEREDLINEREQUEST: 'δημιουργία νέου εγγράφου κόκκινης γραμμής',
    // ORIGINAL TEXT: create new redline document
    CREATEFINALDOCREQUEST: 'δημιουργία νέου τελικού εγγράφου για υπογραφή',
    // ORIGINAL TEXT: create new final document for signature
    COPIEDDOCUMENT: 'αντιγράφηκε από έγγραφο',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: 'δημιούργησε την καθαρή έκδοση από το έγγραφο',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: 'created document from template',
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'δημιουργία τύπου εγγράφου',
    // ORIGINAL TEXT: create document type
    DELETEDOCTYPE: 'διαγραφή τύπου εγγράφου',
    // ORIGINAL TEXT: delete document type
    ADDOWNERTODOCTYPE: 'προστιθέμενη ιδιοκτήτρια σε τύπο εγγράφου',
    // ORIGINAL TEXT: added owner to document type
    DELETEOWNERFROMDOCTYPE: 'διαγράφηκε ο ιδιοκτήτης σε τύπο εγγράφου',
    // ORIGINAL TEXT: deleted owner to document type
    ADDCLAUSETODOCTYPE: 'Προστέθηκε ρήτρα προσθήκης στον τύπο εγγράφου',
    // ORIGINAL TEXT: added addl clause to document type
    DELETECLAUSEDOCTYPE: 'Διαγράφηκε ρήτρα προσθήκης στον τύπο εγγράφου',
    // ORIGINAL TEXT: deleted addl clause to document type
    ADDTAGDOCTYPE: 'προσθήκη ετικέτας addel στον τύπο εγγράφου',
    // ORIGINAL TEXT: added addl tag to document type
    DELETETAGDOCTYPE: 'διαγραφή ετικέτας addl από τον τύπο εγγράφου',
    // ORIGINAL TEXT: deleted addl tag from document type
    ADDCOLLABRULEDOCTYPE: 'Προστέθηκε κανόνες colab για τον τύπο εγγράφου',
    // ORIGINAL TEXT: added collab rules to  document type
    DELETECOLLABRULEDOCTYPE:
      'διαγραμμένοι κανόνες collab από τον τύπο εγγράφου',
    // ORIGINAL TEXT: deleted collab rules from document type
    ADDNOTIFRULEDOCTYPE: 'πρόσθετοι κανόνες κοινοποίησης για τον τύπο εγγράφου',
    // ORIGINAL TEXT: added notification rules to  document type
    DELETENOTIFRULEDOCTYPE:
      'διαγραμμένοι κανόνες κοινοποίησης από τον τύπο του εγγράφου',
    // ORIGINAL TEXT: deleted notification rules from document type
    ADDNEGOTRULEDOCTYPE:
      'πρόσθετοι κανόνες διαπραγμάτευσης για τον τύπο εγγράφου',
    // ORIGINAL TEXT: added negotiation rules to  document type
    DELETENEGOTRULEDOCTYPE:
      'διαγραμμένοι κανόνες διαπραγμάτευσης από τον τύπο εγγράφου',
    // ORIGINAL TEXT: deleted negotiation rules from document type

    // GROUPS
    CREATEGROUP: 'δημιουργημένη ομάδα',
    // ORIGINAL TEXT: created group
    DELETEGROUP: 'διαγραμμένη ομάδα',
    // ORIGINAL TEXT: deleted group
    ADDOWNERGROUP: 'προστιθέμενη ιδιοκτήτρια στην ομάδα',
    // ORIGINAL TEXT: added owner to group
    DELETEOWNERGROUP: 'διαγράφηκε Ιδιοκτήτης από ομάδα',
    // ORIGINAL TEXT: deleted Owner from group
    ADDCOLLABGROUP: 'Προστέθηκε συνεργάτης στην ομάδα',
    // ORIGINAL TEXT: added collaborator to group
    DELETECOLLABGROUP: 'διαγράφηκε συνεργάτης από την ομάδα',
    // ORIGINAL TEXT: deleted collaborator from group
    ADDDOCTYPETOGROUP: 'Προστέθηκε τύπος εγγράφου στην ομάδα',
    // ORIGINAL TEXT: added document type to group
    DELETEDOCTYPEGROUP: 'διαγράφεται ο τύπος εγγράφου από την ομάδα',
    // ORIGINAL TEXT: deleted document type from group

    // ALERTS
    CREATEALERT: 'δημιουργία ειδοποίησης',
    // ORIGINAL TEXT: created alert
    DELETEALERT: 'διαγράφηκε η καταχώριση',
    // ORIGINAL TEXT: deleted alert
    ADDOWNERALERT: 'Προστέθηκε ιδιοκτήτης σε προειδοποίηση',
    // ORIGINAL TEXT: added owner to alert
    DELETEOWNERALERT: 'διαγράφηκε ο ιδιοκτήτης από την καταχώριση',
    // ORIGINAL TEXT: deleted owner from alert
    ADDDOCTYPEALERT: 'Προστέθηκε doctype σε καταχώριση',
    // ORIGINAL TEXT: added doctype to alert
    DELETEDOCTYPEALERT: 'διαγραμμένο έγγραφο από την καταχώριση',
    // ORIGINAL TEXT: deleted doctype from alert
    ADDCLAUSETOPICALERT: 'Προστέθηκε θέμα ρήτρας για την καταχώριση',
    // ORIGINAL TEXT: added clause topic to alert
    DELETECLAUSETOPICALERT: 'διαγράφηκε θέμα ρήτρας από την καταχώριση',
    // ORIGINAL TEXT: deleted clause topic from alert
    ADDRECIPIENTALERT: 'Προστέθηκε παραλήπτης σε καταχώριση',
    // ORIGINAL TEXT: added recipient to alert
    DELETERECIPIENTALERT: 'διαγράφηκε ο παραλήπτης από την καταχώριση',
    // ORIGINAL TEXT: deleted recipient from alert
    ADDNOTIFRULEALERT: 'προστιθέμενοι κανόνες κοινοποίησης για την καταχώριση',
    // ORIGINAL TEXT: added notification rules to alert
    DELETENOTIFRULEALERT:
      'διαγραμμένοι κανόνες κοινοποίησης από την καταχώριση',
    // ORIGINAL TEXT: deleted notification rules from alert
    SETSELECTIVITYALERT: 'Ορισμός της επιλεκτικότητας για την καταχώριση',
    // ORIGINAL TEXT: set selectivity to  alert
  },

  USEREVENTS: {
    USERSIGNON: 'Συνδεδεμένος χρήστης',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT:
      'αίτημα επαναφοράς κωδικού πρόσβασης που αποστέλλεται',
    // ORIGINAL TEXT: password reset request sent
    CHANGEDPASSWORD: 'αλλαγμένο κωδικό πρόσβασης',
    // ORIGINAL TEXT: changed password
    DELETEDREGISTRATION: 'διαγραμμένη εγγραφή χρήστη',
    // ORIGINAL TEXT: deleted user registration
    UPDATEDACCOUNTINFO: 'επικαιροποιημένες πληροφορίες λογαριασμού',
    // ORIGINAL TEXT: updated account information
    UPGRADEDACCOUNTTOREFERRAL:
      'αναβαθμισμένος λογαριασμός στον αναφερόμενο λογαριασμό',
    // ORIGINAL TEXT: upgraded account to referred account
    DELETEDUSERACCOUNT: 'διαγραμμένος λογαριασμός χρήστη',
    // ORIGINAL TEXT: deleted user account
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: 'ΔΡΑΣΕΙΣ ΤΟΥ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT ACTIONS
    COMMENTS: 'ΠΑΡΑΤΗΡΗΣΕΙΣ',
    // ORIGINAL TEXT: COMMENTS
    DOCEDITS: 'ΕΚΔΟΤΕΣ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT EDITS
    LABELSRISK: 'ΕΤΙΚΕΤΕΣ ΚΑΙ ΚΙΝΔΥΝΟΣ',
    // ORIGINAL TEXT: LABELS AND RISK
    TEXT: 'ΚΕΙΜΕΝΟΥ',
    // ORIGINAL TEXT: TEXT
    MLE_DESC: 'DOCGILITY',
    // ORIGINAL TEXT: DOCGILITY
    CLAUSE: 'Ρήτρα',
    // ORIGINAL TEXT: Clause

    // PRIVILEGE
    OWNER: 'ιδιοκτήτης',
    // ORIGINAL TEXT: owner
    COLLABORATOR: 'συνεργάτης',
    // ORIGINAL TEXT: collaborator
    NEGOTIATOR: 'διαπραγματευτής',
    // ORIGINAL TEXT: negotiator
    OWNERGROUP: 'ιδιοκτήτης (ομάδα)',
    // ORIGINAL TEXT: owner(group)
    COLLABORATORGROUP: 'συνεργάτης (ομάδα)',
    // ORIGINAL TEXT: collaborator(group)

    // TITLES
    SAVEDOCUMENT: 'ΕΓΓΡΑΦΟ SAVE',
    // ORIGINAL TEXT: SAVE DOCUMENT
    SAVEARCHIVE: 'ΑΠΟΘΗΚΕΥΣΗ ΑΡΧΕΙΟ',
    // ORIGINAL TEXT: SAVE ARCHIVE
    QUICKSTART: 'Γρήγορα, ξεκινήστε από το TUTORAL',
    // ORIGINAL TEXT: QUICK START TUTORIAL
    TUTORIALVIDEOS: 'TUTORIAL VIDEOS',
    // ORIGINAL TEXT: TUTORIAL VIDEOS

    MOVETOARCHIVEDOCUMENT: 'ΜΕΤΑΚΙΝΗΣΗ ΣΤΟ ΑΡΧΙΚΟ ΕΓΓΡΑΦΟ',
    // ORIGINAL TEXT: MOVE TO ARCHIVE DOCUMENT
    OPENDOCUMENT: 'ΑΝΟΙΚΤΟ ΕΓΓΡΑΦΟ',
    // ORIGINAL TEXT: OPEN DOCUMENT
    COPYDOCUMENT: 'ΕΓΓΡΑΦΟ ΑΝΤΙΓΡΑΦΩΝ',
    // ORIGINAL TEXT: COPY DOCUMENT
    CREATEVERSION: 'ΔΗΜΙΟΥΡΓΗΣΤΕ ΚΑΘΑΡΗ ΕΚΔΟΣΗ',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: 'CREATE DOCUMENT FROM TEMPLATE',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: 'ΕΓΓΡΑΦΟ ΑΝΟΙΚΤΩΝ ΟΜΑΔΩΝ',
    // ORIGINAL TEXT: OPEN GROUP DOCUMENT
    OPENHUBDOCUMENT: 'ΑΝΟΙΚΤΟ HUB ΕΓΓΡΑΦΟ',
    // ORIGINAL TEXT: OPEN HUB DOCUMENT
    OPENARCHIVEDOCUMENT: 'ΑΝΟΙΚΤΟ ΑΡΧΙΚΟ ΕΓΓΡΑΦΟ',
    // ORIGINAL TEXT: OPEN ARCHIVE DOCUMENT
    OPENPLAYBOOK: 'ΑΝΟΙΧΤΟ ΠΑΙΧΝΙΔΙ',
    // ORIGINAL TEXT: OPEN PLAYBOOK
    OPENTEMPLATE: 'OPEN TEMPLATE',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'SAVE AS TEMPLATE',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: 'ΣΥΝΑΦΕΙΑ',
    // ORIGINAL TEXT: COLLABORATION
    NEGOTIATION: 'ΑΝΤΑΠΟΚΡΙΣΗ',
    // ORIGINAL TEXT: NEGOTIATION
    ADDOWNER: 'ΙΔΡΥΤΗΣ ΠΡΟΣΔΙΟΡΙΣΜΟΥ',
    // ORIGINAL TEXT: ADD OWNER
    ADDNEGOTIATOR: 'ADD NEGOTIATOR',
    // ORIGINAL TEXT: ADD NEGOTIATOR
    ADDCOLLABORATOR: 'ADD COLABORATOR',
    // ORIGINAL TEXT: ADD COLLABORATOR
    ERRORMESSAGE: 'ΜΗΝΥΜΑ ΔΙΑΚΑΝΟΝΙΣΜΟΥ',
    // ORIGINAL TEXT: ERROR MESSAGE
    DOCUMENTINFO: 'ΠΛΗΡΟΦΟΡΙΕΣ ΓΙΑ ΤΟ ΕΓΓΡΑΦΟ',
    // ORIGINAL TEXT: DOCUMENT INFORMATION
    DOCUMENTLOGS: 'ΕΓΓΡΑΦΟ LOGS',
    // ORIGINAL TEXT: DOCUMENT LOGS
    USERLOGS: 'LOGS ΧΡΗΣΤΗ',
    // ORIGINAL TEXT: USER LOGS
    DOCUMENTNOTES: 'ΣΗΜΕΙΩΣΕΙΣ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT NOTES
    TRIGGERALERTS: 'ΠΥΡΗΝΙΚΑ ΣΚΑΦΗ',
    // ORIGINAL TEXT: TRIGGER ALERTS
    ALERTS: 'ΕΛΛΗΝΙΚΑ',
    // ORIGINAL TEXT: ALERTS
    DOCTYPES: 'ΤΥΠΟΙ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT TYPES
    GROUPS: 'ΟΜΑΔΕΣ',
    // ORIGINAL TEXT: GROUPS
    NOTE: 'ΣΗΜΕΙΩΣΗ',
    // ORIGINAL TEXT: NOTE
    GOTOPARAGRAPH: 'Πηγαίνετε στην παρθενιά.',
    // ORIGINAL TEXT: GO TO PARAGRAPH
    ESIGNATURE: 'E-ΣΗΜΑΤΟΓΡΑΦΙΑ',
    // ORIGINAL TEXT: E-SIGNATURE
    DOWNLOADREDLINE: 'DOWNLOAD REDLINE DOCX',
    // ORIGINAL TEXT: DOWNLOAD REDLINE DOCX

    // ACTIONS
    ADD: 'ADD',
    // ORIGINAL TEXT: ADD
    BACK: 'Πίσω',
    // ORIGINAL TEXT: BACK
    DELETE: 'DELETE',
    // ORIGINAL TEXT: DELETE
    SAVE: 'SAVE',
    // ORIGINAL TEXT: SAVE
    MOVETOARCHIVE: 'ΜΕΤΑΚΙΝΗΣΗ ΣΤΟ ΑΡΧΕΙΟ',
    // ORIGINAL TEXT: MOVE TO ARCHIVE
    COPY: 'ΑΝΤΙΓΡΑΦΟ',
    // ORIGINAL TEXT: COPY
    CREATE: 'ΔΗΜΙΟΥΡΓΩ',
    // ORIGINAL TEXT: CREATE
    OPEN: 'ΑΝΟΙΓΜΑ',
    // ORIGINAL TEXT: OPEN
    CLOSE: 'ΚΛΕΙΣΤΟ',
    // ORIGINAL TEXT: CLOSE
    UPDATE: 'ΕΝΗΜΕΡΩΣΗ',
    // ORIGINAL TEXT: UPDATE
    REGISTER: 'ΜΗΤΡΩΟ',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'LOGIN',
    // ORIGINAL TEXT: LOGIN
    LOGINSSO: 'LOGIN SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: 'RESET PASSWORD',
    // ORIGINAL TEXT: RESET PASSWORD
    CHANGEPASSWORD: 'ΑΛΛΑΓΗ ΔΙΑΛΟΓΟΥ',
    // ORIGINAL TEXT: CHANGE PASSWORD
    CANCELACCOUNT: 'ΛΟΓΑΡΙΑΣΜΟΣ ΑΚΥΡΩΣΗΣ',
    // ORIGINAL TEXT: CANCEL ACCOUNT
    QUICKSTARTVIDEO: 'Watch Quick Start VIDEO',
    // ORIGINAL TEXT: WATCH QUICK START VIDEO

    CHARGEBASIC: 'ΕΝΙΣΧΥΣΗ ΣΤΟ BASIC',
    // ORIGINAL TEXT: UPGRADE TO BASIC
    CHARGEPREMIUM: 'ΕΝΙΣΧΥΣΗ ΣΤΟ ΠΙΣΤΩΜΑ',
    // ORIGINAL TEXT: UPGRADE TO PREMIUM
    CHARGECORPORATE: 'ΕΝΗΜΕΡΩΣΗ ΓΙΑ ΤΗΝ ΕΤΑΙΡΕΙΑ',
    // ORIGINAL TEXT: UPGRADE TO CORPORATE
    UPGRADESUBSCRIPTION: 'ΕΠΙΓΡΑΦΗ ΑΝΑΠΤΥΞΙΝΟΜΗΣΗΣ',
    // ORIGINAL TEXT: UPGRADE SUBSCRIPTION
    GO: 'Πήγαινε',
    // ORIGINAL TEXT: GO
    PREVIEW_FEEDBACK: 'ΑΛΛΑΓΕΣ ΕΠΑΝΕΞΕΤΑΣΗΣ',
    // ORIGINAL TEXT: REVIEW CHANGES
    ACTIONS: 'ΠΡΑΞΗ',
    // ORIGINAL TEXT: ACT
    REFRESH: 'REFRESH',
    // ORIGINAL TEXT: REFRESH
    ADDNEWGROUP: 'ΠΡΟΣΤΙΘΕΜΕΝΗ ΝΕΑ ΟΜΙΛΟΣ',
    // ORIGINAL TEXT: ADD NEW GROUP
    ADDNEWDOCTYPE: 'ΠΡΟΣΔΙΟΡΙΣΜΟΣ ΝΕΟΥ ΓΙΑΤΡΥΠΕ',
    // ORIGINAL TEXT: ADD NEW DOCTYPE
    ADDNEWALERT: 'ΠΡΟΣΔΙΟΡΙΣΜΟΣ ΝΕΟΥ ΕΛΕΓΧΟΥ',
    // ORIGINAL TEXT: ADD NEW ALERT
    GROUPSLIST: 'ΚΑΤΑΛΟΓΟΣ ΤΗΣ ΟΜΑΔΑΣ ΕΠΑΝΕΞΕΤΑΣΗΣ',
    // ORIGINAL TEXT: REVIEW GROUP LIST

    DOWNLOADDOCUMENT: 'ΚΑΤΑΧΩΡΙΣΜΕΝΟ ΕΓΓΡΑΦΟ',
    // ORIGINAL TEXT: DOWNLOAD DOCUMENT
    DOCUMENTTITLE: 'Τίτλος εγγράφου',
    // ORIGINAL TEXT: Document Title
    DOCUMENTSUBJECT: 'Θέμα εγγράφου',
    // ORIGINAL TEXT: Document Subject
    SIGNMESSAGE: 'Μήνυμα για υπογραφή',
    // ORIGINAL TEXT: Message for Signing
    NAMEPRIMARYSIGNER: 'Όνομα: Πρωτογενής υπογράφων',
    // ORIGINAL TEXT: Name: Primary Signer
    EMAILPRIMARYSIGNER: 'Ηλ. διεύθυνση: Πρωτογενής υπογράφων',
    // ORIGINAL TEXT: Email: Primary Signer
    NAMECOUNTERSIGNER: 'Όνομα: Μετρητής',
    // ORIGINAL TEXT: Name: Counter Signer
    EMAILCOUNTERSIGNER: 'Ηλ. διεύθυνση: Counter Signer',
    // ORIGINAL TEXT: Email: Counter Signer
    SIGREQUESTED: 'ΑΙΤΗΣΗ ΥΠΟΓΡΑΦΗΣ',
    // ORIGINAL TEXT: SIGNATURE REQUESTED
    SIGREQUESTEDBY: 'ΑΙΤΗΣΕΙΣ ΑΠΟ',
    // ORIGINAL TEXT: REQUESTED BY
    SIGREQUESTEDID: 'ID ΥΠΟΓΡΑΦΗΣ',
    // ORIGINAL TEXT: SIGNATURE ID
    SIGREQUESTEDAT: 'ΑΙΤΗΣΕΙΣ ΣΤΟ',
    // ORIGINAL TEXT: REQUESTED AT
    SUBMITSIG: 'ΥΠΟΔΟΜΗ ΥΠΟΓΡΑΦΗΣ',
    // ORIGINAL TEXT: SUBMIT FOR SIGNATURE
    CANCELSIG: 'ΑΙΤΗΣΗ ΥΠΟΓΡΑΦΗΣ CANCEL',
    // ORIGINAL TEXT: CANCEL SIGNATURE REQUEST

    // DATA LABELS
    NAME: 'ΟΝΟΜΑ',
    // ORIGINAL TEXT: NAME
    EMAIL: 'EMAIL',
    // ORIGINAL TEXT: EMAIL
    STATUS: 'STATUS',
    // ORIGINAL TEXT: STATUS
    LASTVIEWED: 'ΤΕΛΕΥΤΑΙΑ ΑΠΟΖΗΤΗΣΗ',
    // ORIGINAL TEXT: LAST VIEWED
    LASTREVIEWED: 'ΤΕΛΕΥΤΑΙΑ ΕΠΑΝΕΞΕΤΑΣΗ',
    // ORIGINAL TEXT: LAST REVIEWED
    SIGNEDAT: 'ΥΠΟΓΡΑΦΗ',
    // ORIGINAL TEXT: SIGNED
    OWNERAPPROVAL: 'ΙΔΡΥΜΑ ΕΓΚΡΙΣΗΣ',
    // ORIGINAL TEXT: OWNER APPROVAL
    OWNERACTION: 'ΔΡΑΣΤΗΡΙΟΤΗΤΑ',
    // ORIGINAL TEXT: OWNER ACTION
    NEGOTIATORAPPROVAL: 'ΕΓΚΡΙΣΗ ΕΓΚΑΤΑΣΤΑΤΩΝ',
    // ORIGINAL TEXT: NEGOTIATOR APPROVAL
    NEGOTIATORACTION: 'ΔΡΑΣΤΗΡΙΟΤΗΤΑ ΝΟΜΟΘΕΤΗΣΕ',
    // ORIGINAL TEXT: NEGOTIATOR ACTION
    ARCHIVENOTES: 'ΑΡΧΙΚΕΣ ΣΗΜΕΙΩΣΕΙΣ',
    // ORIGINAL TEXT: ARCHIVE NOTES
    EXECUTEDCONTRACT: 'ΕΞΕΤΑΖΟΜΕΝΟ',
    // ORIGINAL TEXT: EXECUTED
    EXECUTEDDATE: 'ΗΜΕΡΟΜΗΝΙΑ ΕΚΚΛΗΡΩΣΗΣ',
    // ORIGINAL TEXT: EXECUTED DATE
    CONTRACTATTACHED: 'ΣΥΜΒΑΣΕΙΣ ΠΟΥ ΕΦΑΡΜΟΖΟΝΤΑΙ',
    // ORIGINAL TEXT: CONTRACT ATTACHED
    STARTDATE: 'ΗΜΕΡΟΜΗΝΙΑ ΕΝΑΡΞΗΣ',
    // ORIGINAL TEXT: START DATE
    ENDDATE: 'ΗΜΕΡΟΜΗΝΙΑ ΤΕΛΟΣ',
    // ORIGINAL TEXT: END DATE
    EDIT: 'EDIT',
    // ORIGINAL TEXT: EDIT
    USER: 'ΧΡΗΣΤΗ',
    // ORIGINAL TEXT: USER
    TIME: 'ΩΡΑ',
    // ORIGINAL TEXT: TIME
    CONFIRM: 'ΕΠΙΒΕΒΑΙΩΣΗ',
    // ORIGINAL TEXT: CONFIRM
    CONFIRMACTION: 'ΕΠΙΒΕΒΑΙΩΣΗ ΔΡΑΣΗΣ',
    // ORIGINAL TEXT: CONFIRM ACTION
    CURRENT_LABEL: 'ΠΕΡΙΕΚΤΙΚΗ ΕΤΙΚΕΤΑ',
    // ORIGINAL TEXT: CURRENT LABEL
    NEW_LABEL: 'ΝΕΑ ΕΤΙΚΕΤΑ',
    // ORIGINAL TEXT: NEW LABEL
    DESCRIPTION: 'ΠΕΡΙΓΡΑΦΗ',
    // ORIGINAL TEXT: DESCRIPTION
    TYPE: 'ΤΥΠΟΣ',
    // ORIGINAL TEXT: TYPE
    SENTENCE: 'ΚΑΤΑΔΙΚΑΣΙΑ',
    // ORIGINAL TEXT: SENTENCE
    NUMBER: '#',
    // ORIGINAL TEXT: #
    PRIVILEGE: 'Ρόλος',
    // ORIGINAL TEXT: ROLE
    OWNERID: 'ΙΔΙΟΚΤΗΣΙΑ',
    // ORIGINAL TEXT: OWNERID
    DOCNAME: 'ΟΝΟΜΑ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT NAME
    DOCTYPE: 'ΤΥΠΟΣ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT TYPE
    DOCDESCRIPTION: 'ΠΕΡΙΓΡΑΦΗ ΤΟΥ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT DESCRIPTION
    DOCNOTES: 'ΣΗΜΕΙΩΣΕΙΣ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT NOTES
    DOCPARTY: 'ΜΕΡΟΣ',
    // ORIGINAL TEXT: PARTY
    DOCCOUNTERPARTY: 'ΑΝΘΡΩΠΙΝΟ ΜΕΡΙΚΟ',
    // ORIGINAL TEXT: COUNTER PARTY
    DOCRAWFILENAME: 'ΟΝΟΜΑ ΑΡΧΕΙΟΥ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'TAGS',
    // ORIGINAL TEXT: TAGS
    COLLABORATIONSTATUS: 'ΣΤΑΘΜΟΣ ΣΥΝΑΦΕΙΑΣ',
    // ORIGINAL TEXT: COLLABORATION STATUS
    COLLABORATIONEND: 'ΤΕΛΟΣ ΣΥΝΑΦΕΙΑΣ',
    // ORIGINAL TEXT: COLLABORATION END
    NEGOTIATIONSTATUS: 'ΚΑΘΕΣΤΩΣ ΑΝΑΓΚΗΣ',
    // ORIGINAL TEXT: NEGOTIATION STATUS
    NEGOTIATIONEND: 'ΤΕΛΟΣ ΑΝΑΓΚΗΣ',
    // ORIGINAL TEXT: NEGOTIATION END
    DOCID: 'ID ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT ID
    PARAGRAPHID: 'ID ΠΑΡΑΓΡΑΦΟΥ',
    // ORIGINAL TEXT: PARAGRAPH ID
    ACTION: 'ΛΟΓΗΓΗΜΕΝΗ ΔΡΑΣΗ',
    // ORIGINAL TEXT: LOGGED ACTION
    DETAIL: 'ΛΕΠΤΟΜΕΡΗΣ ΔΡΑΣΗΣ',
    // ORIGINAL TEXT: ACTION DETAIL
    CONTEXT: 'ΠΡΟΣΘΕΤΟ ΠΛΑΙΣΙΟ',
    // ORIGINAL TEXT: ADDITIONAL CONTEXT
    GROUPNAME: 'ΟΝΟΜΑ ΤΗΣ ΟΜΑΔΑΣ',
    // ORIGINAL TEXT: GROUP NAME
    GROUPDESC: 'ΠΕΡΙΓΡΑΦΗ ΤΗΣ ΟΜΑΔΑΣ',
    // ORIGINAL TEXT: GROUP DESCRIPTION
    DOCTYPEDESC: 'ΠΕΡΙΓΡΑΦΗ ΤΥΠΟΥ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT TYPE DESCRIPTION
    ADDLCLAUSETYPES: 'ΠΑΡΑΣΚΕΥΕΣ ΧΑΡΑΚΤΗΡΙΣΤΙΚΩΝ ΧΑΡΑΚΤΗΡΩΝ',
    // ORIGINAL TEXT: CUSTOM CLAUSE LABELS
    ADDLTAGS: 'CUSTOM TAGS',
    // ORIGINAL TEXT: CUSTOM TAGS
    COLLABORATIONRULES: 'ΚΑΝΟΝΙΣΜΟΣ ΣΥΝΑΦΕΙΑΣ',
    // ORIGINAL TEXT: COLLABORATION RULES
    NEGOTIATIONRULES: 'ΚΑΝΟΝΙΣΜΟΣ ΑΝΤΟΧΗΣ',
    // ORIGINAL TEXT: NEGOTIATION RULES
    NOTIFICATIONRULES: 'ΚΑΝΟΝΕΣ ΓΝΩΣΤΟΠΟΙΗΣΗΣ',
    // ORIGINAL TEXT: NOTIFICATION RULES
    LOG: 'LOG',
    // ORIGINAL TEXT: LOG
    ALERTNAME: 'ΟΝΟΜΑ ΕΝΑΛΛΑΚΤΗΣ',
    // ORIGINAL TEXT: ALERT NAME
    ALERTDESC: 'ΠΕΡΙΓΡΑΦΗ ΤΗΣ ΕΝΔΕΙΚΤΙΚΗΣ ΠΕΡΙΓΡΑΦΗΣ',
    // ORIGINAL TEXT: ALERT DESCRIPTION
    TOPICS: 'ΤΟΠΙΚΟΙ',
    // ORIGINAL TEXT: TOPICS
    RECIPIENTS: 'ΕΚΔΟΧΩΝ',
    // ORIGINAL TEXT: RECIPIENTS
    SELECTIVITY: 'ΕΠΙΛΟΓΗ',
    // ORIGINAL TEXT: SELECTIVITY
    FIELDNAME: 'FIELD NAME',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: 'ORDER',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: 'EXAMPLE',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: 'DEFAULT',
    // ORIGINAL TEXT: DEFAULT
    VALUE: 'VALUE',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: 'DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: 'PLAYBOOK',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: 'TEMPLATE',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: 'ARCHIVED DOCUMENT',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: 'ORIGINATED FROM TEMPLATE:',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: 'ΣΥΝΑΦΕΙΑ',
    // ORIGINAL TEXT: COLLABORATION
    NEGSHORT: 'ΑΝΤΑΠΟΚΡΙΣΗ',
    // ORIGINAL TEXT: NEGOTIATION
    OWNERS: 'ΙΔΡΥΤΕΣ',
    // ORIGINAL TEXT: OWNERS
    COLLABORATORS: 'ΣΥΝΑΦΕΙΣ',
    // ORIGINAL TEXT: COLLABORATORS
    NEGOTIATORS: 'ΑΝΘΡΩΠΟΙ',
    // ORIGINAL TEXT: NEGOTIATORS
    // ADDITION
    DOCUMENTDETAILS: 'ΣΤΟΙΧΕΙΑ ΕΓΓΡΑΦΟΥ',
    // ORIGINAL TEXT: DOCUMENT DETAILS
    CREATIONDATE: 'ΗΜΕΡΟΜΗΝΙΑ ΔΗΜΙΟΥΡΓΙΑΣ',
    // ORIGINAL TEXT: CREATION DATE
    PARTICIPANTS: 'ΣΥΜΜΕΤΕΧΟΝΤΕΣ',
    // ORIGINAL TEXT: PARTICIPANTS
    COLLABORATIONANDNEGOTIATION: 'ΣΥΝΑΦΕΙΑ ΚΑΙ ΑΝΑΓΚΗ',
    // ORIGINAL TEXT: COLLABORATION AND NEGOTIATION
    DOCTYPEANDGROUPACCESS: 'ΤΥΠΟΣ ΕΓΓΡΑΦΟΥ ΚΑΙ ΠΡΟΣΒΑΣΗ ΤΗΣ ΟΜΑΔΑΣ',
    // ORIGINAL TEXT: DOCUMENT TYPE AND GROUP ACCESS
    GROUPOWNERS: 'Ιδιοκτήτες του ομίλου',
    // ORIGINAL TEXT: GROUP OWNERS
    GROUPCOLLABORATORS: 'ΣΥΝΑΦΕΙΣ ΟΜΑΔΕΣ',
    // ORIGINAL TEXT: GROUP COLLABORATORS
    DOCUMENTSETTINGS: 'ΚΑΘΟΡΙΣΜΟΣ ΕΓΓΡΑΦΩΝ',
    // ORIGINAL TEXT: DOCUMENT SETTINGS
    ENABLECOLLABTOMODLABELS:
      'Ενεργοποίηση συνεργατών για την τροποποίηση ετικετών ρήτρας',
    // ORIGINAL TEXT: Enable Collaborators to Modify Clause Labels
    ENABLENEGOTCAPABILITIES:
      'Ενεργοποίηση δυνατοτήτων διαπραγμάτευσης (Δεν είναι τρόπος διαπραγμάτευσης)',
    // ORIGINAL TEXT: Enable Negotiation capabilities (Not Negotiation mode)
    KEEPDOCNOTES: 'Αντιγραφή σημειώσεων εγγράφων με λειτουργία αντιγραφής',
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS:
      'Αναθεωρημένα και Τροποποιημένα Θέματα Ενότητας ως Απαραίτητα',
    // ORIGINAL TEXT: Reviewed and Amended Clause Topics as Necessary
    NOEXECUTIONDATE: 'Καμία ημερομηνία εκτέλεσης',
    // ORIGINAL TEXT: No Execution Date
    EXECUTIONDATE: 'Ημερομηνία εκτέλεσης',
    // ORIGINAL TEXT: Execution Date
    CONTRACTSTARTDATE: 'Ημερομηνία έναρξης σύμβασης',
    // ORIGINAL TEXT: Contract Start Date
    CONTRACTENDDATE: 'Ημερομηνία λήξης της σύμβασης',
    // ORIGINAL TEXT: Contract End Date
    EXECCONTRACTNAME: 'Εκτελεσμένο όνομα αρχείου σύμβασης',
    // ORIGINAL TEXT: Executed Contract File Name
    SELECTEXECCONTRACT:
      'Επιλέξτε αρχείο για την αποστολή της εκτελεσθείσας σύμβασης',
    // ORIGINAL TEXT: select file to upload executed contract
    UPLOADEXECUTEDCONTRACT: 'ULLOAD executed contract',
    // ORIGINAL TEXT: UPLOAD EXECUTED CONTRACT
    LOADDEFAULTS: 'ΖΗΜΙΕΣ',
    // ORIGINAL TEXT: LOAD DEFAULTS
    CLOSED: 'ΚΛΕΙΣΤΟ',
    // ORIGINAL TEXT: CLOSED
    NONE: 'ΝΟΟΥ',
    // ORIGINAL TEXT: NONE
    NA: 'NA',
    // ORIGINAL TEXT: NA
    RESULTEXECUTEDCONTRACT: 'Αποτέλεσμα της Εκτελεσθείσας Σύμβασης',
    // ORIGINAL TEXT: Resulted in Executed Contract
    NOSTARTDATE: 'Καμία ημερομηνία έναρξης',
    // ORIGINAL TEXT: No Start Date
    NOENDDATE: 'Χωρίς ημερομηνία λήξης',
    // ORIGINAL TEXT: No End Date
    EXCLUDECOLLABORATORSFROMACCESS:
      'Αποκλείστε τους εξωτερικούς συνεργάτες από την πρόσβαση στο έγγραφο',
    // ORIGINAL TEXT: Exclude External Collaborators From Accessing Document
    EXCLUDENEGOTIATORSFROMACCESS:
      'Αποκλείστε τους εξωτερικούς διαπραγματευτές από την πρόσβαση στο έγγραφο',
    // ORIGINAL TEXT: Exclude External Negotiators From Accessing Document
    REVIEWEDAMENDEDCLAUSELABELS:
      'Αναθεωρημένες και τροποποιημένες ετικέτες ρήτρας ως Απαραίτητες',
    // ORIGINAL TEXT: Reviewed and Amended Clause Labels as Necessary
    ENTERPARAGRAPHBETWEEN: 'Εισάγετε αριθμό παραγράφου μεταξύ:',
    // ORIGINAL TEXT: Enter a paragraph number between:
    GOTOPARAGRAPHINDEX: 'Πηγαίνετε στο ευρετήριο παραγράφων',
    // ORIGINAL TEXT: Go to Paragraph Index
    INPUTDOCUMENTNOTEHERE: 'Σημείωση εγγράφου εισόδου εδώ',
    // ORIGINAL TEXT: Input Document Note here
    INPUTCOMMENTHERE: 'Σχόλιο εισόδου εδώ',
    // ORIGINAL TEXT: Input Comment here
    SHOWPENDINGONLY: 'Εμφάνιση μόνο εν αναμονή',
    // ORIGINAL TEXT: Show Pending Only
    COLLABORATIONSETTING: 'ΚΑΘΟΡΙΣΜΟΣ ΣΥΝΑΦΕΙΑΣ',
    // ORIGINAL TEXT: COLLABORATION SETTING
    NEGOTIATIONSETTING: 'ΠΡΟΣΔΙΟΡΙΣΜΟΣ ΑΝΑΓΚΗΣ',
    // ORIGINAL TEXT: NEGOTIATION SETTING
    NONEGOTWHENAPPROVED:
      'Αδύνατη η ενεργοποίηση των διαπραγματεύσεων όταν πρόκειται για εγκεκριμένο κράτος',
    // ORIGINAL TEXT: Cannot turn on Negotiation when in Approved State
    EMAILTOADD: 'Email για προσθήκη',
    // ORIGINAL TEXT: Email to add
    CLAUSETOADD: 'Ενότητα προσθήκης',
    // ORIGINAL TEXT: Clause to add
    TAGTOADD: 'Ετικέτα προσθήκης',
    // ORIGINAL TEXT: Tag to add
    OPTIONSFORREDLINEINCLUDE: 'Επιλογές για Redlines για να συμπεριληφθεί',
    // ORIGINAL TEXT: Options for Redlines to Include
    ONLYINCLUDEOWNERAPPROVEDEDITS:
      'Συμπεριλάβετε μόνο επεξεργασία εγγράφων με έγκριση από τον ιδιοκτήτη',
    // ORIGINAL TEXT: Only Include Owner-approved Document Edits
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'Συμπεριλάβετε μόνο επεξεργαστές εγγράφων με έγκριση ιδιοκτήτη και διαπραγματευτή',
    // ORIGINAL TEXT: Only Include Owner and Negotiator-approved Document Edits
    INCLUDEALLDOCEDITS:
      'Συμπεριλάβετε όλες τις επεξεργαστές εγγράφων (ανεξαρτήτως έγκρισης)',
    // ORIGINAL TEXT: Include All Document Edits (regardless of approval)
    REDLINECHANGESSELECTED: 'Επιλεγμένες Λεξογραφημένες Αλλαγές',
    // ORIGINAL TEXT: Redlined Changes Selected
    TOTALCHANGES: 'Σύνολο αλλαγών',
    // ORIGINAL TEXT: Total Changes
    REPLACEREDLINES: 'Αντικατάσταση κόκκινων γραμμών',
    // ORIGINAL TEXT: Replace Redlines
    DELETEREDLINES: 'Διαγραφή κόκκινων γραμμών',
    // ORIGINAL TEXT: Delete Redlines
    ADDAFTERREDLINES: 'Add-After Redlines',
    // ORIGINAL TEXT: Add-After Redlines
    OPTIONSFORPREVREDLINES: 'Επιλογές για προηγούμενες Redlines',
    // ORIGINAL TEXT: Options for Previous Redlines
    OVERWRITEPREVREDLINES:
      'Αντικατάσταση όλων των προηγούμενων κόκκινων γραμμών στην πηγή εγγράφου',
    // ORIGINAL TEXT: Overwrite all Previous Redlines in Document Source
    KEEPPREVREDLINES:
      'Κρατήστε όλες τις προηγούμενες κόκκινες γραμμές στην πηγή εγγράφων',
    // ORIGINAL TEXT: Keep all Previous Redlines in Document Source
    GENERATEFINALDOC: 'Δημιουργία τελικού εγγράφου (χωρίς κόκκινες γραμμές)',
    // ORIGINAL TEXT: Generate Final Document (without Redlines)
    DEFAULTESIGNOTE:
      'Όπως συζητήθηκε, παρακαλούμε να υπογράψετε το εν λόγω συμβόλαιο.',
    // ORIGINAL TEXT: As discussed, please provide your eSignature to this contract.
    SUBMITTEDSIGMESSAGE: 'Υποβλήθηκε για υπογραφές.',
    // ORIGINAL TEXT: Submitted for signatures.  Please wait.
    UPLOADEDMESSAGE: 'Μεταφορτώθηκε',
    // ORIGINAL TEXT: Uploaded
    ADDADDLFILES:
      'Προαιρετικό - Προσθήκη πρόσθετων αρχείων στο αίτημα υπογραφής',
    // ORIGINAL TEXT: Optional - Adding additional files to signature request
    ADDFILESMSG: 'προσθήκη πρόσθετων αρχείων για την υπογραφή',
    // ORIGINAL TEXT: add additional files for signature
    ESIGPREPAIDPLANS:
      'Η δυνατότητα eSignature είναι διαθέσιμη μόνο μέσω των προπληρωμένων προγραμμάτων μας.',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      'Επικοινωνήστε μαζί μας στη διεύθυνση billing@mylegaleinstein.com για περισσότερες λεπτομέρειες.',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: 'More videos at the ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'Ο τύπος εγγράφου δεν υποστηρίζεται. Το MLE δέχεται μόνο μορφές dox και pdf.',
    // ORIGINAL TEXT: Document type is not supported.  MLE only accepts docx and pdf formats.
    PDFSUPPORTEDFORPREMIUMONLY:
      'Για τη σάρωση PDF, ο χρήστης πρέπει να αναβαθμίσει τη συνδρομή Premium ή καλύτερα.',
    // ORIGINAL TEXT: For PDF scanning, user needs to upgrade to Premium subscription or better.
    BADDOCFORMAT: 'Κακή μορφή εγγράφου πέρασε στην επεξεργασία εγγράφων.',
    // ORIGINAL TEXT: Bad Document format was passed to document processing.
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      'Δεν έχετε το προνόμιο να κάνετε αλλαγές στις Ρυθμίσεις Εγγράφων.',
    // ORIGINAL TEXT: You do not have the privilege to make changes to the Document Settings.  You are required to be an Owner.
    PRIVILEGSNOTAVAIL:
      'Τα προνόμια αυτού του χρήστη δεν είναι διαθέσιμα. Προσπαθήστε ξανά αργότερα.',
    // ORIGINAL TEXT: The privileges for this user are not available.  Try again later.
    EXHAUSTEDTRIALLICENSE:
      'Έχετε εξαντλήσει τον αριθμό των επιτρεπόμενων εγγράφων κατά τη διάρκεια της δοκιμαστικής περιόδου σας. Παρακαλώ επιλέξτε "Επιστροφή" στο προφίλ σας.',
    // ORIGINAL TEXT: You have exhausted the number of allowed documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    EXHAUSTEDMONTHLYLIMIT:
      'Έχετε εξαντλήσει τον αριθμό των επιτρεπόμενων μηνιαία έγγραφα κατά τη διάρκεια της δοκιμαστικής περιόδου σας. Παρακαλώ επιλέξτε "Upgrade Συνδρομή" στο προφίλ σας.',
    // ORIGINAL TEXT: You have exhausted the number of allowed monthly documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    PASSWORDNOTMATCHING:
      'Οι κωδικοί πρόσβασης δεν ταιριάζουν. Ελέγξτε ξανά τους κωδικούς πρόσβασης.',
    // ORIGINAL TEXT: Passwords do not match.  Check the passwords again.
    ABAMEMBERIDLENGTH:
      'Οι ταυτότητες συνδρομής ABA είναι 7 χαρακτήρες μακρύ. Παρακαλώ ελέγξτε και υποβάλετε.',
    // ORIGINAL TEXT: ABA Membership IDs are 7 characters long.  Please review and submit.
    TERMSNOTCHECKED:
      'Οι Όροι και Προϋποθέσεις δεν έχουν αναθεωρηθεί και ελεγχθεί.',
    // ORIGINAL TEXT: The Terms and Conditions have not been reviewed and checked.
    EMAILFORMATBAD:
      'Η μορφή ηλεκτρονικού ταχυδρομείου δεν είναι έγκυρη. Παρακαλώ δείτε τη διεύθυνση ηλεκτρονικού ταχυδρομείου.',
    // ORIGINAL TEXT: Email format is not valid.  Please review email address.
    CANCELEDACTION:
      'Η τελευταία σας ενέργεια ακυρώθηκε. Η εφαρμογή θα ξαναγεμίσει.',
    // ORIGINAL TEXT: Your last action have been canceled.  Application will now reload.
    SUCCESSACTION:
      'Η τελευταία σας ενέργεια ήταν επιτυχής. Η εφαρμογή θα ξαναγεμίσει.',
    // ORIGINAL TEXT: Your last action has been successful.  Application will now reload.
    USERSUCCESSLOGIN: 'Επιτυχής σύνδεση με την',
    // ORIGINAL TEXT: Successfully logged on as
    USERLOGGEDOUT: 'Ο χρήστης έχει συνδεθεί.',
    // ORIGINAL TEXT: The user has been logged out
    EMAILNOTVERIFIED: 'Το email λογαριασμού δεν έχει ακόμη επαληθευτεί.',
    // ORIGINAL TEXT: Account email has not yet been verified.  An email verification has been sent to you.
    EMAILALREADYREGISTERED: 'Αυτό το email έχει ήδη εγγεγραμμένο λογαριασμό.',
    // ORIGINAL TEXT: This email already has a previously registered account.
    EMAILNOTVERIFIEDRESENT:
      'Το email σας δεν έχει επαληθευτεί. Σας έχουμε δυσανασχετήσει με ένα σύνδεσμο επαλήθευσης ηλεκτρονικού ταχυδρομείου στο email σας για να ενεργοποιήσετε το νέο λογαριασμό.',
    // ORIGINAL TEXT: Your email has not been verified.  We have resent you an email verification link to your email to activate the new account.
    USERLOGFILENOTEXISTS: 'Σφάλμα - δεν υπάρχει αρχείο χρήστη καταγραφής για:',
    // ORIGINAL TEXT: Error - no log user file exists for:
    PASSWORDRESETSENT:
      'Ένα μήνυμα επαναφοράς κωδικού πρόσβασης ηλεκτρονικού ταχυδρομείου έχει σταλεί για το λογαριασμό σας.',
    // ORIGINAL TEXT: An email password reset message has been sent for your account.
    PASSWORDCHANGED: 'Ο κωδικός σου έχει αλλαχτεί.',
    // ORIGINAL TEXT: Your password has been changed.
    REGISTRATIONDELETED: 'διαγραμμένη εγγραφή χρήστη',
    // ORIGINAL TEXT: deleted user registration
    USERACCOUNTUPDATED:
      'Έχετε ενημερώσει τις ρυθμίσεις του λογαριασμού χρήστη σας. Οι αλλαγές θα τεθούν σε ισχύ την επόμενη φορά που θα συνδεθείτε.',
    // ORIGINAL TEXT: You have updated your user account settings.  Changes will take into effect the next time you login.
    UPGRADEDTOREFERRED:
      'Ο λογαριασμός σας έχει ενημερωθεί για την αναφερόμενη δοκιμαστική συνδρομή.',
    // ORIGINAL TEXT: Your account has been updated to Referred Trial subscription.
    REGISTEREDTRIALUSER:
      'Νέος λογαριασμός είναι εγγεγραμμένος ως δοκιμαστική συνδρομή.',
    // ORIGINAL TEXT: New account is registered as Trial subscription.
    DELETEDUSERACCOUNT: 'Διαγραφή λογαριασμού προφίλ χρήστη',
    // ORIGINAL TEXT: Deleted user profile account
    EMAILALREADYREGISTEREDRESENT:
      'Το email δεν έχει ήδη εγγραφεί στο Docgility.',
    // ORIGINAL TEXT: Email has not already been registered with Docgility.  An email invitiation has been sent to
    UPGRADEEXTERNALPAYMENTS:
      'Ευχαριστώ για την αναβάθμιση. Θα επαναδρομολογηθείτε σε μια εξωτερική υπηρεσία πληρωμών και μετά την ολοκλήρωση της συναλλαγής σας, θα πρέπει να συνδεθείτε ξανά για την υπηρεσία συνδρομής για να ξεκινήσετε.',
    // ORIGINAL TEXT: Thanks for upgrading.  You will be rerouted to an external payment service and after completing your transaction, you will need to login again for subscription service to start.
    BILLINGEXTERNALPAYMENTS:
      'Επιβεβαιώστε ότι θα θέλατε να κατευθύνεστε στην εξωτερική υπηρεσία πληρωμών και χρέωσης μας. Αφού κάνετε οποιεσδήποτε αλλαγές, θα πρέπει να συνδεθείτε ξανά για αλλαγές που θα αρχίσουν να ισχύουν.',
    // ORIGINAL TEXT: Please confirm that you will like to be directed to our external payment and billing service.  After you make any changes, you will need to login again for changes to take effect.
    NOTABLETOLOGINATTHISTIME:
      'Δεν είναι σε θέση να συνδεθείτε αυτή τη στιγμή. Προσπαθήστε ξανά αργότερα.',
    // ORIGINAL TEXT: Not able to login at this time.  Try again later.
    NOTREGISTERED:
      'Ο χρήστης δεν έχει εγγραφεί προηγουμένως. Εγγραφή πριν από την προσπάθεια σύνδεσης.',
    // ORIGINAL TEXT: User has not previously registered.  Register before trying to login.
    NAMETOOLONG: 'Το όνομα που εισάγατε είναι πολύ μεγάλο.',
    // ORIGINAL TEXT: The name you entered is too long.  Max is 60 characters.
    COMPANYTOOLONG:
      'Η εταιρεία που μπήκες είναι πολύ μεγάλη, ο Μαξ είναι 60 χαρακτήρες.',
    // ORIGINAL TEXT: The company you entered is too long.  Max is 60 characters.
    COMMENTTOOLONG: 'Τα σχόλια που εισάγατε είναι πολύ μεγάλα.',
    // ORIGINAL TEXT: The comments you entered is too long.  Max is 10000 characters.
    DOCEDITTOOLONG:
      'Η επεξεργασία του εγγράφου που εισάγατε είναι πολύ μεγάλη.',
    // ORIGINAL TEXT: The document edits you entered is too long.  Max is 10000 characters.
    TITLETOOLONG: 'Ο τίτλος που εισάγατε είναι πολύ μακρύς.',
    // ORIGINAL TEXT: The title you entered is too long.  Max is 60 characters.
    PASSWORDTOOSHORT:
      'Ο κωδικός πρόσβασης που εισάγατε είναι πολύ σύντομος. Ο κωδικός πρόσβασης πρέπει να είναι τουλάχιστον 6 χαρακτήρες.',
    // ORIGINAL TEXT: The password you entered is too short.  Password has to be at least 6 characters.
    CONGRATSNEWDOCUMENTPROCESSING:
      'Τέλεια! Έχετε επεξεργαστεί ένα νέο έγγραφο. Χρειάζεται λίγα λεπτά και ο πίνακας θα πρέπει να ανανεώσει όταν το έγγραφο έχει ολοκληρωθεί η επεξεργασία. Παρακαλώ περιμένετε λίγα λεπτά.',
    // ORIGINAL TEXT: Great! You have processed a new document.  It takes a few minutes and the panel should refresh when the document is completed processing.  Please wait a few minutes.
    NODOCUMENTLOADED: 'Δεν φορτώθηκε έγγραφο',
    // ORIGINAL TEXT: No document loaded
    SELECTFILETOUPLOADDOCX: 'επιλογή αρχείου για αποστολή (*.docx)',
    // ORIGINAL TEXT: select file to upload (*.docx)
    SELECTFILETOUPLOADDOCXPDF:
      'επιλέξτε το αρχείο για να ανεβάσετε (*.docx, *.pdf)',
    // ORIGINAL TEXT: select file to upload (*.docx, *.pdf)
    INPUTCOMMENTHERE: 'Είσοδος σχόλιο εδώ',
    // ORIGINAL TEXT: Input comment here
    AUTOAPPROVEASOWNER: 'Αυτόματη έγκριση ως ιδιοκτήτη',
    // ORIGINAL TEXT: Auto Approve as Owner
    INPUTDOCEDITSHERE: 'Επεξεργασία εγγράφου εισόδου εδώ',
    // ORIGINAL TEXT: Input Document Edit here
    TRIGGEREDALLALERTS:
      'Εναλλαγή όλων των (αν υπάρχουν) ειδοποιήσεων για το έγγραφο αυτό.',
    // ORIGINAL TEXT: Triggered all (if any) Alerts for this doctype document.
    QSINTROMESSAGE:
      'Το MyNomeEinstein αναλύει αμέσως τα συμβόλαιά σας, τα ευρετήρια ανά νομικά θέματα, και επιτρέπει παράλληλα συγκρίσεις της νομικής γλώσσας σε μια πλήρη πλατφόρμα συνεργασίας συμβάσεων.',
    // ORIGINAL TEXT: MyLegalEinstein instantly analyzes your contracts, indexes by legal topics, and allows side-by-side comparisons of legal language in a complete contract collaboration platform. We will demonstrate the capabilities in four simple steps (detailed instructions are provided in english but similar in all languages.)
    QSDOCMESSAGE:
      'Για την ευκολία σας, εδώ είναι μερικές δημόσιες συμβάσεις τομέα που μπορείτε να κατεβάσετε και να δοκιμάσετε:',
    // ORIGINAL TEXT: For your convenience, here are some public domain contracts that you can download and try:
    QSHELPMESSAGE:
      'Ιδού μια εύχρηστη σύνδεση με την τεκμηρίωση του προϊόντος (με wordy έκδοση του Quick Start)',
    // ORIGINAL TEXT: Heres a handy link to product documentation (with wordy version of Quick Start)
    DOCUMENTFAILEDTOPROCESS:
      'Ένα μοιραίο σφάλμα συνέβη κατά την επεξεργασία του εγγράφου. Παρακαλώ δοκιμάστε ξανά. Εάν το πρόβλημα επιμείνει, παρακαλούμε επικοινωνήστε με την υποστήριξη πελατών στο support@mylegaleinstein.com.',
    // ORIGINAL TEXT: A fatal error occurred while processing the document.  Please try again.  If problem persists, please contact customer support at support@mylegaleinstein.com.

    // ADDED
    DOCUMENTSUCCESSCOPIED: 'Το έγγραφό σας έχει πλέον αντιγραφεί.',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      'Έχει δημιουργηθεί και αποθηκευτεί μια καθαρή έκδοση αυτού του εγγράφου.',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      'A document was generated from this template and saved.',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE:
      'A document has been converted into a document template.',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      'Επιβεβαιώστε ότι θέλετε να διαγράψετε το καθορισμένο έγγραφο. Αφού διαγραφεί το έγγραφο, δεν θα είναι πλέον διαθέσιμο. Μπορείτε επίσης να σκεφτείτε τη μετακίνηση του εγγράφου στο Αρχείο.\n\n Παρακαλώ επιβεβαιώστε τη διαγραφή λειτουργίας.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified document.  After document is deleted, it will no longer be available.  You may also consider moving the document to Archive status.  \n\n Please confirm delete operation.
    CONFIRMSAVETOPLAYBOOK:
      'Παρακαλώ επιβεβαιώστε ότι για να μετατρέψετε αυτό το έγγραφο σε ένα playbook. Αυτό θα κάνει αυτό το έγγραφο ακυρώσιμο εκτός από τον ιδιοκτήτη.\n\n Παρακαλώ επιβεβαιώστε την αποθήκευση σε λειτουργία playbook.',
    // ORIGINAL TEXT: Please confirm that to convert this document to a playbook.  This would make this document unchangable except for the owner.  \n\n Please confirm the save to playbook operation.
    COLLABORATIONNOTE:
      'ΣΗΜΕΙΩΣΗ: Αυτές οι πληροφορίες μοιράζονται εσωτερικά μόνο με καθορισμένους συνεργάτες και ιδιοκτήτες εγγράφων μόνο.',
    // ORIGINAL TEXT: NOTE: This information is shared INTERNALLY ONLY with designated collaborators and document owners only.
    NEGOTIATIONNOTE:
      'ΣΗΜΕΙΩΣΗ: Αυτές οι πληροφορίες μοιράζονται ΕΞΩΤΕΡΙΚΑ με τα συμβαλλόμενα μέρη των διαπραγματεύσεων.',
    // ORIGINAL TEXT: NOTE: This information is shared EXTERNALLY with negotiation counter parties.
    CONFIRMDELETEALERT:
      'Επιβεβαιώστε ότι θέλετε να διαγράψετε την καθορισμένη ειδοποίηση. Αφού διαγραφεί η ειδοποίηση, δεν θα είναι πλέον διαθέσιμη. Παρακαλώ επιβεβαιώστε τη λειτουργία διαγραφής.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified alert.  After alert is deleted, it will no longer be available.  Please confirm delete operation.
    CONFIRMDELETERECIPIENTALERT:
      'Επιβεβαιώστε ότι δεν θέλετε πλέον να είστε αποδέκτης της καθορισμένης ειδοποίησης. Αφού αφαιρέσετε τον εαυτό σας ως παραλήπτη, μπορείτε να ζητήσετε να επιστρέψετε επικοινωνώντας με τον ιδιοκτήτη της ειδοποίησης. Παρακαλώ επιβεβαιώστε τη διαγραφή της πράξης.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.
    CONFIRMDELETEGROUP:
      'Παρακαλώ επιβεβαιώστε ότι θέλετε να διαγράψετε την καθορισμένη ομάδα. Αφού διαγραφεί η ομάδα, δεν θα είναι πλέον διαθέσιμη. Ωστόσο, θα αντανακλά την επόμενη φορά που θα συνδεθείτε ξανά. Παρακαλώ επιβεβαιώστε τη διαγραφή λειτουργίας.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified group.  After group is deleted, it will no longer be available.  However, it will reflected the next time you log back in.  Please confirm delete operation.
    CONFIRMDELETECOLLABORATORGROUP:
      'Επιβεβαιώστε ότι δεν θέλετε πλέον να είστε συνεργάτης της συγκεκριμένης ομάδας. Μετά την αφαίρεση του εαυτού σας ως συνεργάτη, μπορείτε να ζητήσετε να προστεθεί πίσω επικοινωνώντας με τον ιδιοκτήτη της ομάδας. Παρακαλώ επιβεβαιώστε τη διαγραφή λειτουργίας.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a collaborator for the specified group.  After removing yourself as a collaborator, you can request to be added back by contacting the owner of the group.  Please confirm delete operation.
    CONFIRMDELETEDOCTYPE:
      'Επιβεβαιώστε ότι θέλετε να διαγράψετε το συγκεκριμένο doctype. Αφού διαγραφεί το doctype, δεν θα είναι πλέον διαθέσιμο. Παρακαλώ επιβεβαιώστε τη λειτουργία διαγραφής.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified doctype.  After doctype is deleted, it will no longer be available.  Please confirm delete operation.
    EXPIREDTRIAL:
      'Η δοκιμαστική σας άδεια έχει λήξει. Παρακαλώ αναβαθμιστείτε για να συνεχίσετε τις υπηρεσίες σας.',
    // ORIGINAL TEXT: Your trial license has expired.  Please upgrade to continue your service.
    EXPIREDSUBSCRIPTION:
      'Η άδεια συνδρομής σας έχει λήξει. Παρακαλώ αναβαθμίστε για να συνεχίσετε την υπηρεσία σας ή να επικοινωνήσετε με την χρέωση στο Docgility.',
    // ORIGINAL TEXT: Your subscription license has expired.  Please upgrade to continue your service or contact billing at Docgility.
    SOURCEDOCNOTDOCX:
      'Το έγγραφο Πηγής δεν είναι έγγραφο DOCX. Οι δυνατότητες Redline δεν είναι διαθέσιμες.',
    // ORIGINAL TEXT: The Source document is not a DOCX document.  Redline capabilities are not available.
  },
}

export default EL
