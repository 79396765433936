<script>
import {
  dbdocmetaComputed,
  dbauthComputed,
  panelComputed,
} from '@state/helpers'

import { displayUserName, displayUserInfo, docroute } from '@utils/dialogutils'
import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },

    // FYI - Need to pass each field in individually
    externalcollaborators: {
      type: Array,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
  },
  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  data: function() {
    return {
      email: '', // for adding a new person.
      valid: false, // for validating if email is valid

      // validation rules for email
      emailrules: [
        (v) => !!v || this.$t('VALIDMSGS.EMAILREQUIRED'),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t('VALIDMSGS.EMAILVALID'),
        (v) => v.length <= 60 || this.$t('VALIDMSGS.MAX60CHARS'),
        (v) =>
          this.localpanelstate.docmeta.ownerid.indexOf(v.toLowerCase()) < 0 ||
          this.$t('VALIDMSGS.EMAILALREADYOWNER'),
        (v) =>
          this.localpanelstate.docmeta.externalcollaborators.indexOf(
            v.toLowerCase()
          ) < 0 || this.$t('VALIDMSGS.EMAILALREADYCOLLABORATOR'),
        (v) =>
          this.localpanelstate.docmeta.externalnegotiators.indexOf(
            v.toLowerCase()
          ) < 0 || this.$t('VALIDMSGS.EMAILALREADYNEGOTIATOR'),
        // (v) => false || 'false',
        (v) =>
          !(
            this.localpanelstate.ifDocType &&
            this.localpanelstate.docTypeofDoc.ownerid.indexOf(
              v.toLowerCase()
            ) >= 0
          ) || this.$t('VALIDMSGS.EMAILALREADYOWNERDOCTYPE'),
        (v) =>
          !(
            this.localpanelstate.ifDocType &&
            this.localpanelstate.docTypeofDoc.uniqid.indexOf(v.toLowerCase()) >=
              0
          ) || this.$t('VALIDMSGS.EMAILALREADYCOLLABORATORDOCTYPE'),
      ],
    }
  },

  computed: {
    ...panelComputed,
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
        this.email = ''
      },
    },
  },
  methods: {
    ...dbdocmetaComputed,

    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },

    addCollaborator() {
      if (this.valid) {
        if (!this.localpanelstate.ifHUBType){
          this.$store.dispatch(
            'dbuserinfo/addEmailReferralIfNotRegisteredUserInfo',
            {
              referralid: this.email.toLowerCase(),
              uniqid: this.dbauthuser.uniqid,
              name: this.displayUserName(this.dbauthuser.uniqid),
              docid: this.aPIDjobID[this.panelno],
              docname: this.localpanelstate.docmeta.docname,
              docdescription: this.localpanelstate.docmeta.docdescription,
              privilege: 'collaborator',
            }
          )
        }
        this.addCollaboratorDocMeta({
          uniqid: this.dbauthuser.uniqid,
          docid: this.aPIDjobID[this.panelno],
          email: this.email.toLowerCase(),
          ifHUBType: this.localpanelstate.ifHUBType,
        })
      }
    },

    deleteCollaborator(email) {
      this.deleteCollaboratorDocMeta({
        uniqid: this.dbauthuser.uniqid,
        docid: this.aPIDjobID[this.panelno],
        email: email,
        ifHUBType: this.localpanelstate.ifHUBType,
      })
    },
    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.ADDCOLLABORATOR')"
        ></PanelCardTitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-simple-table :class="$style.dialogTable" fixed-header>
                <template>
                  <thead :class="$style.dialogHead">
                    <th
                      style="font-size:16px"
                      :class="$style.dialogCellStatus"
                      >{{ $t('DIALOGS.COLLABORATORS') }}</th
                    >
                    <th
                      style="font-size:16px"
                      :class="$style.dialogCellStatus"
                      >{{ $t('DIALOGS.ACTIONS') }}</th
                    >
                  </thead>
                  <tbody :class="$style.dialogBody">
                    <td :class="$style.dialogTags">
                      <v-list>
                        <v-list-item
                          v-for="item in externalcollaborators"
                          :key="item"
                        >
                          <v-list-item-title
                            ><v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{
                                  displayUserName(item)
                                }}</span>
                              </template>
                              <span>{{ displayUserInfo(item) }}</span>
                            </v-tooltip>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </td>

                    <td :class="$style.dialogCellStatus">
                      <v-row class="my-2">
                        <v-btn
                          small
                          class="white--text black"
                          @click="addCollaborator"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>
                      <v-row class="my-2">
                        <v-menu
                          v-if="externalcollaborators.length > 0"
                          offset-x
                        >
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                              small
                              class="white--text black"
                              v-on="{ ...tooltip }"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="item2 in externalcollaborators"
                              :key="item2"
                              @click="deleteCollaborator(item2)"
                            >
                              <v-list-item-title>{{ item2 }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-row>
                    </td>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-col cols="10" sm="10">
          <v-form v-model="valid">
            <v-text-field
              v-model="email"
              class="mx-4"
              :rules="emailrules"
              :label="$t('DIALOGS.EMAILTOADD')"
            ></v-text-field>
          </v-form>
        </v-col>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('act/addparticipant')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer
          ><v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
