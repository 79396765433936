// Contains all application constants //

export const JA = {
  TOPMENU: {
    FILE: 'ファイル',
    EDIT: '編集',
    ACT: '操作',
    DOCS: '文書',
  },

  MENU: {
    NEWDOCUMENT: '新しいドキュメント',
    SAVEDOCUMENT: 'ドキュメントを保存',
    COPYDOCUMENT: 'ドキュメントをコピー',
    CREATEVERSION: 'クリーンバージョンを作成する',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: 'テンプレートからドキュメントを作成する',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: 'ドキュメントを開く',
    OPENGROUPDOCUMENT: 'グループドキュメントを開く',
    OPENHUBDOCUMENT: 'ドキュHUBメントを開く',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: 'アーカイブドキュメントを開く',
    OPENPLAYBOOK: 'プレイブックを開く',
    OPENTEMPLATE: 'テンプレートを開く',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: 'テンプレートとして保存する',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: 'プレイブックとして保存',
    MOVETOARCHIVE: 'アーカイブに移動',
    CLOSEDOCUMENT: 'ドキュメントを閉じる',
    DOCUMENTLOG: 'ドキュメントログ',

    GOTOPARAGRAPH: '段落に移動',
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'ドキュメントノート',
    COLLABCOMMENTS: '共同編集者 - コメント',
    COLLABDOCEDITS: '共同編集者 - 変更内容',
    COLLABRISKANDLABELS: '共同編集者 - リスクとラベル',
    NEGOTCOMMENTS: '交渉相手 - コメント',
    NEGOTDOCEDITS: '交渉相手 - 変更内容',

    COLLABORATION: '共同編集',
    NEGOTIATION: '交渉',
    ADDOWNER: '所有者を追加',
    ADDCOLLABORATOR: '共同編集者を追加',
    ADDNEGOTIATOR: '交渉相手を追加',
    TRIGGERALERTS: 'アラートを送信',
    EXPORTEXCEL: 'Excelのエクスポート',
    DOWNLOADSOURCE: 'ソースのダウンロード',
    SENDTOCLM: 'CLMに送信',
    EXPORTJSON: 'JSONにエクスポート',

    ESIGNATURE: '電子署名のために送信',
    DOWNLOADREDLINE: '変更されたドキュメントをダウンロード',
    DOCUMENTSETTINGS: 'ドキュメント設定',
    EXPAND: '開く',
    COLLAPSE: '閉じる',
    TRANSLATETOENGLISH: '英語に翻訳',
    ORIGINALLANGUAGE: 'オリジナルに戻す',

    ALLPARAGRAPHS: 'すべての段落',
    EDITEDPARAGRAPHSONLY: '編集された段落のみ',

    NOEDITCLAUSELABELS: '条項ラベルの編集不可',
    EDITCLAUSELABELS: '条項ラベルの編集',

    HIDEEDITS: '編集を非表示',
    SHOWEDITS: '編集を表示',

    SHOWALLEDITS: 'すべての編集を表示',
    SHOWNEGOTEDITSONLY: '交渉相手の編集のみを表示',

    GROUPS: 'グループ',
    DOCUMENTTYPES: 'ドキュメントタイプ',
    ALERTS: 'アラート',
    QUICKSTARTTUTORIAL: 'クイックスタートチュートリアル',
    QUICKSTARTVIDEOS: 'クイックスタートビデオ',
    UPGRADESUBSCRIPTION: 'サブスクリプションのアップグレード',
    SUBSCRIPTIONBILLING: 'サブスクリプション/請求',
    ACCOUNTSETTINGS: 'アカウント設定',
    USERLOG: 'ユーザーログ',
    CHANGEPASSWORD: 'パスワードの変更',
    LOGOUT: 'ログアウト',

    // HEADINGS
    ADMIN: '管理',
    PROFILE: 'プロファイル',
    LPROFILE: 'プロファイル',
    FILE: 'ファイル',
    EDIT: '編集',
    ACT: 'アクション',
    TOPIC: 'トピック',
    DOCS: 'ドキュメント',

    // Field Headings in Navigation Bar
    NAME: '名前',
    ROLE: 'ロール',
    STATE: '状態',
    GROUP: 'グループ',
    TYPE: 'タイプ',
    SENSIT: '感度',
    LTOPIC: 'トピック',
    USER: 'ユーザー',

    // Other Headings
    REGISTER: '登録',
    LOGIN: 'ログイン',
    LREGISTER: '登録',
    LLOGIN: 'ログイン',

    ADMINEXPLAIN: '管理機能',
    PROFILEEXPLAIN: 'アカウント管理、サブスクリプション、請求',

    // AUTH LEVELS
    PREMIUM: 'プレミアム',
    PREMIUMPREPAID: 'プレミアム（プリペイド）',
    BASIC: 'ベーシック',
    BASICPREPAID: 'ベーシック（プリペイド)',
    VIPACCESS: '特別アクセス',
    VIPMEMBER: '特別会員',
    UNLIMITED: '無制限',
    TRIALUSER: 'トライアルユーザー',
    REFERREDUSER: '紹介ユーザー',
    SOURCEDOCUMENT: 'ソースドキュメント',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: '比較文書',
    // ORIGINAL TEXT: Comparison Document
    EXIT: '出口',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: '段落',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: 'ドキュメント比較モード',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: 'なし',
    PREAMBLE: '前文',
    DEFN: '定義',
    PAYMENT: '支払い',
    TAXES: '税金',
    REPWARR: '表明および保証',
    PERIOD: '期間',
    TERMINATION: '終了',
    CONFID: '守秘義務',
    INDEMNITY: '補償',
    LIABILITY: '債務',
    INTELPROP: '知的財産',
    SIGNATURE: '署名',
    OTHER: '他',
    DATASECURITY: 'データセキュリティ',
    ASSIGN: '契約譲渡',
    NOTICE: '通知',
    GOVERNLAW: '準拠法',
    DISPUTE: '紛争',
    ENTIREAGREE: '完全合意',
    SURVIVAL: '存続',
    RELATPART: '両当事者の関係',
    THIRDPARTY: '第三者',
    FORCEMAJEURE: '不可抗力',
    SEVERABILITY: '可分性',
    EXPORTCTRL: '輸出管理',
    INTRO: '頭書',
    INSURANCE: '保険',
    HEADING: '見出し',
    ALL: 'すべて',
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'Eメールは必須です',
    EMAILVALID: '有効なEメールが必要です',
    MAX20CHARS: '最大20文字',
    MAX60CHARS: '最大60文字',
    MAX80CHARS: '最大80文字',
    MAX160CHARS: '最大160文字',
    EMAILALREADYOWNER: '入力されたEメールは既にオーナーとして存在します',
    EMAILALREADYCOLLABORATOR:
      '入力されたEメールは既に共同編集者として存在します',
    EMAILALREADYNEGOTIATOR: '入力されたEメールは既に交渉相手として存在します',
    EMAILALREADYOWNERDOCTYPE:
      '入力されたEメールは既にドキュメントタイプのオーナーとして存在します',
    EMAILALREADYCOLLABORATORDOCTYPE:
      '入力されたEメールは既にドキュメントタイプの共同編集者として存在します',
    REQUIRED: '必須',
    CLAUSEFIELDREQUIRED: 'この条項は本操作に必要です',
    CLAUSENOTDEFAULT: 'デフォルトの条項リストから条項を追加できません',
    TAGFIELDREQUIRED: 'このタグは本操作に必要です',
    TAGNOTDEFAULT: 'デフォルトのタグリストからタグを追加できません',

    PARAINDEXREQUIRED: '段落インデックスが必要です',
    PARAINDEXINVALID: '段落インデックスが存在しません',
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'ドキュメントを保存',
    OPENDOCUMENT: 'ドキュメントを開く',
    CLOSEDOCUMENT: 'ドキュメントを閉じる',
    ADDNOTE: 'メモを追加',
    ADDCOLLABORATOR: '共同編集者を追加',
    DELETECOLLABORATOR: '共同編集者を削除',
    ADDNEGOTIATOR: '交渉相手を追加',
    DELETENEGOTIATOR: '交渉相手を削除',
    UPDATEARCHIVEDATA: 'アーカイブデータの更新',
    UPDATEDOCMETA: 'ドキュメントメタデータの更新',
    ADDOWNER: '所有者を追加',
    DELETEOWNER: '所有者を削除',
    CHANGECOLLABORATION: '共同編集設定を変更しました',
    CHANGENEGOTIATION: '交渉設定を変更しました',
    SAVEPLAYBOOK: 'プレイブックとして保存しました',
    SAVETEMPLATE: 'テンプレートとして保存',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: 'アーカイブとして保存しました',
    TRIGGERALERT: 'アラートを送信',
    TRIGGERALLALERT: 'すべてのアラートを送信',
    TRIGGERCUSTOMALERT: 'カスタムアラートを送信',
    ADDCOMMENT: 'コメントを追加',
    DELETECOMMENT: 'コメントを削除',
    ADDDOCEDIT: '変更内容を追加',
    ADDAPPROVEDDOCEDIT: '承認された変更内容を追加',
    APPROVEDDOCEDIT: '変更内容を承認',
    REJECTDOCEDIT: '変更内容を拒否',
    NEGOTIATORAPPROVEDDOCEDIT: '交渉相手が承認した変更内容',
    NEGOTIATORREJECTEDDOCEDIT: '交渉相手が拒否した変更内容',
    DELETEDDOCEDIT: '変更内容を削除',
    ATTACHCONTRACT: 'サインされた契約書を添付',
    ADDTAG: 'タグを追加',
    DELETETAG: 'タグを削除',
    NEWSIGNATUREREQUEST: '新しい署名がリクエストされました',
    CANCELSIGNATUREREQUEST: '署名リクエストがキャンセルされました',
    CREATEREDLINEREQUEST: '変更履歴付きの新しいドキュメントを作成',
    CREATEFINALDOCREQUEST: '署名用の新しい最終ドキュメントを作成',
    COPIEDDOCUMENT: 'ドキュメントからコピー',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: 'ドキュメントからクリーンバージョンを作成しました',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: 'テンプレートから作成されたドキュメント',
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'ドキュメントタイプを作成',
    DELETEDOCTYPE: 'ドキュメントタイプを削除',
    ADDOWNERTODOCTYPE: 'ドキュメントタイプにオーナーを追加しました',
    DELETEOWNERFROMDOCTYPE: 'ドキュメントタイプからオーナーを削除しました',
    ADDCLAUSETODOCTYPE: 'ドキュメントタイプに条項を追加しました',
    DELETECLAUSEDOCTYPE: 'ドキュメントタイプから条項を追加しました',
    ADDTAGDOCTYPE: 'ドキュメントタイプにタグを追加しました',
    DELETETAGDOCTYPE: 'ドキュメントタイプからタグを削除しました',
    ADDCOLLABRULEDOCTYPE: 'ドキュメントタイプに共同編集ルールを追加しました',
    DELETECOLLABRULEDOCTYPE:
      'ドキュメントタイプから共同編集ルールを削除しました',
    ADDNOTIFRULEDOCTYPE: 'ドキュメントタイプに通知ルールを追加しました',
    DELETENOTIFRULEDOCTYPE: 'ドキュメントタイプから通知ルールを削除しました',
    ADDNEGOTRULEDOCTYPE: 'ドキュメントタイプに交渉ルールを追加しました',
    DELETENEGOTRULEDOCTYPE: 'ドキュメントタイプから交渉ルールを削除しました',

    // GROUPS
    CREATEGROUP: 'グループを作成しました',
    DELETEGROUP: 'グループを削除しました',
    ADDOWNERGROUP: 'グループにオーナーを追加しました',
    DELETEOWNERGROUP: 'グループからオーナーを削除しました',
    ADDCOLLABGROUP: 'グループに共同編集者を追加しました',
    DELETECOLLABGROUP: 'グループから共同編集者を削除しました',
    ADDDOCTYPETOGROUP: 'グループにドキュメントタイプを追加しました',
    DELETEDOCTYPEGROUP: 'グループからドキュメントタイプを削除しました',

    // ALERTS
    CREATEALERT: 'アラートを作成しました',
    DELETEALERT: 'アラートを削除しました',
    ADDOWNERALERT: 'アラートにオーナーを追加しました',
    DELETEOWNERALERT: 'アラートからオーナーを削除しました',
    ADDDOCTYPEALERT: 'アラートにドキュメントタイプを追加しました',
    DELETEDOCTYPEALERT: 'アラートからドキュメントタイプを削除しました',
    ADDCLAUSETOPICALERT: 'アラートに条項トピックを追加しました',
    DELETECLAUSETOPICALERT: 'アラートから条項トピックを削除しました',
    ADDRECIPIENTALERT: 'アラートに受信者を追加しました',
    DELETERECIPIENTALERT: 'アラートから受信者を削除しました',
    ADDNOTIFRULEALERT: 'アラートに通知ルールを追加しました',
    DELETENOTIFRULEALERT: 'アラートから通知ルールを削除しました',
    SETSELECTIVITYALERT: 'アラートに選択性を設定しました',
  },

  USEREVENTS: {
    USERSIGNON: 'サインインしたユーザー',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT: 'パスワード再設定のリクエストが送信されました',
    CHANGEDPASSWORD: 'パスワードを変更しました',
    DELETEDREGISTRATION: 'ユーザー登録を削除しました',
    UPDATEDACCOUNTINFO: 'アカウント情報を更新しました',
    UPGRADEDACCOUNTTOREFERRAL:
      'アカウントを紹介アカウントにアップグレードしました',
    DELETEDUSERACCOUNT: 'ユーザーアカウントを削除しました',
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: 'ドキュメントアクション',
    COMMENTS: 'コメント',
    DOCEDITS: '変更内容',
    LABELSRISK: 'ラベルとリスク',
    TEXT: '文章',
    MLE_DESC: 'DOCGILITY',
    CLAUSE: '条項',

    // PRIVILEGE
    OWNER: 'オーナー',
    COLLABORATOR: '共同編集者',
    NEGOTIATOR: '交渉相手',
    OWNERGROUP: 'グループのオーナー',
    COLLABORATORGROUP: 'グループの共同編集者',

    // TITLES
    SAVEDOCUMENT: 'ドキュメントを保存',
    SAVEARCHIVE: 'アーカイブを保存',
    QUICKSTART: 'クイックスタートチュートリアル',
    TUTORIALVIDEOS: 'チュートリアルビデオ',

    MOVETOARCHIVEDOCUMENT: 'アーカイブドキュメントに移動',
    OPENDOCUMENT: 'ドキュメントを開く',
    COPYDOCUMENT: 'ドキュメントをコピー',
    CREATEVERSION: 'クリーンバージョンを作成する',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: 'テンプレートからドキュメントを作成する',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: 'グループドキュメントを開く',
    OPENHUBDOCUMENT: 'ドキュHUBメントを開く',
    OPENARCHIVEDOCUMENT: 'アーカイブドキュメントを開く',
    OPENPLAYBOOK: 'プレイブックを開く',
    OPENTEMPLATE: 'オープンテンプレート',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'テンプレートとして保存する',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: '共同編集',
    NEGOTIATION: '交渉',
    ADDOWNER: 'オーナーを追加',
    ADDNEGOTIATOR: '交渉相手を追加',
    ADDCOLLABORATOR: '共同編集者を追加',
    ERRORMESSAGE: 'エラーメッセージ',
    DOCUMENTINFO: 'ドキュメント情報',
    DOCUMENTLOGS: 'ドキュメントログ',
    USERLOGS: 'ユーザーログ',
    DOCUMENTNOTES: 'ドキュメントノート',
    TRIGGERALERTS: 'アラートを送信',
    ALERTS: 'アラート',
    DOCTYPES: 'ドキュメントタイプ',
    GROUPS: 'グループ',
    NOTE: 'ノート',
    GOTOPARAGRAPH: '段落に移動',
    ESIGNATURE: '電子署名',
    DOWNLOADREDLINE: '変更履歴付きのワードドキュメントをダウンロード',
    // ACTIONS
    ADD: '追加',
    BACK: '戻る',
    DELETE: '削除',
    SAVE: '保存する',
    MOVETOARCHIVE: 'アーカイブに移動',
    COPY: 'コピー',
    CREATE: '作成',
    // ORIGINAL TEXT: CREATE
    OPEN: '開く',
    CLOSE: '閉じる',
    UPDATE: 'アップデート',
    REGISTER: '登録',
    LOGIN: 'ログイン',
    LOGINSSO: 'ログイン SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: 'パスワードの再設定',
    CHANGEPASSWORD: 'パスワードの変更',
    CANCELACCOUNT: 'アカウントを削除',
    QUICKSTARTVIDEO: 'クイックスタートビデオを見る',

    CHARGEBASIC: 'ベーシックプランへアップグレード',
    CHARGEPREMIUM: 'プレミアムプランへアップグレード',
    CHARGECORPORATE: 'コーポレートプランへアップグレード',
    UPGRADESUBSCRIPTION: 'サブスクリプションをアップグレード',
    GO: '進む',
    PREVIEW_FEEDBACK: '変更を確認',
    ACTIONS: 'アクション',
    REFRESH: 'リフレッシュ',
    ADDNEWGROUP: '新しいグループを追加',
    ADDNEWDOCTYPE: '新しいドキュメントタイプを追加',
    ADDNEWALERT: '新しいアラートを追加',
    GROUPSLIST: 'グループリストを確認',

    DOWNLOADDOCUMENT: 'ドキュメントをダウンロード',
    DOCUMENTTITLE: 'ドキュメントタイトル',
    DOCUMENTSUBJECT: 'ドキュメントのテーマ',
    SIGNMESSAGE: 'サインのためのメッセージ',
    NAMEPRIMARYSIGNER: '名前：署名者',
    EMAILPRIMARYSIGNER: 'Eメール：署名者',
    NAMECOUNTERSIGNER: '名前：相手方の署名者',
    EMAILCOUNTERSIGNER: 'Eメール：相手方の署名者',
    SIGREQUESTED: '署名がリクエストされました',
    SIGREQUESTEDBY: '要求者',
    SIGREQUESTEDID: '署名ID',
    SIGREQUESTEDAT: '日時',
    SUBMITSIG: '署名のために送信',
    CANCELSIG: '署名リクエストをキャンセル',

    // DATA LABELS
    NAME: '名前',
    EMAIL: 'Eメール',
    STATUS: 'ステータス',
    LASTVIEWED: '最終閲覧時刻',
    LASTREVIEWED: '最終閲覧時刻',
    SIGNEDAT: '署名日時',
    OWNERAPPROVAL: 'オーナーの承認',
    OWNERACTION: 'オーナーのアクション',
    NEGOTIATORAPPROVAL: '交渉相手の承認',
    NEGOTIATORACTION: '交渉者のアクション',
    ARCHIVENOTES: 'ノートをアーカイブ',
    EXECUTEDCONTRACT: '締結済み',
    EXECUTEDDATE: '締結日',
    CONTRACTATTACHED: '契約書添付',
    STARTDATE: '開始日',
    ENDDATE: '終了日',
    EDIT: '編集',
    USER: 'ユーザー',
    TIME: '時間',
    CONFIRM: '確認',
    CONFIRMACTION: 'アクションを確認',
    CURRENT_LABEL: '現在のラベル',
    NEW_LABEL: '新しいラベル',
    description: '説明',
    TYPE: 'タイプ',
    SENTENCE: '文',
    NUMBER: '＃',
    PRIVILEGE: 'ロール',
    OWNERID: 'オーナーID',
    DOCNAME: 'ドキュメント名',
    DOCTYPE: 'ドキュメントタイプ',
    DOCDESCRIPTION: 'ドキュメントの説明',
    DOCNOTES: 'ドキュメントノート',
    DOCPARTY: '契約者',
    DOCCOUNTERPARTY: '相手方',
    DOCRAWFILENAME: 'ドキュメントファイル名',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'タグ',
    COLLABORATIONSTATUS: '共同編集ステータス',
    COLLABORATIONEND: '共同編集終了',
    NEGOTIATIONSTATUS: '交渉ステータス',
    NEGOTIATIONEND: '交渉終了',
    DOCID: 'ドキュメントID',
    PARAGRAPHID: '段落ID',
    ACTION: 'ログに記録されたアクション',
    DETAIL: 'アクション詳細',
    CONTEXT: '追加の文脈',
    GROUPNAME: 'グループ名',
    GROUPDESC: 'グループの説明',
    DOCTYPEDESC: 'ドキュメントタイプの説明',
    ADDLCLAUSETYPES: 'カスタム条項ラベル',
    ADDLTAGS: 'カスタムタグ',
    COLLABORATIONRULES: '共同編集ルール',
    NEGOTIATIONRULES: '交渉ルール',
    NOTIFICATIONRULES: '通知ルール',
    LOG: 'ログ',
    ALERTNAME: 'アラート名',
    ALERTDESC: 'アラートの説明',
    TOPICS: 'トピック',
    RECIPIENTS: '受信者',
    SELECTIVITY: '選択性',
    FIELDNAME: 'フィールド名',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: '順序',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: '例',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: 'デフォルト',
    // ORIGINAL TEXT: DEFAULT
    VALUE: '値',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: 'ドキュメント',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: 'プレイブック',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: 'テンプレート',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: 'アーカイブされたドキュメント',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: 'テンプレートから作成：',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: '共同編集',
    NEGSHORT: '交渉',
    OWNERS: 'オーナー',
    COLLABORATORS: '共同編集者',
    NEGOTIATORS: '交渉相手',

    // ADDITION
    DOCUMENTDETAILS: 'ドキュメントの詳細',
    CREATIONDATE: '作成日',
    PARTICIPANTS: '参加者',
    COLLABORATIONANDNEGOTIATION: '共同編集と交渉',
    DOCTYPEANDGROUPACCESS: 'ドキュメントタイプとグループアクセス',
    GROUPOWNERS: 'グループのオーナー',
    GROUPCOLLABORATORS: 'グループの共同編集者',
    DOCUMENTSETTINGS: 'ドキュメント設定',
    ENABLECOLLABTOMODLABELS: '共同編集者による条項ラベルの変更を有効にする',
    ENABLENEGOTCAPABILITIES: '交渉機能を有効にする（交渉モードはオフ）',
    KEEPDOCNOTES: 'コピー操作でドキュメントノートをコピーする',
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS:
      '必要に応じて条項トピックをレビューおよび修正しました',
    NOEXECUTIONDATE: '締結日なし',
    EXECUTIONDATE: '締結日',
    CONTRACTSTARTDATE: '契約開始日',
    CONTRACTENDDATE: '契約終了日',
    EXECCONTRACTNAME: '締結済み契約ファイル名',
    SELECTEXECCONTRACT:
      'アップロードする締結済み契約ファイルを選択してください',
    UPLOADEXECUTEDCONTRACT: '締結済み契約をアップロード',
    LOADDEFAULTS: 'デフォルトをロード',
    CLOSED: '停止中',
    NONE: 'なし',
    NA: '適用外',
    RESULTEXECUTEDCONTRACT: '契約が締結されました',
    NOSTARTDATE: '開始日なし',
    NOENDDATE: '終了日なし',
    EXCLUDECOLLABORATORSFROMACCESS:
      '外部の共同編集者をドキュメントのアクセスから除外する',
    EXCLUDENEGOTIATORSFROMACCESS:
      '交渉相手をドキュメントのアクセスから除外する',
    REVIEWEDAMENDEDCLAUSELABELS: '必要に応じて条項ラベルを確認および修正',
    ENTERPARAGRAPHBETWEEN: '段落番号を入力してください：',
    GOTOPARAGRAPHINDEX: '段落インデックスに移動',
    INPUTDOCUMENTNOTEHERE: 'ここにドキュメントノートを入力してください',
    INPUTCOMMENTHERE: 'ここにコメントを入力してください',
    SHOWPENDINGONLY: '保留中のみ表示',
    COLLABORATIONSETTING: '共同編集設定',
    NEGOTIATIONSETTING: '交渉設定',
    NONEGOTWHENAPPROVED:
      '承認済みのステータスでは交渉モードをオンにすることはできません',
    EMAILTOADD: '追加するEメール',
    CLAUSETOADD: '追加する条項',
    TAGTOADD: '追加するタグ',
    OPTIONSFORREDLINEINCLUDE: '含める変更履歴のオプション',
    ONLYINCLUDEOWNERAPPROVEDEDITS: 'オーナーが承認した変更内容のみを含める',
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'オーナーと交渉相手が承認した変更内容のみを含める',
    INCLUDEALLDOCEDITS:
      '承認されたかどうかに関わらず、すべての変更内容を含める',
    REDLINECHANGESSELECTED: '選択された変更履歴',
    TOTALCHANGES: '合計変更数',
    REPLACEREDLINES: '変更履歴を置換',
    DELETEREDLINES: '変更履歴を削除',
    ADDAFTERREDLINES: '変更履歴に追記',
    OPTIONSFORPREVREDLINES: '過去の変更履歴に対するオプション',
    OVERWRITEPREVREDLINES:
      'ドキュメントソース内の過去のすべての変更履歴を上書き',
    KEEPPREVREDLINES: 'ドキュメントソース内の過去のすべての変更履歴を保持',
    GENERATEFINALDOC: '変更履歴を含まない最終ドキュメントを生成',
    DEFAULTESIGNOTE: '話し合ったように、この契約書に電子署名してください。',
    SUBMITTEDSIGMESSAGE: '署名のために送信されました。お待ちください。',
    UPLOADEDMESSAGE: 'アップロード完了',
    ADDADDLFILES: 'オプション - 署名リクエストにファイルを追加する',
    ADDFILESMSG: '署名用にファイルを追加してください',
    ESIGPREPAIDPLANS:
      '電子署名機能は、プリペイドプランでのみご利用いただけます。',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      '詳細については、billing @ mylegaleinstein.comまでお問い合わせください。',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: '以下からより多くのビデオを見ることができます。 ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'サポートされていないドキュメントタイプです。 Docgilityでは、docxおよびpdf形式のみ利用可能です。',
    PDFSUPPORTEDFORPREMIUMONLY:
      'PDFスキャンを利用するには、プレミアムプラン以上にアップグレードする必要があります。',
    BADDOCFORMAT: '不正なドキュメント形式がドキュメント処理に渡されました。',
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      'ドキュメント設定を変更する権限がありません。ドキュメントのオーナーである必要があります。',
    PRIVILEGSNOTAVAIL:
      'このユーザーの権限は利用できません。後でもう一度試してください。',
    EXHAUSTEDTRIALLICENSE:
      'トライアル期間中に許可されたドキュメント数を使い切りました。プロファイルから [サブスクリプションのアップグレード] を選択してください。',
    EXHAUSTEDMONTHLYLIMIT:
      'トライアル期間中に許可された月次ドキュメント数を使い切りました。プロファイルで [サブスクリプションのアップグレード] を選択してください。',
    PASSWORDNOTMATCHING:
      'パスワードが一致しません。パスワードをもう一度確認してください。 ',
    ABAMEMBERIDLENGTH:
      'ABAメンバーシップIDは7文字です。確認して送信してください。',
    TERMSNOTCHECKED: '利用規約が確認および同意されていません。',
    EMAILFORMATBAD:
      'Eメール形式が無効です。Eメールアドレスを確認してください。',
    CANCELEDACTION:
      '最後のアクションはキャンセルされました。アプリケーションがリロードされます。',
    SUCCESSACTION:
      '最後のアクションは成功しました。アプリケーションがリロードされます。',
    USERSUCCESSLOGIN: '正常にログオンしました。',
    USERLOGGEDOUT: 'ログアウトしました。',
    EMAILNOTVERIFIED:
      'アカウントのEメールアドレスはまだ確認されていません。確認メールが送信されました。',
    EMAILALREADYREGISTERED:
      'このEメールには、既に登録されたアカウントがあります。。',
    EMAILNOTVERIFIEDRESENT:
      'あなたのEメールアドレスはまだ確認されていません。新しいアカウントを有効にするために、確認メールを再送信しました。',
    USERLOGFILENOTEXISTS: 'エラー - ログユーザーファイルが存在しません。',
    PASSWORDRESETSENT: 'パスワード再設定のためのEメールが送信されました。',
    PASSWORDCHANGED: 'パスワードが変更されました。',
    REGISTRATIONDELETED: 'ユーザー登録を削除しました',
    USERACCOUNTUPDATED:
      'ユーザーアカウント設定を更新しました。変更は、次回ログイン時に有効になります。',
    UPGRADEDTOREFERRED:
      'アカウントが紹介トライアルサブスクリプションに更新されました。',
    REGISTEREDTRIALUSER:
      '新しいアカウントがトライアルサブスクリプションとして登録されました。',
    DELETEDUSERACCOUNT: 'ユーザーアカウントを削除しました',
    EMAILALREADYREGISTEREDRESENT:
      'EメールはまだDocgilityに登録されていません。新しいユーザーに招待メールが送信されています。',
    UPGRADEEXTERNALPAYMENTS:
      'アップグレードありがとうございます。外部の支払いサービスにリダイレクトします。お支払いが完了した後、サブスクリプションサービスを開始するために再度ログインしてください。',
    BILLINGEXTERNALPAYMENTS:
      '外部の支払いおよび請求サービスへリダイレクトすることを確認してください。変更を加えた後、変更を有効にするために再度ログインしてください。',
    NOTABLETOLOGINATTHISTIME:
      '現在ログインできません。後でもう一度試してください。',
    NOTREGISTERED:
      'ユーザーが登録されていません。ログインする前に登録してください。',
    NAMETOOLONG: '名前が長すぎます。60文字以内で入力してください。',
    COMPANYTOOLONG: '会社名が長すぎます。60文字以内で入力してください。',
    COMMENTTOOLONG: 'コメントが長すぎます。10,000文字以内で入力してください。',
    DOCEDITTOOLONG:
      'ドキュメントの編集が長すぎます。10,000文字以内で入力してください。',
    TITLETOOLONG: 'タイトルが長すぎます。60文字以内で入力してください。',
    PASSWORDTOOSHORT: 'パスワードが短すぎます。6文字以上で入力してください。',
    CONGRATSNEWDOCUMENTPROCESSING:
      '新しいドキュメントを送信しました！　ドキュメントの分析が完了すると画面が更新されます。ドキュメントの分析には数分かかる場合があります。しばらくお待ちください。',
    NODOCUMENTLOADED: 'ドキュメントがロードされていません',
    SELECTFILETOUPLOADDOCX:
      'アップロードするファイルを選択してください（*.docx）',
    SELECTFILETOUPLOADDOCXPDF:
      'アップロードするファイルを選択してください（*.docx、*.pdf）',
    INPUTCOMMENTHERE: 'ここにコメントを入力してください',
    AUTOAPPROVEASOWNER: 'オーナーとして自動承認',
    INPUTDOCEDITSHERE: 'ここに編集内容を入力',
    TRIGGEREDALLALERTS:
      'このドキュメントタイプに追加されたすべてのアラートを送信しました。',
    QSINTROMESSAGE:
      'Docgilityは、契約書を即座に分析、トピックごとにインデックスを作成、条文を一目で比較できるようにするコラボレーションプラットフォームです。',
    QSDOCMESSAGE:
      'ここにダウンロードして利用できるいくつかの公開された契約書があります：',
    QSHELPMESSAGE: 'ここに製品ドキュメントへのリンクがあります',
    DOCUMENTFAILEDTOPROCESS:
      'ドキュメントの処理中に致命的なエラーが発生しました。もう一度やり直してください。それでも問題が解決しない場合は、カスタマーサポート（support@mylegaleinstein.com）までご連絡ください。',

    // ADDED
    DOCUMENTSUCCESSCOPIED: 'これでドキュメントがコピーされました。',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      'このドキュメントのクリーンバージョンが生成され、保存されました。',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      'このテンプレートからドキュメントが生成され、保存されました。',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE:
      'ドキュメントがドキュメントテンプレートに変換されました。',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      '指定したドキュメントを削除します。ドキュメントが削除されると利用できなくなります。ドキュメントをアーカイブステータスに変更することも検討してください。¥n¥n削除してよろしいですか？',
    CONFIRMSAVETOPLAYBOOK:
      'このドキュメントをプレイブックに変換します。これにより、オーナーを除いてこのドキュメントは変更できなくなります。¥n¥n変換してよろしいですか？',
    COLLABORATIONNOTE:
      '注：この情報は内部の指定された共同編集者およびドキュメントオーナーにのみ共有されます。',
    NEGOTIATIONNOTE: '注：この情報は外部の交渉相手と共有されます。',
    CONFIRMDELETEALERT:
      '指定したアラートを削除することを確認してください。アラートが削除されると、使用できなくなります。削除操作を確認してください。',
    CONFIRMDELETERECIPIENTALERT:
      '指定されたアラートの受信を停止します。受信を停止した後、アラートのオーナーに連絡してアラート受信の再開をリクエストできます。停止してよろしいですか？',
    CONFIRMDELETEGROUP:
      '指定したグループを削除します。グループが削除されると使用できなくなります。ただし、グループの削除は次回ログイン時に反映されます。削除してよろしいですか？',
    CONFIRMDELETECOLLABORATORGROUP:
      '指定したグループの共同編集者を辞退します。共同編集者を辞退した後、グループのオーナーに連絡して共同編集者として再追加をリクエストできます。辞退してよろしいですか？',
    CONFIRMDELETEDOCTYPE:
      '指定したドキュメントタイプを削除します。ドキュメントタイプが削除されると使用できなくなります。削除してよろしいですか？',
    EXPIREDTRIAL:
      'トライアルライセンスの有効期限が切れました。サービスの利用を継続するには、アップグレードしてください。',
    EXPIREDSUBSCRIPTION:
      'サブスクリプションライセンスの有効期限が切れました。アップグレードしてサービスの利用を継続するか、Docgilityの請求担当に連絡してください。',
    SOURCEDOCNOTDOCX:
      'docx形式ではないソースドキュメントです。変更履歴機能は利用できません。',
  },
}

export default JA
