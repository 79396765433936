import store from '@state/store'

import {
  DBsetone,
  DBupdateobjarrayObjUnion,
  DBupdateobjarrayIncrement,
  DBgetobjsync,
} from '@/src/state/io/iodb.js'

const LOGDOCTABLENAME = 'logdoc'
const LOGUSERTABLENAME = 'loguser'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  init({ commit }) {},

  // creates a log file for each document ID
  writeNewLogDoc({ commit, state }, payload) {
    const timestamp = new Date()

    const insertdetail = {
      action: 'DOCEVENTS.SAVEDOCUMENT',
      detail: '',
      context: '',
      uniqid: payload.uniqid,
      docid: payload.docid,
      timestamp: timestamp,
    }

    var writeNewLogDocEx = {
      docid: payload.docid,
      // change to include the first event.
      log: [insertdetail],
      create: timestamp,
      delete: '',
    }

    DBsetone(LOGDOCTABLENAME, payload.docid, writeNewLogDocEx)
  },

  // creates a log file for each document ID
  writeNewLogUser({ commit, state }, newuniqid) {
    var writeNewLogUserEx = {
      uniqid: newuniqid,

      // promotion source (if any)
      source: '',
      // log
      log: [],
      // counts
      addcollab: 0,
      addnegot: 0,
      docsproc: 0,
      docsprocmonth: 0,
      referrreddocs: [],

      // count placeholders for later.
      Z1: 0,
      Z2: 0,
      Z3: 0,
      Z4: 0,
      Z5: 0,

      create: new Date(),
      delete: '',
    }

    DBsetone(LOGUSERTABLENAME, newuniqid, writeNewLogUserEx)
  },

  // Add a user action log for a document.
  addLogDoc({ commit, state }, payload) {
    // 2 values are requires for all paload = uniqid, and docid.  It's inserted into the log detail.
    let insertdetail = payload.logdetail
    insertdetail.uniqid = payload.uniqid
    insertdetail.docid = payload.docid
    insertdetail.timestamp = new Date()

    // aggregated metrics
    // counts -
    // docsproc: 0,  - total number of docs processed
    // docsprocmonth: 0,  - total number of docs processed since last monthly subsription event (to address )
    // addcollab: 0,  - engagement number for adding new collaborators
    // addnegot: 0,   - engagement number for adding new negotiatiors.

    // // count placeholders for later = don't know what they will include until later.
    // Z1: 0,
    // Z2: 0,
    // Z3: 0,
    // Z4: 0,
    // Z5: 0,

    DBupdateobjarrayObjUnion(
      LOGDOCTABLENAME,
      payload.docid,
      'log',
      insertdetail
    )

    DBupdateobjarrayObjUnion(
      LOGUSERTABLENAME,
      payload.uniqid,
      'log',
      insertdetail
    )

    if ('Z1' in insertdetail && insertdetail.Z1) {
      DBupdateobjarrayIncrement(LOGDOCTABLENAME, payload.docid, 'Z1')
    }
    if ('Z2' in insertdetail && insertdetail.Z1) {
      DBupdateobjarrayIncrement(LOGDOCTABLENAME, payload.docid, 'Z2')
    }
    if ('Z3' in insertdetail && insertdetail.Z1) {
      DBupdateobjarrayIncrement(LOGDOCTABLENAME, payload.docid, 'Z3')
    }
    if ('Z4' in insertdetail && insertdetail.Z1) {
      DBupdateobjarrayIncrement(LOGDOCTABLENAME, payload.docid, 'Z4')
    }
    if ('Z5' in insertdetail && insertdetail.Z1) {
      DBupdateobjarrayIncrement(LOGDOCTABLENAME, payload.docid, 'Z5')
    }
    if ('docsproc' in insertdetail && insertdetail.docsproc) {
      DBupdateobjarrayIncrement(LOGDOCTABLENAME, payload.docid, 'docsproc')
    }
    if ('docsprocmonth' in insertdetail && insertdetail.docsprocmonth) {
      DBupdateobjarrayIncrement(LOGDOCTABLENAME, payload.docid, 'docsprocmonth')
    }
    if ('addcollab' in insertdetail && insertdetail.addcollab) {
      DBupdateobjarrayIncrement(LOGDOCTABLENAME, payload.docid, 'addcollab')
    }
    if ('addnegot' in insertdetail && insertdetail.addnegot) {
      DBupdateobjarrayIncrement(LOGDOCTABLENAME, payload.docid, 'addnegot')
    }
  },

  addLogUserOnly({ commit, state }, payload) {
    let insertdetail = payload.logdetail
    insertdetail.uniqid = payload.uniqid
    insertdetail.timestamp = new Date()

    DBupdateobjarrayObjUnion(
      LOGUSERTABLENAME,
      payload.uniqid,
      'log',
      insertdetail
    )

    if ('docsproc' in insertdetail && insertdetail.docsproc) {
      DBupdateobjarrayIncrement(LOGUSERTABLENAME, payload.uniqid, 'docsproc')
    }
    if ('docsprocmonth' in insertdetail && insertdetail.docsprocmonth) {
      DBupdateobjarrayIncrement(
        LOGUSERTABLENAME,
        payload.uniqid,
        'docsprocmonth'
      )
    }
  },

  // get the Document Log for a specific documentid and set in panels
  setLogDoc({ commit, state }, payload) {
    DBgetobjsync(LOGDOCTABLENAME, payload.docid).then(function (doc) {
      if (doc.exists) {
        store.dispatch('panels/setDocLog', {
          panelno: payload.panelno,
          docobj: doc.data,
        })
      }
    })
  },
}
