import store from '@state/store'

import {
  DBinsertonesync,
  DBdeactivate,
  DBupdateobj,
} from '@/src/state/io/iodb.js'

import {
  DBHUBadddocchange,
  DBHUBdeletedocchange,
  DBHUBdoceditapproverejectchange,
} from '@/src/state/io/iohub.js'

const TABLENAME = 'docedits'

export const state = {
  // debug
  dbdoceditid: 0,
}

export const getters = {
  dbdoceditid(state) {
    return state.dbdoceditid
  },
}

export const mutations = {
  SET_ID(state, id) {
    state.dbdoceditid = id
  },
}

export const actions = {
  init({ commit }) {},

  // writes the clause list to the aiclauses collection.  - OK (intended to do once to load the table)
  writeDocEdit({ commit, state }, payload) {
    var writeDocEditEx = {
      docid: payload.docid,
      paraid: payload.paraid,
      sentid: payload.sentid,
      senttext: payload.senttext,
      docedittype: payload.docedittype,
      docedittext: payload.docedittext,
      uniqid: payload.uniqid,
      type: payload.type,

      // set defaults
      approver: '',
      approvedtime: '',
      approved: false,
      negotiatorapprover: '',
      negotiatorapprovedtime: '',
      negotiatorapproved: false,
      create: new Date(),
      delete: '',
      active: true,
    }

    var returnval

    if (payload.ifHUBType) {
      returnval = DBHUBadddocchange(TABLENAME, writeDocEditEx)
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.ADDDOCEDIT',
          detail: payload.docedittype + '-' + payload.docedittext,
          context:
            'docid:' + payload.docid + ',' + 'paragraph:' + payload.paraid,
        },
      })
    } else {
      returnval = DBinsertonesync(TABLENAME, writeDocEditEx)
      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.ADDDOCEDIT',
          detail: payload.docedittype + '-' + payload.docedittext,
          context: 'paragraph:' + payload.paraid,
        },
      })
    }
    return returnval
  },

  // writes the clause list to the aiclauses collection.  - OK (intended to do once to load the table)
  writeApprovedDocEdit({ commit, state }, payload) {
    // commit('INIT_USEDB')
    var writeDocEditEx = {
      docid: payload.docid,
      paraid: payload.paraid,
      sentid: payload.sentid,
      senttext: payload.senttext,
      docedittype: payload.docedittype,
      docedittext: payload.docedittext,
      uniqid: payload.uniqid,
      type: payload.type,

      // this is filled in by payload if preapproved.
      approver: payload.approver,
      approvedtime: payload.approvedtime,
      approved: payload.approved,
      negotiatorapprover: payload.negotiatorapprover,
      negotiatorapprovedtime: payload.negotiatorapprovedtime,
      negotiatorapproved: payload.negotiatorapproved,
      create: new Date(),
      delete: '',
      active: true,
    }

    var returnval

    if (payload.ifHUBType) {
      returnval = DBHUBadddocchange(TABLENAME, writeDocEditEx)
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.ADDAPPROVEDDOCEDIT',
          detail: payload.docedittype + '-' + payload.docedittext,
          context:
            'docid:' + payload.docid + ',' + 'paragraph:' + payload.paraid,
        },
      })
    } else {
      returnval = DBinsertonesync(TABLENAME, writeDocEditEx)
      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.ADDAPPROVEDDOCEDIT',
          detail: payload.docedittype + '-' + payload.docedittext,
          context: 'paragraph:' + payload.paraid,
        },
      })
    }
    return returnval
  },

  ownerApproveDocEdit({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBdoceditapproverejectchange(payload.docid, payload.doceditid, {
        approver: payload.approver,
        approvedtime: new Date(),
        approved: true,
        detail: payload.detail,
        context: payload.context,
      })
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.APPROVEDDOCEDIT',
          detail: payload.detail,
          context: 'docid:' + payload.docid + ',' + payload.context,
        },
      })
    } else {
      DBupdateobj(TABLENAME, payload.doceditid, {
        approver: payload.approver,
        approvedtime: new Date(),
        approved: true,
      })
      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.APPROVEDDOCEDIT',
          detail: payload.detail,
          context: payload.context,
        },
      })
    }
  },

  ownerRejectDocEdit({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBdoceditapproverejectchange(payload.docid, payload.doceditid, {
        approver: payload.approver,
        approvedtime: new Date(),
        approved: false,
        detail: payload.detail,
        context: payload.context,
      })
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.REJECTDOCEDIT',
          detail: payload.detail,
          context: 'docid:' + payload.docid + ',' + payload.context,
        },
      })
    } else {
      DBupdateobj(TABLENAME, payload.doceditid, {
        approver: payload.approver,
        approvedtime: new Date(),
        approved: false,
      })

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.REJECTDOCEDIT',
          detail: payload.detail,
          context: payload.context,
        },
      })
    }
  },

  negotiatorApproveDocEdit({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBdoceditapproverejectchange(payload.docid, payload.doceditid, {
        negotiatorapprover: payload.approver,
        negotiatorapprovedtime: new Date(),
        negotiatorapproved: true,
        detail: payload.detail,
        context: payload.context,
      })
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.NEGOTIATORAPPROVEDDOCEDIT',
          detail: payload.detail,
          context: 'docid:' + payload.docid + ',' + payload.context,
        },
      })
    } else {
      DBupdateobj(TABLENAME, payload.doceditid, {
        negotiatorapprover: payload.approver,
        negotiatorapprovedtime: new Date(),
        negotiatorapproved: true,
      })

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.NEGOTIATORAPPROVEDDOCEDIT',
          detail: payload.detail,
          context: payload.context,
        },
      })
    }
  },

  negotiatorRejectDocEdit({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBdoceditapproverejectchange(payload.docid, payload.doceditid, {
        negotiatorapprover: payload.approver,
        negotiatorapprovedtime: new Date(),
        negotiatorapproved: false,
        detail: payload.detail,
        context: payload.context,
      })
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.NEGOTIATORREJECTEDDOCEDIT',
          detail: payload.detail,
          context: 'docid:' + payload.docid + ',' + payload.context,
        },
      })
    } else {
      DBupdateobj(TABLENAME, payload.doceditid, {
        negotiatorapprover: payload.approver,
        negotiatorapprovedtime: new Date(),
        negotiatorapproved: false,
      })
      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.NEGOTIATORREJECTEDDOCEDIT',
          detail: payload.detail,
          context: payload.context,
        },
      })
    }
  },

  deleteDocEdit({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBdeletedocchange(TABLENAME, payload, payload.doceditid)
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.DELETEDDOCEDIT',
          detail: 'docid:' + payload.docid + ',' + payload.doceditid,
          context: '',
        },
      })
    } else {
      DBdeactivate(TABLENAME, payload.doceditid)
      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.DELETEDDOCEDIT',
          detail: payload.doceditid,
          context: '',
        },
      })
    }
  },
}
