// Contains all application constants //

export const IT = {
  TOPMENU: {
    FILE: 'FILE',
    EDIT: 'COMP',
    ACT: 'ATTO',
    DOCS: 'DOCS',
  },

  MENU: {
    NEWDOCUMENT: 'Nuovo documento',
    // ORIGINAL TEXT: New Document
    SAVEDOCUMENT: 'Salva documento',
    // ORIGINAL TEXT: Save Document
    COPYDOCUMENT: 'Copia documento',
    // ORIGINAL TEXT: Copy Document
    CREATEVERSION: 'Crea versione pulita',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: 'Crea documento da modello',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: 'Apri documento',
    // ORIGINAL TEXT: Open Document
    OPENGROUPDOCUMENT: 'Apri documento di gruppo',
    // ORIGINAL TEXT: Open Group Document
    OPENHUBDOCUMENT: 'Apri HUB documento',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: 'Apri documento di archivio',
    // ORIGINAL TEXT: Open Archive Document
    OPENPLAYBOOK: 'Apri playbook',
    // ORIGINAL TEXT: Open Playbook
    OPENTEMPLATE: 'Apri modello',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: 'Salva come modello',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: 'Salva come playbook',
    // ORIGINAL TEXT: Save As Playbook
    MOVETOARCHIVE: "Sposta nell' archivio",
    // ORIGINAL TEXT: Move to Archive
    CLOSEDOCUMENT: 'Chiudi documento',
    // ORIGINAL TEXT: Close Document
    DOCUMENTLOG: 'Registro documenti',
    // ORIGINAL TEXT: Document Log

    GOTOPARAGRAPH: 'Va al paragrafo',
    // ORIGINAL TEXT: Go to Paragraph
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'Note sul documento',
    // ORIGINAL TEXT: Document Notes
    COLLABCOMMENTS: 'Collaborazione - Commenti',
    // ORIGINAL TEXT: Collaboration - Comments
    COLLABDOCEDITS: 'Collaborazione - Modifica documenti',
    // ORIGINAL TEXT: Collaboration - Document Edits
    COLLABRISKANDLABELS: 'Collaborazione - Rischio ed Etichette',
    // ORIGINAL TEXT: Collaboration - Risk and Labels
    NEGOTCOMMENTS: 'Negoziazione - Commenti',
    // ORIGINAL TEXT: Negotiation - Comments
    NEGOTDOCEDITS: 'Negoziazione - Modifica documento',
    // ORIGINAL TEXT: Negotiation - Document Edits

    COLLABORATION: 'Collaborazione',
    // ORIGINAL TEXT: Collaboration
    NEGOTIATION: 'Negoziazione',
    // ORIGINAL TEXT: Negotiation
    ADDOWNER: 'Aggiungi proprietario',
    // ORIGINAL TEXT: Add Owner
    ADDCOLLABORATOR: 'Aggiungi collaboratore',
    // ORIGINAL TEXT: Add Collaborator
    ADDNEGOTIATOR: 'Aggiungi negoziatore',
    // ORIGINAL TEXT: Add Negotiator
    TRIGGERALERTS: 'Avvisi trigger',
    // ORIGINAL TEXT: Trigger Alerts
    EXPORTEXCEL: 'Esporta Excel',
    // ORIGINAL TEXT: Export Excel
    DOWNLOADSOURCE: 'Scarica sorgente',
    // ORIGINAL TEXT: Download Source
    SENDTOCLM: 'Invia a CLM',
    // ORIGINAL TEXT: Send to CLM
    EXPORTJSON: 'Esporta JSON',
    // ORIGINAL TEXT: Export JSON
    ESIGNATURE: 'Invia per la firma',
    // ORIGINAL TEXT: Send for Esignature
    DOWNLOADREDLINE: 'Scarica Redline DocX',
    // ORIGINAL TEXT: Download Redline DocX
    DOCUMENTSETTINGS: 'Impostazioni del documento',
    // ORIGINAL TEXT: Document Settings
    EXPAND: 'Espandi',
    // ORIGINAL TEXT: Expand
    COLLAPSE: 'Collasso',
    // ORIGINAL TEXT: Collapse

    TRANSLATETOENGLISH: 'Traduci in inglese',
    // ORIGINAL TEXT: Translate to English
    ORIGINALLANGUAGE: "Ripristina all' originale",
    // ORIGINAL TEXT: Revert to Original

    ALLPARAGRAPHS: 'Tutti i paragrafi',
    // ORIGINAL TEXT: All Paragraphs
    EDITEDPARAGRAPHSONLY: 'Solo paragrafi modificati',
    // ORIGINAL TEXT: Edited Paragraphs Only

    NOEDITCLAUSELABELS: 'Nessuna modifica delle etichette delle clausole',
    // ORIGINAL TEXT: No Edit Clause Labels
    EDITCLAUSELABELS: 'Modifica le etichette delle clausole',
    // ORIGINAL TEXT: Edit Clause Labels

    HIDEEDITS: 'Nascondi modifiche',
    // ORIGINAL TEXT: Hide Edits
    SHOWEDITS: 'Mostra le modifiche',
    // ORIGINAL TEXT: Show Edits

    SHOWALLEDITS: 'Mostra tutte le modifiche',
    // ORIGINAL TEXT: Show All Edits
    SHOWNEGOTEDITSONLY: 'Mostra Solo modifiche di negoziazione',
    // ORIGINAL TEXT: Show Negotiation Edits Only

    GROUPS: 'Gruppi',
    // ORIGINAL TEXT: Groups
    DOCUMENTTYPES: 'Tipi di documento',
    // ORIGINAL TEXT: Document Types
    ALERTS: 'Avvisi',
    // ORIGINAL TEXT: Alerts
    QUICKSTARTTUTORIAL: 'Tutorial di avvio rapido',
    // ORIGINAL TEXT: Quick Start Tutorial
    QUICKSTARTVIDEOS: 'Avvia video veloce',
    // ORIGINAL TEXT: Quick Start Videos
    UPGRADESUBSCRIPTION: 'PREMIUM',
    // ORIGINAL TEXT: Upgrade Subscription
    SUBSCRIPTIONBILLING: 'Abbonamento alla fatturazione',
    // ORIGINAL TEXT: Subscription Billing
    ACCOUNTSETTINGS: 'Impostazioni account',
    // ORIGINAL TEXT: Account Settings
    USERLOG: 'Log utente',
    // ORIGINAL TEXT: User Log
    CHANGEPASSWORD: 'Cambia password',
    // ORIGINAL TEXT: Change Password
    LOGOUT: 'Esci',
    // ORIGINAL TEXT: Logout

    // HEADINGS
    ADMIN: 'ADMIN',
    // ORIGINAL TEXT: ADMIN
    PROFILE: 'PROFILO',
    // ORIGINAL TEXT: PROFILE
    LPROFILE: 'Profilo',
    // ORIGINAL TEXT: Profile
    FILE: 'FILE',
    // ORIGINAL TEXT: FILE
    EDIT: 'EDIT',
    // ORIGINAL TEXT: EDIT
    ACT: 'ATTO',
    // ORIGINAL TEXT: ACT
    TOPIC: 'COPERTURA',
    // ORIGINAL TEXT: TOPIC
    DOCS: 'DOCS',
    // ORIGINAL TEXT: DOCS

    // Field Headings in Navigation Bar
    NAME: 'Nome',
    // ORIGINAL TEXT: Name
    ROLE: 'Ruolo',
    // ORIGINAL TEXT: Role
    STATE: 'Stato',
    // ORIGINAL TEXT: State
    GROUP: 'Gruppo',
    // ORIGINAL TEXT: Group
    TYPE: 'Tipo',
    // ORIGINAL TEXT: Type
    SENSIT: 'Sensibilità',
    // ORIGINAL TEXT: Sensitivity
    LTOPIC: 'Tema',
    // ORIGINAL TEXT: Topic
    USER: 'Utilizzatore',
    // ORIGINAL TEXT: User

    // Other Headings
    REGISTER: 'REGISTRO',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'LOGIN',
    // ORIGINAL TEXT: LOGIN
    LREGISTER: 'Registro',
    // ORIGINAL TEXT: Register
    LLOGIN: 'Accesso',
    // ORIGINAL TEXT: Login

    ADMINEXPLAIN: 'Capacità amministrative',
    // ORIGINAL TEXT: Administrative capabilities
    PROFILEEXPLAIN: 'Gestione degli account, abbonamenti, fatturazione',
    // ORIGINAL TEXT: Account Management, Subscriptions, Billing

    // AUTH LEVELS
    PREMIUM: 'Premio',
    // ORIGINAL TEXT: Premium
    PREMIUMPREPAID: 'Premio (prepagato)',
    // ORIGINAL TEXT: Premium (Prepaid)
    BASIC: 'Basic',
    // ORIGINAL TEXT: Basic
    BASICPREPAID: 'Base (prepagato)',
    // ORIGINAL TEXT: Basic (Prepaid)
    VIPACCESS: 'Accesso VIP',
    // ORIGINAL TEXT: VIP Access
    VIPMEMBER: 'Membro VIP',
    // ORIGINAL TEXT: VIP Member
    UNLIMITED: 'Illimitata',
    // ORIGINAL TEXT: Unlimited
    TRIALUSER: 'Utente di prova',
    // ORIGINAL TEXT: Trial User
    REFERREDUSER: 'Utente citato',
    // ORIGINAL TEXT: Referred User
    SOURCEDOCUMENT: 'Documento di origine',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: 'Documento di confronto',
    // ORIGINAL TEXT: Comparison Document
    EXIT: 'USCITA',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: 'Paragrafo',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: 'MODALITÀ CONFRONTO DOCUMENTI',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: 'Nessuno',
    // ORIGINAL TEXT: NONE
    PREAMBLE: 'PREAMBOLO',
    // ORIGINAL TEXT: PREAMBLE
    DEFN: 'DEFINIZIONE',
    // ORIGINAL TEXT: DEFINITION
    PAYMENT: 'PAGAMENTO',
    // ORIGINAL TEXT: PAYMENT
    TAXES: 'IMPOSTE',
    // ORIGINAL TEXT: TAXES
    REPWARR: 'RAPPRESENTANTE E GARANZIA',
    // ORIGINAL TEXT: REPRESENTATION AND WARRANTIES
    PERIOD: 'TEMPO',
    // ORIGINAL TEXT: PERIOD
    TERMINATION: 'CHIUSURA',
    // ORIGINAL TEXT: TERMINATION
    CONFID: 'COMPATIBILITÀ',
    // ORIGINAL TEXT: CONFIDENTIALITY
    INDEMNITY: 'INDENNITÀ',
    // ORIGINAL TEXT: INDEMNITY
    LIABILITY: 'RESPONSABILITÀ',
    // ORIGINAL TEXT: LIABILITY
    INTELPROP: 'PROP INTELLETTUALE',
    // ORIGINAL TEXT: INTELLECTUAL PROP
    SIGNATURE: 'FIRMA',
    // ORIGINAL TEXT: SIGNATURE
    OTHER: 'ALTRO',
    // ORIGINAL TEXT: OTHER
    DATASECURITY: 'SICUREZZA DEI DATI',
    // ORIGINAL TEXT: DATA SECURITY
    ASSIGN: 'ASSEGNAZIONE',
    // ORIGINAL TEXT: ASSIGNMENT
    NOTICE: 'AVVISO',
    // ORIGINAL TEXT: NOTICE
    GOVERNLAW: 'LEGGE DI GOVERNO',
    // ORIGINAL TEXT: GOVERNING LAW
    DISPUTE: 'DISPUTO',
    // ORIGINAL TEXT: DISPUTE
    ENTIREAGREE: 'ACCORDO ENTRO',
    // ORIGINAL TEXT: ENTIRE AGREEMENT
    SURVIVAL: 'SORVIVALE',
    // ORIGINAL TEXT: SURVIVAL
    RELATPART: 'RELAZIONE TRA PARTI',
    // ORIGINAL TEXT: RELATION BETWEEN PARTIES
    THIRDPARTY: 'TERZO PARTE',
    // ORIGINAL TEXT: THIRD PARTY
    FORCEMAJEURE: 'MAGGIORE FORCE',
    // ORIGINAL TEXT: FORCE MAJEURE
    SEVERABILITY: 'COMPATIBILITÀ',
    // ORIGINAL TEXT: SEVERABILITY
    EXPORTCTRL: 'CONTROLLO DELLE ESPORTAZIONI',
    // ORIGINAL TEXT: EXPORT CONTROLS
    INTRO: 'INTRODUZIONE',
    // ORIGINAL TEXT: INTRODUCTION
    INSURANCE: 'ASSICURAZIONI',
    // ORIGINAL TEXT: INSURANCE
    HEADING: 'RUBRICA',
    // ORIGINAL TEXT: HEADING
    ALL: 'TUTTI',
    // ORIGINAL TEXT: ALL
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'È richiesta la posta elettronica',
    // ORIGINAL TEXT: E-mail is required
    EMAILVALID: 'La posta elettronica deve essere valida',
    // ORIGINAL TEXT: E-mail must be valid
    MAX20CHARS: 'Max 20 caratteri',
    // ORIGINAL TEXT: Max 20 characters
    MAX60CHARS: 'Max 60 caratteri',
    // ORIGINAL TEXT: Max 60 characters
    MAX80CHARS: 'Max 80 caratteri',
    // ORIGINAL TEXT: Max 80 characters
    MAX160CHARS: 'Max 160 caratteri',
    // ORIGINAL TEXT: Max 160 characters
    EMAILALREADYOWNER: "L'email è già un proprietario",
    // ORIGINAL TEXT: Email is already an owner
    EMAILALREADYCOLLABORATOR: "L'email è già un collaboratore",
    // ORIGINAL TEXT: Email is already a collaborator
    EMAILALREADYNEGOTIATOR: "L'email è già un negoziatore",
    // ORIGINAL TEXT: Email is already a negotiator
    EMAILALREADYOWNERDOCTYPE:
      "L'email è già proprietaria del tipo di documento",
    // ORIGINAL TEXT: Email is already an owner of the document type
    EMAILALREADYCOLLABORATORDOCTYPE:
      "L'email è già un collaboratore del tipo di documento",
    // ORIGINAL TEXT: Email is already an collaborator of the document type
    REQUIRED: 'Richiesto.',
    // ORIGINAL TEXT: Required.
    CLAUSEFIELDREQUIRED:
      'Questo campo di clausola è necessario per questa operazione',
    // ORIGINAL TEXT: This clause field is required for this operation
    CLAUSENOTDEFAULT:
      "Impossibile aggiungere la clausola dall'elenco delle clausole di default",
    // ORIGINAL TEXT: Cannot add clause from default clause list
    TAGFIELDREQUIRED: 'Questo campo di tag è necessario per questa operazione',
    // ORIGINAL TEXT: This tag field is required for this operation
    TAGNOTDEFAULT:
      "Impossibile aggiungere i tag dall'elenco di tag predefinito",
    // ORIGINAL TEXT: Cannot add tags from default tag list

    PARAINDEXREQUIRED: "È richiesto l'indice del paragrafo",
    // ORIGINAL TEXT: Paragraph index is required
    PARAINDEXINVALID: "L'indice dei paragrafi non esiste",
    // ORIGINAL TEXT: Paragraph index does not exist
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'documento salva',
    OPENDOCUMENT: 'documento aperto',
    // ORIGINAL TEXT: open document
    CLOSEDOCUMENT: 'chiudere il documento',
    // ORIGINAL TEXT: close document
    ADDNOTE: 'aggiungi nota',
    // ORIGINAL TEXT: add note
    ADDCOLLABORATOR: 'aggiungi collaboratore',
    // ORIGINAL TEXT: add collaborator
    DELETECOLLABORATOR: 'Elimina collaboratore',
    // ORIGINAL TEXT: delete collaborator
    ADDNEGOTIATOR: 'aggiungere negoziatore',
    // ORIGINAL TEXT: add negotiator
    DELETENEGOTIATOR: 'eliminare il negoziatore',
    // ORIGINAL TEXT: delete negotiator
    UPDATEARCHIVEDATA: "aggiornare i dati dell'archivio",
    // ORIGINAL TEXT: update archive data
    UPDATEDOCMETA: 'aggiornare i metadati del documento',
    // ORIGINAL TEXT: update document metadata
    ADDOWNER: 'aggiungi proprietario',
    // ORIGINAL TEXT: add owner
    DELETEOWNER: 'eliminare il proprietario',
    // ORIGINAL TEXT: delete owner
    CHANGECOLLABORATION: 'modifica le impostazioni della collaborazione',
    // ORIGINAL TEXT: changed collaboration settings
    CHANGENEGOTIATION: 'modifica delle impostazioni di negoziazione',
    // ORIGINAL TEXT: changed negotiation settings
    SAVEPLAYBOOK: 'salvato come playbook',
    // ORIGINAL TEXT: saved as playbook
    SAVETEMPLATE: 'salvato come modello',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: 'salvato come archiviato',
    // ORIGINAL TEXT: saved as archived
    TRIGGERALERT: 'avviso di attivazione',
    // ORIGINAL TEXT: trigger alert
    TRIGGERALLALERT: 'attiva tutti gli avvisi',
    // ORIGINAL TEXT: trigger all alerts
    TRIGGERCUSTOMALERT: 'attiva avviso personalizzato',
    // ORIGINAL TEXT: trigger custom alert
    ADDCOMMENT: 'aggiungi commento',
    // ORIGINAL TEXT: add comment
    DELETECOMMENT: 'Cancella commento',
    // ORIGINAL TEXT: delete comment
    ADDDOCEDIT: 'aggiungi docedit',
    // ORIGINAL TEXT: add docedit
    ADDAPPROVEDDOCEDIT: 'aggiungi documento approvato modifica',
    // ORIGINAL TEXT: add approved document edit
    APPROVEDDOCEDIT: 'approva la modifica del documento',
    // ORIGINAL TEXT: approve document edit
    REJECTDOCEDIT: 'rifiutare la modifica del documento',
    // ORIGINAL TEXT: reject document edit
    NEGOTIATORAPPROVEDDOCEDIT: 'negoziatore approvato documento di modifica',
    // ORIGINAL TEXT: negotiator approved document edit
    NEGOTIATORREJECTEDDOCEDIT:
      'il negoziatore ha respinto la modifica del documento',
    // ORIGINAL TEXT: negotiator rejected document edit
    DELETEDDOCEDIT: 'Elimina la modifica del documento',
    // ORIGINAL TEXT: delete document edit
    ATTACHCONTRACT: 'allegare il contratto exec',
    // ORIGINAL TEXT: attach exec contract
    ADDTAG: 'aggiungi etichetta',
    // ORIGINAL TEXT: add tag
    DELETETAG: 'Elimina etichetta',
    // ORIGINAL TEXT: delete tag

    NEWSIGNATUREREQUEST: 'richiesta nuova firma',
    // ORIGINAL TEXT: new signature requested
    CANCELSIGNATUREREQUEST: 'richiesta di firma annullata',
    // ORIGINAL TEXT: cancelled signature request

    CREATEREDLINEREQUEST: 'crea un nuovo documento in linea rossa',
    // ORIGINAL TEXT: create new redline document
    CREATEFINALDOCREQUEST: 'creare un nuovo documento finale per la firma',
    // ORIGINAL TEXT: create new final document for signature
    COPIEDDOCUMENT: 'copiato dal documento',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: 'creata una versione pulita dal documento',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: 'documento creato da modello',
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'crea tipo di documento',
    // ORIGINAL TEXT: create document type
    DELETEDOCTYPE: 'Elimina il tipo di documento',
    // ORIGINAL TEXT: delete document type
    ADDOWNERTODOCTYPE: 'proprietario aggiunto al tipo di documento',
    // ORIGINAL TEXT: added owner to document type
    DELETEOWNERFROMDOCTYPE: 'proprietario eliminato al tipo di documento',
    // ORIGINAL TEXT: deleted owner to document type
    ADDCLAUSETODOCTYPE: 'aggiunta clausola addl al tipo di documento',
    // ORIGINAL TEXT: added addl clause to document type
    DELETECLAUSEDOCTYPE: 'clausola addl soppressa al tipo di documento',
    // ORIGINAL TEXT: deleted addl clause to document type
    ADDTAGDOCTYPE: 'aggiunto tag addl al tipo di documento',
    // ORIGINAL TEXT: added addl tag to document type
    DELETETAGDOCTYPE: 'tag addl eliminato dal tipo di documento',
    // ORIGINAL TEXT: deleted addl tag from document type
    ADDCOLLABRULEDOCTYPE: 'regole di collab aggiunte al tipo di documento',
    // ORIGINAL TEXT: added collab rules to  document type
    DELETECOLLABRULEDOCTYPE:
      'regole di collab cancellate dal tipo di documento',
    // ORIGINAL TEXT: deleted collab rules from document type
    ADDNOTIFRULEDOCTYPE: 'norme di notifica aggiunte al tipo di documento',
    // ORIGINAL TEXT: added notification rules to  document type
    DELETENOTIFRULEDOCTYPE: 'norme di notifica soppresse dal tipo di documento',
    // ORIGINAL TEXT: deleted notification rules from document type
    ADDNEGOTRULEDOCTYPE: 'regole di negoziazione aggiunte al tipo di documento',
    // ORIGINAL TEXT: added negotiation rules to  document type
    DELETENEGOTRULEDOCTYPE:
      'le regole di negoziazione cancellate dal tipo di documento',
    // ORIGINAL TEXT: deleted negotiation rules from document type

    // GROUPS
    CREATEGROUP: 'gruppo creato',
    // ORIGINAL TEXT: created group
    DELETEGROUP: 'gruppo soppresso',
    // ORIGINAL TEXT: deleted group
    ADDOWNERGROUP: 'proprietario aggiunto al gruppo',
    // ORIGINAL TEXT: added owner to group
    DELETEOWNERGROUP: 'proprietario eliminato dal gruppo',
    // ORIGINAL TEXT: deleted Owner from group
    ADDCOLLABGROUP: 'aggiunto collaboratore al gruppo',
    // ORIGINAL TEXT: added collaborator to group
    DELETECOLLABGROUP: 'collaboratore eliminato dal gruppo',
    // ORIGINAL TEXT: deleted collaborator from group
    ADDDOCTYPETOGROUP: 'il tipo di documento aggiunto al gruppo',
    // ORIGINAL TEXT: added document type to group
    DELETEDOCTYPEGROUP: 'tipo di documento cancellato dal gruppo',
    // ORIGINAL TEXT: deleted document type from group

    // ALERTS
    CREATEALERT: 'avviso creato',
    // ORIGINAL TEXT: created alert
    DELETEALERT: 'allarme cancellato',
    // ORIGINAL TEXT: deleted alert
    ADDOWNERALERT: 'Aggiunto proprietario alla segnalazione',
    // ORIGINAL TEXT: added owner to alert
    DELETEOWNERALERT: 'proprietario eliminato dalla segnalazione',
    // ORIGINAL TEXT: deleted owner from alert
    ADDDOCTYPEALERT: "aggiunto il doctype all'allerta",
    // ORIGINAL TEXT: added doctype to alert
    DELETEDOCTYPEALERT: "Doctype eliminato dall'allerta",
    // ORIGINAL TEXT: deleted doctype from alert
    ADDCLAUSETOPICALERT: 'Argomento della clausola aggiunto da avvisare',
    // ORIGINAL TEXT: added clause topic to alert
    DELETECLAUSETOPICALERT: "topic clausola eliminata dall'allerta",
    // ORIGINAL TEXT: deleted clause topic from alert
    ADDRECIPIENTALERT: 'aggiunto il destinatario alla segnalazione',
    // ORIGINAL TEXT: added recipient to alert
    DELETERECIPIENTALERT: 'destinatario eliminato dalla segnalazione',
    // ORIGINAL TEXT: deleted recipient from alert
    ADDNOTIFRULEALERT: 'norme di notifica aggiunte da segnalare',
    // ORIGINAL TEXT: added notification rules to alert
    DELETENOTIFRULEALERT: 'le regole di notifica cancellate dalla segnalazione',
    // ORIGINAL TEXT: deleted notification rules from alert
    SETSELECTIVITYALERT: "impostare la selettività all'allerta",
    // ORIGINAL TEXT: set selectivity to  alert
  },

  USEREVENTS: {
    USERSIGNON: 'Utente registrato',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT: 'password reimposta la richiesta inviata',
    // ORIGINAL TEXT: password reset request sent
    CHANGEDPASSWORD: 'cambia la password',
    // ORIGINAL TEXT: changed password
    DELETEDREGISTRATION: 'registrazione utente cancellata',
    // ORIGINAL TEXT: deleted user registration
    UPDATEDACCOUNTINFO: "informazioni aggiornate sull'account",
    // ORIGINAL TEXT: updated account information
    UPGRADEDACCOUNTTOREFERRAL: "account aggiornato all'account di riferimento",
    // ORIGINAL TEXT: upgraded account to referred account
    DELETEDUSERACCOUNT: 'account utente eliminato',
    // ORIGINAL TEXT: deleted user account
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: 'AZIONI DEI DOCUMENTI',
    // ORIGINAL TEXT: DOCUMENT ACTIONS
    COMMENTS: 'OSSERVAZIONI',
    // ORIGINAL TEXT: COMMENTS
    DOCEDITS: 'EDITI DEI DOCUMENTI',
    // ORIGINAL TEXT: DOCUMENT EDITS
    LABELSRISK: 'ETICHETTE E RISCHIO',
    // ORIGINAL TEXT: LABELS AND RISK
    TEXT: 'TESTO',
    // ORIGINAL TEXT: TEXT
    MLE_DESC: 'DOCGILITY',
    // ORIGINAL TEXT: DOCGILITY
    CLAUSE: 'Clausola',
    // ORIGINAL TEXT: Clause

    // PRIVILEGE
    OWNER: 'proprietario',
    // ORIGINAL TEXT: owner
    COLLABORATOR: 'collaboratore',
    // ORIGINAL TEXT: collaborator
    NEGOTIATOR: 'negoziatore',
    // ORIGINAL TEXT: negotiator
    OWNERGROUP: 'proprietario(gruppo)',
    // ORIGINAL TEXT: owner group
    COLLABORATORGROUP: 'collaboratori(gruppo)',
    // ORIGINAL TEXT: collaborator group

    // TITLES
    SAVEDOCUMENT: 'SAVE DOCUMENT',
    // ORIGINAL TEXT: SAVE DOCUMENT
    SAVEARCHIVE: 'Salva ARCHIVIO',
    // ORIGINAL TEXT: SAVE ARCHIVE
    QUICKSTART: "TUTORIALE VELOCE ALL'INIZIO",
    // ORIGINAL TEXT: QUICK START TUTORIAL
    TUTORIALVIDEOS: 'VIDEO TUTORIALI',
    // ORIGINAL TEXT: TUTORIAL VIDEOS

    MOVETOARCHIVEDOCUMENT: 'TRASFERIMENTO AL DOCUMENTO ARCHIVICO',
    // ORIGINAL TEXT: MOVE TO ARCHIVE DOCUMENT
    OPENDOCUMENT: 'DOCUMENTO APERTO',
    // ORIGINAL TEXT: OPEN DOCUMENT
    COPYDOCUMENT: 'DOCUMENTO DELLA COPIA',
    // ORIGINAL TEXT: COPY DOCUMENT
    CREATEVERSION: 'CREA VERSIONE PULITA',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: 'CREA DOCUMENTO DAL MODELLO',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: 'DOCUMENTO DEL GRUPPO APERTO',
    // ORIGINAL TEXT: OPEN GROUP DOCUMENT
    OPENHUBDOCUMENT: 'DOCUMENTO HUB APERTO',
    // ORIGINAL TEXT: OPEN HUB DOCUMENT
    OPENARCHIVEDOCUMENT: 'DOCUMENTO DI ARCHIVIO APERTO',
    // ORIGINAL TEXT: OPEN ARCHIVE DOCUMENT
    OPENPLAYBOOK: 'APERTO IL PLAYBOOK',
    // ORIGINAL TEXT: OPEN PLAYBOOK
    OPENTEMPLATE: 'MODELLO APERTO',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'SALVA COME MODELLO',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: 'COLLABORAZIONE',
    // ORIGINAL TEXT: COLLABORATION
    NEGOTIATION: 'NEGOZIO',
    // ORIGINAL TEXT: NEGOTIATION
    ADDOWNER: 'ADD OWNER',
    // ORIGINAL TEXT: ADD OWNER
    ADDNEGOTIATOR: 'ADD NEGOTITOR',
    // ORIGINAL TEXT: ADD NEGOTIATOR
    ADDCOLLABORATOR: 'ADD COLLABORATORE',
    // ORIGINAL TEXT: ADD COLLABORATOR
    ERRORMESSAGE: 'MESSAGGIO DI ERRORE',
    // ORIGINAL TEXT: ERROR MESSAGE
    DOCUMENTINFO: 'INFORMAZIONI SUL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT INFORMATION
    DOCUMENTLOGS: 'LOGS DA DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT LOGS
    USERLOGS: 'LOGS UTENTE',
    // ORIGINAL TEXT: USER LOGS
    DOCUMENTNOTES: 'NOTE DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT NOTES
    TRIGGERALERTS: 'ALERTE DI TRIGGER',
    // ORIGINAL TEXT: TRIGGER ALERTS
    ALERTS: 'ALERTS',
    // ORIGINAL TEXT: ALERTS
    DOCTYPES: 'TIPO DI DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT TYPES
    GROUPS: 'GRUPPI',
    // ORIGINAL TEXT: GROUPS
    NOTE: 'NOTA',
    // ORIGINAL TEXT: NOTE
    GOTOPARAGRAPH: 'VAI AL PARAGRAFIO',
    // ORIGINAL TEXT: GO TO PARAGRAPH
    ESIGNATURE: 'E-SIGNATURE',
    // ORIGINAL TEXT: E-SIGNATURE
    DOWNLOADREDLINE: 'DOWNLOAD REDLINE DOCX',
    // ORIGINAL TEXT: DOWNLOAD REDLINE DOCX

    // ACTIONS
    ADD: 'ADD',
    // ORIGINAL TEXT: ADD
    BACK: 'Indietro',
    // ORIGINAL TEXT: BACK
    DELETE: 'DELETE',
    // ORIGINAL TEXT: DELETE
    SAVE: 'SALVA',
    // ORIGINAL TEXT: SAVE
    MOVETOARCHIVE: "Spostati verso l'archive",
    // ORIGINAL TEXT: MOVE TO ARCHIVE
    COPY: 'COPIA',
    // ORIGINAL TEXT: COPY
    CREATE: 'CREARE',
    // ORIGINAL TEXT: CREATE
    OPEN: 'APERTO',
    // ORIGINAL TEXT: OPEN
    CLOSE: 'CHIUSURA',
    // ORIGINAL TEXT: CLOSE
    UPDATE: 'AGGIORNAMENTO',
    // ORIGINAL TEXT: UPDATE
    REGISTER: 'REGISTRO',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'LOGIN',
    // ORIGINAL TEXT: LOGIN
    LOGINSSO: 'LOGIN SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: 'RESET PASSWORD',
    // ORIGINAL TEXT: RESET PASSWORD
    CHANGEPASSWORD: 'PASSWORD DI CAMBIAMENTO',
    // ORIGINAL TEXT: CHANGE PASSWORD
    CANCELACCOUNT: 'CONTO DI CANCELLAMENTO',
    // ORIGINAL TEXT: CANCEL ACCOUNT
    QUICKSTARTVIDEO: "GUARDA IL VIDEO DELL'INIZIO VELOCE",
    // ORIGINAL TEXT: WATCH QUICK START VIDEO

    CHARGEBASIC: 'AGGIORNAMENTO DI BASE',
    // ORIGINAL TEXT: UPGRADE TO BASIC
    CHARGEPREMIUM: 'PREMIO DI PREMIO',
    // ORIGINAL TEXT: UPGRADE TO PREMIUM
    CHARGECORPORATE: 'AGGIORNAMENTO DEL CORPORATO',
    // ORIGINAL TEXT: UPGRADE TO CORPORATE
    UPGRADESUBSCRIPTION: "ABBONAMENTO DELL'UPGRADE",
    // ORIGINAL TEXT: UPGRADE SUBSCRIPTION
    GO: 'Vai',
    // ORIGINAL TEXT: GO
    PREVIEW_FEEDBACK: 'CAMBIAMENTI DI RIESAME',
    // ORIGINAL TEXT: REVIEW CHANGES
    ACTIONS: 'ATTO',
    // ORIGINAL TEXT: ACT
    REFRESH: 'REFRESH',
    // ORIGINAL TEXT: REFRESH
    ADDNEWGROUP: 'AGGIUNGI NUOVO GRUPPO',
    // ORIGINAL TEXT: ADD NEW GROUP
    ADDNEWDOCTYPE: 'ADD NUOVO DOCTYPE',
    // ORIGINAL TEXT: ADD NEW DOCTYPE
    ADDNEWALERT: 'ADD NEW ALERT',
    // ORIGINAL TEXT: ADD NEW ALERT
    GROUPSLIST: 'ELENCO DEI GRUPPO DI RIESAME',
    // ORIGINAL TEXT: REVIEW GROUP LIST

    DOWNLOADDOCUMENT: 'DOCUMENTO Download',
    // ORIGINAL TEXT: DOWNLOAD DOCUMENT

    DOCUMENTTITLE: 'Titolo del documento',
    // ORIGINAL TEXT: Document Title
    DOCUMENTSUBJECT: 'Oggetto del documento',
    // ORIGINAL TEXT: Document Subject
    SIGNMESSAGE: 'Messaggio per la firma',
    // ORIGINAL TEXT: Message for Signing
    NAMEPRIMARYSIGNER: 'Nome: Firmatario primario',
    // ORIGINAL TEXT: Name: Primary Signer
    EMAILPRIMARYSIGNER: 'Email: Firmatario primario',
    // ORIGINAL TEXT: Email: Primary Signer
    NAMECOUNTERSIGNER: 'Nome: Counter Signer',
    // ORIGINAL TEXT: Name: Counter Signer
    EMAILCOUNTERSIGNER: 'Email: Counter Signer',
    // ORIGINAL TEXT: Email: Counter Signer
    SIGREQUESTED: 'FIRMA RICHIESTA',
    // ORIGINAL TEXT: SIGNATURE REQUESTED
    SIGREQUESTEDBY: 'RICHIESTA DA:',
    // ORIGINAL TEXT: REQUESTED BY
    SIGREQUESTEDID: 'ID DELLA FIRMA',
    // ORIGINAL TEXT: SIGNATURE ID
    SIGREQUESTEDAT: 'RICHIESTO AL',
    // ORIGINAL TEXT: REQUESTED AT
    SUBMITSIG: 'PRESENTAZIONE DELLA FIRMA',
    // ORIGINAL TEXT: SUBMIT FOR SIGNATURE
    CANCELSIG: 'DOMANDA DI CANCELITÀ',
    // ORIGINAL TEXT: CANCEL SIGNATURE REQUEST

    // DATA LABELS
    NAME: 'NOME',
    // ORIGINAL TEXT: NAME
    EMAIL: 'EMAIL',
    // ORIGINAL TEXT: EMAIL
    STATUS: 'STATUS',
    // ORIGINAL TEXT: STATUS
    LASTVIEWED: 'ULTIMI VISTI',
    // ORIGINAL TEXT: LAST VIEWED
    LASTREVIEWED: 'ULTIMI RIESAME',
    // ORIGINAL TEXT: LAST REVIEWED
    SIGNEDAT: 'Firmato',
    // ORIGINAL TEXT: SIGNED
    OWNERAPPROVAL: 'OMOLOGAZIONE DEL PROPRIO',
    // ORIGINAL TEXT: OWNER APPROVAL
    OWNERACTION: 'AZIONE PROPRIA',
    // ORIGINAL TEXT: OWNER ACTION
    NEGOTIATORAPPROVAL: 'OMOLOGAZIONE NEGOZIATORE',
    // ORIGINAL TEXT: NEGOTIATOR APPROVAL
    NEGOTIATORACTION: 'AZIONE NEGATIVA',
    // ORIGINAL TEXT: NEGOTIATOR ACTION
    ARCHIVENOTES: "NOTE D'ARCHIVIO",
    // ORIGINAL TEXT: ARCHIVE NOTES
    EXECUTEDCONTRACT: 'ESECUZIONE',
    // ORIGINAL TEXT: EXECUTED
    EXECUTEDDATE: 'DATA DI ESECUZIONE',
    // ORIGINAL TEXT: EXECUTED DATE
    CONTRACTATTACHED: 'CONTRATTO ATTACATO',
    // ORIGINAL TEXT: CONTRACT ATTACHED
    STARTDATE: 'DATA DI Inizio',
    // ORIGINAL TEXT: START DATE
    ENDDATE: 'DATA DI FINE',
    // ORIGINAL TEXT: END DATE
    EDIT: 'EDIT',
    // ORIGINAL TEXT: EDIT
    USER: 'UTILIZZATORE',
    // ORIGINAL TEXT: USER
    TIME: 'TEMPO',
    // ORIGINAL TEXT: TIME
    CONFIRM: 'CONFERMA',
    // ORIGINAL TEXT: CONFIRM
    CONFIRMACTION: 'AZIONE CONFIRMA',
    // ORIGINAL TEXT: CONFIRM ACTION
    CURRENT_LABEL: 'ETICHETTA ATTUALE',
    // ORIGINAL TEXT: CURRENT LABEL
    NEW_LABEL: 'NUOVA ETICHETTA',
    // ORIGINAL TEXT: NEW LABEL
    DESCRIPTION: 'DESCRIZIONE',
    // ORIGINAL TEXT: DESCRIPTION
    TYPE: 'TIPO',
    // ORIGINAL TEXT: TYPE
    SENTENCE: 'CONDOTTA',
    // ORIGINAL TEXT: SENTENCE
    NUMBER: '#',
    // ORIGINAL TEXT: #
    PRIVILEGE: 'RUOLO',
    // ORIGINAL TEXT: ROLE
    OWNERID: "PROPRIETA'",
    // ORIGINAL TEXT: OWNERID
    DOCNAME: 'NOME DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT NAME
    DOCTYPE: 'TIPO DI DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT TYPE
    DOCDESCRIPTION: 'DESCRIZIONE DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT DESCRIPTION
    DOCNOTES: 'NOTE DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT NOTES
    DOCPARTY: 'PARTE',
    // ORIGINAL TEXT: PARTY
    DOCCOUNTERPARTY: 'PARTE CONTRAENTE',
    // ORIGINAL TEXT: COUNTER PARTY
    DOCRAWFILENAME: 'NOME DEL FILE DEL DOCUMENTO',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'TAGS',
    // ORIGINAL TEXT: TAGS
    COLLABORATIONSTATUS: 'STATUS DI COLLABORAZIONE',
    // ORIGINAL TEXT: COLLABORATION STATUS
    COLLABORATIONEND: 'FINE DELLA COLLABORAZIONE',
    // ORIGINAL TEXT: COLLABORATION END
    NEGOTIATIONSTATUS: 'STATO DI NEGOZIO',
    // ORIGINAL TEXT: NEGOTIATION STATUS
    NEGOTIATIONEND: 'FINE DELEGAZIONE',
    // ORIGINAL TEXT: NEGOTIATION END
    DOCID: 'ID DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT ID
    PARAGRAPHID: 'ID PARAGRAFIO',
    // ORIGINAL TEXT: PARAGRAPH ID
    ACTION: 'AZIONE ACCESSO',
    // ORIGINAL TEXT: LOGGED ACTION
    DETAIL: "DETTAGLIO DELL'AZIONE",
    // ORIGINAL TEXT: ACTION DETAIL
    CONTEXT: 'CONTESTO AGGIUNTO',
    // ORIGINAL TEXT: ADDITIONAL CONTEXT
    GROUPNAME: 'NOME DEL GRUPPO',
    // ORIGINAL TEXT: GROUP NAME
    GROUPDESC: 'DESCRIZIONE DEL GRUPPO',
    // ORIGINAL TEXT: GROUP DESCRIPTION
    DOCTYPEDESC: 'DESCRIZIONE DEL TIPO DI DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT TYPE DESCRIPTION
    ADDLCLAUSETYPES: 'ETICHETTE DELLA CLAUSOLA DI CUSTOM',
    // ORIGINAL TEXT: CUSTOM CLAUSE LABELS
    ADDLTAGS: 'TAGS DI CUSTOM',
    // ORIGINAL TEXT: CUSTOM TAGS
    COLLABORATIONRULES: 'REGOLE DI COLLABORAZIONE',
    // ORIGINAL TEXT: COLLABORATION RULES
    NEGOTIATIONRULES: 'REGOLE DI NEGOZIO',
    // ORIGINAL TEXT: NEGOTIATION RULES
    NOTIFICATIONRULES: 'REGOLE DI COMUNICAZIONE',
    // ORIGINAL TEXT: NOTIFICATION RULES
    LOG: 'LOG',
    // ORIGINAL TEXT: LOG
    ALERTNAME: "NOME DELL'ALLERTO",
    // ORIGINAL TEXT: ALERT NAME
    ALERTDESC: "DESCRIZIONE DELL'ALERTO",
    // ORIGINAL TEXT: ALERT DESCRIPTION
    TOPICS: 'PROBLEMI',
    // ORIGINAL TEXT: TOPICS
    RECIPIENTS: 'INDIRIZZI',
    // ORIGINAL TEXT: RECIPIENTS
    SELECTIVITY: 'SELECTIVITÀ',
    // ORIGINAL TEXT: SELECTIVITY
    FIELDNAME: 'NOME',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: 'ORDINE',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: 'ESEMPIO',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: 'PREDEFINITO',
    // ORIGINAL TEXT: DEFAULT
    VALUE: 'VALORE',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: 'DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: 'PLAYBOOK',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: 'MODELLO',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: 'DOCUMENTO ARCHIVIATO',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: 'ORIGINATO DAL MODELLO:',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: 'COLLABORAZIONE',
    // ORIGINAL TEXT: COLLABORATION
    NEGSHORT: 'NEGOZIO',
    // ORIGINAL TEXT: NEGOTIATION
    OWNERS: 'PROPRI',
    // ORIGINAL TEXT: OWNERS
    COLLABORATORS: 'COLLABORATORI',
    // ORIGINAL TEXT: COLLABORATORS
    NEGOTIATORS: 'NEGATORS',
    // ORIGINAL TEXT: NEGOTIATORS
    // ADDITION
    DOCUMENTDETAILS: 'DATI DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT DETAILS
    CREATIONDATE: 'DATA DI CREAZIONE',
    // ORIGINAL TEXT: CREATION DATE
    PARTICIPANTS: 'PARTECIPANTI',
    // ORIGINAL TEXT: PARTICIPANTS
    COLLABORATIONANDNEGOTIATION: 'COLLABORAZIONE E NEGOZIO',
    // ORIGINAL TEXT: COLLABORATION AND NEGOTIATION
    DOCTYPEANDGROUPACCESS: 'TIPO DI DOCUMENTO E ACCESSO DEL GRUPPO',
    // ORIGINAL TEXT: DOCUMENT TYPE AND GROUP ACCESS
    GROUPOWNERS: "PROPRIETA' DEL GRUPPO",
    // ORIGINAL TEXT: GROUP OWNERS
    GROUPCOLLABORATORS: 'COLLABORATORI DI GRUPPO',
    // ORIGINAL TEXT: GROUP COLLABORATORS
    DOCUMENTSETTINGS: 'STABILIMENTI DEI DOCUMENTI',
    // ORIGINAL TEXT: DOCUMENT SETTINGS
    ENABLECOLLABTOMODLABELS:
      'Abilita i collaboratori per modificare le etichette delle clausole',
    // ORIGINAL TEXT: Enable Collaborators to Modify Clause Labels
    ENABLENEGOTCAPABILITIES:
      'Abilita capacità di negoziazione (modalità non negoziazione)',
    // ORIGINAL TEXT: Enable Negotiation capabilities (Not Negotiation mode)
    KEEPDOCNOTES: "Copiare le note del documento con l'operazione di copia",
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS: 'Clausola rivista e modificata Temi come necessari',
    // ORIGINAL TEXT: Reviewed and Amended Clause Topics as Necessary
    NOEXECUTIONDATE: 'Nessuna data di esecuzione',
    // ORIGINAL TEXT: No Execution Date
    EXECUTIONDATE: 'Data di esecuzione',
    // ORIGINAL TEXT: Execution Date
    CONTRACTSTARTDATE: 'Data di inizio del contratto',
    // ORIGINAL TEXT: Contract Start Date
    CONTRACTENDDATE: 'Data di conclusione del contratto',
    // ORIGINAL TEXT: Contract End Date
    EXECCONTRACTNAME: 'Nome del file del contratto eseguito',
    // ORIGINAL TEXT: Executed Contract File Name
    SELECTEXECCONTRACT:
      'selezionare il file per caricare il contratto eseguito',
    // ORIGINAL TEXT: select file to upload executed contract
    UPLOADEXECUTEDCONTRACT: 'APPALTI ESEGUITI',
    // ORIGINAL TEXT: UPLOAD EXECUTED CONTRACT
    LOADDEFAULTS: 'DEFAULTI DI CARICO',
    // ORIGINAL TEXT: LOAD DEFAULTS
    CLOSED: 'CHIUSO',
    // ORIGINAL TEXT: CLOSED
    NONE: 'Nessuno',
    // ORIGINAL TEXT: NONE
    NA: 'NA',
    // ORIGINAL TEXT: NA
    RESULTEXECUTEDCONTRACT: "Risultato dell'esecuzione del contratto",
    // ORIGINAL TEXT: Resulted in Executed Contract
    NOSTARTDATE: 'Nessuna data di inizio',
    // ORIGINAL TEXT: No Start Date
    NOENDDATE: 'Nessuna data di fine',
    // ORIGINAL TEXT: No End Date
    EXCLUDECOLLABORATORSFROMACCESS:
      "Escludi collaboratori esterni dall'accesso al documento",
    // ORIGINAL TEXT: Exclude External Collaborators From Accessing Document
    EXCLUDENEGOTIATORSFROMACCESS:
      'Esclusi i negoziatori esterni dal documento di accesso',
    // ORIGINAL TEXT: Exclude External Negotiators From Accessing Document
    REVIEWEDAMENDEDCLAUSELABELS:
      'Etichette revisionate e modificate come necessarie',
    // ORIGINAL TEXT: Reviewed and Amended Clause Labels as Necessary
    ENTERPARAGRAPHBETWEEN: 'Inserire un numero di paragrafo tra:',
    // ORIGINAL TEXT: Enter a paragraph number between:
    GOTOPARAGRAPHINDEX: "Va all'indice del paragrafo",
    // ORIGINAL TEXT: Go to Paragraph Index
    INPUTDOCUMENTNOTEHERE: 'Inserisci documento Nota qui',
    // ORIGINAL TEXT: Input Document Note here
    INPUTCOMMENTHERE: 'Commento di input qui',
    // ORIGINAL TEXT: Input Comment here
    SHOWPENDINGONLY: 'Mostra solo in attesa',
    // ORIGINAL TEXT: Show Pending Only
    COLLABORATIONSETTING: 'SETTIMANA DI COLLABORAZIONE',
    // ORIGINAL TEXT: COLLABORATION SETTING
    NEGOTIATIONSETTING: 'NEGOZIO DI STABILIMENTO',
    // ORIGINAL TEXT: NEGOTIATION SETTING
    NONEGOTWHENAPPROVED:
      'Impossibile attivare la negoziazione quando nello Stato approvato',
    // ORIGINAL TEXT: Cannot turn on Negotiation when in Approved State
    EMAILTOADD: 'Email da aggiungere',
    // ORIGINAL TEXT: Email to add
    CLAUSETOADD: 'Clausola da aggiungere',
    // ORIGINAL TEXT: Clause to add
    TAGTOADD: 'Etichetta da aggiungere',
    // ORIGINAL TEXT: Tag to add
    OPTIONSFORREDLINEINCLUDE: 'Opzioni per le linee rosse da includere',
    // ORIGINAL TEXT: Options for Redlines to Include
    ONLYINCLUDEOWNERAPPROVEDEDITS:
      'Includi solo le modifiche del documento approvate dal proprietario',
    // ORIGINAL TEXT: Only Include Owner-approved Document Edits
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'Includi solo il proprietario e le modifiche del documento approvato dal negoziatore',
    // ORIGINAL TEXT: Only Include Owner and Negotiator-approved Document Edits
    INCLUDEALLDOCEDITS:
      "Includi tutte le modifiche del documento (indipendentemente dall'approvazione)",
    // ORIGINAL TEXT: Include All Document Edits (regardless of approval)
    REDLINECHANGESSELECTED: 'Modifiche selezionate in riga rossa',
    // ORIGINAL TEXT: Redlined Changes Selected
    TOTALCHANGES: 'Totale modifiche',
    // ORIGINAL TEXT: Total Changes
    REPLACEREDLINES: 'Sostituisci linee rosse',
    // ORIGINAL TEXT: Replace Redlines
    DELETEREDLINES: 'Elimina linee rosse',
    // ORIGINAL TEXT: Delete Redlines
    ADDAFTERREDLINES: 'Add-After Redlines',
    // ORIGINAL TEXT: Add-After Redlines
    OPTIONSFORPREVREDLINES: 'Opzioni per linee rosse precedenti',
    // ORIGINAL TEXT: Options for Previous Redlines
    OVERWRITEPREVREDLINES:
      'Sovrascrivi tutte le righe rosse precedenti nel sorgente del documento',
    // ORIGINAL TEXT: Overwrite all Previous Redlines in Document Source
    KEEPPREVREDLINES:
      'Mantieni tutte le righe rosse precedenti nella sorgente del documento',
    // ORIGINAL TEXT: Keep all Previous Redlines in Document Source
    GENERATEFINALDOC: 'Genera documento finale (senza linee rosse)',
    // ORIGINAL TEXT: Generate Final Document (without Redlines)
    DEFAULTESIGNOTE:
      'Come discusso, si prega di fornire la firma al presente contratto.',
    // ORIGINAL TEXT: As discussed, please provide your eSignature to this contract.
    SUBMITTEDSIGMESSAGE: 'Presentato per le firme, attendere.',
    // ORIGINAL TEXT: Submitted for signatures.  Please wait.
    UPLOADEDMESSAGE: 'Caricato',
    // ORIGINAL TEXT: Uploaded
    ADDADDLFILES:
      'Facoltativo - Aggiunta di file aggiuntivi alla richiesta di firma',
    // ORIGINAL TEXT: Optional - Adding additional files to signature request
    ADDFILESMSG: 'aggiungere file aggiuntivi per la firma',
    // ORIGINAL TEXT: add additional files for signature
    ESIGPREPAIDPLANS:
      'La funzione di firma elettronica è disponibile solo tramite i nostri piani prepagati.',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      'Contattaci a billing@mylegaleinstein.com per maggiori dettagli.',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: 'Altri video al ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'Il tipo di documento non è supportato. MLE accetta solo i formati docx e pdf.',
    // ORIGINAL TEXT: Document type is not supported.  MLE only accepts docx and pdf formats.
    PDFSUPPORTEDFORPREMIUMONLY:
      "Per la scansione PDF, l'utente ha bisogno di aggiornare a Premium abbonamento o meglio.",
    // ORIGINAL TEXT: For PDF scanning, user needs to upgrade to Premium subscription or better.
    BADDOCFORMAT:
      "Il formato del documento errato è stato passato all'elaborazione del documento.",
    // ORIGINAL TEXT: Bad Document format was passed to document processing.
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      'Non hai il privilegio di apportare modifiche alle Impostazioni del documento. Devi essere un proprietario.',
    // ORIGINAL TEXT: You do not have the privilege to make changes to the Document Settings.  You are required to be an Owner.
    PRIVILEGSNOTAVAIL:
      'I privilegi per questo utente non sono disponibili. Riprovare più tardi.',
    // ORIGINAL TEXT: The privileges for this user are not available.  Try again later.
    EXHAUSTEDTRIALLICENSE:
      "Hai esaurito il numero di documenti permessi durante il periodo di prova. Seleziona 'Upgrade Iscriviti' nel tuo profilo.",
    // ORIGINAL TEXT: You have exhausted the number of allowed documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    EXHAUSTEDMONTHLYLIMIT:
      "Hai esaurito il numero di documenti mensili ammessi durante il periodo di prova. Seleziona 'Upgrade Iscriviti' nel tuo profilo.",
    // ORIGINAL TEXT: You have exhausted the number of allowed monthly documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    PASSWORDNOTMATCHING:
      'Le password non corrispondono. Controlla di nuovo le password.',
    // ORIGINAL TEXT: Passwords do not match.  Check the passwords again.
    ABAMEMBERIDLENGTH:
      'Gli ID ABA Membership sono lunghi 7 caratteri. Si prega di recensire e inviare.',
    // ORIGINAL TEXT: ABA Membership IDs are 7 characters long.  Please review and submit.
    TERMSNOTCHECKED:
      'I Termini e le Condizioni non sono stati rivisti e controllati.',
    // ORIGINAL TEXT: The Terms and Conditions have not been reviewed and checked.
    EMAILFORMATBAD:
      "Il formato email non è valido. Rivedere l'indirizzo email.",
    // ORIGINAL TEXT: Email format is not valid.  Please review email address.
    CANCELEDACTION:
      "L'ultima azione è stata annullata. L'applicazione verrà ricaricata.",
    // ORIGINAL TEXT: Your last action have been canceled.  Application will now reload.
    SUCCESSACTION:
      "La tua ultima azione ha avuto successo. L'applicazione verrà ricaricata.",
    // ORIGINAL TEXT: Your last action has been successful.  Application will now reload.
    USERSUCCESSLOGIN: "L'utente ha effettuato l'accesso con successo.",
    // ORIGINAL TEXT: User has successfully logged on.
    USERLOGGEDOUT: "L'utente è stato disconnesso",
    // ORIGINAL TEXT: The user has been logged out
    EMAILNOTVERIFIED:
      "L'email dell'account non è ancora stata verificata. Ti è stata inviata una verifica dell'email.",
    // ORIGINAL TEXT: Account email has not yet been verified.  An email verification has been sent to you.
    EMAILALREADYREGISTERED:
      'Questa email ha già un account precedentemente registrato.',
    // ORIGINAL TEXT: This email already has a previously registered account.
    EMAILNOTVERIFIEDRESENT:
      'La tua email non è stata verificata. Ti abbiamo inviato un link di verifica email alla tua email per attivare il nuovo account.',
    // ORIGINAL TEXT: Your email has not been verified.  We have resent you an email verification link to your email to activate the new account.
    USERLOGFILENOTEXISTS: 'Errore: nessun file utente di log esiste.',
    // ORIGINAL TEXT: Error - no log user file exists.
    PASSWORDRESETSENT:
      'Per il tuo account è stato inviato un messaggio di reset della password.',
    // ORIGINAL TEXT: An email password reset message has been sent for your account.
    PASSWORDCHANGED: 'La tua password è stata cambiata.',
    // ORIGINAL TEXT: Your password has been changed.
    REGISTRATIONDELETED: 'registrazione utente cancellata',
    // ORIGINAL TEXT: deleted user registration
    USERACCOUNTUPDATED:
      'Hai aggiornato le impostazioni del tuo account utente. Le modifiche entreranno in vigore la prossima volta che fai il login.',
    // ORIGINAL TEXT: You have updated your user account settings.  Changes will take into effect the next time you login.
    UPGRADEDTOREFERRED:
      "Il tuo account è stato aggiornato all'abbonamento Refered Trial.",
    // ORIGINAL TEXT: Your account has been updated to Referred Trial subscription.
    REGISTEREDTRIALUSER:
      'Nuovo account è registrato come abbonamento di prova.',
    // ORIGINAL TEXT: New account is registered as Trial subscription.
    DELETEDUSERACCOUNT: 'Conto profilo utente eliminato',
    // ORIGINAL TEXT: Deleted user profile account
    EMAILALREADYREGISTEREDRESENT:
      "L'email non è già stata registrata con Docgility. Un invito email è stato inviato al nuovo utente.",
    // ORIGINAL TEXT: Email has not already been registered with Docgility.  An email invitation has been sent to the new user.

    UPGRADEEXTERNALPAYMENTS:
      "Grazie per l'aggiornamento. Sarete reindirizzati a un servizio di pagamento esterno e dopo aver completato la transazione, sarà necessario effettuare nuovamente il login per l'avvio del servizio di abbonamento.",
    // ORIGINAL TEXT: Thanks for upgrading.  You will be rerouted to an external payment service and after completing your transaction, you will need to login again for subscription service to start.
    BILLINGEXTERNALPAYMENTS:
      'Si prega di confermare che si desidera essere indirizzati al nostro servizio di pagamento esterno e fatturazione. Dopo aver apportato eventuali modifiche, sarà necessario effettuare nuovamente il login per le modifiche per entrare in vigore.',
    // ORIGINAL TEXT: Please confirm that you will like to be directed to our external payment and billing service.  After you make any changes, you will need to login again for changes to take effect.
    NOTABLETOLOGINATTHISTIME:
      'Non sono in grado di effettuare il login in questo momento. Riprova più tardi.',
    // ORIGINAL TEXT: Not able to login at this time.  Try again later.
    NOTREGISTERED:
      "L'utente non si è registrato in precedenza. Registrarsi prima di effettuare il login.",
    // ORIGINAL TEXT: User has not previously registered.  Register before trying to login.
    NAMETOOLONG: 'Il nome che hai inserito è troppo lungo. Max è 60 caratteri.',
    // ORIGINAL TEXT: The name you entered is too long.  Max is 60 characters.
    COMPANYTOOLONG:
      'La compagnia in cui sei entrato è troppo lunga. Max è 60 caratteri.',
    // ORIGINAL TEXT: The company you entered is too long.  Max is 60 characters.
    COMMENTTOOLONG:
      'I commenti che hai inserito sono troppo lunghi. Max è 10000 caratteri.',
    // ORIGINAL TEXT: The comments you entered is too long.  Max is 10000 characters.
    DOCEDITTOOLONG:
      'Il documento che hai inserito è troppo lungo. Max è 10000 caratteri.',
    // ORIGINAL TEXT: The document edits you entered is too long.  Max is 10000 characters.
    TITLETOOLONG:
      'Il titolo che hai inserito è troppo lungo. Max è 60 caratteri.',
    // ORIGINAL TEXT: The title you entered is too long.  Max is 60 characters.
    PASSWORDTOOSHORT:
      'La password inserita è troppo breve. La password deve essere almeno 6 caratteri.',
    // ORIGINAL TEXT: The password you entered is too short.  Password has to be at least 6 characters.
    CONGRATSNEWDOCUMENTPROCESSING:
      "Grande! Hai elaborato un nuovo documento. Ci vogliono alcuni minuti e il pannello dovrebbe aggiornare quando il documento è completato l'elaborazione. Attendere alcuni minuti.",
    // ORIGINAL TEXT: Great! You have processed a new document.  It takes a few minutes and the panel should refresh when the document is completed processing.  Please wait a few minutes.

    // ADDITION
    NODOCUMENTLOADED: 'Nessun documento caricato',
    // ORIGINAL TEXT: No document loaded
    SELECTFILETOUPLOADDOCX: 'selezionare il file da caricare (*.docx)',
    // ORIGINAL TEXT: select file to upload (*.docx)
    SELECTFILETOUPLOADDOCXPDF:
      'selezionare il file da caricare (*.docx, *.pdf)',
    // ORIGINAL TEXT: select file to upload (*.docx, *.pdf)
    INPUTCOMMENTHERE: 'Commento input qui',
    // ORIGINAL TEXT: Input comment here
    AUTOAPPROVEASOWNER: 'Autoapprovazione come proprietario',
    // ORIGINAL TEXT: Auto Approve as Owner
    INPUTDOCEDITSHERE: 'Modifica documento di input qui',
    // ORIGINAL TEXT: Input Document Edit here
    TRIGGEREDALLALERTS:
      'Attivati tutti (se presenti) gli avvisi per questo documento doctype.',
    // ORIGINAL TEXT: Triggered all (if any) Alerts for this doctype document.
    QSINTROMESSAGE:
      'MyLegalEinstein analizza istantaneamente i tuoi contratti, gli indici per argomenti legali e consente confronti di linguaggio legale affiancati in una piattaforma completa di collaborazione contrattuale.',
    // ORIGINAL TEXT: MyLegalEinstein instantly analyzes your contracts, indexes by legal topics, and allows side-by-side comparisons of legal language in a complete contract collaboration platform. We will demonstrate the capabilities in four simple steps (detailed instructions are provided in english but similar in all languages.)
    QSDOCMESSAGE:
      'Per vostra comodità, ecco alcuni contratti di pubblico dominio che potete scaricare e provare:',
    // ORIGINAL TEXT: For your convenience, here are some public domain contracts that you can download and try:
    QSHELPMESSAGE:
      'Ecco un comodo link alla documentazione del prodotto (con la versione verbosa di Quick Start)',

    DOCUMENTFAILEDTOPROCESS:
      "Si è verificato un errore fatale durante l'elaborazione del documento. Riprovare. Se il problema persiste, contattare l'assistenza clienti all'indirizzo support@mylegaleinstein.com.",
    // ORIGINAL TEXT: A fatal error occurred while processing the document.  Please try again.  If problem persists, please contact customer support at support@mylegaleinstein.com.

    // ADDED
    DOCUMENTSUCCESSCOPIED: 'Il tuo documento è stato copiato.',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      'Una versione pulita di questo documento è stata generata e salvata.',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      'Un documento è stato generato da questo modello e salvato.',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE:
      'Un documento è stato convertito in un modello di documento.',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      "Si prega di confermare che si desidera eliminare il documento specificato. Dopo l'eliminazione del documento, esso non sarà più disponibile. Si può anche considerare la possibilità di spostare il documento allo stato dell'archivio. \n\n Si prega di confermare l'operazione di cancellazione.",
    // ORIGINAL TEXT: Please confirm that you want to delete the specified document.  After document is deleted, it will no longer be available.  You may also consider moving the document to Archive status.  \n\n Please confirm delete operation.
    CONFIRMSAVETOPLAYBOOK:
      "Si prega di confermare che per convertire questo documento in una playbook. Ciò renderebbe questo documento irraggiungibile ad eccezione del proprietario. \n\n Si prega di confermare l'operazione salva in playbook.",
    // ORIGINAL TEXT: Please confirm that to convert this document to a playbook.  This would make this document unchangable except for the owner.  \n\n Please confirm the save to playbook operation.
    COLLABORATIONNOTE:
      "NOTA: Queste informazioni sono condivise ALL'INTERNO SOLAMENTE con collaboratori e proprietari di documenti designati.",
    // ORIGINAL TEXT: NOTE: This information is shared INTERNALLY ONLY with designated collaborators and document owners only.
    NEGOTIATIONNOTE:
      "NOTA: Queste informazioni sono condivise dall'esterno con le controparti negoziali.",
    // ORIGINAL TEXT: NOTE: This information is shared EXTERNALLY with negotiation counter parties.
    CONFIRMDELETEALERT:
      "Si prega di confermare che si desidera eliminare l' avviso specificato. Dopo l' avviso viene eliminato, non sarà più disponibile. Si prega di confermare l' operazione di cancellazione.",
    // ORIGINAL TEXT: Please confirm that you want to delete the specified alert.  After alert is deleted, it will no longer be available.  Please confirm delete operation.
    CONFIRMDELETERECIPIENTALERT:
      "Si prega di confermare che non si desidera più essere un destinatario per l'allerta specificata. Dopo aver rimosso te stesso come destinatario, è possibile richiedere di essere aggiunto indietro contattando il proprietario dell'allerta. Si prega di confermare l'operazione di cancellazione.",
    // ORIGINAL TEXT: Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.
    CONFIRMDELETEGROUP:
      "Si prega di confermare che si desidera eliminare il gruppo specificato. Dopo che il gruppo viene eliminato, non sarà più disponibile. Tuttavia, rifletterà la prossima volta che si effettua il login. Confermare l'operazione di cancellazione.",
    // ORIGINAL TEXT: Please confirm that you want to delete the specified group.  After group is deleted, it will no longer be available.  However, it will reflected the next time you log back in.  Please confirm delete operation.
    CONFIRMDELETECOLLABORATORGROUP:
      "Si prega di confermare che non si desidera più essere un collaboratore per il gruppo specificato. Dopo aver rimosso te stesso come collaboratore, è possibile richiedere di essere aggiunto indietro contattando il proprietario del gruppo. Si prega di confermare l'operazione di cancellazione.",
    // ORIGINAL TEXT: Please confirm that you no longer want to be a collaborator for the specified group.  After removing yourself as a collaborator, you can request to be added back by contacting the owner of the group.  Please confirm delete operation.
    CONFIRMDELETEDOCTYPE:
      "Confermare che si desidera eliminare il doctype specificato. Dopo che il doctype viene eliminato, non sarà più disponibile. Confermare l'operazione di cancellazione.",
    // ORIGINAL TEXT: Please confirm that you want to delete the specified doctype.  After doctype is deleted, it will no longer be available.  Please confirm delete operation.
    EXPIREDTRIAL:
      'La licenza di prova è scaduta. Si prega di aggiornare per continuare il servizio.',
    // ORIGINAL TEXT: Your trial license has expired.  Please upgrade to continue your service.
    EXPIREDSUBSCRIPTION:
      'La tua licenza di abbonamento è scaduta. Ti preghiamo di aggiornare per continuare il servizio o contattare la fatturazione presso Docgility.',
    // ORIGINAL TEXT: Your subscription license has expired.  Please upgrade to continue your service or contact billing at Docgility.
    SOURCEDOCNOTDOCX:
      'Il documento sorgente non è un documento DOCX. Le funzionalità Redline non sono disponibili.',
    // ORIGINAL TEXT: The Source document is not a DOCX document.  Redline capabilities are not available.
  },
}

export default IT
