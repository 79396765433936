<script>
import { docroute } from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },

    // FYI - Need to pass each field in individually - VuejS objects to mutability of objects and arrays.
    docname: {
      type: String,
      required: true,
    },
    docdescription: {
      type: String,
      required: true,
    },
    originaltext: {
      type: Object,
      default: () => {},
    },
    labelinputdata: {
      type: Array,
      default: () => [],
    },
  },

  data: function() {
    return {
      // this never works to pass defaults to local variables/
      // previously, this was just saved in the form, it didn't necessarily retrieve it.
      // when this instantiated, it needs to populated with the right defaults for the panel.
      // save operation works but not the local copy to a default variable.

      // this does not seem to work at all to set the initial defaults.  instead, use setInitialValues()
      ldocname: this.docname,
      ldocdescription: this.docdescription,

      llabelinputdata: [],

      // validation rules for data entry
      rules: {
        required: (value) => !!value || this.$t('VALIDMSGS.REQUIRED'),
        counter80: (value) =>
          value.length <= 80 || this.$t('VALIDMSGS.MAX80CHARS'),
        counter160: (value) =>
          value.length <= 160 || this.$t('VALIDMSGS.MAX160CHARS'),
      },
      valid: false, // check for validation rules
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
    cdocname() {
      return this.docname
    },
    paraswithlabels() {
      var returnArray = []
      const regex = '\\{\\{\\{[a-zA-Z0-9]*\\}\\}\\}'

      for (const key in this.originaltext) {
        // if (this.originaltext[key].OT.includes('{{{')) {
        const text = this.originaltext[key].OT
        if (this.originaltext[key].OT.match(regex)) {
          const arrayofmatches = [...text.matchAll(regex)]

          // get the values if this is found:
          for (const item of arrayofmatches) {
            returnArray.push({ paraid: key, label: item[0] })
          }
        }
      }
      return returnArray
    },

    uniquelabels() {
      var returnArray = []
      var indexArray = []
      for (const item of this.paraswithlabels) {
        const label = item.label.replace('{{{', '').replace('}}}', '')
        if (!indexArray.includes(label)) {
          returnArray.push({ name: label, para: [item.paraid] })
          indexArray.push(label)
        } else {
          const position = indexArray.findIndex((f) => f === label)
          // ensures uniqueness of paraid in case of multiple occurrences of the string in para.
          // keep the different values because it may be needed to address the multiple changes in the paragrpah
          // each replace is for each string.
          // if (!returnArray[position].para.includes(item.paraid)) {
          returnArray[position].para.push(item.paraid)
          // }
        }
      }
      return returnArray
    },
  },
  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  // for some reason, values are passed correctly, but values are not always passed to local variables.
  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
  },
  methods: {
    setInitialValues() {
      this.ldocname = this.docname
      this.ldocdescription = this.docdescription

      this.populatelabeldata()
    },

    populatelabeldata() {
      var returnArray = []

      // loads a blank version based on the uniquelabels

      for (const item of this.uniquelabels) {
        // if item.name
        const position = this.labelinputdata.findIndex(
          (f) => f.name === item.name
        )

        if (position >= 0) {
          returnArray.push({
            name: item.name,
            paras: item.para,
            description: this.labelinputdata[position].description,
            example: this.labelinputdata[position].example,
            default: this.labelinputdata[position].default,
          })
        } else {
          returnArray.push({
            name: item.name,
            paras: item.para,
            description: '',
            example: '',
            default: '',
          })
        }
      }
      this.llabelinputdata = returnArray
    },

    moveup(index) {
      var newllabelinputdata = JSON.parse(JSON.stringify(this.llabelinputdata))
      newllabelinputdata[index - 1] = this.llabelinputdata[index]
      newllabelinputdata[index] = this.llabelinputdata[index - 1]
      this.llabelinputdata = newllabelinputdata
    },

    movedown(index) {
      var newllabelinputdata = JSON.parse(JSON.stringify(this.llabelinputdata))
      newllabelinputdata[index + 1] = this.llabelinputdata[index]
      newllabelinputdata[index] = this.llabelinputdata[index + 1]
      this.llabelinputdata = newllabelinputdata
    },

    saveTemplate() {
      const formdata = {
        docname: this.ldocname,
        docdescription: this.ldocdescription,
        labelinputdata: this.llabelinputdata,
      }

      this.$emit('save-template', formdata)

      this.showDialog = false
    },
    docroute(d) {
      return docroute(d)
    },

    setparagraphhighlight(index) {
      this.$emit('processnavall', index)
      this.showDialog = false
      // this unsyncs panel, sets topic to ALL, sets paragraph highlight
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1200px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.SAVEASTEMPLATE')"
        ></PanelCardTitle>
        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="ldocname"
                    :rules="[rules.required, rules.counter80]"
                    :label="$t('DIALOGS.DOCNAME')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldocdescription"
                    :rules="[rules.counter160]"
                    :label="$t('DIALOGS.DOCDESCRIPTION')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <div class="ma-6">
          <v-form>
            <v-container fluid>
              <v-row justify="center">
                <v-simple-table :class="$style.dialogTable" fixed-header>
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                      fixed-header
                    >
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.FIELDNAME') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.ORDER') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.PARAGRAPHID') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.DESCRIPTION') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          >{{ $t('DIALOGS.EXAMPLE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          >{{ $t('DIALOGS.DEFAULT') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="(item, index) in llabelinputdata"
                        :key="item.name"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus">{{
                          item.name
                        }}</td>
                        <td :class="$style.dialogCellWidth"
                          ><v-btn v-if="index > 0" small @click="moveup(index)">
                            <v-icon>mdi-chevron-up</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="index < llabelinputdata.length - 1"
                            small
                            @click="movedown(index)"
                          >
                            <v-icon>mdi-chevron-down</v-icon>
                          </v-btn>
                        </td>
                        <td :class="$style.dialogCellWidth">
                          <div v-for="(paraid, indx) in item.paras" :key="indx"
                            ><v-btn
                              x-small
                              text
                              outlined
                              class="py-5"
                              @click="setparagraphhighlight(paraid)"
                              >{{ paraid }}</v-btn
                            ></div
                          >{{ item.para }}</td
                        >

                        <td :class="$style.dialogCellWidth"
                          ><v-text-field
                            v-model="item.description"
                            :rules="[rules.required, rules.counter160]"
                            >{{ item.description }}</v-text-field
                          ></td
                        >
                        <td :class="$style.dialogCellStatus"
                          ><v-text-field
                            v-model="item.example"
                            :rules="[rules.required, rules.counter80]"
                            >{{ item.example }}</v-text-field
                          ></td
                        >
                        <td :class="$style.dialogCellStatus"
                          ><v-text-field
                            v-model="item.default"
                            :rules="[rules.required, rules.counter80]"
                            >{{ item.default }}</v-text-field
                          ></td
                        >
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('file/saveastemplate')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            large
            outlined
            :disabled="!valid"
            class="mx-4"
            @click="saveTemplate"
            >{{ $t('DIALOGS.SAVE') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
