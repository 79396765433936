import {
  isArray,
  isObject,
  isString,
  map,
  mapKeys,
  camelCase,
  snakeCase,
} from 'lodash'

export function camelCaseRecursive(obj) {
  if (isArray(obj)) {
    return map(obj, camelCaseRecursive)
  }
  if (isObject(obj)) {
    const newObj = mapKeys(obj, (_, key) => camelCase(key))
    Object.keys(newObj).forEach((key) => {
      if (isObject(newObj[key])) {
        newObj[key] = camelCaseRecursive(newObj[key])
      }
    })
    return newObj
  }
  if (isString(obj)) {
    return camelCase(obj)
  }
  return obj
}

export function snakeCaseRecursive(obj) {
  if (isArray(obj)) {
    return map(obj, snakeCaseRecursive)
  }
  if (isObject(obj)) {
    const newObj = mapKeys(obj, (_, key) => snakeCase(key))
    Object.keys(newObj).forEach((key) => {
      if (isObject(newObj[key])) {
        newObj[key] = snakeCaseRecursive(newObj[key])
      }
    })
    return newObj
  }
  if (isString(obj)) {
    return snakeCase(obj)
  }
  return obj
}
