// FIREBASE
import { db } from '@/src/dbconfig/FBconfig.js'

import store from '@state/store'

import {
  doc,
  getDoc,
  collection,
  addDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore'

// import DocumentProcessingService from '@services/DocumentProcessingService'

import AIHUBDocumentService from '@services/AIHUBDocumentService'

const DBFIREBASE = process.env.VUE_APP_DB === 'FIREBASE'

// MONGO
const DBMONGO = process.env.VUE_APP_DB === 'MONGO'

function formatMongoData(data) {
  var objlist = []
  for (var key in data.data) {
    objlist.push(data.data[key])
  }
  return objlist
}

// HUB CALLS
export function DBHUBgetdocmetadata(id) {
  // console.log('DBHUBgetdocmetadata')
  // console.log(id)
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const docRef = doc(db, 'docmeta', id)
      getDoc(doc(db, tablename, id)).then(function (doc) {
        if (doc.exists()) {
          resolve({ exists: doc.exists(), data: doc.data(), id: doc.id })
        }
        resolve({ exists: false })
      })
    })
  }

  // Mongo
  // changed access for Mongo to be with objid for objs in TABLESWITHID
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      // Changed to call the new AIHUBDocumentService for the HUB
      AIHUBDocumentService.HUBgetdocmetadata({
        id: id,
      }).then(function (data) {
        if (Object.keys(data.data).length === 0) {
          resolve({ exists: false })
        }
        resolve({ exists: true, data: data.data, id: data.data['_id'] })
      })
      // }
    })
  }
}

export function DBHUBgetdocumentdata(id) {
  // console.log('DBHUBgetdocumentdata')
  // console.log(id)
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const docRef = doc(db, 'documents', id)
      getDoc(doc(db, 'documents', id)).then(function (doc) {
        if (doc.exists()) {
          resolve({ exists: doc.exists(), data: doc.data(), id: doc.id })
        }
        resolve({ exists: false })
      })
    })
  }

  // Mongo
  // changed access for Mongo to be with objid for objs in TABLESWITHID
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      // Changed to call the new AIHUBDocumentService for the HUB
      AIHUBDocumentService.HUBgetdocumentdata({
        id: id,
      }).then(function (data) {
        if (Object.keys(data.data).length === 0) {
          resolve({ exists: false })
        }
        resolve({ exists: true, data: data.data, id: data.data['_id'] })
      })
      // }
    })
  }
}

// supports comments, docedits, and tags
export function DBHUBadddocchange(tablename, obj) {
  // console.log('DBHUBadddocchange')
  // console.log(tablename)
  // console.log(obj)
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      // dbtable.add(obj)

      addDoc(collection(db, tablename), obj).then(function (doc) {
        resolve({ id: doc.id })
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      AIHUBDocumentService.HUBadddocchange({
        tablename: tablename,
        obj: obj,
      }).then(function (data) {
        resolve({ id: data.data['id'] })
      })
    })
  }
}

export function DBHUBdocmetaupdate(id, modobj) {
  // console.log('DBHUBdocmetaupdate')
  // console.log(id)
  // console.log(modobj)
  // Firebase
  if (DBFIREBASE) {
    const docRef = doc(db, 'docmeta', id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    AIHUBDocumentService.HUBdocmetaupdate({
      id: id,
      modobj: modobj,
    })
  }
}

export function DBHUBdoceditapproverejectchange(docid, id, modobj) {
  // console.log('DBHUBdoceditapproverejectchange')
  // console.log(docid)
  // console.log(id)
  // console.log(modobj)
  // Firebase
  if (DBFIREBASE) {
    const docRef = doc(db, 'docedit', id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    AIHUBDocumentService.HUBdoceditapproverejectchange({
      docid: docid,
      id: id,
      modobj: modobj,
    })
  }
}

export function DBHUBdeletedocchange(tablename, obj, id) {
  // console.log('DBHUBdeletedocchange')
  // console.log(tablename)
  // console.log(obj)
  // console.log(id)
  // Firebase
  if (DBFIREBASE) {
    const docRef = doc(db, tablename, id)
    return updateDoc(docRef, {
      active: false,
      delete: new Date(),
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      AIHUBDocumentService.HUBdeletedocchange({
        tablename: tablename,
        obj: obj,
        id: id,
      }).then(() => resolve())
    })
  }
}

export function DBHUBgetListenerComments(docid) {
  // console.log('DBHUBgetListenerComments')
  // Mongo only
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      AIHUBDocumentService.HUBgetListenerComments({
        docid: docid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBHUBgetListenerDocEdits(docid) {
  // console.log('DBHUBgetListenerDocEdits')
  // Mongo only
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      AIHUBDocumentService.HUBgetListenerDocEdits({
        docid: docid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBHUBgetListenerTags(docid) {
  // console.log('DBHUBgetListenerTags')
  // Mongo only
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      AIHUBDocumentService.HUBgetListenerTags({
        docid: docid,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

// only used for the adding of notes to the document.
export function DBHUBDocMetaAddNotes(id, field, value) {
  // console.log('DBHUBDocMetaAddNotes')
  // console.log(id)
  // console.log(field)
  // console.log(value)
  // Firebase
  if (DBFIREBASE) {
    // const dbtable = db.collection(tablename)

    var modobj = {}
    modobj[field] = arrayUnion(value)

    const docRef = doc(db, 'docmeta', id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    AIHUBDocumentService.HUBDocMetaAddNotes({
      id: id,
      field: field,
      value: value,
    })
  }
}

export function DBHUBDocMetaParticipantsAdd(id, field, value) {
  // console.log('DBHUBDocMetaParticipantsAdd')
  // console.log(id)
  // console.log(field)
  // console.log(value)
  // Firebase
  if (DBFIREBASE) {
    var modobj = {}
    modobj[field] = arrayUnion(value)

    const docRef = doc(db, 'docmeta', id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }

  // Mongo
  if (DBMONGO) {
    AIHUBDocumentService.HUBDocMetaParticipantsAdd({
      id: id,
      field: field,
      value: value,
    })
  }
}

export function DBHUBDocMetaParticipantsRemove(id, field, value) {
  // console.log('DBHUBDocMetaParticipantsRemove')
  // console.log(id)
  // console.log(field)
  // console.log(value)
  // Firebase
  if (DBFIREBASE) {
    var modobj = {}
    modobj[field] = arrayRemove(value)

    const docRef = doc(db, 'docmeta', id)
    updateDoc(docRef, modobj).catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
  }
  // Mongo
  if (DBMONGO) {
    AIHUBDocumentService.HUBDocMetaParticipantsRemove({
      id: id,
      field: field,
      value: value,
    })
  }
}

// only used to query for available documents.  Keep active for now, until we create ways to query for available documents.
// utility functions for queries
function formatFBquerySnapshot(querySnapshot) {
  var objlist = []
  querySnapshot.forEach((doc) => {
    var data = doc.data()
    data._id = doc.id
    objlist.push(data)
  })
  return objlist
}

export function DBdocmetaquery4(doctypeid, doccategory) {
  // doccategory can be either 'STANDARD' (4), 'ARCHIVE' (6), 'PLAYBOOK' (10), 'TEMPLATE' (12)
  // Firebase
  if (DBFIREBASE) {
    return new Promise(function (resolve, reject) {
      const query1 = query(
        collection(db, 'docmeta'),
        where('doctypeid', '==', doctypeid),
        where('doccategory', '==', doccategory),
        where('active', '==', true)
      )

      getDocs(query1).then(function (querySnapshot) {
        resolve(formatFBquerySnapshot(querySnapshot))
      })
    })
  }

  // Mongo
  if (DBMONGO) {
    return new Promise(function (resolve, reject) {
      AIHUBDocumentService.HUBdocmetaquery4({
        doctypeid: doctypeid,
        doccategory: doccategory,
      }).then(function (data) {
        resolve(formatMongoData(data))
      })
    })
  }
}

export function DBHUBdocmetaquery4(doctypeid, doccategory) {
  // console.log('DBHUBdocmetaquery4 - calls DBdocmetaquery4 for now')
  return DBdocmetaquery4(doctypeid, doccategory)
}
