import store from '@state/store'

import SnackService from '@/src/services/SnackService'

import DocumentProcessingService from '@services/DocumentProcessingService'

import { DBsetone, DBgetobjsync } from '@/src/state/io/iodb.js'

const REFERREDTABLENAME = 'referred'
const USERSTABLENAME = 'users'

export const state = {
  dbuserinfocache: [],
}

// do I need to add getters here?
export const getters = {
  dbuserinfocache(state) {
    return state.dbuserinfocache
  },
}

export const mutations = {
  INIT_USEDB(state) {
    state.dbuserinfocache = []
  },

  ADD_USERINFO(state, val) {
    state.dbuserinfo.push(val)
  },
}

export const actions = {
  init({ commit }) {
    commit('INIT_USEDB')
  },

  addEmailReferralIfNotRegisteredUserInfo({ commit, state }, payload) {
    DBgetobjsync(USERSTABLENAME, payload.referralid).then(function (doc) {
      if (!doc.exists) {
        store
          .dispatch('dbuserinfo/addEmailtoReferralUserInfo', payload)
          .then(function (obj) {
            DocumentProcessingService.BATCHreferralemailsendbatch({})
          })
      }
    })
  },

  addEmailtoReferralUserInfo({ commit, state }, payload) {
    DBsetone(REFERREDTABLENAME, payload.referralid, {
      referralid: payload.referralid,
      name: payload.name,
      uniqid: payload.uniqid,
      docid: payload.docid,
      docname: payload.docname,
      docdescription: payload.docdescription,
      privilege: payload.privilege,
      create: new Date(),
      status: 'active',
    })

    SnackService.info('MESSAGES.EMAILALREADYREGISTEREDRESENT')
  },
}
