<script>
import PanelCardTitle from './panel-cardtitle.vue'

// state
import { panelComputed, dbauthComputed } from '@state/helpers'

import { docroute, displayUserName, comparePara } from '@utils/dialogutils'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    eligibledocxin: {
      type: Boolean,
      required: true,
    },
  },

  data: function () {
    return {
      valid: false,

      TWOBLANKS: '  ',
      REDLINEAREA: 'redlines',

      selected: 'OwnerAndNegotiator',
      overwriteprevious: 'previous',

      IOstringdelimiter: '|||MLE|||',

      localdocedits: [],
      processingmessage: '',
    }
  },

  computed: {
    ...panelComputed,
    ...dbauthComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },

    seldocedits() {
      if (
        Array.isArray(this.editDocedits[this.panelno]) &&
        this.editDocedits[this.panelno].length > 0
      ) {
        // added the filter to only include active edits.
        return this.editDocedits[this.panelno].filter((e) => e.active === true)
      } else {
        return []
      }
    },

    filterdocedits() {
      var returnArray = []
      var index
      for (index in this.localdocedits) {
        if (this.selected === 'OwnerOnly') {
          if (this.localdocedits[index].approved) {
            returnArray.push(this.localdocedits[index])
          }
        } else if (this.selected === 'OwnerAndNegotiator') {
          if (
            this.localdocedits[index].approved &&
            this.localdocedits[index].negotiatorapproved
          ) {
            returnArray.push(this.localdocedits[index])
          }
        } else {
          returnArray.push(this.localdocedits[index])
        }
      }

      return returnArray.sort(comparePara)
    },

    filterparamods() {
      return this.filterdocedits.map((x) => x.paraid)
    },
    filtersentidmods() {
      return this.filterdocedits.map((x) => x.sentid)
    },

    filtermodsource() {
      return this.filterdocedits.map((x) => x.senttext)
    },
    filtermodtype() {
      return this.filterdocedits.map((x) => x.docedittype)
    },

    filtermodtarget() {
      var index
      var returnArray = []
      for (index in this.filterdocedits) {
        if (this.filterdocedits[index].docedittype === 'REPLACE') {
          returnArray.push(
            this.TWOBLANKS + this.filterdocedits[index].docedittext
          )
        } else if (this.filterdocedits[index].docedittype === 'DELETE') {
          returnArray.push(this.TWOBLANKS)
        } else if (this.filterdocedits[index].docedittype === 'ADD-AFTER') {
          // Added 2 spaces after ADD AFTER.
          returnArray.push(
            '<<ADDAFTER>>' +
              this.TWOBLANKS +
              this.filterdocedits[index].docedittext
          )
        } else {
          returnArray.push('MLE ERROR - MERGE ERROR')
        }
      }
      return returnArray
    },

    sizeDocEdits() {
      return this.filterdocedits.length
    },
    sizeDocEditsReplace() {
      return this.filterdocedits.filter((e) => e.docedittype === 'REPLACE')
        .length
    },
    sizeDocEditsDelete() {
      return this.filterdocedits.filter((e) => e.docedittype === 'DELETE')
        .length
    },
    sizeDocEditsAddAfter() {
      return this.filterdocedits.filter((e) => e.docedittype === 'ADD-AFTER')
        .length
    },

    notDocXMessage() {
      if (!this.eligibledocxin) {
        return this.$t('INFO_MESSAGES.SOURCEDOCNOTDOCX')
        // 'The Source document is not a DOCX document.  Redline capabilities are not available.'
      }
      return ''
    },
  },
  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
  },

  methods: {
    // ...dbdocumentComputed,
    setInitialValues() {
      this.localdocedits = this.seldocedits

      // Add a condition - if not in negotiation state, switch to Owner only as default
      if (!this.localpanelstate.ifNegotiationState) {
        this.selected = 'OwnerOnly'
      }
    },

    createStringDateTime() {
      const date = new Date()
      return (
        date.toISOString().slice(0, 10) +
        ':' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds() +
        ':'
      )
    },

    downloadDocument() {
      const MyThis = this
      const datenow = new Date()
      const inputDate = datenow.toISOString()

      if (['overwrite', 'previous'].includes(this.overwriteprevious)) {
        this.$store
          .dispatch('dbdocument/getDocument', {
            docid: MyThis.localpanelstate.docmeta.docid,
          })
          .then(function (doc) {
            MyThis.$emit('docx-createredline', {
              urlname: doc.data.urlname,
              paramods: MyThis.filterparamods.join(MyThis.IOstringdelimiter),
              modsource: MyThis.filtermodsource.join(MyThis.IOstringdelimiter),
              modtarget: MyThis.filtermodtarget.join(MyThis.IOstringdelimiter),
              inputName:
                'DOCG-' + MyThis.displayUserName(MyThis.dbauthuser.uniqid),
              inputDate: inputDate,
              docid: MyThis.localpanelstate.docmeta.docid,
              uniqid: MyThis.localpanelstate.dbauthuser.uniqid,
              overwriteprevredlines: MyThis.overwriteprevious,
              outputFileName: doc.data.filename.replace('.docx', '-RL.docx'),
              outputurlname:
                MyThis.REDLINEAREA +
                '/' +
                doc.data.docid +
                '/' +
                MyThis.createStringDateTime() +
                doc.data.filename.replace('.docx', '-RL.docx'),
            })
          })
      }

      // Dispatches for Final document creation and downloads.
      if (['final'].includes(this.overwriteprevious)) {
        this.$store
          .dispatch('dbdocument/getDocument', {
            docid: MyThis.localpanelstate.docmeta.docid,
          })
          .then(function (doc) {
            MyThis.$emit('docx-createfinal', {
              urlname: doc.data.urlname,
              paramods: MyThis.filterparamods.join(MyThis.IOstringdelimiter),
              modsource: MyThis.filtermodsource.join(MyThis.IOstringdelimiter),
              modtarget: MyThis.filtermodtarget.join(MyThis.IOstringdelimiter),
              docid: MyThis.localpanelstate.docmeta.docid,
              uniqid: MyThis.localpanelstate.dbauthuser.uniqid,
              outputFileName: doc.data.filename.replace('.docx', '-FINAL.docx'),
              outputurlname:
                MyThis.REDLINEAREA +
                '/' +
                doc.data.docid +
                '/' +
                MyThis.createStringDateTime() +
                doc.data.filename.replace('.docx', '-FINAL.docx'),
            })
          })
      }
      this.showDialog = false
    },

    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },

    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1400px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.DOWNLOADREDLINE')"
        ></PanelCardTitle>
        <v-card-text
          ><v-container>
            <v-row>
              <v-col cols="7">
                <h3 class="ma-1 mt-5">
                  {{ $t('DIALOGS.OPTIONSFORREDLINEINCLUDE') }}:</h3
                >
                <div class="ma-5">
                  <v-radio-group v-model="selected" required row>
                    <v-radio
                      class="mt-2"
                      :label="$t('DIALOGS.ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS')"
                      value="OwnerAndNegotiator"
                    ></v-radio>
                    <v-radio
                      class="mt-2"
                      :label="$t('DIALOGS.ONLYINCLUDEOWNERAPPROVEDEDITS')"
                      value="OwnerOnly"
                    ></v-radio>

                    <v-radio
                      class="mt-2"
                      :label="$t('DIALOGS.INCLUDEALLDOCEDITS')"
                      value="All"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
              <v-col cols="5">
                <h3 class="ma-1 mt-5"
                  >{{ $t('DIALOGS.REDLINECHANGESSELECTED') }}:
                  {{ sizeDocEdits }}</h3
                >
                <div class="ma-5 mt-8">
                  <div class="ma-5">
                    <p></p>
                    <b
                      ><p
                        >{{ $t('DIALOGS.REPLACEREDLINES') }}:
                        {{ sizeDocEditsReplace }}
                      </p>
                      <p
                        >{{ $t('DIALOGS.DELETEREDLINES') }}:
                        {{ sizeDocEditsDelete }}
                      </p>
                      <p
                        >{{ $t('DIALOGS.ADDAFTERREDLINES') }}:
                        {{ sizeDocEditsAddAfter }}
                      </p></b
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="7">
                <h3 class="ma-1 mt-5"
                  >{{ $t('DIALOGS.OPTIONSFORPREVREDLINES') }}:</h3
                >
                <div class="ma-5">
                  <v-radio-group v-model="overwriteprevious" required row>
                    <v-radio
                      class="mt-2"
                      :label="$t('DIALOGS.OVERWRITEPREVREDLINES')"
                      value="overwrite"
                    ></v-radio>
                    <v-radio
                      class="mt-2"
                      :label="$t('DIALOGS.KEEPPREVREDLINES')"
                      value="previous"
                    ></v-radio>
                    <v-radio
                      class="mt-2"
                      :label="$t('DIALOGS.GENERATEFINALDOC')"
                      value="final"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <h3 class="ma-5">{{ processingmessage }}</h3>
          <h3 class="ma-5">{{ notDocXMessage }}</h3>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('act/downloadredlinedocx')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="eligibledocxin"
            :disabled="sizeDocEdits === 0"
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="downloadDocument"
            >{{ $t('DIALOGS.DOWNLOADDOCUMENT') }}</v-btn
          >

          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
