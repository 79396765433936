import Vue from 'vue'
import Vuetify from 'vuetify'
import App from './app.vue'
import router from '@router'
import store from '@state/store'
import '@components/_globals'
import 'vuetify/dist/vuetify.min.css'
import VueI18n from 'vue-i18n'
import { LOCALES } from './i18n'
import './assets/scss/app.scss'

// firebase for authentication changes
const auth = require('./dbconfig/FBconfig.js')

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

Vue.use(Vuetify)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: LOCALES,
})

// handle page reloads
let app
// auth.onIdTokenChanged((user) => {
if (!app) {
  app = new Vue({
    router,
    store,
    vuetify: new Vuetify(),
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
}
// })

// this does not seem to generate immediate change.
// locales
// const loadedLanguages = ['en', 'fr'] // our default language that is preloaded

// function setI18nLanguage(lang) {
//   i18n.locale = lang
//   // axios.defaults.headers.common['Accept-Language'] = lang
//   document.querySelector('html').setAttribute('lang', lang)
//   return lang
// }

// export function loadLanguageAsync(lang) {
//   // If the same language
//   if (i18n.locale === lang) {
//     return Promise.resolve(setI18nLanguage(lang))
//   }

//   // If the language was already loaded
//   if (loadedLanguages.includes(lang)) {
//     return Promise.resolve(setI18nLanguage(lang))
//   }

// If the language hasn't been loaded yet
// return import(
//   /* webpackChunkName: "lang-[request]" */ `./i18n/${lang}.js`
// ).then((messages) => {
//   i18n.setLocaleMessage(lang, messages.default)
//   loadedLanguages.push(lang)
//   return setI18nLanguage(lang)
// })
// }

// locales

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
