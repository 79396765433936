import store from '@state/store'

import {
  DBdeactivate,
  DBupdateobjarrayUnion,
  DBupdateobjarrayObjUnion,
  DBupdateobjarrayRemove,
  DBinsertonesync,
  DBgetobjsync,
  DBownergroup,
  DBcollaboratorgroup,
} from '@/src/state/io/iodb.js'

const GROUPSTABLENAME = 'groups'
const USERSTABLENAME = 'users'

export const state = {
  // debug only
  dbgroupid: 0,
}

// do I need to add getters here?
export const getters = {
  dbgroupid(state) {
    return state.dbgroupid
  },
}

export const mutations = {
  // debug
  SET_GROUPID(state, id) {
    state.dbgroupid = id
  },
}

export const actions = {
  init({ commit }) {},

  writeGroup({ commit, state }, payload) {
    // Define the object for creation  ... passed from the payload.
    var groupEx = {
      group: payload.group,
      description: payload.description,
      ownerid: [payload.ownerid], // start with the owner as the only user
      uniqid: [], // always initialize user list as empty, doing the minibatch below
      doctypeid: [], // empty to start
      privileges: [],
      log: [],
      created: new Date(),
      createdby: payload.ownerid,
      delete: '',
      active: true,
    }

    DBinsertonesync(GROUPSTABLENAME, groupEx)
      .then(function(doc) {
        commit('SET_GROUPID', doc.id)

        DBupdateobjarrayUnion(
          USERSTABLENAME,
          payload.ownerid,
          'groupid',
          doc.id
        )
      })
      .catch(function(e) {
        store.dispatch('dberror/logError', e)
      })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.CREATEGROUP',
        detail: payload.group,
        context: payload.description,
      },
    })
  },

  deleteGroup({ commit, state }, payload) {
    let gid = payload.groupid

    DBgetobjsync(GROUPSTABLENAME, payload.groupid)
      .then(function(item) {
        item.data.uniqid.forEach((uniqid) =>
          DBupdateobjarrayRemove(USERSTABLENAME, uniqid, 'groupid', gid)
        )
      })
      .catch(function(e) {
        store.dispatch('dberror/logError', e)
      })

    // then delete the group.
    DBdeactivate(GROUPSTABLENAME, gid)

    // logGroup
    store.dispatch('dbgroup/logGroup', {
      uniqid: payload.uniqid,
      groupid: payload.groupid,
      logdetail: { action: 'ADMINEVENTS.DELETEGROUP', context: '' },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEGROUP',
        detail: payload.groupid,
        context: '',
      },
    })
  },

  addOwnerGroup({ commit, state }, payload) {
    DBownergroup(payload.ownerid, payload.groupid, 'Union')

    // logGroup
    store.dispatch('dbgroup/logGroup', {
      uniqid: payload.uniqid,
      groupid: payload.groupid,
      logdetail: {
        action: 'ADMINEVENTS.ADDOWNERGROUP',
        context: payload.ownerid,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDOWNERGROUP',
        detail: payload.ownerid,
        context: payload.groupid,
      },
    })
  },
  deleteOwnerGroup({ commit, state }, payload) {
    DBownergroup(payload.ownerid, payload.groupid, 'Remove')

    // logGroup
    store.dispatch('dbgroup/logGroup', {
      uniqid: payload.uniqid,
      groupid: payload.groupid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEOWNERGROUP',
        context: payload.ownerid,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEOWNERGROUP',
        detail: payload.ownerid,
        context: payload.groupid,
      },
    })
  },

  addCollabGroup({ commit, state }, payload) {
    DBcollaboratorgroup(payload.uniqidadd, payload.groupid, 'Union')

    // logGroup
    store.dispatch('dbgroup/logGroup', {
      uniqid: payload.uniqid,
      groupid: payload.groupid,
      logdetail: {
        action: 'ADMINEVENTS.ADDCOLLABGROUP',
        context: payload.uniqidadd,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDCOLLABGROUP',
        detail: payload.uniqidadd,
        context: payload.groupid,
      },
    })
  },

  deleteCollabGroup({ commit, state }, payload) {
    DBcollaboratorgroup(payload.uniqiddel, payload.groupid, 'Remove')

    // logGroup
    store.dispatch('dbgroup/logGroup', {
      uniqid: payload.uniqid,
      groupid: payload.groupid,
      logdetail: {
        action: 'ADMINEVENTS.DELETECOLLABGROUP',
        context: payload.uniqiddel,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETECOLLABGROUP',
        detail: payload.uniqiddel,
        context: payload.groupid,
      },
    })
  },

  addDocTypeGroup({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      GROUPSTABLENAME,
      payload.groupid,
      'doctypeid',
      payload.doctypeid
    )

    // logGroup
    store.dispatch('dbgroup/logGroup', {
      uniqid: payload.uniqid,
      groupid: payload.groupid,
      logdetail: {
        action: 'ADMINEVENTS.ADDDOCTYPETOGROUP',
        context: payload.doctypeid,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.ADDDOCTYPETOGROUP',
        detail: payload.doctypeid,
        context: payload.groupid,
      },
    })
  },

  deleteDocTypeGroup({ commit, state }, payload) {
    DBupdateobjarrayRemove(
      GROUPSTABLENAME,
      payload.groupid,
      'doctypeid',
      payload.doctypeid
    )

    // logGroup
    store.dispatch('dbgroup/logGroup', {
      uniqid: payload.uniqid,
      groupid: payload.groupid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEDOCTYPEGROUP',
        context: payload.doctypeid,
      },
    })

    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEDOCTYPEGROUP',
        detail: payload.doctypeid,
        context: payload.groupid,
      },
    })
  },

  logGroup({ commit, state }, payload) {
    let insertdetail = payload.logdetail
    insertdetail.groupid = payload.groupid
    insertdetail.uniqid = payload.uniqid
    insertdetail.timestamp = new Date()

    DBupdateobjarrayObjUnion(
      GROUPSTABLENAME,
      payload.groupid,
      'log',
      insertdetail
    )
  },
}
