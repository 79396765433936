import AIApiService from './AIApiService'
import { camelCaseRecursive } from '@utils/json'

const ENDPOINTS = {
  // These endpoints are available in alternate AIonly backend as well.
  PROC_DOC: 'proc-doc/',
  PROC_DOC_PDF: 'proc-pdf-doc/',
}

class AIDocumentProcessingService extends AIApiService {
  // New - move autosaving logic to backend
  procDoc({
    document,
    documentTypeId = 1,
    docid,
    urlname,
    docname,
    owner,
    downloadURL,
    dbauthprivilege,
    processingOption = 0,
  }) {
    const formData = new FormData()
    // formData.append('document', document)
    formData.append('document_type_id', documentTypeId)
    formData.append('docid', docid)
    formData.append('urlname', urlname)
    formData.append('processing_option', processingOption)
    // added to UploadObject
    formData.append('docname', docname)
    formData.append('owner', owner)
    formData.append('downloadURL', downloadURL)
    formData.append('dbauthprivilege', dbauthprivilege)
    return this.apiClient.post(ENDPOINTS.PROC_DOC, formData)
  }

  // new
  procPDFDoc({
    document,
    documentTypeId = 1,
    docid,
    urlname,
    processingOption = 0,
    docname,
    owner,
    downloadURL,
    dbauthprivilege,
  }) {
    const formData = new FormData()
    // formData.append('document', document)
    formData.append('document_type_id', documentTypeId)
    formData.append('docid', docid)
    formData.append('urlname', urlname)
    formData.append('processing_option', processingOption)
    // added to UploadObject
    formData.append('docname', docname)
    formData.append('owner', owner)
    formData.append('downloadURL', downloadURL)
    formData.append('dbauthprivilege', dbauthprivilege)

    return this.apiClient.post(ENDPOINTS.PROC_DOC_PDF, formData)
  }
}

export default new AIDocumentProcessingService()
