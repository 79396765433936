// PANELS STATE - this is an array form of the document state within a panel.
// Changed all state names to be preceded by p
// paramaterized functions to pass panelno required.
// troubleshooting the clausehierarcy calls.  failing.
// need to: eliminate dependencies on documents.js

import { camelCase } from 'lodash'
import { camelCaseRecursive } from '@utils/json'

import DocumentProcessingService from '@services/DocumentProcessingService'
import AIDocumentProcessingService from '@services/AIDocumentProcessingService'

import store from '../store'
import { setVueArray } from '@utils/setVueArray'
import { getCatIDFromUID } from '@utils/clauseMapping'

export const state = {
  // document specific arrays per panel  init with 5 null values for all arrays for now.
  // panel status
  aPID: [null, null, null, null, null, null], // panel id for the panel.  corresponds to the corresponding panel prev -ppanelnoid
  aPIDprocessStatus: [null, null, null, null, null, null], // status flag for the processing of this document - prev pisProcessing
  aPIDjobID: [null, null, null, null, null, null], // job id prev - pjobId

  // document info
  // change to empty array.  would that cause error?  does not help.
  docRawData: [null, null, null, null, null, null], // contains the document information from the the backend - also contains raw data return values.  prev pdocument
  docDocData: [null, null, null, null, null, null], // contains the document that is saved and specified as part of this document when user chooses to upload data.
  docDocLog: [null, null, null, null, null, null], // contains the document that is stored per panel.
  docFile: [null, null, null, null, null, null], // file for the document prev pdocumentFile
  docTypeId: [null, null, null, null, null, null], // id for the document type. prev pdocumentTypeId

  // edit comments - this is specific to the document and pulled from the DB.
  editComments: [null, null, null, null, null, null],
  editDocedits: [null, null, null, null, null, null],
  editTags: [null, null, null, null, null, null],

  // clause info
  infoClauseRedundant: [null, null, null, null, null, null], // derived from document prev: predundantClauses
  infoClauseTreeStructure: [null, null, null, null, null, null], //  prev pclauseTreeStructure

  // metadata and obligations - not being tested right now, maybe later.
  infoMetadataPrioritySelected: [null, null, null, null, null, null], // metadata info from the backend prev: pmetadataPrioritySelected
  infoObligationTreeStructure: [null, null, null, null, null, null], // derived obligations tree structure prev: pobligationTreeStructure

  // below are not used but keep for now.
  infoDocClauses: [null, null, null, null, null, null], // clauseTypes specific to each document ID.
  infoDocMetadata: [null, null, null, null, null, null], // metadata Types specific to each document ID.
}

export const getters = {
  // changing all getters to non-paramaterized.  Parameter and context and panelno will be controlled by each component.
  aPID(state) {
    return state.aPID
  },
  aPIDjobID(state) {
    return state.aPIDjobID
  },
  aPIDprocessStatus(state) {
    return state.aPIDprocessStatus
  },
  docTypeId(state) {
    return state.docTypeId
  },
  docFile(state) {
    return state.docFile
  },
  docRawData(state) {
    return state.docRawData
  },
  docDocData(state) {
    return state.docDocData
  },
  docDocLog(state) {
    return state.docDocLog
  },
  editComments(state) {
    return state.editComments
  },
  editDocedits(state) {
    return state.editDocedits
  },
  editTags(state) {
    return state.editTags
  },

  infoClauseRedundant(state) {
    return state.infoClauseRedundant
  },

  infoClauseTreeStructure(state) {
    return state.infoClauseTreeStructure
  },

  infoObligationTreeStructure(state) {
    return state.infoObligationTreeStructure
  },
  infoMetadataPrioritySelected(state) {
    return state.infoMetadataPrioritySelected
  },
  infoDocClauses(state) {
    return state.infoDocClauses
  },
  infoDocMetadata(state) {
    return state.infoDocMetadata
  },
}

export const mutations = {
  INIT_DOCUMENT(state, panelno) {
    setVueArray(state.aPID, panelno, panelno)
  },
  SET_DOCUMENT_FILE(state, payload) {
    setVueArray(state.docFile, payload.panelno, payload.documentFile)
  },
  SET_DOCUMENT_TYPE_ID(state, payload) {
    setVueArray(state.docTypeId, payload.panelno, payload.documentTypeId)
  },
  SET_CLAUSE_TYPES(state, payload) {
    setVueArray(state.infoDocClauses, payload.panelno, payload.clauseTypes)
  },
  SET_METADATA_TYPES(state, payload) {
    setVueArray(state.infoDocMetadata, payload.panelno, payload.metadataTypes)
  },
  SET_JOB_ID(state, payload) {
    setVueArray(state.aPIDjobID, payload.panelno, payload.jobId)
  },
  SET_IS_PROCESSING(state, payload) {
    setVueArray(state.aPIDprocessStatus, payload.panelno, payload.isProcessing)
  },
  SET_DOCUMENT(state, payload) {
    setVueArray(state.docRawData, payload.panelno, payload.document)
  },

  SET_DOCUMENTDATA(state, payload) {
    setVueArray(state.docDocData, payload.panelno, payload.docobj)
  },

  SET_DOCUMENTLOG(state, payload) {
    setVueArray(state.docDocLog, payload.panelno, payload.docobj)
  },

  SET_REDUNDANT_CLAUSES(state, payload) {
    setVueArray(
      state.infoClauseRedundant,
      payload.panelno,
      payload.redundantClauses
    )
  },
  SET_CLAUSE_TREE_STRUCTURE(state, payload) {
    setVueArray(
      state.infoClauseTreeStructure,
      payload.panelno,
      payload.clauseTreeStructure
    )
  },

  SET_OBLIGATION_TREE_STRUCTURE(state, payload) {
    setVueArray(
      state.infoObligationTreeStructure,
      payload.panelno,
      payload.obligationTreeStructure
    )
  },

  INSERT_INTO_METADATA(state, payload) {
    const { paragraphIndex } = payload.metadata
    let metadataList =
      state.docRawData[payload.panelno].metadata[paragraphIndex]
    if (!metadataList) {
      metadataList = []
      state.docRawData[payload.panelno].metadata[paragraphIndex] = metadataList
    }
    metadataList.push(payload.metadata)
  },
  INSERT_INTO_OBLIGATIONS(state, payload) {
    const { paragraphIndex } = payload.obligation
    let obligations =
      state.docRawData[payload.panelno].obligations[paragraphIndex]
    if (!obligations) {
      obligations = []
      state.docRawData[payload.panelno].obligations[paragraphIndex] =
        obligations
    }
    obligations.push(payload.obligation)
  },
  INSERT_INTO_PRIORITIZED(state, payload) {
    const apttusTag = camelCase(payload.metadata.apttusTag)
    const list =
      state.docRawData[payload.panelno].prioritizedMetadata[apttusTag]
    list.splice(0, 0, payload.metadata)
  },

  UPDATE_CLAUSE(state, payload) {
    const document = state.docRawData[payload.panelno]

    const clause = document.clauseId[payload.paragraphIndex]

    // updated this routine to the smaller data model.
    // some expanded information for changed labels is added.

    if (!clause.previousUID) {
      clause.previousUID = payload.previousUID

      clause.confidence = 1.0
    }
    if (clause.previousUID === payload.uid) {
      clause.confidence = 1.0
      clause.uid = clause.previousUID
      clause.changed = false
    } else {
      clause.uid = payload.uid
      clause.clauseCategory = payload.newLabel
      clause.changed = true
    }
    document.clauseId[payload.paragraphIndex] = clause

    // Need to dispatch to a method to make the edits to docRawData on the DB.
    store.dispatch('dbdocument/updateDocument', {
      docRawData: document,
      docid: payload.docid,
    })

    setVueArray(state.docRawData, payload.panelno, document)
  },
  UPDATE_OBLIGATION(state, payload) {
    const document = state.docRawData[payload.panelno]
    const clauseIndex = parseInt(getCatIDFromUID(payload.id))
    const obligation = document.obligations[payload.paragraphIndex][clauseIndex]
    if (!obligation.previousUID) {
      obligation.previousUID = payload.previousUID
    }
    if (obligation.previousUID === payload.uid) {
      obligation.uid = obligation.previousUID
      obligation.previousUID = null
      obligation.changed = false
    } else {
      obligation.uid = payload.uid
      obligation.changed = true
    }
    document.obligations[payload.paragraphIndex][clauseIndex] = obligation
    setVueArray(state.docRawData, payload.panelno, document)
  },

  MOVE_TO_TOP_PRIORITIZED(state, payload) {
    const metadataPrioritySelected =
      state.infoMetadataPrioritySelected[payload.panelno]
    metadataPrioritySelected[payload.ner] = {
      text: payload.metadataText,
      apttusTag: payload.apttusTag,
    }
    setVueArray(
      state.infoMetadataPrioritySelected,
      payload.panelno,
      metadataPrioritySelected
    )
  },

  SET_EDITCOMMENTS(state, payload) {
    setVueArray(state.editComments, payload.panelno, payload.editComments)
  },
  SET_EDITDOCEDITS(state, payload) {
    setVueArray(state.editDocedits, payload.panelno, payload.editDocedits)
  },
  SET_EDITTAGS(state, payload) {
    setVueArray(state.editTags, payload.panelno, payload.editTags)
  },
}

export const actions = {
  init({ commit }) {
    // what to initialize for panel state.
    store.dispatch('panels/initpanel', 0)
    store.dispatch('panels/initpanel', 1)
    store.dispatch('panels/initpanel', 2)
    store.dispatch('panels/initpanel', 3)
    store.dispatch('panels/initpanel', 4)
    store.dispatch('panels/initpanel', 5)
  },
  initpanel({ commit }, panelno) {
    commit('INIT_DOCUMENT', panelno)

    // expand to include a reset of all panel state for this panel.
    commit('SET_DOCUMENT_TYPE_ID', {
      panelno: panelno,
      documentTypeId: null,
    })
    commit('SET_JOB_ID', {
      panelno: panelno,
      jobId: null,
    })
    commit('SET_DOCUMENT', {
      panelno: panelno,
      document: null,
    })
    commit('SET_DOCUMENTDATA', {
      panelno: panelno,
      docobj: null,
    })
    commit('SET_REDUNDANT_CLAUSES', {
      panelno: panelno,
      redundantClauses: null,
    })
    commit('SET_CLAUSE_TREE_STRUCTURE', {
      panelno: panelno,
      clauseTreeStructure: null,
    })
    commit('SET_OBLIGATION_TREE_STRUCTURE', {
      panelno: panelno,
      obligationTreeStructure: null,
    })

    // reset the panel listened states.
    commit('SET_EDITCOMMENTS', {
      panelno: panelno,
      editComments: [],
    })
    commit('SET_EDITDOCEDITS', {
      panelno: panelno,
      editDocedits: [],
    })
    commit('SET_EDITTAGS', {
      panelno: panelno,
      editDocedits: [],
    })
  },

  // async fetchDocument({ commit }, panelno) {
  //   const document = await DocumentProcessingService.processDocument()
  //   commit('SET_DOCUMENT', { panelno: panelno, document: document })
  // },
  async fetchClauseTypes({ commit }, panelno) {
    const clauseTypes = await DocumentProcessingService.getClauseTypes(
      state.docTypeId[panelno]
    )
    commit('SET_CLAUSE_TYPES', { panelno: panelno, clauseTypes: clauseTypes })
  },
  async fetchMetadataTypes({ commit }, panelno) {
    const metadataTypes = await DocumentProcessingService.getMetadataTypes(
      state.docTypeId[panelno]
    )
    commit('SET_METADATA_TYPES', {
      panelno: panelno,
      metadataTypes: metadataTypes,
    })
  },

  async fetchDocumentTypes({ commit }, panelno) {
    const { data } = await DocumentProcessingService.fetchDocumentTypes()
    commit('SET_DOCUMENT_TYPES', panelno, data)
  },
  async insertIntoMetadata({ commit }, panelno, metadata) {
    commit('INSERT_INTO_METADATA', { panelno: panelno, metadata: metadata })
  },
  async insertIntoObligations({ commit }, panelno, obligation) {
    commit('INSERT_INTO_OBLIGATIONS', {
      panelno: panelno,
      obligation: obligation,
    })
  },
  async insertIntoPrioritized({ commit }, panelno, metadata) {
    commit('INSERT_INTO_PRIORITIZED', { panelno: panelno, metadata: metadata })
  },

  async setOpenDocMeta({ commit }, payload) {
    commit('SET_DOCUMENTDATA', {
      panelno: payload.panelno,
      docobj: payload.docobj,
    })
  },

  async setOpenHUBDocMeta({ commit }, payload) {
    commit('SET_DOCUMENTDATA', {
      panelno: payload.panelno,
      docobj: payload.docobj,
    })
  },

  async setDocLog({ commit }, payload) {
    commit('SET_DOCUMENTLOG', {
      panelno: payload.panelno,
      docobj: payload.docobj,
    })
  },

  async setOpenDoc({ commit }, payload) {
    const panelno = payload.panelno
    const docobj = payload.docobj

    // docTypeId  - this complains at startup - don't know why.
    if (docobj !== null) {
      commit('SET_DOCUMENT_TYPE_ID', {
        panelno: panelno,
        documentTypeId: docobj.documentTypeId,
      })
    }

    // Set the Job ID - aPIDjobID
    commit('SET_JOB_ID', {
      panelno: panelno,
      jobId: docobj.docid,
    })

    // Store the raw data  - docRawData
    commit('SET_DOCUMENT', {
      panelno: panelno,
      document: docobj.docRawData,
    })

    // dispatch to get the appropruate DocMeta for this document.
    store.dispatch('dbdocmeta/openDocMeta', {
      uniqid: payload.uniqid,
      panelno: panelno,
      docid: docobj.docid,
    })
  },

  async setOpenHUBDoc({ commit }, payload) {
    const panelno = payload.panelno
    const docobj = payload.docobj

    // docTypeId  - this complains at startup - don't know why.
    if (docobj !== null) {
      commit('SET_DOCUMENT_TYPE_ID', {
        panelno: panelno,
        documentTypeId: docobj.documentTypeId,
      })
    }

    // Set the Job ID - aPIDjobID
    commit('SET_JOB_ID', {
      panelno: panelno,
      jobId: docobj.docid,
    })

    // Store the raw data  - docRawData
    commit('SET_DOCUMENT', {
      panelno: panelno,
      document: docobj.docRawData,
    })

    // dispatch to get the appropruate DocMeta for this document.
    store.dispatch('dbdocmeta/openHUBDocMeta', {
      uniqid: payload.uniqid,
      panelno: panelno,
      docid: docobj.docid,
    })
  },

  async stripeGetLatest({ commit }, stripecustid = {}) {
    DocumentProcessingService.stripeGetLatest({
      stripecustid: stripecustid.stripecustid,
    })
  },

  async stripeGetBillingSession({ commit }, stripecustid = {}) {
    // Upload the document (sync call, wait for job id to come back)
    const { data } = await DocumentProcessingService.stripeGetBillingSession({
      stripecustid: stripecustid.stripecustid,
    })
    return data
  },

  async alertEmailGeneration({ commit }, alertid) {
    DocumentProcessingService.alertEmailGeneration({
      key: alertid,
    })
  },

  // MAIN CALL to upload and process a new docx document.
  async uploadDocument({ commit }, uploadDocumentObject = {}) {
    // Set the doc type id - docTypeId
    commit('SET_DOCUMENT_TYPE_ID', {
      panelno: uploadDocumentObject.panelno,
      documentTypeId: uploadDocumentObject.documentTypeId,
    })

    // Set the Job ID - aPIDjobID
    commit('SET_JOB_ID', {
      panelno: uploadDocumentObject.panelno,
      jobId: uploadDocumentObject.docid,
    })

    // Upload the document (sync call, wait for job id to come back)
    const { data } = await AIDocumentProcessingService.procDoc({
      document: uploadDocumentObject.document,
      documentTypeId: uploadDocumentObject.documentTypeId,
      docid: uploadDocumentObject.docid,
      urlname: uploadDocumentObject.urlname,

      // added to UploadObject
      docname: uploadDocumentObject.docname,
      owner: uploadDocumentObject.owner,
      downloadURL: uploadDocumentObject.downloadURL,
      dbauthprivilege: uploadDocumentObject.dbauthprivilege,
    })

    // Set the job processing as true - aPIDprocessStatus
    commit('SET_IS_PROCESSING', {
      panelno: uploadDocumentObject.panelno,
      isProcessing: true,
    })
  },

  // MAIN CALL to upload and process a new PDF document.
  async uploadPDFDocument({ commit }, uploadDocumentObject = {}) {
    // Set the doc type id - docTypeId
    commit('SET_DOCUMENT_TYPE_ID', {
      panelno: uploadDocumentObject.panelno,
      documentTypeId: uploadDocumentObject.documentTypeId,
    })

    // Set the Job ID - aPIDjobID
    commit('SET_JOB_ID', {
      panelno: uploadDocumentObject.panelno,
      jobId: uploadDocumentObject.docid,
    })

    // Upload the document (sync call, wait for job id to come back)
    const { data } = await AIDocumentProcessingService.procPDFDoc({
      document: uploadDocumentObject.document,
      documentTypeId: uploadDocumentObject.documentTypeId,
      docid: uploadDocumentObject.docid,
      urlname: uploadDocumentObject.urlname,

      // added to UploadObject
      docname: uploadDocumentObject.docname,
      owner: uploadDocumentObject.owner,
      downloadURL: uploadDocumentObject.downloadURL,
      dbauthprivilege: uploadDocumentObject.dbauthprivilege,
    })

    // Set the job processing as true - aPIDprocessStatus
    commit('SET_IS_PROCESSING', {
      panelno: uploadDocumentObject.panelno,
      isProcessing: true,
    })
  },

  // MAIN CALL to upload and process a new docx document.
  async uploadDocumentold({ commit }, uploadDocumentObject = {}) {
    // Set the doc type id - docTypeId
    commit('SET_DOCUMENT_TYPE_ID', {
      panelno: uploadDocumentObject.panelno,
      documentTypeId: uploadDocumentObject.documentTypeId,
    })

    // Upload the document (sync call, wait for job id to come back)
    const { data } = await DocumentProcessingService.uploadDocument({
      document: uploadDocumentObject.document,
      // for now, everything passes back with a documentTypeId = 1.  Later, change it so that it sends the right DocTypeId back.
      documentTypeId: '1',
      docid: uploadDocumentObject.docid,
      urlname: uploadDocumentObject.urlname,
    })

    // Set the Job ID - aPIDjobID
    commit('SET_JOB_ID', {
      panelno: uploadDocumentObject.panelno,
      jobId: data.job_id,
    })

    // Set the job processing as true - aPIDprocessStatus
    commit('SET_IS_PROCESSING', {
      panelno: uploadDocumentObject.panelno,
      isProcessing: true,
    })
  },

  // MAIN CALL to upload and process a new PDF document.
  async uploadPDFDocumentold({ commit }, uploadDocumentObject = {}) {
    // Set the doc type id - docTypeId
    commit('SET_DOCUMENT_TYPE_ID', {
      panelno: uploadDocumentObject.panelno,
      documentTypeId: uploadDocumentObject.documentTypeId,
    })

    // Upload the document (sync call, wait for job id to come back)
    const { data } = await DocumentProcessingService.uploadPDFDocument({
      document: uploadDocumentObject.document,
      // for now, everything passes back with a documentTypeId = 1.  Later, change it so that it sends the right DocTypeId back.
      documentTypeId: '1',
      docid: uploadDocumentObject.docid,
      urlname: uploadDocumentObject.urlname,
    })

    // Set the Job ID - aPIDjobID
    commit('SET_JOB_ID', {
      panelno: uploadDocumentObject.panelno,
      jobId: data.job_id,
    })

    // Set the job processing as true - aPIDprocessStatus
    commit('SET_IS_PROCESSING', {
      panelno: uploadDocumentObject.panelno,
      isProcessing: true,
    })
  },

  getDocRawData({ commit }, payload) {
    var document = camelCaseRecursive(payload.predictions)

    Object.keys(document.clauseId).forEach((paragraphIdx) => {
      const clause = document.clauseId[paragraphIdx]
      clause.id = `clause-${paragraphIdx}`
      delete clause.clause
    })

    // Store the raw data  - docRawData
    commit('SET_DOCUMENT', {
      panelno: payload.panelno,
      document: document,
    })
    // // Set the IsProcessing to False - aPIDprocessStatus
    commit('SET_IS_PROCESSING', {
      panelno: payload.panelno,
      isProcessing: false,
    })
  },

  updateEditComments({ commit }, payload) {
    commit('SET_EDITCOMMENTS', payload)
  },

  updateEditDocEdits({ commit }, payload) {
    commit('SET_EDITDOCEDITS', payload)
  },
  updateEditTags({ commit }, payload) {
    commit('SET_EDITTAGS', payload)
  },

  updateDocData({ commit }, payload) {
    commit('SET_DOCUMENTDATA', payload)
  },

  updateClause({ commit }, payload) {
    commit('UPDATE_CLAUSE', payload)
  },
}
