// EU Configuration

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

import { getAuth } from 'firebase/auth'

const config = {
  apiKey: 'AIzaSyAAMf3Mn0mVuV4J3QCiXuvW1ZMT1r4LE80',
  authDomain: 'mle10eu.firebaseapp.com',
  projectId: 'mle10eu',
  storageBucket: 'mle10eu.appspot.com',
  messagingSenderId: '272743279686',
  appId: '1:272743279686:web:01d5345299971b05ddae47',
}

const firebaseApp = initializeApp(config)
const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
const auth = getAuth()

export { db, storage, auth }

export default {
  db,
  storage,
  auth,
}
