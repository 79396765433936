import hubhttpService from './HUBHttpService'

class ApiService {
  constructor() {
    this.http = hubhttpService
    this.apiClient = hubhttpService.client
  }
}

export default ApiService
