import HUBApiService from './HUBApiService'
import { camelCaseRecursive } from '@utils/json'

const ENDPOINTS = {
  HUB_GETDOCDATA: 'hubreq-getdocdata',

  HUB_GETDOCMETADATA: 'hubreq-getdocmetadata',
  HUB_GETDOCUMENTDATA: 'hubreq-getdocumentdata',

  HUB_ADDDOCCHANGE: 'hubreq-adddocchange',
  HUB_DELETEDOCCHANGE: 'hubreq-deletedocchange',

  HUB_DOCMETAUPDATE: 'hubreq-docmetaupdate',
  HUB_DOCEDITAPPROVEREJECTCHANGE: 'hubreq-doceditapproverejectchange',
  HUB_DOCMETAPARTICIPANTSADD: 'hubreq-docmetaparticipantsadd',
  HUB_DOCMETAPARTICIPANTSREMOVE: 'hubreq-docmetaparticipantsremove',
  HUB_DOCMETAADDNOTES: 'hubreq-docmetaaddnotes',

  HUB_GETLISTENERCOMMENTS: 'hubreq-getlistenercomments',
  HUB_GETLISTENERDOCEDITS: 'hubreq-getlistenerdocedits',
  HUB_GETLISTENERTAGS: 'hubreq-getlistenertags',

  // for now, keep this to local to get the document name
  HUB_DOCMETAQUERY4: 'hub-docmetaquery4',
}

// temporary values
const TEMPVALUES = {
  hubsource: 'hubsource',
  hubsourceauth: 'hubsourceauth',
  hubdest: 'http://0.0.0.0:8002',
  hubdestkey: 'hubdestkey',
  hubsecuritykey: 'hubsecuritykey',
  hubuserid: 'hubuserid',
  hubdestdocid: 'hubdestdocid',
}

class AIHUBDocumentService extends HUBApiService {
  // HUBgetdocdata({ tablename, id }) {
  //   const formData = new FormData()

  //   // security and routing
  //   formData.append('hubsource', TEMPVALUES.hubsource)
  //   formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
  //   formData.append('hubdest', TEMPVALUES.hubdest)
  //   formData.append('hubdestkey', TEMPVALUES.hubdestkey)
  //   formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
  //   formData.append('hubuserid', TEMPVALUES.hubuserid)
  //   formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

  //   formData.append('tablename', tablename)
  //   formData.append('id', id)
  //   return this.apiClient.post(ENDPOINTS.HUB_GETDOCDATA, formData)
  // }
  // replaced above with 2 below
  HUBgetdocmetadata({ id }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('id', id)
    return this.apiClient.post(ENDPOINTS.HUB_GETDOCMETADATA, formData)
  }
  HUBgetdocumentdata({ tablename, id }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('id', id)
    return this.apiClient.post(ENDPOINTS.HUB_GETDOCUMENTDATA, formData)
  }

  HUBadddocchange({ tablename, obj }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('tablename', tablename)
    formData.append('obj', JSON.stringify(obj))
    return this.apiClient.post(ENDPOINTS.HUB_ADDDOCCHANGE, formData)
  }

  HUBdeletedocchange({ tablename, obj, id }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('tablename', tablename)
    formData.append('obj', JSON.stringify(obj))
    formData.append('id', id)
    return this.apiClient.post(ENDPOINTS.HUB_DELETEDOCCHANGE, formData)
  }

  HUBdocmetaupdate({ id, modobj }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('id', id)
    formData.append('modobj', JSON.stringify(modobj))
    return this.apiClient.post(ENDPOINTS.HUB_DOCMETAUPDATE, formData)
  }

  HUBdoceditapproverejectchange({ docid, id, modobj }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('docid', docid)
    formData.append('id', id)
    formData.append('modobj', JSON.stringify(modobj))
    return this.apiClient.post(
      ENDPOINTS.HUB_DOCEDITAPPROVEREJECTCHANGE,
      formData
    )
  }

  HUBDocMetaParticipantsAdd({ id, field, value }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('id', id)
    formData.append('field', field)
    formData.append('value', value)
    return this.apiClient.post(ENDPOINTS.HUB_DOCMETAPARTICIPANTSADD, formData)
  }

  HUBDocMetaParticipantsRemove({ id, field, value }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('id', id)
    formData.append('field', field)
    formData.append('value', value)
    return this.apiClient.post(
      ENDPOINTS.HUB_DOCMETAPARTICIPANTSREMOVE,
      formData
    )
  }

  HUBDocMetaAddNotes({ id, field, value }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('id', id)
    formData.append('field', field)
    formData.append('value', JSON.stringify(value))
    return this.apiClient.post(ENDPOINTS.HUB_DOCMETAADDNOTES, formData)
  }
  HUBgetListenerComments({ docid }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('docid', docid)
    return this.apiClient.post(ENDPOINTS.HUB_GETLISTENERCOMMENTS, formData)
  }
  HUBgetListenerDocEdits({ docid }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('docid', docid)
    return this.apiClient.post(ENDPOINTS.HUB_GETLISTENERDOCEDITS, formData)
  }
  HUBgetListenerTags({ docid }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('docid', docid)
    return this.apiClient.post(ENDPOINTS.HUB_GETLISTENERTAGS, formData)
  }
  HUBdocmetaquery4({ doctypeid, doccategory }) {
    const formData = new FormData()

    // security and routing
    formData.append('hubsource', TEMPVALUES.hubsource)
    formData.append('hubsourceauth', TEMPVALUES.hubsourceauth)
    formData.append('hubdest', TEMPVALUES.hubdest)
    formData.append('hubdestkey', TEMPVALUES.hubdestkey)
    formData.append('hubsecuritykey', TEMPVALUES.hubsecuritykey)
    formData.append('hubuserid', TEMPVALUES.hubuserid)
    formData.append('hubdestdocid', TEMPVALUES.hubdestdocid)

    formData.append('doctypeid', doctypeid)
    formData.append('doccategory', doccategory)
    return this.apiClient.post(ENDPOINTS.HUB_DOCMETAQUERY4, formData)
  }
}

export default new AIHUBDocumentService()
