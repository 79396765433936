import store from '@state/store'

import {
  DBupdateobj,
  DBupdateobjsync,
  DBsetonesync,
  DBinsertonesync,
  DBgetobjsync,
} from '@/src/state/io/iodb.js'

import { DBHUBgetdocumentdata } from '@/src/state/io/iohub.js'

const TABLENAME = 'documents'

export const state = {
  // debug
  dbdocumentid: 0,
}

export const getters = {
  dbdocumentid(state) {
    return state.dbdocumentid
  },
}

export const mutations = {
  SET_ID(state, id) {
    state.dbdocumentid = id
  },
}

export const actions = {
  init({ commit }) {},

  // changed to update
  writeNewDocument({ commit, state }, payload) {
    var writeDocumentEx = {
      docRawData: payload.docRawData,
      // overwrite the prediction object for size reasons.
      predictions: [],
      saved: true,
      copied: '',
    }
    DBupdateobj(TABLENAME, payload.docid, writeDocumentEx)
  },

  openDocument({ commit, state }, payload) {
    DBgetobjsync(TABLENAME, payload.docid).then(function (doc) {
      if (doc.exists) {
        store.dispatch('panels/setOpenDoc', {
          uniqid: payload.uniqid,
          panelno: payload.panelno,
          docobj: doc.data,
        })
      }
    })
  },

  // Hub call to open document.
  openHUBDocument({ commit, state }, payload) {
    DBHUBgetdocumentdata(payload.docid).then(function (doc) {
      if (doc.exists) {
        store.dispatch('panels/setOpenHUBDoc', {
          uniqid: payload.uniqid,
          panelno: payload.panelno,
          docobj: doc.data,
        })
      }
    })
  },

  updateDocument({ commit, state }, payload) {
    DBupdateobj(TABLENAME, payload.docid, {
      docRawData: payload.docRawData,
    })
  },

  // SYNCHRONOUS
  // gets document and returns it
  getDocument({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      DBgetobjsync(TABLENAME, payload.docid)
        .then(function (doc) {
          if (doc.exists) {
            resolve(doc)
          } else {
            resolve(false)
          }
        })
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },

  // SYNCHRONOUS
  // write a new document and wait for new document to be available and return
  writeNewSyncDocument({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      DBinsertonesync(TABLENAME, payload)
        .then((doc) => resolve(doc))
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },

  // SYNCHRONOUS
  // write a document to an id'ed docid
  writeToDocSyncDocument({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      DBsetonesync(TABLENAME, payload.id, payload.data)
        .then((doc) => resolve(doc))
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },

  // SYNCHRONOUS
  // write a document to an id'ed docid
  updateDocSyncDocument({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      DBupdateobjsync(TABLENAME, payload.id, payload.update)
        .then((doc) => resolve(doc))
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },
}
