// Contains all application constants //

export const FR = {
  TOPMENU: {
    FILE: 'FICH',
    EDIT: 'ÉDIT',
    ACT: 'ACTE',
    DOCS: 'DOCS',
  },

  MENU: {
    NEWDOCUMENT: 'Nouveau document',
    // ORIGINAL TEXT: New Document
    SAVEDOCUMENT: 'Sauvegarder',
    // ORIGINAL TEXT: Save Document
    COPYDOCUMENT: 'Copier',
    // ORIGINAL TEXT: Copy Document
    CREATEVERSION: 'Créer une version propre',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: "Créer un document à partir d'un modèle",
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: 'Ouvrir',
    // ORIGINAL TEXT: Open Document
    OPENGROUPDOCUMENT: 'Ouvrir le document de groupe',
    // ORIGINAL TEXT: Open Group Document
    OPENHUBDOCUMENT: 'Ouvrir le HUB document',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: 'Ouvrir le document archivé',
    // ORIGINAL TEXT: Open Archive Document
    OPENPLAYBOOK: "Ouvrir livre d'instructions",
    // ORIGINAL TEXT: Open Playbook
    OPENTEMPLATE: 'Ouvrir le modèle',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: 'Enregistrer en tant que modèle',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: "Sauvegarder sous livre d'instructions",
    // ORIGINAL TEXT: Save As Playbook
    MOVETOARCHIVE: 'Déplacer vers les archives',
    // ORIGINAL TEXT: Move to Archive
    CLOSEDOCUMENT: 'Fermer',
    // ORIGINAL TEXT: Close Document
    DOCUMENTLOG: 'Journal des documents',
    // ORIGINAL TEXT: Document Log

    GOTOPARAGRAPH: 'Aller au paragraphe',
    // ORIGINAL TEXT: Go to Paragraph
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'Notes sur les documents',
    // ORIGINAL TEXT: Document Notes
    COLLABCOMMENTS: 'Collab - Commentaires',
    // ORIGINAL TEXT: Collab - Comments
    COLLABDOCEDITS: 'Collab - Edits',
    // ORIGINAL TEXT: Collab - DocEdits
    COLLABRISKANDLABELS: 'Collab - Risques et étiquettes',
    // ORIGINAL TEXT: Collab - Risk and Labels
    NEGOTCOMMENTS: 'Négoc - Commentaires',
    // ORIGINAL TEXT: Negot - Comments
    NEGOTDOCEDITS: 'Négoc - Edits',
    // ORIGINAL TEXT: Negot - DocEdits

    COLLABORATION: 'Collaboration',
    // ORIGINAL TEXT: Collaboration
    NEGOTIATION: 'Négociation',
    // ORIGINAL TEXT: Negotiation
    ADDOWNER: 'Ajouter un propriétaire',
    // ORIGINAL TEXT: Add Owner
    ADDCOLLABORATOR: 'Ajouter un collaborateur',
    // ORIGINAL TEXT: Add Collaborator
    ADDNEGOTIATOR: 'Ajouter un négociateur',
    // ORIGINAL TEXT: Add Negotiator
    TRIGGERALERTS: 'Déclencher des alertes',
    // ORIGINAL TEXT: Trigger Alerts
    EXPORTEXCEL: 'Exporter Excel',
    // ORIGINAL TEXT: Export Excel
    DOWNLOADSOURCE: 'Télécharger la source',
    // ORIGINAL TEXT: Download Source
    SENDTOCLM: 'Envoyer à CLM',
    // ORIGINAL TEXT: Send to CLM
    EXPORTJSON: 'Exporter JSON',
    // ORIGINAL TEXT: Export JSON
    ESIGNATURE: "Envoyer à l'Esignature",
    // ORIGINAL TEXT: Send for Esignature
    DOWNLOADREDLINE: 'Télécharger Redline DocX',
    // ORIGINAL TEXT: Download Redline DocX
    DOCUMENTSETTINGS: 'Paramètres du document',
    // ORIGINAL TEXT: Document Settings
    EXPAND: 'Élargir',
    // ORIGINAL TEXT: Expand
    COLLAPSE: 'Réduire',
    // ORIGINAL TEXT: Collapse

    TRANSLATETOENGLISH: 'Traduire en anglais',
    // ORIGINAL TEXT: Translate to English
    ORIGINALLANGUAGE: "Retour à l'original",
    // ORIGINAL TEXT: Revert to Original

    ALLPARAGRAPHS: 'Tous les paragraphes',
    // ORIGINAL TEXT: All Paragraphs
    EDITEDPARAGRAPHSONLY: 'Paragraphes modifiés seulement',
    // ORIGINAL TEXT: Edited Paragraphs Only

    NOEDITCLAUSELABELS: 'Ne pas modifier les étiquettes de clause',
    // ORIGINAL TEXT: No Edit Clause Labels
    EDITCLAUSELABELS: 'Modifier les étiquettes de clause',
    // ORIGINAL TEXT: Edit Clause Labels

    HIDEEDITS: 'Cacher les modifications',
    // ORIGINAL TEXT: Hide Edits
    SHOWEDITS: 'Afficher les modifications',
    // ORIGINAL TEXT: Show Edits

    SHOWALLEDITS: 'Afficher toutes les modifications',
    // ORIGINAL TEXT: Show All Edits
    SHOWNEGOTEDITSONLY: 'Afficher seulement les éditions de négoc',
    // ORIGINAL TEXT: Show Negot Edits Only

    GROUPS: 'Groupes',
    // ORIGINAL TEXT: Groups
    DOCUMENTTYPES: 'Types de documents',
    // ORIGINAL TEXT: Document Types
    ALERTS: 'Alertes',
    // ORIGINAL TEXT: Alerts
    QUICKSTARTTUTORIAL: 'Guide de démarrage rapide',
    // ORIGINAL TEXT: Quickstart Tutorial
    QUICKSTARTVIDEOS: 'Vidéos de démarrage rapide',
    // ORIGINAL TEXT: Quickstart Videos
    UPGRADESUBSCRIPTION: 'Passer à la version supérieure',
    // ORIGINAL TEXT: Upgrade Subscription
    SUBSCRIPTIONBILLING: 'Abonnement/Facturation',
    // ORIGINAL TEXT: Subscription/Billing
    ACCOUNTSETTINGS: 'Paramètres du compte',
    // ORIGINAL TEXT: Account Settings
    USERLOG: 'Journal de l’utilisateur',
    // ORIGINAL TEXT: User Log
    CHANGEPASSWORD: 'Changer le mot de passe',
    // ORIGINAL TEXT: Change Password
    LOGOUT: 'Déconnexion',
    // ORIGINAL TEXT: Logout

    // HEADINGS
    ADMIN: 'ADMIN',
    PROFILE: 'PROFIL',
    LPROFILE: 'Profil',
    FILE: 'FICH',
    EDIT: 'EDIT',
    ACT: 'ACT',
    TOPIC: 'SUJET',
    DOCS: 'DOCS',

    // Field Headings in Navigation Bar
    NAME: 'Nom',
    // ORIGINAL TEXT: Name
    ROLE: 'Rôle',
    // ORIGINAL TEXT: Role
    STATE: 'Condition',
    // ORIGINAL TEXT: State
    GROUP: 'Groupe',
    // ORIGINAL TEXT: Group
    TYPE: 'Type',
    // ORIGINAL TEXT: Type
    SENSIT: 'Sensib',
    // ORIGINAL TEXT: Sensit
    LTOPIC: 'Sujet',
    // ORIGINAL TEXT: Topic
    USER: 'Membre',
    // ORIGINAL TEXT: User

    // Other Headings
    REGISTER: 'ENREGISTRER',
    LOGIN: 'CONNECTEZ',
    LREGISTER: 'Enregistrer',
    LLOGIN: 'Connectez',

    ADMINEXPLAIN: 'Capacités administratives',
    // ORIGINAL TEXT: Administrative capabilities
    PROFILEEXPLAIN: 'Gestion des comptes, abonnements, facturation',
    // ORIGINAL TEXT: Account Management, Subscriptions, Billing

    // AUTH LEVELS
    PREMIUM: 'Premier',
    PREMIUMPREPAID: 'Premier (Prépayé)',
    BASIC: 'Base',
    BASICPREPAID: 'Base (Prépayé)',
    VIPACCESS: 'Accès VIP',
    VIPMEMBER: 'Membre VIP',
    UNLIMITED: 'Illimité',
    TRIALUSER: 'Utilisateur expérimental',
    REFERREDUSER: 'Utilisateur référencé',
    SOURCEDOCUMENT: 'Document source',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: 'Document de comparaison',
    // ORIGINAL TEXT: Comparison Document
    EXIT: 'SORTIR',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: 'Paragraphe',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: 'MODE DE COMPARAISON DE DOCUMENTS',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: 'AUCUN',
    // ORIGINAL TEXT: NONE
    PREAMBLE: 'PRÉAMBULE',
    // ORIGINAL TEXT: PREAMBLE
    DEFN: 'DÉFN',
    // ORIGINAL TEXT: DEFN
    PAYMENT: 'PAIEMENT',
    // ORIGINAL TEXT: PAYMENT
    TAXES: 'TAXES',
    // ORIGINAL TEXT: TAXES
    REPWARR: 'REPRÉS GARANT',
    // ORIGINAL TEXT: REP WARR
    PERIOD: 'PÉRIODE',
    // ORIGINAL TEXT: PERIOD
    TERMINATION: 'RÉSILIATION',
    // ORIGINAL TEXT: TERMINATION
    CONFID: 'CONFID',
    // ORIGINAL TEXT: CONFID
    INDEMNITY: 'INDEMNITÉ',
    // ORIGINAL TEXT: INDEMNITY
    LIABILITY: 'RESPONSABILITÉ',
    // ORIGINAL TEXT: LIABILITY
    INTELPROP: 'PROPRIÉTÉ INTEL',
    // ORIGINAL TEXT: INTEL PROP
    SIGNATURE: 'SIGNATURE',
    // ORIGINAL TEXT: SIGNATURE
    OTHER: 'AUTRES',
    // ORIGINAL TEXT: OTHER
    DATASECURITY: 'SÉCURITÉ DES DONNÉES',
    // ORIGINAL TEXT: DATA SECURITY
    ASSIGN: 'ASSIGNER',
    // ORIGINAL TEXT: ASSIGN
    NOTICE: 'AVIS',
    // ORIGINAL TEXT: NOTICE
    GOVERNLAW: 'DROIT APPLIC',
    // ORIGINAL TEXT: GOVERN LAW
    DISPUTE: 'CONFLITS',
    // ORIGINAL TEXT: DISPUTE
    ENTIREAGREE: 'ACCORD INTÉGRAL',
    // ORIGINAL TEXT: ENTIRE AGREE
    SURVIVAL: 'SURVIE',
    // ORIGINAL TEXT: SURVIVAL
    RELATPART: 'RELAT DES PART',
    // ORIGINAL TEXT: RELAT PART
    THIRDPARTY: 'TROISIÈME PARTIE',
    // ORIGINAL TEXT: THIRD PARTY
    FORCEMAJEURE: 'FORCE MAJEURE',
    // ORIGINAL TEXT: FORCE MAJEURE
    SEVERABILITY: 'SÉVERABILITÉ',
    // ORIGINAL TEXT: SEVERABILITY
    EXPORTCTRL: 'CONTRÔLE DES EXPORTS',
    // ORIGINAL TEXT: EXPORT CTRL
    INTRO: 'INTRO',
    // ORIGINAL TEXT: INTRO
    INSURANCE: 'ASSURANCE',
    // ORIGINAL TEXT: INSURANCE
    HEADING: 'TITRE',
    // ORIGINAL TEXT: HEADING
    ALL: 'TOUS',
    // ORIGINAL TEXT: ALL
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'l’adresse mail est requise',
    // ORIGINAL TEXT: E-mail is required
    EMAILVALID: 'l’adresse mail doit être valide',
    // ORIGINAL TEXT: E-mail must be valid
    MAX20CHARS: 'Max 20 caractères',
    // ORIGINAL TEXT: Max 20 characters
    MAX60CHARS: 'Max 60 caractères',
    // ORIGINAL TEXT: Max 60 characters
    MAX80CHARS: 'Max 80 caractères',
    // ORIGINAL TEXT: Max 80 characters
    MAX160CHARS: 'Max 160 caractères',
    // ORIGINAL TEXT: Max 160 characters
    EMAILALREADYOWNER: 'Email est déjà propriétaire',
    // ORIGINAL TEXT: Email is already an owner
    EMAILALREADYCOLLABORATOR: 'Email est déjà un collaborateur',
    // ORIGINAL TEXT: Email is already a collaborator
    EMAILALREADYNEGOTIATOR: 'Email est déjà un négociateur',
    // ORIGINAL TEXT: Email is already a negotiator
    EMAILALREADYOWNERDOCTYPE: 'Email est déjà propriétaire du type de document',
    // ORIGINAL TEXT: Email is already an owner of the document type
    EMAILALREADYCOLLABORATORDOCTYPE:
      'Email est déjà un collaborateur du type de document',
    // ORIGINAL TEXT: Email is already an collaborator of the document type
    REQUIRED: 'Requis',
    // ORIGINAL TEXT: Required.
    CLAUSEFIELDREQUIRED: 'Ce champ de clause est requis pour cette opération',
    // ORIGINAL TEXT: This clause field is required for this operation
    CLAUSENOTDEFAULT:
      "Impossible d'ajouter une clause à partir de la liste de clauses par défaut",
    // ORIGINAL TEXT: Cannot add clause from default clause list
    TAGFIELDREQUIRED: 'Ce champ d’étiquette est requis pour cette opération',
    // ORIGINAL TEXT: This tag field is required for this operation
    TAGNOTDEFAULT:
      'Impossible d’ajouter des étiquettes à partir de la liste d’étiquettes par défaut',
    // ORIGINAL TEXT: Cannot add tags from default tag list

    PARAINDEXREQUIRED: 'Un index des paragraphes est nécessaire',
    // ORIGINAL TEXT: Paragraph index is required
    PARAINDEXINVALID: 'L’index des paragraphes n’existe pas',
    // ORIGINAL TEXT: Paragraph index does not exist
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'sauvegarde le document',
    OPENDOCUMENT: 'ouvrir le document',
    // ORIGINAL TEXT: open document
    CLOSEDOCUMENT: 'fermer le document',
    // ORIGINAL TEXT: close document
    ADDNOTE: 'ajouter une note',
    // ORIGINAL TEXT: add note
    ADDCOLLABORATOR: 'ajouter un collaborateur',
    // ORIGINAL TEXT: add collaborator
    DELETECOLLABORATOR: 'supprimer le collaborateur',
    // ORIGINAL TEXT: delete collaborator
    ADDNEGOTIATOR: 'ajouter un négociateur',
    // ORIGINAL TEXT: add negotiator
    DELETENEGOTIATOR: 'supprimer le négociateur',
    // ORIGINAL TEXT: delete negotiator
    UPDATEARCHIVEDATA: 'mettre à jour les archives',
    // ORIGINAL TEXT: update archive data
    UPDATEDOCMETA: 'mettre à jour les métadonnées',
    // ORIGINAL TEXT: update doc metadata
    ADDOWNER: 'ajouter un propriétaire',
    // ORIGINAL TEXT: add owner
    DELETEOWNER: 'supprimer le propriétaire',
    // ORIGINAL TEXT: delete owner
    CHANGECOLLABORATION: 'modification des paramètres de collaboration',
    // ORIGINAL TEXT: changed collaboration settings
    CHANGENEGOTIATION: 'modification des paramètres de négociation',
    // ORIGINAL TEXT: changed negotiation settings
    SAVEPLAYBOOK: "enregistré comme livre d'instruction",
    // ORIGINAL TEXT: saved as playbook
    SAVETEMPLATE: 'enregistré comme modèle',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: 'enregistré comme archive',
    // ORIGINAL TEXT: saved as archived
    TRIGGERALERT: 'déclencher une alerte',
    // ORIGINAL TEXT: trigger alert
    TRIGGERALLALERT: 'lancer toutes les alertes',
    // ORIGINAL TEXT: trigger all alert
    TRIGGERCUSTOMALERT: 'lancer une alerte personnalisée',
    // ORIGINAL TEXT: trigger custom alert
    ADDCOMMENT: 'ajouter un commentaire',
    // ORIGINAL TEXT: add comment
    DELETECOMMENT: 'supprimer le commentaire',
    // ORIGINAL TEXT: delete comment
    ADDDOCEDIT: 'ajouter le edit',
    // ORIGINAL TEXT: add docedit
    ADDAPPROVEDDOCEDIT: 'ajouter le edit approuvé',
    // ORIGINAL TEXT: add approved docedit
    APPROVEDDOCEDIT: 'approuver le edit',
    // ORIGINAL TEXT: approve docedit
    REJECTDOCEDIT: 'rejeter le edit',
    // ORIGINAL TEXT: reject docedit
    NEGOTIATORAPPROVEDDOCEDIT: 'le négociateur a approuvé le edit',
    // ORIGINAL TEXT: negotiator approved docedit
    NEGOTIATORREJECTEDDOCEDIT: 'le négociateur a rejeté le edit',
    // ORIGINAL TEXT: negotiator rejected docedit
    DELETEDDOCEDIT: 'supprimer le edit',
    // ORIGINAL TEXT: delete docedit
    ATTACHCONTRACT: 'joindre le contrat exécuté',
    // ORIGINAL TEXT: attach exec contract
    ADDTAG: 'ajouter une étiquette',
    // ORIGINAL TEXT: add tag
    DELETETAG: "supprimer l'étiquette",
    // ORIGINAL TEXT: delete tag

    NEWSIGNATUREREQUEST: 'nouvelle signature demandée',
    // ORIGINAL TEXT: new signature requested
    CANCELSIGNATUREREQUEST: 'demande de signature annulée',
    // ORIGINAL TEXT: cancelled signature request

    CREATEREDLINEREQUEST: 'créer un nouveau document redline',
    // ORIGINAL TEXT: create new redline document
    CREATEFINALDOCREQUEST: 'créer un nouveau document final pour la signature',
    // ORIGINAL TEXT: create new final document for signature
    COPIEDDOCUMENT: 'copié à partir du document',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: 'créé une version propre à partir du document',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: "document créé à partir d'un modèle",
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'créer un doctype',
    // ORIGINAL TEXT: create doctype
    DELETEDOCTYPE: 'supprimer le doctype',
    // ORIGINAL TEXT: delete doctype
    ADDOWNERTODOCTYPE: 'propriétaire ajouté au doctype',
    // ORIGINAL TEXT: added owner to doctype
    DELETEOWNERFROMDOCTYPE: 'propriétaire supprimé du doctype',
    // ORIGINAL TEXT: deleted owner to doctype
    ADDCLAUSETODOCTYPE: 'clause addl ajoutée au doctype',
    // ORIGINAL TEXT: added addl clause to doctype
    DELETECLAUSEDOCTYPE: 'clause addl supprimée du doctype',
    // ORIGINAL TEXT: deleted addl clause to doctype
    ADDTAGDOCTYPE: 'étiquette ajoutée au doctype',
    // ORIGINAL TEXT: added addl tag to doctype
    DELETETAGDOCTYPE: 'étiquette supprimée du doctype',
    // ORIGINAL TEXT: deleted addl tag from doctype
    ADDCOLLABRULEDOCTYPE: 'règles de collaborations ajoutées au doctype',
    // ORIGINAL TEXT: added collab rules to doctype
    DELETECOLLABRULEDOCTYPE: 'les règles de collab supprimées du doctype',
    // ORIGINAL TEXT: deleted collab rules from doctype
    ADDNOTIFRULEDOCTYPE: 'règles de notification ajoutées au doctype',
    // ORIGINAL TEXT: added notification rules to  doctype
    DELETENOTIFRULEDOCTYPE: 'les règles de notification supprimées du doctype',
    // ORIGINAL TEXT: deleted notification rules from doctype
    ADDNEGOTRULEDOCTYPE: 'règles de négociation supprimées du doctype',
    // ORIGINAL TEXT: added negotiation rules to  doctype
    DELETENEGOTRULEDOCTYPE: 'suppression des règles de négociation du doctype',
    // ORIGINAL TEXT: deleted negotiation rules from doctype

    // GROUPS
    CREATEGROUP: 'groupe créé',
    // ORIGINAL TEXT: created group
    DELETEGROUP: 'groupe supprimé',
    // ORIGINAL TEXT: deleted group
    ADDOWNERGROUP: 'propriétaire ajouté au groupe',
    // ORIGINAL TEXT: added owner to group
    DELETEOWNERGROUP: 'propriétaire supprimé du groupe',
    // ORIGINAL TEXT: deleted Owner from group
    ADDCOLLABGROUP: 'collaborateur ajouté au groupe',
    // ORIGINAL TEXT: added collaborator to group
    DELETECOLLABGROUP: 'collaborateur supprimé du groupe',
    // ORIGINAL TEXT: deleted collaborator from group
    ADDDOCTYPETOGROUP: 'le doctype ajouté au groupe',
    // ORIGINAL TEXT: added doctype to group
    DELETEDOCTYPEGROUP: 'le doctype supprimé du groupe',
    // ORIGINAL TEXT: deleted doctype from group

    // ALERTS
    CREATEALERT: 'alerte créée',
    // ORIGINAL TEXT: created alert
    DELETEALERT: 'alerte supprimée',
    // ORIGINAL TEXT: deleted alert
    ADDOWNERALERT: "propriétaire ajouté à l'alerte",
    // ORIGINAL TEXT: added owner to alert
    DELETEOWNERALERT: "propriétaire supprimé dà l'alerte",
    // ORIGINAL TEXT: deleted owner from alert
    ADDDOCTYPEALERT: 'doctype ajouté à l’alerte',
    // ORIGINAL TEXT: added doctype to alert
    DELETEDOCTYPEALERT: "doctype supprimé de l'alerte",
    // ORIGINAL TEXT: deleted doctype from alert
    ADDCLAUSETOPICALERT: "sujet de clause ajouté à l'alerte",
    // ORIGINAL TEXT: added clause topic to alert
    DELETECLAUSETOPICALERT: "sujet de clause supprimé de l'alerte",
    // ORIGINAL TEXT: deleted clause topic from alert
    ADDRECIPIENTALERT: "le destinataire ajouté à l'alerte",
    // ORIGINAL TEXT: added recipient to alert
    DELETERECIPIENTALERT: "destinataire supprimé de l'alerte",
    // ORIGINAL TEXT: deleted recipient from alert
    ADDNOTIFRULEALERT: 'règles de notification ajoutées à l’alerte',
    // ORIGINAL TEXT: added notification rules to alert
    DELETENOTIFRULEALERT: "les règles de notification supprimées de l'alerte",
    // ORIGINAL TEXT: deleted notification rules from alert
    SETSELECTIVITYALERT: "définir la sélectivité de l'alerte",
    // ORIGINAL TEXT: set selectivity to  alert
  },

  USEREVENTS: {
    USERSIGNON: 'Utilisateur connecté',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT:
      "envoi d'une demande de réinitialisation du mot de passe envoyée",
    // ORIGINAL TEXT: password reset request sent
    CHANGEDPASSWORD: 'mot de passe modifié',
    // ORIGINAL TEXT: changed password
    DELETEDREGISTRATION: "enregistrement d'un utilisateur supprimé",
    // ORIGINAL TEXT: deleted user registration
    UPDATEDACCOUNTINFO: 'informations sur le compte actualisées',
    // ORIGINAL TEXT: updated account information
    UPGRADEDACCOUNTTOREFERRAL: 'passer à la version compte référé',
    // ORIGINAL TEXT: upgraded account to referred account
    DELETEDUSERACCOUNT: 'compte de l’utilisateur supprimé',
    // ORIGINAL TEXT: deleted user account
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: 'ACTIONS',
    // ORIGINAL TEXT: DOCUMENT ACTIONS
    COMMENTS: 'COMMENTAIRES',
    // ORIGINAL TEXT: COMMENTS
    DOCEDITS: 'DOC EDITS',
    // ORIGINAL TEXT: DOCUMENT EDITS
    LABELSRISK: 'LABELS ET RISQUES',
    // ORIGINAL TEXT: LABELS AND RISK
    TEXT: 'TEXTE',
    // ORIGINAL TEXT: TEXT
    MLE_DESC: 'DOCGILITY',
    // ORIGINAL TEXT: DOCGILITY
    CLAUSE: 'Clause',
    // ORIGINAL TEXT: Clause

    // PRIVILEGE
    OWNER: 'propriétaire',
    // ORIGINAL TEXT: owner
    COLLABORATOR: 'collaborateur',
    // ORIGINAL TEXT: collaborator
    NEGOTIATOR: 'négociateur',
    // ORIGINAL TEXT: negotiator
    OWNERGROUP: 'propriétaires(groupe)',
    // ORIGINAL TEXT: owner group
    COLLABORATORGROUP: 'collaborateurs(groupe)',
    // ORIGINAL TEXT: collaborator group

    // TITLES
    SAVEDOCUMENT: 'SAUVEGARDEZ LE DOCUMENT',
    // ORIGINAL TEXT: SAVE DOCUMENT
    SAVEARCHIVE: "SAUVEGARDER L'ARCHIVE",
    // ORIGINAL TEXT: SAVE ARCHIVE
    QUICKSTART: 'GUIDE DE DEMARRAGE RAPIDE',
    // ORIGINAL TEXT: QUICKSTART TUTORIAL
    TUTORIALVIDEOS: 'VIDEOS DE DEMARRAGE RAPIDE',
    // ORIGINAL TEXT: TUTORIAL VIDEOS

    MOVETOARCHIVEDOCUMENT: "DÉPLACER VERS LE DOCUMENT D'ARCHIVE",
    // ORIGINAL TEXT: MOVE TO ARCHIVE DOCUMENT
    OPENDOCUMENT: 'OUVRIR LE DOCUMENT',
    // ORIGINAL TEXT: OPEN DOCUMENT
    COPYDOCUMENT: 'COPIER LE DOCUMENT',
    // ORIGINAL TEXT: COPY DOCUMENT
    CREATEVERSION: 'CRÉER UNE VERSION PROPRE',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: "CRÉER UN DOCUMENT À PARTIR D'UN MODÈLE",
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: 'OUVRIR LE DOCUMENT DE GROUPE',
    // ORIGINAL TEXT: OPEN GROUP DOCUMENT
    OPENHUBDOCUMENT: 'OUVRIR LE HUB DOCUMENT',
    // ORIGINAL TEXT: OPEN HUB DOCUMENT
    OPENARCHIVEDOCUMENT: "OUVRIR UN DOCUMENT D'ARCHIVE",
    // ORIGINAL TEXT: OPEN ARCHIVE DOCUMENT
    OPENPLAYBOOK: "OUVRIR LE LIVRE D'INSTRUCTIONS",
    // ORIGINAL TEXT: OPEN PLAYBOOK
    OPENTEMPLATE: 'MODÈLE OUVERT',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'ENREGISTRER EN TANT QUE MODÈLE',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: 'COLLABORATION',
    // ORIGINAL TEXT: COLLABORATION
    NEGOTIATION: 'NÉGOCIATION',
    // ORIGINAL TEXT: NEGOTIATION
    ADDOWNER: 'AJOUTER LE PROPRIÉTAIRE',
    // ORIGINAL TEXT: ADD OWNER
    ADDNEGOTIATOR: 'AJOUTER UN NÉGOCIATEUR',
    // ORIGINAL TEXT: ADD NEGOTIATOR
    ADDCOLLABORATOR: 'AJOUTER UN COLLABORATEUR',
    // ORIGINAL TEXT: ADD COLLABORATOR
    ERRORMESSAGE: "MESSAGE D'ERREUR",
    // ORIGINAL TEXT: ERROR MESSAGE
    DOCUMENTINFO: 'INFORMATIONS SUR LE DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT INFORMATION
    DOCUMENTLOGS: 'JOURNAUX DE DOCUMENTS',
    // ORIGINAL TEXT: DOCUMENT LOGS
    USERLOGS: 'JOURNAUX DES UTILISATEURS',
    // ORIGINAL TEXT: USER LOGS
    DOCUMENTNOTES: 'NOTES DE DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT NOTES
    TRIGGERALERTS: 'ALERTES DE DÉMARRAGE',
    ALERTS: 'ALERTES',
    DOCTYPES: 'TYPES DE DOCUMENTS',
    GROUPS: 'GROUPES',
    NOTE: 'NOTE',
    GOTOPARAGRAPH: 'ALLER AU PARAGRAPHE',
    // ORIGINAL TEXT: GO TO PARAGRAPH
    ESIGNATURE: 'E-SIGNATURE',
    // ORIGINAL TEXT: E-SIGNATURE
    DOWNLOADREDLINE: 'TÉLÉCHARGER REDLINE DOCX',
    // ORIGINAL TEXT: DOWNLOAD REDLINE DOCX

    // ACTIONS
    ADD: 'AJOUTER',
    BACK: 'RETOUR',
    DELETE: 'SUPPRIMER',
    SAVE: 'SAUVEGARDER',
    MOVETOARCHIVE: 'DÉPLACER VERS LES ARCHIVES',
    COPY: 'COPIER',
    CREATE: 'CRÉER',
    // ORIGINAL TEXT: CREATE
    OPEN: 'OUVRIR',
    CLOSE: 'FERMER',
    UPDATE: 'ACTUALISER',
    REGISTER: 'ENREGISTRER',
    LOGIN: 'CONNECTER',
    LOGINSSO: 'CONNECTEZ SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: 'RÉINITIALISER LE MOT DE PASSE',
    CHANGEPASSWORD: 'CHANGER LE MOT DE PASSE',
    CANCELACCOUNT: 'ANNULER LE COMPTE',
    QUICKSTARTVIDEO: 'REGARDER LA VIDÉO DE DÉMARRAGE RAPIDE',

    CHARGEBASIC: 'PASSER A LA VERSION DE BASE',
    // ORIGINAL TEXT: UPGRADE TO BASIC
    CHARGEPREMIUM: 'PASSER À LA VERSION PREMIUM',
    // ORIGINAL TEXT: UPGRADE TO PREMIUM
    CHARGECORPORATE: 'PASSER AU STATUT DE SOCIÉTÉ',
    // ORIGINAL TEXT: UPGRADE TO CORPORATE
    UPGRADESUBSCRIPTION: 'PASSER LA VERSION ABONNEMENT SUPÉRIEURE',
    // ORIGINAL TEXT: UPGRADE SUBSCRIPTION
    GO: 'ALLEZ',
    // ORIGINAL TEXT: GO
    // PREVIEW_FEEDBACK: 'CHANGE',
    // ORIGINAL TEXT: REVIEW CHANGES
    ACTIONS: 'ACT',
    // ORIGINAL TEXT: ACT
    REFRESH: 'REFRAÎCHIR',
    // ORIGINAL TEXT: REFRESH
    ADDNEWGROUP: 'AJOUTER UN NOUVEAU GROUPE',
    // ORIGINAL TEXT: ADD NEW GROUP
    ADDNEWDOCTYPE: 'AJOUTER UN NOUVEAU DOCTYPE',
    // ORIGINAL TEXT: ADD NEW DOCTYPE
    ADDNEWALERT: 'AJOUTER UNE NOUVELLE ALERTE',
    // ORIGINAL TEXT: ADD NEW ALERT
    GROUPSLIST: 'REVOIR LA LISTE DES GROUPES',
    // ORIGINAL TEXT: REVIEW GROUP LIST

    DOWNLOADDOCUMENT: 'TÉLÉCHARGER DE DOCUMENT',
    // ORIGINAL TEXT: DOWNLOAD DOCUMENT

    DOCUMENTTITLE: 'Titre du document',
    // ORIGINAL TEXT: Document Title
    DOCUMENTSUBJECT: 'Sujet du document',
    // ORIGINAL TEXT: Document Subject
    SIGNMESSAGE: 'Message pour la signature',
    // ORIGINAL TEXT: Message for Signing
    NAMEPRIMARYSIGNER: 'Nom: signataire principal',
    // ORIGINAL TEXT: Name: Primary Signer
    EMAILPRIMARYSIGNER: 'Courriel : signataire principal',
    // ORIGINAL TEXT: Email: Primary Signer
    NAMECOUNTERSIGNER: 'Nom: contre-signataire',
    // ORIGINAL TEXT: Name: Counter Signer
    EMAILCOUNTERSIGNER: 'Courriel: contre-signataire',
    // ORIGINAL TEXT: Email: Counter Signer
    SIGREQUESTED: 'SIGNATURE DEMANDÉE',
    // ORIGINAL TEXT: SIGNATURE REQUESTED
    SIGREQUESTEDBY: 'DEMANDÉ PAR',
    // ORIGINAL TEXT: REQUESTED BY
    SIGREQUESTEDID: 'ID SIGNATURE',
    // ORIGINAL TEXT: SIGNATURE ID
    SIGREQUESTEDAT: 'DEMANDÉ AU',
    // ORIGINAL TEXT: REQUESTED AT
    SUBMITSIG: 'SOUMIS À LA SIGNATURE',
    // ORIGINAL TEXT: SUBMIT FOR SIGNATURE
    CANCELSIG: 'ANNULEZ LA DEMANDE DE SIGNATURE',
    // ORIGINAL TEXT: CANCEL SIGNATURE REQUEST

    // DATA LABELS
    NAME: 'NOM',
    EMAIL: 'EMAIL',
    STATUS: 'STATUT',
    // ORIGINAL TEXT: STATUS
    LASTVIEWED: 'DERNIÈRE VUE',
    // ORIGINAL TEXT: LAST VIEWED
    LASTREVIEWED: 'DERNIER EXAMEN',
    // ORIGINAL TEXT: LAST REVIEWED
    SIGNEDAT: 'SIGNÉ',
    // ORIGINAL TEXT: SIGNED
    OWNERAPPROVAL: 'APPROBATION DU PROPRIÉTAIRE',
    // ORIGINAL TEXT: OWNER APPROVAL
    OWNERACTION: 'ACTION DU PROPRIÉTAIRE',
    // ORIGINAL TEXT: OWNER ACTION
    NEGOTIATORAPPROVAL: 'APPROBATION DU NÉGOCIATEUR',
    // ORIGINAL TEXT: NEGOTIATOR APPROVAL
    NEGOTIATORACTION: 'ACTION DU NÉGOCIATEUR',
    // ORIGINAL TEXT: NEGOTIATOR ACTION
    ARCHIVENOTES: "NOTES D'ARCHIVES",
    // ORIGINAL TEXT: ARCHIVE NOTES
    EXECUTEDCONTRACT: 'EXÉCUTÉ',
    // ORIGINAL TEXT: EXECUTED
    EXECUTEDDATE: "DATE D'EXÉCUTION",
    // ORIGINAL TEXT: EXECUTED DATE
    CONTRACTATTACHED: 'CONTRAT JOINT',
    // ORIGINAL TEXT: CONTRACT ATTACHED
    STARTDATE: 'DATE DE DÉPART',
    // ORIGINAL TEXT: START DATE
    ENDDATE: 'DATE DE FIN',
    // ORIGINAL TEXT: END DATE
    EDIT: 'CORRIGER',
    USER: 'MEMBRE',
    TIME: 'TEMPS',
    CONFIRM: 'CONFIRMER',
    // ORIGINAL TEXT: CONFIRM
    CONFIRMACTION: "CONFIRMER L'ACTION",
    // ORIGINAL TEXT: CONFIRM ACTION
    CURRENT_LABEL: 'ÉTIQUETTE ACTUELLE',
    // ORIGINAL TEXT: CURRENT LABEL
    NEW_LABEL: 'NOUVEAU ETIQUETTE',
    // ORIGINAL TEXT: NEW LABEL
    DESCRIPTION: 'DESCRIPTION',
    TYPE: 'TYPE',
    SENTENCE: 'PHRASE',
    NUMBER: '#',
    // ORIGINAL TEXT: #
    PRIVILEGE: 'RÔLE',
    // ORIGINAL TEXT: ROLE
    OWNERID: 'ID DU PROPRIÉTAIRE',
    // ORIGINAL TEXT: OWNERID
    DOCNAME: 'NOM DU DOCUMENT',
    // ORIGINAL TEXT: DOC NAME
    DOCTYPE: 'TYPE DE DOCUMENT',
    // ORIGINAL TEXT: DOC TYPE
    DOCDESCRIPTION: 'DESCRIPTION',
    // ORIGINAL TEXT: DOC DESCRIPTION
    DOCNOTES: 'NOTES DE DOC',
    // ORIGINAL TEXT: DOC NOTES
    DOCPARTY: 'PARTIE ORIGINALE',
    // ORIGINAL TEXT: PARTY
    DOCCOUNTERPARTY: 'CONTRE-PARTIE',
    // ORIGINAL TEXT: COUNTER PARTY
    DOCRAWFILENAME: 'NOM DU FICHIER DU DOCUMENT',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'ÉTIQUETTES',
    // ORIGINAL TEXT: TAGS
    COLLABORATIONSTATUS: 'COLLAB - STATUT',
    // ORIGINAL TEXT: COLLAB STATUS
    COLLABORATIONEND: 'COLLAB - FIN',
    // ORIGINAL TEXT: COLLAB END
    NEGOTIATIONSTATUS: 'NEGOC - STATUT',
    // ORIGINAL TEXT: NEGOT STATUS
    NEGOTIATIONEND: 'NEGOC - FIN',
    // ORIGINAL TEXT: NEGOT END
    DOCID: 'ID DOC',
    // ORIGINAL TEXT: DOC ID
    PARAGRAPHID: 'ID DU PARAGRAPHE',
    // ORIGINAL TEXT: PARA ID
    ACTION: 'ACTION JOUNALISÉE',
    // ORIGINAL TEXT: LOGGED ACTION
    DETAIL: "DÉTAIL DE L'ACTION",
    // ORIGINAL TEXT: ACTION DETAIL
    CONTEXT: 'CONTEXTE SUPPLÉMENTAIRE',
    // ORIGINAL TEXT: ADDITIONAL CONTEXT
    GROUPNAME: 'NOM DU GROUPE',
    // ORIGINAL TEXT: GROUP NAME
    GROUPDESC: 'DESCRIPTION DU GROUPE',
    // ORIGINAL TEXT: GROUP DESCRIPTION
    DOCTYPEDESC: 'DESCRIPTION DU DOCTYPE',
    // ORIGINAL TEXT: DOC TYPE DESCRIPTION
    ADDLCLAUSETYPES: 'PERSONNALISER LES ÉTIQUETTES DE CLAUSE',
    // ORIGINAL TEXT: CUSTOM CLAUSE LABELS
    ADDLTAGS: 'ÉTIQUETTES PERSONNALISÉES',
    // ORIGINAL TEXT: CUSTOM TAGS
    COLLABORATIONRULES: 'RÈGLES DE COLLABORATION',
    // ORIGINAL TEXT: COLLABORATION RULES
    NEGOTIATIONRULES: 'RÈGLES DE NÉGOCIATION',
    // ORIGINAL TEXT: NEGOTIATION RULES
    NOTIFICATIONRULES: 'RÈGLES DE NOTIFICATION',
    // ORIGINAL TEXT: NOTIFICATION RULES
    LOG: 'LOG',
    // ORIGINAL TEXT: LOG
    ALERTNAME: "NOM DE L'ALERTE",
    // ORIGINAL TEXT: ALERT NAME
    ALERTDESC: "DESCRIPTION DE L'ALERTE",
    // ORIGINAL TEXT: ALERT DESCRIPTION
    TOPICS: 'SUJETS',
    // ORIGINAL TEXT: TOPICS
    RECIPIENTS: 'DESTINATAIRES',
    // ORIGINAL TEXT: RECIPIENTS
    SELECTIVITY: 'SELECTIVITÈ',
    // ORIGINAL TEXT: SELECTIVITY
    FIELDNAME: 'NOM',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: 'ORDRE',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: 'EXEMPLE',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: 'DÉFAUT',
    // ORIGINAL TEXT: DEFAULT
    VALUE: 'VALEUR',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: 'DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: "LE LIVRE D'INSTRUCTIONS",
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: 'MODÈLE',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: 'DOCUMENT ARCHIVÉ',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: 'ORIGINÉ DU MODÈLE :',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: 'COL',
    // ORIGINAL TEXT: COL
    NEGSHORT: 'NEG',
    // ORIGINAL TEXT: NEG
    OWNERS: 'PROPRIÉTAIRE',
    // ORIGINAL TEXT: OWNERS
    COLLABORATORS: 'COLLABORATEURS',
    // ORIGINAL TEXT: COLLABORATORS
    NEGOTIATORS: 'NÉGOCIATEURS',
    // ORIGINAL TEXT: NEGOTIATORS

    // ADDITION
    DOCUMENTDETAILS: 'DÉTAILS DU DOCUMENT',
    CREATIONDATE: 'DATE DE CRÉATION',
    PARTICIPANTS: 'PARTICIPANTS',
    COLLABORATIONANDNEGOTIATION: 'COLLABORATION ET NÉGOCIATION',
    DOCTYPEANDGROUPACCESS: 'TYPE DE DOCUMENT ET ACCÈS AUX GROUPES',
    GROUPOWNERS: 'PROPRIÉTAIRES DU GROUPE',
    GROUPCOLLABORATORS: 'COLLABORATEURS DU GROUPE',
    DOCUMENTSETTINGS: 'PARAMÈTRES DU DOCUMENT',
    ENABLECOLLABTOMODLABELS:
      'Permettre aux collaborateurs de modifier les libellés des clauses',
    ENABLENEGOTCAPABILITIES:
      'Activer les capacités de négociation (pas le mode de négociation)',
    KEEPDOCNOTES: "Copier des notes de document avec l'opération de copie",
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS:
      'Révision et modification des sujets des clauses, le cas échéant',
    NOEXECUTIONDATE: "Pas de date d'exécution",
    EXECUTIONDATE: "Date d'exécution",
    CONTRACTSTARTDATE: 'Date de début du contrat',
    CONTRACTENDDATE: 'Date de fin du contrat',
    EXECCONTRACTNAME: 'Nom du fichier du contrat exécuté',
    SELECTEXECCONTRACT:
      'sélectionnez le fichier pour télécharger le contrat exécuté',
    UPLOADEXECUTEDCONTRACT: 'TÉLÉCHARGER LE CONTRAT EXÉCUTÉ',
    LOADDEFAULTS: 'CHARGER LES DÉFAUTS',
    CLOSED: 'FERMÉ',
    NONE: 'NON',
    NA: 'NA',
    RESULTEXECUTEDCONTRACT: 'Résultant en un contrat exécuté',
    NOSTARTDATE: 'Pas de date de début',
    NOENDDATE: 'Pas de date de fin',
    EXCLUDECOLLABORATORSFROMACCESS:
      "Exclure les collaborateurs externes de l'accès au document",
    EXCLUDENEGOTIATORSFROMACCESS:
      "Exclure les négociateurs externes de l'accès au document",
    REVIEWEDAMENDEDCLAUSELABELS:
      'Révision et modification des libellés des clauses, le cas échéant',

    ENTERPARAGRAPHBETWEEN: 'Entrez un numéro de paragraphe entre :',
    GOTOPARAGRAPHINDEX: "Aller à l'index des paragraphes",
    INPUTDOCUMENTNOTEHERE: 'Entrez la note du document ici',
    INPUTCOMMENTHERE: 'Saisissez votre commentaire ici',
    SHOWPENDINGONLY: 'Afficher uniquement les demandes en attente',
    COLLABORATIONSETTING: 'CADRE DE COLLABORATION',
    NEGOTIATIONSETTING: 'CADRE DE NÉGOCIATION',
    NONEGOTWHENAPPROVED: 'pas de négociation une fois le document approuvé',
    EMAILTOADD: 'Email à ajouter',
    CLAUSETOADD: 'Clause à ajouter',
    TAGTOADD: 'Étiquette à ajouter',

    OPTIONSFORREDLINEINCLUDE: 'Options pour les lignes directrices à inclure',
    // ORIGINAL TEXT: Options for Redlines to Include
    ONLYINCLUDEOWNERAPPROVEDEDITS:
      'Inclure uniquement les éditions de documents approuvées par le propriétaire',
    // ORIGINAL TEXT: Only Include Owner-approved Document Edits
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'Inclure uniquement les éditions de documents approuvées par le propriétaire et le négociateur',
    // ORIGINAL TEXT: Only Include Owner and Negotiator-approved Document Edits
    INCLUDEALLDOCEDITS:
      "Inclure toutes les éditions de documents (quelle que soit l'approbation)",
    // ORIGINAL TEXT: Include All Document Edits (regardless of approval)
    REDLINECHANGESSELECTED: 'Changements redondants sélectionnés',
    // ORIGINAL TEXT: Redlined Changes Selected
    TOTALCHANGES: 'Total des changements',
    // ORIGINAL TEXT: Total Changes
    REPLACEREDLINES: 'Remplacer les lignes rouges',
    // ORIGINAL TEXT: Replace Redlines
    DELETEREDLINES: 'Supprimer les lignes rouges',
    // ORIGINAL TEXT: Delete Redlines
    ADDAFTERREDLINES: 'Ajouter des lignes rouges',
    // ORIGINAL TEXT: Add-After Redlines
    OPTIONSFORPREVREDLINES: 'Options pour les lignes rouges précédentes',
    // ORIGINAL TEXT: Options for Previous Redlines
    OVERWRITEPREVREDLINES:
      'écraser toutes les lignes rouges précédentes dans la source de document',
    // ORIGINAL TEXT: Overwrite all Previous Redlines in Document Source
    KEEPPREVREDLINES:
      'Conservez toutes les lignes directrices antérieures dans la source de document',
    // ORIGINAL TEXT: Keep all Previous Redlines in Document Source
    GENERATEFINALDOC: 'Générer le document final (sans lignes rouges)',
    // ORIGINAL TEXT: Generate Final Document (without Redlines)
    DEFAULTESIGNOTE:
      'Tel que discuté, veuillez fournir votre signature électronique au présent contrat.',
    // ORIGINAL TEXT: As discussed, please provide your eSignature to this contract.
    SUBMITTEDSIGMESSAGE: 'Soumis pour signatures. Veuillez patienter.',
    // ORIGINAL TEXT: Submitted for signatures.  Please wait.
    UPLOADEDMESSAGE: 'Téléchargé',
    // ORIGINAL TEXT: Uploaded
    ADDADDLFILES:
      'Facultatif - Ajout de fichiers supplémentaires à la demande de signature',
    // ORIGINAL TEXT: Optional - Adding additional files to signature request
    ADDFILESMSG: 'ajouter des fichiers supplémentaires pour la signature',
    // ORIGINAL TEXT: add additional files for signature
    ESIGPREPAIDPLANS:
      "La fonction de signature électronique n'est disponible que via nos forfaits prépayés.",
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      'Contactez-nous à billing@mylegaleinstein.com pour plus de détails.',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: 'Plus de vidéos sur le ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'Le type de document n’est pas compatible. MLE accepte uniquement les formats docx et pdf.',
    // ORIGINAL TEXT: Document type is not supported.  MLE only accepts docx and pdf formats.
    PDFSUPPORTEDFORPREMIUMONLY:
      "Pour la numérisation PDF, l'utilisateur doit passer à l'abonnement Premium ou supérieur.",
    // ORIGINAL TEXT: For PDF scanning, user needs to upgrade to Premium subscription or better.
    BADDOCFORMAT:
      'Mauvais format de document a été passé au traitement des documents.',
    // ORIGINAL TEXT: Bad Document format was passed to document processing.
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      "Vous n'avez pas le privilège d'apporter des modifications aux paramètres du document. Vous devez être propriétaire.",
    // ORIGINAL TEXT: You do not have the privilege to make changes to the Document Settings.  You are required to be an Owner.
    PRIVILEGSNOTAVAIL:
      'Les privilèges pour cet utilisateur ne sont pas disponibles. Essayez à nouveau plus tard.',
    // ORIGINAL TEXT: The privileges for this user are not available.  Try again later.
    EXHAUSTEDTRIALLICENSE:
      "Vous avez épuisé le nombre de documents autorisés pendant votre période d'essai. Veuillez selectionner 'mise a niveau de l’abonnement' dans votre profil.",
    // ORIGINAL TEXT: You have exhausted the number of allowed documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    EXHAUSTEDMONTHLYLIMIT:
      "Vous avez épuisé le nombre de documents mensuels autorisés pendant votre période d'essai. Veuillez selectionner 'mise a niveau de l’abonnement' dans votre profil.",
    // ORIGINAL TEXT: You have exhausted the number of allowed monthly documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    PASSWORDNOTMATCHING:
      'Les mots de passe ne correspondent pas. Vérifiez à nouveau les mots de passe.',
    // ORIGINAL TEXT: Passwords do not match.  Check the passwords again.
    ABAMEMBERIDLENGTH:
      "Les ID d'adhésion à l'ABA doivent contenir 7 caractères. Veuillez examiner et soumettre.",
    // ORIGINAL TEXT: ABA Membership IDs are 7 characters long.  Please review and submit.
    TERMSNOTCHECKED: "Les modalités n'ont pas été examinées et vérifiées.",
    // ORIGINAL TEXT: The Terms and Conditions have not been reviewed and checked.
    EMAILFORMATBAD:
      "Le format d'email n'est pas valide. Veuillez vérifier l'adresse e-mail.",
    // ORIGINAL TEXT: Email format is not valid.  Please review email address.
    CANCELEDACTION:
      "Votre dernière action a été annulée. L'application va maintenant recharger.",
    // ORIGINAL TEXT: Your last action have been canceled.  Application will now reload.
    SUCCESSACTION:
      "Votre dernière action a été réussie. L'application va maintenant recharger.",
    // ORIGINAL TEXT: Your last action has been successful.  Application will now reload.
    USERSUCCESSLOGIN: "L'utilisateur est connecté avec succès.",
    // ORIGINAL TEXT: User has successfully logged on.
    USERLOGGEDOUT: "L'utilisateur a été déconnecté.",
    // ORIGINAL TEXT: The user has been logged out
    EMAILNOTVERIFIED:
      "L'email du compte n'a pas encore été vérifié. Une vérification par e-mail vous a été envoyée.",
    // ORIGINAL TEXT: Account email has not yet been verified.  An email verification has been sent to you.
    EMAILALREADYREGISTERED: 'Cet email correspond à un compte déjà enregistré.',
    // ORIGINAL TEXT: This email already has a previously registered account.
    EMAILNOTVERIFIEDRESENT:
      "Votre e-mail n'a pas été vérifié. Nous vous avons renvoyé un lien de verification.",
    // ORIGINAL TEXT: Your email has not been verified.  We have resent you an email verification link to your email to activate the new account.
    USERLOGFILENOTEXISTS:
      "Erreur - aucun fichier utilisateur de journal n'existe.",
    // ORIGINAL TEXT: Error - no log user file exists.
    PASSWORDRESETSENT:
      'Un message de réinitialisation du mot de passe a été envoyé pour votre compte.',
    // ORIGINAL TEXT: An email password reset message has been sent for your account.
    PASSWORDCHANGED: 'Votre mot de passe a été modifié.',
    // ORIGINAL TEXT: Your password has been changed.
    REGISTRATIONDELETED: 'l’enregistrement de l’utilisateur a ete supprimé.',
    // ORIGINAL TEXT: deleted user registration
    USERACCOUNTUPDATED:
      'Vous avez mis à jour les paramètres de votre compte utilisateur. Les modifications prendront effet la prochaine fois que vous vous connecterez.',
    // ORIGINAL TEXT: You have updated your user account settings.  Changes will take into effect the next time you login.
    UPGRADEDTOREFERRED:
      "Votre compte a été mis à jour pour l'abonnement d'essai référencé.",
    // ORIGINAL TEXT: Your account has been updated to Referred Trial subscription.
    REGISTEREDTRIALUSER:
      "Un nouveau compte est enregistré en tant qu'abonnement d'essai référencé.",
    // ORIGINAL TEXT: New account is registered as Trial subscription.
    DELETEDUSERACCOUNT: 'Compte de profil utilisateur supprimé.',
    // ORIGINAL TEXT: Deleted user profile account
    EMAILALREADYREGISTEREDRESENT:
      "L'email n'a pas encore été enregistré auprès de Docgility. Une invitation par e-mail a été envoyée au nouvel utilisateur.",
    // ORIGINAL TEXT: Email has not already been registered with Docgility.  An email invitation has been sent to the new user.
    UPGRADEEXTERNALPAYMENTS:
      "Merci pour la mise à niveau. Vous serez réacheminé vers un service de paiement externe et après avoir terminé votre transaction, vous devrez vous connecter à nouveau pour le service d'abonnement avant de pouvoir commencer.",
    // ORIGINAL TEXT: Thanks for upgrading.  You will be rerouted to an external payment service and after completing your transaction, you will need to login again for subscription service to start.
    BILLINGEXTERNALPAYMENTS:
      'Veuillez confirmer que vous aimerez être dirigé vers notre service de paiement et de facturation externe. Après avoir apporté des modifications, vous devrez vous connecter à nouveau pour que les modifications prennent effet.',
    // ORIGINAL TEXT: Please confirm that you will like to be directed to our external payment and billing service.  After you make any changes, you will need to login again for changes to take effect.
    NOTABLETOLOGINATTHISTIME:
      'Impossible de se connecter pour le moment. Essayez de nouveau plus tard.',
    // ORIGINAL TEXT: Not able to login at this time.  Try again later.
    NOTREGISTERED:
      "L'utilisateur n'a pas encore été enregistré. Inscrivez-vous avant d'essayer de vous connecter.",
    // ORIGINAL TEXT: User has not previously registered.  Register before trying to login.
    NAMETOOLONG:
      'Le nom que vous avez saisi est trop long. Il doit comporter un maximum de 60 caracteres.',
    // ORIGINAL TEXT: The name you entered is too long.  Max is 60 characters.
    COMPANYTOOLONG:
      'La compagnie que vous avez entrée est trop longue. Il doit comporter un maximum de 60 caracteres.',
    // ORIGINAL TEXT: The company you entered is too long.  Max is 60 characters.
    COMMENTTOOLONG:
      'Les commentaires que vous avez saisis sont trop longs. Il doit comporter un maximum de 10000 caracteres.',
    // ORIGINAL TEXT: The comments you entered is too long.  Max is 10000 characters.
    DOCEDITTOOLONG:
      'Le document que vous avez saisi est trop long. Il doit comporter un maximum de 10000 caracteres.',
    // ORIGINAL TEXT: The document edits you entered is too long.  Max is 10000 characters.
    TITLETOOLONG:
      'Le titre que vous avez saisi est trop long. Il doit comporter un maximum de 60 caracteres.',
    // ORIGINAL TEXT: The title you entered is too long.  Max is 60 characters.
    PASSWORDTOOSHORT:
      "Le mot de passe que vous avez saisi est trop court. Le mot de passe doit être d'au moins 6 caractères.",
    // ORIGINAL TEXT: The password you entered is too short.  Password has to be at least 6 characters.
    CONGRATSNEWDOCUMENTPROCESSING:
      'Super! Vous avez traité un nouveau document. Cela prend quelques minutes et le panneau devrait se rafraîchir lorsque le document est terminé d’être traité. Veuillez patienter quelques minutes.',
    // ORIGINAL TEXT: Great! You have processed a new document.  It takes a few minutes and the panel should refresh when the document is completed processing.  Please wait a few minutes.
    // ADDITION
    NODOCUMENTLOADED: 'Aucun document chargé',
    SELECTFILETOUPLOADDOCX: 'sélectionnez le fichier à télécharger (*.docx)',
    SELECTFILETOUPLOADDOCXPDF:
      'sélectionnez le fichier à télécharger (*.docx, *.pdf)',
    INPUTCOMMENTHERE: 'Saisissez votre commentaire ici',
    AUTOAPPROVEASOWNER: 'Approbation automatique en tant que propriétaire',
    INPUTDOCEDITSHERE: 'Saisissez le doc edit ici.',
    TRIGGEREDALLALERTS:
      'Déclencher toutes les alertes (le cas échéant) pour ce document doctype.',
    // ORIGINAL TEXT: Triggered all (if any) Alerts for this doctype document.
    QSINTROMESSAGE:
      'MyLegalEinstein analyse instantanément vos contrats, liste par sujets juridiques, et permet des comparaisons côte à côte du langage juridique dans une plateforme complète de collaboration contractuelle.',
    // ORIGINAL TEXT: MyLegalEinstein instantly analyzes your contracts, indexes by legal topics, and allows side-by-side comparisons of legal language in a complete contract collaboration platform. We will demonstrate the capabilities in four simple steps (detailed instructions are provided in english but similar in all languages.)
    QSDOCMESSAGE:
      'Pour votre commodité, voici quelques contrats de domaine public que vous pouvez télécharger et essayer:',
    // ORIGINAL TEXT: For your convenience, here are some public domain contracts that you can download and try:
    QSHELPMESSAGE:
      'Voici un lien pratique vers la documentation du produit (avec une version verbeuse du Quick Start)',
    DOCUMENTFAILEDTOPROCESS:
      "Une erreur fatale s'est produite lors du traitement du document. Veuillez réessayer. Si le problème persiste, veuillez contacter le support client à support@mylegaleinstein.com.",
    // ORIGINAL TEXT: A fatal error occurred while processing the document.  Please try again.  If problem persists, please contact customer support at support@mylegaleinstein.com.

    // ADDED
    DOCUMENTSUCCESSCOPIED: 'Votre document a maintenant été copié.',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      'Une version propre de ce document a maintenant été générée et enregistrée.',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      'Un document a été généré à partir de ce modèle et enregistré.',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE:
      'Un document a été converti en modèle de document.',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      "Veuillez confirmer que vous voulez supprimer le document spécifié. Après la suppression du document, il ne sera plus disponible. Vous pouvez également envisager de déplacer le document vers le statut d'archive. \n\n Veuillez confirmer l'opération de suppression.",
    // ORIGINAL TEXT: Please confirm that you want to delete the specified document.  After document is deleted, it will no longer be available.  You may also consider moving the document to Archive status.  \n\n Please confirm delete operation.
    CONFIRMSAVETOPLAYBOOK:
      "Merci de confirmer que pour convertir ce document en playbook. Cela rendrait ce document inchangable sauf pour le propriétaire. Merci de confirmer l'opération de sauvegarde du livre d'instructions.",
    // ORIGINAL TEXT: Please confirm that to convert this document to a playbook.  This would make this document unchangable except for the owner.  \n\n Please confirm the save to playbook operation.
    COLLABORATIONNOTE:
      'REMARQUE: Cette information est partagée seulement en interne et uniquement avec les collaborateurs désignés et les propriétaires de documents seulement.',
    // ORIGINAL TEXT: NOTE: This information is shared INTERNALLY ONLY with designated collaborators and document owners only.
    NEGOTIATIONNOTE:
      'REMARQUE: Cette information est partagée en externe avec les contre-parties.',
    // ORIGINAL TEXT: NOTE: This information is shared EXTERNALLY with negotiation counter parties.
    CONFIRMDELETEALERT:
      "Veuillez confirmer que vous souhaitez supprimer l'alerte spécifiée. Après la suppression de l'alerte, celle-ci ne sera plus disponible. Veuillez confirmer l'opération de suppression.",
    // ORIGINAL TEXT: Please confirm that you want to delete the specified alert.  After alert is deleted, it will no longer be available.  Please confirm delete operation.
    CONFIRMDELETERECIPIENTALERT:
      "Merci de confirmer que vous ne voulez plus être un destinataire pour l'alerte spécifiée. Après vous être retiré en tant que destinataire, vous pouvez demander à être ajouté à nouveau en contactant le propriétaire de l'alerte. Merci de confirmer l'opération de suppression.",
    // ORIGINAL TEXT: Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.
    CONFIRMDELETEGROUP:
      "Merci de confirmer que vous voulez supprimer le groupe spécifié. Une fois le groupe supprimé, il ne sera plus disponible. Cependant, cela ne sera pris en compte que lors de votre prochaine connexion. Merci de confirmer l'opération de suppression.",
    // ORIGINAL TEXT: Please confirm that you want to delete the specified group.  After group is deleted, it will no longer be available.  However, it will reflected the next time you log back in.  Please confirm delete operation.
    CONFIRMDELETECOLLABORATORGROUP:
      "Merci de confirmer que vous ne voulez plus être collaborateur pour le groupe spécifié. Après vous être retiré en tant que collaborateur, vous pouvez demander à être ajouté a nouveau en contactant le propriétaire du groupe. Merci de confirmer l'opération de suppression.",
    // ORIGINAL TEXT: Please confirm that you no longer want to be a collaborator for the specified group.  After removing yourself as a collaborator, you can request to be added back by contacting the owner of the group.  Please confirm delete operation.
    CONFIRMDELETEDOCTYPE:
      "Veuillez confirmer que vous souhaitez supprimer le doctype spécifié. Après la suppression du doctype, il ne sera plus disponible. Merci de confirmer l'opération de suppression.",
    // ORIGINAL TEXT: Please confirm that you want to delete the specified doctype.  After doctype is deleted, it will no longer be available.  Please confirm delete operation.
    EXPIREDTRIAL:
      "Votre licence d'essai a expiré. Veuillez mettre à jour pour continuer votre service.",
    // ORIGINAL TEXT: Your trial license has expired.  Please upgrade to continue your service.
    EXPIREDSUBSCRIPTION:
      "Votre licence d'abonnement a expiré. Veuillez mettre à jour pour continuer votre service ou contacter la facturation chez Docgility.",
    // ORIGINAL TEXT: Your subscription license has expired.  Please upgrade to continue your service or contact billing at Docgility.
    SOURCEDOCNOTDOCX:
      "Le document Source n'est pas un document DOCX. Les capacités Redline ne sont pas disponibles.",
    // ORIGINAL TEXT: The Source document is not a DOCX document.  Redline capabilities are not available.
  },
}

export default FR
