// utility for Excel
import { utils, writeFile } from 'xlsx'
import {
  getDisplayCategoryforCatID,
  getCatIDFromUID,
} from '@utils/clauseMapping'

import store from '@state/store'

import { formatDateDialog } from '@utils/dialogutils'

import { STORdownloadCloudFile } from '@/src/state/io/iostorage.js'

import { DBgetobjsync } from '@/src/state/io/iodb.js'

const DOCUMENTSTABLENAME = 'documents'
const DOCMETATABLENAME = 'docmeta'

// possibly useful function to merge 2 javascript arrays.  Modify to to additive queries
export function mergeArrayObjects(arr1, arr2) {
  return arr1.map((item, i) => {
    if (item.id === arr2[i].id) {
      // merging two objects
      return Object.assign({}, item, arr2[i])
    }
  })
}

export function downloadObjectAsJSON(object) {
  // Convert JSON object to string.
  var json = JSON.stringify(object)

  // Convert JSON string to BLOB.
  json = [json]
  var blob1 = new Blob(json, { type: 'text/plain;charset=utf-8' })
  const fileName = `document${new Date().toJSON().slice(0, 10)}.json`
  // Check the Browser.
  var isIE = false || !!document.documentMode
  if (isIE) {
    window.navigator.msSaveBlob(blob1, fileName)
  } else {
    var url = window.URL || window.webkitURL
    const link = url.createObjectURL(blob1)
    var a = document.createElement('a')
    a.download = fileName
    a.href = link
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export function sortbyparaidsentid(a, b) {
  if (parseInt(a.paraid) > parseInt(b.paraid)) {
    return 1
  } else if (parseInt(a.paraid) < parseInt(b.paraid)) {
    return -1
  } else if (parseInt(a.paraid) === parseInt(b.paraid)) {
    return a.sentid > b.sentid ? 1 : -1
  } else {
    return 0
  }
}

export function genExcel(
  panelstate,
  docRawData,
  originaltext,
  comments,
  docedits,
  tags
) {
  // Define new Excel spreadsheet
  let wb = utils.book_new()
  wb.Props = {
    Title: `MLE Excel export ${new Date().toJSON().slice(0, 10)}`,
    Subject: 'ALL',
    Author: 'MLE',
    CreatedDate: new Date(),
  }

  // clauses only
  const clausecols = [{ wch: 10 }, { wch: 60 }, { wch: 20 }]
  wb.SheetNames.push('ClauseTopics')
  let wsData = [['Paragraph', 'Text', 'Category']]

  Object.keys(docRawData.clauseId).forEach((clauseKey) => {
    wsData.push([
      clauseKey,
      // need to change
      originaltext[clauseKey].OT,
      getDisplayCategoryforCatID(
        getCatIDFromUID(docRawData.clauseId[clauseKey].uid)
      ),
    ])
  })
  let ws = utils.aoa_to_sheet(wsData)
  ws['!cols'] = clausecols
  wb.Sheets['ClauseTopics'] = ws

  // negotiators cannot see collaboration information
  if (!panelstate.negotiatorPriv) {
    // collaboration - comments
    const commentscols = [
      { wch: 10 },
      { wch: 60 },
      { wch: 60 },
      { wch: 20 },
      { wch: 20 },
    ]
    wb.SheetNames.push('Collaboration-Comments')
    let commentsData = [
      ['Paragraph', 'Paragraph Text', 'Comment', 'Author', 'Time'],
    ]
    Object.keys(comments).forEach((commentkey) => {
      if (
        comments[commentkey].type === 'COLLABORATION' &&
        comments[commentkey].delete === '' &&
        comments[commentkey].active === true
      ) {
        commentsData.push([
          comments[commentkey].paraid,
          originaltext[comments[commentkey].paraid].OT,
          comments[commentkey].comment,
          comments[commentkey].uniqid,
          formatDateDialog(comments[commentkey].create),
        ])
      }
    })
    let commentsWS = utils.aoa_to_sheet(commentsData)
    commentsWS['!cols'] = commentscols
    wb.Sheets['Collaboration-Comments'] = commentsWS

    // collaboration - docedits
    const doceditscols = [
      { wch: 10 },
      { wch: 60 },
      { wch: 60 },
      { wch: 20 },
      { wch: 60 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ]
    wb.SheetNames.push('Collaboration-DocEdits')
    let doceditsData = [
      [
        'Paragraph',
        'Paragraph Text',
        'Original Sentence',
        'Action',
        'New Sentence(if nec)',
        'Author',
        'Time',
        '',
        'owner-approved',
        'owner-approver',
        'owner-approve time',
      ],
    ]
    Object.keys(docedits).forEach((doceditkey) => {
      if (
        docedits[doceditkey].type === 'COLLABORATION' &&
        docedits[doceditkey].delete === '' &&
        docedits[doceditkey].active === true
      ) {
        doceditsData.push([
          docedits[doceditkey].paraid,
          originaltext[docedits[doceditkey].paraid].OT,
          docedits[doceditkey].senttext,
          docedits[doceditkey].docedittype,
          docedits[doceditkey].docedittext,
          docedits[doceditkey].uniqid,
          formatDateDialog(docedits[doceditkey].create),
          '',
          docedits[doceditkey].approved,
          docedits[doceditkey].approver,
          formatDateDialog(docedits[doceditkey].approvedtime),
        ])
      }
    })
    let doceditsWS = utils.aoa_to_sheet(doceditsData)
    doceditsWS['!cols'] = doceditscols
    wb.Sheets['Collaboration-DocEdits'] = doceditsWS

    // collaboration - tags
    const tagscols = [
      { wch: 10 },
      { wch: 60 },
      { wch: 60 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ]
    wb.SheetNames.push('Collaboration-RiskandLabels')
    let tagsData = [
      ['Paragraph', 'Paragraph Text', 'Sentence', 'Label', 'Author', 'Time'],
    ]
    Object.keys(tags).forEach((tagkey) => {
      if (tags[tagkey].delete === '' && tags[tagkey].active === true) {
        tagsData.push([
          tags[tagkey].paraid,
          originaltext[tags[tagkey].paraid].OT,
          tags[tagkey].senttext,
          tags[tagkey].tag,
          tags[tagkey].uniqid,
          formatDateDialog(tags[tagkey].create),
        ])
      }
    })
    let tagsWS = utils.aoa_to_sheet(tagsData)
    tagsWS['!cols'] = tagscols
    wb.Sheets['Collaboration-RiskandLabels'] = tagsWS
  }

  // NEGOTIATOR
  // negotiation - comments
  const ncommentscols = [
    { wch: 10 },
    { wch: 60 },
    { wch: 60 },
    { wch: 20 },
    { wch: 20 },
  ]
  wb.SheetNames.push('Negotiation-Comments')
  let ncommentsData = [
    ['Paragraph', 'Paragraph Text', 'Comment', 'Author', 'Time'],
  ]
  Object.keys(comments).forEach((ncommentkey) => {
    if (
      comments[ncommentkey].type === 'NEGOTIATION' &&
      comments[ncommentkey].delete === '' &&
      comments[ncommentkey].active === true
    ) {
      ncommentsData.push([
        comments[ncommentkey].paraid,
        originaltext[comments[ncommentkey].paraid].OT,
        comments[ncommentkey].comment,
        comments[ncommentkey].uniqid,
        formatDateDialog(comments[ncommentkey].create),
      ])
    }
  })
  let ncommentsWS = utils.aoa_to_sheet(ncommentsData)
  ncommentsWS['!cols'] = ncommentscols
  wb.Sheets['Negotiation-Comments'] = ncommentsWS

  // negotiation - docedits
  const ndoceditscols = [
    { wch: 10 },
    { wch: 60 },
    { wch: 60 },
    { wch: 20 },
    { wch: 60 },
    { wch: 20 },
    { wch: 20 },
    { wch: 10 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ]
  wb.SheetNames.push('Negotiation-DocEdits')
  let ndoceditsData = [
    [
      'Paragraph',
      'Paragraph Text',
      'Original Sentence',
      'Action',
      'New Sentence(if nec)',
      'Author',
      'Time',
      '',
      'owner-approved',
      'owner-approver',
      'owner-approve time',
      'negotiator-approved',
      'negotiator-approver',
      'negotiator-approve time',
    ],
  ]
  Object.keys(docedits).forEach((ndoceditkey) => {
    if (
      (docedits[ndoceditkey].type === 'NEGOTIATION' ||
        (docedits[ndoceditkey].type === 'COLLABORATION' &&
          docedits[ndoceditkey].approved)) &&
      docedits[ndoceditkey].delete === '' &&
      docedits[ndoceditkey].active === true
    ) {
      ndoceditsData.push([
        docedits[ndoceditkey].paraid,
        originaltext[docedits[ndoceditkey].paraid].OT,
        docedits[ndoceditkey].senttext,
        docedits[ndoceditkey].docedittype,
        docedits[ndoceditkey].docedittext,
        docedits[ndoceditkey].uniqid,
        formatDateDialog(docedits[ndoceditkey].create),
        '',
        docedits[ndoceditkey].approved
          ? 'ACCEPTED'
          : docedits[ndoceditkey].approver === ''
          ? 'PENDING'
          : 'REJECTED',
        docedits[ndoceditkey].approver,
        formatDateDialog(docedits[ndoceditkey].approvedtime),
        docedits[ndoceditkey].negotiatorapproved
          ? 'ACCEPTED'
          : docedits[ndoceditkey].negotiatorapprover === ''
          ? 'PENDING'
          : 'REJECTED',
        docedits[ndoceditkey].negotiatorapprover,
        formatDateDialog(docedits[ndoceditkey].negotiatorapprovedtime),
      ])
    }
  })
  let ndoceditsWS = utils.aoa_to_sheet(ndoceditsData)
  ndoceditsWS['!cols'] = ndoceditscols
  wb.Sheets['Negotiation-DocEdits'] = ndoceditsWS

  // Get name and then output the file.
  DBgetobjsync(DOCUMENTSTABLENAME, panelstate.docmeta.docid)
    .then((document) =>
      writeFile(
        wb,
        `DOCG-${document.data.filename}-${new Date()
          .toJSON()
          .slice(0, 10)}.xlsx`
      )
    )
    .catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
}

// sorting functions
export function compareTitle(a, b) {
  if (a.title > b.title) {
    return 1
  } else {
    return -1
  }
}

// Word output
export function genWord(docid) {
  DBgetobjsync(DOCUMENTSTABLENAME, docid)
    .then((document) =>
      downloadCloudFile(document.data.urlname, document.data.filename)
    )
    .catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
}

export function genRedline(urlname, filename) {
  downloadCloudFile(urlname, filename)
}

// Word output
export function getExecuteContract(docid) {
  DBgetobjsync(DOCMETATABLENAME, docid)
    .then((docmeta) =>
      downloadCloudFile(
        docmeta.data.contractfilename,
        docmeta.data.contractename
      )
    )
    .catch(function (e) {
      store.dispatch('dberror/logError', e)
    })
}

export function downloadCloudFile(urlname, filename) {
  STORdownloadCloudFile(urlname, filename)
}
