// Contains all application constants //

export const NL = {
  TOPMENU: {
    FILE: 'FILE',
    EDIT: 'EDIT',
    ACT: 'ACT',
    DOCS: 'DOCS',
  },

  MENU: {
    NEWDOCUMENT: 'Nieuw document',
    // ORIGINAL TEXT: New Document
    SAVEDOCUMENT: 'Document opslaan',
    // ORIGINAL TEXT: Save Document
    COPYDOCUMENT: 'Document kopiëren',
    // ORIGINAL TEXT: Copy Document
    CREATEVERSION: 'Schone versie maken',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: 'Create Doc from Template',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: 'Document openen',
    // ORIGINAL TEXT: Open Document
    OPENGROUPDOCUMENT: 'Groepsdocument openen',
    // ORIGINAL TEXT: Open Group Document
    OPENHUBDOCUMENT: 'Document HUB openen',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: 'Archiefdocument openen',
    // ORIGINAL TEXT: Open Archive Document
    OPENPLAYBOOK: 'Speelboek openen',
    // ORIGINAL TEXT: Open Playbook
    OPENTEMPLATE: 'Open Template',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: 'Save As Template',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: 'Opslaan als speelboek',
    // ORIGINAL TEXT: Save As Playbook
    MOVETOARCHIVE: 'Naar archief verplaatsen',
    // ORIGINAL TEXT: Move to Archive
    CLOSEDOCUMENT: 'Document sluiten',
    // ORIGINAL TEXT: Close Document
    DOCUMENTLOG: 'Documentlog',
    // ORIGINAL TEXT: Document Log

    GOTOPARAGRAPH: 'Ga naar paragraaf',
    // ORIGINAL TEXT: Go to Paragraph
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'Documentnotities',
    // ORIGINAL TEXT: Document Notes
    COLLABCOMMENTS: 'Samenwerking - Opmerkingen',
    // ORIGINAL TEXT: Collaboration - Comments
    COLLABDOCEDITS: 'Samenwerking - Document Edits',
    // ORIGINAL TEXT: Collaboration - Document Edits
    COLLABRISKANDLABELS: 'Samenwerking - Risico en labels',
    // ORIGINAL TEXT: Collaboration - Risk and Labels
    NEGOTCOMMENTS: 'Onderhandelingen - Opmerkingen',
    // ORIGINAL TEXT: Negotiation - Comments
    NEGOTDOCEDITS: 'Onderhandeling - Document Edits',
    // ORIGINAL TEXT: Negotiation - Document Edits

    COLLABORATION: 'Samenwerking',
    // ORIGINAL TEXT: Collaboration
    NEGOTIATION: 'Onderhandelingen',
    // ORIGINAL TEXT: Negotiation
    ADDOWNER: 'Eigenaar toevoegen',
    // ORIGINAL TEXT: Add Owner
    ADDCOLLABORATOR: 'Collaborator toevoegen',
    // ORIGINAL TEXT: Add Collaborator
    ADDNEGOTIATOR: 'Onderhandelaar toevoegen',
    // ORIGINAL TEXT: Add Negotiator
    TRIGGERALERTS: 'Triggerwaarschuwingen',
    // ORIGINAL TEXT: Trigger Alerts
    EXPORTEXCEL: 'Excel exporteren',
    // ORIGINAL TEXT: Export Excel
    DOWNLOADSOURCE: 'Bron downloaden',
    // ORIGINAL TEXT: Download Source
    SENDTOCLM: 'Stuur naar CLM',
    // ORIGINAL TEXT: Send to CLM
    EXPORTJSON: 'JSON exporteren',
    // ORIGINAL TEXT: Export JSON
    ESIGNATURE: 'Verzenden voor esignatuur',
    // ORIGINAL TEXT: Send for Esignature
    DOWNLOADREDLINE: 'Redline DocX downloaden',
    // ORIGINAL TEXT: Download Redline DocX
    DOCUMENTSETTINGS: 'Documentinstellingen',
    // ORIGINAL TEXT: Document Settings
    EXPAND: 'Uitvouwen',
    // ORIGINAL TEXT: Expand
    COLLAPSE: 'Inklappen',
    // ORIGINAL TEXT: Collapse
    TRANSLATETOENGLISH: 'Vertalen Nederlands naar Engels',
    // ORIGINAL TEXT: Translate to English
    ORIGINALLANGUAGE: 'Terugkeren naar origineel',
    // ORIGINAL TEXT: Revert to Original

    ALLPARAGRAPHS: 'Alle paragrafen',
    // ORIGINAL TEXT: All Paragraphs
    EDITEDPARAGRAPHSONLY: "Alleen alinea's bewerkt",
    // ORIGINAL TEXT: Edited Paragraphs Only

    NOEDITCLAUSELABELS: 'Geen Clausule-labels bewerken',
    // ORIGINAL TEXT: No Edit Clause Labels
    EDITCLAUSELABELS: 'Clausulelabels bewerken',
    // ORIGINAL TEXT: Edit Clause Labels

    HIDEEDITS: 'Edits verbergen',
    // ORIGINAL TEXT: Hide Edits
    SHOWEDITS: 'Edits tonen',
    // ORIGINAL TEXT: Show Edits

    SHOWALLEDITS: 'Alle bewerkingen tonen',
    // ORIGINAL TEXT: Show All Edits
    SHOWNEGOTEDITSONLY: 'Alleen onderhandelingen tonen',
    // ORIGINAL TEXT: Show Negotiation Edits Only

    GROUPS: 'Groepen',
    // ORIGINAL TEXT: Groups
    DOCUMENTTYPES: 'Documenttypes',
    // ORIGINAL TEXT: Document Types
    ALERTS: 'Waarschuwingen',
    // ORIGINAL TEXT: Alerts
    QUICKSTARTTUTORIAL: 'Snelstart-tutorial',
    // ORIGINAL TEXT: Quick Start Tutorial
    QUICKSTARTVIDEOS: "Snelstartvideo's",
    // ORIGINAL TEXT: Quick Start Videos
    UPGRADESUBSCRIPTION: 'Abonnement upgraden',
    // ORIGINAL TEXT: Upgrade Subscription
    SUBSCRIPTIONBILLING: 'Abonnementsfacturering',
    // ORIGINAL TEXT: Subscription Billing
    ACCOUNTSETTINGS: 'Accountinstellingen',
    // ORIGINAL TEXT: Account Settings
    USERLOG: 'Gebruikerslog',
    // ORIGINAL TEXT: User Log
    CHANGEPASSWORD: 'Wachtwoord wijzigen',
    // ORIGINAL TEXT: Change Password
    LOGOUT: 'Afmelden',
    // ORIGINAL TEXT: Logout

    // HEADINGS
    ADMIN: 'ADMIN',
    // ORIGINAL TEXT: ADMIN
    PROFILE: 'PROFIEL',
    // ORIGINAL TEXT: PROFILE
    LPROFILE: 'Profiel',
    // ORIGINAL TEXT: Profile
    FILE: 'BESTAND',
    // ORIGINAL TEXT: FILE
    EDIT: 'EDIT',
    // ORIGINAL TEXT: EDIT
    ACT: 'AKTE',
    // ORIGINAL TEXT: ACT
    TOPIC: 'Subsidiair',
    // ORIGINAL TEXT: TOPIC
    DOCS: 'DOCS',
    // ORIGINAL TEXT: DOCS

    // Field Headings in Navigation Bar
    NAME: 'Naam',
    // ORIGINAL TEXT: Name
    ROLE: 'Rol',
    // ORIGINAL TEXT: Role
    STATE: 'Staat',
    // ORIGINAL TEXT: State
    GROUP: 'Groep',
    // ORIGINAL TEXT: Group
    TYPE: 'Type',
    // ORIGINAL TEXT: Type
    SENSIT: 'Gevoeligheid',
    // ORIGINAL TEXT: Sensitivity
    LTOPIC: 'Onderwerp',
    // ORIGINAL TEXT: Topic
    USER: 'Gebruiker',
    // ORIGINAL TEXT: User

    // Other Headings
    REGISTER: 'REGISTER',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'LOGIN',
    // ORIGINAL TEXT: LOGIN
    LREGISTER: 'Register',
    // ORIGINAL TEXT: Register
    LLOGIN: 'Aanmelden',
    // ORIGINAL TEXT: Login

    ADMINEXPLAIN: 'Administratieve capaciteit',
    // ORIGINAL TEXT: Administrative capabilities
    PROFILEEXPLAIN: 'Accountbeheer, Abonnementen, Facturering',
    // ORIGINAL TEXT: Account Management, Subscriptions, Billing

    // AUTH LEVELS
    PREMIUM: 'Premie',
    // ORIGINAL TEXT: Premium
    PREMIUMPREPAID: 'Premie (vooruitbetaalde)',
    // ORIGINAL TEXT: Premium (Prepaid)
    BASIC: 'Basis',
    // ORIGINAL TEXT: Basic
    BASICPREPAID: 'Basis (vooruitbetaald)',
    // ORIGINAL TEXT: Basic (Prepaid)
    VIPACCESS: 'VIP-toegang',
    // ORIGINAL TEXT: VIP Access
    VIPMEMBER: 'VIP-lid',
    // ORIGINAL TEXT: VIP Member
    UNLIMITED: 'Onbeperkt',
    // ORIGINAL TEXT: Unlimited
    TRIALUSER: 'Proefgebruiker',
    // ORIGINAL TEXT: Trial User
    REFERREDUSER: 'Verwezen gebruiker',
    // ORIGINAL TEXT: Referred User
    SOURCEDOCUMENT: 'Bron document',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: 'Vergelijkingsdocument',
    // ORIGINAL TEXT: Comparison Document
    EXIT: 'UITGANG',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: 'Paragraaf',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: 'DOCUMENTVERGELIJKINGSMODUS',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: 'Geen',
    // ORIGINAL TEXT: NONE
    PREAMBLE: 'PREAMBULE',
    // ORIGINAL TEXT: PREAMBLE
    DEFN: 'DEFINITIE',
    // ORIGINAL TEXT: DEFINITION
    PAYMENT: 'BETALING',
    // ORIGINAL TEXT: PAYMENT
    TAXES: 'BELASTING',
    // ORIGINAL TEXT: TAXES
    REPWARR: 'VERTEGENWOORDIGING EN GARANTIES',
    // ORIGINAL TEXT: REPRESENTATION AND WARRANTIES
    PERIOD: 'PERIODE',
    // ORIGINAL TEXT: PERIOD
    TERMINATION: 'BEËINDIGING',
    // ORIGINAL TEXT: TERMINATION
    CONFID: 'VERTROUWELIJKHEID',
    // ORIGINAL TEXT: CONFIDENTIALITY
    INDEMNITY: 'INDEMNITEIT',
    // ORIGINAL TEXT: INDEMNITY
    LIABILITY: 'AANSPRAKELIJKHEID',
    // ORIGINAL TEXT: LIABILITY
    INTELPROP: 'INTELLECTUELE PROP',
    // ORIGINAL TEXT: INTELLECTUAL PROP
    SIGNATURE: 'SIGNATURE',
    // ORIGINAL TEXT: SIGNATURE
    OTHER: 'OVERIGE',
    // ORIGINAL TEXT: OTHER
    DATASECURITY: 'GEGEVENSBEVEILIGING',
    // ORIGINAL TEXT: DATA SECURITY
    ASSIGN: 'OVERDRACHT',
    // ORIGINAL TEXT: ASSIGNMENT
    NOTICE: 'MEDEDELING',
    // ORIGINAL TEXT: NOTICE
    GOVERNLAW: 'HET RECHT VAN BESTUUR',
    // ORIGINAL TEXT: GOVERNING LAW
    DISPUTE: 'GESCHIEDENIS',
    // ORIGINAL TEXT: DISPUTE
    ENTIREAGREE: 'VERENIGBARE OVEREENKOMST',
    // ORIGINAL TEXT: ENTIRE AGREEMENT
    SURVIVAL: 'OVERWEGING',
    // ORIGINAL TEXT: SURVIVAL
    RELATPART: 'VERENIGING TUSSEN PARTIJEN',
    // ORIGINAL TEXT: RELATION BETWEEN PARTIES
    THIRDPARTY: 'DERDE DEEL',
    // ORIGINAL TEXT: THIRD PARTY
    FORCEMAJEURE: 'FORCE MAJEURE',
    // ORIGINAL TEXT: FORCE MAJEURE
    SEVERABILITY: 'UITSPLITSBAARHEID',
    // ORIGINAL TEXT: SEVERABILITY
    EXPORTCTRL: 'UITVOERCONTROLE',
    // ORIGINAL TEXT: EXPORT CONTROLS
    INTRO: 'INLEIDING',
    // ORIGINAL TEXT: INTRODUCTION
    INSURANCE: 'GARANTIE',
    // ORIGINAL TEXT: INSURANCE
    HEADING: 'RUBRIEK',
    // ORIGINAL TEXT: HEADING
    ALL: 'ALL',
    // ORIGINAL TEXT: ALL
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'E-mail is vereist',
    // ORIGINAL TEXT: E-mail is required
    EMAILVALID: 'E-mail moet geldig zijn',
    // ORIGINAL TEXT: E-mail must be valid
    MAX20CHARS: 'Max. 20 tekens',
    // ORIGINAL TEXT: Max 20 characters
    MAX60CHARS: 'Max. 60 tekens',
    // ORIGINAL TEXT: Max 60 characters
    MAX80CHARS: 'Max. 80 tekens',
    // ORIGINAL TEXT: Max 80 characters
    MAX160CHARS: 'Max. 160 tekens',
    // ORIGINAL TEXT: Max 160 characters
    EMAILALREADYOWNER: 'E-mail is al eigenaar',
    // ORIGINAL TEXT: Email is already an owner
    EMAILALREADYCOLLABORATOR: 'E-mail is al een medewerker',
    // ORIGINAL TEXT: Email is already a collaborator
    EMAILALREADYNEGOTIATOR: 'E-mail is al onderhandelaar',
    // ORIGINAL TEXT: Email is already a negotiator
    EMAILALREADYOWNERDOCTYPE: 'E-mail is al eigenaar van het documenttype',
    // ORIGINAL TEXT: Email is already an owner of the document type
    EMAILALREADYCOLLABORATORDOCTYPE:
      'E-mail is al een medewerker van het documenttype',
    // ORIGINAL TEXT: Email is already an collaborator of the document type
    REQUIRED: 'Vereist.',
    // ORIGINAL TEXT: Required.
    CLAUSEFIELDREQUIRED:
      'Dit veld van de clausule is vereist voor deze bewerking',
    // ORIGINAL TEXT: This clause field is required for this operation
    CLAUSENOTDEFAULT:
      'Kan clausule uit de standaard clausulelijst niet toevoegen',
    // ORIGINAL TEXT: Cannot add clause from default clause list
    TAGFIELDREQUIRED: 'Dit tag-veld is vereist voor deze bewerking',
    // ORIGINAL TEXT: This tag field is required for this operation
    TAGNOTDEFAULT: 'Kan geen tags toevoegen uit de standaard taglijst',
    // ORIGINAL TEXT: Cannot add tags from default tag list

    PARAINDEXREQUIRED: 'Alinea-index is vereist',
    // ORIGINAL TEXT: Paragraph index is required
    PARAINDEXINVALID: 'Alinea-index bestaat niet',
    // ORIGINAL TEXT: Paragraph index does not exist
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'Document opslaan',
    OPENDOCUMENT: 'document openen',
    // ORIGINAL TEXT: open document
    CLOSEDOCUMENT: 'document sluiten',
    // ORIGINAL TEXT: close document
    ADDNOTE: 'Opmerking toevoegen',
    // ORIGINAL TEXT: add note
    ADDCOLLABORATOR: 'collaborateur toevoegen',
    // ORIGINAL TEXT: add collaborator
    DELETECOLLABORATOR: 'collaborateur verwijderen',
    // ORIGINAL TEXT: delete collaborator
    ADDNEGOTIATOR: 'onderhandelaar toevoegen',
    // ORIGINAL TEXT: add negotiator
    DELETENEGOTIATOR: 'onderhandelaar verwijderen',
    // ORIGINAL TEXT: delete negotiator
    UPDATEARCHIVEDATA: 'archiefgegevens bijwerken',
    // ORIGINAL TEXT: update archive data
    UPDATEDOCMETA: 'documentmetadata bijwerken',
    // ORIGINAL TEXT: update document metadata
    ADDOWNER: 'eigenaar toevoegen',
    // ORIGINAL TEXT: add owner
    DELETEOWNER: 'eigenaar verwijderen',
    // ORIGINAL TEXT: delete owner
    CHANGECOLLABORATION: 'gewijzigde samenwerkingsinstellingen',
    // ORIGINAL TEXT: changed collaboration settings
    CHANGENEGOTIATION: 'gewijzigde onderhandelingsinstellingen',
    // ORIGINAL TEXT: changed negotiation settings
    SAVEPLAYBOOK: 'opgeslagen als afspeelboek',
    // ORIGINAL TEXT: saved as playbook
    SAVETEMPLATE: 'saved as template',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: 'opgeslagen als gearchiveerd',
    // ORIGINAL TEXT: saved as archived
    TRIGGERALERT: 'trigger alert',
    // ORIGINAL TEXT: trigger alert
    TRIGGERALLALERT: 'alle waarschuwingen oproepen',
    // ORIGINAL TEXT: trigger all alerts
    TRIGGERCUSTOMALERT: 'Aangepaste waarschuwing activeren',
    // ORIGINAL TEXT: trigger custom alert
    ADDCOMMENT: 'commentaar toevoegen',
    // ORIGINAL TEXT: add comment
    DELETECOMMENT: 'commentaar verwijderen',
    // ORIGINAL TEXT: delete comment
    ADDDOCEDIT: 'docedit toevoegen',
    // ORIGINAL TEXT: add docedit
    ADDAPPROVEDDOCEDIT: 'goedgekeurde documentbewerking toevoegen',
    // ORIGINAL TEXT: add approved document edit
    APPROVEDDOCEDIT: 'document bewerken goedkeuren',
    // ORIGINAL TEXT: approve document edit
    REJECTDOCEDIT: 'documentbewerking verwerpen',
    // ORIGINAL TEXT: reject document edit
    NEGOTIATORAPPROVEDDOCEDIT: 'Onderhandelaar goedgekeurd document bewerken',
    // ORIGINAL TEXT: negotiator approved document edit
    NEGOTIATORREJECTEDDOCEDIT:
      'Onderhandelaar heeft documentbewerking afgewezen',
    // ORIGINAL TEXT: negotiator rejected document edit
    DELETEDDOCEDIT: 'document bewerken verwijderen',
    // ORIGINAL TEXT: delete document edit
    ATTACHCONTRACT: 'exec-contract bijvoegen',
    // ORIGINAL TEXT: attach exec contract
    ADDTAG: 'tag toevoegen',
    // ORIGINAL TEXT: add tag
    DELETETAG: 'tag verwijderen',
    // ORIGINAL TEXT: delete tag
    NEWSIGNATUREREQUEST: 'Nieuwe handtekening aangevraagd',
    // ORIGINAL TEXT: new signature requested
    CANCELSIGNATUREREQUEST: 'geannuleerde ondertekeningsverzoek',
    // ORIGINAL TEXT: cancelled signature request
    CREATEREDLINEREQUEST: 'nieuw redline-document aanmaken',
    // ORIGINAL TEXT: create new redline document
    CREATEFINALDOCREQUEST:
      'een nieuw definitief document voor ondertekening aanmaken',
    // ORIGINAL TEXT: create new final document for signature
    COPIEDDOCUMENT: 'gekopieerd van document',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: 'schone versie gemaakt van document',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: 'created document from template',
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'documenttype aanmaken',
    // ORIGINAL TEXT: create document type
    DELETEDOCTYPE: 'documenttype verwijderen',
    // ORIGINAL TEXT: delete document type
    ADDOWNERTODOCTYPE: 'eigenaar toegevoegd aan documenttype',
    // ORIGINAL TEXT: added owner to document type
    DELETEOWNERFROMDOCTYPE: 'verwijderde eigenaar naar documenttype',
    // ORIGINAL TEXT: deleted owner to document type
    ADDCLAUSETODOCTYPE: 'toegevoegd addl-clausule aan documenttype',
    // ORIGINAL TEXT: added addl clause to document type
    DELETECLAUSEDOCTYPE: 'geschrapte toevoegingsclausule aan documenttype',
    // ORIGINAL TEXT: deleted addl clause to document type
    ADDTAGDOCTYPE: 'addl-tag toegevoegd aan documenttype',
    // ORIGINAL TEXT: added addl tag to document type
    DELETETAGDOCTYPE: 'verwijderde addl-tag van documenttype',
    // ORIGINAL TEXT: deleted addl tag from document type
    ADDCOLLABRULEDOCTYPE: 'Collab-regels toegevoegd aan documenttype',
    // ORIGINAL TEXT: added collab rules to  document type
    DELETECOLLABRULEDOCTYPE: 'verwijderde collib-regels van documenttype',
    // ORIGINAL TEXT: deleted collab rules from document type
    ADDNOTIFRULEDOCTYPE: 'kennisgevingsregels toegevoegd aan documenttype',
    // ORIGINAL TEXT: added notification rules to  document type
    DELETENOTIFRULEDOCTYPE: 'geschrapte meldingsregels van het type document',
    // ORIGINAL TEXT: deleted notification rules from document type
    ADDNEGOTRULEDOCTYPE: 'onderhandelingsregels toegevoegd aan documenttype',
    // ORIGINAL TEXT: added negotiation rules to  document type
    DELETENEGOTRULEDOCTYPE:
      'geschrapte onderhandelingsregels van het type document',
    // ORIGINAL TEXT: deleted negotiation rules from document type

    // GROUPS
    CREATEGROUP: 'aangemaakte groep',
    // ORIGINAL TEXT: created group
    DELETEGROUP: 'verwijderde groep',
    // ORIGINAL TEXT: deleted group
    ADDOWNERGROUP: 'eigenaar toegevoegd aan groep',
    // ORIGINAL TEXT: added owner to group
    DELETEOWNERGROUP: 'verwijderde eigenaar van groep',
    // ORIGINAL TEXT: deleted Owner from group
    ADDCOLLABGROUP: 'collaborateur toegevoegd aan groep',
    // ORIGINAL TEXT: added collaborator to group
    DELETECOLLABGROUP: 'verwijderde medewerker uit groep',
    // ORIGINAL TEXT: deleted collaborator from group
    ADDDOCTYPETOGROUP: 'documenttype toevoegen aan groep',
    // ORIGINAL TEXT: added document type to group
    DELETEDOCTYPEGROUP: 'verwijderd documenttype uit groep',
    // ORIGINAL TEXT: deleted document type from group

    // ALERTS
    CREATEALERT: 'alert aangemaakt',
    // ORIGINAL TEXT: created alert
    DELETEALERT: 'verwijderde waarschuwing',
    // ORIGINAL TEXT: deleted alert
    ADDOWNERALERT: 'eigenaar toegevoegd aan waarschuwing',
    // ORIGINAL TEXT: added owner to alert
    DELETEOWNERALERT: 'verwijderde eigenaar van waarschuwing',
    // ORIGINAL TEXT: deleted owner from alert
    ADDDOCTYPEALERT: 'doctype toegevoegd aan waarschuwing',
    // ORIGINAL TEXT: added doctype to alert
    DELETEDOCTYPEALERT: 'verwijderd doctype van waarschuwing',
    // ORIGINAL TEXT: deleted doctype from alert
    ADDCLAUSETOPICALERT: 'clausule-onderwerp toegevoegd aan waarschuwing',
    // ORIGINAL TEXT: added clause topic to alert
    DELETECLAUSETOPICALERT: 'verwijderde clausule onderwerp van waarschuwing',
    // ORIGINAL TEXT: deleted clause topic from alert
    ADDRECIPIENTALERT: 'ontvanger toegevoegd aan waarschuwing',
    // ORIGINAL TEXT: added recipient to alert
    DELETERECIPIENTALERT: 'verwijderde ontvanger van waarschuwing',
    // ORIGINAL TEXT: deleted recipient from alert
    ADDNOTIFRULEALERT: 'Aanmeldingsregels toegevoegd',
    // ORIGINAL TEXT: added notification rules to alert
    DELETENOTIFRULEALERT: 'geschrapte meldingsregels van signalering',
    // ORIGINAL TEXT: deleted notification rules from alert
    SETSELECTIVITYALERT: 'Stel selectiviteit in om te alarmeren',
    // ORIGINAL TEXT: set selectivity to  alert
  },

  USEREVENTS: {
    USERSIGNON: 'Gebruiker aangemeld',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT: 'resetverzoek met wachtwoord verzonden',
    // ORIGINAL TEXT: password reset request sent
    CHANGEDPASSWORD: 'wachtwoord gewijzigd',
    // ORIGINAL TEXT: changed password
    DELETEDREGISTRATION: 'verwijderde gebruikersregistratie',
    // ORIGINAL TEXT: deleted user registration
    UPDATEDACCOUNTINFO: 'bijgewerkte rekeninginformatie',
    // ORIGINAL TEXT: updated account information
    UPGRADEDACCOUNTTOREFERRAL: 'upgrade van rekening naar referentierekening',
    // ORIGINAL TEXT: upgraded account to referred account
    DELETEDUSERACCOUNT: 'verwijderd gebruikersaccount',
    // ORIGINAL TEXT: deleted user account
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: 'DOCUMENTACTIES',
    // ORIGINAL TEXT: DOCUMENT ACTIONS
    COMMENTS: 'OPMERKINGEN',
    // ORIGINAL TEXT: COMMENTS
    DOCEDITS: 'DOCUMENT EDITS',
    // ORIGINAL TEXT: DOCUMENT EDITS
    LABELSRISK: "ETIKETTEN EN RISICO'S",
    // ORIGINAL TEXT: LABELS AND RISK
    TEXT: 'TEKST',
    // ORIGINAL TEXT: TEXT
    MLE_DESC: 'DOCGILITY',
    // ORIGINAL TEXT: DOCGILITY
    CLAUSE: 'Beding',
    // ORIGINAL TEXT: Clause

    // PRIVILEGE
    OWNER: 'eigenaar',
    // ORIGINAL TEXT: owner
    COLLABORATOR: 'medewerker',
    // ORIGINAL TEXT: collaborator
    NEGOTIATOR: 'Onderhandelaar',
    // ORIGINAL TEXT: negotiator
    OWNERGROUP: 'eigenaar(groep)',
    // ORIGINAL TEXT: owner(group)
    COLLABORATORGROUP: 'collaborateur(groep)',
    // ORIGINAL TEXT: collaborator(group)

    // TITLES
    SAVEDOCUMENT: 'SAVE-DOCUMENT',
    // ORIGINAL TEXT: SAVE DOCUMENT
    SAVEARCHIVE: 'SAVE ARCHIEF',
    // ORIGINAL TEXT: SAVE ARCHIVE
    QUICKSTART: 'Snel beginnen met de TUTORIAL',
    // ORIGINAL TEXT: QUICK START TUTORIAL
    TUTORIALVIDEOS: "TUTORIALE VIDEO'S",
    // ORIGINAL TEXT: TUTORIAL VIDEOS

    MOVETOARCHIVEDOCUMENT: 'NAAR ARCHIEFDOCUMENT VERZENDEN',
    // ORIGINAL TEXT: MOVE TO ARCHIVE DOCUMENT
    OPENDOCUMENT: 'OPEN DOCUMENT',
    // ORIGINAL TEXT: OPEN DOCUMENT
    COPYDOCUMENT: 'KOPIEDOCUMENT',
    // ORIGINAL TEXT: COPY DOCUMENT
    CREATEVERSION: 'MAAK EEN SCHONE VERSIE',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: 'CREATE DOCUMENT FROM TEMPLATE',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: 'OPEN GROUPDOCUMENT',
    // ORIGINAL TEXT: OPEN GROUP DOCUMENT
    OPENHUBDOCUMENT: 'OPEN HUB DOCUMENT',
    // ORIGINAL TEXT: OPEN HUB DOCUMENT
    OPENARCHIVEDOCUMENT: 'OPEN ARCHIEFDOCUMENT',
    // ORIGINAL TEXT: OPEN ARCHIVE DOCUMENT
    OPENPLAYBOOK: 'OPEN SPEELBOEK',
    // ORIGINAL TEXT: OPEN PLAYBOOK
    OPENTEMPLATE: 'OPEN TEMPLATE',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'SAVE AS TEMPLATE',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: 'COLLABORATIE',
    // ORIGINAL TEXT: COLLABORATION
    NEGOTIATION: 'ONDERHANDELING',
    // ORIGINAL TEXT: NEGOTIATION
    ADDOWNER: 'ADD Owner',
    // ORIGINAL TEXT: ADD OWNER
    ADDNEGOTIATOR: 'ADD NEGOTIATOR',
    // ORIGINAL TEXT: ADD NEGOTIATOR
    ADDCOLLABORATOR: 'ADD COLLABORATOR',
    // ORIGINAL TEXT: ADD COLLABORATOR
    ERRORMESSAGE: 'FOUTBERICHT',
    // ORIGINAL TEXT: ERROR MESSAGE
    DOCUMENTINFO: 'DOCUMENTINFORMATIE',
    // ORIGINAL TEXT: DOCUMENT INFORMATION
    DOCUMENTLOGS: 'DOCUMENT LOGS',
    // ORIGINAL TEXT: DOCUMENT LOGS
    USERLOGS: 'GEBRUIKERSLOGS',
    // ORIGINAL TEXT: USER LOGS
    DOCUMENTNOTES: 'NOTA VAN HET DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT NOTES
    TRIGGERALERTS: 'TRIGGERALERTS',
    // ORIGINAL TEXT: TRIGGER ALERTS
    ALERTS: 'SIGNALERING',
    // ORIGINAL TEXT: ALERTS
    DOCTYPES: 'DOCUMENTTYPEN',
    // ORIGINAL TEXT: DOCUMENT TYPES
    GROUPS: 'GROEPEN',
    // ORIGINAL TEXT: GROUPS
    NOTE: 'NOOT',
    // ORIGINAL TEXT: NOTE
    GOTOPARAGRAPH: 'Ga naar het lid',
    // ORIGINAL TEXT: GO TO PARAGRAPH
    ESIGNATURE: 'E-SIGNATURE',
    // ORIGINAL TEXT: E-SIGNATURE
    DOWNLOADREDLINE: 'DOWNLOAD REDLINE DOCX',
    // ORIGINAL TEXT: DOWNLOAD REDLINE DOCX

    // ACTIONS
    ADD: 'ADD',
    // ORIGINAL TEXT: ADD
    BACK: 'ACHTER',
    // ORIGINAL TEXT: BACK
    DELETE: 'DELETE',
    // ORIGINAL TEXT: DELETE
    SAVE: 'SAVE',
    // ORIGINAL TEXT: SAVE
    MOVETOARCHIVE: 'NAAR ARCHIEF',
    // ORIGINAL TEXT: MOVE TO ARCHIVE
    COPY: 'KOPIE',
    // ORIGINAL TEXT: COPY
    CREATE: 'CREËREN',
    // ORIGINAL TEXT: CREATE
    OPEN: 'OPEN',
    // ORIGINAL TEXT: OPEN
    CLOSE: 'SLUITING',
    // ORIGINAL TEXT: CLOSE
    UPDATE: 'UPDAAT',
    // ORIGINAL TEXT: UPDATE
    REGISTER: 'REGISTER',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'LOGIN',
    // ORIGINAL TEXT: LOGIN
    LOGINSSO: 'LOGIN SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: 'RESETTEN PASSORD',
    // ORIGINAL TEXT: RESET PASSWORD
    CHANGEPASSWORD: 'VERANDERING VAN HET PASSORD',
    // ORIGINAL TEXT: CHANGE PASSWORD
    CANCELACCOUNT: 'ANNULERING',
    // ORIGINAL TEXT: CANCEL ACCOUNT
    QUICKSTARTVIDEO: 'Snel kijken begint video',
    // ORIGINAL TEXT: WATCH QUICK START VIDEO

    CHARGEBASIC: 'OPGEGEVEN AAN DE BASIS',
    // ORIGINAL TEXT: UPGRADE TO BASIC
    CHARGEPREMIUM: 'UPGRADE TO PREMIE',
    // ORIGINAL TEXT: UPGRADE TO PREMIUM
    CHARGECORPORATE: 'OPDRACHT TOT CORPORATIE',
    // ORIGINAL TEXT: UPGRADE TO CORPORATE
    UPGRADESUBSCRIPTION: 'SUBSCRIPTION VAN DE UPGRADE',
    // ORIGINAL TEXT: UPGRADE SUBSCRIPTION
    GO: 'GAAN',
    // ORIGINAL TEXT: GO
    PREVIEW_FEEDBACK: 'VERANDERINGEN VAN DE EVALUATIE',
    // ORIGINAL TEXT: REVIEW CHANGES
    ACTIONS: 'AKTE',
    // ORIGINAL TEXT: ACT
    REFRESH: 'REFRESH',
    // ORIGINAL TEXT: REFRESH
    ADDNEWGROUP: 'NIEUWE GROEP ADD',
    // ORIGINAL TEXT: ADD NEW GROUP
    ADDNEWDOCTYPE: 'ADD NIEUWE DOCTYPE',
    // ORIGINAL TEXT: ADD NEW DOCTYPE
    ADDNEWALERT: 'NIEUWE ALERT ADD',
    // ORIGINAL TEXT: ADD NEW ALERT
    GROUPSLIST: 'LIJST VAN DE NIEUWE GROEPEN',
    // ORIGINAL TEXT: REVIEW GROUP LIST

    DOWNLOADDOCUMENT: 'DOWNLOAD-DOCUMENT',
    // ORIGINAL TEXT: DOWNLOAD DOCUMENT
    DOCUMENTTITLE: 'Titel document',
    // ORIGINAL TEXT: Document Title
    DOCUMENTSUBJECT: 'Onderwerp document',
    // ORIGINAL TEXT: Document Subject
    SIGNMESSAGE: 'Bericht voor ondertekening',
    // ORIGINAL TEXT: Message for Signing
    NAMEPRIMARYSIGNER: 'Naam: Primaire Ondertekenaar',
    // ORIGINAL TEXT: Name: Primary Signer
    EMAILPRIMARYSIGNER: 'E-mail: Primaire Ondertekenaar',
    // ORIGINAL TEXT: Email: Primary Signer
    NAMECOUNTERSIGNER: 'Naam: Tellerondertekenaar',
    // ORIGINAL TEXT: Name: Counter Signer
    EMAILCOUNTERSIGNER: 'E-mail: Counter Signer',
    // ORIGINAL TEXT: Email: Counter Signer
    SIGREQUESTED: 'VERZOEK OM ONDERTEKENING',
    // ORIGINAL TEXT: SIGNATURE REQUESTED
    SIGREQUESTEDBY: 'OPGESTELD DOOR',
    // ORIGINAL TEXT: REQUESTED BY
    SIGREQUESTEDID: 'SIGNATURE ID',
    // ORIGINAL TEXT: SIGNATURE ID
    SIGREQUESTEDAT: 'VERZOEK OM',
    // ORIGINAL TEXT: REQUESTED AT
    SUBMITSIG: 'SUBMIT VOOR SIGNATURE',
    // ORIGINAL TEXT: SUBMIT FOR SIGNATURE
    CANCELSIG: 'VERZOEK OM ANNULERING VAN DE ONDERTEKENING',
    // ORIGINAL TEXT: CANCEL SIGNATURE REQUEST

    // DATA LABELS
    NAME: 'NAAM',
    // ORIGINAL TEXT: NAME
    EMAIL: 'E-mail',
    // ORIGINAL TEXT: EMAIL
    STATUS: 'STATUS',
    // ORIGINAL TEXT: STATUS
    LASTVIEWED: 'Laatst gezien',
    // ORIGINAL TEXT: LAST VIEWED
    LASTREVIEWED: 'LAATSTE EVALUATIE',
    // ORIGINAL TEXT: LAST REVIEWED
    SIGNEDAT: 'ONDERTEKEND',
    // ORIGINAL TEXT: SIGNED
    OWNERAPPROVAL: 'GOEDKEURING VAN DE ORDE',
    // ORIGINAL TEXT: OWNER APPROVAL
    OWNERACTION: 'EIGEN ACTIE',
    // ORIGINAL TEXT: OWNER ACTION
    NEGOTIATORAPPROVAL: 'GOEDKEURING VAN DE NEGOTIATOR',
    // ORIGINAL TEXT: NEGOTIATOR APPROVAL
    NEGOTIATORACTION: 'NEGOTIATORACTIE',
    // ORIGINAL TEXT: NEGOTIATOR ACTION
    ARCHIVENOTES: 'ARCHIEFMERK',
    // ORIGINAL TEXT: ARCHIVE NOTES
    EXECUTEDCONTRACT: 'UITGEBREIDE',
    // ORIGINAL TEXT: EXECUTED
    EXECUTEDDATE: 'UITGEBREIDE DATUM',
    // ORIGINAL TEXT: EXECUTED DATE
    CONTRACTATTACHED: 'TOEGEVOEGDE OPDRACHT',
    // ORIGINAL TEXT: CONTRACT ATTACHED
    STARTDATE: 'Begindatum',
    // ORIGINAL TEXT: START DATE
    ENDDATE: 'Datum van het einde',
    // ORIGINAL TEXT: END DATE
    EDIT: 'EDIT',
    // ORIGINAL TEXT: EDIT
    USER: 'GEBRUIKER',
    // ORIGINAL TEXT: USER
    TIME: 'TIJD',
    // ORIGINAL TEXT: TIME
    CONFIRM: 'BEVESTIGING',
    // ORIGINAL TEXT: CONFIRM
    CONFIRMACTION: 'BEVEILIGINGSACTIE',
    // ORIGINAL TEXT: CONFIRM ACTION
    CURRENT_LABEL: 'HUIDIGE ETIKET',
    // ORIGINAL TEXT: CURRENT LABEL
    NEW_LABEL: 'NIEUWE ETIKET',
    // ORIGINAL TEXT: NEW LABEL
    DESCRIPTION: 'BESCHRIJVING',
    // ORIGINAL TEXT: DESCRIPTION
    TYPE: 'TYPE',
    // ORIGINAL TEXT: TYPE
    SENTENCE: 'ZITTING',
    // ORIGINAL TEXT: SENTENCE
    NUMBER: '#',
    // ORIGINAL TEXT: #
    PRIVILEGE: 'ROL',
    // ORIGINAL TEXT: ROLE
    OWNERID: 'OWNERID',
    // ORIGINAL TEXT: OWNERID
    DOCNAME: 'NAAM DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT NAME
    DOCTYPE: 'DOCUMENTTYPE',
    // ORIGINAL TEXT: DOCUMENT TYPE
    DOCDESCRIPTION: 'BESCHRIJVING VAN HET DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT DESCRIPTION
    DOCNOTES: 'NOTA VAN HET DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT NOTES
    DOCPARTY: 'DEEL',
    // ORIGINAL TEXT: PARTY
    DOCCOUNTERPARTY: 'COUNTER PARTY',
    // ORIGINAL TEXT: COUNTER PARTY
    DOCRAWFILENAME: 'BESTANDSNAAM DOCUMENT',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'TAGS',
    // ORIGINAL TEXT: TAGS
    COLLABORATIONSTATUS: 'COLLABORATIESTATUS',
    // ORIGINAL TEXT: COLLABORATION STATUS
    COLLABORATIONEND: 'COLLABORATIE EIND',
    // ORIGINAL TEXT: COLLABORATION END
    NEGOTIATIONSTATUS: 'ONDERHANDELINGSSTATUS',
    // ORIGINAL TEXT: NEGOTIATION STATUS
    NEGOTIATIONEND: 'EINDE ONDERHANDELING',
    // ORIGINAL TEXT: NEGOTIATION END
    DOCID: 'DOCUMENT-ID',
    // ORIGINAL TEXT: DOCUMENT ID
    PARAGRAPHID: 'PARAGRAFIE-ID',
    // ORIGINAL TEXT: PARAGRAPH ID
    ACTION: 'LOGGED ACTIE',
    // ORIGINAL TEXT: LOGGED ACTION
    DETAIL: 'GEGEVENS VAN DE ACTIE',
    // ORIGINAL TEXT: ACTION DETAIL
    CONTEXT: 'EXTRA CONTEXT',
    // ORIGINAL TEXT: ADDITIONAL CONTEXT
    GROUPNAME: 'GROEPSNAAM',
    // ORIGINAL TEXT: GROUP NAME
    GROUPDESC: 'BESCHRIJVING VAN DE GROEP',
    // ORIGINAL TEXT: GROUP DESCRIPTION
    DOCTYPEDESC: 'DOCUMENTTYPE BESCHRIJVING',
    // ORIGINAL TEXT: DOCUMENT TYPE DESCRIPTION
    ADDLCLAUSETYPES: 'LABELS VAN DE KUSTLAUS',
    // ORIGINAL TEXT: CUSTOM CLAUSE LABELS
    ADDLTAGS: 'KUSTTAKKEN',
    // ORIGINAL TEXT: CUSTOM TAGS
    COLLABORATIONRULES: 'COLLABORATIEREGELS',
    // ORIGINAL TEXT: COLLABORATION RULES
    NEGOTIATIONRULES: 'ONDERHANDELINGSREGELS',
    // ORIGINAL TEXT: NEGOTIATION RULES
    NOTIFICATIONRULES: 'AANMELDINGSREGELS',
    // ORIGINAL TEXT: NOTIFICATION RULES
    LOG: 'LOG',
    // ORIGINAL TEXT: LOG
    ALERTNAME: 'NAAM VAN DE ALERT',
    // ORIGINAL TEXT: ALERT NAME
    ALERTDESC: 'BESCHRIJVING VAN DE ALERT',
    // ORIGINAL TEXT: ALERT DESCRIPTION
    TOPICS: 'SUBSIDIES',
    // ORIGINAL TEXT: TOPICS
    RECIPIENTS: 'HULPSTOFFEN',
    // ORIGINAL TEXT: RECIPIENTS
    SELECTIVITY: 'SELECTIVITEIT',
    // ORIGINAL TEXT: SELECTIVITY
    FIELDNAME: 'FIELD NAME',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: 'ORDER',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: 'EXAMPLE',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: 'DEFAULT',
    // ORIGINAL TEXT: DEFAULT
    VALUE: 'VALUE',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: 'DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: 'PLAYBOOK',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: 'TEMPLATE',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: 'ARCHIVED DOCUMENT',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: 'ORIGINATED FROM TEMPLATE:',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: 'COLLABORATIE',
    // ORIGINAL TEXT: COLLABORATION
    NEGSHORT: 'ONDERHANDELING',
    // ORIGINAL TEXT: NEGOTIATION
    OWNERS: 'EIGEN MIDDELEN',
    // ORIGINAL TEXT: OWNERS
    COLLABORATORS: 'KOLLABORATOREN',
    // ORIGINAL TEXT: COLLABORATORS
    NEGOTIATORS: 'ONDERHANDELINGEN',
    // ORIGINAL TEXT: NEGOTIATORS
    // ADDITION
    DOCUMENTDETAILS: 'GEGEVENS VAN HET DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT DETAILS
    CREATIONDATE: 'Creatiedatum',
    // ORIGINAL TEXT: CREATION DATE
    PARTICIPANTS: 'DEELNEMERS',
    // ORIGINAL TEXT: PARTICIPANTS
    COLLABORATIONANDNEGOTIATION: 'COLLABORATIE EN ONDERHANDELING',
    // ORIGINAL TEXT: COLLABORATION AND NEGOTIATION
    DOCTYPEANDGROUPACCESS: 'DOCUMENTTYPE EN TOEGANG VAN DE GROEP',
    // ORIGINAL TEXT: DOCUMENT TYPE AND GROUP ACCESS
    GROUPOWNERS: 'EIGENHEDEN VAN DE GROEP',
    // ORIGINAL TEXT: GROUP OWNERS
    GROUPCOLLABORATORS: 'GROEPSCOLLABORATOREN',
    // ORIGINAL TEXT: GROUP COLLABORATORS
    DOCUMENTSETTINGS: 'DOCUMENTSETTINGEN',
    // ORIGINAL TEXT: DOCUMENT SETTINGS
    ENABLECOLLABTOMODLABELS:
      'Collaborators inschakelen om Clausule-labels te wijzigen',
    // ORIGINAL TEXT: Enable Collaborators to Modify Clause Labels
    ENABLENEGOTCAPABILITIES:
      'Onderhandelmogelijkheden inschakelen (niet onderhandelmodus)',
    // ORIGINAL TEXT: Enable Negotiation capabilities (Not Negotiation mode)
    KEEPDOCNOTES: 'Documentnotities kopiëren met kopieerbewerking',
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS:
      'Beoordeelde en gewijzigde Clausule Onderwerpen als noodzakelijk',
    // ORIGINAL TEXT: Reviewed and Amended Clause Topics as Necessary
    NOEXECUTIONDATE: 'Geen uitvoeringsdatum',
    // ORIGINAL TEXT: No Execution Date
    EXECUTIONDATE: 'Uitvoeringsdatum',
    // ORIGINAL TEXT: Execution Date
    CONTRACTSTARTDATE: 'Begindatum contract',
    // ORIGINAL TEXT: Contract Start Date
    CONTRACTENDDATE: 'Einddatum contract',
    // ORIGINAL TEXT: Contract End Date
    EXECCONTRACTNAME: 'Geëxecuteerde contractbestandsnaam',
    // ORIGINAL TEXT: Executed Contract File Name
    SELECTEXECCONTRACT: 'selecteer bestand om uitgevoerd contract te uploaden',
    // ORIGINAL TEXT: select file to upload executed contract
    UPLOADEXECUTEDCONTRACT: 'UITGEBREIDE OPDRACHT VAN UPLOAD',
    // ORIGINAL TEXT: UPLOAD EXECUTED CONTRACT
    LOADDEFAULTS: 'LOAD DEFAULTEN',
    // ORIGINAL TEXT: LOAD DEFAULTS
    CLOSED: 'AFGESLOTEN',
    // ORIGINAL TEXT: CLOSED
    NONE: 'Geen',
    // ORIGINAL TEXT: NONE
    NA: 'NVT',
    // ORIGINAL TEXT: NA
    RESULTEXECUTEDCONTRACT: 'Resultaat van de uitgevoerde overeenkomst',
    // ORIGINAL TEXT: Resulted in Executed Contract
    NOSTARTDATE: 'Geen begindatum',
    // ORIGINAL TEXT: No Start Date
    NOENDDATE: 'Geen einddatum',
    // ORIGINAL TEXT: No End Date
    EXCLUDECOLLABORATORSFROMACCESS:
      'Externe collaborateurs uitsluiten van toegang tot document',
    // ORIGINAL TEXT: Exclude External Collaborators From Accessing Document
    EXCLUDENEGOTIATORSFROMACCESS:
      'Externe onderhandelaars uitsluiten van toegang tot document',
    // ORIGINAL TEXT: Exclude External Negotiators From Accessing Document
    REVIEWEDAMENDEDCLAUSELABELS:
      'Beoordeelde en gewijzigde bepalingen als noodzakelijk',
    // ORIGINAL TEXT: Reviewed and Amended Clause Labels as Necessary
    ENTERPARAGRAPHBETWEEN: 'Geef een alineanummer tussen:',
    // ORIGINAL TEXT: Enter a paragraph number between:
    GOTOPARAGRAPHINDEX: 'Ga naar Paragraaf Index',
    // ORIGINAL TEXT: Go to Paragraph Index
    INPUTDOCUMENTNOTEHERE: 'Input document Opmerking hier',
    // ORIGINAL TEXT: Input Document Note here
    INPUTCOMMENTHERE: 'Invoer Commentaar hier',
    // ORIGINAL TEXT: Input Comment here
    SHOWPENDINGONLY: 'Alleen in afwachting tonen',
    // ORIGINAL TEXT: Show Pending Only
    COLLABORATIONSETTING: 'COLLABORATION SETTING',
    // ORIGINAL TEXT: COLLABORATION SETTING
    NEGOTIATIONSETTING: 'ONDERHANDELINGSVERPLICHTING',
    // ORIGINAL TEXT: NEGOTIATION SETTING
    NONEGOTWHENAPPROVED:
      'Kan onderhandelen in goedgekeurde staat niet inschakelen',
    // ORIGINAL TEXT: Cannot turn on Negotiation when in Approved State
    EMAILTOADD: 'E-mail om toe te voegen',
    // ORIGINAL TEXT: Email to add
    CLAUSETOADD: 'Clausule toe te voegen',
    // ORIGINAL TEXT: Clause to add
    TAGTOADD: 'Te toevoegen label',
    // ORIGINAL TEXT: Tag to add
    OPTIONSFORREDLINEINCLUDE: 'Opties voor redlines om op te nemen',
    // ORIGINAL TEXT: Options for Redlines to Include
    ONLYINCLUDEOWNERAPPROVEDEDITS:
      'Alleen door de eigenaar goedgekeurde documentbewerkingen invoegen',
    // ORIGINAL TEXT: Only Include Owner-approved Document Edits
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'Alleen Eigenaar en Onderhandelaar goedgekeurde Document Edits opnemen',
    // ORIGINAL TEXT: Only Include Owner and Negotiator-approved Document Edits
    INCLUDEALLDOCEDITS:
      'Alle documentbewerkingen toevoegen (ongeacht de goedkeuring)',
    // ORIGINAL TEXT: Include All Document Edits (regardless of approval)
    REDLINECHANGESSELECTED: 'Geselecteerde wijzigingen op rode lijn',
    // ORIGINAL TEXT: Redlined Changes Selected
    TOTALCHANGES: 'Totaal Wijzigingen',
    // ORIGINAL TEXT: Total Changes
    REPLACEREDLINES: 'Redlines vervangen',
    // ORIGINAL TEXT: Replace Redlines
    DELETEREDLINES: 'Redlines verwijderen',
    // ORIGINAL TEXT: Delete Redlines
    ADDAFTERREDLINES: 'Add-na Redlines',
    // ORIGINAL TEXT: Add-After Redlines
    OPTIONSFORPREVREDLINES: 'Opties voor vorige roodlijnen',
    // ORIGINAL TEXT: Options for Previous Redlines
    OVERWRITEPREVREDLINES: 'Alle vorige regels in documentbron overschrijven',
    // ORIGINAL TEXT: Overwrite all Previous Redlines in Document Source
    KEEPPREVREDLINES: 'Alle vorige regels in documentbron bewaren',
    // ORIGINAL TEXT: Keep all Previous Redlines in Document Source
    GENERATEFINALDOC: 'Einddocument aanmaken (zonder rode regels)',
    // ORIGINAL TEXT: Generate Final Document (without Redlines)
    DEFAULTESIGNOTE:
      'Zoals besproken, geef uw e-handtekening aan dit contract.',
    // ORIGINAL TEXT: As discussed, please provide your eSignature to this contract.
    SUBMITTEDSIGMESSAGE: 'Ingediend voor handtekeningen.',
    // ORIGINAL TEXT: Submitted for signatures.  Please wait.
    UPLOADEDMESSAGE: 'Geüpload',
    // ORIGINAL TEXT: Uploaded
    ADDADDLFILES:
      'Facultatief - Extra bestanden toevoegen aan ondertekeningsverzoek',
    // ORIGINAL TEXT: Optional - Adding additional files to signature request
    ADDFILESMSG: 'extra bestanden voor ondertekening toevoegen',
    // ORIGINAL TEXT: add additional files for signature
    ESIGPREPAIDPLANS:
      'eSignature-functie is alleen beschikbaar via onze prepaid-abonnementen.',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      'Neem voor meer informatie contact met ons op via billing@mylegaleinstein.com.',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: 'More videos at the ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'Documenttype wordt niet ondersteund. MLE accepteert alleen docx- en pdf-formaten.',
    // ORIGINAL TEXT: Document type is not supported.  MLE only accepts docx and pdf formats.
    PDFSUPPORTEDFORPREMIUMONLY:
      'Voor PDF scannen, moet de gebruiker upgraden naar Premium abonnement of beter.',
    // ORIGINAL TEXT: For PDF scanning, user needs to upgrade to Premium subscription or better.
    BADDOCFORMAT:
      'Foutief documentformaat werd doorgegeven aan documentverwerking.',
    // ORIGINAL TEXT: Bad Document format was passed to document processing.
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      'U hebt niet het voorrecht om wijzigingen aan te brengen in de Documentinstellingen. U bent verplicht om eigenaar te zijn.',
    // ORIGINAL TEXT: You do not have the privilege to make changes to the Document Settings.  You are required to be an Owner.
    PRIVILEGSNOTAVAIL:
      'De privileges voor deze gebruiker zijn niet beschikbaar. Probeer het later nog eens.',
    // ORIGINAL TEXT: The privileges for this user are not available.  Try again later.
    EXHAUSTEDTRIALLICENSE:
      'U heeft het aantal toegestane documenten onder uw proefperiode uitgeput. Selecteer "Upgrade Abonnement" in uw profiel.',
    // ORIGINAL TEXT: You have exhausted the number of allowed documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    EXHAUSTEDMONTHLYLIMIT:
      'U heeft het aantal toegestane maandelijkse documenten onder uw proefperiode uitgeput. Selecteer "Upgrade Abonnement" in uw profiel.',
    // ORIGINAL TEXT: You have exhausted the number of allowed monthly documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    PASSWORDNOTMATCHING:
      'Wachtwoorden komen niet overeen. Controleer de wachtwoorden opnieuw.',
    // ORIGINAL TEXT: Passwords do not match.  Check the passwords again.
    ABAMEMBERIDLENGTH:
      "ABA Lidmaatschap ID's zijn 7 tekens lang. Gelieve te beoordelen en in te dienen.",
    // ORIGINAL TEXT: ABA Membership IDs are 7 characters long.  Please review and submit.
    TERMSNOTCHECKED:
      'De Algemene Voorwaarden zijn niet herzien en gecontroleerd.',
    // ORIGINAL TEXT: The Terms and Conditions have not been reviewed and checked.
    EMAILFORMATBAD: 'E-mailformaat is niet geldig. Bekijk e-mailadres.',
    // ORIGINAL TEXT: Email format is not valid.  Please review email address.
    CANCELEDACTION:
      'Uw laatste actie is geannuleerd. De toepassing wordt nu opnieuw geladen.',
    // ORIGINAL TEXT: Your last action have been canceled.  Application will now reload.
    SUCCESSACTION:
      'Uw laatste actie is succesvol geweest. Toepassing zal nu herladen.',
    // ORIGINAL TEXT: Your last action has been successful.  Application will now reload.
    USERSUCCESSLOGIN: 'Succesvol aangemeld als',
    // ORIGINAL TEXT: Successfully logged on as
    USERLOGGEDOUT: 'De gebruiker is uitgelogd',
    // ORIGINAL TEXT: The user has been logged out
    EMAILNOTVERIFIED:
      'E-mailaccount is nog niet geverifieerd. Er is een e-mailverificatie naar u verzonden.',
    // ORIGINAL TEXT: Account email has not yet been verified.  An email verification has been sent to you.
    EMAILALREADYREGISTERED:
      'Deze e-mail heeft al een eerder geregistreerd account.',
    // ORIGINAL TEXT: This email already has a previously registered account.
    EMAILNOTVERIFIEDRESENT:
      'Uw e-mail is niet geverifieerd. We hebben een hekel aan een e-mail verificatie link naar uw e-mail om het nieuwe account te activeren.',
    // ORIGINAL TEXT: Your email has not been verified.  We have resent you an email verification link to your email to activate the new account.
    USERLOGFILENOTEXISTS: 'Fout - er bestaat geen log-gebruikersbestand voor:',
    // ORIGINAL TEXT: Error - no log user file exists for:
    PASSWORDRESETSENT:
      'Een e-mail wachtwoord reset bericht is verzonden voor uw account.',
    // ORIGINAL TEXT: An email password reset message has been sent for your account.
    PASSWORDCHANGED: 'Uw wachtwoord is gewijzigd.',
    // ORIGINAL TEXT: Your password has been changed.
    REGISTRATIONDELETED: 'verwijderde gebruikersregistratie',
    // ORIGINAL TEXT: deleted user registration
    USERACCOUNTUPDATED:
      'U heeft de instellingen van uw gebruikersaccount bijgewerkt. Wijzigingen zullen de volgende keer dat u zich aanmeldt van kracht worden.',
    // ORIGINAL TEXT: You have updated your user account settings.  Changes will take into effect the next time you login.
    UPGRADEDTOREFERRED:
      'Uw account is bijgewerkt naar Verwezen Trial abonnement.',
    // ORIGINAL TEXT: Your account has been updated to Referred Trial subscription.
    REGISTEREDTRIALUSER:
      'Nieuwe account is geregistreerd als Trial abonnement.',
    // ORIGINAL TEXT: New account is registered as Trial subscription.
    DELETEDUSERACCOUNT: 'Gedelete gebruikersprofielaccount',
    // ORIGINAL TEXT: Deleted user profile account
    EMAILALREADYREGISTEREDRESENT:
      'E-mail is nog niet geregistreerd bij Docgility. Er is een e-mailuitnodiging verzonden naar',
    // ORIGINAL TEXT: Email has not already been registered with Docgility.  An email invitiation has been sent to
    UPGRADEEXTERNALPAYMENTS:
      'Bedankt voor het upgraden. U wordt omgeleid naar een externe betaaldienst en na het voltooien van uw transactie, moet u opnieuw inloggen voor een abonnementsdienst om te starten.',
    // ORIGINAL TEXT: Thanks for upgrading.  You will be rerouted to an external payment service and after completing your transaction, you will need to login again for subscription service to start.
    BILLINGEXTERNALPAYMENTS:
      'Bevestig dat u wilt worden doorgestuurd naar onze externe betaal- en facturatiedienst. Nadat u wijzigingen heeft aangebracht, dient u opnieuw in te loggen voor wijzigingen die van kracht kunnen worden.',
    // ORIGINAL TEXT: Please confirm that you will like to be directed to our external payment and billing service.  After you make any changes, you will need to login again for changes to take effect.
    NOTABLETOLOGINATTHISTIME:
      'Niet in staat om in te loggen op dit moment. Probeer het later opnieuw.',
    // ORIGINAL TEXT: Not able to login at this time.  Try again later.
    NOTREGISTERED:
      'Gebruiker heeft zich niet eerder aangemeld. Registreer voordat u probeert in te loggen.',
    // ORIGINAL TEXT: User has not previously registered.  Register before trying to login.
    NAMETOOLONG: 'De naam die u invoerde is te lang. Max is 60 karakters.',
    // ORIGINAL TEXT: The name you entered is too long.  Max is 60 characters.
    COMPANYTOOLONG:
      'Het bedrijf dat je binnenkwam is te lang. Max is 60 tekens.',
    // ORIGINAL TEXT: The company you entered is too long.  Max is 60 characters.
    COMMENTTOOLONG:
      'De opmerkingen die je in te voeren is te lang. Max is 10000 tekens.',
    // ORIGINAL TEXT: The comments you entered is too long.  Max is 10000 characters.
    DOCEDITTOOLONG:
      'Het document dat u invoert is te lang. Max is 10000 tekens.',
    // ORIGINAL TEXT: The document edits you entered is too long.  Max is 10000 characters.
    TITLETOOLONG: 'De titel die je invulde is te lang. Max is 60 karakters.',
    // ORIGINAL TEXT: The title you entered is too long.  Max is 60 characters.
    PASSWORDTOOSHORT:
      'Het door u opgegeven wachtwoord is te kort. Wachtwoord moet minstens 6 tekens zijn.',
    // ORIGINAL TEXT: The password you entered is too short.  Password has to be at least 6 characters.
    CONGRATSNEWDOCUMENTPROCESSING:
      'Geweldig! U heeft een nieuw document verwerkt. Het duurt een paar minuten en het paneel moet zich vernieuwen wanneer het document is voltooid. Wacht een paar minuten.',
    // ORIGINAL TEXT: Great! You have processed a new document.  It takes a few minutes and the panel should refresh when the document is completed processing.  Please wait a few minutes.
    NODOCUMENTLOADED: 'Geen document geladen',
    // ORIGINAL TEXT: No document loaded
    SELECTFILETOUPLOADDOCX: 'selecteer bestand om te uploaden (*.docx)',
    // ORIGINAL TEXT: select file to upload (*.docx)
    SELECTFILETOUPLOADDOCXPDF:
      'selecteer bestand om te uploaden (*.docx, *.pdf)',
    // ORIGINAL TEXT: select file to upload (*.docx, *.pdf)
    INPUTCOMMENTHERE: 'Input commentaar hier',
    // ORIGINAL TEXT: Input comment here
    AUTOAPPROVEASOWNER: 'Automatisch goedkeuren als eigenaar',
    // ORIGINAL TEXT: Auto Approve as Owner
    INPUTDOCEDITSHERE: 'Invoerdocument Bewerken hier',
    // ORIGINAL TEXT: Input Document Edit here
    TRIGGEREDALLALERTS:
      'Alle (indien van toepassing) waarschuwingen voor dit documenttype zijn geactiveerd.',
    // ORIGINAL TEXT: Triggered all (if any) Alerts for this doctype document.
    QSINTROMESSAGE:
      'MyLegalEinstein analyseert direct uw contracten, indexeert op juridische onderwerpen, en laat zij-voor-zij vergelijkingen van juridische taal toe in een compleet platform voor contractsamenwerking.',
    // ORIGINAL TEXT: MyLegalEinstein instantly analyzes your contracts, indexes by legal topics, and allows side-by-side comparisons of legal language in a complete contract collaboration platform. We will demonstrate the capabilities in four simple steps (detailed instructions are provided in english but similar in all languages.)
    QSDOCMESSAGE:
      'Voor uw gemak, hier zijn een aantal publieke domeincontracten die u kunt downloaden en proberen:',
    // ORIGINAL TEXT: For your convenience, here are some public domain contracts that you can download and try:
    QSHELPMESSAGE:
      'Hier vindt u een handige link naar productdocumentatie (met Wordy versie van Quick Start)',
    // ORIGINAL TEXT: Heres a handy link to product documentation (with wordy version of Quick Start)
    DOCUMENTFAILEDTOPROCESS:
      'Er is een fatale fout opgetreden tijdens het verwerken van het document. Probeer het opnieuw. Als er een probleem blijft bestaan, neem dan contact op met de klantenservice via support@mylegaleinstein.com.',
    // ORIGINAL TEXT: A fatal error occurred while processing the document.  Please try again.  If problem persists, please contact customer support at support@mylegaleinstein.com.

    // ADDED
    DOCUMENTSUCCESSCOPIED: 'Uw document is nu gekopieerd.',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      'Er is nu een schone versie van dit document gegenereerd en opgeslagen.',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      'A document was generated from this template and saved.',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE:
      'A document has been converted into a document template.',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      'Bevestig dat u het opgegeven document wilt verwijderen. Nadat het document verwijderd is, zal het niet meer beschikbaar zijn. U kunt ook overwegen het document naar de status van het archief te verplaatsen. \n\n Bevestig de verwijdering.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified document.  After document is deleted, it will no longer be available.  You may also consider moving the document to Archive status.  \n\n Please confirm delete operation.
    CONFIRMSAVETOPLAYBOOK:
      'Bevestig dat om dit document naar een afspeelboek te converteren. Dit zou dit document unchangable maken, behalve voor de eigenaar. \n\n Bevestig de opslag naar het afspeelboek.',
    // ORIGINAL TEXT: Please confirm that to convert this document to a playbook.  This would make this document unchangable except for the owner.  \n\n Please confirm the save to playbook operation.
    COLLABORATIONNOTE:
      'OPMERKING: Deze informatie wordt ALLEEN INTERNEN gedeeld met aangewezen medewerkers en alleen documenteigenaren.',
    // ORIGINAL TEXT: NOTE: This information is shared INTERNALLY ONLY with designated collaborators and document owners only.
    NEGOTIATIONNOTE:
      'OPMERKING: Deze informatie wordt EXTERN gedeeld met onderhandelingspartijen.',
    // ORIGINAL TEXT: NOTE: This information is shared EXTERNALLY with negotiation counter parties.
    CONFIRMDELETEALERT:
      'Bevestig dat u de opgegeven waarschuwing wilt verwijderen. Nadat de waarschuwing is verwijderd, zal deze niet meer beschikbaar zijn. Bevestig de verwijdering.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified alert.  After alert is deleted, it will no longer be available.  Please confirm delete operation.
    CONFIRMDELETERECIPIENTALERT:
      'Bevestig dat u geen ontvanger meer wilt zijn voor de opgegeven waarschuwing. Na het verwijderen van uzelf als ontvanger, kunt u verzoeken om terug te worden toegevoegd door contact op te nemen met de eigenaar van de waarschuwing. Bevestig de verwijdering.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.
    CONFIRMDELETEGROUP:
      'Bevestig dat u de opgegeven groep wilt verwijderen. Nadat de groep is verwijderd, zal deze niet meer beschikbaar zijn. Het zal echter de volgende keer dat u zich weer aanmeldt weerkaatsen. Bevestig de verwijdering.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified group.  After group is deleted, it will no longer be available.  However, it will reflected the next time you log back in.  Please confirm delete operation.
    CONFIRMDELETECOLLABORATORGROUP:
      'Bevestig alsjeblieft dat je geen medewerker meer wilt zijn voor de opgegeven groep. Na het verwijderen van jezelf als collaborateur, kun je verzoeken om terug te worden toegevoegd door contact op te nemen met de eigenaar van de groep.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a collaborator for the specified group.  After removing yourself as a collaborator, you can request to be added back by contacting the owner of the group.  Please confirm delete operation.
    CONFIRMDELETEDOCTYPE:
      'Bevestig dat u het opgegeven doctype wilt verwijderen. Nadat doctype is verwijderd, zal het niet meer beschikbaar zijn. Bevestig de verwijdering.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified doctype.  After doctype is deleted, it will no longer be available.  Please confirm delete operation.
    EXPIREDTRIAL:
      'Uw proeflicentie is verlopen. Gelieve te upgraden om uw service voort te zetten.',
    // ORIGINAL TEXT: Your trial license has expired.  Please upgrade to continue your service.
    EXPIREDSUBSCRIPTION:
      'Uw abonnementslicentie is verlopen. Gelieve te upgraden om uw service te continueren of contact op te nemen met facturering bij Docgility.',
    // ORIGINAL TEXT: Your subscription license has expired.  Please upgrade to continue your service or contact billing at Docgility.
    SOURCEDOCNOTDOCX:
      'Het brondocument is geen DOCX-document. Redline-mogelijkheden zijn niet beschikbaar.',
    // ORIGINAL TEXT: The Source document is not a DOCX document.  Redline capabilities are not available.
  },
}

export default NL
