<script>
import { dbauthComputed, dbauthActions, dbintegrationComputed } from '@state/helpers'
import SnackService from '@/src/services/SnackService'
import getEnv from '@src/utils/env'

// VueRecaptcha library
import VueRecaptcha from 'vue-recaptcha'

import { formatDateDialog, docroute } from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle, VueRecaptcha },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      // registration info
      form: {
        name: '',
        email: '', // login
        password: '', // login
        confirmp: '',
        newpassword: '',
        confirmnp: '',
        company: '',
        title: '',
        disablealerts: false,
        language: '',
        uniqid: '',
        memberid: '', // empty registration memberid
      },
      checkTC: false,
      verifiedUser: true,
      verifyCancel: false,
      verifiedCancel: false,
      verified: false,
      valid: false,

      languages: [
        { state: 'English', abbr: 'en' },
        { state: 'French', abbr: 'fr' },
        { state: 'German', abbr: 'de' },
        { state: 'Italian', abbr: 'it' },
        { state: 'Spanish', abbr: 'es' },
        { state: 'Portuguese', abbr: 'pt' },
        { state: 'Chinese', abbr: 'zh' },
        { state: 'Japanese', abbr: 'ja' },
        { state: 'Korean', abbr: 'ko' },
      ],

      betalanguages: [
        { state: 'Greek (beta)', abbr: 'el' },
        { state: 'Dutch (beta)', abbr: 'nl' },
        { state: 'Russian (beta)', abbr: 'ru' },
        { state: 'Turkish (beta)', abbr: 'tr' },
      ],

      LANGUAGEBETAPROGRAM: ['john@mylegaleinstein.com'],

      emailrules: [
        (v) => !!v || 'Email is required',
        // (v) => !!v || this.$t('VALIDMSGS.EMAILREQUIRED'),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          // this.$t('VALIDMSGS.EMAILVALID'),
          'Email must be valid',
        // (v) => v.length <= 60 || this.$t('VALIDMSGS.MAX60CHARS'),
        (v) => v.length <= 60 || 'Email must be less than 60 characters',
      ],
      // passwordrules: [],
    }
  },

  computed: {
    ...dbauthComputed,
    ...dbintegrationComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },

    alllanguages() {
      if (
        this.dbauthuser !== null &&
        this.dbauthuser !== undefined &&
        this.LANGUAGEBETAPROGRAM.includes(this.dbauthuser.uniqid)
      ) {
        return this.languages.concat(this.betalanguages)
      }
      return this.languages
    },

    title() {
      if (this.mode === 'Register') {
        if (this.enterpriseInstance) {
          return 'REGISTER FOR DEMO ACCOUNT'
        }
        return 'REGISTER FOR 30-DAY FREE TRIAL'
      }
      return this.mode
    },

    showRegister() {
      return this.mode === this.$t('MENU.LREGISTER')
    },

    showLogin() {
      return this.mode === this.$t('MENU.LLOGIN')
    },

    showLoginSSO() {

      // use the following to get the value if available from backend.
      // values from the backend supercede the front end environment variables.
      if (this.dbssoinfo.samlactivated !== undefined && this.dbssoinfo.samlactivated === 'TRU'){
        return true
      }

      if (getEnv('VUE_APP_SSO_ENABLED') !== undefined) {
        return this.mode === this.$t('MENU.LLOGIN') && getEnv('VUE_APP_SSO_ENABLEDENV') === 'TRU'
      }
      if (process.env.VUE_APP_SSO_ENABLED !== undefined) {
        return this.mode === this.$t('MENU.LLOGIN') && process.env.VUE_APP_SSO_ENABLED === 'TRU'
      }
      return false
    },

    showChangePassword() {
      return this.mode === this.$t('MENU.CHANGEPASSWORD')
    },

    showAccountSettings() {
      return this.mode === this.$t('MENU.ACCOUNTSETTINGS')
    },

    // Indicate message to user.
    verifiedMessage() {
      if (this.verifiedUser) {
        return ''
      } else {
        return 'Unsuccessfully signed in.  Email has not been verified'
      }
    },

    enterpriseInstance() {
      return process.env.VUE_APP_INSTANCETYPE === 'ENTERPRISE'
    },

    subscriptionStatusType() {
      if (this.dbauthPrivileges !== null && this.dbauthProfile !== null) {
        return 'Subscription: ' + this.dbauthPrivileges.name
      }
      return ''
    },
    subscriptionStatusEnd() {
      // if it's an enterprise instance, don't consider trial dates
      if (process.env.VUE_APP_INSTANCETYPE === 'ENTERPRISE') {
        return ''
      }
      if (this.dbauthPrivileges !== null && this.dbauthProfile !== null) {
        if (this.dbauthPrivileges.type >= 'C') {
          return 'Next Billing Cycle: ' + this.dbauthProfile.subscriptionend
        } else {
          return (
            'Trial Expiration: ' +
            // this.dbauthProfile.trailexpdate.toDate().toDateString()
            formatDateDialog(this.dbauthProfile.trailexpdate)
          )
        }
      }
      return ''
    },
  },

  watch: {
    // when logged in, close the dialog
    dbauthuser(newValue, oldValue) {
      if (newValue !== null) {
        this.$emit('close-dialog')
      }
    },

    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setFormValues()
      }
    },
  },

  methods: {
    ...dbauthActions,
    // setLoginMessage() {
    //   if (this.dbauthuser !== null && this.dbauthuser !== undefined) {
    //     this.verifiedUser = true
    //   }
    // },
    resetForm() {
      this.form = {
        name: '',
        email: '',
        password: '',
        confirmp: '',
        newpassword: '',
        confirmnp: '',
        company: '',
        title: '',
        disablealerts: false,
        language: process.env.VUE_APP_DEFAULTLANGUAGE,
        uniqid: '', // added as identifier for originator of account management transactions
        memberid: '', // empty registration memberid
      }
      this.checkTC = false
      this.$emit('close-dialog')
    },

    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    docroute(d) {
      return docroute(d)
    },
    getEnv(d) {
      return getEnv(d)
    },

    // Captcha
    onVerify(response) {
      if (response) {
        this.verified = true
      }
    },

    setFormValues() {
      if (this.dbauthProfile != null) {
        this.form.name = this.dbauthProfile.name
        this.form.company = this.dbauthProfile.company
        this.form.title = this.dbauthProfile.title
        this.form.disablealerts = this.dbauthProfile.disablealerts
        if (this.dbauthProfile.language !== undefined) {
          this.form.language = this.dbauthProfile.language
        } else {
          this.form.language = 'en'
        }
        this.form.uniqid = this.dbauthProfile.uniqid
      }
    },

    loginEmailPassAuth() {
      // Check for any email addresses are prohibited and not allow login
      const checkemail = this.form.uniqid.toLowerCase().trim()
      const prohibiteddomains = ['@apttus.com', '@conga.com', '@icertis.com']
      for (var i = 0, len = prohibiteddomains.length; i < len; ++i) {
        if (checkemail.indexOf(prohibiteddomains[i]) !== -1) {
          SnackService.info('MESSAGES.NOTABLETOLOGINATTHISTIME')
          return
        }
      }
      // Check if previously registered, if not let the user know.
      this.queryValidIDAuth({ uniqid: checkemail }).then(function (bool) {
        if (!bool) {
          SnackService.info('MESSAGES.NOTREGISTERED')
        }
      })

      this.$store.dispatch('dbauth/loginEmailPassAuth', this.form)
      this.resetForm()
      if (this.$route.name !== 'panel-processing') {
        this.$router.push({ path: '/' })
      }
    },

    loginSSO() {
      // redirects to SSOLogin page
      this.$router.push('SSOLogin')
    },

    confirmPasswordResetReg() {
      this.$store.dispatch(
        'dbreg/confirmPasswordResetReg',
        this.form.uniqid.toLowerCase()
      )
      this.resetForm()
    },

    registerNewUser() {
      if (this.form.password !== this.form.confirmp) {
        SnackService.info('MESSAGES.PASSWORDNOTMATCHING')
        return
      }
      // Don't need to check TC if enterprise account.
      if (!this.checkTC && !this.enterpriseInstance) {
        SnackService.info('MESSAGES.TERMSNOTCHECKED')
        return
      }
      if (!this.valid) {
        SnackService.info('MESSAGES.EMAILFORMATBAD')
        return
      }
      if (!this.verified) {
        SnackService.info(
          'eCAPTCHA required: please check that you are not a robot.'
        )
        return
      }

      if (this.form.name.length > 60) {
        SnackService.info('MESSAGES.NAMETOOLONG')
        return
      }
      if (this.form.company.length > 60) {
        SnackService.info('MESSAGES.COMPANYTOOLONG')
        return
      }
      if (this.form.title.length > 60) {
        SnackService.info('MESSAGES.TITLETOOLONG')
        return
      }
      if (this.form.password.length < 6) {
        SnackService.info('MESSAGES.PASSWORDTOOSHORT')
        return
      }

      this.$store.dispatch('dbreg/createUserfromEmailPWReg', this.form)
      this.resetForm()
    },

    updatePasswordinAppReg() {
      if (this.form.password.length < 6) {
        SnackService.info('MESSAGES.PASSWORDTOOSHORT')
        return
      }
      if (this.form.password === this.form.confirmp) {
        this.$store.dispatch('dbreg/updatePasswordinAppReg', {
          uniqid: this.dbauthuser.uniqid,
          newpassword: this.form.password,
          token: this.dbauthuser.token,
          oldpassword: this.dbauthuser.oldpassword,
        })
        this.resetForm()
      } else {
        SnackService.info('MESSAGES.PASSWORDNOTMATCHING')
      }
    },

    updateAccountSettings() {
      if (this.form.name.length > 60) {
        SnackService.info('MESSAGES.NAMETOOLONG')
        return
      }
      if (this.form.company.length > 60) {
        SnackService.info('MESSAGES.COMPANYTOOLONG')
        return
      }
      if (this.form.title.length > 60) {
        SnackService.info('MESSAGES.TITLETOOLONG')
        return
      }
      this.$store.dispatch('dbreg/updateAccountSettingsReg', this.form)
      this.resetForm()
    },

    // Not used
    // cancelAccount() {
    //   if (!this.verifyCancel) {
    //     this.verifyCancel = !this.verifyCancel
    //   } else {
    //     if (this.verifiedCancel) {
    //       this.$store.dispatch('dbreg/cancelAccountReg', this.dbauthuser.uniqid)
    //       this.resetForm()
    //     }
    //   }
    // },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <PanelCardTitle :cardtitle="title"></PanelCardTitle>
        <v-card-text>
          <v-container>
            <v-form v-model="valid" action="#">
              <div
                v-if="showRegister || showAccountSettings"
                class="form-group row"
              >
                <label for="name" class="col-md-3 col-form-label text-md-right"
                  >Name</label
                >
                <div class="col-md-8">
                  <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                    name="name"
                    value
                    required
                  />
                </div>
              </div>
              <div
                v-if="!showChangePassword && !showAccountSettings"
                class="form-group row"
              >
                <label
                  for="uniqid"
                  class="col-md-3 col-form-label text-md-right"
                  >Email</label
                >

                <div class="col-md-8 mt-n4">
                  <v-text-field
                    id="uniqid"
                    v-model="form.uniqid"
                    :rules="emailrules"
                    required
                  >
                  </v-text-field>
                </div>
              </div>

              <div v-if="!showAccountSettings" class="form-group row">
                <label
                  for="password"
                  class="col-md-3 col-form-label text-md-right"
                  >Password</label
                >

                <div class="col-md-8">
                  <input
                    id="password"
                    v-model="form.password"
                    type="password"
                    class="form-control"
                    name="password"
                    required
                  />
                </div>
              </div>

              <div
                v-if="showRegister || showChangePassword"
                class="form-group row"
              >
                <label
                  for="confirmp"
                  class="col-md-3 col-form-label text-md-right"
                  >Confirm Password</label
                >

                <div class="col-md-8">
                  <input
                    id="confirmp"
                    v-model="form.confirmp"
                    type="password"
                    class="form-control"
                    name="confirmp"
                  />
                </div>
              </div>

              <div
                v-if="showRegister || showAccountSettings"
                class="form-group row"
              >
                <label
                  for="company"
                  class="col-md-3 col-form-label text-md-right"
                  >Company</label
                >

                <div class="col-md-8">
                  <input
                    id="company"
                    v-model="form.company"
                    class="form-control"
                    name="company"
                    value
                    required
                  />
                </div>
              </div>

              <div
                v-if="showRegister || showAccountSettings"
                class="form-group row"
              >
                <label for="title" class="col-md-3 col-form-label text-md-right"
                  >Title</label
                >

                <div class="col-md-8">
                  <input
                    id="title"
                    v-model="form.title"
                    class="form-control"
                    name="title"
                    value
                    required
                  />
                </div>
              </div>

              <div v-if="showAccountSettings" class="form-group row">
                <label
                  for="title"
                  class="col-md-3 col-form-label text-md-right mt-5"
                  >Disable Alerts:</label
                >

                <div class="col-md-8">
                  <v-checkbox v-model="form.disablealerts"> </v-checkbox>
                </div>
              </div>
              <div v-if="showAccountSettings" class="form-group row">
                <label
                  for="title"
                  class="col-md-3 col-form-label text-md-right mt-5"
                  >UX Language:</label
                >
                <div class="my-3">
                  <v-select
                    v-model="form.language"
                    :items="alllanguages"
                    item-text="state"
                    item-value="abbr"
                    dense
                    filled
                  ></v-select>
                </div>
              </div>

              <div class="form-group row mb-0"> </div>
            </v-form>
          </v-container>
          <div v-if="showLogin">
            {{ verifiedMessage }}
          </div>
          <v-checkbox
            v-if="showRegister && !enterpriseInstance"
            v-model="checkTC"
            label="By registering you agree to the Terms of Use and Privacy Policy provided below:"
          ></v-checkbox>
          <v-btn
            v-if="showRegister && !enterpriseInstance"
            class="mx-6"
            text
            @click="docroute('general/termsofuse')"
          >
            Terms of Use
          </v-btn>
          <v-btn
            v-if="showRegister && !enterpriseInstance"
            class="mx-3"
            text
            @click="docroute('general/privacypolicy')"
          >
            Privacy Policy
          </v-btn>
          <div v-if="showRegister" class="mt-10 mx-10">
            <VueRecaptcha
              ref="recaptcha"
              sitekey="6Lfdjn4cAAAAAKeKjNq3do-BQ0Przm0x0L_CtYP5"
              :load-recaptcha-script="true"
              @verify="onVerify"
            >
            </VueRecaptcha>
          </div>

          <v-checkbox
            v-if="verifyCancel"
            v-model="verifiedCancel"
            label="Are you sure that you want to cancel and delete your account?  Mark checkbox to confirm."
          ></v-checkbox>
        </v-card-text>
        <div v-if="showAccountSettings" class="mx-4 mt-4 my-6">
          <v-divider></v-divider>
          <h4>{{ subscriptionStatusType }}</h4>
          <h4>{{ subscriptionStatusEnd }} </h4>
          <p> </p>
          <p v-if="dbauthPrivileges.type > 'B'"
            >For more information on changing your subscription settings, select
            "Subscription/Billing" in the Account Menu.</p
          >
          <p v-if="dbauthPrivileges.type < 'C'"
            >In order to upgrade your subscription, choose "Upgrade
            Subscription" under Account Menu.</p
          >
          <v-divider></v-divider>
          <p> </p>
          <p> </p>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="showAccountSettings"
            large
            outlined
            class="mx-4"
            @click="updateAccountSettings"
            >{{ $t('DIALOGS.SAVE') }}</v-btn
          >

          <v-btn
            v-if="showChangePassword"
            large
            outlined
            class="mx-4"
            @click="updatePasswordinAppReg"
            >{{ $t('DIALOGS.CHANGEPASSWORD') }}</v-btn
          >

          <v-btn
            v-if="showLogin"
            small
            outlined
            class="mx-4"
            @click="confirmPasswordResetReg"
            >{{ $t('DIALOGS.RESETPASSWORD') }}</v-btn
          >
          <v-spacer></v-spacer>

          <v-btn
            v-if="showLogin"
            large
            outlined
            class="mx-4"
            @click="loginEmailPassAuth"
            >{{ $t('DIALOGS.LOGIN') }}</v-btn
          >
          <v-btn v-if="showLoginSSO" large outlined class="mx-4" @click="loginSSO">{{
            $t('DIALOGS.LOGINSSO')
          }}</v-btn>
          <v-btn
            v-if="showRegister"
            large
            outlined
            class="mx-4"
            @click="registerNewUser"
            >{{ $t('DIALOGS.REGISTER') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
