<script>
// NAVIGATION
import PanelNavigation from '@components/panel/panel-navigation.vue'

// for batch job calls
import DocumentProcessingService from '@services/DocumentProcessingService'

// PARAGRAPH DETAIL
import ParaC from './para-c.vue'

// COMPARE DETAIL
import ParaCompare from './para-compare.vue'
import ParaIntegrationList from './para-integrationlist.vue'

// STATE
import store from '@state/store'
import { panelComputed, dbauthComputed, syncviewComputed, dbintegrationComputed } from '@state/helpers'
import { panelstate, panelstated } from '@utils/authutils'

// DB OPERATIONS - used only for the listeners
import { db } from '@/src/dbconfig/FBconfig.js'
import {
  doc,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from 'firebase/firestore'

// Dialogs
import ParaDialogSaveDocument from '@components/panel/para-dialog-savedocument.vue'
import ParaDialogCopyDocument from '@components/panel/para-dialog-copydocument.vue'
import ParaDialogCreateVersion from '@components/panel/para-dialog-createversion.vue'
import ParaDialogCreateDocTemplate from '@components/panel/para-dialog-createdoctemplate.vue'
import ParaDialogMovetoArchiveDocument from '@components/panel/para-dialog-movetoArchivedocument.vue'
import ParaDialogCollaboration from '@components/panel/para-dialog-collaboration.vue'
import ParaDialogNegotiation from '@components/panel/para-dialog-negotiation.vue'
import ParaDialogAddOwner from '@components/panel/para-dialog-addowner.vue'
import ParaDialogAddCollaborator from '@components/panel/para-dialog-addcollaborator.vue'
import ParaDialogAddNegotiator from '@components/panel/para-dialog-addnegotiator.vue'
import ParaDialogOpenDocument from '@components/panel/para-dialog-opendocument.vue'
import ParaDialogOpenGroupDocument from '@components/panel/para-dialog-opengroupdocument.vue'
import ParaDialogOpenHubDocument from '@components/panel/para-dialog-openhubdocument.vue'
import ParaDialogOpenArchiveDocument from '@components/panel/para-dialog-openarchivedocument.vue'
import ParaDialogCompareDocument from '@components/panel/para-dialog-comparedocument.vue'
import ParaDialogEsignature from '@components/panel/para-dialog-esignature.vue'
import ParaDialogDownloadRedline from '@components/panel/para-dialog-downloadredline.vue'

import ParaDialogOpenPlaybook from '@components/panel/para-dialog-openplaybook.vue'
import ParaDialogOpenTemplate from '@components/panel/para-dialog-opentemplate.vue'
import ParaDialogSaveTemplate from '@components/panel/para-dialog-savetemplate.vue'
import PanelInfoDialog from '@components/panel/panel-info.vue'
import PanelDialogDocLog from '@components/panel/para-dialog-doclog.vue'

// Action Panel (within Paragraph)
import ParaDialogComments from '@components/panel/para-dialog-comments.vue'
import ParaDialogDocEdits from '@components/panel/para-dialog-docedits.vue'
import ParaDialogTags from '@components/panel/para-dialog-tags.vue'

// Actions Global (from Navigation)
import ParaDialogCommentsAll from '@components/panel/para-dialog-commentsall.vue'
import ParaDialogDocEditsAll from '@components/panel/para-dialog-doceditsall.vue'
import ParaDialogCollabLabels from '@components/panel/para-dialog-tagsall.vue'

//
import ParaDialogDocNotes from '@components/panel/para-dialog-docnotes.vue'
import ParaDialogTriggerAlerts from '@components/panel/para-dialog-triggeralerts.vue'
import ParaDialogGotoParagraph from '@components/panel/para-dialog-gotoparagraph.vue'

import ConfirmAction from '@components/panel/para-dialog-confirmaction.vue'

// UTILITY
import { isEmpty } from 'lodash'
import { getCatIDFromUID } from '@utils/clauseMapping'
import { chNO, chNOarray, chNObool } from '@utils/dialogutils'

import {
  STORgetJSONFile,
  STORcopyFromToFile,
  STORwriteFile,
} from '@/src/state/io/iostorage.js'

// ACTIONS from Navigation
import {
  genExcel,
  genWord,
  genRedline,
  downloadObjectAsJSON,
} from '@utils/panel-actions.js'
import SnackService from '@/src/services/SnackService'
import {
  DBlistenerComments,
  DBlistenerDocEdits,
  DBlistenerTags,
  DBgetobjsync,
} from '@/src/state/io/iodb.js'

import {
  DBHUBgetListenerComments,
  DBHUBgetListenerDocEdits,
  DBHUBgetListenerTags,
  DBHUBgetdocmetadata,
  DBHUBgetdocumentdata,
} from '@/src/state/io/iohub.js'

// DB Backend
const DBFIREBASE = process.env.VUE_APP_DB === 'FIREBASE'
const DBMONGO = process.env.VUE_APP_DB === 'MONGO'

export default {
  components: {
    ParaC,
    ParaCompare,
    ParaIntegrationList,
    PanelNavigation,
    PanelInfoDialog,
    PanelDialogDocLog,
    ParaDialogSaveDocument,
    ParaDialogMovetoArchiveDocument,
    ParaDialogCopyDocument,
    ParaDialogCreateVersion,
    ParaDialogCreateDocTemplate,
    ParaDialogCollaboration,
    ParaDialogNegotiation,
    ParaDialogAddOwner,
    ParaDialogAddCollaborator,
    ParaDialogAddNegotiator,
    ParaDialogOpenDocument,
    ParaDialogOpenGroupDocument,
    ParaDialogOpenHubDocument,
    ParaDialogOpenArchiveDocument,
    ParaDialogOpenPlaybook,
    ParaDialogOpenTemplate,
    ParaDialogCompareDocument,
    ParaDialogSaveTemplate,
    ParaDialogComments,
    ParaDialogDocEdits,
    ParaDialogTags,
    ParaDialogCommentsAll,
    ParaDialogDocEditsAll,
    ParaDialogCollabLabels,
    ParaDialogDocNotes,
    ParaDialogTriggerAlerts,
    ParaDialogGotoParagraph,
    ParaDialogEsignature,
    ParaDialogDownloadRedline,
    ConfirmAction,
  },
  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    panelsdistinct: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // for file upload
      showFileUpload: false, // show file upload detail.
      progresson: false, // turn on when getFile processing.
      // uploadprogress: 0, // show the progress of the upload (if shown)
      progresscircular: 0, // the progress of the background process

      // confirmation dialog
      confirmSavetoPlaybook: false,

      // panel state
      actionPara: {}, // this is the para that is called to access the dialog
      editable: false, // this controls whether clause labels are editable

      // compressed state
      pcompressed: false, // this is a switch to toggle whether you want the entire panel to be uncompressed.
      penglishtranslate: false, // this is a switch to toggle whether you want the entire panel to be translated to english.
      doctranslation: {}, // set doctranslation null until requested
      originaltext: {}, // set doctranslation null until requested
      comparetext: {}, // set doctranslation null until requested

      editsonlyview: false, // this toggles whether to include sections that have edits only.
      // compressed is computed and would recommend if panel is compressed.
      showcollaborativeedits: true,
      shownegotiationedits: false, // this triggers the text to show the modifications of the agreed upon changes.

      // for determining if docx is source
      eligibledocxin: false,

      // toggles to control dialog presentation (one for each dialog)
      showSaveDocumentDialog: false,
      showCopyDocumentDialog: false,
      showCreateVersionDialog: false,
      showCreateDocTemplateDialog: false,
      showMovetoArchiveDocumentDialog: false,
      showCollaborationDialog: false,
      showNegotiationDialog: false,
      showEsignatureDialog: false,
      showDownloadRedlineDialog: false,
      showAddOwnerDialog: false,
      showAddCollaboratorDialog: false,
      showAddNegotiatorDialog: false,
      showOpenDocumentDialog: false,
      showOpenGroupDocumentDialog: false,
      showOpenHubDocumentDialog: false,
      showOpenArchiveDocumentDialog: false,
      showOpenPlaybookDialog: false,
      showOpenTemplateDialog: false,
      showCompareDocDialog: false,
      showSaveTemplateDialog: false,
      showTagsDialog: false,
      showCollabCommentsDialog: false,
      showCollabCommentsAllDialog: false,
      showCollabDocEditsAllDialog: false,
      showCollabDocEditsDialog: false,
      showNegotCommentsDialog: false,
      showNegotCommentsAllDialog: false,
      showNegotDocEditsAllDialog: false,
      showNegotDocEditsDialog: false,
      showCollabLabelsDialog: false,
      showPanelInfoDialog: false,
      showDocLogDialog: false,
      showDocNotesDialog: false,
      showGotoParagraphDialog: false,
      showTriggerAlertsDialog: false,

      // topic filtering  this is only active if filterTopicView (in global state is negative)
      panelFilterTopic: { action: 'ALL' }, // this is the locally chosen filter topic.
      effectiveTopic: { action: 'ALL' }, // this is the local setting for effective topic.  this value depends on panelSync and global topic sync.
      selectivityTopic: '0', // this indicates how selective the local panel is to the topic.
      panelSync: false, // if true, the local panel will be synced to larger panel

      // paracontext
      lparacontext:'',

      // local search state
      lsearchOn: false,
      lsearchCapsOn: false,
      lsearchText: '',

      // local compare state
      lcompareOn: false,
      lcomparedocid: '',
      lcomparedocname: '',
      lcompoptnonsequential: false,

      // local integration state
      lintegrationOn: false,
      lrefreshIntegration: false,
      lintegname: '',
      lintegtype: '',
      lintegcreds: '',
      lintegparas: '',
      lintegspecs: '',

      // return result from integration call.
      lintegdata: {},

      // available document types
      // availTopicSelectivity - list of 4 only, defaults to Strict/0 (ok) hardcoded here.
      uploaddoctype: { label: 'NONE', value: '1' }, // sets the default to the first one.
      doctypesdefault: [{ label: 'NONE', value: '1' }],

      COLLABORATION: 'COLLABORATION',
      NEGOTIATION: 'NEGOTIATION',

      // listeners that will need to be deactivated upon close document.
      listenersOn: false,

      // firebase listeners
      listenerFBcomments: null,
      listenerFBdocedits: null,
      listenerFBtags: null,
      listenerFBdocmeta: null,

      // mongo listener
      listenerMongoseconds: 5,
      listenerMongodoccompseconds: 5,
      listenerMongodoccompleted: null,

      listenerMongocomments: null,
      listenerMongodocedits: null,
      listenerMongotags: null,
      listenerMongodocmeta: null,

      ifHUBType: false,

      // debug
      debugString: 'start', // delete soon.
    }
  },

  computed: {
    ...panelComputed,
    ...dbauthComputed,
    ...syncviewComputed,
    ...dbintegrationComputed,

    // gets the panel state for document handing, used in other child functions.
    localpanelstate() {
      const temppanelstate = panelstate(
        this.docDocData[this.panelno],
        this.docRawData[this.panelno],
        this.dbauthuser,
        this.dbauthGroupsAccess,
        this.dbauthDocTypes
      )

      // adding HubType to localpanelstate
      temppanelstate.ifHUBType = this.ifHUBType

      return temppanelstate
    },

    availdoctypes() {
      let retavaildoctypes = this.doctypesdefault
      // only D and above can create documents of special doctypes
      if (this.dbauthprivilege >= 'G') {
        this.dbauthDocTypes.forEach((e) =>
          retavaildoctypes.push({ label: e.doctype, value: e.keyid })
        )
      }
      return retavaildoctypes
    },

    // display version of panelstate
    panelstated() {
      const temppanelstated = panelstated(this.localpanelstate, this.ifProcessing)
      // if HUB, add letter to displayD for now.
      if (this.ifHUBType){
        temppanelstated.privilegeD += 'H'
      }

      // return panelstated(this.localpanelstate, this.ifProcessing)
      return temppanelstated
    },

    // state for whether ifProcessing
    ifProcessing() {
      return this.aPIDprocessStatus[this.panelno]
    },

    // document logs for this panelno
    doclogs() {
      return this.docDocLog[this.panelno]
    },

    document() {
      return this.docRawData[this.panelno]
    },

    availKeys() {
      if (
        this.docRawData[this.panelno] === null ||
        this.docRawData[this.panelno] === undefined
      ) {
        return []
      }
      return Object.keys(this.docRawData[this.panelno].clauseId)
    },

    // implements topic filtering
    paraIndicesTopic() {
      if (this.docRawData[this.panelno] === null) {
        return []
      }
      const allKeys = Object.keys(this.docRawData[this.panelno].clauseId)
      if (this.effectiveTopic.action === 'ALL') {
        return allKeys
      } else {
        var returnArray = []
        var i
        // create an array from the clause id object.
        const clauseArray = Object.entries(
          this.docRawData[this.panelno].clauseId
        )

        // iteratte through the clause array.
        for (const [key, value] of clauseArray) {
          // expand the array of values that can be considered when matching clauses.
          var selectivityTargetCategoryArray = []
          selectivityTargetCategoryArray.push(getCatIDFromUID(value.uid))

          for (i = 0; i < this.selectivityTopic; i++) {
            selectivityTargetCategoryArray.push(
              getCatIDFromUID(value.alternate[i].uid)
            )
          }
          // determine based on superset whether it should be included.//
          if (
            selectivityTargetCategoryArray.includes(this.effectiveTopic.action)
            // comment out for now to keep the paragraph counts synchonized.
            // include condition of excluding short paragraphs - less than 100 characters.
            // &&
            // (value.text.length > 100 || this.effectiveTopic.title === 'TITLE')
          ) {
            returnArray.push(key)
          }
        }

        return returnArray
      }
    },

    // Adding search functionality as additional filtering
    searchFilteredParaIndicesTopic() {
      const searchActive =
        this.panelSync && this.lsearchOn && this.lsearchText.trim() !== ''
      if (!searchActive) {
        return this.paraIndicesTopic
      }
      if (this.originaltext === null) {
        return this.paraIndicesTopic
      }

      // Do some further search filtering.
      var returnArray = []

      for (const key in this.paraIndicesTopic) {
        if (
          (this.lsearchCapsOn &&
            this.originaltext[key].OT.includes(this.lsearchText.trim())) ||
          (!this.lsearchCapsOn &&
            this.originaltext[key].OT.toLowerCase().includes(
              this.lsearchText.trim().toLowerCase()
            ))
        ) {
          returnArray.push(key)
        }
      }

      return returnArray
    },

    // this is what is returned to panel to show which indices of clauses.
    paraIndices() {
      const inputIndices = this.searchFilteredParaIndicesTopic

      // simple case
      if (!this.editsonlyview) {
        return inputIndices
      }

      // if nothing loaded yet, return same thing.
      if (
        this.editComments[this.panelno] === null ||
        this.editDocedits[this.panelno] === null ||
        this.editTags[this.panelno] === null
      ) {
        return inputIndices
      }

      var returnArray = []

      for (var index = 0; index < inputIndices.length; index++) {
        var commentsIndex = this.editComments[this.panelno].findIndex(
          (obj) => obj.paraid === inputIndices[index]
        )
        var doceditsIndex = this.editDocedits[this.panelno].findIndex(
          (obj) => obj.paraid === inputIndices[index]
        )
        var tagsIndex = this.editTags[this.panelno].findIndex(
          (obj) => obj.paraid === inputIndices[index]
        )
        var commentsNegIndex = this.editComments[this.panelno].findIndex(
          (obj) =>
            obj.paraid === inputIndices[index] && obj.type === 'NEGOTIATION'
        )
        var doceditsNegIndex = this.editDocedits[this.panelno].findIndex(
          (obj) =>
            (obj.paraid === inputIndices[index] &&
              obj.type === 'NEGOTIATION') ||
            (obj.paraid === inputIndices[index] &&
              obj.type === 'COLLABORATION' &&
              obj.approved)
        )
        // negotiator
        if (this.localpanelstate.negotiatorPriv) {
          if (commentsNegIndex + doceditsNegIndex > -2) {
            returnArray.push(inputIndices[index])
          }
          // other than negotiator.
        } else {
          if (
            commentsIndex +
              doceditsIndex +
              tagsIndex +
              commentsNegIndex +
              doceditsNegIndex >
            -5
          ) {
            returnArray.push(inputIndices[index])
          }
        }
      }
      return returnArray
    },

    compressedrec() {
      return this.pcompressed
    },

    englishtranslaterec() {
      return this.penglishtranslate
    },

    englishavail() {
      if (
        this.documentAvailable &&
        this.docRawData[this.panelno].clauseId[0].language !== undefined &&
        this.docRawData[this.panelno].clauseId[0].language !== 'en' &&
        // only available to premium users
        this.dbauthprivilege >= 'G' &&
        // and not negotiators
        !this.localpanelstate.negotiatorPriv
      ) {
        return true
      }
      return false
    },

    // state
    documentAvailable() {
      return !isEmpty(this.docRawData[this.panelno])
    },

    // paragraph to feed to the highlighted paragraph action
    dataActionPara() {
      return this.actionPara
    },

    // provides permissions for the authorization
    dbauthprivilege() {
      if (this.dbauthPrivileges === null) {
        return 'A'
      } else {
        return this.dbauthPrivileges.type
      }
    },

    relativelynewuser() {
      if (this.dbauthPrivUsage !== null && this.dbauthPrivUsage !== undefined) {
        if (this.dbauthPrivUsage.docsproc < 5) {
          return true
        }
      }
      return false
    },

    // locally declared default input values for feeding defaults for db state changes.
    // DO NOT CONVERT THIS TO OBJECT. NEEDS TO BE MUTABLE FORM TO BE PASSED TO CHILD COMPONENTS.
    // Intermittent problem of not having the information populate child components.  Not sure why.

    docname() {
      return chNO(this.docDocData[this.panelno], 'docname')
    },

    docdescription() {
      return chNO(this.docDocData[this.panelno], 'docdescription')
    },
    docnotes() {
      return chNOarray(this.docDocData[this.panelno], 'docnotes')
    },
    docparty() {
      return chNO(this.docDocData[this.panelno], 'docparty')
    },
    doccounterparty() {
      return chNO(this.docDocData[this.panelno], 'doccounterparty')
    },

    docallowcollclauselabels() {
      const temp = chNO(
        this.docDocData[this.panelno],
        'docallowcollclauselabels'
      )
      if (temp === '') {
        return false
      }
      return chNO(this.docDocData[this.panelno], 'docallowcollclauselabels')
    },

    docenableNegotiation() {
      const temp = chNO(this.docDocData[this.panelno], 'docenableNegotiation')
      if (temp === '') {
        return false
      }
      return chNO(this.docDocData[this.panelno], 'docenableNegotiation')
    },

    // added to support the open and save template functions.
    labelinputdata() {
      // input variables if previously stored in the template.
      return chNOarray(this.docDocData[this.panelno], 'labelinputdata')
    },

    // added for the data fields for the document from templates.
    templateorigid() {
      return chNO(this.docDocData[this.panelno], 'templateorigid')
    },

    templateorigdocname() {
      return chNO(this.docDocData[this.panelno], 'templateorigdocname')
    },

    // add ownerid
    ownerid() {
      // array of emails
      return chNOarray(this.docDocData[this.panelno], 'ownerid')
    },

    // add collaborators
    externalcollaborators() {
      // array of emails
      return chNOarray(this.docDocData[this.panelno], 'externalcollaborators')
    },

    // add negotiators
    externalnegotiators() {
      // array of emails
      return chNOarray(this.docDocData[this.panelno], 'externalnegotiators')
    },

    // Collaboration
    collabstatus() {
      return chNO(this.docDocData[this.panelno], 'collabstatus')
    },
    collabend() {
      return chNO(this.docDocData[this.panelno], 'collabend')
    },
    collabrules() {
      return chNOarray(this.docDocData[this.panelno], 'collabrules')
    },

    // Negotiation
    negotiationstatus() {
      return chNO(this.docDocData[this.panelno], 'negotiationstatus')
    },
    negotiationend() {
      return chNO(this.docDocData[this.panelno], 'negotiationend')
    },
    negotiationrules() {
      return chNOarray(this.docDocData[this.panelno], 'negotiationrules')
    },

    // Signing data
    sigrequestactive() {
      return chNO(this.docDocData[this.panelno], 'sigrequestactive')
    },
    sigrequestid() {
      return chNO(this.docDocData[this.panelno], 'sigrequestid')
    },
    sigrequestuniqid() {
      return chNO(this.docDocData[this.panelno], 'sigrequestuniqid')
    },
    sigrequestcreate() {
      return chNO(this.docDocData[this.panelno], 'sigrequestcreate')
    },

    // Notification for alerts
    notificationrules() {
      return chNOarray(this.docDocData[this.panelno], 'notificationrules')
    },

    // add additional metadata fields to accommmodate the archive information

    archivenotes() {
      return chNO(this.docDocData[this.panelno], 'archivenotes')
    },
    // boolean
    executedcontract() {
      return chNObool(this.docDocData[this.panelno], 'executedcontract')
    },
    executeddate() {
      return chNO(this.docDocData[this.panelno], 'executeddate')
    },
    startdate() {
      return chNO(this.docDocData[this.panelno], 'startdate')
    },
    enddate() {
      return chNO(this.docDocData[this.panelno], 'enddate')
    },
    // boolean
    clausetopics() {
      return chNObool(this.docDocData[this.panelno], 'clausetopics')
    },
    // boolean
    contractattached() {
      return chNObool(this.docDocData[this.panelno], 'contractattached')
    },
    contractename() {
      return chNO(this.docDocData[this.panelno], 'contractename')
    },
    contractdownloadURL() {
      return chNO(this.docDocData[this.panelno], 'contractdownloadURL')
    },

    // Informational
    templateid() {
      return chNOarray(this.docDocData[this.panelno], 'templateid')
    },
    create() {
      // date
      return chNO(this.docDocData[this.panelno], 'create')
    },
    delete() {
      // date
      return chNO(this.docDocData[this.panelno], 'delete')
    },
    active() {
      // date
      return chNO(this.docDocData[this.panelno], 'active')
    },

    docmetaobj() {
      let obj = {}
      obj.docname = this.docname
      obj.docdescription = this.docdescription
      obj.docnotes = this.docnotes
      obj.docparty = this.docparty
      obj.doccounterparty = this.doccounterparty
      obj.docenableNegotiation = this.docenableNegotiation
      obj.docallowcollclauselabels = this.docallowcollclauselabels
      obj.ownerid = this.ownerid
      obj.externalcollaborators = this.externalcollaborators
      obj.externalnegotiators = this.externalnegotiators
      obj.collabstatus = this.collabstatus
      obj.collabend = this.collabend
      obj.collabrules = this.collabrules
      obj.negotiationstatus = this.negotiationstatus
      obj.negotiationend = this.negotiationend
      obj.negotiationrules = this.negotiationrules
      obj.notificationrules = this.notificationrules
      obj.sigrequestactive = this.sigrequestactive
      obj.sigrequestid = this.sigrequestid
      obj.sigrequestuniqid = this.sigrequestuniqid
      obj.templateid = this.templateid
      obj.create = this.create
      obj.delete = this.delete
      obj.active = this.active
      return obj
    },
    filesuploadmessage() {
      if (this.dbauthprivilege < 'G') {
        return this.$t('MESSAGES.SELECTFILETOUPLOADDOCX')
      }
      return this.$t('MESSAGES.SELECTFILETOUPLOADDOCXPDF')
    },
  },
  // if changes in documents, fetch collaborative updates for this panel.
  // need to change this to only update the changed panel.
  watch: {
    docRawData(newValue, oldValue) {
      // needs a better way to detect a new document.

      if (
        newValue[this.panelno] !== null &&
        newValue[this.panelno] !== undefined &&
        newValue[this.panelno].clauseId !== undefined
      ) {
        const clausecount = Object.keys(newValue[this.panelno].clauseId).length
        if (clausecount > 0) {
          const uniqclausecountpanel = clausecount + this.panelno * 1000

          if (
            !this.panelsdistinct.includes(uniqclausecountpanel) ||
            !this.listenersOn
          ) {
            // process the panel
            // if the fileupload is on, shut it off -
            this.showFileUpload = false

            if (this.aPIDjobID[this.panelno] !== null) {
              // get the text from the storage
              setTimeout(
                this.getOriginalText(this.aPIDjobID[this.panelno]),
                3000
              )
            }

            if (
              this.dbauthprivilege >= 'B' &&
              this.aPIDjobID[this.panelno] !== null
            ) {
              this.listenersOn = true
              this.fetchComments()
              this.fetchDocEdits()
              this.fetchTags() // may want to restrict that negotiators do not get alerted for this.
              // also fetch any updates to docDocData and updates
              this.fetchDocData()
            }

            this.$emit('add-distinct', uniqclausecountpanel)
          }
        }
      }
    },
    // docDocData(newValue, oldValue) {
    // tried to edit based on this state but doesn't seem to work as well.  not sure why.

    // refresh the topic syncing between panels
    filterTopicView(newValue, oldValue) {
      if (newValue === true) {

        // commented out - seems to be odd behavior when first turning on sync
        // if (this.panelSync === true) {
        //   this.effectiveTopic = this.filterTopic
        // }

      } else {
        this.effectiveTopic = this.panelFilterTopic
      }
    },
    filterTopic(newValue, oldValue) {
      if (this.filterTopicView === true) {
        if (this.panelSync === true) {
          this.effectiveTopic = this.filterTopic
        }
      } else {
        this.effectiveTopic = this.panelFilterTopic
      }
    },

    paraContext(newValue, oldValue) {
      if (newValue !== '' && this.lintegrationOn){
        // mechanism triggered when sync is on
        if (this.panelSync){
          this.lparacontext = this.paraContext
          this.setIntegrationInfo(this.lintegname)
        }
        // mechanism triggered when clicking the refresh button in integration.
        if (this.lrefreshIntegration){
          this.lparacontext = this.paraContext
          this.setIntegrationInfo(this.lintegname)
          this.lrefreshIntegration = false
        }
      }
    },
    // search watch state
    searchChanged(newValue, oldValue) {
      if (this.panelSync === true) {
        this.lsearchOn = this.searchOn
        this.lsearchCapsOn = this.searchCapsOn
        this.lsearchText = this.searchText
      }
    },

    // activates comparison goto paragraph
    compareChange(newValue, oldValue) {
      if (
        this.panelSync === true &&
        this.aPIDjobID[this.panelno] === this.comparedocid
      ) {
        // triggers local comparison para search
        this.gotoPara(this.comparepara)
      }
    },

    panelsToShow(newValue, oldValue){
      if (newValue < oldValue && this.panelno === this.panelsToShow){
        this.closeDocument()
      }
    },
  },

  methods: {
    // File Processing functions to enable file upload, calls backend.

    getFile(e) {
      // reset the panel when processing a new document.
      this.resetPanelState()

      // check if empty file. If so, just return.
      if (e === undefined) {
        return
      }

      // check file type - if not supported, return
      var docformat = ''
      if (
        e.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        docformat = 'docx'
        // set the expected time based on the docformat and the number of bytes
        // this.expectedtotaltime = parseInt(e.size * 0.003)
      } else if (e.type === 'application/pdf') {
        docformat = 'pdf'
        // set the expected time based on the docformat and the number of bytes
        // this.expectedtotaltime = parseInt(e.size * 0.001)
      } else {
        SnackService.info('MESSAGES.DOCTYPENOTSUPPORTED')
        return
      }

      // Check Privilege - only Collaborators can scan PDF documents.
      if (docformat === 'pdf' && this.dbauthprivilege < 'G') {
        SnackService.info('MESSAGES.PDFSUPPORTEDFORPREMIUMONLY')
        return
      }

      // if new user, tell the user what's happening.
      if (this.relativelynewuser) {
        SnackService.info('MESSAGES.CONGRATSNEWDOCUMENTPROCESSING')
      }

      // first, write the file to a cloud location
      const fileName = this.createFileName(e)
      const MyThis = this

      STORwriteFile(fileName, e).then(function (downloadURL) {
        MyThis.processNewDocument(docformat, fileName, downloadURL, e)
      })

      // turn on progress indicator
      this.progresson = true

      // default for new docs should be expanded.
      this.pcompressed = false
      this.penglishtranslate = false
    },

    processNewDocument(docformat, fileName, downloadURL, e) {
      // create a new ID for this new document and write a simple document file
      var newDoc = {
        status: 'new',
        submitted: new Date(),
        owner: this.dbauthuser.uniqid,
        saved: false,
        predstarted: '',
        predfinished: '',
        preddocstats: {},
        predictions: {},
        prederror: '',
        urlname: fileName,
        downloadURL: downloadURL,
        filename: e.name,
        documentTypeId: this.uploaddoctype.value,
      }

      store
        .dispatch('dbdocument/writeNewSyncDocument', newDoc)
        .then((doc) =>
          this.waitDocCompleted({
            document: e,
            docformat: docformat,
            panelno: this.panelno,
            documentTypeId: this.uploaddoctype.value,
            docid: doc.id,
            urlname: fileName,

            // adding to UploadObject
            docname: '--' + e.name,
            // submitted: new Date(),
            owner: this.dbauthuser.uniqid,
            // where does htis come from
            downloadURL: downloadURL,
            dbauthprivilege: this.dbauthprivilege,
          })
        )
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })

      // log - increment processed documents for user
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: this.dbauthuser.uniqid,
        logdetail: {
          action: 'new document',
          detail: e.name,
          context: '',
          // increment
          docsproc: 1,
          docsprocmonth: 1,
        },
      })

      // increment the local state with new docs processed.
      store.dispatch('dbauth/addUserPrivUsageDocProcessedAuth')
    },

    createFileName(e) {
      const date = new Date()
      return (
        date.toISOString().slice(0, 10) +
        '/' +
        this.dbauthuser.uniqid +
        ':' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds() +
        ':' +
        e.name
      )
    },

    waitDocCompleted(uploadObject) {
      if (uploadObject.docformat === 'docx') {
        // process docx
        store.dispatch('panels/uploadDocument', uploadObject)
      } else if (uploadObject.docformat === 'pdf') {
        // process pdf
        store.dispatch('panels/uploadPDFDocument', uploadObject)
      } else {
        SnackService.error('MESSAGES.BADDOCFORMAT')
        return
      }

      if (DBMONGO) {
        // turn on listener
        this.listenerMongodoccompleted = setInterval(() => {
          this.startlistenerMongodoccompleted(uploadObject)
        }, this.listenerMongodoccompseconds * 1000)
      }

      if (DBFIREBASE) {
        // const dbdocuments = db.collection('documents')
        // var doccompletedlistener = dbdocuments
        //   .where('docid', '==', uploadObject.docid)
        //   .where('status', '==', 'processing')
        //   .onSnapshot((querySnapshot) => {
        const q = query(
          collection(db, 'documents'),
          where('docid', '==', uploadObject.docid),
          where('status', '==', 'processing')
        )

        var doccompletedlistener = onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data()

            if (
              data.predstatus === 'processing' ||
              data.predstatus === 'scanning'
            ) {
              this.progresscircular = data.predprogress
            } else {
              doccompletedlistener()

              this.finishDocCompleted(data, uploadObject)
            }
          })
        })
      }
    },

    finishDocCompleted(data, uploadObject) {
      if (data.predstatus === 'failed') {
        // job failed
        SnackService.error('MESSAGES.DOCUMENTFAILEDTOPROCESS')
        this.progresson = false
        // need to reset the document for this current panel.  - modified to reset the edit states.
        store.dispatch('panels/initpanel', this.panelno)
        // reset panel edit states
        this.resetPanelState()
      } else if (data.predstatus === 'processed') {
        // job finished and return results
        this.progresson = false
        this.showFileUpload = false

        store.dispatch('panels/getDocRawData', {
          panelno: this.panelno,
          // predictions: data.predictions,
          predictions: data.docRawData,
        })
        // when document is completed, load the originalText that was processed.
        // setTimeout(this.getOriginalText(uploadObject.docid), 4000)
        this.getOriginalText(uploadObject.docid)

        // implement code to auto-save if privileges allows saving.
        if (this.dbauthprivilege > 'B') {
          // autosave
          // commenting out autosave for testing purposes
          // 'commented out auto-save')
          // this.saveDocument(
          //   {
          //     docname: '--' + uploadObject.document.name,
          //     docdescription: '',
          //     docparty: '',
          //     doccounterparty: '',
          //     docother: '',
          //     docallowcollclauselabels: false,
          //     docenableNegotiation: false,
          //   },
          //   uploadObject.docid
          // )
          // ending auto-save commenting out
        }
      } else if (data.predstatus === 'processing') {
        this.progresscircular = data.predprogress
      }
    },

    // methods for triggering para-specific actions.
    showCollabCommentsPanel(actionPara) {
      this.actionPara = actionPara
      this.showCollabCommentsDialog = true
    },
    showCollabDocEditsPanel(actionPara) {
      this.actionPara = actionPara
      this.showCollabDocEditsDialog = true
    },
    showTagsPanel(actionPara) {
      this.actionPara = actionPara
      this.showTagsDialog = true
    },
    showNegotCommentsPanel(actionPara) {
      this.actionPara = actionPara
      this.showNegotCommentsDialog = true
    },
    showNegotDocEditsPanel(actionPara) {
      this.actionPara = actionPara
      this.showNegotDocEditsDialog = true
    },

    // when clause text is clicked in the panel, this is triggered.
    setLocalParaContext(actionsPara) {
      store.dispatch('syncview/setParaContext', actionsPara.OT)
    },

    // when clause text is clicked in the panel of the integration panel, only trigger this if localsync on
    setLocalParaContextfromIntegration(actionsPara) {
      if (this.panelSync){
        store.dispatch('syncview/setParaContext', actionsPara.OT)
      }
    },

    // auto-state updates via watch - this mechanism is necessary to propagate changes when changes are made to docmeta.
    fetchDocData() {
      if (DBMONGO) {
        // turn on listener
        this.startlistenerMongodocdata()
        this.listenerMongodocmeta = setInterval(() => {
          this.startlistenerMongodocdata()
        }, this.listenerMongoseconds * 1000)
      }

      // firebase Listener - converted
      if (DBFIREBASE) {
        const MyThis = this

        //  const dbdocmeta = collection(db, 'docmeta')
        if (this.aPIDjobID[this.panelno] !== null) {
          // this.listenerFBdocmeta = dbdocmeta
          //   .doc(this.aPIDjobID[this.panelno])
          this.listenerFBdocmeta = onSnapshot(
            doc(db, 'docmeta', this.aPIDjobID[this.panelno]),
            (doc) => {
              // .onSnapshot(function (doc) {
              store.dispatch('panels/updateDocData', {
                panelno: MyThis.panelno,
                docobj: doc.data(),
              })
              MyThis.determineSourceDocument()
            }
          )
        }
      }
    },

    // work on this to incrementally check status
    startlistenerMongodoccompleted(uploadObject) {
      const MyThis = this

      DBgetobjsync('documents', uploadObject.docid).then(function (doc) {
        if (
          doc.data.predstatus === 'processing' ||
          doc.data.predstatus === 'scanning'
        ) {
          MyThis.progresscircular = doc.data.predprogress
        } else {
          // stop listener and do updates
          clearInterval(MyThis.listenerMongodoccompleted)
          MyThis.finishDocCompleted(doc.data, uploadObject)
        }
      })
    },

    // if Mongo, do a quick sync to get new value when adding.
    synccomments() {
      if (DBMONGO) {
        this.startlistenerMongocomments()
        setTimeout(this.startlistenerMongocomments(), 2000)
      }
    },
    syncdocedits() {
      if (DBMONGO) {
        this.startlistenerMongodocedits()
        setTimeout(this.startlistenerMongodocedits(), 2000)
      }
    },
    synctags() {
      if (DBMONGO) {
        this.startlistenerMongotags()
        setTimeout(this.startlistenerMongotags(), 2000)
      }
    },

    startlistenerMongocomments() {
      const MyThis = this
      if (this.listenersOn) {
        if (this.ifHUBType){
          DBHUBgetListenerComments(this.aPIDjobID[this.panelno]).then(function (docs) {
            // fill in keyid
            docs.forEach((doc) => {
              doc.keyid = doc._id
            })
            store.dispatch('panels/updateEditComments', {
              panelno: MyThis.panelno,
              editComments: docs,
            })
          })
        } else {
          DBlistenerComments(this.aPIDjobID[this.panelno]).then(function (docs) {
            // fill in keyid
            docs.forEach((doc) => {
              doc.keyid = doc._id
            })
            store.dispatch('panels/updateEditComments', {
              panelno: MyThis.panelno,
              editComments: docs,
            })
          })
        }
      }
    },
    startlistenerMongodocedits() {
      const MyThis = this
      if (this.listenersOn) {

        if (this.ifHUBType){
          DBHUBgetListenerDocEdits(this.aPIDjobID[this.panelno]).then(function (docs) {
            docs.forEach((doc) => {
              doc.keyid = doc._id
            })
            store.dispatch('panels/updateEditDocEdits', {
              panelno: MyThis.panelno,
              editDocedits: docs,
            })
          })
        } else {
          DBlistenerDocEdits(this.aPIDjobID[this.panelno]).then(function (docs) {
            docs.forEach((doc) => {
              doc.keyid = doc._id
            })
            store.dispatch('panels/updateEditDocEdits', {
              panelno: MyThis.panelno,
              editDocedits: docs,
            })
          })
        }

      }
    },
    startlistenerMongotags() {
      const MyThis = this
      if (this.listenersOn) {
        if (this.ifHUBType){
          DBHUBgetListenerTags(this.aPIDjobID[this.panelno]).then(function (docs) {
            docs.forEach((doc) => {
              doc.keyid = doc._id
            })
            store.dispatch('panels/updateEditTags', {
              panelno: MyThis.panelno,
              editTags: docs,
            })
          })
        } else {
          DBlistenerTags(this.aPIDjobID[this.panelno]).then(function (docs) {
            docs.forEach((doc) => {
              doc.keyid = doc._id
            })
            store.dispatch('panels/updateEditTags', {
              panelno: MyThis.panelno,
              editTags: docs,
            })
          })
        }
      }
    },

    startlistenerMongodocdata() {
      const MyThis = this

      if (this.ifHUBType){
        DBHUBgetdocmetadata(this.aPIDjobID[this.panelno]).then(function (
          doc
        ) {
          store.dispatch('panels/updateDocData', {
            panelno: MyThis.panelno,
            docobj: doc.data,
          })
          MyThis.determineSourceDocument()
        })
      } else {
        DBgetobjsync('docmeta', this.aPIDjobID[this.panelno]).then(function (
          doc
        ) {
          store.dispatch('panels/updateDocData', {
            panelno: MyThis.panelno,
            docobj: doc.data,
          })
          MyThis.determineSourceDocument()
        })
      }
    },

    fetchComments() {
      if (DBMONGO) {
        // turn on listener
        this.startlistenerMongocomments()
        this.listenerMongocomments = setInterval(() => {
          this.startlistenerMongocomments()
        }, this.listenerMongoseconds * 1000)
      }

      // firebase Listener
      if (DBFIREBASE) {
        // const dbcomments = db.collection('comments')
        if (this.aPIDjobID[this.panelno] !== null) {
          const q = query(
            collection(db, 'comments'),
            where('docid', '==', this.aPIDjobID[this.panelno]),
            orderBy('paraid')
          )

          // dbcomments
          //   .where('docid', '==', this.aPIDjobID[this.panelno])
          //   .orderBy('paraid')
          //  .onSnapshot((querySnapshot) => {
          this.listenerFBcomments = onSnapshot(q, (querySnapshot) => {
            let comments = []
            querySnapshot.forEach((doc) => {
              const obj = doc.data()
              obj.keyid = doc.id
              comments.push(obj)
            })
            store.dispatch('panels/updateEditComments', {
              panelno: this.panelno,
              editComments: comments,
            })
          })
        }
      }
    },
    fetchDocEdits() {
      if (DBMONGO) {
        // turn on listener
        this.startlistenerMongodocedits()
        this.listenerMongodocedits = setInterval(() => {
          this.startlistenerMongodocedits()
        }, this.listenerMongoseconds * 1000)
      }

      // firebase Listener
      if (DBFIREBASE) {
        // const dbdocedits = db.collection('docedits')
        if (this.aPIDjobID[this.panelno] !== null) {
          const q = query(
            collection(db, 'docedits'),
            where('docid', '==', this.aPIDjobID[this.panelno]),
            orderBy('paraid')
          )

          // this.listenerFBdocedits = dbdocedits
          //   .where('docid', '==', this.aPIDjobID[this.panelno])
          //   .orderBy('paraid')
          //   .onSnapshot((querySnapshot) => {
          this.listenerFBdocedits = onSnapshot(q, (querySnapshot) => {
            let docedits = []
            querySnapshot.forEach((doc) => {
              const obj = doc.data()
              obj.keyid = doc.id
              docedits.push(obj)
            })
            store.dispatch('panels/updateEditDocEdits', {
              panelno: this.panelno,
              editDocedits: docedits,
            })
          })
        }
      }
    },

    fetchTags() {
      if (DBMONGO) {
        // turn on listener
        this.startlistenerMongotags()
        this.listenerMongotags = setInterval(() => {
          this.startlistenerMongotags()
        }, this.listenerMongoseconds * 1000)
      }

      // firebase Listener
      if (DBFIREBASE) {
        // const dbtags = db.collection('tags')
        if (this.aPIDjobID[this.panelno] !== null) {
          // this.listenerFBtags = dbtags
          //   .where('docid', '==', this.aPIDjobID[this.panelno])
          //   .orderBy('paraid')
          //   .onSnapshot((querySnapshot) => {

          const q = query(
            collection(db, 'tags'),
            where('docid', '==', this.aPIDjobID[this.panelno]),
            orderBy('paraid')
          )

          this.listenerFBtags = onSnapshot(q, (querySnapshot) => {
            let tags = []
            querySnapshot.forEach((doc) => {
              const obj = doc.data()
              obj.keyid = doc.id
              tags.push(obj)
            })
            store.dispatch('panels/updateEditTags', {
              panelno: this.panelno,
              editTags: tags,
            })
          })
        }
      }
    },

    // opens document and document metadata given docid
    openDocument(docid) {
      // if document open, close document first
      if (this.localpanelstate.ifDocLoaded) {
        this.closeDocument()
      }

      store.dispatch('dbdocument/openDocument', {
        panelno: this.panelno,
        uniqid: this.dbauthuser.uniqid,
        docid: docid,
      })

      this.showOpenDocumentDialog = false
      this.showOpenGroupDocumentDialog = false
      this.showOpenHubDocumentDialog = false
      this.showOpenArchiveDocumentDialog = false
      this.showOpenPlaybookDialog = false
      this.showOpenTemplateDialog = false
      this.showCompareDocDialog = false

      this.resetPanelState()
    },

    // only logs that the previous document has been closed.
    closeDocument() {
      let dociddelete = this.aPIDjobID[this.panelno]

      // only try to closeDocMeta if doc has been saved.  Otherwise, nothing to log.
      if (this.localpanelstate.ifSaved) {
        store.dispatch('dbdocmeta/closeDocMeta', {
          uniqid: this.dbauthuser.uniqid,
          docid: dociddelete,
          docname: this.localpanelstate.docmeta.docname,
        })
      }

      this.ifHUBType = false

      // release all listeners for this panel
      if (this.listenersOn) {
        if (DBFIREBASE) {
          // FB listeners
          this.listenerFBdocedits()
          this.listenerFBtags()
          this.listenerFBcomments()
          this.listenerFBdocmeta()
          this.listenersOn = false
        }
        if (DBMONGO) {
          // Mongo listeners
          clearInterval(this.listenerMongocomments)
          clearInterval(this.listenerMongodocedits)
          clearInterval(this.listenerMongotags)
          clearInterval(this.listenerMongodocmeta)
          this.listenersOn = false
        }
      }

      // need to reset the document for this current panel.  - modified to reset the edit states.
      store.dispatch('panels/initpanel', this.panelno)

      // reset panel edit states
      this.resetPanelState()
    },

    // HUB Document handling
    // opens document and document metadata given docid
    openHubDocument(docid) {
      // if document open, close document first
      if (this.localpanelstate.ifDocLoaded) {
        this.closeDocument()
      }

      this.ifHUBType = true

      store.dispatch('dbdocument/openHUBDocument', {
        panelno: this.panelno,
        uniqid: this.dbauthuser.uniqid,
        docid: docid,
      })

      this.showOpenDocumentDialog = false
      this.showOpenGroupDocumentDialog = false
      this.showOpenHubDocumentDialog = false
      this.showOpenArchiveDocumentDialog = false
      this.showOpenPlaybookDialog = false
      this.showOpenTemplateDialog = false
      this.showCompareDocDialog = false

      this.resetPanelState()
    },

    // Does closing a Hub document need special treatment?
    closeHubDocument() {
      this.ifHUBType = false
      this.closeDocument()
    },

    saveCollaboration(formdata) {
      var saveCollaboriationObj = {
        uniqid: this.dbauthuser.uniqid,
        docid: this.aPIDjobID[this.panelno], // docid = same as id from the backend processing, need to ensure unique.
        formdata: formdata,
        ifHUBType: this.localpanelstate.ifHUBType,
      }
      store.dispatch(
        'dbdocmeta/saveCollaborationDocMeta',
        saveCollaboriationObj
      )
      this.showCollaborationDialog = false
    },
    saveNegotiation(formdata) {
      var saveNegotiationObj = {
        uniqid: this.dbauthuser.uniqid,
        docid: this.aPIDjobID[this.panelno], // docid = same as id from the backend processing, need to ensure unique.
        formdata: formdata,
        ifHUBType: this.localpanelstate.ifHUBType,
      }
      store.dispatch('dbdocmeta/saveNegotiationDocMeta', saveNegotiationObj)
      this.showNegotiationDialog = false
    },

    addDocNote(note) {
      const noteObj = {
        docid: this.aPIDjobID[this.panelno],
        uniqid: this.dbauthuser.uniqid,
        note: note,
        ifHUBType: this.localpanelstate.ifHUBType,
      }
      store.dispatch('dbdocmeta/addNoteDocMeta', noteObj)
    },

    movetoArchiveDocument(formdata) {
      store.dispatch('dbdocmeta/saveAsArchiveDocMeta', {
        uniqid: this.dbauthuser.uniqid,
        docid: this.aPIDjobID[this.panelno],
        archivenotes: formdata.archivenotes,
        executedcontract: formdata.executedcontract,
        executeddate: formdata.executeddate,
        startdate: formdata.startdate,
        enddate: formdata.enddate,
        clausetopics: formdata.clausetopics,
        excludecollaborators: formdata.excludecollaborators,
        excludenegotiators: formdata.excludenegotiators,
      })

      this.closeDocument()

      // Need to refresh doc states
      const MyThis = this
      // also have to reset the avail docs for Playbooks and Group Docs.
      setTimeout(function () {
        MyThis.$store.dispatch('dbauth/getUserAvailDocsAuth')
        MyThis.$store.dispatch('dbauth/getUserAvailGroupDocsAuth')
        MyThis.$store.dispatch('dbauth/getUserAvailHubDocsAuth')
        MyThis.$store.dispatch('dbauth/getUserAvailArchiveDocsAuth')
      }, 500)
    },

    // saves a new document and writes the metadata.
    saveDocument(formdata, newdocid = null) {
      // Cannot save if Collaborator or Negotiator, only owner.
      if (!this.localpanelstate.ownerPriv && this.localpanelstate.ifSaved) {
        SnackService.info('MESSAGES.NOPRIVILEGSTOCHANGEDOCSETTINGS')
        return
      }

      const docidused =
        newdocid === null ? this.aPIDjobID[this.panelno] : newdocid

      var writeNewDocumentObj = {
        // commoon across document and docmeta
        docid: docidused, // docid = same as id from the backend processing, need to ensure unique.
        doctypeid: this.docTypeId[this.panelno],

        // state from processed document.
        docRawData: this.docRawData[this.panelno],
      }

      // This updates the existing document object with the new data
      store.dispatch('dbdocument/writeNewDocument', writeNewDocumentObj)

      // also create new docmeta file
      var writeNewDocMetaObj = {
        docid: docidused, // docid = same as id from the backend processing, need to ensure unique.
        doctypeid: this.docTypeId[this.panelno],
        ownerid: this.dbauthuser.uniqid,
        // info from the form.
        formdata: formdata,
      }
      store.dispatch('dbdocmeta/writeNewDocMeta', writeNewDocMetaObj)

      // write new log for doc.
      store.dispatch('dblogdoc/writeNewLogDoc', {
        uniqid: this.dbauthuser.uniqid,
        docid: docidused,
      })
      this.showSaveDocumentDialog = false
      // for new documents, default edit to true when docs are first processed (not necessary)
      this.editable = false
    },

    updatedocsettings(formdata) {
      // also create new docmeta file
      var writeNewDocMetaObj = {
        docid: this.aPIDjobID[this.panelno],
        doctypeid: this.docTypeId[this.panelno],
        uniqid: this.dbauthuser.uniqid,
        // info from the form.
        formdata: formdata,
      }
      store.dispatch('dbdocmeta/updateDocMeta', writeNewDocMetaObj)
    },

    updateArchiveDocument(formdata) {
      // also create new docmeta file
      var writeNewDocMetaObj = {
        docid: this.aPIDjobID[this.panelno],
        doctypeid: this.docTypeId[this.panelno],
        uniqid: this.dbauthuser.uniqid,
        // info from the form.
        formdata: formdata,
      }
      store.dispatch('dbdocmeta/updateArchiveDocMeta', writeNewDocMetaObj)
    },

    get4digitRandomString() {
      return Math.floor(1000 + Math.random() * 9000).toString()
    },

    // saves a new document and writes the metadata.
    copyDocument(formdata) {
      // create a new ID for this new document and write a simple document file
      store
        .dispatch('dbdocument/writeNewSyncDocument', {})
        .then((doc) => this.copyDoc(formdata, doc.id))
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })

      this.showCopyDocumentDialog = false

      // log - increment processed documents for user
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: this.dbauthuser.uniqid,
        logdetail: {
          action: 'copy document',
          detail: formdata.docname,
          context: '',
          // increment
          docsproc: 1,
          docsprocmonth: 1,
        },
      })
    },

    copyDoc(formdata, id) {
      // preserve state and pass to functions.
      let This = this

      // Need to add a copyDocument of the source document to a new location

      This.copyDocA(This, id)
        .then(function (copyResult) {
          return This.copyDocB(This, formdata, id, copyResult)
        })
        .then(function (resultB) {
          return This.copyDocC(This, id)
        })
        .then(function (resultC) {
          This.$store.dispatch('dblogdoc/addLogDoc', {
            docid: id,
            uniqid: This.dbauthuser.uniqid,
            logdetail: {
              action: 'DOCEVENTS.COPIEDDOCUMENT',
              detail: This.docname,
              context: This.aPIDjobID[This.panelno],
            },
          })
          SnackService.info('MESSAGES.DOCUMENTSUCCESSCOPIED')
        })
      // Does not work, generates errors
      // setTimeout(this.openDocument(id), 6000)
    },

    copyDocA: function (This, id) {
      return new Promise(function (resolve, reject) {
        store
          .dispatch('dbdocument/getDocument', {
            docid: This.aPIDjobID[This.panelno],
          })
          .then((doc) =>
            This.copyFileCloud(
              doc.data.urlname,
              This.createFileName({
                name: doc.data.filename,
              })
            ).then(function (copyResult) {
              resolve(copyResult)
            })
          )
      })
    },

    copyDocB: function (This, formdata, id, copyResult) {
      return new Promise(function (resolve, reject) {
        store
          .dispatch('dbdocument/getDocument', {
            docid: This.aPIDjobID[This.panelno],
          })
          .then((doc) =>
            store.dispatch('dbdocument/writeToDocSyncDocument', {
              id: id,
              data: {
                ...doc.data,
                ...{
                  copied: This.aPIDjobID[This.panelno],
                  docid: id,
                  // new filename and url informaiton is needed.
                  downloadURL: copyResult.downloadURL,
                  urlname: copyResult.filelocation,
                  owner: This.dbauthuser.uniqid,
                },
              },
            })
          )
          .catch(function (e) {
            store.dispatch('dberror/logError', e)
          })

        store
          .dispatch('dbdocmeta/getDocMeta', {
            docid: This.aPIDjobID[This.panelno],
          })
          .then((doc) =>
            store.dispatch('dbdocmeta/writeToDocSyncDocMeta', {
              id: id,
              data: {
                ...doc.data,
                ...{
                  copied: This.aPIDjobID[This.panelno],
                  docid: id,
                  docname: formdata.docname,
                  docdescription: formdata.docdescription,
                  docparty: formdata.docparty,
                  doccounterparty: formdata.doccounterparty,
                  docallowcollclauselabels: formdata.docallowcollclauselabels,
                  docenableNegotiation: formdata.docenableNegotiation,
                  docnotes: formdata.keepdocnotes ? This.docnotes : [],

                  // restore defaults - below copied from dbdocmeta
                  // null values for each of below.
                  notificationrules: [], // notification - options for perforning notifications based on doctype.
                  collabstatus: 'CLOSED', // OPEN, CLOSED -
                  collabend: '', // end date for collaboration.
                  collabrules: [], // options for collaboration - allow clause labels, etc.  triggers workflow
                  externalcollaborators: [], // list of external collaborators.

                  negotiationstatus: 'CLOSED', // OPEN or CLOSED
                  negotiationend: '', // end date for negotiation.
                  negotiationrules: [], // rules for who is able to negotiate - triggers workflow
                  externalnegotiators: [], // list of external collaborator emails
                  ownerid: [This.dbauthuser.uniqid], // correcting ownerid assignment bug
                  // signing default data
                  sigrequestactive: false,
                  sigrequestid: '',
                  sigrequestuniqid: '',

                  templateid: [], // list of templates that can be applied to this document.  Can assign from a list.
                  doccategory: 'STANDARD',
                  create: new Date(),
                  delete: '',
                  active: true,
                },
              },
            })
          )
          //     .then(
          //       store.dispatch('dbdocmeta/updateDocSyncDocMeta', {
          //         id: id,
          //         update: {
          //           copied: This.aPIDjobID[This.panelno],
          //           docid: id,
          //           docname: formdata.docname,
          //           docdescription: formdata.docdescription,
          //           docparty: formdata.docparty,
          //           doccounterparty: formdata.doccounterparty,
          //           docallowcollclauselabels: formdata.docallowcollclauselabels,
          //           docenableNegotiation: formdata.docenableNegotiation,
          //           docnotes: formdata.keepdocnotes ? This.docnotes : [],

          //           // restore defaults - below copied from dbdocmeta
          //           // null values for each of below.
          //           notificationrules: [], // notification - options for perforning notifications based on doctype.
          //           collabstatus: 'CLOSED', // OPEN, CLOSED -
          //           collabend: '', // end date for collaboration.
          //           collabrules: [], // options for collaboration - allow clause labels, etc.  triggers workflow
          //           externalcollaborators: [], // list of external collaborators.

          //           negotiationstatus: 'CLOSED', // OPEN or CLOSED
          //           negotiationend: '', // end date for negotiation.
          //           negotiationrules: [], // rules for who is able to negotiate - triggers workflow
          //           externalnegotiators: [], // list of external collaborator emails
          //           ownerid: [This.dbauthuser.uniqid], // correcting ownerid assignment bug
          //           // signing default data
          //           sigrequestactive: false,
          //           sigrequestid: '',
          //           sigrequestuniqid: '',

          //           templateid: [], // list of templates that can be applied to this document.  Can assign from a list.
          //           doccategory: 'STANDARD',
          //           create: new Date(),
          //           delete: '',
          //           active: true,
          //         },
          //       })
          //     )
          // )
          .catch(function (e) {
            store.dispatch('dberror/logError', e)
          })

        // write new log for doc.
        This.$store.dispatch('dblogdoc/writeNewLogDoc', {
          uniqid: This.dbauthuser.uniqid,
          docid: id,
        })

        resolve('copyA finished')
      })
    },
    copyDocC: function (This, id) {
      return new Promise(function (resolve, reject) {
        const sourceNameBase = 'docdata/' + This.aPIDjobID[This.panelno]
        const targetNameBase = 'docdata/' + id

        // copy
        This.copyFileCloud(sourceNameBase + '-O', targetNameBase + '-O').then(
          function (copyResult) {
            resolve(copyResult)
          }
        )

        if (Object.keys(This.doctranslation).length !== 0) {
          This.copyFileCloud(sourceNameBase + '-T', targetNameBase + '-T')
        }

        // resolve('copyC finished')
      })
    },

    // create a fresh version of the document implementing changes
    createVersion(args) {
      // pass into functions.
      let This = this

      // create a new ID for this new document and write a simple document file
      store
        .dispatch('dbdocument/writeNewSyncDocument', {})
        .then((doc) => This.createVers(This, args, doc.id))
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })

      this.showCreateVersionDialog = false

      // log - increment processed documents for user
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: This.dbauthuser.uniqid,
        logdetail: {
          action: args.completionaction,
          detail: args.formdata.docname,
          context: '',
          // increment
          docsproc: 1,
          docsprocmonth: 1,
        },
      })
    },

    // copies file from to on google storage
    copyFileCloud(from, to) {
      return STORcopyFromToFile(from, to)
    },

    createVers(This, args, id) {
      // preserve state and pass to functions.

      // get new id
      //
      // createVersA -  save created final doc as source document
      // createVersB - copy document to new document (preserves the clauseid)
      // copy new docmeta to new docmeta (uses the formdata)
      // createVersC - create new text document (merging changes)
      //   write the new text document to OT
      //   if translation, substitute (not available)

      This.createVersA(This, args, id)
        .then(function (rawfileresult) {
          return This.createVersB(This, args, id, rawfileresult)
        })
        .then(function (resultB) {
          return This.createVersC(This, args, id)
        })
        .then(function (resultC) {
          This.$store.dispatch('dblogdoc/addLogDoc', {
            docid: id,
            uniqid: This.dbauthuser.uniqid,
            logdetail: {
              // changed below for create clean version and create doc template
              action: args.completionaction,
              detail: This.docname,
              context: This.aPIDjobID[This.panelno],
            },
          })
          // changed below for create clean version and create doc template
          SnackService.info(args.completionmessage)
        })
    },

    // Works
    // need to create a final document and store as the new source document and get the url
    createVersA: function (This, args, id) {
      return new Promise(function (resolve, reject) {
        // catch any strange characters and white space issues.
        const rawfilename =
          args.formdata.docrawfilename
            .trim()
            .replace('.docx', '')
            .replace(' ', '') + '.docx'

        const rawfileStorageLocation = This.createFileName({
          name: rawfilename,
        })

        // Change this to return the downloadUrl as well?
        This.copyFileCloud(args.filelocation, rawfileStorageLocation).then(
          function (copyResult) {
            resolve({
              rawfilename: rawfilename,
              rawfileStorageLocation: rawfileStorageLocation,
              downloadURL: copyResult.downloadURL,
            })
          }
        )
      })
    },

    // Works
    // Copies the documents and docmeta to new id - works.
    createVersB: function (This, args, id, rawfileresult) {
      return new Promise(function (resolve, reject) {
        const formdata = args.formdata // contains formdata settings

        store
          .dispatch('dbdocument/getDocument', {
            docid: This.aPIDjobID[This.panelno],
          })
          .then((doc) =>
            store.dispatch('dbdocument/writeToDocSyncDocument', {
              id: id,
              data: {
                ...doc.data,
                ...{
                  copied: This.aPIDjobID[This.panelno],
                  versioned: true, // add versioned flag/
                  docid: id,

                  // new filename and url informaiton is needed.
                  downloadURL: rawfileresult.downloadURL,
                  filename: rawfileresult.rawfilename,
                  urlname: rawfileresult.rawfileStorageLocation,
                  owner: This.dbauthuser.uniqid,
                },
              },
            })
          )
          .catch(function (e) {
            store.dispatch('dberror/logError', e)
          })

        store
          .dispatch('dbdocmeta/getDocMeta', {
            docid: This.aPIDjobID[This.panelno],
          })
          .then((doc) =>
            store.dispatch('dbdocmeta/writeToDocSyncDocMeta', {
              id: id,
              data: {
                ...doc.data,
                ...{
                  copied: This.aPIDjobID[This.panelno],
                  versioned: true, // add versioned flag/
                  docid: id,
                  docname: formdata.docname,
                  docdescription: formdata.docdescription,
                  docparty: formdata.docparty,
                  doccounterparty: formdata.doccounterparty,
                  docallowcollclauselabels: formdata.docallowcollclauselabels,
                  docenableNegotiation: formdata.docenableNegotiation,
                  docnotes: formdata.keepdocnotes ? This.docnotes : [],

                  // restore defaults - below copied from dbdocmeta
                  // null values for each of below.
                  notificationrules: [], // notification - options for perforning notifications based on doctype.
                  collabstatus: 'CLOSED', // OPEN, CLOSED -
                  collabend: '', // end date for collaboration.
                  collabrules: [], // options for collaboration - allow clause labels, etc.  triggers workflow
                  externalcollaborators: [], // list of external collaborators.

                  negotiationstatus: 'CLOSED', // OPEN or CLOSED
                  negotiationend: '', // end date for negotiation.
                  negotiationrules: [], // rules for who is able to negotiate - triggers workflow
                  externalnegotiators: [], // list of external collaborator emails
                  ownerid: [This.dbauthuser.uniqid], // correcting ownerid assignment bug

                  // signing default data
                  sigrequestactive: false,
                  sigrequestid: '',
                  sigrequestuniqid: '',

                  templateid: [], // list of templates that can be applied to this document.  Can assign from a list.
                  labelinputdata: args.labelinputdata,
                  templateorigid: args.templateorigid,
                  templateorigdocname: args.templateorigdocname,

                  doccategory: 'STANDARD',
                  create: new Date(),
                  delete: '',
                  active: true,
                },
              },
            })
          )
          // .then(
          //   store.dispatch('dbdocmeta/updateDocSyncDocMeta', {
          //     id: id,
          //     update: {
          //       copied: This.aPIDjobID[This.panelno],
          //       versioned: true, // add versioned flag/
          //       docid: id,
          //       docname: formdata.docname,
          //       docdescription: formdata.docdescription,
          //       docparty: formdata.docparty,
          //       doccounterparty: formdata.doccounterparty,
          //       docallowcollclauselabels: formdata.docallowcollclauselabels,
          //       docenableNegotiation: formdata.docenableNegotiation,
          //       docnotes: formdata.keepdocnotes ? This.docnotes : [],

          //       // restore defaults - below copied from dbdocmeta
          //       // null values for each of below.
          //       notificationrules: [], // notification - options for perforning notifications based on doctype.
          //       collabstatus: 'CLOSED', // OPEN, CLOSED -
          //       collabend: '', // end date for collaboration.
          //       collabrules: [], // options for collaboration - allow clause labels, etc.  triggers workflow
          //       externalcollaborators: [], // list of external collaborators.

          //       negotiationstatus: 'CLOSED', // OPEN or CLOSED
          //       negotiationend: '', // end date for negotiation.
          //       negotiationrules: [], // rules for who is able to negotiate - triggers workflow
          //       externalnegotiators: [], // list of external collaborator emails
          //       ownerid: [This.dbauthuser.uniqid], // correcting ownerid assignment bug

          //       // signing default data
          //       sigrequestactive: false,
          //       sigrequestid: '',
          //       sigrequestuniqid: '',

          //       templateid: [], // list of templates that can be applied to this document.  Can assign from a list.
          //       labelinputdata: args.labelinputdata,
          //       templateorigid: args.templateorigid,
          //       templateorigdocname: args.templateorigdocname,

          //       doccategory: 'STANDARD',
          //       create: new Date(),
          //       delete: '',
          //       active: true,
          //     },
          //   })
          // )
          // )
          .catch(function (e) {
            store.dispatch('dberror/logError', e)
          })

        // write new log for doc.
        This.$store.dispatch('dblogdoc/writeNewLogDoc', {
          uniqid: This.dbauthuser.uniqid,
          docid: id,
        })

        resolve('createVersB finished')
      })
    },

    // copies the originat text and translated text over to the new id.
    // however need to modify to edit the translated text.
    // Need to JSON.stringify in javascript to process 2 times and then write to a disk.
    //
    // and make changes to the original text.
    createVersC: function (This, args, id) {
      return new Promise(function (resolve, reject) {
        const neworiginaltext = args.neworiginaltext
        const newdoctranslation = args.newdoctranslation

        // id to put the data
        const targetNameBase = 'docdata/' + id

        // convert object to JSON twice for right format.
        const neworiginaltextjj = JSON.stringify(
          JSON.stringify(neworiginaltext)
        )
        const neworiginaltextjjblob = new Blob([neworiginaltextjj], {
          type: 'application/json',
        })
        const neworiginaltextjjfile = new File(
          [neworiginaltextjjblob],
          'foo.txt',
          { type: 'application/json' }
        )

        STORwriteFile(targetNameBase + '-O', neworiginaltextjjfile).then(
          function (downloadURL) {
            resolve('createVersB finished')
          }
        )

        // translation file has items - write at leisure
        if (Object.keys(newdoctranslation).length !== 0) {
          // const fileRefTargetT = storageRef.child(targetNameBase + '-T')
          const newdoctranslationjj = JSON.stringify(
            JSON.stringify(newdoctranslation)
          )
          const newdoctranslationjjblob = new Blob([newdoctranslationjj], {
            type: 'text/plain',
          })
          const newdoctranslationjjfile = new File(
            [newdoctranslationjjblob],
            'foo.txt',
            { type: 'text/plain' }
          )

          STORwriteFile(targetNameBase + '-T', newdoctranslationjjfile)
        }
      })
    },

    // Compare Document
    compareDocument(docid) {
      this.showCompareDocDialog = false
      this.panelSync = false // shut off panel sync if turned on.  Can't sync when comparing
      this.lcompareOn = true
      this.lcompoptnonsequential = false
      this.lcomparedocid = docid

      const MyThis = this

      STORgetJSONFile('docdata/' + docid + '-O').then(function (JSONobj) {
        MyThis.comparetext = JSONobj
      })

      store
        .dispatch('dbdocmeta/getDocMeta', { docid: docid })
        .then((doc) => (MyThis.lcomparedocname = doc.data.docname))
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    },

    // Integraton
    setIntegrationInfo(action) {
      // this.panelSync = false // shut off panel sync if turned on.  Can't sync when comparing
      // this.lintegrationOn = true

      if (action == this.dbintegrationInteg1.integname){
        this.lintegname=this.dbintegrationInteg1.integname
        this.lintegtype=this.dbintegrationInteg1.integtype
        this.lintegcreds=this.dbintegrationInteg1.integcreds
        this.lintegparas=this.dbintegrationInteg1.integparas
        this.lintegspecs=this.dbintegrationInteg1.integspecs
      } else if (action == this.dbintegrationInteg2.integname){
        this.lintegname=this.dbintegrationInteg2.integname
        this.lintegtype=this.dbintegrationInteg2.integtype
        this.lintegcreds=this.dbintegrationInteg2.integcreds
        this.lintegparas=this.dbintegrationInteg2.integparas
        this.lintegspecs=this.dbintegrationInteg2.integspecs
      } else if (action == this.dbintegrationInteg3.integname){
        this.lintegname=this.dbintegrationInteg3.integname
        this.lintegtype=this.dbintegrationInteg3.integtype
        this.lintegcreds=this.dbintegrationInteg3.integcreds
        this.lintegparas=this.dbintegrationInteg3.integparas
        this.lintegspecs=this.dbintegrationInteg3.integspecs
      } else if (action == this.dbintegrationInteg4.integname){
        this.lintegname=this.dbintegrationInteg4.integname
        this.lintegtype=this.dbintegrationInteg4.integtype
        this.lintegcreds=this.dbintegrationInteg4.integcreds
        this.lintegparas=this.dbintegrationInteg4.integparas
        this.lintegspecs=this.dbintegrationInteg4.integspecs
      } else {
        this.lintegname='error'
      }

      const MyThis = this

      // Need to do integration info to get data
      var integInfo = {
        integname: this.lintegname,
        integtype: this.lintegtype,
        integcreds: this.lintegcreds,
        integparas: this.lintegparas,
        integspecs: this.lintegspecs,
        paratype: 'standard',
        paratext: this.lparacontext,
        paratopic: this.filterTopic.action
      }

      // Only do it if is not empty.  If empty, then make the return data empty.
      if (this.lparacontext !== ''){
        // perform integration call and then return results
        DocumentProcessingService.INTEGreturnlist(integInfo).then(function (
              returnobj
            ) {
              MyThis.lintegdata=returnobj.data
            })
      } else {
        this.lintegdata={}
      }

    },

    stopCompareDocument() {
      this.lcompareOn = false
      this.lcomparedocid = ''
      this.lcomparedocname = ''
      this.lcompoptnonsequential = false
      this.comparetext = {}
    },

    stopIntegrationState(){
      this.lparacontext= ''
      this.lintegrationOn = false
      this.lintegname= ''
      this.lintegtype= ''
      this.lintegcreds= ''
      this.lintegparas= ''
      this.lintegspecs= ''
      this.lintegdata= {}
    },

    integrationRefresh(paracontext, integname){
      this.lrefreshIntegration = true
      store.dispatch('syncview/setParaContext',paracontext)
      // .then(MyThis.setIntegrationInfo(MyThis.integname))
    },



    compareGotoPara(val) {
      // sends message to other panels to get to para.
      store.dispatch('syncview/setDocCompareIDpara', val)
    },

    compareSetOptions(obj) {
      this.lcompoptnonsequential = obj.compoptnonsequential
    },

    // saveTemplate
    // saves a new document and writes the metadata.
    saveTemplate(formdata) {
      store.dispatch('dbdocmeta/saveAsTemplateDocMeta', {
        uniqid: this.dbauthuser.uniqid,
        docid: this.aPIDjobID[this.panelno],
        formdata: formdata,
      })
      this.closeDocument()

      // indicate to the user that message is completed.
      SnackService.info('MESSAGES.DOCUMENTCONVERTEDTOTEMPLATE')

      // log - increment processed documents for user
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: this.dbauthuser.uniqid,
        logdetail: {
          action: 'save template',
          detail: formdata.docname,
          context: '',
          // increment
          docsproc: 1,
          docsprocmonth: 1,
        },
      })
    },

    // Determine if source document is a docx document.
    determineSourceDocument() {
      const MyThis = this

      if (MyThis.localpanelstate.docmeta !== undefined) {
        store
          .dispatch('dbdocument/getDocument', {
            docid: MyThis.localpanelstate.docmeta.docid,
          })
          .then(function (doc) {
            MyThis.eligibledocxin = doc.data.urlname.endsWith('.docx')
          })
      }
    },

    getOriginalText(docid) {
      const MyThis = this

      STORgetJSONFile('docdata/' + docid + '-O').then(function (JSONobj) {
        MyThis.originaltext = JSONobj
      })
    },

    getTranslation() {
      const MyThis = this

      STORgetJSONFile('docdata/' + this.aPIDjobID[this.panelno] + '-T').then(
        function (JSONobj) {
          MyThis.doctranslation = JSONobj
        }
      )
    },

    saveAsPlaybook() {
      store.dispatch('dbdocmeta/saveAsPlaybookDocMeta', {
        uniqid: this.dbauthuser.uniqid,
        docid: this.aPIDjobID[this.panelno],
      })
      this.closeDocument()

      const MyThis = this
      // also have to reset the avail docs for Playbooks and Group Docs.
      setTimeout(function () {
        MyThis.$store.dispatch('dbauth/getUserAvailGroupDocsAuth')
        MyThis.$store.dispatch('dbauth/getUserAvailHubDocsAuth')
        MyThis.$store.dispatch('dbauth/getUserAvailPlaybooksAuth')
        MyThis.$store.dispatch('dbauth/getUserAvailTemplatesAuth')
      }, 500)
    },

    // methods for performing topic syncing between panels
    pushTopic(cat) {
      store.dispatch('syncview/setFilterTopic', cat)
    },
    // This is triggered as soon as a topic is selected.
    processTopic(cat) {
      this.panelFilterTopic = cat
      this.effectiveTopic = cat
      store.dispatch('syncview/setFilterTopic', cat)

      // when changing topics to ALL, set the compressed setting based on the returned values.
      this.pcompressed = cat.action === 'ALL'
      this.editsonlyview = false
    },
    processSync(sync) {
      this.panelSync = sync
      // if turn on locally, but global is not on, turn it on.
      if (!this.filterTopicView && this.panelSync) {
        store.dispatch('syncview/setFilterTopicView', true)
      }
    },
    processTopicSelectivity(selectivity) {
      this.selectivityTopic = parseInt(selectivity)
    },

    // triggered when paragraph number is triggered.
    processnavall(index) {
      this.processTopic({ action: 'ALL' })
      // sets paragraph highlight
      this.gotoPara(index)
    },

    gotoPara(index) {
      this.pcompressed = false
      this.editsonlyview = false
      this.panelFilterTopic = { action: 'ALL' }
      this.effectiveTopic = { action: 'ALL' }

      this.setParaHighlightTimeout(this.panelno + '-' + 'clause-' + index, 500)

      this.showGotoParagraphDialog = false
    },

    // function to set where to scroll to to highlight the right object for each of the panels.
    // Added timeout and it seems to work nicely.
    setParaHighlightTimeout(entity, timeout) {
      const element = document.getElementById(entity)
      if (element) {
        setTimeout(function () {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }, timeout)
      }
    },

    processAllTrigger() {
      const processTriggerAlertEx = {
        docid: this.aPIDjobID[this.panelno],
        doctypeid: this.localpanelstate.docTypeDetailofDoc.keyid,
        alertid: 'ALL',
        groupid: '',
        alert: 'ALL',
        uniqid: this.dbauthuser.uniqid,
      }
      SnackService.info('MESSAGES.TRIGGEREDALLALERTS')
      store
        .dispatch('dbalert/processTriggerAlert', processTriggerAlertEx)
        .then(function (returnobj) {
          DocumentProcessingService.BATCHalertemailgenbatch({}).then(function (
            returnobj
          ) {
            DocumentProcessingService.BATCHalertemailsendbatch({})
          })
        })
    },

    // trigger processing
    processTrigger(obj) {
      const processTriggerAlertEx = {
        docid: this.aPIDjobID[this.panelno],
        doctypeid: this.localpanelstate.docTypeDetailofDoc.keyid,
        alertid: obj.keyid,
        groupid: obj.groupid,
        alert: obj.alert,
        uniqid: this.dbauthuser.uniqid,
      }
      store
        .dispatch('dbalert/processTriggerAlert', processTriggerAlertEx)
        .then(function (obj) {
          DocumentProcessingService.BATCHalertemailgenbatch({}).then(function (
            returnobj
          ) {
            DocumentProcessingService.BATCHalertemailsendbatch({})
          })
        })
    },

    // relays to utility functions
    chNO(d) {
      return chNO(d)
    },
    chNObool(d) {
      return chNObool(d)
    },

    resetPanelState() {
      this.editable = false
      this.pcompressed = true
      this.penglishtranslate = false
      this.editsonlyview = false
      this.showcollaborativeedits = true
      this.shownegotiationedits = false

      // reset the progress circular.
      this.progresscircular = 0

      // reset the text to be nothing
      this.originaltext = {}
      this.doctranslation = {}

      // reset this panel filters
      this.panelFilterTopic = { action: 'ALL' }
      this.effectiveTopic = { action: 'ALL' }
      this.selectivityTopic = '0'
    },

    // Signing Routines
    signNewRequest(args) {
      store.dispatch('signesignature/signNewRequest', args)
    },
    signCancelRequest(args) {
      store.dispatch('signesignature/signCancelRequest', args)
    },

    // Docx Modifications Requests
    docxCreateRedlineAct(args) {
      const filename = store.dispatch('docxmodservice/docxCreateRedline', args)
      filename.then(function (filename) {
        genRedline(filename, filename.substr(filename.lastIndexOf(':') + 1))
      })
    },
    docxCreateFinalAct(args) {
      const filename = store.dispatch('docxmodservice/docxCreateFinal', args)
      filename.then(function (filename) {
        genRedline(filename, filename.substr(filename.lastIndexOf(':') + 1))
      })
    },

    // navigation from Panel-Nav
    processNav(navitem) {
      switch (navitem.action) {
        // FILE MENU
        case 'divider':
          // do nothing - this is the dividers
          break
        case 'NewDoc':
          // initialize progresson to false to reset -
          this.progresson = false

          // need to check for limits.  If exceeded, pop up message and break
          if (this.dbauthPrivUsage === null || this.dbauthPrivileges === null) {
            SnackService.info('MESSAGES.PRIVILEGSNOTAVAIL')
            break
          }

          // if trial user, docsproc > documentcap, throw error.
          if (
            this.dbauthPrivileges.type < 'C' &&
            this.dbauthPrivUsage.docsproc > this.dbauthPrivileges.documentcap
          ) {
            SnackService.info('MESSAGES.EXHAUSTEDTRIALLICENSE')
            break
          }

          // if subscription user, docsprocmonth > documentcap, throw error.
          if (
            this.dbauthPrivileges.type > 'B' &&
            this.dbauthPrivUsage.docsprocmonth >
              this.dbauthPrivileges.documentcap
          ) {
            SnackService.info('MESSAGES.EXHAUSTEDMONTHLYLIMIT')
            break
          }
          if (this.showFileUpload) {
            clearInterval(this.listenerMongodoccompleted)
          }

          this.showFileUpload = !this.showFileUpload
          if (this.localpanelstate.ifDocLoaded) {
            this.closeDocument()
          }
          break
        case 'SaveNewDoc':
          this.showSaveDocumentDialog = true
          break
        case 'CopyDoc':
          if (this.dbauthPrivUsage === null || this.dbauthPrivileges === null) {
            SnackService.info('MESSAGES.PRIVILEGSNOTAVAIL')
            break
          }

          // if trial user, docsproc > documentcap, throw error.
          if (
            this.dbauthPrivileges.type < 'C' &&
            this.dbauthPrivUsage.docsproc > this.dbauthPrivileges.documentcap
          ) {
            SnackService.info('MESSAGES.EXHAUSTEDTRIALLICENSE')
            break
          }

          // if subscription user, docsprocmonth > documentcap, throw error.
          if (
            this.dbauthPrivileges.type > 'B' &&
            this.dbauthPrivUsage.docsprocmonth >
              this.dbauthPrivileges.documentcap
          ) {
            SnackService.info('MESSAGES.EXHAUSTEDMONTHLYLIMIT')
            break
          }

          this.showCopyDocumentDialog = true
          break
        case 'CreateVersion':
          // copied from copy document, same restrictions
          if (this.dbauthPrivUsage === null || this.dbauthPrivileges === null) {
            SnackService.info('MESSAGES.PRIVILEGSNOTAVAIL')
            break
          }

          // if trial user, docsproc > documentcap, throw error.
          if (
            this.dbauthPrivileges.type < 'C' &&
            this.dbauthPrivUsage.docsproc > this.dbauthPrivileges.documentcap
          ) {
            SnackService.info('MESSAGES.EXHAUSTEDTRIALLICENSE')
            break
          }

          // if subscription user, docsprocmonth > documentcap, throw error.
          if (
            this.dbauthPrivileges.type > 'B' &&
            this.dbauthPrivUsage.docsprocmonth >
              this.dbauthPrivileges.documentcap
          ) {
            SnackService.info('MESSAGES.EXHAUSTEDMONTHLYLIMIT')
            break
          }

          // if doctranslation available, load it if not loaded yet.
          if (
            this.englishavail &&
            Object.keys(this.doctranslation).length === 0
          ) {
            this.getTranslation()
          }

          this.showCreateVersionDialog = true
          break

        case 'CreateDocTemplate':
          // copied from copy document, same restrictions
          if (this.dbauthPrivUsage === null || this.dbauthPrivileges === null) {
            SnackService.info('MESSAGES.PRIVILEGSNOTAVAIL')
            break
          }

          // if trial user, docsproc > documentcap, throw error.
          if (
            this.dbauthPrivileges.type < 'C' &&
            this.dbauthPrivUsage.docsproc > this.dbauthPrivileges.documentcap
          ) {
            SnackService.info('MESSAGES.EXHAUSTEDTRIALLICENSE')
            break
          }

          // if subscription user, docsprocmonth > documentcap, throw error.
          if (
            this.dbauthPrivileges.type > 'B' &&
            this.dbauthPrivUsage.docsprocmonth >
              this.dbauthPrivileges.documentcap
          ) {
            SnackService.info('MESSAGES.EXHAUSTEDMONTHLYLIMIT')
            break
          }

          // if doctranslation available, load it if not loaded yet.
          if (
            this.englishavail &&
            Object.keys(this.doctranslation).length === 0
          ) {
            this.getTranslation()
          }

          this.showCreateDocTemplateDialog = true
          break

        case 'OpenDoc':
          if (this.dbauthAvailDocs.length === 0) {
            store.dispatch('dbauth/getUserAvailDocsAuth') // get a list of docs that are accessible to the user.
          }
          // if already an open document, close it.
          if (this.localpanelstate.ifDocLoaded) {
            this.closeDocument()
          }
          this.showOpenDocumentDialog = true
          break
        case 'OpenGroupDoc':
          if (this.dbauthAvailGroupDocs.length === 0) {
            store.dispatch('dbauth/getUserAvailGroupDocsAuth') // get a list of docs that are accessible to the user.
          }
          // if already an open document, close it.
          if (this.localpanelstate.ifDocLoaded) {
            this.closeDocument()
          }
          this.showOpenGroupDocumentDialog = true
          break
        case 'OpenHubDoc':
          if (this.dbauthAvailGroupDocs.length === 0) {
            store.dispatch('dbauth/getUserAvailHubDocsAuth') // get a list of docs that are accessible to the user.
          }
          // if already an open document, close it.
          if (this.localpanelstate.ifDocLoaded) {
            this.closeDocument()
          }
          this.showOpenHubDocumentDialog = true
          break
        case 'OpenArchiveDoc':
          if (this.dbauthAvailArchiveDocs.length === 0) {
            store.dispatch('dbauth/getUserAvailArchiveDocsAuth') // get a list of docs that are accessible to the user.
          }
          // if already an open document, close it.
          if (this.localpanelstate.ifDocLoaded) {
            this.closeDocument()
          }
          this.showOpenArchiveDocumentDialog = true
          break
        case 'OpenPlaybook':
          if (this.dbauthAvailPlaybooks.length === 0) {
            store.dispatch('dbauth/getUserAvailPlaybooksAuth') // get a list of playsbooks that are accessible to the user.
          }
          // if already an open document, close it.
          if (this.localpanelstate.ifDocLoaded) {
            this.closeDocument()
          }
          this.showOpenPlaybookDialog = true
          break
        case 'OpenTemplate':
          if (this.dbauthAvailTemplates.length === 0) {
            store.dispatch('dbauth/getUserAvailTemplatesAuth') // get a list of playsbooks that are accessible to the user.
          }
          // if already an open document, close it.
          if (this.localpanelstate.ifDocLoaded) {
            this.closeDocument()
          }
          this.showOpenTemplateDialog = true
          break
        case 'CompareDoc':
          this.showCompareDocDialog = true
          break
        case 'SavePlaybook':
          this.confirmSavetoPlaybook = true
          break
        case 'SaveTemplate':
          this.showSaveTemplateDialog = true
          break
        case 'SaveArchive':
          this.showMovetoArchiveDocumentDialog = true
          break
        case 'CloseDocument':
          this.closeDocument()
          break
        case 'DocumentLog':
          store.dispatch('dblogdoc/setLogDoc', {
            uniqid: this.dbauthuser.uniqid,
            docid: this.aPIDjobID[this.panelno],
            panelno: this.panelno,
          })
          this.showDocLogDialog = true
          break
        // EDIT MENU
        case 'GotoParagraph':
          this.showGotoParagraphDialog = true
          break
        case 'ClauseTopics':
          this.editable = !this.editable
          // if turning on clause topics, make sure it's expanded mode.
          if (this.editable) {
            this.pcompressed = false
          }
          break
        case 'Expand/Collapse':
          this.pcompressed = !this.pcompressed
          break
        case 'EnglishTranslate':
          this.penglishtranslate = !this.penglishtranslate
          if (Object.keys(this.doctranslation).length === 0) {
            this.getTranslation()
          }
          break
        case 'EditsOnlyView':
          this.editsonlyview = !this.editsonlyview
          break
        case 'ShowCollaborativeEdits':
          this.showcollaborativeedits = !this.showcollaborativeedits
          break
        case 'ShowNegotiationEdits':
          this.shownegotiationedits = !this.shownegotiationedits
          break
        case 'DocumentNotes':
          this.showDocNotesDialog = true
          break
        case 'CollabComments':
          this.showCollabCommentsAllDialog = true
          break
        case 'CollabDocEdits':
          this.showCollabDocEditsAllDialog = true
          break
        case 'CollabLabels':
          this.showCollabLabelsDialog = true
          break
        case 'NegotiationComments':
          this.showNegotCommentsAllDialog = true
          break
        case 'NegotiationDocEdits':
          this.showNegotDocEditsAllDialog = true
          break
        case 'Collaboration':
          this.showCollaborationDialog = true
          break
        case 'Negotiation':
          this.showNegotiationDialog = true
          break
        case 'DownloadRedline':
          this.showDownloadRedlineDialog = true
          break
        case 'Esignature':
          this.showEsignatureDialog = true
          break
        case 'AddOwner':
          this.showAddOwnerDialog = true
          break
        case 'AddCollaborator':
          this.showAddCollaboratorDialog = true
          break
        case 'AddNegotiator':
          this.showAddNegotiatorDialog = true
          break
        case 'TriggerAlerts':
          store.dispatch(
            'dbauth/getDocTypeAlertsAuth',
            this.localpanelstate.docTypeDetailofDoc.keyid
          )
          this.showTriggerAlertsDialog = true
          break
        case 'ExportExcel':
          genExcel(
            this.localpanelstate,
            this.document,
            this.originaltext,
            this.editComments[this.panelno],
            this.editDocedits[this.panelno],
            this.editTags[this.panelno]
          )
          break
        case 'ExportWord':
          genWord(this.aPIDjobID[this.panelno])
          break
        case 'SendDoctoCLM':
          break
        case 'ExportJSON':
          downloadObjectAsJSON(this.document)
          break
        // TRIGGERED FROM INFO ICON
        case 'PanelInfo':
          this.showPanelInfoDialog = true
          break

        default:
          // integrations
          this.lintegrationOn = true
          this.processSync(true)
          this.lparacontext = ''
          this.setIntegrationInfo(navitem.action)
      }
    },
  },
}
</script>

<template>
  <div :class="$style.panelContainer">
    <header>
      <ConfirmAction
        :dialog="confirmSavetoPlaybook"
        :confirmationmessage="$t('INFO_MESSAGES.CONFIRMSAVETOPLAYBOOK')"
        @close-dialog="confirmSavetoPlaybook = false"
        @confirm="saveAsPlaybook"
      />
      <PanelInfoDialog
        :dialog="showPanelInfoDialog"
        :panelno="parseInt(panelno)"
        :panelstated="panelstated"
        :localpanelstate="localpanelstate"
        :docmetaobj="docmetaobj"
        :templateorigdocname="templateorigdocname"
        @close-dialog="showPanelInfoDialog = false"
      />
      <ParaDialogSaveDocument
        :dialog="showSaveDocumentDialog"
        :panelno="parseInt(panelno)"
        :localpanelstate="localpanelstate"
        :dbauthuser="dbauthuser"
        :docname="docname"
        :docdescription="docdescription"
        :docnotes="docnotes"
        :docparty="docparty"
        :doccounterparty="doccounterparty"
        :archivenotes="archivenotes"
        :executedcontract="executedcontract"
        :executeddate="executeddate"
        :startdate="startdate"
        :enddate="enddate"
        :clausetopics="clausetopics"
        :contractattached="contractattached"
        :contractename="contractename"
        :contractdownloadurl="contractdownloadURL"
        :docallowcollclauselabels="docallowcollclauselabels"
        :docenable-negotiation="docenableNegotiation"
        :labelinputdata="labelinputdata"
        :templateorigid="templateorigid"
        :templateorigdocname="templateorigdocname"
        @close-dialog="showSaveDocumentDialog = false"
        @save-document="saveDocument"
        @update-archivedocument="updateArchiveDocument"
        @updatedocmeta="updatedocsettings"
      />
      <ParaDialogMovetoArchiveDocument
        :dialog="showMovetoArchiveDocumentDialog"
        :panelno="parseInt(panelno)"
        :localpanelstate="localpanelstate"
        @close-dialog="showMovetoArchiveDocumentDialog = false"
        @archive-document="movetoArchiveDocument"
      />
      <ParaDialogCopyDocument
        :dialog="showCopyDocumentDialog"
        :panelno="parseInt(panelno)"
        :docname="docname"
        :docdescription="docdescription"
        :docparty="docparty"
        :doccounterparty="doccounterparty"
        :docallowcollclauselabels="docallowcollclauselabels"
        :docenable-negotiation="docenableNegotiation"
        @close-dialog="showCopyDocumentDialog = false"
        @copy-document="copyDocument"
      />
      <ParaDialogSaveTemplate
        :dialog="showSaveTemplateDialog"
        :panelno="parseInt(panelno)"
        :docname="docname"
        :docdescription="docdescription"
        :originaltext="originaltext"
        :labelinputdata="labelinputdata"
        @close-dialog="showSaveTemplateDialog = false"
        @processnavall="processnavall"
        @save-template="saveTemplate"
      />
      <ParaDialogCreateVersion
        :dialog="showCreateVersionDialog"
        :panelno="parseInt(panelno)"
        :localpanelstate="localpanelstate"
        :eligibledocxin="eligibledocxin"
        :originaltext="originaltext"
        :doctranslation="doctranslation"
        :docname="docname"
        :docdescription="docdescription"
        :docparty="docparty"
        :doccounterparty="doccounterparty"
        :docallowcollclauselabels="docallowcollclauselabels"
        :docenable-negotiation="docenableNegotiation"
        :labelinputdata="labelinputdata"
        @close-dialog="showCreateVersionDialog = false"
        @create-version="createVersion"
      />
      <ParaDialogCreateDocTemplate
        :dialog="showCreateDocTemplateDialog"
        :panelno="parseInt(panelno)"
        :localpanelstate="localpanelstate"
        :eligibledocxin="eligibledocxin"
        :originaltext="originaltext"
        :doctranslation="doctranslation"
        :docname="docname"
        :docdescription="docdescription"
        :labelinputdata="labelinputdata"
        @close-dialog="showCreateDocTemplateDialog = false"
        @create-version="createVersion"
      />
      <ParaDialogAddOwner
        :dialog="showAddOwnerDialog"
        :panelno="parseInt(panelno)"
        :ownerid="ownerid"
        :localpanelstate="localpanelstate"
        @close-dialog="showAddOwnerDialog = false"
      />
      <ParaDialogAddCollaborator
        :dialog="showAddCollaboratorDialog"
        :panelno="parseInt(panelno)"
        :externalcollaborators="externalcollaborators"
        :localpanelstate="localpanelstate"
        @close-dialog="showAddCollaboratorDialog = false"
      />
      <ParaDialogAddNegotiator
        :dialog="showAddNegotiatorDialog"
        :panelno="parseInt(panelno)"
        :externalnegotiators="externalnegotiators"
        :localpanelstate="localpanelstate"
        @close-dialog="showAddNegotiatorDialog = false"
      />
      <ParaDialogDocNotes
        :dialog="showDocNotesDialog"
        :panelno="parseInt(panelno)"
        :docnotes="docnotes"
        :localpanelstate="localpanelstate"
        @close-dialog="showDocNotesDialog = false"
        @add-docnote="addDocNote"
      />
      <ParaDialogGotoParagraph
        :dialog="showGotoParagraphDialog"
        :panelno="parseInt(panelno)"
        :avail-keys="availKeys"
        @close-dialog="showGotoParagraphDialog = false"
        @gotopara="gotoPara"
      />
      <ParaDialogTriggerAlerts
        :dialog="showTriggerAlertsDialog"
        :panelno="parseInt(panelno)"
        :localpanelstate="localpanelstate"
        @close-dialog="showTriggerAlertsDialog = false"
        @processTrigger="processTrigger"
        @processAllTrigger="processAllTrigger"
      />
      <ParaDialogCollaboration
        :dialog="showCollaborationDialog"
        :panelno="parseInt(panelno)"
        :collabstatus="collabstatus"
        :collabend="collabend"
        :collabrules="collabrules"
        :localpanelstate="localpanelstate"
        @opencollab="processAllTrigger"
        @close-dialog="showCollaborationDialog = false"
        @save-collaboration="saveCollaboration"
      />
      <ParaDialogNegotiation
        :dialog="showNegotiationDialog"
        :panelno="parseInt(panelno)"
        :negotiationstatus="negotiationstatus"
        :negotiationend="negotiationend"
        :negotiationrules="negotiationrules"
        :localpanelstate="localpanelstate"
        @close-dialog="showNegotiationDialog = false"
        @save-negotiation="saveNegotiation"
      />
      <ParaDialogDownloadRedline
        :dialog="showDownloadRedlineDialog"
        :panelno="parseInt(panelno)"
        :localpanelstate="localpanelstate"
        :eligibledocxin="eligibledocxin"
        @close-dialog="showDownloadRedlineDialog = false"
        @docx-createredline="docxCreateRedlineAct"
        @docx-createfinal="docxCreateFinalAct"
      />
      <ParaDialogEsignature
        :dialog="showEsignatureDialog"
        :panelno="parseInt(panelno)"
        :localpanelstate="localpanelstate"
        :sigrequestactive="sigrequestactive"
        :sigrequestid="sigrequestid"
        :sigrequestuniqid="sigrequestuniqid"
        :sigrequestcreate="sigrequestcreate"
        @close-dialog="showEsignatureDialog = false"
        @sign-newrequest="signNewRequest"
        @sign-cancelrequest="signCancelRequest"
      />
      <ParaDialogOpenDocument
        :dialog="showOpenDocumentDialog"
        :panelno="parseInt(panelno)"
        @close-dialog="showOpenDocumentDialog = false"
        @open-document="openDocument"
        @close-document="closeDocument"
      />
      <ParaDialogOpenGroupDocument
        :dialog="showOpenGroupDocumentDialog"
        :panelno="parseInt(panelno)"
        @close-dialog="showOpenGroupDocumentDialog = false"
        @open-document="openDocument"
        @close-document="closeDocument"
      />
      <ParaDialogOpenHubDocument
        :dialog="showOpenHubDocumentDialog"
        :panelno="parseInt(panelno)"
        @close-dialog="showOpenHubDocumentDialog = false"
        @open-hub-document="openHubDocument"
        @close-hub-document="closeHubDocument"
      />
      <ParaDialogOpenArchiveDocument
        :dialog="showOpenArchiveDocumentDialog"
        :panelno="parseInt(panelno)"
        @close-dialog="showOpenArchiveDocumentDialog = false"
        @open-document="openDocument"
        @close-document="closeDocument"
      />
      <ParaDialogOpenPlaybook
        :dialog="showOpenPlaybookDialog"
        :panelno="parseInt(panelno)"
        @close-dialog="showOpenPlaybookDialog = false"
        @open-document="openDocument"
        @close-document="closeDocument"
      />
      <ParaDialogOpenTemplate
        :dialog="showOpenTemplateDialog"
        :panelno="parseInt(panelno)"
        :labelinputdata="labelinputdata"
        @close-dialog="showOpenTemplateDialog = false"
        @open-document="openDocument"
        @close-document="closeDocument"
      />
      <ParaDialogCompareDocument
        :dialog="showCompareDocDialog"
        :panelno="parseInt(panelno)"
        @close-dialog="showCompareDocDialog = false"
        @compare-document="compareDocument"
      />
      <PanelDialogDocLog
        :dialog="showDocLogDialog"
        :panelno="parseInt(panelno)"
        :localpanelstate="localpanelstate"
        :doclogs="doclogs"
        @close-dialog="showDocLogDialog = false"
      />
      <!-- docedits -->
      <ParaDialogComments
        :dialog="showCollabCommentsDialog"
        :panelno="parseInt(panelno)"
        :actionpara="dataActionPara"
        :dialogtype="COLLABORATION"
        :localpanelstate="localpanelstate"
        @close-dialog="showCollabCommentsDialog = false"
        @synccomments="synccomments"
      />
      <ParaDialogDocEdits
        :dialog="showCollabDocEditsDialog"
        :panelno="parseInt(panelno)"
        :actionpara="dataActionPara"
        :dialogtype="COLLABORATION"
        :localpanelstate="localpanelstate"
        @close-dialog="showCollabDocEditsDialog = false"
        @syncdocedits="syncdocedits"
      />
      <ParaDialogTags
        :dialog="showTagsDialog"
        :panelno="parseInt(panelno)"
        :actionpara="dataActionPara"
        :localpanelstate="localpanelstate"
        @close-dialog="showTagsDialog = false"
        @synctags="synctags"
      />
      <ParaDialogCommentsAll
        :dialog="showCollabCommentsAllDialog"
        :panelno="parseInt(panelno)"
        :dialogtype="COLLABORATION"
        :localpanelstate="localpanelstate"
        :originaltext="originaltext"
        @close-dialog="showCollabCommentsAllDialog = false"
        @processnavall="processnavall"
      />
      <ParaDialogDocEditsAll
        :dialog="showCollabDocEditsAllDialog"
        :panelno="parseInt(panelno)"
        :dialogtype="COLLABORATION"
        :localpanelstate="localpanelstate"
        :originaltext="originaltext"
        @close-dialog="showCollabDocEditsAllDialog = false"
        @processnavall="processnavall"
      />
      <!-- negotiation -->
      <ParaDialogComments
        :dialog="showNegotCommentsDialog"
        :panelno="parseInt(panelno)"
        :actionpara="dataActionPara"
        :dialogtype="NEGOTIATION"
        :localpanelstate="localpanelstate"
        @close-dialog="showNegotCommentsDialog = false"
      />
      <ParaDialogDocEdits
        :dialog="showNegotDocEditsDialog"
        :panelno="parseInt(panelno)"
        :actionpara="dataActionPara"
        :dialogtype="NEGOTIATION"
        :localpanelstate="localpanelstate"
        @close-dialog="showNegotDocEditsDialog = false"
      />
      <ParaDialogCommentsAll
        :dialog="showNegotCommentsAllDialog"
        :panelno="parseInt(panelno)"
        :dialogtype="NEGOTIATION"
        :localpanelstate="localpanelstate"
        :originaltext="originaltext"
        @close-dialog="showNegotCommentsAllDialog = false"
        @processnavall="processnavall"
      />
      <ParaDialogDocEditsAll
        :dialog="showNegotDocEditsAllDialog"
        :panelno="parseInt(panelno)"
        :dialogtype="NEGOTIATION"
        :localpanelstate="localpanelstate"
        :originaltext="originaltext"
        @close-dialog="showNegotDocEditsAllDialog = false"
        @processnavall="processnavall"
      />

      <ParaDialogCollabLabels
        :dialog="showCollabLabelsDialog"
        :panelno="parseInt(panelno)"
        :localpanelstate="localpanelstate"
        :originaltext="originaltext"
        @close-dialog="showCollabLabelsDialog = false"
        @processnavall="processnavall"
      />

      <!-- Navigation Panel -->
      <PanelNavigation
        :authlevel="dbauthprivilege"
        :panelno="parseInt(panelno)"
        :sync="panelSync"
        :localpanelstate="localpanelstate"
        :eligibledocxin="eligibledocxin"
        :effective-topic="effectiveTopic"
        :paracontext="lparacontext"
        :panelstated="panelstated"
        :document-available="documentAvailable"
        :compressedrec="compressedrec"
        :englishtranslaterec="englishtranslaterec"
        :englishavail="englishavail"
        :editable="editable"
        :editsonlyview="editsonlyview"
        :showcollaborativeedits="showcollaborativeedits"
        :shownegotiationedits="shownegotiationedits"
        :compareon="lcompareOn"
        :comparedocname="lcomparedocname"
        :integrationon="lintegrationOn"
        :integtype="lintegtype"
        :integname="lintegname"
        :integcreds="lintegcreds"
        :integparas="lintegparas"
        :integspecs="lintegspecs"
        :ifhubtype="ifHUBType"
        @processNav="processNav"
        @processTopic="processTopic"
        @processSync="processSync"
        @processTopicSelectivity="processTopicSelectivity"
        @compareStop="stopCompareDocument"
        @integrationStop="stopIntegrationState"
        @compareSetOptions="compareSetOptions"
        @integrationRefresh="integrationRefresh"
      />
      <div v-if="showFileUpload">
        <v-container>
          <v-row class="my-n6">
            <v-col v-if="dbauthprivilege > 'D'" cols="12" sm="4" md="4">
              <v-select
                v-model="uploaddoctype"
                label="Document Type"
                :items="availdoctypes"
                item-text="label"
                item-value="value"
                return-object
                single-line
              />
            </v-col>
            <v-col cols="12" sm="progresson ? 8 : 7" md="7">
              <v-file-input :label="filesuploadmessage" @change="getFile" />
            </v-col>
            <v-col cols="12" sm="1" md="1">
              <v-progress-circular
                v-if="progresson"
                indeterminate
                class="mt-3"
                color="blue"
                :size="45"
              >
                {{ progresscircular }}%
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </header>

    <main :class="$style.panelmain">
      <div v-if="documentAvailable && !lcompareOn && !lintegrationOn">
        <ParaC
          v-for="paragraphIdx in paraIndices"
          :key="paragraphIdx"
          :panelno="panelno"
          :authlevel="dbauthprivilege"
          :ind="parseInt(paragraphIdx)"
          :localpanelstate="localpanelstate"
          :editable="editable"
          :compressedrec="compressedrec"
          :englishtranslaterec="englishtranslaterec"
          :englishavail="englishavail"
          :doctranslation="doctranslation"
          :originaltext="originaltext"
          :showcollaborativeedits="showcollaborativeedits"
          :shownegotiationedits="shownegotiationedits"
          :lsearchon="lsearchOn"
          :lsearchcapson="lsearchCapsOn"
          :lsearchtext="lsearchText"
          @showCollabCommentsPanel="showCollabCommentsPanel"
          @showCollabDocEditsPanel="showCollabDocEditsPanel"
          @showNegotCommentsPanel="showNegotCommentsPanel"
          @showNegotDocEditsPanel="showNegotDocEditsPanel"
          @showTagsPanel="showTagsPanel"
          @setLocalParaContext="setLocalParaContext"
          @processnavall="processnavall"
          @gettranslation="getTranslation"
        />
      </div>
      <div v-if="documentAvailable && lcompareOn">
        <ParaCompare
          :originaltext="originaltext"
          :comparetext="comparetext"
          :otdoc="aPIDjobID[panelno]"
          :ctdoc="lcomparedocid"
          :compoptnonsequential="lcompoptnonsequential"
          @compareGotoPara="compareGotoPara"
        />
      </div>
      <div v-if="lintegrationOn">
        <ParaIntegrationList
          :datalist="lintegdata"
          @setLocalParaContext="setLocalParaContextfromIntegration"
        />
      </div>
    </main>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
