// Constants forworkflow options in processsing
// Only relevant for Corporate Licenses.
// collaboration, negotiation, notification

// THIS IS NOT USED IN THIS ITERATION, WILL EXPAND LATER TO INCLUDE WORKFLOW CAPABILITIES IN CORPORATE.

export const COLLABORATIONLIST = {
  '0': {
    id: '0',
    short: 'A',
    long: 'ADD-OTHER',
    order: '100',
    description: 'ALLOW COLLABORATORS TO ASSIGN OTHER COLLABORATORS',
  },
  '1': {
    id: '1',
    short: 'B',
    long: 'CLAUSE-LABEL',
    order: '010',
    description: 'ALLOW CLAUSE LABEL CHANGES DURING COLLABORATION',
  },
  '2': {
    id: '2',
    short: 'C',
    long: 'INSTANT-VIEW',
    order: '015',
    description: 'ALLOW COLLABORATORS TO VIEW DOCUMENT NOT IN COLLABORATION',
  },
  '3': {
    id: '6',
    short: 'F',
    long: 'OTHER',
    order: '060',
    description: 'OTHER',
  },
}

export const NEGOTIATIONLIST = {
  '4': {
    id: '4',
    short: 'D',
    long: 'ALLOW-COMMENTS',
    order: '070',
    description: 'ALLOW NEGOTIATIATOR COMMENTS DURING NEGOTIATION',
  },
  '5': {
    id: '5',
    short: 'E',
    long: 'ALLOW-COLLAB',
    order: '030',
    description: 'ALLOW COLLABORATORS TO VIEW DOCUMENT DURING NEGOTIATION',
  },
  '6': {
    id: '6',
    short: 'F',
    long: 'OTHER',
    order: '060',
    description: 'OTHER',
  },
}

export const NOTIFICATIONLIST = {
  '8': {
    id: '8',
    short: 'G',
    long: 'MANUALLY-TRIGGER',
    order: '074',
    description: 'ALLOW COLLABORATORS TO MANUALLY TRIGGER ALERTS',
  },
  '10': {
    id: '10',
    short: 'I',
    long: 'DISABLE-AUTO',
    order: '020',
    description:
      'DISABLE AUTO-GENERATION OF ALERTS WHEN BEGINNING COLLABORATION',
  },
  '6': {
    id: '6',
    short: 'F',
    long: 'OTHER',
    order: '060',
    description: 'OTHER',
  },
}

export const DEFAULTTAGLIST = {
  '8': {
    id: '8',
    short: 'G',
    long: 'HIGH-RISK',
    order: '074',
    description: 'Gdescr',
  },
  '9': {
    id: '9',
    short: 'H',
    long: 'LOW-RISK',
    order: '025',
    description: 'Hdescr',
  },
  '10': {
    id: '10',
    short: 'I',
    long: 'NEEDS-REVIEW',
    order: '020',
    description: 'Idescr',
  },
}
