<script>
import Layout from '@layouts/main.vue'

import APanel from '@components/panel/Apanel.vue'

import DocumentProcessingService from '@services/DocumentProcessingService'

import { panelComputed, dbauthComputed, syncviewComputed } from '@state/helpers'

export default {
  page: {
    title: 'Panel Processing',
    meta: [{ name: 'description', content: 'The Panel Processing page.' }],
  },
  components: {
    // DBtest,
    // Pan,
    APanel,
    // test1,
    Layout,
    // DocumentToolbar,
  },

  props: {
    userid: {
      type: String,
      default: () => 'NONE',
    },
    keycode: {
      type: String,
      default: () => 'NONE',
    },
  },

  data: function () {
    return {
      previewFeedbackDialog: false,
      showpanelno: -1,
      panelsdistinct: [],
    }
  },
  computed: {
    ...dbauthComputed,
    ...panelComputed,
    ...syncviewComputed,

    ifLoggedIn() {
      return this.dbauthuser !== null && this.dbauthuser !== undefined
    },

    per() {
      switch (this.panelsToShow) {
        case 1:
          return 'xs12'
        case 2:
          return 'xs6'
        case 3:
          return 'xs4'
        case 4:
          return 'xs3'
        case 5:
          return 'xs2'
        case 6:
          return 'xs2'
        default:
          return 'xs6'
      }
    },
  },

  mounted: function () {
    // moved loading integrations to start
    this.$store.dispatch('dbintegration/setIntegrations')

    if (
      this.userid !== null &&
      this.userid !== undefined &&
      this.userid !== 'NONE'
    ) {
      const MyThis = this

      // need to also validate that the userid and keycode combo is valid.
      DocumentProcessingService.SSOvalidatesigninkeycode({
        userid: this.userid,
        keycode: this.keycode,
      }).then(function (returnobj) {
        if (returnobj.data.valid === 'verified') {
          MyThis.$store
            .dispatch('dbauth/setUserAuth', {
              uniqid: MyThis.userid,
              verified: true,
              token: 'SSO',
              oldpassword: 'NONE',
            })
            // added log.
            .then(
              // log
              MyThis.$store.dispatch('dblogdoc/addLogUserOnly', {
                uniqid: MyThis.userid,
                logdetail: {
                  action: 'USEREVENTS.USERSIGNON',
                  detail: 'SSO',
                  context: '',
                },
              })
            )
        } else {
          MyThis.$router.push({ path: '/' })
        }
      })
    }
  },

  methods: {
    // ...dbauthComputed,

    showDoc(panelno) {
      return panelno < this.panelsToShow
    },

    // don't think this works.  fix later.
    deleteItem(type, index, panelno) {
      this.removeOneFeedback({
        type: type,
        feedbackIndex: index,
        panelno: panelno,
      })
    },

    adddistinct(count) {
      this.panelsdistinct.push(count)
    },
  },
}
</script>

<template>
  <Layout>
    <header>
      <!-- <DocumentToolbar v-show="true"></DocumentToolbar> -->
    </header>

    <main :class="$style.main" class="d-flex fill-height">
      <v-container fluid class="container">
        <v-layout row>
          <v-flex :class="per">
            <APanel
              v-show="showDoc(0) && ifLoggedIn"
              :class="$style.panel"
              :panelno="0"
              :panelsdistinct="panelsdistinct"
              @add-distinct="adddistinct"
            />
          </v-flex>
          <!-- For some reason, panel 1 always has trouble scrolling.  Not sure why at all.  trouble shoot later. -->
          <v-flex :class="per">
            <APanel
              v-show="showDoc(1) && ifLoggedIn"
              :class="$style.panel"
              :panelno="1"
              :panelsdistinct="panelsdistinct"
              @add-distinct="adddistinct"
            />
          </v-flex>
          <v-flex :class="per">
            <APanel
              v-show="showDoc(2) && ifLoggedIn"
              :class="$style.panel"
              :panelno="2"
              :panelsdistinct="panelsdistinct"
              @add-distinct="adddistinct"
            />
          </v-flex>
          <v-flex :class="per">
            <APanel
              v-show="showDoc(3) && ifLoggedIn"
              :class="$style.panel"
              :panelno="3"
              :panelsdistinct="panelsdistinct"
              @add-distinct="adddistinct"
            />
          </v-flex>
          <v-flex :class="per">
            <APanel
              v-show="showDoc(4) && ifLoggedIn"
              :class="$style.panel"
              :panelno="4"
              :panelsdistinct="panelsdistinct"
              @add-distinct="adddistinct"
            />
          </v-flex>
          <v-flex :class="per">
            <APanel
              v-show="showDoc(5) && ifLoggedIn"
              :class="$style.panel"
              :panelno="5"
              :panelsdistinct="panelsdistinct"
              @add-distinct="adddistinct"
            />
          </v-flex>
          <!-- <test1 v-if="false"></test1> -->
          <!-- commenting out DBtest until needed for now -->
          <!-- <DBtest v-if="false"></DBtest> -->

          <!-- </v-flex> -->
        </v-layout>
      </v-container>
    </main>
    <footer />
  </Layout>
</template>

<style lang="scss" module>
@import '@design';
</style>
