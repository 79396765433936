// panel state and authentication util functions

export function panelstate(
  docmeta,
  docRawData,
  dbauthuser,
  dbauthGroupsAccess,
  dbauthDocTypes
) {
  let obj = {}
  obj.docmeta = docmeta
  // add the uniqid contents to the panelstate.
  obj.dbauthuser = dbauthuser
  obj.ifLoggedIn = dbauthuser !== null
  obj.ifDocLoaded = docRawData !== null && typeof docRawData !== 'undefined'
  obj.ifSaved = docmeta !== null && typeof docmeta !== 'undefined'

  // defaults to be set later.
  obj.ifStandard = false
  obj.ifPlaybook = false
  obj.ifTemplate = false
  obj.ifApproved = false
  obj.ifOwner = false
  obj.ifExternalCollaborator = false
  obj.ifExternalNegotiator = false
  obj.ifDocType = false
  obj.docTypeofDoc = null
  obj.docTypeDetailofDoc = null
  obj.ifDocTypeOwner = false
  obj.ifDocTypeCollaborator = false

  // document state - STARTED, COLLABORATION, NEGOTIATION, APPROVED (document states)
  obj.ifStartedState = false
  obj.ifCollaborationState = false
  obj.ifNegotiationState = false
  obj.ifArchivedState = false

  // set for now, expand later.
  obj.ifCorporateState = false

  obj.NegotiationAvail = false

  // ifDocType
  if (
    obj.ifLoggedIn &&
    obj.ifSaved &&
    typeof docmeta.doctypeid !== 'undefined'
  ) {
    obj.ifDocType = docmeta.doctypeid !== '1' && docmeta.doctypeid !== 1
  }

  // setting for specific document state.
  if (
    obj.ifLoggedIn &&
    obj.ifSaved &&
    typeof docmeta.doccategory !== 'undefined'
  ) {
    obj.ifStandard = docmeta.doccategory === 'STANDARD'
    obj.ifPlaybook = docmeta.doccategory === 'PLAYBOOK'
    obj.ifApproved = docmeta.doccategory === 'ARCHIVE'
    obj.ifTemplate = docmeta.doccategory === 'TEMPLATE'

    // set document state
    obj.ifStartedState =
      docmeta.collabstatus !== 'OPEN' && docmeta.negotiationstatus !== 'OPEN'
    obj.ifCollaborationState = docmeta.collabstatus === 'OPEN'
    obj.ifNegotiationState = docmeta.negotiationstatus === 'OPEN'

    // Archived State = Archive or Playbook.  Both are not mutable documents.
    obj.ifArchivedState =
      docmeta.doccategory === 'ARCHIVE' ||
      docmeta.doccategory === 'PLAYBOOK' ||
      docmeta.doccategory === 'TEMPLATE'

    // set an observed option to see if we should turn on the negotiation capabilities of the product.
    obj.NegotiationAvail =
      docmeta.externalnegotiators.length > 0 ||
      docmeta.docenableNegotiation ||
      docmeta.negotiationstatus === 'OPEN'

    if (typeof docmeta.ownerid !== 'undefined') {
      obj.ifOwner = docmeta.ownerid.includes(dbauthuser.uniqid)
      obj.ifExternalCollaborator = docmeta.externalcollaborators.includes(
        dbauthuser.uniqid
      )
      obj.ifExternalNegotiator = docmeta.externalnegotiators.includes(
        dbauthuser.uniqid
      )
    }
    if (typeof docmeta.doctypeid !== 'undefined') {
      obj.ifDocType = docmeta.doctypeid !== '1' && docmeta.doctypeid !== 1
    }
  }
  // docTypeofDoc and docTypeDetailofDoc
  if (
    obj.ifDocType &&
    dbauthGroupsAccess.length > 0 &&
    typeof docmeta !== 'undefined'
  ) {
    const id = docmeta.doctypeid
    dbauthGroupsAccess.forEach(function (e) {
      if (e.doctypeid.includes(id)) {
        obj.docTypeofDoc = e
      }
    })
    dbauthDocTypes.forEach(function (e) {
      if (e.keyid === id) {
        obj.docTypeDetailofDoc = e
      }
    })
  }

  if (
    obj.ifLoggedIn &&
    obj.ifSaved &&
    obj.ifDocType &&
    obj.docTypeofDoc !== null
  ) {
    obj.ifDocTypeOwner = obj.docTypeofDoc.ownerid.includes(dbauthuser.uniqid)
    obj.ifDocTypeCollaborator = obj.docTypeofDoc.uniqid.includes(
      dbauthuser.uniqid
    )
  }

  // set the effective privileges allowed in each panel.
  obj.ownerPriv = obj.ifDocTypeOwner || obj.ifOwner
  obj.collaboratorPriv = obj.ifExternalCollaborator || obj.ifDocTypeCollaborator
  obj.negotiatorPriv = obj.ifExternalNegotiator

  return obj
}

// used to generate different
export function panelstated(localpanelstate, ifProcessing) {
  let obj = {}

  obj.docidD = '--'
  if (localpanelstate.ifSaved) {
    obj.docidD = localpanelstate.docmeta.docid
  }

  obj.docStateD = '--'

  if (
    localpanelstate.ifArchivedState &&
    !localpanelstate.ifPlaybook &&
    !localpanelstate.ifTemplate
  ) {
    obj.docStateD = 'A'
  } else if (localpanelstate.ifArchivedState && localpanelstate.ifPlaybook) {
    obj.docStateD = 'P'
  } else if (localpanelstate.ifArchivedState && localpanelstate.ifTemplate) {
    obj.docStateD = 'T'
  } else if (
    localpanelstate.ifCollaborationState &&
    localpanelstate.ifNegotiationState
  ) {
    obj.docStateD = 'CN'
  } else if (localpanelstate.ifCollaborationState) {
    obj.docStateD = 'C'
  } else if (localpanelstate.ifNegotiationState) {
    obj.docStateD = 'N'
  } else if (localpanelstate.ifStartedState) {
    obj.docStateD = '--'
  } else {
    obj.docStateD = '--'
  }

  obj.privilegeD = '---'

  if (localpanelstate.ifOwner) {
    obj.privilegeD = 'O'
  } else if (localpanelstate.ifExternalCollaborator) {
    obj.privilegeD = 'C'
  } else if (localpanelstate.ifExternalNegotiator) {
    obj.privilegeD = 'N'
  } else if (localpanelstate.ifDocTypeOwner) {
    obj.privilegeD = 'OG'
  } else if (localpanelstate.ifDocTypeCollaborator) {
    obj.privilegeD = 'CG'
  } else {
    obj.privilegeD = '--'
  }

  obj.doctypeD = '--'
  obj.groupD = '--'

  if (!localpanelstate.ifSaved) {
    obj.doctypeD = '--'
  } else if (!localpanelstate.ifDocType) {
    obj.doctypeD = '--'
  } else if (localpanelstate.docTypeofDoc === null) {
    obj.doctypeD = '--'
  } else {
    obj.doctypeD = localpanelstate.docTypeDetailofDoc.doctype

    // this gives a null error.
    if (localpanelstate.docTypeofDoc !== null) {
      obj.groupD = localpanelstate.docTypeofDoc.group
    }
  }
  obj.docnameD = '--'
  if (localpanelstate.ifProcessing) {
    obj.docnameD = 'PROCESSING'
  } else if (!localpanelstate.ifDocLoaded) {
    obj.docnameD = '--'
  } else if (!localpanelstate.ifSaved) {
    obj.docnameD = 'UNSAVED'
  } else if (!localpanelstate.ifSaved) {
    obj.docnameD = '--'
  } else {
    obj.docnameD = localpanelstate.docmeta.docname
  }

  return obj
}
