import { EN } from './locale-en'
import { FR } from './locale-fr'
import { DE } from './locale-de'
import { IT } from './locale-it'
import { ES } from './locale-es'

// adding
import { ZH } from './locale-zh'
import { EL } from './locale-el'
import { JA } from './locale-ja'
import { NL } from './locale-nl'
import { RU } from './locale-ru'
import { TR } from './locale-tr'
import { KO } from './locale-ko'
import { PT } from './locale-pt'

export const LOCALES = {
  en: EN,
  fr: FR,
  de: DE,
  it: IT,
  es: ES,

  // adding
  zh: ZH, // Chinese - reviewed by Eva
  el: EL, // Greek
  ja: JA, // Japanese -
  nl: NL, // Dutch
  ru: RU, // Russian -
  tr: TR, // Turkish - Ebru?
  ko: KO, // Korean
  pt: PT, // Portugese
}
