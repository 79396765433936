import { mapState, mapGetters, mapActions } from 'vuex'

// DB State IO
export const dbauthComputed = {
  ...mapState('dbauth', {}),
  ...mapGetters('dbauth', [
    'dbauthreturn',
    'dbgroupid',
    'dbgroupobject',
    'dbauthProfile',
    'dbauthPrivileges',
    'dbauthPrivUsage',
    'dbauthGroupsAccess',
    'dbauthDocTypes',
    'dbauthAvailDocs',
    'dbauthAvailGroupDocs',
    'dbauthAvailHubDocs',
    'dbauthAvailArchiveDocs',
    'dbauthAvailPlaybooks',
    'dbauthAvailTemplates',
    'dbauthOwnerDocTypes',
    'dbauthOwnerAlerts',
    'dbauthDocTypeAlerts',
    'dbauthUserInfoCache',
    'dbauthuser',
  ]),
}

export const dbregActions = {
  ...mapActions('dbreg', [
    'createUserfromEmailPWReg',
    'completeRegistrationReg',
    'sendInitialEmailVerificationReqReg',
    'sendEmailVerificationReqReg',
    'confirmPasswordResetReg',
    'updatePasswordinAppReg',
    'updateAccountSettingsReg',
    'upgradetoBifReferred',
    'createUserProfileReg',
    'deleteUserProfileReg',
    'writeNewUserReg',
  ]),
}

export const dbauthActions = {
  ...mapActions('dbauth', [
    'setUserAuth',
    'logoutUserAuth',
    'loginEmailPassAuth',
    'setCurrentLoggedInUserAuth',
    'setLoginErrorAuth',
    'createUserObjectAuth',
    'setUserProfileAuth',
    'getUserProfileAuth',
    'resetUserGroupAccessAuth',
    'getUserGroupAccessAuth',
    'getUserPrivilegesAuth',
    'getUserPrivUsageAuth',
    'addUserPrivUsageDocProcessedAuth',
    'getUserDocTypesAuth',
    'getUserAvailDocsAuth',
    'getUserAvailGroupDocsAuth',
    'getUserAvailHubDocsAuth',
    'getUserAvailArchiveDocsAuth',
    'getUserAvailPlaybooksAuth',
    'getUserAvailTemplatesAuth',
    'getUserOwnerDocTypesAuth',
    'getUserOwnerAlertsAuth',
    'getDocTypeAlertsAuth',
    'updateUserProfileAuth',
    'addRecordUserInfoAuth',
    'queryValidIDAuth',
    'createUserEmailPasswordAuth',
    'doStateChangeAuth',
  ]),
}

export const dbalertComputed = {
  ...mapActions('dbalert', [
    'writeAlert',
    'deleteAlert',
    'addOwnerAlert',
    'deleteOwnerAlert',
    'addDocTypeAlert',
    'deleteDocTypeAlert',
    'addClauseTopicAlert',
    'deleteClauseTopicAlert',
    'addRecipientAlert',
    'deleteRecipientAlert',
    'addNotificationRuleAlert',
    'deleteNotificationRuleAlert',
    'setSelectivitityAlert',
    'processTriggerAlert',
    'processAllTriggerAlert',
    'processCustomTriggerAlert',
    'logAlert',
  ]),
}

export const dbgroupComputed = {
  ...mapActions('dbgroup', [
    'writeGroup',
    'deleteGroup',
    'addOwnerGroup',
    'deleteOwnerGroup',
    'addCollabGroup',
    'deleteCollabGroup',
    'addDocTypeGroup',
    'deleteDocTypeGroup',
    'logGroup',
  ]),
}

export const dbintegrationComputed = {
  ...mapGetters('dbintegration', [
    'dbintegrationInteg1',
    'dbintegrationInteg2',
    'dbintegrationInteg3',
    'dbintegrationInteg4',
    'dbintegrationConfig',
    'dbhubinfo',
    'dbssoinfo',
  ]),
}

export const dbcommentComputed = {
  ...mapState('dbcomment', {}),
  ...mapGetters('dbcomment', ['dbcommentobject']),
  ...mapActions('dbcomment', ['writeComment', 'deleteComment']),
}
export const dbdoceditComputed = {
  ...mapState('dbdocedit', {}),
  ...mapGetters('dbdocedit', ['dbcommentreturn', 'dbcommentobject']),
  ...mapActions('dbdocedit', [
    'writeDocEdit',
    'writeApprovedDocEdit',
    'ownerApproveDocEdit',
    'ownerRejectDocEdit',
    'negotiatorApproveDocEdit',
    'negotiatorRejectDocEdit',
    'deleteDocEdit',
  ]),
}
export const dbdoctypeComputed = {
  ...mapActions('dbdoctype', [
    'writeDocType',
    'deleteDocType',
    'addOwnerDocType',
    'deleteOwnerDocType',
    'addAddlClauseDocType',
    'deleteAddlClauseDocType',
    'addAddlTagDocType',
    'deleteAddlTagDocType',
    'addCollabRuleDocType',
    'deleteCollabRuleDocType',
    'addNotificationRuleDocType',
    'deleteNotificationRuleDocType',
    'addNegotiationRuleDocType',
    'deleteNegotiationRuleDocType',
    'logDocType',
  ]),
}
export const dbdocumentComputed = {
  ...mapState('dbdocument', {}),
  ...mapGetters('dbdocument', ['dbdocumentreturn', 'dbdocumentobject']),
  ...mapActions('dbdocument', [
    'writeNewDocument',
    'updateDocument',
    'getDocument',
    'writeNewSyncDocument',
    'writeToDocSyncDocument',
    'updateDocSyncDocument',
  ]),
}

export const dbdocmetaComputed = {
  ...mapState('dbdocmeta', {}),
  ...mapGetters('dbdocmeta', [
    'dbdocmetaobject',
    'dbdocumetareturn',
    'dbdocmetaid',
  ]),
  ...mapActions('dbdocmeta', [
    'writeNewDocMeta',
    'openDocMeta',
    'closeDocMeta',
    'deleteDocMeta',
    'addOwnerDocMeta',
    'deleteOwnerDocMeta',
    'addCollaboratorDocMeta',
    'deleteCollaboratorDocMeta',
    'addNegotiatorDocMeta',
    'deleteNegotiatorDocMeta',
    'saveCollaborationDocMeta',
    'saveNegotiationDocMeta',
    'writeCollaborationInviteDocMeta',
    'deleteDocMetaSync',
    'addNoteDocMeta',
    'updateArchiveDocMeta',
    'saveAsPlaybookDocMeta',
    'saveAsTemplateDocMeta',
    'saveAsArchiveDocMeta',
    'attachContracttoArchiveDocMeta',
    'deleteSyncDocMeta',
    'getDocMeta',
    'writeToDocSyncDocMeta',
    'updateDocSyncDocMeta',
  ]),
}

export const dberrorComputed = {
  ...mapState('dberror', {}),
  ...mapGetters('dberror', ['dberror']),
  ...mapActions('dberror', ['logError']),
}

export const dblogdocComputed = {
  ...mapState('dblogdoc', {}),
  ...mapGetters('dblogdoc', ['dblogdocreturn']),
  ...mapActions('dblogdoc', [
    'writeNewLogDoc',
    'writeNewLogUser',
    'addLogDoc',
    'addLogUserOnly',
    'setLogDoc',
  ]),
}

export const dbtagComputed = {
  ...mapState('dbtag', {}),
  ...mapGetters('dbtag', ['dbtagreturn', 'dbtagobject']),
  ...mapActions('dbtag', ['writeTag', 'deleteTag']),
}
export const dbtemplateComputed = {
  ...mapState('dbtemplate', {}),
  ...mapGetters('dbtemplate', ['dbtemplatereturn', 'dbtemplateobject']),
  ...mapActions('dbtemplate', [
    'writeTemplate',
    'updateTemplate',
    'deleteTemplate',
    'addClauseExampleTemplate',
    'deleteClauseExampleTemplate',
  ]),
}
export const dbuserComputed = {
  ...mapState('dbuser', {}),
  ...mapGetters('dbuser', ['dbuserreturn', 'dbuserobject']),
  ...mapActions('dbuser', ['doSomeuser']),
}

export const syncviewComputed = {
  ...mapState('syncview', {}),
  ...mapGetters('syncview', [
    'filterTopicView',
    'filterTopic',
    'paraContext',
    'panelsToShow',
    'searchOn',
    'searchCapsOn',
    'searchText',
    'searchChanged',
    'comparedocid',
    'comparepara',
    'compareChange',
  ]),
  ...mapActions('syncview', [
    'setFilterTopicView',
    'setFilterTopic',
    'setParaContext',
    'setPanelsToShow',
    'setSearch',
    'setDocCompareIDpara',
  ]),
}

export const statisticsComputed = {
  ...mapState('statistics', {
    evaluation: (state) => state.evaluation,
  }),
  ...mapGetters('statistics', ['getEvaluation', 'getConfiguration']),
}

export const panelComputed = {
  ...mapGetters('panels', [
    'aPID',
    'aPIDjobID',
    'aPIDprocessStatus',
    'docRawData',
    'docDocData',
    'docDocLog',
    'docFile',
    'docTypeId',
    'editComments',
    'editDocedits',
    'editTags',
    'infoClauseRedundant',
    'infoClauseTreeStructure',
    'infoMetadataPrioritySelected',
    'infoObligationTreeStructure',
    'infoDocClauses',
    'zMetadataTypes',
    'zDocumentTypes',
  ]),
  ...mapActions('panels', [
    'setOpenDoc',
    'getDocRawData',
    'updateDocData',
    'stripeGetLatest',
    'stripeGetBillingSession',
    'alertEmailGeneration',
    'updateEditComments',
    'updateEditDocEdits',
    'updateEditTags',
    'setDocLog',
  ]),
}

export const documentEventsMethods = {
  ...mapActions('documentEvents', [
    'toggleSwitches',
    'setJumpTarget',
    'setShowJumpMenu',
  ]),
}

export const dbuserinfoActions = {
  ...mapGetters('dbuserinfo', ['dbauserinfocache']),
  ...mapActions('dbuserinfo', [
    'ifEmailRegisteredUserInfo',
    'addEmailReferralIfNotRegisteredUserInfo',
    'addEmailtoReferralUserInfo',
    'getUserRecordUserInfo',
    '',
  ]),
}

export const authMethods = mapActions('auth', ['logIn', 'logOut'])

export const snackbarMethods = mapActions('snackbar', [
  'setSnackbar',
  'resetSnackbar',
])

export const signMethods = mapActions('signesignature', [
  'signNewRequest',
  'signGetRequestStatus',
  'signCancelRequest',
  'signGetRequestSig',
])

export const docxmodMethods = mapActions('docxmodservice', [
  'docxCreateRedline',
  'docxCreateFinal',
])

export const snackbarComputed = {
  ...mapState('snackbar', {
    snackbar: (state) => state.snack,
  }),
  ...mapGetters('snackbar', ['getSnack']),
}

export const statisticsMethods = mapActions('statistics', [
  'updateProperty',
  'fetchEvaluation',
  'executeEvaluation',
])

export const documentMethods = mapActions('documents', [
  'uploadDocument',
  'uploadPDFDocument',
  'updateClause',
])

// export const mongoMethods = mapActions('mongoservice', ['mongoInsertOne'])

export const settingsComputed = mapGetters('settings', ['isDebugEnabled'])
