<script>
import { capitalize, last } from 'lodash'

import {
  panelComputed,
  // feedbackComputed,
  // feedbackMethods,
} from '@state/helpers'
import {
  getDisplayCategoryforCatID,
  // getDisplayCategoryShortforCatID,
  getCatIDFromUID,
  constructLabel,
  getCategoryNames,
  getCategoryListNames,
} from '@utils/clauseMapping'

export default {
  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    // data: {
    //   type: Object,
    //   required: true,
    // },
    ind: {
      type: Number,
      default: () => 0,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      required: true,
    },
    compressed: {
      type: Boolean,
      required: true,
    },
    englishtranslate: {
      type: Boolean,
      required: true,
    },
    originaltext: {
      type: Object,
      required: true,
    },
    englishavail: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    showMenu: false,
    x: 0,
    y: 0,
    // editable: true, // toggle this to make it editable or not.
  }),
  computed: {
    ...panelComputed,
    // ...feedbackComputed,

    dataInput() {
      return this.docRawData[this.panelno].clauseId[this.ind]
    },

    enopt() {
      if (this.englishtranslate) {
        return 'EN'
      }
      return this.docRawData[this.panelno].clauseId['0'].language
    },

    // this is used to identify the exact entity uniquely across panels  should return panelno-clause-7
    dataInputId() {
      const concatString = this.panelno + '-' + this.dataInput.id
      return concatString
    },

    category() {
      const clauseId = last(this.dataInput.uid.split('-'))
      if (this.compressed) {
        return ''
        // return getDisplayCategoryShortforCatID(clauseId)
      } else {
        return getDisplayCategoryforCatID(clauseId)
      }
    },

    text() {
      if (this.short && this.compressed) {
        return ''
      }
      return this.displayCat(this.category)
    },

    short() {
      if (
        this.originaltext === null ||
        this.originaltext === undefined ||
        this.originaltext[this.ind] === undefined
      ) {
        return true
      }
      return this.originaltext[this.ind].OT.length < 100
      // return this.data.text.length < 100
    },

    // dynamically sets the color via style.
    color() {
      if (this.category === 'TITLE') {
        return `confidenceUnknown`
      }
      // eliminated confidenceLevel from passed data.  UX interprets
      // based on confidence.
      const conf = this.dataInput.confidence
      var level = ''
      if (conf > 0.8) {
        level = 'High'
      } else if (conf > 0.6) {
        level = 'Medium'
      } else {
        level = 'Low'
      }

      if (this.editable) {
        return `confidence${capitalize(level)}`
      } else {
        return `confidenceUnknown`
      }
    },

    // this is to populate the items as alternatives.
    items() {
      // if negotiator, likely does not know about doc types or groups, and also no ability to edit clause labels
      if (this.localpanelstate.negotiatorPriv) {
        return []
      }

      const items = []
      let alternativeCategories = this.dataInput.alternate
      for (let alt in alternativeCategories) {
        items.push(
          // upperFirst(alternativeCategories[alt]['clauseCategory']).replace(
          this.displayCat(
            getDisplayCategoryforCatID(
              getCatIDFromUID(alternativeCategories[alt]['uid'])
            )
          )
        )
      }
      // add the categories for the doctype if docType
      if (this.localpanelstate.ifDocType) {
        if (
          this.localpanelstate.docTypeDetailofDoc !== null &&
          this.localpanelstate.docTypeDetailofDoc !== undefined
        ) {
          // below is filled out only if a member of the doc type group.
          let doctypecategories = this.localpanelstate.docTypeDetailofDoc
            .addlclauses
          doctypecategories.forEach((e) => items.push(e))
        }
      }

      // add the other categories from the list
      const categories = getCategoryNames()
      categories
        .filter((e) => !items.includes(this.displayCat(e)))
        .forEach((e) => items.push(e))

      return items
    },
    reverseLookupIntl() {
      const catList = getCategoryListNames()

      var returnList = []

      catList.forEach(
        (e) =>
          returnList.push({ long: e.long, trlong: this.displayCat(e.long) })
        // returnList.push({
        //   label: e.long,
        //   translatedlabel: this.this.displayCat(e.long),
        // })
      )

      return returnList
    },

    icontype() {
      if (this.compressed) {
        return 'mdi-arrow-expand'
      } else {
        return 'mdi-arrow-collapse'
      }
    },
  },

  methods: {
    // ...feedbackMethods,
    show(e) {
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        if (this.editable) {
          this.showMenu = true
        }
      })
    },
    getUidFromLabel(label) {
      // return ''
      return constructLabel(label)
    },
    addFeedback(newLabel) {
      // const currentLabel = this.dataInput.clauseCategory

      var findIndex = this.reverseLookupIntl.findIndex(
        (e) => e.trlong === newLabel
      )

      var reversetransLabel
      if (findIndex > 0) {
        reversetransLabel = Object.values(this.reverseLookupIntl).find(
          (e) => e.trlong === newLabel
        ).long
      } else {
        reversetransLabel = newLabel
      }

      // modified to adjust for OT data structure.
      let feedback = {
        panelno: this.panelno,
        // text: this.dataInput.text,  // undefined
        text: this.originaltext[this.ind].OT,
        uid: this.getUidFromLabel(reversetransLabel),
        // currentLabel: currentLabel, // undefined
        newLabel: reversetransLabel,
        paragraphIndex: this.ind,
        previousUID: this.dataInput.previousUID || this.dataInput.uid,
        docid: this.aPIDjobID[this.panelno],
      }

      this.$store.dispatch('panels/updateClause', feedback)
    },
    setparagraphhighlight() {
      this.$emit('processnavall')
      // this unsyncs panel, sets topic to ALL, sets paragraph highlight
    },
    changelcompressed() {
      this.$emit('changelcompressed')
    },
    changelenglishtranslate() {
      this.$emit('changelenglishtranslate')
    },
    // displays the internationalized name of the topic.
    displayCat(longid) {
      var catNamestemp = getCategoryNames()
      if (catNamestemp.includes(longid)) {
        return this.$t('TOPIC.' + longid)
      }
      return longid
    },
  },
}
</script>
<template>
  <div class="px-2">
    <v-btn x-small text class="py-3" @click="changelcompressed">
      <v-icon size="15">{{ icontype }}</v-icon>
    </v-btn>
    <v-icon
      v-if="dataInput.changed && editable && !compressed"
      :class="$style.iconStyle"
      size="20"
      color="green"
      >mdi-checkbox-marked-circle</v-icon
    >
    <div :id="dataInputId" :class="$style.labelContainer" @click.stop="show">
      <p :class="[$style.label, color]">{{ text }}</p>
    </div>

    <v-btn
      v-if="!compressed"
      x-small
      text
      class="py-3"
      @click="setparagraphhighlight"
      >{{ ind }}</v-btn
    >
    <v-btn
      v-if="englishavail"
      x-small
      text
      class="py-3"
      @click="changelenglishtranslate"
      >{{ enopt }}</v-btn
    >
    <v-menu
      v-model="showMenu"
      max-height="500px"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="addFeedback(item)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <!-- </div> -->
  </div>
</template>
<style lang="scss" module>
@import '@design';
</style>
