<script>
import { dbauthComputed } from '@state/helpers'

import { formatDateDialog, docroute } from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },

  computed: {
    ...dbauthComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
    // only the last 200 records.
    logRecords() {
      if (this.dbauthPrivUsage !== null && this.dbauthPrivUsage !== undefined) {
        if (
          this.dbauthPrivUsage.log !== undefined &&
          this.dbauthPrivUsage.log !== null &&
          this.dbauthPrivUsage.log.length > 0
        ) {
          return this.dbauthPrivUsage.log.slice(
            Math.max(this.dbauthPrivUsage.log.length - 200, 1)
          )
        }
      }
      return []
    },
  },
  methods: {
    // relay to use utility functions.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    docroute(d) {
      return docroute(d)
    },

    displayAction(action) {
      // backward compartible
      if (action.includes(' ')) {
        return action
      }
      if (action.includes('HUB.')) {
        return 'HUB--' + this.$t(action.substring(4))
      }
      return this.$t(action)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <PanelCardTitle :cardtitle="$t('DIALOGS.USERLOGS')"></PanelCardTitle>
        <div v-if="true" width="auto" class="mx-8">
          <v-form>
            <v-container>
              <v-row justify="center" width="auto">
                <v-simple-table :class="$style.dialogOpenTable" fixed-header>
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                      fixed-header
                    >
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          >{{ $t('DIALOGS.TIME') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.ACTION') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.DETAIL') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.CONTEXT') }}</th
                        >
                      </tr>
                    </thead>

                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in logRecords"
                        :key="item.timestamp.toString()"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogBigCellStatus">{{
                          formatDateDialog(item.timestamp)
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          displayAction(item.action)
                        }}</td>
                        <td :class="$style.dialogBigCellStatus">{{
                          item.detail
                        }}</td>
                        <td :class="$style.dialogCellWidth">{{
                          item.context
                        }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>

        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('profile/#user-log')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
