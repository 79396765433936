export const state = {
  debug: true,
}

export const getters = {
  isDebugEnabled(state) {
    return state.debug
  },
}

export const mutations = {
  SET_DEBUG(state, enabled) {
    state.debug = enabled
  },
}

export const actions = {
  setDebug({ commit }, enabled) {
    commit('SET_DEBUG', enabled)
  },
}
