// utility function to address the Vue state changes for arrays.  Cannot alter in place, need to use splice methods
export function setVueArray(array, position, value) {
  if (array.length === 0) {
    // first one
    array.push(value)
  } else if (array.length === position) {
    array.push(value)
  } else {
    array.splice(position, 1, value)
  }
}
