<script>
import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    confirmationmessage: {
      type: String,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
  },

  methods: {
    confirmAction() {
      this.showDialog = false
      this.$emit('confirm')
    },
  },
}
</script>

<template>
  <v-row>
    <v-dialog
      v-model="showDialog"
      max-width="500px"
      persistent
      :content-class="$style.dialogWidth"
    >
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.CONFIRMACTION')"
        ></PanelCardTitle>
        <div class="my-5 mx-5">
          {{ confirmationmessage }}
        </div>

        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn large outlined class="mx-4" @click="confirmAction">{{
            $t('DIALOGS.CONFIRM')
          }}</v-btn>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
