import store from '@state/store'

import SnackService from '@/src/services/SnackService'

import DocumentProcessingService from '@services/DocumentProcessingService'

export const state = {
  dbintegrationInteg1: [],
  dbintegrationInteg2: [],
  dbintegrationInteg3: [],
  dbintegrationInteg4: [],
  dbhubinfo: [],
  dbssoinfo: [],
  dbintegrationConfig: [],
}

// do I need to add getters here?
export const getters = {
  dbintegrationInteg1(state) {
    return state.dbintegrationInteg1
  },
  dbintegrationInteg2(state) {
    return state.dbintegrationInteg2
  },
  dbintegrationInteg3(state) {
    return state.dbintegrationInteg3
  },
  dbintegrationInteg4(state) {
    return state.dbintegrationInteg4
  },
  dbhubinfo(state) {
    return state.dbhubinfo
  },
  dbssoinfo(state) {
    return state.dbssoinfo
  },
  dbintegrationConfig(state) {
    return state.dbintegrationConfig
  },
}

export const mutations = {
  INIT_INTEGRATIONS(state) {
    state.dbintegrationInteg1 = []
    state.dbintegrationInteg2 = []
    state.dbintegrationInteg3 = []
    state.dbintegrationInteg4 = []
    state.dbhubinfo = []
    state.dbssoinfo = []
    state.dbintegrationConfig = []
  },

  SET_INTEG1(state, val) {
    state.dbintegrationInteg1 = val
  },
  SET_INTEG2(state, val) {
    state.dbintegrationInteg2 = val
  },
  SET_INTEG3(state, val) {
    state.dbintegrationInteg3 = val
  },
  SET_INTEG4(state, val) {
    state.dbintegrationInteg4 = val
  },
  SET_HUBINFO(state, val) {
    state.dbhubinfo = val
  },
  SET_SSOINFO(state, val) {
    state.dbssoinfo = val
  },
  SET_INTEGCONFIG(state, val) {
    state.dbintegrationConfig = val
  },
}

export const actions = {
  init({ commit }) {
    commit('INIT_INTEGRATIONS')
  },

  setIntegrations({ commit, state }) {
    DocumentProcessingService.INTEGgetglobals({}).then(function (doc) {
      if (!doc.exists) {
        commit('SET_INTEGCONFIG', doc.data)
        commit('SET_INTEG1', {
          integname: doc.data.integ1name,
          integtype: doc.data.integ1type,
          integcreds: doc.data.integ1creds,
          integparas: doc.data.integ1paras,
          integspecs: doc.data.integ1specs,
        })
        commit('SET_INTEG2', {
          integname: doc.data.integ2name,
          integtype: doc.data.integ2type,
          integcreds: doc.data.integ2creds,
          integparas: doc.data.integ2paras,
          integspecs: doc.data.integ2specs,
        })
        commit('SET_INTEG3', {
          integname: doc.data.integ3name,
          integtype: doc.data.integ3type,
          integcreds: doc.data.integ3creds,
          integparas: doc.data.integ3paras,
          integspecs: doc.data.integ3specs,
        })
        commit('SET_INTEG4', {
          integname: doc.data.integ4name,
          integtype: doc.data.integ4type,
          integcreds: doc.data.integ4creds,
          integparas: doc.data.integ4paras,
          integspecs: doc.data.integ4specs,
        })
        commit('SET_HUBINFO', {
          hubenabled: doc.data.hubenabled,
          hubaddress1: doc.data.hubaddress1,
          hubaddress2: doc.data.hubaddress2,
          huboption1: doc.data.huboption1,
          huboption2: doc.data.huboption2,
          huboption3: doc.data.huboption3,
          huboption4: doc.data.huboption4,
        })
        commit('SET_SSOINFO', {
          samlactivated: doc.data.samlactivated,
        })
      }
    })
  },
}
