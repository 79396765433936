import store from '@state/store'

import {
  DBsetone,
  DBdeactivate,
  DBupdateobj,
  DBupdateobjarrayUnion,
  DBupdateobjarrayObjUnion,
  DBupdateobjarrayRemove,
  DBgetobjsync,
  DBupdateobjsync,
  DBsetonesync,
} from '@/src/state/io/iodb.js'

import {
  DBHUBDocMetaAddNotes,
  DBHUBDocMetaParticipantsAdd,
  DBHUBDocMetaParticipantsRemove,
  DBHUBdocmetaupdate,
  DBHUBgetdocmetadata,
} from '@/src/state/io/iohub.js'

const TABLENAME = 'docmeta'

export const state = {
  dbdocmetaid: '',
}

// do I need to add getters here?
export const getters = {
  dbdocmetaid(state) {
    return state.dbdocmetaid
  },
}

export const mutations = {
  SET_ID(state, id) {
    state.dbdocumetaid = id
  },
}

export const actions = {
  init({ commit }) {},
  writeNewDocMeta({ commit, state }, payload) {
    var writeDocMetaEx = {
      // common across document and docmeta
      docid: payload.docid,
      doctypeid: payload.doctypeid,
      ownerid: [payload.ownerid],
      copied: '',

      // from the forms data
      docname: payload.formdata.docname,
      docdescription: payload.formdata.docdescription,
      docnotes: [], /// this will be added to via the interface.  empty to start
      docparty: payload.formdata.docparty,
      doccounterparty: payload.formdata.doccounterparty,
      docallowcollclauselabels: payload.formdata.docallowcollclauselabels,
      docenableNegotiation: payload.formdata.docenableNegotiation,
      docother: '',

      // null values for each of below.
      notificationrules: [], // notification - options for perforning notifications based on doctype.
      collabstatus: 'CLOSED', // OPEN, CLOSED -
      collabend: '', // end date for collaboration.
      collabrules: [], // options for collaboration - allow clause labels, etc.  triggers workflow
      externalcollaborators: [], // list of external collaborators.

      negotiationstatus: 'CLOSED', // OPEN or CLOSED
      negotiationend: '', // end date for negotiation.
      negotiationrules: [], // rules for who is able to negotiate - triggers workflow
      externalnegotiators: [], // list of external collaborator emails

      // signing default data
      sigrequestactive: false,
      sigrequestid: '',
      sigrequestuniqid: '',

      templateid: [], // list of templates that can be applied to this document.  Can assign from a list.
      doccategory: 'STANDARD',
      create: new Date(),
      delete: '',
      active: true,
    }

    DBsetone(TABLENAME, payload.docid, writeDocMetaEx)
  },

  updateDocMeta({ commit, state }, payload) {
    DBupdateobj(TABLENAME, payload.docid, {
      docname: payload.formdata.docname,
      docdescription: payload.formdata.docdescription,
      docparty: payload.formdata.docparty,
      doccounterparty: payload.formdata.doccounterparty,
      docallowcollclauselabels: payload.formdata.docallowcollclauselabels,
      docenableNegotiation: payload.formdata.docenableNegotiation,
    })

    store.dispatch('dblogdoc/addLogDoc', {
      docid: payload.docid,
      uniqid: payload.uniqid,
      logdetail: {
        action: 'DOCEVENTS.UPDATEDOCMETA',
        detail: payload.formdata.docname,
        context: '',
      },
    })
  },

  updateArchiveDocMeta({ commit, state }, payload) {
    DBupdateobj(TABLENAME, payload.docid, {
      docname: payload.formdata.docname,
      docdescription: payload.formdata.docdescription,
      docparty: payload.formdata.docparty,
      doccounterparty: payload.formdata.doccounterparty,
      archivenotes: payload.formdata.archivenotes,
      executedcontract: payload.formdata.executedcontract,
      executeddate: payload.formdata.executeddate,
      startdate: payload.formdata.startdate,
      enddate: payload.formdata.enddate,
      clausetopics: payload.formdata.clausetopics,
    })

    store.dispatch('dblogdoc/addLogDoc', {
      docid: payload.docid,
      uniqid: payload.uniqid,
      logdetail: {
        action: 'DOCEVENTS.UPDATEARCHIVEDATA',
        detail: payload.formdata.docname,
        context: '',
      },
    })
  },

  openDocMeta({ commit, state }, payload) {
    DBgetobjsync(TABLENAME, payload.docid).then(function (doc) {
      if (doc.exists) {
        store.dispatch('panels/setOpenDocMeta', {
          panelno: payload.panelno,
          docobj: doc.data,
        })
        // log
        store.dispatch('dblogdoc/addLogDoc', {
          docid: payload.docid,
          uniqid: payload.uniqid,
          logdetail: {
            action: 'DOCEVENTS.OPENDOCUMENT',
            detail: doc.data.docname,
            context: payload.docid,
          },
        })
      }
    })
  },

  openHUBDocMeta({ commit, state }, payload) {
    DBHUBgetdocmetadata(payload.docid).then(function (doc) {
      if (doc.exists) {
        store.dispatch('panels/setOpenHUBDocMeta', {
          panelno: payload.panelno,
          docobj: doc.data,
        })

        // log
        store.dispatch('dblogdoc/addLogUserOnly', {
          uniqid: payload.uniqid,
          logdetail: {
            action: 'HUB.DOCEVENTS.OPENDOCUMENT',
            detail: doc.data.docname,
            context: payload.docid,
          },
        })
      }
    })
  },

  closeDocMeta({ commit, state }, payload) {
    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: payload.docid,
      uniqid: payload.uniqid,
      logdetail: {
        action: 'DOCEVENTS.CLOSEDOCUMENT',
        detail: payload.docname,
        context: payload.docid,
      },
    })
  },

  addNoteDocMeta({ commit, state }, payload) {
    let noteadd = payload
    noteadd.uniqid = payload.uniqid
    noteadd.docid = payload.docid
    noteadd.timestamp = new Date()

    if (payload.ifHUBType) {
      DBHUBDocMetaAddNotes(payload.docid, 'docnotes', noteadd)
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.ADDNOTE',
          detail: payload.docid,
          context: 'docid:' + payload.docid,
        },
      })
    } else {
      DBupdateobjarrayObjUnion(TABLENAME, payload.docid, 'docnotes', noteadd)
      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.ADDNOTE',
          detail: payload.note,
          context: '',
        },
      })
    }
  },

  addCollaboratorDocMeta({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBDocMetaParticipantsAdd(
        payload.docid,
        'externalcollaborators',
        payload.email
      )
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.ADDCOLLABORATOR',
          detail: payload.email,
          context: 'docid:' + payload.docid,
        },
      })
    } else {
      DBupdateobjarrayUnion(
        TABLENAME,
        payload.docid,
        'externalcollaborators',
        payload.email
      )
      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.ADDCOLLABORATOR',
          detail: payload.email,
          context: '',
          addcollab: true,
        },
      })
    }
  },

  deleteCollaboratorDocMeta({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBDocMetaParticipantsRemove(
        payload.docid,
        'externalcollaborators',
        payload.email
      )
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.DELETECOLLABORATOR',
          detail: payload.email,
          context: 'docid:' + payload.docid,
        },
      })
    } else {
      DBupdateobjarrayRemove(
        TABLENAME,
        payload.docid,
        'externalcollaborators',
        payload.email
      )

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.DELETECOLLABORATOR',
          detail: payload.email,
          context: '',
        },
      })
    }
  },

  addNegotiatorDocMeta({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBDocMetaParticipantsAdd(
        payload.docid,
        'externalnegotiators',
        payload.email
      )
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.ADDNEGOTIATOR',
          detail: payload.email,
          context: 'docid:' + payload.docid,
        },
      })
    } else {
      DBupdateobjarrayUnion(
        TABLENAME,
        payload.docid,
        'externalnegotiators',
        payload.email
      )

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.ADDNEGOTIATOR',
          detail: payload.email,
          context: '',
          addnegot: true,
        },
      })
    }
  },

  deleteNegotiatorDocMeta({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBDocMetaParticipantsRemove(
        payload.docid,
        'externalnegotiators',
        payload.email
      )
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.DELETENEGOTIATOR',
          detail: payload.email,
          context: 'docid:' + payload.docid,
        },
      })
    } else {
      DBupdateobjarrayRemove(
        TABLENAME,
        payload.docid,
        'externalnegotiators',
        payload.email
      )

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.DELETENEGOTIATOR',
          detail: payload.email,
          context: '',
        },
      })
    }
  },

  addOwnerDocMeta({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBDocMetaParticipantsAdd(payload.docid, 'ownerid', payload.email)
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.ADDOWNER',
          detail: payload.email,
          context: 'docid:' + payload.docid,
        },
      })
    } else {
      DBupdateobjarrayUnion(TABLENAME, payload.docid, 'ownerid', payload.email)

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.ADDOWNER',
          detail: payload.email,
          context: '',
        },
      })
    }
  },

  deleteOwnerDocMeta({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBDocMetaParticipantsRemove(payload.docid, 'ownerid', payload.email)
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.DELETEOWNER',
          detail: payload.email,
          context: 'docid:' + payload.docid,
        },
      })
    } else {
      DBupdateobjarrayRemove(TABLENAME, payload.docid, 'ownerid', payload.email)

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.DELETEOWNER',
          detail: payload.email,
          context: '',
        },
      })
    }
  },

  saveCollaborationDocMeta({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBdocmetaupdate(payload.docid, {
        collabstatus: payload.formdata.collabstatus,
        collabend: payload.formdata.collabend,
        collabrules: payload.formdata.collabrules,
      })
    } else {
      DBupdateobj(TABLENAME, payload.docid, {
        collabstatus: payload.formdata.collabstatus,
        collabend: payload.formdata.collabend,
        collabrules: payload.formdata.collabrules,
      })

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.CHANGECOLLABORATION',
          detail: 'TO:' + payload.formdata.collabstatus,
          context: '',
        },
      })
    }
  },

  saveNegotiationDocMeta({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBdocmetaupdate(payload.docid, {
        negotiationstatus: payload.formdata.negotiationstatus,
        negotiationend: payload.formdata.negotiationend,
        negotiationrules: payload.formdata.negotiationrules,
      })
    } else {
      DBupdateobj(TABLENAME, payload.docid, {
        negotiationstatus: payload.formdata.negotiationstatus,
        negotiationend: payload.formdata.negotiationend,
        negotiationrules: payload.formdata.negotiationrules,
      })

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.CHANGENEGOTIATION',
          detail: 'TO:' + payload.formdata.negotiationstatus,
          context: '',
        },
      })
    }
  },

  // not used
  writeCollaborationInviteDocMeta({ commit, state }, payload) {
    DBupdateobjarrayUnion(
      TABLENAME,
      payload.docid,
      'externalcollaborators',
      payload.externalcollaborators
    )
  },

  // used to delete the documents in open screens.
  deleteDocMeta({ commit, state }, payload) {
    DBdeactivate(TABLENAME, payload.docid)
  },

  // used to delete the documents in open screens.
  deleteDocMetaSync({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      DBupdateobjsync(TABLENAME, payload.docid, {
        active: false,
        delete: new Date(),
      })
        .then(resolve('doneaa'))

        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },

  saveAsPlaybookDocMeta({ commit, state }, payload) {
    DBupdateobj(TABLENAME, payload.docid, {
      doccategory: 'PLAYBOOK',
    })

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: payload.docid,
      uniqid: payload.uniqid,
      logdetail: {
        action: 'DOCEVENTS.SAVEPLAYBOOK',
        detail: '',
        context: '',
      },
    })
  },
  saveAsTemplateDocMeta({ commit, state }, payload) {
    DBupdateobj(TABLENAME, payload.docid, {
      doccategory: 'TEMPLATE',
      docname: payload.formdata.docname,
      docdescription: payload.formdata.docdescription,
      labelinputdata: payload.formdata.labelinputdata,
    })

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: payload.docid,
      uniqid: payload.uniqid,
      logdetail: {
        action: 'DOCEVENTS.SAVETEMPLATE',
        detail: '',
        context: '',
      },
    })
  },
  saveAsArchiveDocMeta({ commit, state }, payload) {
    // if option to exclude, then do that ahead of time to the docmeta document.
    if (payload.excludecollaborators) {
      DBupdateobj(TABLENAME, payload.docid, {
        externalcollaborators: [],
      })
    }

    if (payload.excludenegotiators) {
      DBupdateobj(TABLENAME, payload.docid, {
        externalnegotiators: [],
      })
    }

    DBupdateobj(TABLENAME, payload.docid, {
      doccategory: 'ARCHIVE',
      archivenotes: payload.archivenotes,
      executedcontract: payload.executedcontract,
      executeddate: payload.executeddate,
      startdate: payload.startdate,
      enddate: payload.enddate,
      clausetopics: payload.clausetopics,
      excludecollaborators: payload.excludecollaborators,
      excludenegotiators: payload.excludenegotiators,
    })

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: payload.docid,
      uniqid: payload.uniqid,
      logdetail: {
        action: 'DOCEVENTS.SAVEARCHIVE',
        detail: '',
        context: '',
      },
    })
  },
  attachContracttoArchiveDocMeta({ commit, state }, payload) {
    DBupdateobj(TABLENAME, payload.docid, {
      contractfilename: payload.filename,
      contractdownloadURL: payload.downloadURL,
      contractename: payload.ename,
      contractattached: true,
    })

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: payload.docid,
      uniqid: payload.uniqid,
      logdetail: {
        action: 'DOCEVENTS.ATTACHCONTRACT',
        detail: payload.ename,
        context: '',
      },
    })
  },

  // SYNCHRONOUS
  // delete a specific docmeta (mark as not active)
  deleteSyncDocMeta({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      DBupdateobjsync(TABLENAME, payload.docid, {
        active: false,
        delete: new Date(),
      })
        .then(resolve(true))
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },

  // SYNCHRONOUS
  // gets docmeta and returns it
  getDocMeta({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      DBgetobjsync(TABLENAME, payload.docid)
        .then(function (doc) {
          if (doc.exists) {
            resolve(doc)
          } else {
            resolve(false)
          }
        })
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },

  // SYNCHRONOUS
  // write a docmeta to an id'ed docid
  writeToDocSyncDocMeta({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      DBsetonesync(TABLENAME, payload.id, payload.data)
        .then((doc) => resolve(doc))
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },

  // SYNCHRONOUS
  // write a docmeta to an id'ed docid
  updateDocSyncDocMeta({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      DBupdateobjsync(TABLENAME, payload.id, payload.update)
        .then((doc) => resolve(doc))
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },
}
