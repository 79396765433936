<script>
/* eslint-disable */
import {
  panelComputed,
  dbauthComputed,
  dbdoceditComputed,
} from '@state/helpers'

import {
  splitSentences,
  formatDateDialog,
  statusIndicator,
  displayUserName,
  displayUserInfo,
} from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

import SnackService from '@/src/services/SnackService'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    actionpara: {
      type: Object,
      required: true,
    },
    dialogtype: {
      type: String,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    showDeleted: false,
    docedittext: '',
    autoapproveoption: true, // option only available for owners to automatically approve docedit.
  }),
  computed: {
    ...panelComputed,
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
        this.docedittext = ''
      },
    },
    cardtitle() {
      return this.dialogtype + ' : ' + this.$t('DIALOGS.DOCEDITS')
    },
    paragraphtext() {
      return this.actionpara.OT
    },
    paragraphindex() {
      return this.actionpara.ind
    },
    paragraphindexstr() {
      if (this.actionpara.ind !== undefined) {
        return this.actionpara.ind.toString()
      }
      return ''
    },
    sentences() {
      return splitSentences(this.actionpara.OT)
    },

    filteredDocEdits() {
      // Check if empty
      if (
        Array.isArray(this.editDocedits[this.panelno]) &&
        this.editDocedits[this.panelno].length > 0
      ) {
        if (this.dialogtype === 'COLLABORATION') {
          return this.editDocedits[this.panelno]
            .filter((e) => e.type === this.dialogtype)
            .filter((e) => e.paraid === this.actionpara.ind)
          // NEGOTIATION
        } else {
          // negotiation includes collaboration docedits that have been approved by owner.
          return this.editDocedits[this.panelno]
            .filter(
              (e) =>
                e.type === this.dialogtype ||
                (e.type === 'COLLABORATION' && e.approved)
            )
            .filter((e) => e.paraid === this.actionpara.ind)
        }
      } else {
        return []
      }
    },

    docedits() {
      if (this.showDeleted) {
        return this.filteredDocEdits
      } else {
        return this.filteredDocEdits.filter((e) => e.active === true)
      }
    },

    availDocEdits() {
      return ['REPLACE', 'DELETE', 'ADD-AFTER']
    },
  },
  methods: {
    ...dbdoceditComputed,

    addDocEdit(docedit) {
      if (this.docedittext.length > 10000) {
        SnackService.info('MESSAGES.DOCEDITTOOLONG')
        return
      }
      // negotiators do not have a separate approval process.
      if (this.localpanelstate.negotiatorPriv) {
        this.writeApprovedDocEdit({
          docid: this.aPIDjobID[this.panelno],
          paraid: this.paragraphindex,
          sentid: docedit.sentid,
          // added senttext
          senttext: this.sentences.find(
            (e) => e.key === parseInt(docedit.sentid)
          ).sentence,
          docedittype: docedit.docedittype,
          docedittext: docedit.docedittype === 'DELETE' ? '' : this.docedittext,
          type: this.dialogtype,
          uniqid: this.dbauthuser.uniqid,

          // this is filled in by payload if preapproved.
          approver: '',
          approvedtime: '',
          approved: false,
          negotiatorapprover: this.dbauthuser.uniqid,
          negotiatorapprovedtime: new Date(),
          negotiatorapproved: true,
          ifHUBType: this.localpanelstate.ifHUBType,
        })
        return
      }

      // owners
      if (this.localpanelstate.ownerPriv && this.autoapproveoption) {
        this.writeApprovedDocEdit({
          docid: this.aPIDjobID[this.panelno],
          paraid: this.paragraphindex,
          sentid: docedit.sentid,
          // added senttext
          senttext: this.sentences.find(
            (e) => e.key === parseInt(docedit.sentid)
          ).sentence,
          docedittype: docedit.docedittype,
          docedittext: docedit.docedittype === 'DELETE' ? '' : this.docedittext,
          type: this.dialogtype,
          uniqid: this.dbauthuser.uniqid,

          // this is filled in by payload if preapproved.
          approver: this.dbauthuser.uniqid,
          approvedtime: new Date(),
          approved: true,
          negotiatorapprover: '',
          negotiatorapprovedtime: '',
          negotiatorapproved: false,
          ifHUBType: this.localpanelstate.ifHUBType,
        })
        return
      }

      // else, normal - just write the docedit and look for approval later.
      this.writeDocEdit({
        docid: this.aPIDjobID[this.panelno],
        paraid: this.paragraphindex,
        sentid: docedit.sentid,
        // added senttext
        senttext: this.sentences.find((e) => e.key === parseInt(docedit.sentid))
          .sentence,
        docedittype: docedit.docedittype,
        docedittext: docedit.docedittype === 'DELETE' ? '' : this.docedittext,
        type: this.dialogtype,
        uniqid: this.dbauthuser.uniqid,
      }).then(() => this.$emit('syncdocedits'))

      return true
    },

    delDocEdit(docedit) {
      this.deleteDocEdit({
        docid: this.aPIDjobID[this.panelno],
        uniqid: this.dbauthuser.uniqid,
        doceditid: docedit.keyid,
        ifHUBType: this.localpanelstate.ifHUBType,
      }).then(() => this.$emit('syncdocedits'))

      return true
    },

    // these functions call the utils - not sure why it's not picking up immediately.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    statusIndicator(bool) {
      return statusIndicator(bool)
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },

    // need to implement check to see if someone can delete a comment.
    deleteok(uniqid) {
      return this.dbauthuser.uniqid in uniqid
    },
    // docedit, user, date
    doceditsent(sentid) {
      const doceditArray = this.docedits
        .filter((e) => e.paraid === this.actionpara.ind)
        .filter((e) => e.sentid === sentid.toString())
        .filter((e) => e.active === true)
      return doceditArray
    },

    // returns the array of available docedits to add or delete.
    // limited to one of each type for each sentence.
    doceditAvailable(sentid) {
      const doceditArray = this.doceditsent(sentid).filter(
        (e) => e.uniqid === this.dbauthuser.uniqid
      )
      var addArray = []
      var delArray = []
      var position = -1
      this.availDocEdits.forEach(function(e) {
        position = doceditArray.findIndex((f) => f.docedittype === e)
        if (position < 0) {
          addArray.push({ docedittype: e, sentid: sentid.toString() })
        } else {
          delArray.push({
            obj: doceditArray[position],
            sentid: sentid.toString(),
          })
        }
      })
      return { addArray: addArray, delArray: delArray }
    },

    copySentencetoBox(sentence) {
      this.docedittext = sentence
    },

    displayDocEdit(docedit) {
      var ownerStatus = ''
      var negotiatorStatus = ''

      if (docedit.approved) {
        ownerStatus = 'APPROVED'
      } else if (docedit.approver === '') {
        ownerStatus = 'PENDING'
      } else {
        ownerStatus = 'REJECTED'
      }

      if (docedit.negotiatorapproved) {
        negotiatorStatus = 'APPROVED'
      } else if (docedit.negotiatorapprover === '') {
        negotiatorStatus = 'PENDING'
      } else {
        negotiatorStatus = 'REJECTED'
      }

      const returnString =
        '<b>' +
        docedit.docedittype +
        ' by ' +
        this.displayUserName(docedit.uniqid) +
        ':' +
        '</b>' +
        docedit.docedittext +
        '\n' +
        'owner:' +
        '<b>' +
        ownerStatus +
        '</b>' +
        '\n negotiator:' +
        '<b>' +
        negotiatorStatus +
        '</b>'

      return returnString
    },
  },
}
</script>

<template>
  <v-row>
    <v-dialog
      v-model="showDialog"
      persistent
      :content-class="$style.dialogWidth"
    >
      <v-card>
        <PanelCardTitle
          :cardtitle="cardtitle"
          :optnumber="paragraphindexstr"
        ></PanelCardTitle>
        <div>
          <v-form>
            <v-container>
              <v-row>
                <v-flex xs12>
                  <h5 :class="$style.dialogTextStyle">
                    {{ actionpara.OT }}
                  </h5>
                </v-flex>
                <v-simple-table :class="$style.dialogTable" fixed-header>
                  <template>
                    <thead :class="$style.dialogHead">
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogSentNumStatus"
                          >{{ $t('DIALOGS.NUMBER') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogDocEdits"
                          >{{ $t('DIALOGS.SENTENCE') }}</th
                        >
                        <th
                          v-if="!localpanelstate.ifArchivedState"
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.EDIT') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogDocEdits"
                          >{{ $t('DIALOGS.DOCEDITS') }}</th
                        >
                        <th
                          v-if="!localpanelstate.ifArchivedState"
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.ACTIONS') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in sentences"
                        :key="item.key"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogSentNumStatus">{{
                          item.key
                        }}</td>
                        <td :class="$style.dialogDocEdits">{{
                          item.sentence
                        }}</td>
                        <td
                          v-if="!localpanelstate.ifArchivedState"
                          :class="$style.dialogDocEdits"
                        >
                          <v-btn
                            small
                            class="white--text black"
                            @click="copySentencetoBox(item.sentence)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn></td
                        >

                        <td :class="$style.dialogDocEdits">
                          <v-list>
                            <v-list-item
                              v-for="docedit in doceditsent(item.key)"
                              :key="docedit.keyid"
                            >
                              <v-list-item-title>
                                <p class="text-wrap">
                                  <span v-html="displayDocEdit(docedit)">
                                  </span>
                                </p>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </td>
                        <td
                          v-if="!localpanelstate.ifArchivedState"
                          :class="$style.dialogCellStatus"
                        >
                          <v-row class="my-2">
                            <v-menu
                              v-if="
                                doceditAvailable(item.key).addArray.length > 0
                              "
                              offset-x
                            >
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item2 in doceditAvailable(item.key)
                                    .addArray"
                                  :key="item2.docedittype"
                                  @click="addDocEdit(item2)"
                                >
                                  <v-list-item-title>{{
                                    item2.docedittype
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                          <v-row class="my-2">
                            <v-menu
                              v-if="
                                doceditAvailable(item.key).delArray.length > 0
                              "
                              offset-x
                            >
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  small
                                  class="white--text black"
                                  v-on="{ ...tooltip }"
                                >
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item2 in doceditAvailable(item.key)
                                    .delArray"
                                  :key="item2.obj.docedittype"
                                  @click="delDocEdit(item2.obj)"
                                >
                                  <v-list-item-title>{{
                                    item2.obj.docedittype
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <div v-if="!localpanelstate.ifArchivedState">
          <v-container align-center>
            <v-flex xs12 md12 lg12 xl12>
              <v-textarea
                v-model="docedittext"
                :placeholder="$t('MESSAGES.INPUTDOCEDITSHERE')"
                outlined
                clearable
                solo
                height="200px"
                :class="$style.dialogInputText"
                :label="$t('DIALOGS.DOCEDITS')"
              >
                ></v-textarea
              >
            </v-flex>
          </v-container>
        </div>
        <v-card-actions class="justify-end">
          <div v-if="dialogtype === 'COLLABORATION'" class="mx-5">
            {{ $t('INFO_MESSAGES.COLLABORATIONNOTE') }}
          </div>
          <div v-if="dialogtype === 'NEGOTIATION'" class="mx-5">
            {{ $t('INFO_MESSAGES.NEGOTIATIONNOTE') }}
          </div>
          <v-spacer></v-spacer>
          <v-checkbox
            v-if="localpanelstate.ownerPriv && !localpanelstate.ifArchivedState"
            v-model="autoapproveoption"
            :label="$t('MESSAGES.AUTOAPPROVEASOWNER')"
          ></v-checkbox>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
