<script>
import { panelComputed, dbauthComputed } from '@state/helpers'
import {
  formatDateDialog,
  statusIndicator,
  displayDocType,
  displayDocID,
  displayUserName,
  displayUserInfo,
  docroute,
} from '@utils/dialogutils'

import { isEmpty } from 'lodash'

import PanelCardTitle from './panel-cardtitle.vue'
import ConfirmAction from '@components/panel/para-dialog-confirmaction.vue'

export default {
  components: {
    ConfirmAction,
    PanelCardTitle,
  },
  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    confirmDelete: false,
    indexDoc: '',

    sortcolumn: 'create',
    sortascend: false,
  }),
  computed: {
    ...panelComputed,
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },

    doclist() {
      if (
        Array.isArray(this.dbauthAvailArchiveDocs) &&
        this.dbauthAvailArchiveDocs.length > 0
      ) {
        return this.dbauthAvailArchiveDocs.filter((e) => e.active === true)
      } else {
        return []
      }
    },

    sorteddoclist() {
      var sorteddoclist = [...this.doclist]
      return sorteddoclist.sort(this.compare(this.sortcolumn, this.sortascend))
    },
  },
  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
  },

  methods: {
    setInitialValues() {
      this.refreshDocList()
    },

    setSortColumn(column) {
      this.sortcolumn = column
      this.sortascend = !this.sortascend
    },

    // ascend changes the direction of the comparison
    compare(value, ascend) {
      if (ascend) {
        return function(a, b) {
          if (a[value] > b[value]) {
            return 1
          } else {
            return -1
          }
        }
      } else {
        return function(a, b) {
          if (a[value] > b[value]) {
            return -1
          } else {
            return +1
          }
        }
      }
    },

    openDoc(index) {
      // if there is already a document, then first "close the document", which logs the exiting of previous document
      if (!isEmpty(this.docRawData[this.panelno])) {
        this.$emit('close-document')
      }
      this.$emit('open-document', this.sorteddoclist[index].keyid.toString())
    },

    confirmDeleteIndex(index) {
      this.indexDoc = index
      this.confirmDelete = true
    },

    deleteDoc() {
      const MyThis = this

      // dbdocmeta
      //   .doc(MyThis.sorteddoclist[MyThis.indexDoc].keyid.toString())
      //   .update({
      //     active: false,
      //     delete: new Date(),
      //   })
      MyThis.$store
        .dispatch('dbdocmeta/deleteSyncDocMeta', {
          docid: MyThis.sorteddoclist[MyThis.indexDoc].keyid.toString(),
        })

        .then(MyThis.refreshDocList())

        .catch(function(e) {
          MyThis.$store.dispatch('dberror/logError', e)
        })
    },

    // deleteDoc() {
    //   this.$store.dispatch('dbdocmeta/deleteDocMeta', {
    //     docid: this.dbauthAvailArchiveDocs[this.indexDoc].keyid.toString(),
    //   })
    //   setTimeout(this.refreshDocList(), 3000)
    // },

    // relay to use utility functions.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    displayDocTypeAct(d) {
      return displayDocType(d, this.dbauthDocTypes)
    },
    formatDelineatedArray(d) {
      return d
        .slice(Math.max(d.length - 2, 1))
        .map((e) => e.note)
        .join('\n')
    },
    statusIndicator(bool) {
      return statusIndicator(bool)
    },
    displayDocID(d) {
      return displayDocID(d)
    },
    displayPrivilege(d) {
      switch (d) {
        // FILE MENU
        case 'owner':
          return this.$t('DIALOGS.OWNER')
        case 'archive(owner)':
          return this.$t('DIALOGS.OWNER')
        case 'collaborator':
          return this.$t('DIALOGS.COLLABORATOR')
        case 'archive(group)':
          return this.$t('DIALOGS.COLLABORATORGROUP')
        case 'negotiator':
          return this.$t('DIALOGS.NEGOTIATOR')
        case 'owner(group)':
          return this.$t('DIALOGS.OWNERGROUP')
        case 'collaborator(group)':
          return this.$t('DIALOGS.COLLABORATORGROUP')
        default:
          return ''
      }
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },
    docroute(d) {
      return docroute(d)
    },

    deleteok(uniqid) {
      return uniqid.includes(this.dbauthuser.uniqid)
    },
    refreshDocList() {
      this.$store.dispatch('dbauth/getUserAvailArchiveDocsAuth')
    },
  },
}
</script>

<template>
  <v-row>
    <ConfirmAction
      :dialog="confirmDelete"
      :confirmationmessage="$t('INFO_MESSAGES.CONFIRMFILEDELETE')"
      @close-dialog="confirmDelete = false"
      @confirm="deleteDoc"
    />
    <v-dialog
      v-model="showDialog"
      persistent
      :content-class="$style.dialogWidth"
    >
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.OPENARCHIVEDOCUMENT')"
        ></PanelCardTitle>
        <div>
          <v-form>
            <v-container fluid>
              <v-row justify="center">
                <v-simple-table :class="$style.dialogOpenTable" fixed-header>
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                      fixed-header
                    >
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellDelete"
                          >{{ $t('DIALOGS.OPEN') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('privilege')"
                          >{{ $t('DIALOGS.PRIVILEGE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('ownerid')"
                          >{{ $t('DIALOGS.OWNERID') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          @click="setSortColumn('doctypeid')"
                          >{{ $t('DIALOGS.DOCTYPE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          @click="setSortColumn('docid')"
                          >{{ $t('DIALOGS.DOCID') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          @click="setSortColumn('docname')"
                          >{{ $t('DIALOGS.DOCNAME') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          @click="setSortColumn('docdescription')"
                          >{{ $t('DIALOGS.DOCDESCRIPTION') }}</th
                        >

                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          @click="setSortColumn('docnotes')"
                          >{{ $t('DIALOGS.DOCNOTES') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          @click="setSortColumn('docparty')"
                          >{{ $t('DIALOGS.DOCPARTY') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          @click="setSortColumn('doccounterparty')"
                          >{{ $t('DIALOGS.DOCCOUNTERPARTY') }}</th
                        >

                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellDelete"
                          @click="setSortColumn('create')"
                          >{{ $t('DIALOGS.TIME') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellDelete"
                          @click="setSortColumn('archivenotes')"
                          >{{ $t('DIALOGS.ARCHIVENOTES') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('executedcontract')"
                          >{{ $t('DIALOGS.EXECUTEDCONTRACT') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('contractattached')"
                          >{{ $t('DIALOGS.CONTRACTATTACHED') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('executeddate')"
                          >{{ $t('DIALOGS.EXECUTEDDATE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('startdate')"
                          >{{ $t('DIALOGS.STARTDATE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          @click="setSortColumn('enddate')"
                          >{{ $t('DIALOGS.ENDDATE') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellDelete"
                          >{{ $t('DIALOGS.DELETE') }}</th
                        >
                      </tr>
                    </thead>

                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="(item, index) in sorteddoclist"
                        :key="item.docid"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellDelete"
                          ><v-btn x-small text @click="openDoc(index)">
                            <v-icon>mdi-folder-open-outline</v-icon>
                          </v-btn></td
                        >

                        <td :class="$style.dialogCellStatus">{{
                          displayPrivilege(item.privilege)
                        }}</td>
                        <td :class="$style.dialogCellStatus">
                          <v-list>
                            <v-list-item v-for="it in item.ownerid" :key="it">
                              <v-list-item-subtitle
                                ><v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{
                                      displayUserName(it)
                                    }}</span>
                                  </template>
                                  <span>{{ displayUserInfo(it) }}</span>
                                </v-tooltip></v-list-item-subtitle
                              >
                            </v-list-item>
                          </v-list>
                        </td>
                        <td :class="$style.dialogCellStatus">{{
                          displayDocTypeAct(item.doctypeid)
                        }}</td>
                        <td :class="$style.dialogBigCellStatus">{{
                          displayDocID(item.docid)
                        }}</td>
                        <td
                          :class="$style.dialogBigCellStatus"
                          style="word-wrap: break-word;"
                          >{{ item.docname }}</td
                        >
                        <td
                          :class="$style.dialogBigCellStatus"
                          style="word-wrap: break-word;"
                          >{{ item.docdescription }}</td
                        >

                        <td
                          :class="$style.dialogBigCellStatus"
                          style="word-wrap: break-word;"
                          >{{ formatDelineatedArray(item.docnotes) }}</td
                        >
                        <td
                          :class="$style.dialogCellWidth"
                          style="word-wrap: break-word;"
                          >{{ item.docparty }}</td
                        >
                        <td
                          :class="$style.dialogCellWidth"
                          style="word-wrap: break-word;"
                          >{{ item.doccounterparty }}</td
                        >
                        <td :class="$style.dialogCellDelete">{{
                          formatDateDialog(item.create)
                        }}</td>
                        <td
                          :class="$style.dialogCellDelete"
                          style="word-wrap: break-word;"
                        >
                          {{ item.archivenotes }}
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <v-icon
                            :color="
                              statusIndicator(item.executedcontract).color
                            "
                            >{{
                              statusIndicator(item.executedcontract).icon
                            }}</v-icon
                          >
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <v-icon
                            :color="
                              statusIndicator(item.contractattached).color
                            "
                            >{{
                              statusIndicator(item.contractattached).icon
                            }}</v-icon
                          >
                        </td>
                        <td :class="$style.dialogCellStatus">
                          {{ item.executeddate }}
                        </td>
                        <td :class="$style.dialogCellStatus">
                          {{ item.startdate }}
                        </td>
                        <td :class="$style.dialogCellStatus">
                          {{ item.enddate }}
                        </td>
                        <td :class="$style.dialogCellDelete">
                          <v-btn
                            v-if="deleteok(item.ownerid)"
                            x-small
                            text
                            @click="confirmDeleteIndex(index)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>

        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('file/openarchivedocument')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn large outlined class="mx-4" @click="refreshDocList">{{
            $t('DIALOGS.REFRESH')
          }}</v-btn>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
