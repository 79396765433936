import StorageService from '@services/StorageService'

export const state = {
  snack: {
    message: StorageService.getItem('snackbar.snack.message'),
    type: StorageService.getItem('snackbar.snack.type'),
    show: StorageService.getItem('snackbar.snack.show'),
    length: StorageService.getItem('snackbar.snack.length'),
  },
}

export const getters = {
  getSnack(state) {
    return state.snack
  },
}

export const mutations = {
  SET_SNACK(state, snack) {
    state.snack = snack
    StorageService.setItem('snackbar.snack', snack)
  },
}

export const actions = {
  setSnackbar({ commit }, snack) {
    commit('SET_SNACK', {
      message: snack.message,
      type: snack.type,
      show: true,
      length: snack.length,
    })
  },
  resetSnackbar({ commit }) {
    commit('SET_SNACK', { message: '', type: '', show: false, length: -1 })
  },
}
