<script>
import { dbauthComputed } from '@state/helpers'

import {
  formatDateDialog,
  displayUserName,
  displayUserInfo,
  docroute,
} from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },

  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    panelstated: {
      type: Object,
      default: () => {},
    },
    docmetaobj: {
      type: Object,
      default: () => {},
    },

    templateorigdocname: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDebug: false,
      Debug: false,
    }
  },

  computed: {
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },

    documentType() {
      if (this.localpanelstate.ifPlaybook) {
        return 'PLAYBOOK'
      }
      if (this.localpanelstate.ifTemplate) {
        return 'TEMPLATE'
      }
      if (this.localpanelstate.ifArchivedState) {
        return 'ARCHIVEDOC'
      }
      return 'DOCUMENT'
    },

    documentTypeDisplay() {
      return this.$t('DIALOGS.' + this.documentType)
    },

    docFromTemplate() {
      return this.documentType === 'DOCUMENT' && this.templateorigid !== ''
    },
  },
  methods: {
    // relay to use utility functions.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    docroute(d) {
      return docroute(d)
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },

    displayPrivilege(priv) {
      var returnval = ''
      if (priv.includes ('O')){
        returnval += 'OWNER'
      }
      if (priv.includes ('C')){
        returnval += 'COLLABORATOR'
      }
      if (priv.includes ('N')){
        returnval += 'NEGOTIATOR'
      }
      if (priv.includes ('G')){
        returnval += '-GROUP'
      }
      if (priv.includes ('H')){
        returnval += '-HUB'
      }
      return returnval
    },

    displayDocState(docstate) {
      switch (docstate) {
        case 'A':
          return 'ARCHIVE'
        case 'P':
          return 'PLAYBOOK'
        case 'CN':
          return 'COLLABORATION, NEGOTIATION'
        case 'C':
          return 'COLLABORATION ONLY'
        case 'N':
          return 'NEGOTIATION ONLY'
        default:
          return 'NONE'
      }
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.DOCUMENTINFO') + ' - ' + documentTypeDisplay"
        ></PanelCardTitle>

        <v-card-text>
          <v-container>
            <div v-if="!localpanelstate.ifDocLoaded">
              <p>
                {{ $t('MESSAGES.NODOCUMENTLOADED') }}
              </p>
            </div>
            <div v-if="localpanelstate.ifDocLoaded">
              <hr />
              <p
                ><b>{{ $t('DIALOGS.DOCUMENTDETAILS') }}</b></p
              >
              <p
                ><b>{{ $t('DIALOGS.NAME') }}:</b> {{ docmetaobj.docname }}
              </p>
              <p
                ><b>{{ $t('DIALOGS.DOCDESCRIPTION') }}:</b>
                {{ docmetaobj.docdescription }}
              </p>
              <p
                ><b>{{ $t('DIALOGS.DOCPARTY') }}:</b> {{ docmetaobj.docparty }}
              </p>
              <p
                ><b>{{ $t('DIALOGS.DOCCOUNTERPARTY') }}:</b>
                {{ docmetaobj.doccounterparty }}
              </p>
              <p
                ><b>{{ $t('DIALOGS.DOCID') }}:</b> {{ panelstated.docidD }}
              </p>
              <div v-if="localpanelstate.ifSaved">
                <p
                  ><b>{{ $t('DIALOGS.CREATIONDATE') }}:</b>
                  {{ formatDateDialog(docmetaobj.create) }}
                </p>
              </div>
              <div v-if="docFromTemplate">
                <p
                  ><b>{{ $t('DIALOGS.TEMPLATEORIGDOCNAME') }}</b>
                  {{ templateorigdocname }}
                </p>
              </div>

              <hr />
              <p
                ><b>{{ $t('DIALOGS.PARTICIPANTS') }}</b></p
              >
              <p
                ><b>{{ $t('DIALOGS.OWNERS') }}:</b></p
              >
              <li v-for="owner in docmetaobj.ownerid" :key="owner">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{
                      displayUserName(owner)
                    }}</span>
                  </template>
                  <span>{{ displayUserInfo(owner) }}</span>
                </v-tooltip>
              </li>
              <p></p>
              <p
                ><b>{{ $t('DIALOGS.COLLABORATORS') }}:</b></p
              >
              <li
                v-for="collaborator in docmetaobj.externalcollaborators"
                :key="collaborator"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{
                      displayUserName(collaborator)
                    }}</span>
                  </template>
                  <span>{{ displayUserInfo(collaborator) }}</span>
                </v-tooltip>
              </li>
              <p></p>
              <p
                ><b>{{ $t('DIALOGS.NEGOTIATORS') }}:</b></p
              >
              <ul>
                <li
                  v-for="negotiator in docmetaobj.externalnegotiators"
                  :key="negotiator"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{
                        displayUserName(negotiator)
                      }}</span>
                    </template>
                    <span>{{ displayUserInfo(negotiator) }}</span>
                  </v-tooltip>
                </li>
              </ul>
              <hr />
              <p></p>
              <p
                ><b>{{ $t('DIALOGS.COLLABORATIONANDNEGOTIATION') }}</b></p
              >
              <p>
                <b>{{ $t('DIALOGS.PRIVILEGE') }}:</b>
                {{ displayPrivilege(panelstated.privilegeD) }}</p
              >
              <p>
                <b>{{ $t('DIALOGS.STATUS') }}:</b>
                {{ displayDocState(panelstated.docStateD) }}</p
              >

              <div v-if="!localpanelstate.ifArchivedState">
                <p></p>
                <p>
                  <b>{{ $t('DIALOGS.COLLABORATIONSTATUS') }}:</b>
                  {{ docmetaobj.collabstatus }}</p
                >
                <div v-if="docmetaobj.collabstatus === 'OPEN'">
                  <p>
                    <b>{{ $t('DIALOGS.COLLABORATIONEND') }}:</b>
                    {{ docmetaobj.collabend }}
                  </p>
                </div>
                <p>
                  <b>{{ $t('DIALOGS.NEGOTIATIONSTATUS') }}:</b>
                  {{ docmetaobj.negotiationstatus }}</p
                >
                <div v-if="docmetaobj.negotiationstatus === 'OPEN'">
                  <p>
                    <b>{{ $t('DIALOGS.NEGOTIATIONEND') }}:</b>
                    {{ docmetaobj.negotiationend }}
                  </p>
                </div>
                <hr />
              </div>

              <hr />
              <div
                v-if="
                  localpanelstate.ifDocType &&
                    !localpanelstate.negotiatorPriv &&
                    localpanelstate.docTypeofDoc !== null
                "
              >
                <p
                  ><b>{{ $t('DIALOGS.DOCTYPEANDGROUPACCESS') }}</b></p
                >
                <p
                  ><b>{{ $t('DIALOGS.GROUPNAME') }}:</b>
                  {{ localpanelstate.docTypeofDoc.group }}
                </p>
                <p
                  ><b>{{ $t('DIALOGS.GROUPDESC') }}:</b>
                  {{ localpanelstate.docTypeofDoc.description }}
                </p>
                <p
                  ><b>{{ $t('DIALOGS.DOCTYPE') }}:</b>
                  {{ localpanelstate.docTypeDetailofDoc.doctype }}
                </p>
                <p
                  ><b>{{ $t('DIALOGS.DOCTYPEDESC') }}:</b>
                  {{ localpanelstate.docTypeDetailofDoc.description }}
                </p>
                <p></p>
                <p
                  ><b>{{ $t('DIALOGS.GROUPOWNERS') }}:</b></p
                >
                <ul>
                  <li
                    v-for="owner in localpanelstate.docTypeofDoc.ownerid"
                    :key="owner"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{
                          displayUserName(owner)
                        }}</span>
                      </template>
                      <span>{{ displayUserInfo(owner) }}</span>
                    </v-tooltip>
                  </li>
                </ul>
                <p></p>
                <p
                  ><b>{{ $t('DIALOGS.GROUPCOLLABORATORS') }}:</b></p
                >
                <ul>
                  <li
                    v-for="collaborator in localpanelstate.docTypeofDoc.uniqid"
                    :key="collaborator"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{
                          displayUserName(collaborator)
                        }}</span>
                      </template>
                      <span>{{ displayUserInfo(collaborator) }}</span>
                    </v-tooltip>
                  </li>
                </ul>
              </div>
              <hr />
              <div v-if="showDebug">
                <p>DERIVED STATES:</p>
                <p> ---- docmetaobj: {{ docmetaobj }} </p>
                <p> ---- localpanelstate: {{ localpanelstate }} </p>
                <p> ---- panelstated: {{ panelstated }} </p>

                <p>AUTH STATES:</p>
                <p> ---- dbauthuser: {{ dbauthuser }} </p>
                <p> ---- dbauthProfile: {{ dbauthProfile }} </p>
                <p> ---- dbauthGroupsAccess: {{ dbauthGroupsAccess }} </p>
                <p>---- dbauthPrivileges: {{ dbauthPrivileges }} </p>
                <p>---- dbauthPrivUsage: {{ dbauthPrivUsage }} </p>
                <p>---- dbauthDocTypes: {{ dbauthDocTypes }} </p>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('#profile')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            v-if="Debug"
            large
            outlined
            class="mx-4"
            @click="showDebug = !showDebug"
            >DEBUG</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
