import {
  COLLABORATIONLIST,
  NEGOTIATIONLIST,
  NOTIFICATIONLIST,
  DEFAULTTAGLIST,
} from '@constants/workflow'

//   [ "UNKNOWN", "PREAMBLE",  '...']
export function getCollaborationOptions() {
  // return COLLABORATIONLIST
  return Object.values(COLLABORATIONLIST).map((obj) => obj.long)
}
export function getNegotiationOptions() {
  return Object.values(NEGOTIATIONLIST).map((obj) => obj.long)
}
export function getNotificationOptions() {
  return Object.values(NOTIFICATIONLIST).map((obj) => obj.long)
}
export function getDefaultTagOptions() {
  return Object.values(DEFAULTTAGLIST).map((obj) => obj.long)
}
