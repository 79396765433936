// Contains all application constants //

export const ZH = {
  TOPMENU: {
    FILE: '文件',
    EDIT: '編輯',
    ACT: '行動',
    DOCS: '文件',
  },

  MENU: {
    NEWDOCUMENT: '新文档',
    // ORIGINAL TEXT: New Document
    SAVEDOCUMENT: '保存文档',
    // ORIGINAL TEXT: Save Document
    COPYDOCUMENT: '复制文档',
    // ORIGINAL TEXT: Copy Document
    CREATEVERSION: '创建干净版本',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: '从模板创建文档',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: '打开文档',
    // ORIGINAL TEXT: Open Document
    OPENGROUPDOCUMENT: '打开组文档',
    // ORIGINAL TEXT: Open Group Document
    OPENHUBDOCUMENT: '打开HUB文档',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: '打开归档文档',
    // ORIGINAL TEXT: Open Archive Document
    OPENPLAYBOOK: '打开使用手册',
    // ORIGINAL TEXT: Open Playbook         NOT SURE "PLAYBOOK" MEANS
    OPENTEMPLATE: '打开模板',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: '另存为模板',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: '另存为剧本',
    // ORIGINAL TEXT: Save As Playbook
    MOVETOARCHIVE: '移动到归档',
    // ORIGINAL TEXT: Move to Archive
    CLOSEDOCUMENT: '关闭文档',
    // ORIGINAL TEXT: Close Document
    DOCUMENTLOG: '文件日志',
    // ORIGINAL TEXT: Document Log

    GOTOPARAGRAPH: '转到段落',
    // ORIGINAL TEXT: Go to Paragraph
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: '文件说明',
    // ORIGINAL TEXT: Document Notes
    COLLABCOMMENTS: '合作协作 - 评论',
    // ORIGINAL TEXT: Collab - Comments 评论    does collab mean callaboration？ 合作、协作
    COLLABDOCEDITS: '合作协作 - DocEdits',
    // ORIGINAL TEXT: Collab - DocEdits
    COLLABRISKANDLABELS: '合作协作 - 风险和标签',
    // ORIGINAL TEXT: Collab - Risk and Labels
    NEGOTCOMMENTS: '谈判 - 评论',
    // ORIGINAL TEXT: Negot - Comments
    NEGOTDOCEDITS: 'Nagot - DocEdits 文档',
    // ORIGINAL TEXT: Negot - DocEdits

    COLLABORATION: '合作,协作',
    // ORIGINAL TEXT: Collaboration
    NEGOTIATION: '谈判',
    // ORIGINAL TEXT: Negotiation
    ADDOWNER: '添加所有者',
    // ORIGINAL TEXT: Add Owner
    ADDCOLLABORATOR: '添加合作者',
    // ORIGINAL TEXT: Add Collaborator
    ADDNEGOTIATOR: '添加谈判员',
    // ORIGINAL TEXT: Add Negotiator
    TRIGGERALERTS: '触发警报',
    // ORIGINAL TEXT: Trigger Alerts
    EXPORTEXCEL: '出口ExcEx',
    // ORIGINAL TEXT: Export Excel
    DOWNLOADSOURCE: '下载源',
    // ORIGINAL TEXT: Download Source
    SENDTOCLM: '发送到 CLM',
    // ORIGINAL TEXT: Send to CLM
    EXPORTJSON: '出口 JSON',
    // ORIGINAL TEXT: Export JSON
    ESIGNATURE: '已发送请电子签名',
    // ORIGINAL TEXT: Send for Esignature
    DOWNLOADREDLINE: '下载红线文档X',
    // ORIGINAL TEXT: Download Redline DocX
    DOCUMENTSETTINGS: '文档设置',
    // ORIGINAL TEXT: Document Settings
    EXPAND: '展开扩展',
    // ORIGINAL TEXT: Expand
    COLLAPSE: '折叠折叠',
    // ORIGINAL TEXT: Collapse

    TRANSLATETOENGLISH: '译为英文',
    // ORIGINAL TEXT: Translate to English
    ORIGINALLANGUAGE: '还原为原始',
    // ORIGINAL TEXT: Revert to Original

    ALLPARAGRAPHS: '所有段落',
    // ORIGINAL TEXT: All Paragraphs
    EDITEDPARAGRAPHSONLY: '仅编辑经编辑的段落',
    // ORIGINAL TEXT: Edited Paragraphs Only

    NOEDITCLAUSELABELS: '没有编辑条款标签',
    // ORIGINAL TEXT: No Edit Clause Labels
    EDITCLAUSELABELS: '编辑条款标签',
    // ORIGINAL TEXT: Edit Clause Labels

    HIDEEDITS: '隐藏编辑',
    // ORIGINAL TEXT: Hide Edits
    SHOWEDITS: '显示编辑',
    // ORIGINAL TEXT: Show Edits

    SHOWALLEDITS: '显示全部编辑',
    // ORIGINAL TEXT: Show All Edits
    SHOWNEGOTEDITSONLY: '只显示目录编辑',
    // ORIGINAL TEXT: Show Negot Edits Only

    GROUPS: '组群',
    // ORIGINAL TEXT: Groups
    DOCUMENTTYPES: '文件类型',
    // ORIGINAL TEXT: Document Types
    ALERTS: '警报警报',
    // ORIGINAL TEXT: Alerts
    QUICKSTARTTUTORIAL: '快速启动教学',
    // ORIGINAL TEXT: Quickstart Tutorial
    QUICKSTARTVIDEOS: '快速启动视频',
    // ORIGINAL TEXT: Quickstart Videos
    UPGRADESUBSCRIPTION: '更新订阅',
    // ORIGINAL TEXT: Upgrade Subscription
    SUBSCRIPTIONBILLING: '订阅/收费',
    // ORIGINAL TEXT: Subscription/Billing
    ACCOUNTSETTINGS: '账户设置',
    // ORIGINAL TEXT: Account Settings
    USERLOG: '用户日志',
    // ORIGINAL TEXT: User Log
    CHANGEPASSWORD: '更改密码',
    // ORIGINAL TEXT: Change Password
    LOGOUT: '退出',
    // ORIGINAL TEXT: Logout

    // HEADINGS
    ADMIN: '行政 行政',
    // ORIGINAL TEXT: ADMIN
    PROFILE: '概况',
    // ORIGINAL TEXT: PROFILE
    LPROFILE: '概况简介',
    // ORIGINAL TEXT: Profile
    FILE: '编辑',
    // ORIGINAL TEXT: FILE
    EDIT: '编辑 编辑 编辑 编辑',
    // ORIGINAL TEXT: EDIT
    ACT: '青',
    // ORIGINAL TEXT: ACT    need more conetext to translate to Chinese
    TOPIC: '专专题',
    // ORIGINAL TEXT: TOPIC
    DOCS: '数据控制系统',
    // ORIGINAL TEXT: DOCS

    // Field Headings in Navigation Bar
    NAME: '名称',
    // ORIGINAL TEXT: Name
    ROLE: '角色',
    // ORIGINAL TEXT: Role
    STATE: '状态',
    // ORIGINAL TEXT: State
    GROUP: '组群',
    // ORIGINAL TEXT: Group
    TYPE: '类型',
    // ORIGINAL TEXT: Type
    SENSIT: '敏感',
    // ORIGINAL TEXT: Sensit    does sensi mean sensitive？
    LTOPIC: '专题',
    // ORIGINAL TEXT: Topic
    USER: '用户',
    // ORIGINAL TEXT: User

    // Other Headings
    REGISTER: '注册',
    // ORIGINAL TEXT: REGISTER
    LOGIN: '登录',
    // ORIGINAL TEXT: LOGIN
    LREGISTER: '注册',
    // ORIGINAL TEXT: Register
    LLOGIN: '登录',
    // ORIGINAL TEXT: Login

    ADMINEXPLAIN: '行政能力',
    // ORIGINAL TEXT: Administrative capabilities
    PROFILEEXPLAIN: '账户管理、订阅、账单',
    // ORIGINAL TEXT: Account Management, Subscriptions, Billing

    // AUTH LEVELS
    PREMIUM: '保险费',
    // ORIGINAL TEXT: Premium
    PREMIUMPREPAID: '保险费(预付)',
    // ORIGINAL TEXT: Premium (Prepaid)
    BASIC: '基本',
    // ORIGINAL TEXT: Basic
    BASICPREPAID: '基本(预付)',
    // ORIGINAL TEXT: Basic (Prepaid)
    VIPACCESS: '贵宾入口',
    // ORIGINAL TEXT: VIP Access
    VIPMEMBER: '贵宾成员',
    // ORIGINAL TEXT: VIP Member
    UNLIMITED: '无限制',
    // ORIGINAL TEXT: Unlimited
    TRIALUSER: '试用用户',
    // ORIGINAL TEXT: Trial User
    REFERREDUSER: '首选用户',
    // ORIGINAL TEXT: Referred User
    SOURCEDOCUMENT: '源文件',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: '比較文件',
    // ORIGINAL TEXT: Comparison Document
    EXIT: '出口',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: '段落',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: '文件比較模式',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: '无 无',
    // ORIGINAL TEXT: NONE
    PREAMBLE: '预 数',
    // ORIGINAL TEXT: PREAMBLE
    DEFN: 'DEFN 排出',
    // ORIGINAL TEXT: DEFN
    PAYMENT: '缴款 缴款',
    // ORIGINAL TEXT: PAYMENT
    TAXES: '税',
    // ORIGINAL TEXT: TAXES
    REPWARR: '兵器',
    // ORIGINAL TEXT:REP WARR
    PERIOD: '期间',
    // ORIGINAL TEXT: PERIOD
    TERMINATION: '解雇',
    // ORIGINAL TEXT: TERMINATION
    CONFID: '机密',
    // ORIGINAL TEXT: CONFID
    INDEMNITY: '赔款',
    // ORIGINAL TEXT: INDEMNITY
    LIABILITY: '责任',
    // ORIGINAL TEXT: LIABILITY
    INTELPROP: '知识产权',
    // ORIGINAL TEXT: INTEL PROP
    SIGNATURE: '签字签字',
    // ORIGINAL TEXT: SIGNATURE
    OTHER: '其他',
    // ORIGINAL TEXT: OTHER
    DATASECURITY: '数据安全',
    // ORIGINAL TEXT: DATA SECURITY
    ASSIGN: '签字 签字',
    // ORIGINAL TEXT: ASSIGN
    NOTICE: '注意',
    // ORIGINAL TEXT: NOTICE
    GOVERNLAW: '法律法律',
    // ORIGINAL TEXT: GOVERN LAW
    DISPUTE: '争议',
    // ORIGINAL TEXT: DISPUTE
    ENTIREAGREE: '完全赞成',
    // ORIGINAL TEXT: ENTIRE AGREE
    SURVIVAL: '盈余',
    // ORIGINAL TEXT: SURVIVAL
    RELATPART: '专用部分',
    // ORIGINAL TEXT: RELAT PART
    THIRDPARTY: '第三方独立机构',
    // ORIGINAL TEXT: THIRD PARTY
    FORCEMAJEURE: '部队(主要部队)',
    // ORIGINAL TEXT: FORCE MAJEURE
    SEVERABILITY: '千分位数',
    // ORIGINAL TEXT: SEVERABILITY
    EXPORTCTRL: '出口',
    // ORIGINAL TEXT: EXPORT CTRL
    INTRO: '简介',
    // ORIGINAL TEXT: INTRO
    INSURANCE: '保险',
    // ORIGINAL TEXT: INSURANCE
    HEADING: '头 顶',
    // ORIGINAL TEXT: HEADING
    ALL: '全部全部',
    // ORIGINAL TEXT: ALL
  },

  VALIDMSGS: {
    EMAILREQUIRED: '需要电子邮件',
    // ORIGINAL TEXT: E-mail is required
    EMAILVALID: '电子邮件必须有效',
    // ORIGINAL TEXT: E-mail must be valid
    MAX20CHARS: '最大 20 个字符',
    // ORIGINAL TEXT: Max 20 characters
    MAX60CHARS: '最大60个字符数',
    // ORIGINAL TEXT: Max 60 characters
    MAX80CHARS: '最大 80 个字符',
    // ORIGINAL TEXT: Max 80 characters
    MAX160CHARS: '最大 160 个字符',
    // ORIGINAL TEXT: Max 160 characters
    EMAILALREADYOWNER: '电子邮件已经是所有者',
    // ORIGINAL TEXT: Email is already an owner
    EMAILALREADYCOLLABORATOR: '电子邮件已经是一个合作者',
    // ORIGINAL TEXT: Email is already a collaborator
    EMAILALREADYNEGOTIATOR: '电子邮件已经是一个谈判者',
    // ORIGINAL TEXT: Email is already a negotiator
    EMAILALREADYOWNERDOCTYPE: '电子邮件已经是文档类型的所有者',
    // ORIGINAL TEXT: Email is already an owner of the document type
    EMAILALREADYCOLLABORATORDOCTYPE: '电子邮件已经是文档类型的合作者',
    // ORIGINAL TEXT: Email is already an collaborator of the document type
    REQUIRED: '需要。',
    // ORIGINAL TEXT: Required.
    CLAUSEFIELDREQUIRED: '此操作需要此条款字段',
    // ORIGINAL TEXT: This clause field is required for this operation
    CLAUSENOTDEFAULT: '无法从默认条款列表中添加条款',
    // ORIGINAL TEXT: Cannot add clause from default clause list
    TAGFIELDREQUIRED: '此操作需要此标记字段',
    // ORIGINAL TEXT: This tag field is required for this operation
    TAGNOTDEFAULT: '无法从默认标签列表添加标签',
    // ORIGINAL TEXT: Cannot add tags from default tag list

    PARAINDEXREQUIRED: '需要段落索引',
    // ORIGINAL TEXT: Paragraph index is required
    PARAINDEXINVALID: '段落索引不存在',
    // ORIGINAL TEXT: Paragraph index does not exist
  },

  DOCEVENTS: {
    SAVEDOCUMENT: '保存文档',
    OPENDOCUMENT: '打开文档',
    // ORIGINAL TEXT: open document
    CLOSEDOCUMENT: '关闭文档',
    // ORIGINAL TEXT: close document
    ADDNOTE: '加注',
    // ORIGINAL TEXT: add note
    ADDCOLLABORATOR: '添加合作器',
    // ORIGINAL TEXT: add collaborator
    DELETECOLLABORATOR: '删除合作播放器',
    // ORIGINAL TEXT: delete collaborator
    ADDNEGOTIATOR: '添加谈判员',
    // ORIGINAL TEXT: add negotiator
    DELETENEGOTIATOR: '删除谈判员',
    // ORIGINAL TEXT: delete negotiator
    UPDATEARCHIVEDATA: '更新归档数据',
    // ORIGINAL TEXT: update archive data
    UPDATEDOCMETA: '更新 doc 元数据',
    // ORIGINAL TEXT: update doc metadata
    ADDOWNER: '添加所有者',
    // ORIGINAL TEXT: add owner
    DELETEOWNER: '删除所有者',
    // ORIGINAL TEXT: delete owner
    CHANGECOLLABORATION: '更改了合作设置',
    // ORIGINAL TEXT: changed collaboration settings
    CHANGENEGOTIATION: '更改谈判设置',
    // ORIGINAL TEXT: changed negotiation settings
    SAVEPLAYBOOK: '保存为播放手册',
    // ORIGINAL TEXT: saved as playbook
    SAVETEMPLATE: '另存为模板',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: '保存为存档',
    // ORIGINAL TEXT: saved as archived
    TRIGGERALERT: '触发触发提示',
    // ORIGINAL TEXT: trigger alert
    TRIGGERALLALERT: '触发全部提醒',
    // ORIGINAL TEXT: trigger all alert
    TRIGGERCUSTOMALERT: '触发自定义提醒',
    // ORIGINAL TEXT: trigger custom alert
    ADDCOMMENT: '添加备注',
    // ORIGINAL TEXT: add comment
    DELETECOMMENT: '删除注释',
    // ORIGINAL TEXT: delete comment
    ADDDOCEDIT: '添加 docedit',
    // ORIGINAL TEXT: add docedit
    ADDAPPROVEDDOCEDIT: '添加已核准已核准 doced',
    // ORIGINAL TEXT: add approved docedit
    APPROVEDDOCEDIT: '批准申请',
    // ORIGINAL TEXT: approve docedit
    REJECTDOCEDIT: '拒绝的 docedit',
    // ORIGINAL TEXT: reject docedit
    NEGOTIATORAPPROVEDDOCEDIT: '经谈判员批准',
    // ORIGINAL TEXT: negotiator approved docedit
    NEGOTIATORREJECTEDDOCEDIT: '谈判员拒绝 docedit',
    // ORIGINAL TEXT: negotiator rejected docedit
    DELETEDDOCEDIT: '删除 docedit',
    // ORIGINAL TEXT: delete docedit
    ATTACHCONTRACT: '附加 Exec 合同',
    // ORIGINAL TEXT: attach exec contract
    ADDTAG: '添加标签',
    // ORIGINAL TEXT: add tag
    DELETETAG: '删除标签',
    // ORIGINAL TEXT: delete tag

    NEWSIGNATUREREQUEST: '请求新签名',
    // ORIGINAL TEXT: new signature requested
    CANCELSIGNATUREREQUEST: '取消签名请求',
    // ORIGINAL TEXT: cancelled signature request
    CREATEREDLINEREQUEST: '创建新的红线文档',
    // ORIGINAL TEXT: create new redline document
    CREATEFINALDOCREQUEST: '创建供签名的新最后文件',
    // ORIGINAL TEXT: create new final document for signature
    COPIEDDOCUMENT: '从文档复制',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: '从文档创建了干净的版本',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: '从模板创建的文档',
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: '创建 doc 类型',
    // ORIGINAL TEXT: create doctype
    DELETEDOCTYPE: '删除 doc 类型',
    // ORIGINAL TEXT: delete doctype
    ADDOWNERTODOCTYPE: '将所有者添加到 doctype',
    // ORIGINAL TEXT: added owner to doctype
    DELETEOWNERFROMDOCTYPE: '删除所有者到 doctype',
    // ORIGINAL TEXT: deleted owner to doctype
    ADDCLAUSETODOCTYPE: '为 doctype 添加附加条款',
    // ORIGINAL TEXT: added addl clause to doctype
    DELETECLAUSEDOCTYPE: '删除 doctype 的添加l 条款',
    // ORIGINAL TEXT: deleted addl clause to doctype
    ADDTAGDOCTYPE: '添加到 doctype 的添加标签',
    // ORIGINAL TEXT: added addl tag to doctype
    DELETETAGDOCTYPE: '从 doctype 中删除 addl 标签',
    // ORIGINAL TEXT: deleted addl tag from doctype
    ADDCOLLABRULEDOCTYPE: '将 collab 规则添加到 doctype',
    // ORIGINAL TEXT: added collab rules to  doctype
    DELETECOLLABRULEDOCTYPE: '从 doctype 中删除 collab 规则',
    // ORIGINAL TEXT: deleted collab rules from doctype
    ADDNOTIFRULEDOCTYPE: '为 doctype 添加通知规则',
    // ORIGINAL TEXT: added notification rules to  doctype
    DELETENOTIFRULEDOCTYPE: '从 doctype 删除通知规则',
    // ORIGINAL TEXT: deleted notification rules from doctype
    ADDNEGOTRULEDOCTYPE: '为 doctype 添加谈判规则',
    // ORIGINAL TEXT: added negotiation rules to  doctype
    DELETENEGOTRULEDOCTYPE: '从 doctype 删除谈判规则',
    // ORIGINAL TEXT: deleted negotiation rules from doctype

    // GROUPS
    CREATEGROUP: '创建组',
    // ORIGINAL TEXT: created group
    DELETEGROUP: '删除组',
    // ORIGINAL TEXT: deleted group
    ADDOWNERGROUP: '将所有者添加到组',
    // ORIGINAL TEXT: added owner to group
    DELETEOWNERGROUP: '从组中删除所有者',
    // ORIGINAL TEXT: deleted Owner from group
    ADDCOLLABGROUP: '组合中添加的合作播放器',
    // ORIGINAL TEXT: added collaborator to group
    DELETECOLLABGROUP: '从组中删除的合作播放器',
    // ORIGINAL TEXT: deleted collaborator from group
    ADDDOCTYPETOGROUP: '将 doc类型添加到组',
    // ORIGINAL TEXT: added doctype to group
    DELETEDOCTYPEGROUP: '从组中删除 doctype',
    // ORIGINAL TEXT: deleted doctype from group

    // ALERTS
    CREATEALERT: '创建提醒',
    // ORIGINAL TEXT: created alert
    DELETEALERT: '删除提醒',
    // ORIGINAL TEXT: deleted alert
    ADDOWNERALERT: '添加所有者到提醒',
    // ORIGINAL TEXT: added owner to alert
    DELETEOWNERALERT: '从提醒中删除所有者',
    // ORIGINAL TEXT: deleted owner from alert
    ADDDOCTYPEALERT: '添加待提醒的 doc 类型',
    // ORIGINAL TEXT: added doctype to alert
    DELETEDOCTYPEALERT: '从提醒中删除 doctype',
    // ORIGINAL TEXT: deleted doctype from alert
    ADDCLAUSETOPICALERT: '添加了要提醒注意的条款专题',
    // ORIGINAL TEXT: added clause topic to alert
    DELETECLAUSETOPICALERT: '从提醒中删除条款专题',
    // ORIGINAL TEXT: deleted clause topic from alert
    ADDRECIPIENTALERT: '添加收件人到提醒',
    // ORIGINAL TEXT: added recipient to alert
    DELETERECIPIENTALERT: '从提醒中删除收件人',
    // ORIGINAL TEXT: deleted recipient from alert
    ADDNOTIFRULEALERT: '添加通知规则以提醒通知',
    // ORIGINAL TEXT: added notification rules to alert
    DELETENOTIFRULEALERT: '从提醒中删除通知规则',
    // ORIGINAL TEXT: deleted notification rules from alert
    SETSELECTIVITYALERT: '设置用于提醒的选择性设置',
    // ORIGINAL TEXT: set selectivity to  alert
  },

  USEREVENTS: {
    USERSIGNON: '用戶登錄',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT: '发送密码重置请求',
    // ORIGINAL TEXT: password reset request sent
    CHANGEDPASSWORD: '已更改密码',
    // ORIGINAL TEXT: changed password
    DELETEDREGISTRATION: '删除用户注册',
    // ORIGINAL TEXT: deleted user registration
    UPDATEDACCOUNTINFO: '最新账户信息',
    // ORIGINAL TEXT: updated account information
    UPGRADEDACCOUNTTOREFERRAL: '升级账户到推荐账户',
    // ORIGINAL TEXT: upgraded account to referred account
    DELETEDUSERACCOUNT: '删除用户账户',
    // ORIGINAL TEXT: deleted user account
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: '文件 行动 行动',
    // ORIGINAL TEXT: DOCUMENT ACTIONS
    COMMENTS: '评论评论',
    // ORIGINAL TEXT: COMMENTS
    DOCEDITS: '文件编辑',
    // ORIGINAL TEXT: DOCUMENT EDITS
    LABELSRISK: '标签和风险',
    // ORIGINAL TEXT: LABELS AND RISK
    TEXT: '原文',
    // ORIGINAL TEXT: TEXT
    MLE_DESC: 'DOCGILITY',
    // ORIGINAL TEXT: DOCGILITY
    CLAUSE: '条款条款条款',
    // ORIGINAL TEXT: Clause

    // PRIVILEGE
    OWNER: '拥有者(所有者)',
    // ORIGINAL TEXT: owner
    COLLABORATOR: '合作协作者',
    // ORIGINAL TEXT: collaborator
    NEGOTIATOR: '谈判员',
    // ORIGINAL TEXT: negotiator
    OWNERGROUP: '所有人( 组)',
    // ORIGINAL TEXT: owner(group)
    COLLABORATORGROUP: '合作协作者( 组组)',
    // ORIGINAL TEXT: collaborator(group)

    // TITLES
    SAVEDOCUMENT: '保存文件',
    // ORIGINAL TEXT: SAVE DOCUMENT
    SAVEARCHIVE: '保护完整',
    // ORIGINAL TEXT: SAVE ARCHIVE
    QUICKSTART: '快速启动工程',
    // ORIGINAL TEXT: QUICKSTART TUTORIAL
    TUTORIALVIDEOS: '暂定版本',
    // ORIGINAL TEXT: TUTORIAL VIDEOS

    MOVETOARCHIVEDOCUMENT: '移至简略文件',
    // ORIGINAL TEXT: MOVE TO ARCHIVE DOCUMENT
    OPENDOCUMENT: '公开文件',
    // ORIGINAL TEXT: OPEN DOCUMENT
    COPYDOCUMENT: '复制文件',
    // ORIGINAL TEXT: COPY DOCUMENT
    CREATEVERSION: '创建干净版本',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: '从模板创建文档',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: '公开会议文件',
    // ORIGINAL TEXT: OPEN GROUP DOCUMENT
    OPENHUBDOCUMENT: '公开HUB文件',
    // ORIGINAL TEXT: OPEN HUB DOCUMENT
    OPENARCHIVEDOCUMENT: '开放版文件',
    // ORIGINAL TEXT: OPEN ARCHIVE DOCUMENT
    OPENPLAYBOOK: '打开使用手册',
    // ORIGINAL TEXT: OPEN PLAYBOOK                                              NOT SURE
    OPENTEMPLATE: '打开模板',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: '另存为模板',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: '协作',
    // ORIGINAL TEXT: COLLABORATION
    NEGOTIATION: '谈判 协商',
    // ORIGINAL TEXT: NEGOTIATION
    ADDOWNER: 'ADD 拥有者',
    // ORIGINAL TEXT: ADD OWNER
    ADDNEGOTIATOR: '加谈判员',
    // ORIGINAL TEXT: ADD NEGOTIATOR
    ADDCOLLABORATOR: 'ADD 哥伦比亚',
    // ORIGINAL TEXT: ADD COLLABORATOR
    ERRORMESSAGE: '紧急信息',
    // ORIGINAL TEXT: ERROR MESSAGE
    DOCUMENTINFO: '文件文件 资料',
    // ORIGINAL TEXT: DOCUMENT INFORMATION
    DOCUMENTLOGS: '文件记录',
    // ORIGINAL TEXT: DOCUMENT LOGS
    USERLOGS: '使用记录',
    // ORIGINAL TEXT: USER LOGS
    DOCUMENTNOTES: '文件说明',
    // ORIGINAL TEXT: DOCUMENT NOTES
    TRIGGERALERTS: '引发警报',
    // ORIGINAL TEXT: TRIGGER ALERTS
    ALERTS: '警报',
    // ORIGINAL TEXT: ALERTS
    DOCTYPES: '一、文件的周期性文件',
    // ORIGINAL TEXT: DOCUMENT TYPES
    GROUPS: '群体群体',
    // ORIGINAL TEXT: GROUPS
    NOTE: '注',
    // ORIGINAL TEXT: NOTE
    GOTOPARAGRAPH: '至 段 次 页 次',
    // ORIGINAL TEXT: GO TO PARAGRAPH
    ESIGNATURE: '电子签名',
    // ORIGINAL TEXT: E-SIGNATURE
    DOWNLOADREDLINE: '下载红线文档',
    // ORIGINAL TEXT: DOWNLOAD REDLINE DOCX

    // ACTIONS
    ADD: '加',
    // ORIGINAL TEXT: ADD
    BACK: '退后',
    // ORIGINAL TEXT: BACK
    DELETE: '删除',
    // ORIGINAL TEXT: DELETE
    SAVE: '救救',
    // ORIGINAL TEXT: SAVE
    MOVETOARCHIVE: '移动到档案',
    // ORIGINAL TEXT: MOVE TO ARCHIVE
    COPY: '复制',
    // ORIGINAL TEXT: COPY
    CREATE: '创建',
    // ORIGINAL TEXT: CREATE
    OPEN: '开放',
    // ORIGINAL TEXT: OPEN
    CLOSE: '闭闭闭',
    // ORIGINAL TEXT: CLOSE
    UPDATE: '最新更新',
    // ORIGINAL TEXT: UPDATE
    REGISTER: '注册',
    // ORIGINAL TEXT: REGISTER
    LOGIN: '登录',
    // ORIGINAL TEXT: LOGIN
    LOGINSSO: '登录 SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: '后卫士兵',
    // ORIGINAL TEXT: RESET PASSWORD
    CHANGEPASSWORD: '更改密码',
    // ORIGINAL TEXT: CHANGE PASSWORD
    CANCELACCOUNT: '取消账户',
    // ORIGINAL TEXT: CANCEL ACCOUNT
    QUICKSTARTVIDEO: '看着快速 启动视频',
    // ORIGINAL TEXT: WATCH QUICKSTART VIDEO

    CHARGEBASIC: '升级至基本',
    // ORIGINAL TEXT: UPGRADE TO BASIC
    CHARGEPREMIUM: '升级至高级',
    // ORIGINAL TEXT: UPGRADE TO PREMIUM
    CHARGECORPORATE: '升至公司',
    // ORIGINAL TEXT: UPGRADE TO CORPORATE
    UPGRADESUBSCRIPTION: '上 上 年 增 增 增 减',
    // ORIGINAL TEXT: UPGRADE SUBSCRIPTION
    GO: '上 去',
    // ORIGINAL TEXT: GO
    PREVIEW_FEEDBACK: '审评变化',
    // ORIGINAL TEXT: REVIEW CHANGES
    ACTIONS: '青',
    // ORIGINAL TEXT: ACT                                                            NEED MORE CONTEXT
    REFRESH: '更新',
    // ORIGINAL TEXT: REFRESH
    ADDNEWGROUP: '加入新群组',
    // ORIGINAL TEXT: ADD NEW GROUP
    ADDNEWDOCTYPE: '加入新文件类型',
    // ORIGINAL TEXT: ADD NEW DOCTYPE
    ADDNEWALERT: '加入新警告',
    // ORIGINAL TEXT: ADD NEW ALERT
    GROUPSLIST: '审查小组名单',
    // ORIGINAL TEXT: REVIEW GROUP LIST

    DOWNLOADDOCUMENT: '下载文档',
    // ORIGINAL TEXT: DOWNLOAD DOCUMENT

    DOCUMENTTITLE: '文件标题',
    // ORIGINAL TEXT: Document Title
    DOCUMENTSUBJECT: '文件文件内容',
    // ORIGINAL TEXT: Document Subject
    SIGNMESSAGE: '用于签名的信件',
    // ORIGINAL TEXT: Message for Signing
    NAMEPRIMARYSIGNER: '名称: 主要签名人',
    // ORIGINAL TEXT: Name: Primary Signer
    EMAILPRIMARYSIGNER: '电子邮件: 主要签名人',
    // ORIGINAL TEXT: Email: Primary Signer
    NAMECOUNTERSIGNER: '姓名: 对应签名人',
    // ORIGINAL TEXT: Name: Counter Signer
    EMAILCOUNTERSIGNER: '电子邮件: 对应签名人',
    // ORIGINAL TEXT: Email: Counter Signer
    SIGREQUESTED: '请求签名',
    // ORIGINAL TEXT: SIGNATURE REQUESTED
    SIGREQUESTEDBY: '要求的',
    // ORIGINAL TEXT: REQUESTED BY
    SIGREQUESTEDID: '签字代号',
    // ORIGINAL TEXT: SIGNATURE ID
    SIGREQUESTEDAT: '请求日期',
    // ORIGINAL TEXT: REQUESTED AT
    SUBMITSIG: '提交签名',
    // ORIGINAL TEXT: SUBMIT FOR SIGNATURE
    CANCELSIG: '要求的解除签字合同',
    // ORIGINAL TEXT: CANCEL SIGNATURE REQUEST

    // DATA LABELS
    NAME: '名称',
    // ORIGINAL TEXT: NAME
    EMAIL: 'EMAIL',
    // ORIGINAL TEXT: EMAIL
    STATUS: '状况状况状况',
    // ORIGINAL TEXT: STATUS
    LASTVIEWED: '上次审议',
    // ORIGINAL TEXT: LAST VIEWED
    LASTREVIEWED: '上次审查的',
    // ORIGINAL TEXT: LAST REVIEWED
    SIGNEDAT: '签 签',
    // ORIGINAL TEXT: SIGNED
    OWNERAPPROVAL: '所有者批准',
    // ORIGINAL TEXT: OWNER APPROVAL
    OWNERACTION: '行动,包括行动',
    // ORIGINAL TEXT: OWNER ACTION
    NEGOTIATORAPPROVAL: '核准提案',
    // ORIGINAL TEXT: NEGOTIATOR APPROVAL
    NEGOTIATORACTION: '国家行动',
    // ORIGINAL TEXT: NEGOTIATOR ACTION       NOT SURE
    ARCHIVENOTES: '频号注注注',
    // ORIGINAL TEXT: ARCHIVE NOTES
    EXECUTEDCONTRACT: '已经执行',
    // ORIGINAL TEXT: EXECUTED
    EXECUTEDDATE: '尚未执行日期',
    // ORIGINAL TEXT: EXECUTED DATE
    CONTRACTATTACHED: '与合同有关的合同',
    // ORIGINAL TEXT: CONTRACT ATTACHED
    STARTDATE: '开始日期 终止日期',
    // ORIGINAL TEXT: START DATE
    ENDDATE: '编辑日期',
    // ORIGINAL TEXT: END DATE
    EDIT: '编辑 编辑 编辑 编辑',
    // ORIGINAL TEXT: EDIT
    USER: '使用量',
    // ORIGINAL TEXT: USER
    TIME: '时间时间 时间',
    // ORIGINAL TEXT: TIME
    CONFIRM: '确认',
    // ORIGINAL TEXT: CONFIRM
    CONFIRMACTION: '确认行动',
    // ORIGINAL TEXT: CONFIRM ACTION
    CURRENT_LABEL: '现有标签',
    // ORIGINAL TEXT: CURRENT LABEL
    NEW_LABEL: '新拉',
    // ORIGINAL TEXT: NEW LABEL
    DESCRIPTION: '代 代 代 称',
    // ORIGINAL TEXT: DESCRIPTION
    TYPE: '类型',
    // ORIGINAL TEXT: TYPE
    SENTENCE: '词架',
    // ORIGINAL TEXT: SENTENCE
    NUMBER: '# # 发现#',
    // ORIGINAL TEXT: #
    PRIVILEGE: '作用作用',
    // ORIGINAL TEXT: ROLE
    OWNERID: '拥有能源',
    // ORIGINAL TEXT: OWNERID
    DOCNAME: 'DOC 名称',
    // ORIGINAL TEXT: DOC NAME
    DOCTYPE: 'DOC 类型',
    // ORIGINAL TEXT: DOC TYPE
    DOCDESCRIPTION: 'DOC 描述',
    // ORIGINAL TEXT: DOC DESCRIPTION
    DOCNOTES: 'DC 注',
    // ORIGINAL TEXT: DOC NOTES
    DOCPARTY: '缔约方',
    // ORIGINAL TEXT: PARTY
    DOCCOUNTERPARTY: '缔约国',
    // ORIGINAL TEXT: COUNTER PARTY
    DOCRAWFILENAME: '文件名',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'TAGS 电信',
    // ORIGINAL TEXT: TAGS
    COLLABORATIONSTATUS: 'COLLAB 状况',
    // ORIGINAL TEXT: COLLAB STATUS
    COLLABORATIONEND: '日终',
    // ORIGINAL TEXT: COLLAB END
    NEGOTIATIONSTATUS: '国家地位',
    // ORIGINAL TEXT: NEGOT STATUS
    NEGOTIATIONEND: '讨论结束',
    // ORIGINAL TEXT: NEGOT END
    DOCID: 'DOC 身份证',
    // ORIGINAL TEXT: DOC ID
    PARAGRAPHID: 'PARA 标识',
    // ORIGINAL TEXT: PARA ID
    ACTION: '行动',
    // ORIGINAL TEXT: LOGGED ACTION
    DETAIL: '行动、行动和行动',
    // ORIGINAL TEXT: ACTION DETAIL
    CONTEXT: '附加条件',
    // ORIGINAL TEXT: ADDITIONAL CONTEXT
    GROUPNAME: '组名',
    // ORIGINAL TEXT: GROUP NAME
    GROUPDESC: '组 组 化 组 化',
    // ORIGINAL TEXT: GROUP DESCRIPTION
    DOCTYPEDESC: '文件类型描述',
    // ORIGINAL TEXT: DOC TYPE DESCRIPTION
    ADDLCLAUSETYPES: 'Custom CLAUSLAUSLABLLABLS ',
    // ORIGINAL TEXT: CUSTOM CLAUSE LABELS
    ADDLTAGS: '海关关税',
    // ORIGINAL TEXT: CUSTOM TAGS
    COLLABORATIONRULES: '合作规则',
    // ORIGINAL TEXT: COLLABORATION RULES
    NEGOTIATIONRULES: '谈判规则',
    // ORIGINAL TEXT: NEGOTIATION RULES
    NOTIFICATIONRULES: '通知规则',
    // ORIGINAL TEXT: NOTIFICATION RULES
    LOG: '语言',
    // ORIGINAL TEXT: LOG
    ALERTNAME: '替代名称',
    // ORIGINAL TEXT: ALERT NAME
    ALERTDESC: '口译和口译',
    // ORIGINAL TEXT: ALERT DESCRIPTION
    TOPICS: '专题',
    // ORIGINAL TEXT: TOPICS
    RECIPIENTS: '收件人',
    // ORIGINAL TEXT: RECIPIENTS
    SELECTIVITY: '选择性',
    // ORIGINAL TEXT: SELECTIVITY
    FIELDNAME: '字段名称',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: '命令',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: '例子',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: '默认',
    // ORIGINAL TEXT: DEFAULT
    VALUE: '价值',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: '文档',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: '剧本',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: '模板',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: '存档文件',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: '源自模板：',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: '哥 数',
    // ORIGINAL TEXT: COL                                                         NOT SURE
    NEGSHORT: 'NEG 尼经',
    // ORIGINAL TEXT: NEG                                                         NOT SURE
    OWNERS: '拥有',
    // ORIGINAL TEXT: OWNERS
    COLLABORATORS: '协调单位',
    // ORIGINAL TEXT: COLLABORATORS
    NEGOTIATORS: '谈判人员',
    // ORIGINAL TEXT: NEGOTIATORS
    // ADDITION
    DOCUMENTDETAILS: '文件细节',
    // ORIGINAL TEXT: DOCUMENT DETAILS
    CREATIONDATE: '出生日期 出生日期',
    // ORIGINAL TEXT: CREATION DATE
    PARTICIPANTS: '与会者',
    // ORIGINAL TEXT: PARTICIPANTS
    COLLABORATIONANDNEGOTIATION: '合作与谈判',
    // ORIGINAL TEXT: COLLABORATION AND NEGOTIATION
    DOCTYPEANDGROUPACCESS: '类型和群体获得文件',
    // ORIGINAL TEXT: DOCUMENT TYPE AND GROUP ACCESS
    GROUPOWNERS: '群体拥有者',
    // ORIGINAL TEXT: GROUP OWNERS
    GROUPCOLLABORATORS: '集团协调员',
    // ORIGINAL TEXT: GROUP COLLABORATORS
    DOCUMENTSETTINGS: '文件编辑文件',
    // ORIGINAL TEXT: DOCUMENT SETTINGS
    ENABLECOLLABTOMODLABELS: '启用合作者修改条款标签',
    // ORIGINAL TEXT: Enable Collaborators to Modify Clause Labels
    ENABLENEGOTCAPABILITIES: '启用谈判能力(非谈判模式)',
    // ORIGINAL TEXT: Enable Negotiation capabilities (Not Negotiation mode)
    KEEPDOCNOTES: '使用複印操作複印文件筆記',
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS: '审查和修正条款的必要专题',
    // ORIGINAL TEXT: Reviewed and Amended Clause Topics as Necessary
    NOEXECUTIONDATE: '无执行日期',
    // ORIGINAL TEXT: No Execution Date
    EXECUTIONDATE: '执行日期 执行日期',
    // ORIGINAL TEXT: Execution Date
    CONTRACTSTARTDATE: '合同开始日期',
    // ORIGINAL TEXT: Contract Start Date
    CONTRACTENDDATE: '合同终止日期',
    // ORIGINAL TEXT: Contract End Date
    EXECCONTRACTNAME: '已执行的合同文件名',
    // ORIGINAL TEXT: Executed Contract File Name
    SELECTEXECCONTRACT: '选择要上传已执行的合同的文件',
    // ORIGINAL TEXT: select file to upload executed contract
    UPLOADEXECUTEDCONTRACT: '上传已执行合同',
    // ORIGINAL TEXT: UPLOAD EXECUTED CONTRACT
    LOADDEFAULTS: '上传不履行的责任',
    // ORIGINAL TEXT: LOAD DEFAULTS
    CLOSED: '关闭',
    // ORIGINAL TEXT: CLOSED
    NONE: '无 无',
    // ORIGINAL TEXT: NONE
    NA: 'NA NA',
    // ORIGINAL TEXT: NA
    RESULTEXECUTEDCONTRACT: '执行合同中产生的',
    // ORIGINAL TEXT: Resulted in Executed Contract
    NOSTARTDATE: '无开始日期',
    // ORIGINAL TEXT: No Start Date
    NOENDDATE: '无结束日期',
    // ORIGINAL TEXT: No End Date
    EXCLUDECOLLABORATORSFROMACCESS: '排除外部合作者访问文档',
    // ORIGINAL TEXT: Exclude External Collaborators From Accessing Document
    EXCLUDENEGOTIATORSFROMACCESS: '从存取文档中排除外部谈判人员',
    // ORIGINAL TEXT: Exclude External Negotiators From Accessing Document
    REVIEWEDAMENDEDCLAUSELABELS: '审查和修正条款的标签',
    // ORIGINAL TEXT: Reviewed and Amended Clause Labels as Necessary
    ENTERPARAGRAPHBETWEEN: '输入以下之间段落编号:',
    // ORIGINAL TEXT: Enter a paragraph number between:
    GOTOPARAGRAPHINDEX: '转到段落索引',
    // ORIGINAL TEXT: Go to Paragraph Index
    INPUTDOCUMENTNOTEHERE: '此处输入文件注释',
    // ORIGINAL TEXT: Input Document Note here
    INPUTCOMMENTHERE: '这里的输入Comment',
    // ORIGINAL TEXT: Input Comment here
    SHOWPENDINGONLY: '只显示待发',
    // ORIGINAL TEXT: Show Pending Only
    COLLABORATIONSETTING: '合作安排',
    // ORIGINAL TEXT: COLLABORATION SETTING
    NEGOTIATIONSETTING: '谈判安排',
    // ORIGINAL TEXT: NEGOTIATION SETTING
    NONEGOTWHENAPPROVED: '在批准状态下无法打开谈判',
    // ORIGINAL TEXT: Cannot turn on Negotiation when in Approved State
    EMAILTOADD: '要添加的电子邮件',
    // ORIGINAL TEXT: Email to add
    CLAUSETOADD: '添加条款',
    // ORIGINAL TEXT: Clause to add
    TAGTOADD: '要添加的标签',
    // ORIGINAL TEXT: Tag to add
    OPTIONSFORREDLINEINCLUDE: '红线要包含的选项',
    // ORIGINAL TEXT: Options for Redlines to Include
    ONLYINCLUDEOWNERAPPROVEDEDITS: '只包含所有者核准的文档编辑',
    // ORIGINAL TEXT: Only Include Owner-approved Document Edits
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS: '只包含所有者和谈判者核准的文档编辑',
    // ORIGINAL TEXT: Only Include Owner and Negotiator-approved Document Edits
    INCLUDEALLDOCEDITS: '包含所有文档编辑( 不论批准与否)',
    // ORIGINAL TEXT: Include All Document Edits (regardless of approval)
    REDLINECHANGESSELECTED: '选择了红线修改',
    // ORIGINAL TEXT: Redlined Changes Selected
    TOTALCHANGES: '变动共计',
    // ORIGINAL TEXT: Total Changes
    REPLACEREDLINES: '替换红线',
    // ORIGINAL TEXT: Replace Redlines
    DELETEREDLINES: '删除红线',
    // ORIGINAL TEXT: Delete Redlines
    ADDAFTERREDLINES: '红线后添加',
    // ORIGINAL TEXT: Add-After Redlines
    OPTIONSFORPREVREDLINES: '上一个红线选项',
    // ORIGINAL TEXT: Options for Previous Redlines
    OVERWRITEPREVREDLINES: '在文档源( 默认) 中覆盖所有上一个红线',
    // ORIGINAL TEXT: Overwrite all Previous Redlines in Document Source
    KEEPPREVREDLINES: '将所有先前的红线保留在文档源中',
    // ORIGINAL TEXT: Keep all Previous Redlines in Document Source
    GENERATEFINALDOC: '生成最后文件( 无红线)',
    // ORIGINAL TEXT: Generate Final Document (without Redlines)
    DEFAULTESIGNOTE: '如前所述,请向本合同签字。',
    // ORIGINAL TEXT: As discussed, please provide your eSignature to this contract.
    SUBMITTEDSIGMESSAGE: '提交签名。 请等待 。',
    // ORIGINAL TEXT: Submitted for signatures.  Please wait.
    UPLOADEDMESSAGE: '已上传',
    // ORIGINAL TEXT: Uploaded
    ADDADDLFILES: '可选 - 在签名请求中添加附加文件',
    // ORIGINAL TEXT: Optional - Adding additional files to signature request
    ADDFILESMSG: '添加额外的签名文件',
    // ORIGINAL TEXT: add additional files for signature
    ESIGPREPAIDPLANS: '电子签名功能仅通过我们的预付费计划提供。',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      '有关更多详细信息，请通过 billing@mylegaleinstein.com 与我们联系。',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: '更多视频在 ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED: '不支持文档类型。 MLE 只接受 docx 和 pdf 格式 。',
    // ORIGINAL TEXT: Document type is not supported.  MLE only accepts docx and pdf formats.
    PDFSUPPORTEDFORPREMIUMONLY:
      '为了进行PDF扫描,用户需要升级为Premium订阅或更好。',
    // ORIGINAL TEXT: For PDF scanning, user needs to upgrade to Premium subscription or better.
    BADDOCFORMAT: '错误的文档格式已被传送到文档处理中 。',
    // ORIGINAL TEXT: Bad Document format was passed to document processing.
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      '您没有权限更改文档设置。 您必须是一个所有者 。',
    // ORIGINAL TEXT: You do not have the privilege to make changes to the Document Settings.  You are required to be an Owner.
    PRIVILEGSNOTAVAIL: '此用户没有权限使用。 稍后再试 。',
    // ORIGINAL TEXT: The privileges for this user are not available.  Try again later.
    EXHAUSTEDTRIALLICENSE:
      '您在试验期内已经用尽了允许的文件数量。 请在您的配置文件中选择“ 更新订阅 ” 。',
    // ORIGINAL TEXT: You have exhausted the number of allowed documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    EXHAUSTEDMONTHLYLIMIT:
      '您在试用期内已经用尽了允许的月度文件数量。 请在您的配置文件中选择“ 更新订阅 ” 。',
    // ORIGINAL TEXT: You have exhausted the number of allowed monthly documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    PASSWORDNOTMATCHING: '密码不符。 再次检查密码 。',
    // ORIGINAL TEXT: Passwords do not match.  Check the passwords again.
    ABAMEMBERIDLENGTH: 'ABA成员身份证有7个字符长,请审查并提交。',
    // ORIGINAL TEXT: ABA Membership IDs are 7 characters long.  Please review and submit.
    TERMSNOTCHECKED: '《条款和条件》未经审查和检查。',
    // ORIGINAL TEXT: The Terms and Conditions have not been reviewed and checked.
    EMAILFORMATBAD: '电子邮件格式无效。 请查阅电子邮件地址 。',
    // ORIGINAL TEXT: Email format is not valid.  Please review email address.
    CANCELEDACTION: '您的最后一个动作已被取消 。 应用程序现在将重新装入 。',
    // ORIGINAL TEXT: Your last action have been canceled.  Application will now reload.
    SUCCESSACTION: '您的最后一个动作已经成功。 应用程序现在将重新装入 。',
    // ORIGINAL TEXT: Your last action has been successful.  Application will now reload.
    USERSUCCESSLOGIN: '用户已成功登录 。',
    // ORIGINAL TEXT: User has successfully logged on.
    USERLOGGEDOUT: '用户已被登录退出',
    // ORIGINAL TEXT: The user has been logged out
    EMAILNOTVERIFIED: '账户电子邮件尚未校验 。 电子邮件校验已发送给您 。',
    // ORIGINAL TEXT: Account email has not yet been verified.  An email verification has been sent to you.
    EMAILALREADYREGISTERED: '此电子邮件已有先前注册的账户 。',
    // ORIGINAL TEXT: This email already has a previously registered account.
    EMAILNOTVERIFIEDRESENT:
      '您的电子邮件未经校验 。 我们反对您使用电子邮件的验证链接来激活新账户 。',
    // ORIGINAL TEXT: Your email has not been verified.  We have resent you an email verification link to your email to activate the new account.
    USERLOGFILENOTEXISTS: '- 错误 - 没有日志用户文件 。',
    // ORIGINAL TEXT: Error - no log user file exists.
    PASSWORDRESETSENT: '您的账户已发送电子邮件密码重置信件 。',
    // ORIGINAL TEXT: An email password reset message has been sent for your account.
    PASSWORDCHANGED: '您的密码已更改 。',
    // ORIGINAL TEXT: Your password has been changed.
    REGISTRATIONDELETED: '删除用户注册',
    // ORIGINAL TEXT: deleted user registration
    USERACCOUNTUPDATED:
      '您已经更新了您的用户账户设置。 下次登录时, 更改将会生效 。',
    // ORIGINAL TEXT: You have updated your user account settings.  Changes will take into effect the next time you login.
    UPGRADEDTOREFERRED: '您的账户已更新到转帐测试订阅 。',
    // ORIGINAL TEXT: Your account has been updated to Referred Trial subscription.
    REGISTEREDTRIALUSER: '新账户登记为试订账户。',
    // ORIGINAL TEXT: New account is registered as Trial subscription.
    DELETEDUSERACCOUNT: '删除了用户配置文件账户',
    // ORIGINAL TEXT: Deleted user profile account
    EMAILALREADYREGISTEREDRESENT:
      '电子邮件尚未注册到我的法律爱因斯坦。 电子邮件邀请已被发送到新用户 。',
    // ORIGINAL TEXT: Email has not already been registered with Docgility.  An email invitation has been sent to the new user.
    UPGRADEEXTERNALPAYMENTS:
      '感谢您的升级。 您将被转至外部支付服务, 完成交易后, 您需要重新登录订阅服务才能启动 。',
    // ORIGINAL TEXT: Thanks for upgrading.  You will be rerouted to an external payment service and after completing your transaction, you will need to login again for subscription service to start.
    BILLINGEXTERNALPAYMENTS:
      '请确认您愿意接受外部付款和账单服务。 更改后, 您需要重新登录才能生效 。',
    // ORIGINAL TEXT: Please confirm that you will like to be directed to our external payment and billing service.  After you make any changes, you will need to login again for changes to take effect.
    NOTABLETOLOGINATTHISTIME: '此时无法登录。 稍后再试 。',
    // ORIGINAL TEXT: Not able to login at this time.  Try again later.
    NOTREGISTERED: '用户以前没有注册过。 尝试登录前先注册 。',
    // ORIGINAL TEXT: User has not previously registered.  Register before trying to login.
    NAMETOOLONG: '您输入的名称太长。 Max 是 60 个字符 。',
    // ORIGINAL TEXT: The name you entered is too long.  Max is 60 characters.
    COMPANYTOOLONG: '你加入的公司太长了 Max是60个字符',
    // ORIGINAL TEXT: The company you entered is too long.  Max is 60 characters.
    COMMENTTOOLONG: '您输入的批注过长。 最大值为 10000 个字符 。',
    // ORIGINAL TEXT: The comments you entered is too long.  Max is 10000 characters.
    DOCEDITTOOLONG: '您输入的文档编辑时间太长。 最大值为 10,000 字符 。',
    // ORIGINAL TEXT: The document edits you entered is too long.  Max is 10000 characters.
    TITLETOOLONG: '您输入的标题太长。 Max 是 60 个字符 。',
    // ORIGINAL TEXT: The title you entered is too long.  Max is 60 characters.
    PASSWORDTOOSHORT: '您输入的密码太短。 密码必须是至少 6 个字符 。',
    // ORIGINAL TEXT: The password you entered is too short.  Password has to be at least 6 characters.
    CONGRATSNEWDOCUMENTPROCESSING:
      '太好了! 您已经处理过一个新的文档。 它需要几分钟时间, 当文件处理完成后, 面板应该刷新。 请稍候 。',
    // ORIGINAL TEXT: Great! You have processed a new document.  It takes a few minutes and the panel should refresh when the document is completed processing.  Please wait a few minutes.

    NODOCUMENTLOADED: '未装入文档',
    // ORIGINAL TEXT: No document loaded
    SELECTFILETOUPLOADDOCX: '选择要上传的文件 (*. docx)',
    // ORIGINAL TEXT: select file to upload (*.docx)
    SELECTFILETOUPLOADDOCXPDF: '选择要上传的文件 (*.docx, *.pdf)',
    // ORIGINAL TEXT: select file to upload (*.docx, *.pdf)
    INPUTCOMMENTHERE: '在此输入注释',
    // ORIGINAL TEXT: Input comment here
    AUTOAPPROVEASOWNER: '自动核准为所有者',
    // ORIGINAL TEXT: Auto Approve as Owner
    INPUTDOCEDITSHERE: '输入文档 文档编辑在此编辑',
    // ORIGINAL TEXT: Input Document Edit here

    // ADDED
    TRIGGEREDALLALERTS: '为此 doctype 文档触发了全部( 如果有的话) 提醒 。',
    // ORIGINAL TEXT: Triggered all (if any) Alerts for this doctype document.
    QSINTROMESSAGE:
      'MyLegalEinstein立即分析您的合同、法律专题索引,并允许在一个完整的合同合作平台上对法律语言进行并肩比较。',
    // ORIGINAL TEXT: MyLegalEinstein instantly analyzes your contracts, indexes by legal topics, and allows side-by-side comparisons of legal language in a complete contract collaboration platform. We will demonstrate the capabilities in four simple steps (detailed instructions are provided in english but similar in all languages.)
    QSDOCMESSAGE: '为了方便您的方便, 以下是一些公共域合同, 您可以下载并尝试 :',
    // ORIGINAL TEXT: For your convenience, here are some public domain contracts that you can download and try:
    QSHELPMESSAGE:
      '给产品文档( 配有文字版本的 " 快速启动 " ) 提供一个方便的链接',
    // ORIGINAL TEXT: Heres a handy link to product documentation (with wordy version of Quick Start)
    DOCUMENTFAILEDTOPROCESS:
      '处理文档时发生致命错误。 请重试 。 如果问题依然存在, 请联系支持@ mylecleinstein. com 的客户支持 。',
    // ORIGINAL TEXT: A fatal error occurred while processing the document.  Please try again.  If problem persists, please contact customer support at support@mylegaleinstein.com.

    // ADDED
    DOCUMENTSUCCESSCOPIED: '您的文档现已被复制。',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED: '现在已生成并保存了此文档的干净版本。',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE: '从此模板生成并保存了一个文档。',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE: '文档已转换为文档模板。',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      '请确认您想要删除指定的文档。 删除文档后, 它将不再可用 。 您也可以考虑将文档移至归档状态 。 n nn 请确认删除操作 。',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified document.  After document is deleted, it will no longer be available.  You may also consider moving the document to Archive status.  \n\n Please confirm delete operation.
    CONFIRMSAVETOPLAYBOOK:
      '请确认此文档可转换为剧本 。 这将使得此文档除所有者外无法更改 。 n n n 请确认保存为剧本操作 。',
    // ORIGINAL TEXT: Please confirm that to convert this document to a playbook.  This would make this document unchangable except for the owner.  \n\n Please confirm the save to playbook operation.
    COLLABORATIONNOTE: '注:这一信息仅与指定的合作者和文件所有者在内部共享。',
    // ORIGINAL TEXT: NOTE: This information is shared INTERNALLY ONLY with designated collaborators and document owners only.
    NEGOTIATIONNOTE: '注:这一信息与谈判对手方对外分享。',
    // ORIGINAL TEXT: NOTE: This information is shared EXTERNALLY with negotiation counter parties.
    CONFIRMDELETEALERT:
      '请确认您想要删除指定的提醒 。 在删除提醒后, 它将不再可用 。 请确认删除操作 。',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified alert.  After alert is deleted, it will no longer be available.  Please confirm delete operation.
    CONFIRMDELETERECIPIENTALERT:
      '请确认您不再想成为指定警告的收件人。 在将您自己除名为收件人后, 您可以与提醒的所有人联系, 请求被添加。 请确认删除操作 。',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.
    CONFIRMDELETEGROUP:
      '请确认您想要删除指定的组 。 删除组后, 它将不再可用 。 但是, 它将反映您下次登录时的情况 。 请确认删除操作 。',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified group.  After group is deleted, it will no longer be available.  However, it will reflected the next time you log back in.  Please confirm delete operation.
    CONFIRMDELETECOLLABORATORGROUP:
      '请确认您不再想成为指定组群的合作者。 在将自己除名为合作者后, 您可以与组群所有人联系, 请求被添加。 请确认删除操作 。',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a collaborator for the specified group.  After removing yourself as a collaborator, you can request to be added back by contacting the owner of the group.  Please confirm delete operation.
    CONFIRMDELETEDOCTYPE:
      '请确认您想要删除指定的 doc 类型。 删除 doc 类型后, 它将不再可用 。 请确认删除操作 。',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified doctype.  After doctype is deleted, it will no longer be available.  Please confirm delete operation.
    EXPIREDTRIAL: '您的试用证过期了 请升级以继续您的工作',
    // ORIGINAL TEXT: Your trial license has expired.  Please upgrade to continue your service.
    EXPIREDSUBSCRIPTION:
      '您的订阅执照过期了 请升级到我的爱因斯坦法律公司 继续您的服务或联系账单',
    // ORIGINAL TEXT: Your subscription license has expired.  Please upgrade to continue your service or contact billing at Docgility.
    SOURCEDOCNOTDOCX: '源文件不是 DOCX 文档。 没有红线能力 。',
    // ORIGINAL TEXT: The Source document is not a DOCX document.  Redline capabilities are not available.
  },
}

export default ZH
