import store from '@state/store'

import { DBinsertonesync, DBdeactivate } from '@/src/state/io/iodb.js'

import {
  DBHUBadddocchange,
  DBHUBdeletedocchange,
} from '@/src/state/io/iohub.js'

const TABLENAME = 'comments'

export const state = {
  // debug
  dbcommentid: 0,
}

export const getters = {
  dbcommentid(state) {
    return state.dbcommentid
  },
}

export const mutations = {
  SET_ID(state, id) {
    state.dbcommentid = id
  },
}

export const actions = {
  init({ commit }) {},

  writeComment({ commit, state }, payload) {
    var writeCommentEx = {
      docid: payload.docid,
      paraid: payload.paraid,
      comment: payload.comment,
      uniqid: payload.uniqid,
      active: payload.active,
      type: payload.type,
      create: new Date(),
      delete: '',
    }

    if (payload.ifHUBType) {
      DBHUBadddocchange(TABLENAME, writeCommentEx)
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.ADDCOMMENT',
          detail: payload.comment,
          context:
            'docid:' +
            payload.docid +
            ',' +
            'type:' +
            payload.type +
            ' paragraph:' +
            payload.paraid,
        },
      })
    } else {
      DBinsertonesync(TABLENAME, writeCommentEx)
      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.ADDCOMMENT',
          detail: payload.comment,
          context: 'type:' + payload.type + ' paragraph:' + payload.paraid,
        },
      })
    }
  },

  deleteComment({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBdeletedocchange(TABLENAME, payload, payload.commentid)
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.DELETECOMMENT',
          detail: payload.commentid,
          context: 'docid:' + payload.docid,
        },
      })
    } else {
      DBdeactivate(TABLENAME, payload.commentid)
      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.DELETECOMMENT',
          detail: payload.commentid,
          context: '',
        },
      })
    }
  },
}
