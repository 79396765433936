<script>
import { panelComputed, dbauthComputed, syncviewComputed, dbintegrationComputed } from '@state/helpers'

// import ALLOWEDPRIVILEGES from '@src/constants/dbconstants'
import {
  getCategoryNames,
  getDisplayCategoryforCatID,
  getCatIDFromUID,
  getCategoryListNames,
} from '@utils/clauseMapping'

import { compareOrder, docroute } from '@utils/dialogutils'

export default {
  components: {},

  props: {
    authlevel: {
      type: String,
      default: () => 'A',
    },
    panelno: {
      type: Number,
      default: () => 0,
    },
    sync: {
      type: Boolean,
      default: false,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    eligibledocxin: {
      type: Boolean,
      required: true,
    },
    effectiveTopic: {
      type: Object,
      default: () => {},
    },
    paracontext: {
      type: String,
      required: true,
    },
    panelstated: {
      type: Object,
      default: () => {},
    },
    // used to indicate if doc loaded but may not be saved.
    documentAvailable: {
      type: Boolean,
      default: false,
    },
    compressedrec: {
      type: Boolean,
      required: true,
    },
    englishtranslaterec: {
      type: Boolean,
      required: true,
    },
    englishavail: {
      type: Boolean,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    editsonlyview: {
      type: Boolean,
      required: true,
    },
    showcollaborativeedits: {
      type: Boolean,
      required: true,
    },
    shownegotiationedits: {
      type: Boolean,
      required: true,
    },
    compareon: {
      type: Boolean,
      required: true,
    },
    comparedocname: {
      type: String,
      required: true,
    },
    integrationon: {
      type: Boolean,
      required: true,
    },
    integtype: {
      type: String,
      required: true,
    },
    integname: {
      type: String,
      required: true,
    },
    integcreds: {
      type: String,
      required: true,
    },
    integparas: {
      type: String,
      required: true,
    },
    integspecs: {
      type: String,
      required: true,
    },
    ifhubtype: {
      type: Boolean,
      required: true,
    },

  },

  data: function() {
    return {
      selectivity: 0,
      // sync: false,
      localsync: this.sync,
      itemMenu: '',

      // compare
      lcompoptnonsequential: false,

      lparacontext: '',

      BETAPROGRAM: [
        'john@mylegaleinstein.com',
        // 'svreedenburgh@revctr.com',
        // 'britany.kerber.llanos@pwc.com',
        // 'jan.sarsanedas.coll@pwc.com',
        // 'lucila.cano.lopez@pwc.com',
        // 'pablo@pablofb.com',
        // 'patricia.manca.diaz@pwc.com',
        // 'pedro.palomino.toledano@pwc.com',
        // 'rocio.catala.martinez@pwc.com',
        // 'sara.depablo.yanguez@pwc.com',
        // 'szilard.k.kaltenecker@pwc.com',
        // 'ngiesecke@aperianglobal.com',
        // 'daniel.trier@nexpert.ch',
      ],
    }
  },

  computed: {
    ...panelComputed,
    ...dbauthComputed,
    ...syncviewComputed,
    ...dbintegrationComputed,

    menuname() {
      return this.$t('MENU.NAME')
    },

    itemsFile() {
      return [
        {
          title: this.$t('MENU.NEWDOCUMENT'),

          action: 'NewDoc', // action deployed at panel
          minauth: 'A', // min authorization level that allows this function.
          minvisible: 'A', // min authorization level that allows them to see this function.
        },
        {
          title: this.$t('MENU.SAVEDOCUMENT'),
          action: 'SaveNewDoc',
          minauth: 'C',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.OPENDOCUMENT'),
          action: 'OpenDoc',
          minauth: 'B',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.OPENGROUPDOCUMENT'),
          action: 'OpenGroupDoc',
          minauth: 'G',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.OPENARCHIVEDOCUMENT'),
          action: 'OpenArchiveDoc',
          minauth: 'G',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.OPENPLAYBOOK'),
          action: 'OpenPlaybook',
          minauth: 'G',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.OPENTEMPLATE'),
          action: 'OpenTemplate',
          minauth: 'G',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.COMPAREDOCUMENT'),
          action: 'CompareDoc',
          minauth: 'C',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.COPYDOCUMENT'),
          action: 'CopyDoc',
          minauth: 'C',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.CREATEVERSION'),
          action: 'CreateVersion',
          minauth: 'C',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.CREATEDOCFROMTEMPLATE'),
          action: 'CreateDocTemplate',
          minauth: 'C',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.SAVEASPLAYBOOK'),
          action: 'SavePlaybook',
          minauth: 'G',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.SAVEASTEMPLATE'),
          action: 'SaveTemplate',
          minauth: 'G',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.MOVETOARCHIVE'),
          action: 'SaveArchive',
          minauth: 'G',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.CLOSEDOCUMENT'),
          action: 'CloseDocument',
          minauth: 'A',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.DOCUMENTLOG'),
          action: 'DocumentLog',
          minauth: 'G',
          minvisible: 'C',
        },
      ]
    },

    itemsEdit() {
      return [
        {
          title: this.$t('MENU.GOTOPARAGRAPH'),
          action: 'GotoParagraph',
          minauth: 'A',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.EXPANDCOLLAPSE'),
          action: 'Expand/Collapse',
          minauth: 'A',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.ENGLISHTRANSLATE'),
          action: 'EnglishTranslate',
          minauth: 'A',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.EDITSONLYVIEW'),
          action: 'EditsOnlyView',
          minauth: 'B',
          minvisible: 'B',
        },
        {
          title: this.$t('MENU.SHOWCOLLABORATIVEEDITS'),
          action: 'ShowCollaborativeEdits',
          minauth: 'B',
          minvisible: 'B',
        },
        {
          title: this.$t('MENU.SHOWNEGOTIATIONEDITS'),
          action: 'ShowNegotiationEdits',
          minauth: 'B',
          minvisible: 'B',
        },
        {
          title: this.$t('MENU.CLAUSETOPICS'),
          action: 'ClauseTopics',
          minauth: 'C',
          minvisible: 'B',
        },
        {
          title: '------------------------',
          action: 'divider',
          minauth: 'A',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.DOCUMENTNOTES'),
          action: 'DocumentNotes',
          minauth: 'B',
          minvisible: 'B',
        },
        {
          title: this.$t('MENU.COLLABCOMMENTS'),
          action: 'CollabComments',
          minauth: 'B',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.COLLABDOCEDITS'),
          action: 'CollabDocEdits',
          minauth: 'B',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.COLLABRISKANDLABELS'),
          action: 'CollabLabels',
          minauth: 'G',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.NEGOTCOMMENTS'),
          action: 'NegotiationComments',
          minauth: 'B',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.NEGOTDOCEDITS'),
          action: 'NegotiationDocEdits',
          minauth: 'B',
          minvisible: 'A',
        },
      ]
    },

    itemsAction() {
      return [
        {
          title: this.$t('MENU.COLLABORATION'),
          action: 'Collaboration',
          minauth: 'C',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.NEGOTIATION'),
          action: 'Negotiation',
          minauth: 'C',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.ADDOWNER'),
          action: 'AddOwner',
          minauth: 'C',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.ADDCOLLABORATOR'),
          action: 'AddCollaborator',
          minauth: 'C',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.ADDNEGOTIATOR'),
          action: 'AddNegotiator',
          minauth: 'B',
          minvisible: 'B',
        },
        {
          title: this.$t('MENU.TRIGGERALERTS'),
          action: 'TriggerAlerts',
          minauth: 'G',
          minvisible: 'C',
        },
        {
          title: '------------------------',
          action: 'divider',
          minauth: 'A',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.DOWNLOADSOURCE'),
          action: 'ExportWord',
          minauth: 'C',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.EXPORTEXCEL'),
          action: 'ExportExcel',
          minauth: 'C',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.DOWNLOADREDLINE'),
          action: 'DownloadRedline',
          minauth: 'C',
          minvisible: 'A',
        },
        {
          title: this.$t('MENU.ESIGNATURE'),
          action: 'Esignature',
          minauth: 'G',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.SENDTOCLM'),
          action: 'SendDoctoCLM',
          minauth: 'J',
          minvisible: 'C',
        },
        {
          title: this.$t('MENU.EXPORTJSON'),
          action: 'ExportJSON',
          minauth: 'Z',
          minvisible: 'Z',
        },
      ]
    },

    // initializes value
    getMenu() {
      return 'Hello'
    },

    selectivityD() {
      if (this.selectivity === 0) {
        return '--'
      } else if (this.selectivity === 1) {
        return 'LO'
      } else if (this.selectivity === 2) {
        return 'ME'
      } else if (this.selectivity === 3) {
        return 'HI'
      } else {
        return 'UN'
      }
    },

    effectiveTopicD() {
      const translatedAll = this.$t('TOPIC.ALL')
      if (this.effectiveTopic === {}) {
        return translatedAll
      } else if (typeof this.effectiveTopic === 'undefined') {
        return translatedAll
      } else if (this.effectiveTopic.action === 'ALL') {
        return translatedAll
      } else {
        return this.displayCat(
          getDisplayCategoryforCatID(this.effectiveTopic.action)
        )
      }
    },

    itemTopics() {
      var catNamestemp = getCategoryListNames()

      // add the categories for the doctype if docType
      if (this.localpanelstate.ifDocType) {
        // docTypeDetailofDoc is only filled in if user is a member of the group that has access to the user group.
        if (
          this.localpanelstate.docTypeDetailofDoc !== null &&
          this.localpanelstate.docTypeDetailofDoc !== undefined
        ) {
          let doctypecategories = this.localpanelstate.docTypeDetailofDoc
            .addlclauses
          doctypecategories.forEach((e) =>
            catNamestemp.push({
              id: e,
              long: e,
              order: '005',
              description: 'custom',
            })
          )
        }
      }

      const translatedAll = this.$t('TOPIC.ALL')

      // get the counts
      let clauseCount = {}
      if (this.docRawData[this.panelno] !== null) {
        if (
          this.docRawData[this.panelno] !== null &&
          this.docRawData[this.panelno] !== undefined
        ) {
          Object.values(this.docRawData[this.panelno].clauseId).forEach(
            (clause) => {
              const key = getCatIDFromUID(clause.uid)
              // adds count if exists.
              clauseCount[key] = clauseCount[key] + 1 || 1
            }
          )
        }
        // add for ALL
        let sum = 0
        for (let count of Object.values(clauseCount)) {
          sum += count
        }
        clauseCount[translatedAll] = sum
      }

      var arrayReturn = []
      arrayReturn.push({
        title: clauseCount[translatedAll]
          ? '(' + clauseCount[translatedAll] + ') ' + translatedAll
          : '(0) ' + translatedAll,
        action: 'ALL',
        minauth: 'A',
        minvisible: 'A',
        order: '000',
      })

      catNamestemp.forEach((element) =>
        arrayReturn.push({
          title: clauseCount[element.id]
            ? '(' +
              clauseCount[element.id] +
              ') ' +
              this.displayCat(element.long)
            : '(0) ' + this.displayCat(element.long),

          action: element.id,
          order: element.order,
          minauth: 'A',
          minvisible: 'A',
        })
      )
      return arrayReturn.sort(compareOrder)
    },

    synccolor() {
      if (this.localsync) {
        return 'red darken-1'
      } else {
        return 'grey lighten-2'
      }
    },
    displaySelectivity() {
      switch (this.selectivity) {
        case 0:
          return 'STRICT'
        case 1:
          return 'LOW'
        case 2:
          return 'MED'
        case 3:
          return 'HIGH'
        default:
          return 'dont know'
      }
    },
    filterItemsFile() {
      // Check for null state for input since it's moved to a getter.
      if (this.itemsEdit === undefined) {
        return []
      }

      // if Document already Saved, then change title to Document Settings
      var filteredItemsFile = this.itemsFile

      var objIndexSaveDoc = filteredItemsFile.findIndex(
        (obj) => obj.action === 'SaveNewDoc'
      )
      if (objIndexSaveDoc > 0) {
        if (this.localpanelstate.ifSaved) {
          filteredItemsFile[objIndexSaveDoc].title = this.$t(
            'MENU.DOCUMENTSETTINGS'
          )
        } else {
          filteredItemsFile[objIndexSaveDoc].title = this.$t(
            'MENU.SAVEDOCUMENT'
          )
        }
      }

      // filter based on an visibility settings
      filteredItemsFile = filteredItemsFile.filter(
        (e) => e.minvisible <= this.authlevel
      )

      // definitions -
      // !documentAvailable = file not processed = empty
      // !ifDocLoaded = not saved

      // if no document loaded, not allowed to save or close
      if (!this.documentAvailable) {
        filteredItemsFile = filteredItemsFile.filter(
          (e) =>
            !['SaveNewDoc', 'CloseDocument', 'CompareDoc'].includes(e.action)
        )
      }

      // if it's loaded (as in new document), it will allow saving.
      if (!this.localpanelstate.ifDocLoaded) {
        filteredItemsFile = filteredItemsFile.filter(
          (e) =>
            ![
              'CopyDoc',
              'CreateVersion',
              'CloseDocument',
              'CompareDoc',
            ].includes(e.action)
        )
      }

      // only have this option if DocLoaded and Template
      if (
        !this.localpanelstate.ifDocLoaded ||
        !this.localpanelstate.ifTemplate
      ) {
        filteredItemsFile = filteredItemsFile.filter(
          (e) => !['CreateDocTemplate'].includes(e.action)
        )
      }

      // filter based on panelState
      // only owner or doctype owners get to do the following functions
      if (!this.localpanelstate.ownerPriv) {
        filteredItemsFile = filteredItemsFile.filter(
          (e) => !['DocumentLog', 'DocumentTypes'].includes(e.action)
        )
      }

      // When can someone Save Document or Change Document?

      // only Doc Type Owners get to Save Playbook and Save Approved.
      if (
        !this.localpanelstate.ifDocTypeOwner ||
        this.localpanelstate.ifArchivedState
      ) {
        filteredItemsFile = filteredItemsFile.filter(
          (e) => !['SavePlaybook', 'SaveTemplate'].includes(e.action)
        )
      }

      // only owners can move to archive
      if (!this.localpanelstate.ownerPriv) {
        filteredItemsFile = filteredItemsFile.filter(
          (e) => !['SaveArchive'].includes(e.action)
        )
      }

      // negotiators cannot copy documents
      if (this.localpanelstate.negotiatorPriv) {
        filteredItemsFile = filteredItemsFile.filter(
          (e) => !['CopyDoc', 'CreateVersion', 'CompareDoc'].includes(e.action)
        )
      }

      // If not a docx, disallow CreateVersion
      if (!this.eligibledocxin) {
        filteredItemsFile = filteredItemsFile.filter(
          (e) => !['CreateVersion'].includes(e.action)
        )
      }

      // if in Archive State, disable functionality
      if (this.localpanelstate.ifArchivedState) {
        filteredItemsFile = filteredItemsFile.filter(
          (e) =>
            ![
              // 'SaveNewDoc',
              'SaveArchive',
            ].includes(e.action)
        )
      }



      // BETA PROGRAM
      // Delete when finalized.
      // if (!this.BETAPROGRAM.includes(this.localpanelstate.dbauthuser.uniqid)) {
      //   filteredItemsFile = filteredItemsFile.filter(
      //     (e) =>
      //       ![
      //         'OpenTemplate',
      //         'CreateDocTemplate',
      //         'SaveTemplate',
      //         // 'CompareDoc',
      //       ].includes(e.action)
      //   )
      // }

      // If HUB enabled, then add it to as an option, not hub docuyments
      if (process.env.VUE_APP_HUB_ENABLED === 'TRU'){
        filteredItemsFile.push( {
          title: this.$t('MENU.OPENHUBDOCUMENT'),
          action: 'OpenHubDoc', // action deployed at panel
          minauth: 'G', // min authorization level that allows this function.
          minvisible: 'G', // min authorization level that allows them to see this function.
        })
      }

      // If it's a hub document
      if (this.ifhubtype) {
        filteredItemsFile = filteredItemsFile.filter(
          (e) =>
            ![
              'CopyDoc',
              'CreateVersion',
              'SavePlaybook',
              'SaveTemplate',
              'SaveArchive',
            ].includes(e.action)
        )
      }

      // for Community, only allow people in beta program.
      if (process.env.VUE_APP_INSTANCETYPE === 'ENTERPRISE' || (this.localpanelstate.dbauthuser !== null && this.BETAPROGRAM.includes(this.localpanelstate.dbauthuser.uniqid))){


        // Add Integration options
        if (this.dbintegrationConfig.integ1name !== 'NULL') {
          filteredItemsFile.push( {
            title: this.dbintegrationConfig.integ1name,
            action: this.dbintegrationConfig.integ1name, // action deployed at panel
            minauth: 'C', // min authorization level that allows this function.
            minvisible: 'C', // min authorization level that allows them to see this function.
          })
        }
        if (this.dbintegrationConfig.integ2name !== 'NULL') {
          filteredItemsFile.push( {
            title: this.dbintegrationConfig.integ2name,
            action: this.dbintegrationConfig.integ2name, // action deployed at panel
            minauth: 'C', // min authorization level that allows this function.
            minvisible: 'C', // min authorization level that allows them to see this function.
          })
        }
        if (this.dbintegrationConfig.integ3name !== 'NULL') {
          filteredItemsFile.push( {
            title: this.dbintegrationConfig.integ3name,
            action: this.dbintegrationConfig.integ3name, // action deployed at panel
            minauth: 'C', // min authorization level that allows this function.
            minvisible: 'C', // min authorization level that allows them to see this function.
          })
        }
        if (this.dbintegrationConfig.integ4name !== 'NULL') {
          filteredItemsFile.push( {
            title: this.dbintegrationConfig.integ4name,
            action: this.dbintegrationConfig.integ4name, // action deployed at panel
            minauth: 'C', // min authorization level that allows this function.
            minvisible: 'C', // min authorization level that allows them to see this function.
          })
        }

      }

      // filter to adjust the slow loading issue for integrations
      // exclude ones that do not display anything.
      filteredItemsFile = filteredItemsFile.filter(
        (e) =>
          ![undefined].includes(e.action)
      )

      return filteredItemsFile
    },
    filterItemsEdit() {
      // Check for null state for input since it's moved to a getter.
      if (this.itemsEdit === undefined) {
        return []
      }

      var filteredItemsEdit = this.itemsEdit

      // Toggles Collapse vs. Expand based on compressed mode of panel
      var objIndex = filteredItemsEdit.findIndex(
        (obj) => obj.action === 'Expand/Collapse'
      )
      if (objIndex > 0) {
        if (this.compressedrec) {
          filteredItemsEdit[objIndex].title = this.$t('MENU.EXPAND')
        } else {
          filteredItemsEdit[objIndex].title = this.$t('MENU.COLLAPSE')
        }
      }

      // Toggles Translate to English
      var objIndexEnglish = filteredItemsEdit.findIndex(
        (obj) => obj.action === 'EnglishTranslate'
      )
      if (objIndexEnglish > 0) {
        if (this.englishtranslaterec) {
          filteredItemsEdit[objIndexEnglish].title = this.$t(
            'MENU.ORIGINALLANGUAGE'
          )
        } else {
          filteredItemsEdit[objIndexEnglish].title = this.$t(
            'MENU.TRANSLATETOENGLISH'
          )
        }
      }

      // Toggles Editted Sections vs. All Sections based on compressed mode of panel
      var objIndexEditsView = filteredItemsEdit.findIndex(
        (obj) => obj.action === 'EditsOnlyView'
      )

      if (objIndexEditsView > 0) {
        if (this.editsonlyview) {
          filteredItemsEdit[objIndexEditsView].title = this.$t(
            'MENU.ALLPARAGRAPHS'
          )
        } else {
          filteredItemsEdit[objIndexEditsView].title = this.$t(
            'MENU.EDITEDPARAGRAPHSONLY'
          )
        }
      }

      // Toggles Edit Clauses vs. No Edit Clauses on compressed mode of panel
      var objIndexClauseTopics = filteredItemsEdit.findIndex(
        (obj) => obj.action === 'ClauseTopics'
      )
      if (objIndexClauseTopics > 0) {
        if (this.editable) {
          filteredItemsEdit[objIndexClauseTopics].title = this.$t(
            'MENU.NOEDITCLAUSELABELS'
          )
        } else {
          filteredItemsEdit[objIndexClauseTopics].title = this.$t(
            'MENU.EDITCLAUSELABELS'
          )
        }
      }

      // Toggles Show Collaborative Edits vs. Hide Collaborative Edits on compressed mode of panel
      var objIndexCollaborativeEdits = filteredItemsEdit.findIndex(
        (obj) => obj.action === 'ShowCollaborativeEdits'
      )
      if (this.showcollaborativeedits) {
        filteredItemsEdit[objIndexCollaborativeEdits].title = this.$t(
          'MENU.HIDEEDITS'
        )
      } else {
        filteredItemsEdit[objIndexCollaborativeEdits].title = this.$t(
          'MENU.SHOWEDITS'
        )
      }

      // Toggles Show Negotiation Edits vs. Hide Negotiation Edits on compressed mode of panel
      var objIndexNegotiationEdits = filteredItemsEdit.findIndex(
        (obj) => obj.action === 'ShowNegotiationEdits'
      )
      if (this.shownegotiationedits) {
        filteredItemsEdit[objIndexNegotiationEdits].title = this.$t(
          'MENU.SHOWALLEDITS'
        )
      } else {
        filteredItemsEdit[objIndexNegotiationEdits].title = this.$t(
          'MENU.SHOWNEGOTEDITSONLY'
        )
      }

      // english translation only available for international texts.
      if (!this.englishavail) {
        filteredItemsEdit = filteredItemsEdit.filter(
          (e) => !['EnglishTranslate'].includes(e.action)
        )
      }

      // only show negotation edit =s if ShowColalborativeEdits is on
      if (!this.showcollaborativeedits || this.localpanelstate.negotiatorPriv) {
        filteredItemsEdit = filteredItemsEdit.filter(
          (e) => !['ShowNegotiationEdits'].includes(e.action)
        )
      }

      // filter based on an visibility settings
      filteredItemsEdit = filteredItemsEdit.filter(
        (e) => e.minvisible <= this.authlevel
      )

      if (!this.localpanelstate.ifDocLoaded || !this.localpanelstate.ifSaved) {
        filteredItemsEdit = filteredItemsEdit.filter(
          (e) =>
            ![
              'DocumentNotes',
              'EditsOnlyView',
              'ShowCollaborativeEdits',
              'ShowNegotiationEdits',
              'ClauseTopics',
              'CollabComments',
              'CollabDocEdits',
              'CollabLabels',
              'NegotiationComments',
              'NegotiationDocEdits',
              'divider',
            ].includes(e.action)
        )
      }

      if (!this.localpanelstate.ifDocLoaded) {
        filteredItemsEdit = filteredItemsEdit.filter(
          (e) => !['GotoParagraph', 'Expand/Collapse'].includes(e.action)
        )
      }

      // if negotiator, then cannot see the Collaboration comments.
      if (this.localpanelstate.negotiatorPriv) {
        filteredItemsEdit = filteredItemsEdit.filter(
          (e) =>
            ![
              'DocumentNotes',
              'ClauseTopics',
              'CollabComments',
              'CollabDocEdits',
              'CollabLabels',
              'ShowCollaborativeEdits',
            ].includes(e.action)
        )
      }

      // if negotiation state is not turned on, don't include the settings on the menu.
      if (!this.localpanelstate.NegotiationAvail) {
        filteredItemsEdit = filteredItemsEdit.filter(
          (e) =>
            ![
              'NegotiationComments',
              'NegotiationDocEdits',
              'ShowNegotiationEdits',
            ].includes(e.action)
        )
      }

      // if in Archive State, disable functionality
      if (this.localpanelstate.ifArchivedState) {
        filteredItemsEdit = filteredItemsEdit.filter(
          (e) => !['ClauseTopics'].includes(e.action)
        )
      }

      // if the document is specified with false in the Doc Settings for docallowcollclauselabels and collaborator
      // adjusted to allow for owners of document to access clause topics.
      if (
        this.localpanelstate.collaboratorPriv &&
        !this.localpanelstate.ownerPriv
      ) {
        if (!this.localpanelstate.docmeta.docallowcollclauselabels) {
          filteredItemsEdit = filteredItemsEdit.filter(
            (e) => !['ClauseTopics'].includes(e.action)
          )
        }
      }

      // if HUB document, restrict below functions:
      if (this.ifhubtype) {
        filteredItemsEdit = filteredItemsEdit.filter(
          (e) =>
            ![
              'ClauseTopics'
            ].includes(e.action)
        )
      }

      return filteredItemsEdit
    },
    filterItemsAction() {
      // Check for null state for input since it's moved to a getter.
      if (this.itemsAction === undefined) {
        return []
      }

      // filter based on panelState
      // only owner or doctype owners get to do the following functions
      let filteredItemsAction = this.itemsAction.filter(
        (e) => e.minvisible <= this.authlevel
      )

      // if no Doc Loaded, then Action Options are not available.
      if (!this.localpanelstate.ifDocLoaded || !this.localpanelstate.ifSaved) {
        //  if (!this.documentAvailable) {
        filteredItemsAction = filteredItemsAction.filter(
          (e) =>
            ![
              'TriggerAlerts',
              'ExportExcel',
              'ExportWord',
              'SendDoctoCLM',
              'AddOwner',
              'AddCollaborator',
              'Collaboration',
              'AddNegotiator',
              'Negotiation',
              'Esignature',
              'DownloadRedline',
              'divider',
            ].includes(e.action)
        )
      }

      // have to be an owner to change document settings
      if (!this.localpanelstate.ownerPriv) {
        filteredItemsAction = filteredItemsAction.filter(
          (e) =>
            ![
              'AddOwner',
              'AddCollaborator',
              'Collaboration',
              'Negotiation',
              'Esignature',
              'DownloadRedline',
              'divider',
            ].includes(e.action)
        )
      }

      // Have to be either an owner or negotiator to add negotiators.
      if (this.localpanelstate.collaboratorPriv) {
        filteredItemsAction = filteredItemsAction.filter(
          (e) => !['AddNegotiator'].includes(e.action)
        )
      }

      // if not a DocType, hide Trigger Alerts
      if (!this.localpanelstate.ifDocType) {
        filteredItemsAction = filteredItemsAction.filter(
          (e) => !['TriggerAlerts'].includes(e.action)
        )
      }

      // if Negotiator, then can't see collaboration or change negotiations
      if (this.localpanelstate.negotiatorPriv) {
        filteredItemsAction = filteredItemsAction.filter(
          (e) =>
            ![
              'TriggerAlerts',
              'AddOwner',
              'AddCollaborator',
              'Collaboration',
              'Negotiation',
              'Esignature',
              'DownloadRedline',
              '',
            ].includes(e.action)
        )
      }

      // If not a docx, disallow DownloadReadline and Esignatures
      if (!this.eligibledocxin) {
        filteredItemsAction = filteredItemsAction.filter(
          (e) => !['DownloadRedline', 'Esignature'].includes(e.action)
        )
      }

      // if in Archive State, disable functionality
      if (this.localpanelstate.ifArchivedState) {
        filteredItemsAction = filteredItemsAction.filter(
          (e) =>
            ![
              'TriggerAlerts',
              // Archived - owners can still change who has access to archived document.
              // 'AddOwner',
              // 'AddCollaborator',
              // 'AddNegotiator',
              'Collaboration',
              'Negotiation',
              'Esignature',
              'DownloadRedline',
            ].includes(e.action)
        )
      }

      // if HUB document, restrict below functions:
      if (this.ifhubtype) {
        filteredItemsAction = filteredItemsAction.filter(
          (e) =>
            ![
              'TriggerAlerts',
              'ExportExcel',
              'ExportWord',
              'SendDoctoCLM',
              'Esignature',
              'DownloadRedline',
            ].includes(e.action)
        )
      }

      return filteredItemsAction
    },

    smallnav() {
      return this.panelsToShow > 2
    },

    xsmallnav() {
      return this.panelsToShow > 4
    },

    compareOptionIcon() {
      if (this.lcompoptnonsequential) {
        return 'mdi-unfold-more-vertical'
      }
      return 'mdi-chevron-double-down'
    },

    // Integration
    integrationname(){
      return this.integname
    }
  },
  watch: {
    sync(newValue, oldValue) {
      this.localsync = newValue
    },

    paracontext(newValue, oldValue) {
      if (newValue !== '') {
        if (this.localsync){
          this.lparacontext=newValue
        }
      }
    },
  },
  methods: {
    processTopic(cat) {
      // every time you set the a new topic, it should go back to 0 selectivity.
      this.selectivity = 0
      this.$emit('processTopicSelectivity', this.selectivity)

      this.$emit('processTopic', cat)
    },
    process(menuitem) {
      this.itemMenu = menuitem
      this.$emit('processNav', menuitem)
    },
    incrementSelectivity() {
      this.selectivity++
      this.$emit('processTopicSelectivity', this.selectivity)
    },
    decrementSelectivity() {
      this.selectivity--
      this.$emit('processTopicSelectivity', this.selectivity)
    },

    processStopCompare() {
      this.$emit('compareStop')
    },
    processStopIntegration() {
      this.lparacontext = ''
      this.$emit('integrationStop')
    },

    setOptionsCompare() {
      this.lcompoptnonsequential = !this.lcompoptnonsequential
      this.$emit('compareSetOptions', {
        compoptnonsequential: this.lcompoptnonsequential,
      })
    },

    processSync() {
      this.localsync = !this.localsync
      this.$emit('processSync', this.localsync)
    },
    getDocInfo() {
      this.$emit('processNav', { action: 'PanelInfo' })
    },
    // Do I need to set the locale in the panel-navigation
    setLocale(language) {
      this.$i18n.locale = language
      this.$root.$i18n.locale = language
      // sets it but change is not immediate.  Don't see the way to make this an immediate change yet.
    },

    // displays the internationalized name of the topic.
    displayCat(longid) {
      var catNamestemp = getCategoryNames()
      if (catNamestemp.includes(longid)) {
        return this.$t('TOPIC.' + longid)
      }
      return longid
    },

    parachanged(){
      this.$emit('integrationRefresh', this.lparacontext, this.integname)
    },

    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-card width="100%" :class="$style.navcard">
    <div class="pt-1 mt-10"> .</div>
    <div class="pt-1 mt-4"> .</div>
    <v-toolbar v-if="!compareon && !integrationon" flat height="35px" padding="2px">
      <v-row justify="space-between">
        <v-card-actions>
          <v-menu offset-y>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltipA }">
                  <v-btn
                    :fab="smallnav"
                    :small="smallnav && !xsmallnav"
                    :x-small="xsmallnav"
                    outlined
                    class="ml-1"
                    v-bind="attrs"
                    v-on="{ ...tooltipA, ...menu }"
                  >
                    {{ $t('TOPMENU.FILE') }}
                  </v-btn>
                </template>
                <span>File Options - Open Documents, Templates, Save</span>
              </v-tooltip>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in filterItemsFile"
                :key="i"
                :disabled="authlevel < item.minauth"
                @click="process(item)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltipB }">
                  <v-btn
                    :fab="smallnav"
                    :small="smallnav && !xsmallnav"
                    :x-small="xsmallnav"
                    outlined
                    class="ml-1"
                    v-bind="attrs"
                    v-on="{ ...tooltipB, ...menu }"
                  >
                    {{ $t('TOPMENU.EDIT') }}
                  </v-btn>
                </template>
                <span>Document Edits - Comments, Approvals</span>
              </v-tooltip>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in filterItemsEdit"
                :key="i"
                :disabled="authlevel < item.minauth"
                @click="process(item)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltipE }">
                  <v-btn
                    :fab="smallnav"
                    :small="smallnav && !xsmallnav"
                    :x-small="xsmallnav"
                    outlined
                    class="ml-1"
                    v-bind="attrs"
                    v-on="{ ...tooltipE, ...menu }"
                  >
                    {{ $t('TOPMENU.ACT') }}
                  </v-btn>
                </template>
                <span>Collaboration, Document Actions</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, i) in filterItemsAction"
                :key="i"
                :disabled="authlevel < item.minauth"
                @click="process(item)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
        <v-spacer></v-spacer>
        <v-btn x-large icon @click="getDocInfo">
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-menu offset-x max-height="600px">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltipF }">
                  <v-btn
                    :fab="smallnav"
                    :small="smallnav && !xsmallnav"
                    :x-small="xsmallnav"
                    outlined
                    class="ml-1"
                    v-bind="attrs"
                    v-on="{ ...tooltipF, ...menu }"
                  >
                    {{ $t('MENU.TOPIC') }}
                  </v-btn>
                </template>
                <span>Navigate by clause topics</span>
              </v-tooltip>
            </template>
            <v-list overflow-y dense>
              <v-list-item
                v-for="(item, i) in itemTopics"
                :key="i"
                :disabled="false"
                xsmall
                @click="processTopic(item)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            :fab="smallnav"
            :small="smallnav && !xsmallnav"
            :x-small="xsmallnav"
            outlined
            class="ml-1"
            :disabled="selectivity === 0"
            @click="decrementSelectivity"
          >
            <v-icon dark>mdi-minus</v-icon>
          </v-btn>

          <v-btn
            :fab="smallnav"
            :small="smallnav && !xsmallnav"
            :x-small="xsmallnav"
            outlined
            class="ml-1"
            :disabled="selectivity === 3"
            @click="incrementSelectivity"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            :fab="smallnav"
            :small="smallnav && !xsmallnav"
            :x-small="xsmallnav"
            outlined
            class="ml-1"
            :class="synccolor"
            @click="processSync"
          >
            <v-icon>mdi-autorenew</v-icon>
            <v-progress-circular
              v-if="false"
              indeterminate
              color="green"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-row>
    </v-toolbar>
    <v-toolbar
      v-if="localpanelstate.ifDocType && !compareon && !integrationon"
      flat
      class="mt-1 pt-1"
    >
      <v-container fluid>
        <v-row class="mt-n1 mb-n2" no-gutters>
          <v-col cols="3" xs="3">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.NAME')"
              :value="panelstated.docnameD"
            ></v-text-field>
          </v-col>
          <v-col cols="2" xs="2">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.TYPE')"
              :value="panelstated.doctypeD"
            ></v-text-field>
          </v-col>
          <v-col cols="2" xs="2">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.GROUP')"
              :value="panelstated.groupD"
            ></v-text-field>
          </v-col>
          <v-col cols="1" xs="1">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.ROLE')"
              :value="panelstated.privilegeD"
            ></v-text-field>
          </v-col>
          <v-col cols="1" xs="1">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.STATE')"
              :value="panelstated.docStateD"
            ></v-text-field>
          </v-col>
          <v-col cols="1" sm="1">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.SENSIT')"
              :value="selectivityD"
            ></v-text-field>
          </v-col>
          <v-col cols="2" sm="2">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.TOPIC')"
              :value="effectiveTopicD"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-toolbar
      v-if="!localpanelstate.ifDocType && !compareon && !integrationon"
      flat
      class="mt-1 pt-1"
    >
      <v-container fluid>
        <v-row class="mt-n1 mb-n4" no-gutters>
          <v-col cols="7" xs="7">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.NAME')"
              :value="panelstated.docnameD"
            ></v-text-field>
          </v-col>
          <v-col cols="1" xs="1">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.ROLE')"
              :value="panelstated.privilegeD"
            ></v-text-field>
          </v-col>
          <v-col cols="1" xs="1">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.STATE')"
              :value="panelstated.docStateD"
            ></v-text-field>
          </v-col>
          <v-col cols="1" sm="1">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.SENSIT')"
              :value="selectivityD"
            ></v-text-field>
          </v-col>
          <v-col cols="2" sm="2">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.TOPIC')"
              :value="effectiveTopicD"
            ></v-text-field>
            {{ getMenu.NEWDOCUMENT }}
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-toolbar
      v-if="compareon"
      flat
      height="35px"
      padding="2px"
    >
      <v-row justify="space-between">
        <v-container fluid>
          <v-row no-gutters>
            <div class="mx-3 mt-2">
              {{ $t('MENU.DOCCOMPMODE') }}
            </div>
            <v-spacer></v-spacer>

            <v-btn small icon @click="docroute('file/comparedocument')">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              :fab="smallnav"
              :small="smallnav && !xsmallnav"
              :x-small="xsmallnav"
              outlined
              class="ml-1"
              @click="setOptionsCompare"
            >
              <v-icon> {{ compareOptionIcon }}</v-icon></v-btn
            ><v-btn
              :fab="smallnav"
              :small="smallnav && !xsmallnav"
              :x-small="xsmallnav"
              outlined
              class="ml-1"
              @click="processStopCompare"
            >
              {{ $t('MENU.EXIT') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-row>
    </v-toolbar>

    <v-toolbar v-if="compareon" flat class="mt-1 pt-1">
      <v-container fluid>
        <v-row class="mt-n1 mb-n4" no-gutters>
          <v-col cols="5" xs="5">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.SOURCEDOCUMENT')"
              :value="panelstated.docnameD"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="5" xs="5">
            <v-text-field
              readonly
              dense
              :label="$t('MENU.COMPARISONDOCUMENT')"
              :value="comparedocname"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-toolbar
      v-if="integrationon"
      flat
      height="35px"
      padding="2px"
    >
      <v-row justify="space-between">
        <v-container fluid>
          <v-row no-gutters>
            <div class="mx-3 mt-2">
              {{ integrationname }}
            </div>
            <v-spacer></v-spacer>

            <v-btn small icon @click="docroute('file/integration')">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              :fab="smallnav"
              :small="smallnav && !xsmallnav"
              :x-small="xsmallnav"
              outlined
              class="ml-1"
              @click="parachanged">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-btn
              :fab="smallnav"
              :small="smallnav && !xsmallnav"
              :x-small="xsmallnav"
              outlined
              class="ml-1"
              @click="processStopIntegration"
            >
              {{ $t('MENU.EXIT') }}
            </v-btn>
            <v-btn
            :fab="smallnav"
            :small="smallnav && !xsmallnav"
            :x-small="xsmallnav"
            outlined
            class="ml-1"
            :class="synccolor"
            @click="processSync"
          >
          <v-icon>mdi-autorenew</v-icon>
            <v-progress-circular
              v-if="false"
              indeterminate
              color="green"
            ></v-progress-circular></v-btn>
          </v-row>
        </v-container>
      </v-row>
    </v-toolbar>

    <div v-if="integrationon" class="pt-1 mt-10"></div>
    <v-toolbar
      v-if="integrationon"
      flat
      class="my-7 px-1">
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12" xs="12">
            <v-textarea
              v-model="lparacontext"
              outlined
              :label="$t('MENU.PARAGRAPH')"
              :class="$style.dialogInputParagraph"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <div v-if="integrationon" class="pt-1 mt-10"></div>
  </v-card>
</template>
<style lang="scss" module>
@import '@design';
</style>
