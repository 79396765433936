// User Authorization model

// doctypes to many groups.

// each group has different privileges to doctype, access rights.
// each group has multiple users.

// individual documents inherits access rights of the doctype, but can also be accessed if granted collaboration request.

// For each user
// 	- determine which groups user belongs to.
// 		- how - from user Profile and check with the group membership.
// 		- how - when user is added to group. add to user profile as well.  So, they know how to find each other.
// 	- determine subscription of user.
// 		- get privileges for that user.
// 		- verify subscription is current.
// For each document, determine which group access the user has for this document.  Then set privileges at the document level.

// Merging auth and groups together since there's a shared use of tables.

import store from '@state/store'

import SnackService from '@services/SnackService'

import {
  DBgetobjsync,
  DBdoctypesquery1,
  DBalertsquery1,
  DBalertsquery2,
  DBalertsquery3,
  DBdocmetaquery1,
  DBdocmetaquery2,
  DBdocmetaquery3,
  DBdocmetaquery4,
  DBdocmetaquery5,
  DBdocmetaquery6,
} from '@/src/state/io/iodb.js'

import {
  // AUTHsetPersistence,
  AUTHsignIn,
  AUTHcreateUserEmailPassword,
} from '@/src/state/io/ioauth.js'

const USERSTABLE = 'users'
const GROUPSTABLE = 'groups'
// const AISUBSCRIPTIONSTABLE = 'aisubscriptions'
const LOGUSERTABLE = 'loguser'
const DOCTYPESTABLE = 'doctypes'

// Replaces aisubscriptions table
const aisubscriptiontable = {
  A: { documentcap: 5, name: 'TRIALUSER', objid: 'A', type: 'A' },
  B: { documentcap: 10, name: 'REFERREDUSER', objid: 'B', type: 'B' },
  C: { documentcap: 25, name: 'BASIC', objid: 'C', type: 'C' },
  C3: { documentcap: 25, name: 'BASICPREPAID', objid: 'C3', type: 'C3' },
  C5: { documentcap: 200, name: 'VIPMEMBER', objid: 'C5', type: 'C5' },
  G: { documentcap: 100, name: 'PREMIUM', objid: 'G', type: 'G' },
  G3: { documentcap: 100, name: 'PREMIUMPREPAID', objid: 'G3', type: 'G3' },
  G5: { documentcap: 10000, name: 'VIPACCESS', objid: 'G5', type: 'G5' },
  G7: { documentcap: 10000, name: 'VIPACCESS', objid: 'G7', type: 'G7' },
  J: { documentcap: 10000, name: 'UNLIMITED', objid: 'J', type: 'J' },
  J5: { documentcap: 10000, name: 'VIPACCESS', objid: 'J5', type: 'J5' },
}

export const state = {
  dbauthuser: null,

  // // for authorization
  dbauthProfile: null,
  dbauthGroupsAccess: [],
  dbauthPrivileges: null,
  dbauthPrivUsage: null,
  dbauthDocTypes: [],
  dbauthAvailDocs: [],
  dbauthAvailGroupDocs: [],
  dbauthAvailHubDocs: [],
  dbauthAvailArchiveDocs: [],
  dbauthAvailPlaybooks: [],
  dbauthAvailTemplates: [],
  dbauthOwnerDocTypes: [],
  dbauthOwnerAlerts: [],
  dbauthDocTypeAlerts: [],

  // user info cache:
  dbauthUserInfoCache: [],

  // debug only
  dbgroupobject: [],
  dbgroupid: 0,

  // errors and other info.
  dbauthreturn: { data: [], message: '', error: '' },
}

// do I need to add getters here?
export const getters = {
  dbauthuser(state) {
    return state.dbauthuser
  },

  dbauthGroupsAccess(state) {
    return state.dbauthGroupsAccess
  },
  dbauthPrivileges(state) {
    return state.dbauthPrivileges
  },
  dbauthPrivUsage(state) {
    return state.dbauthPrivUsage
  },
  dbauthDocTypes(state) {
    return state.dbauthDocTypes
  },
  dbauthProfile(state) {
    return state.dbauthProfile
  },
  dbauthAvailDocs(state) {
    return state.dbauthAvailDocs
  },
  dbauthAvailGroupDocs(state) {
    return state.dbauthAvailGroupDocs
  },
  dbauthAvailHubDocs(state) {
    return state.dbauthAvailHubDocs
  },
  dbauthAvailArchiveDocs(state) {
    return state.dbauthAvailArchiveDocs
  },
  dbauthAvailPlaybooks(state) {
    return state.dbauthAvailPlaybooks
  },
  dbauthAvailTemplates(state) {
    return state.dbauthAvailTemplates
  },
  dbauthOwnerDocTypes(state) {
    return state.dbauthOwnerDocTypes
  },
  dbauthOwnerAlerts(state) {
    return state.dbauthOwnerAlerts
  },
  dbgroupobject(state) {
    return state.dbgroupobject
  },
  dbgroupid(state) {
    return state.dbgroupid
  },
  dbauthreturn(state) {
    return state.dbauthreturn
  },
  dbauthDocTypeAlerts(state) {
    return state.dbauthDocTypeAlerts
  },
  dbauthUserInfoCache(state) {
    return state.dbauthUserInfoCache
  },
}

export const mutations = {
  INIT_USEDB(state) {
    state.dbauthreturn = { data: [], message: '', error: '' }
  },
  INIT_AUTH(state) {
    state.dbauthuser = null
    state.dbauthProfile = null
    state.dbauthGroupsAccess = []
    state.dbauthPrivileges = []
    state.dbauthPrivUsage = []
    state.dbauthDocTypes = []
    state.dbauthAvailDocs = []
    state.dbauthAvailGroupDocs = []
    state.dbauthAvailHubDocs = []
    state.dbauthAvailArchiveDocs = []
    state.dbauthAvailPlaybooks = []
    state.dbauthAvailTemplates = []
    state.dbauthOwnerDocTypes = []
    state.dbauthOwnerAlerts = []
    state.dbauthDocTypeAlerts = []
    state.dbauthUserInfoCache = []
  },
  SET_DATA(state, data) {
    state.dbauthreturn.data = data
  },
  SET_ERROR(state, error) {
    state.dbauthreturn.error = error
  },
  SET_MESSAGE(state, message) {
    state.dbauthreturn.message = message
  },

  SET_LOGIN_ERROR(state, error) {
    state.dbauthreturn.error = error
  },

  SET_DBAUTHUSER(state, val) {
    state.dbauthuser = val
  },

  SET_DBAUTHPROFILE(state, val) {
    state.dbauthProfile = val
  },
  SET_DBAUTHGROUPSACCESS(state, val) {
    state.dbauthGroupsAccess = val
  },
  ADD_DBAUTHGROUPSACCESS(state, val) {
    state.dbauthGroupsAccess.push(val)
  },
  SET_DBAUTHPRIVILEGES(state, val) {
    state.dbauthPrivileges = val
  },
  SET_DBAUTHPRIVUSAGE(state, val) {
    state.dbauthPrivUsage = val
  },
  SET_DBAUTHDOCTYPES(state, val) {
    state.dbauthDocTypes = val
  },
  ADD_DBAUTHDOCTYPES(state, val) {
    state.dbauthDocTypes.push(val)
  },
  SET_DBAUTHAVAILDOCS(state, val) {
    state.dbauthAvailDocs = val
  },
  ADD_DBAUTHAVAILDOCS(state, val) {
    state.dbauthAvailDocs.push(val)
  },
  SET_DBAUTHAVAILGROUPDOCS(state, val) {
    state.dbauthAvailGroupDocs = val
  },
  ADD_DBAUTHAVAILGROUPDOCS(state, val) {
    state.dbauthAvailGroupDocs.push(val)
  },
  SET_DBAUTHAVAILHUBDOCS(state, val) {
    state.dbauthAvailHubDocs = val
  },
  ADD_DBAUTHAVAILHUBDOCS(state, val) {
    state.dbauthAvailHubDocs.push(val)
  },
  SET_DBAUTHAVAILARCHIVEDOCS(state, val) {
    state.dbauthAvailArchiveDocs = val
  },
  ADD_DBAUTHAVAILARCHIVEDOCS(state, val) {
    state.dbauthAvailArchiveDocs.push(val)
  },
  SET_DBAUTHAVAILPLAYBOOKS(state, val) {
    state.dbauthAvailPlaybooks = val
  },
  ADD_DBAUTHAVAILPLAYBOOKS(state, val) {
    state.dbauthAvailPlaybooks.push(val)
  },
  SET_DBAUTHAVAILTEMPLATES(state, val) {
    state.dbauthAvailTemplates = val
  },
  ADD_DBAUTHAVAILTEMPLATES(state, val) {
    state.dbauthAvailTemplates.push(val)
  },

  SET_DBAUTHOWNERDOCTYPES(state, val) {
    state.dbauthOwnerDocTypes = val
  },
  ADD_DBAUTHOWNERDOCTYPES(state, val) {
    state.dbauthOwnerDocTypes.push(val)
  },

  SET_DBAUTHOWNERALERTS(state, val) {
    state.dbauthOwnerAlerts = val
  },
  ADD_DBAUTHOWNERALERTS(state, val) {
    state.dbauthOwnerAlerts.push(val)
  },

  SET_DBAUTHDOCTYPEALERTS(state, val) {
    state.dbauthDocTypeAlerts = val
  },
  ADD_DBAUTHDOCTYPEALERTS(state, val) {
    state.dbauthDocTypeAlerts.push(val)
  },

  ADD_LOCALAUTHDOCPROCESSED(state) {
    state.dbauthPrivUsage.docsproc += 1
    state.dbauthPrivUsage.docsprocmonth += 1
  },

  SET_USERINFO(state, val) {
    state.dbauthUserInfoCache = val
  },
  ADD_USERINFO(state, val) {
    state.dbauthUserInfoCache.push(val)
  },

  // debug
  SET_GROUPID(state, id) {
    state.dbgroupid = id
  },
}

export const actions = {
  init({ commit }) {
    // initialize the user state
    commit('INIT_USEDB')
    commit('INIT_AUTH')
  },

  // After user is logged on, we set the user to the right user and then extract the information for the other state variables.
  // call is used to set the user.  Various watch will then fill in the user's info.
  setUserAuth({ commit }, uniqid) {
    commit('SET_DBAUTHUSER', uniqid) // set uniqid

    SnackService.info('MESSAGES.USERSUCCESSLOGIN')

    // at a new login, reinitialize all the panels, if not already done.
    store.dispatch('panels/init')
  },

  // logouts current user
  logoutUserAuth({ commit }) {
    SnackService.info('MESSAGES.USERLOGGEDOUT')
    commit('INIT_USEDB')
    commit('INIT_AUTH')
  },

  // login the user with the email and password.
  // modify to persist state at session level for auth.
  loginEmailPassAuth({ commit, state }, payload) {
    // AUTHsetPersistence()
    //   .then(function() {
    AUTHsignIn(payload.uniqid, payload.password)
      .then((data) => {
        store.dispatch('dbauth/setCurrentLoggedInUserAuth', data)
      })
      .catch((e) => {
        store.dispatch('dberror/logError', e)
      })
    // })
    // .catch((e) => {
    //   store.dispatch('dberror/logError', e)
    // })
  },

  // need to verify that it email has been verified.  If not, resend email verification request.
  setCurrentLoggedInUserAuth({ commit, state }, data) {
    const MyThis = this
    // if verified, then set the login, otherwise, resend the verification link.
    if (!data.success) {
      SnackService.info(
        'Login failed - please check your username and password again.'
      )
    } else if (data.emailVerified) {
      store
        .dispatch('dbauth/setUserAuth', {
          uniqid: data.uniqid,
          verified: true,
          token: data.token,
          oldpassword: data.oldpassword,
        })
        .then(
          // log
          store.dispatch('dblogdoc/addLogUserOnly', {
            uniqid: data.uniqid,
            logdetail: {
              action: 'USEREVENTS.USERSIGNON',
              detail: '',
              context: '',
            },
          })
        )
    } else {
      // logged in but not verified
      SnackService.info('MESSAGES.EMAILNOTVERIFIED')
      store.dispatch('dbreg/sendEmailVerificationReqReg', {
        user: data.user,
        payload: { uniqid: data.uniqid },
      })
    }
  },

  setLoginErrorAuth({ commit }, error) {
    commit('SET_LOGIN_ERROR', error)
  },

  setUserProfileAuth({ commit, state }, payload) {
    commit('SET_DBAUTHPROFILE', payload)
  },

  // get users profile from the DB
  getUserProfileAuth({ commit, state }) {
    DBgetobjsync(USERSTABLE, state.dbauthuser.uniqid)
      .then((res) => {
        if (res.exists) {
          var obj = res.data
          obj.keyid = res.id
        } else {
          // special case - for SSO - create a new blank record for first time
          // for bad registration bug - create a new blank record.
          obj = {
            uniqid: state.dbauthuser.uniqid,
            name: state.dbauthuser.uniqid,
            company: '',
            title: '',
            memberid: '',
            subscriptionid:
              process.env.VUE_APP_INSTANCETYPE === 'ENTERPRISE' ? 'G5' : 'A',
            groupid: [],
          }
          store.dispatch('dbreg/createUserProfileReg', obj)
        }

        // insert logic to see if it's canceled or expired subscription, if so, change obj.subscriptionid = 'A'

        commit('SET_DBAUTHPROFILE', obj)
      })
      .catch(function (e) {
        store.dispatch('dberror/logError', e)
      })
  },

  getUserDocTypesAuth({ commit, state }) {
    commit('SET_DBAUTHDOCTYPES', [])
    state.dbauthGroupsAccess.forEach(function (group) {
      group.doctypeid.forEach(function (doctypeid) {
        DBgetobjsync(DOCTYPESTABLE, doctypeid)
          .then((res) => {
            var obj = res.data
            obj.keyid = res.id

            if (state.dbauthDocTypes.findIndex((f) => f.keyid === res.id) < 0) {
              // active only
              if (obj.active === true) {
                commit('ADD_DBAUTHDOCTYPES', obj)
              }
            }
          })
          .catch(function (e) {
            store.dispatch('dberror/logError', e)
          })
      })
    })
    // }
  },

  // Need to implement getUserOwnerDocTypesAuth({}) as a query of doctypes owned by userid (so that it's more inclusive of doctypes that are created by user)
  // expanded definition to include doctypes that user are a member of and can use as collaborator.
  getUserOwnerDocTypesAuth({ commit, state }) {
    commit('SET_DBAUTHOWNERDOCTYPES', [])

    state.dbauthGroupsAccess.forEach(function (e) {
      // owner of doctypes
      if (e.ownerid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBgetobjsync(DOCTYPESTABLE, doctypeid).then(function (doc) {
            if (doc.exists) {
              var obj = doc.data
              obj.keyid = doc.id
              obj.privilege = 'owner'
              if (
                state.dbauthOwnerDocTypes.findIndex((f) => f.keyid === doc.id) <
                0
              ) {
                if (obj.active === true) {
                  commit('ADD_DBAUTHOWNERDOCTYPES', obj)
                }
              }
            } else {
              SnackService.error('error')
            }
          })
        })
      }

      // collaborator of doctypes
      if (e.uniqid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBgetobjsync(DOCTYPESTABLE, doctypeid).then(function (doc) {
            if (doc.exists) {
              var obj = doc.data
              obj.keyid = doc.id
              obj.privilege = 'collaborator'
              if (
                state.dbauthOwnerDocTypes.findIndex((f) => f.keyid === doc.id) <
                0
              ) {
                // only active
                if (obj.active === true) {
                  commit('ADD_DBAUTHOWNERDOCTYPES', obj)
                }
              }
            } else {
              SnackService.error('error')
            }
          })
        })
      }
    })
    // individually owned doctypes (not yet assigned to a group)
    DBdoctypesquery1(state.dbauthuser.uniqid).then(function (querySnapshot) {
      querySnapshot.forEach((doc) => {
        var obj = doc
        obj.keyid = doc._id
        obj.privilege = 'owner'
        if (
          state.dbauthOwnerDocTypes.findIndex((f) => f.keyid === doc._id) < 0
        ) {
          // only active
          if (obj.active === true) {
            commit('ADD_DBAUTHOWNERDOCTYPES', obj)
          }
        }
      })
    })
  },

  // Need to implement getUserOwnerDocTypesAuth({}) as a query of doctypes owned by userid (so that it's more inclusive of doctypes that are created by user)
  getUserOwnerAlertsAuth({ commit, state }) {
    commit('SET_DBAUTHOWNERALERTS', [])

    DBalertsquery1(state.dbauthuser.uniqid).then(function (querySnapshot) {
      querySnapshot.forEach((doc) => {
        var obj = doc
        obj.keyid = doc._id
        obj.privilege = 'owner'
        commit('ADD_DBAUTHOWNERALERTS', obj)
      })
    })

    DBalertsquery2(state.dbauthuser.uniqid).then(function (querySnapshot) {
      querySnapshot.forEach((doc) => {
        var obj = doc
        obj.keyid = doc._id
        obj.privilege = 'recipient'
        if (state.dbauthOwnerAlerts.findIndex((f) => f.keyid === doc._id) < 0) {
          commit('ADD_DBAUTHOWNERALERTS', obj)
        }
      })
    })
  },

  // Gets the alerts that are available for given doctypeid.
  getDocTypeAlertsAuth({ commit, state }, doctypeid) {
    commit('SET_DBAUTHDOCTYPEALERTS', [])

    DBalertsquery3(doctypeid).then(function (querySnapshot) {
      querySnapshot.forEach((doc) => {
        var obj = doc
        obj.keyid = doc._id
        commit('ADD_DBAUTHDOCTYPEALERTS', obj)
      })
    })
  },

  resetUserGroupAccessAuth({ commit, state }) {
    commit('SET_DBAUTHGROUPSACCESS', [])
  },

  // GET THE USER ACCESS AND ASSOCIATED PRIVILEGES FOR EACH DOCTYPE
  getUserGroupAccessAuth({ commit, state }) {
    if (
      state.dbauthProfile !== null &&
      typeof state.dbauthProfile !== 'undefined'
    ) {
      // For each group, get the access privileges for the group and the doctype
      state.dbauthProfile.groupid.forEach(function (groupid) {
        DBgetobjsync(GROUPSTABLE, groupid)
          .then((res) => {
            var obj = res.data
            obj.keyid = res.id
            if (
              state.dbauthGroupsAccess.findIndex((f) => f.keyid === res.id) < 0
            ) {
              commit('ADD_DBAUTHGROUPSACCESS', obj)
            }
          })
          .catch(function (e) {
            store.dispatch('dberror/logError', e)
          })
      })
    }
  },

  getUserPrivilegesAuth({ commit, state }) {
    if (
      state.dbauthProfile !== null &&
      typeof state.dbauthProfile !== 'undefined'
    ) {
      commit(
        'SET_DBAUTHPRIVILEGES',
        aisubscriptiontable[state.dbauthProfile.subscriptionid]
      )
      // Eliminated the dependency on the aisubscriptions table
      // DBgetobjsync(AISUBSCRIPTIONSTABLE, state.dbauthProfile.subscriptionid)
      //   .then((res) => {
      //     var obj = res.data
      //     obj.keyid = res.id
      //     // commit('SET_DBAUTHPRIVILEGES', obj)
      //     commit(
      //       'SET_DBAUTHPRIVILEGES',
      //       aisubscriptiontable[state.dbauthProfile.subscriptionid]
      //     )
      //   })
      //   .catch(function (e) {
      //     store.dispatch('dberror/logError', e)
      //   })
    }
  },

  // for some reason, d3 does not have a a dbloguser file
  getUserPrivUsageAuth({ commit, state }, log = false) {
    if (
      state.dbauthProfile !== null &&
      typeof state.dbauthProfile !== 'undefined'
    ) {
      DBgetobjsync(LOGUSERTABLE, state.dbauthuser.uniqid)
        .then((res) => {
          if (res.exists) {
            var obj = res.data
            obj.keyid = res.id
            if (!log) {
              obj.log = null // I don't need the full log
            }
            commit('SET_DBAUTHPRIVUSAGE', obj)
          } else {
            SnackService.info('MESSAGES.USERLOGFILENOTEXISTS')
          }
        })
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    }
  },

  // adds a document to docs processed and docs monthly processed.
  addUserPrivUsageDocProcessedAuth({ commit, state }) {
    commit('ADD_LOCALAUTHDOCPROCESSED')
  },

  getUserAvailDocsAuth({ commit, state }) {
    // Changed to only get documents that are Standard Documents
    commit('SET_DBAUTHAVAILDOCS', [])

    DBdocmetaquery1(state.dbauthuser.uniqid).then(function (querySnapshot) {
      querySnapshot.forEach((doc) => {
        var obj = doc
        obj.keyid = doc._id
        obj.privilege = 'owner'

        if (state.dbauthAvailDocs.findIndex((f) => f.keyid === doc._id) < 0) {
          commit('ADD_DBAUTHAVAILDOCS', obj)
        }
      })
    })

    DBdocmetaquery2(state.dbauthuser.uniqid).then(function (querySnapshot) {
      querySnapshot.forEach((doc) => {
        var obj = doc
        obj.keyid = doc._id
        obj.privilege = 'collaborator'
        if (state.dbauthAvailDocs.findIndex((f) => f.keyid === doc._id) < 0) {
          commit('ADD_DBAUTHAVAILDOCS', obj)
        }
      })
    })

    DBdocmetaquery3(state.dbauthuser.uniqid).then(function (querySnapshot) {
      querySnapshot.forEach((doc) => {
        var obj = doc
        obj.keyid = doc._id
        obj.privilege = 'negotiator'

        if (state.dbauthAvailDocs.findIndex((f) => f.keyid === doc._id) < 0) {
          commit('ADD_DBAUTHAVAILDOCS', obj)
        }
      })
    })
  },

  getUserAvailGroupDocsAuth({ commit, state }) {
    commit('SET_DBAUTHAVAILGROUPDOCS', [])

    state.dbauthGroupsAccess.forEach(function (e) {
      // owner (group)
      if (e.ownerid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBdocmetaquery4(doctypeid, 'STANDARD').then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              var obj = doc
              obj.keyid = doc._id
              obj.privilege = 'owner(group)'
              if (
                state.dbauthAvailGroupDocs.findIndex(
                  (f) => f.keyid === doc._id
                ) < 0
              ) {
                commit('ADD_DBAUTHAVAILGROUPDOCS', obj)
              }
            })
          })
        })
      }

      // collaborator (group)
      if (e.uniqid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBdocmetaquery5(doctypeid).then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              var obj = doc
              obj.keyid = doc._id
              obj.privilege = 'collaborator(group)'

              if (
                state.dbauthAvailGroupDocs.findIndex(
                  (f) => f.keyid === doc._id
                ) < 0
              ) {
                commit('ADD_DBAUTHAVAILGROUPDOCS', obj)
              }
            })
          })
        })
      }
    })
  },

  // copied from above, need to fill in the right logic to get hub docs.
  // For now, keep the same.
  getUserAvailHubDocsAuth({ commit, state }) {
    commit('SET_DBAUTHAVAILHUBDOCS', [])

    // Kept this section from before - NEED TO REPLACE WITH HUB LOGIC
    state.dbauthGroupsAccess.forEach(function (e) {
      // owner (group)
      if (e.ownerid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBdocmetaquery4(doctypeid, 'STANDARD').then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              var obj = doc
              obj.keyid = doc._id
              obj.privilege = 'owner(group)'
              if (
                state.dbauthAvailHubDocs.findIndex((f) => f.keyid === doc._id) <
                0
              ) {
                commit('ADD_DBAUTHAVAILHUBDOCS', obj)
              }
            })
          })
        })
      }
      // Kept this section from before - NEED TO REPLACE WITH HUB LOGIC

      // Kept this section from before - NEED TO REPLACE WITH HUB LOGIC
      // collaborator (group)
      if (e.uniqid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBdocmetaquery5(doctypeid).then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              var obj = doc
              obj.keyid = doc._id
              obj.privilege = 'collaborator(group)'

              if (
                state.dbauthAvailHubDocs.findIndex((f) => f.keyid === doc._id) <
                0
              ) {
                commit('ADD_DBAUTHAVAILHUBDOCS', obj)
              }
            })
          })
        })
      }
    })
  },

  getUserAvailArchiveDocsAuth({ commit, state }) {
    // gets the groups documents in the archive first.
    // finds the groups in the access and gets those archive documents.

    commit('SET_DBAUTHAVAILARCHIVEDOCS', [])

    state.dbauthGroupsAccess.forEach(function (e) {
      // owner
      if (e.ownerid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBdocmetaquery4(doctypeid, 'ARCHIVE').then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              var obj = doc
              obj.keyid = doc._id
              obj.privilege = 'archive(group)'
              if (
                state.dbauthAvailArchiveDocs.findIndex(
                  (f) => f.keyid === doc._id
                ) < 0
              ) {
                commit('ADD_DBAUTHAVAILARCHIVEDOCS', obj)
              }
            })
          })
        })
      }
      // collaborator
      if (e.uniqid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBdocmetaquery4(doctypeid, 'ARCHIVE').then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              var obj = doc
              obj.keyid = doc._id
              obj.privilege = 'archive(group)'

              if (
                state.dbauthAvailArchiveDocs.findIndex(
                  (f) => f.keyid === doc._id
                ) < 0
              ) {
                commit('ADD_DBAUTHAVAILARCHIVEDOCS', obj)
              }
            })
          })
        })
      }
    })

    // then get the individual documents

    DBdocmetaquery6(state.dbauthuser.uniqid, 'ownerid').then(function (
      querySnapshot
    ) {
      querySnapshot.forEach((doc) => {
        var obj = doc
        obj.keyid = doc._id
        obj.privilege = 'archive(owner)'

        if (state.dbauthAvailDocs.findIndex((f) => f.keyid === doc._id) < 0) {
          commit('ADD_DBAUTHAVAILARCHIVEDOCS', obj)
        }
      })
    })

    DBdocmetaquery6(state.dbauthuser.uniqid, 'externalcollaborators').then(
      function (querySnapshot) {
        querySnapshot.forEach((doc) => {
          var obj = doc
          obj.keyid = doc._id
          obj.privilege = 'archive(collaborator)'
          if (state.dbauthAvailDocs.findIndex((f) => f.keyid === doc._id) < 0) {
            commit('ADD_DBAUTHAVAILARCHIVEDOCS', obj)
          }
        })
      }
    )

    DBdocmetaquery6(state.dbauthuser.uniqid, 'externalnegotiators').then(
      function (querySnapshot) {
        querySnapshot.forEach((doc) => {
          var obj = doc
          obj.keyid = doc._id
          obj.privilege = 'archive(negotiator)'

          if (state.dbauthAvailDocs.findIndex((f) => f.keyid === doc._id) < 0) {
            commit('ADD_DBAUTHAVAILARCHIVEDOCS', obj)
          }
        })
      }
    )
  },

  getUserAvailPlaybooksAuth({ commit, state }) {
    commit('SET_DBAUTHAVAILPLAYBOOKS', [])
    // get documents that are from the groups
    state.dbauthGroupsAccess.forEach(function (e) {
      // owner
      if (e.ownerid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBdocmetaquery4(doctypeid, 'PLAYBOOK').then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              var obj = doc
              obj.keyid = doc._id
              obj.privilege = 'owner(group)'
              if (
                state.dbauthAvailPlaybooks.findIndex(
                  (f) => f.keyid === doc._id
                ) < 0
              ) {
                commit('ADD_DBAUTHAVAILPLAYBOOKS', obj)
              }
            })
          })
        })
      }
      // collaborator
      if (e.uniqid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBdocmetaquery4(doctypeid, 'PLAYBOOK').then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              var obj = doc
              obj.keyid = doc._id
              obj.privilege = 'collaborator(group)'
              if (
                state.dbauthAvailPlaybooks.findIndex(
                  (f) => f.keyid === doc._id
                ) < 0
              ) {
                commit('ADD_DBAUTHAVAILPLAYBOOKS', obj)
              }
            })
          })
        })
      }
    })
  },

  getUserAvailTemplatesAuth({ commit, state }) {
    commit('SET_DBAUTHAVAILTEMPLATES', [])
    // get documents that are from the groups
    state.dbauthGroupsAccess.forEach(function (e) {
      // owner
      if (e.ownerid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBdocmetaquery4(doctypeid, 'TEMPLATE').then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              var obj = doc
              obj.keyid = doc._id
              obj.privilege = 'owner(group)'
              if (
                state.dbauthAvailTemplates.findIndex(
                  (f) => f.keyid === doc._id
                ) < 0
              ) {
                commit('ADD_DBAUTHAVAILTEMPLATES', obj)
              }
            })
          })
        })
      }
      // collaborator
      if (e.uniqid.includes(state.dbauthuser.uniqid)) {
        e.doctypeid.forEach(function (doctypeid) {
          DBdocmetaquery4(doctypeid, 'TEMPLATE').then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
              var obj = doc
              obj.keyid = doc._id
              obj.privilege = 'collaborator(group)'
              if (
                state.dbauthAvailTemplates.findIndex(
                  (f) => f.keyid === doc._id
                ) < 0
              ) {
                commit('ADD_DBAUTHAVAILTEMPLATES', obj)
              }
            })
          })
        })
      }
    })
  },

  updateUserProfileAuth({ commit, state }, data) {},

  addRecordUserInfoAuth({ commit, state }, uniqid) {
    // Check if already in cache.
    var findIndex = state.dbauthUserInfoCache.findIndex(
      (e) => e.uniqid === uniqid
    )
    if (findIndex < 0) {
      // get info from DB and store in DB
      DBgetobjsync(USERSTABLE, uniqid)
        .then(function (doc) {
          if (doc.exists) {
            // if found, add to cache and also return the data.
            // double check it's not already in DB
            if (
              state.dbauthUserInfoCache.findIndex((f) => f.uniqid === uniqid) <
              0
            ) {
              commit('ADD_USERINFO', doc.data)
            }
          }
        })
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    }
  },

  // SYNCHRONOUS
  // used to delete the documents in open screens.
  queryValidIDAuth({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      DBgetobjsync(USERSTABLE, payload.uniqid)
        .then(function (doc) {
          if (doc.exists) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },

  // SYNCHRONOUS - AUTHENTICATION LIBRARY CALL
  // used to create the initial user and password.
  createUserEmailPasswordAuth({ commit, state }, payload) {
    return new Promise(function (resolve, reject) {
      AUTHcreateUserEmailPassword(payload.uniqid, payload.password)
        .then((user) => {
          resolve(user)
        })
        .catch(function (e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },

  // moved processing for Auth State Changes to into an action.
  doStateChangeAuth({ commit, state }, user) {
    if (user) {
      state.commit('SET_DBAUTHUSER', user)
      state.dispatch('fetchUserProfile')

      DBgetobjsync(USERSTABLE, user.uid)
        .then(function (doc) {
          // .onSnapshot((doc) => {
          state.commit('SET_DBAUTHPROFILE', doc.data)
        })
        .catch(function (e) {
          state.dispatch('dberror/logError', e)
        })
    }
  },
}
