// Contains all application constants //

export const ES = {
  TOPMENU: {
    FILE: 'ARCH',
    EDIT: 'EDIC',
    ACT: 'ACT',
    DOCS: 'DOCS',
  },

  MENU: {
    NEWDOCUMENT: 'Nuevo documento',
    // ORIGINAL TEXT: New Document
    SAVEDOCUMENT: 'Guardar documento',
    // ORIGINAL TEXT: Save Document
    COPYDOCUMENT: 'Copiar documento',
    // ORIGINAL TEXT: Copy Document
    CREATEVERSION: 'Crear versión limpia',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: 'Crear documento a partir de la plantilla',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: 'Abrir documento',
    // ORIGINAL TEXT: Open Document
    OPENGROUPDOCUMENT: 'Abrir documentos de grupo',
    // ORIGINAL TEXT: Open Group Document
    OPENHUBDOCUMENT: 'Abrir HUB documento',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: 'Abrir documento de archivo',
    // ORIGINAL TEXT: Open Archive Document
    OPENPLAYBOOK: 'Abrir manual de procedimiento',
    // ORIGINAL TEXT: Open Playbook
    OPENTEMPLATE: 'Plantilla abierta',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: 'Guardar como plantilla',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: 'Guardar como manual de procedimiento',
    // ORIGINAL TEXT: Save As Playbook
    MOVETOARCHIVE: 'Mover al archivo',
    // ORIGINAL TEXT: Move to Archive
    CLOSEDOCUMENT: 'Cerrar documento',
    // ORIGINAL TEXT: Close Document
    DOCUMENTLOG: 'Registro del documento',
    // ORIGINAL TEXT: Document Log

    GOTOPARAGRAPH: 'Ir al párrafo',
    // ORIGINAL TEXT: Go to Paragraph
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'Notas sobre el documento',
    // ORIGINAL TEXT: Document Notes
    COLLABCOMMENTS: 'Colaboración - Observaciones',
    // ORIGINAL TEXT: Collaboration - Comments
    COLLABDOCEDITS: 'Colaboración - Edición de documentos',
    // ORIGINAL TEXT: Collaboration - Document Edits
    COLLABRISKANDLABELS: 'Colaboración - Riesgos y etiquetas',
    // ORIGINAL TEXT: Collaboration - Risk and Labels
    NEGOTCOMMENTS: 'Negociación - Observaciones',
    // ORIGINAL TEXT: Negotiation - Comments
    NEGOTDOCEDITS: 'Negociación - Edición de documentos',
    // ORIGINAL TEXT: Negotiation - Document Edits

    COLLABORATION: 'Colaboración',
    // ORIGINAL TEXT: Collaboration
    NEGOTIATION: 'Negociación',
    // ORIGINAL TEXT: Negotiation
    ADDOWNER: 'Añadir propietario',
    // ORIGINAL TEXT: Add Owner
    ADDCOLLABORATOR: 'Añadir colaborador',
    // ORIGINAL TEXT: Add Collaborator
    ADDNEGOTIATOR: 'Añadir negociador',
    // ORIGINAL TEXT: Add Negotiator
    TRIGGERALERTS: 'Activar Alertas',
    // ORIGINAL TEXT: Trigger Alerts
    EXPORTEXCEL: 'Exportar Excel',
    // ORIGINAL TEXT: Export Excel
    DOWNLOADSOURCE: 'Descargar fuente',
    // ORIGINAL TEXT: Download Source
    SENDTOCLM: 'Enviar a CLM',
    // ORIGINAL TEXT: Send to CLM
    EXPORTJSON: 'Exportar JSON',
    // ORIGINAL TEXT: Export JSON

    ESIGNATURE: 'Enviar para la firma',
    // ORIGINAL TEXT: Send for Esignature
    DOWNLOADREDLINE: 'Descargar Redline DocX',
    // ORIGINAL TEXT: Download Redline DocX
    DOCUMENTSETTINGS: 'Configuración del documento',
    // ORIGINAL TEXT: Document Settings
    EXPAND: 'Expandir',
    // ORIGINAL TEXT: Expand
    COLLAPSE: 'Comprimir',
    // ORIGINAL TEXT: Collapse

    TRANSLATETOENGLISH: 'Traducir al inglés',
    // ORIGINAL TEXT: Translate to English
    ORIGINALLANGUAGE: 'Recuperar el original',
    // ORIGINAL TEXT: Revert to Original

    ALLPARAGRAPHS: 'Todos los párrafos',
    // ORIGINAL TEXT: All Paragraphs
    EDITEDPARAGRAPHSONLY: 'Sólo los párrafos editados',
    // ORIGINAL TEXT: Edited Paragraphs Only

    NOEDITCLAUSELABELS: 'Sin etiquetas de cláusula de edición',
    // ORIGINAL TEXT: No Edit Clause Labels
    EDITCLAUSELABELS: 'Editar etiquetas de la cláusula',
    // ORIGINAL TEXT: Edit Clause Labels

    HIDEEDITS: 'Ocultar ediciones',
    // ORIGINAL TEXT: Hide Edits
    SHOWEDITS: 'Mostrar ediciones',
    // ORIGINAL TEXT: Show Edits

    SHOWALLEDITS: 'Mostrar todas las ediciones',
    // ORIGINAL TEXT: Show All Edits
    SHOWNEGOTEDITSONLY: 'Mostrar sólo ediciones de negociación',
    // ORIGINAL TEXT: Show Negotiation Edits Only

    GROUPS: 'Grupos',
    // ORIGINAL TEXT: Groups
    DOCUMENTTYPES: 'Tipos de documento',
    // ORIGINAL TEXT: Document Types
    ALERTS: 'Alertas',
    // ORIGINAL TEXT: Alerts
    QUICKSTARTTUTORIAL: 'Tutorial de inicio rápido',
    // ORIGINAL TEXT: Quick Start Tutorial
    QUICKSTARTVIDEOS: 'Vídeos de inicio rápido',
    // ORIGINAL TEXT: Quick Start Videos
    UPGRADESUBSCRIPTION: 'Actualización de la suscripción',
    // ORIGINAL TEXT: Upgrade Subscription
    SUBSCRIPTIONBILLING: 'Facturación',
    // ORIGINAL TEXT: Subscription Billing
    ACCOUNTSETTINGS: 'Configuración de la cuenta',
    // ORIGINAL TEXT: Account Settings
    USERLOG: 'Registro del usuario',
    // ORIGINAL TEXT: User Log
    CHANGEPASSWORD: 'Cambiar contraseña',
    // ORIGINAL TEXT: Change Password
    LOGOUT: 'Cerrar sesión',
    // ORIGINAL TEXT: Logout

    // HEADINGS
    ADMIN: 'ADMIN',
    // ORIGINAL TEXT: ADMIN
    PROFILE: 'PERFIL',
    // ORIGINAL TEXT: PROFILE
    LPROFILE: 'Perfil',
    // ORIGINAL TEXT: Profile
    FILE: 'FILE',
    // ORIGINAL TEXT: FILE
    EDIT: 'EDIT',
    // ORIGINAL TEXT: EDIT
    ACT: 'ACTO',
    // ORIGINAL TEXT: ACT
    TOPIC: 'TEMA',
    // ORIGINAL TEXT: TOPIC
    DOCS: 'DOCS',
    // ORIGINAL TEXT: DOCS

    // Field Headings in Navigation Bar
    NAME: 'Nombre',
    // ORIGINAL TEXT: Name
    ROLE: 'Función',
    // ORIGINAL TEXT: Role
    STATE: 'Estado',
    // ORIGINAL TEXT: State
    GROUP: 'Grupo',
    // ORIGINAL TEXT: Group
    TYPE: 'Tipo',
    // ORIGINAL TEXT: Type
    SENSIT: 'Sensibilidad',
    // ORIGINAL TEXT: Sensitivity
    LTOPIC: 'Tema',
    // ORIGINAL TEXT: Topic
    USER: 'Usuario',
    // ORIGINAL TEXT: User

    // Other Headings
    REGISTER: 'REGÍSTRATE',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'acción',
    // ORIGINAL TEXT: LOGIN

    LREGISTER: 'Registro',
    // ORIGINAL TEXT: Register
    LLOGIN: 'Inicio de sesión',
    // ORIGINAL TEXT: Login

    ADMINEXPLAIN: 'Funciones de administrador',
    // ORIGINAL TEXT: Administrative capabilities
    PROFILEEXPLAIN: 'Gestión de cuentas, suscripciones, facturación',
    // ORIGINAL TEXT: Account Management, Subscriptions, Billing

    // AUTH LEVELS
    PREMIUM: 'Prima',
    // ORIGINAL TEXT: Premium
    PREMIUMPREPAID: 'Prima (Prepago)',
    // ORIGINAL TEXT: Premium (Prepaid)
    BASIC: 'Básico',
    // ORIGINAL TEXT: Basic
    BASICPREPAID: 'Básico (Prepago)',
    // ORIGINAL TEXT: Basic (Prepaid)
    VIPACCESS: 'Acceso VIP',
    // ORIGINAL TEXT: VIP Access
    VIPMEMBER: 'Miembro VIP',
    // ORIGINAL TEXT: VIP Member
    UNLIMITED: 'Ilimitado',
    // ORIGINAL TEXT: Unlimited
    TRIALUSER: 'Usuario de prueba',
    // ORIGINAL TEXT: Trial User
    REFERREDUSER: 'Usuario referido',
    // ORIGINAL TEXT: Referred User
    SOURCEDOCUMENT: 'Documento fuente',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: 'Documento de comparación',
    // ORIGINAL TEXT: Comparison Document
    EXIT: 'SALIDA',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: 'Párrafo',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: 'MODO DE COMPARACIÓN DE DOCUMENTOS',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: 'NINGUNA',
    // ORIGINAL TEXT: NONE
    PREAMBLE: 'PREÁMBULO',
    // ORIGINAL TEXT: PREAMBLE
    DEFN: 'DEFINICIÓN',
    // ORIGINAL TEXT: DEFINITION
    PAYMENT: 'PAGO',
    // ORIGINAL TEXT: PAYMENT
    TAXES: 'IMPUESTOS',
    // ORIGINAL TEXT: TAXES
    REPWARR: 'REPRESENTACIÓN Y GARANTÍAS',
    // ORIGINAL TEXT: REPRESENTATION AND WARRANTIES
    PERIOD: 'PERÍODO',
    // ORIGINAL TEXT: PERIOD
    TERMINATION: 'TERMINACIÓN',
    // ORIGINAL TEXT: TERMINATION
    CONFID: 'CONFIDENCIALIDAD',
    // ORIGINAL TEXT: CONFIDENTIALITY
    INDEMNITY: 'INDEMNIDAD',
    // ORIGINAL TEXT: INDEMNITY
    LIABILITY: 'RESPONSABLE',
    // ORIGINAL TEXT: LIABILITY
    INTELPROP: 'PROP INTELECTUAL',
    // ORIGINAL TEXT: INTELLECTUAL PROP
    SIGNATURE: 'FIRMA',
    // ORIGINAL TEXT: SIGNATURE
    OTHER: 'OTROS',
    // ORIGINAL TEXT: OTHER
    DATASECURITY: 'SEGURIDAD DE LOS DATOS',
    // ORIGINAL TEXT: DATA SECURITY
    ASSIGN: 'ASIGNACIÓN',
    // ORIGINAL TEXT: ASSIGNMENT
    NOTICE: 'AVISO',
    // ORIGINAL TEXT: NOTICE
    GOVERNLAW: 'LEY APLICABLE',
    // ORIGINAL TEXT: GOVERNING LAW
    DISPUTE: 'DISPUTE',
    // ORIGINAL TEXT: DISPUTE
    ENTIREAGREE: 'ACUERDO COMPLETO',
    // ORIGINAL TEXT: ENTIRE AGREEMENT
    SURVIVAL: 'SUPERVIVENCIA',
    // ORIGINAL TEXT: SURVIVAL
    RELATPART: 'RELACIÓN ENTRE LAS PARTES',
    // ORIGINAL TEXT: RELATION BETWEEN PARTIES
    THIRDPARTY: 'TERCERA PARTE',
    // ORIGINAL TEXT: THIRD PARTY
    FORCEMAJEURE: 'FUERZA MAYOR',
    // ORIGINAL TEXT: FORCE MAJEURE
    SEVERABILITY: 'SEPARABILIDAD',
    // ORIGINAL TEXT: SEVERABILITY
    EXPORTCTRL: 'CONTROLES DE EXPORTACIÓN',
    // ORIGINAL TEXT: EXPORT CONTROLS
    INTRO: 'INTRODUCCIÓN',
    // ORIGINAL TEXT: INTRODUCTION
    INSURANCE: 'SEGUROS',
    // ORIGINAL TEXT: INSURANCE
    HEADING: 'CABEZA',
    // ORIGINAL TEXT: HEADING
    ALL: 'TODOS',
    // ORIGINAL TEXT: ALL
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'Se requiere correo electrónico',
    // ORIGINAL TEXT: E-mail is required
    EMAILVALID: 'El correo electrónico debe ser válido',
    // ORIGINAL TEXT: E-mail must be valid
    MAX20CHARS: 'Maximo 20 carácteres',
    // ORIGINAL TEXT: Max 20 characters
    MAX60CHARS: 'Maximo 60 carácteres',
    // ORIGINAL TEXT: Max 60 characters
    MAX80CHARS: 'Maximo 80 carácteres',
    // ORIGINAL TEXT: Max 80 characters
    MAX160CHARS: 'Maximo 160 carácteres',
    // ORIGINAL TEXT: Max 160 characters
    EMAILALREADYOWNER:
      'El correo electrónico ya está registrado como propietario',
    // ORIGINAL TEXT: Email is already an owner
    EMAILALREADYCOLLABORATOR:
      'El correo electrónico ya está registrado como colaborador',
    // ORIGINAL TEXT: Email is already a collaborator
    EMAILALREADYNEGOTIATOR:
      'El correo electrónico ya está registrado como negociador',
    // ORIGINAL TEXT: Email is already a negotiator
    EMAILALREADYOWNERDOCTYPE:
      'El correo electrónico ya es propietario del tipo de documento',
    // ORIGINAL TEXT: Email is already an owner of the document type
    EMAILALREADYCOLLABORATORDOCTYPE:
      'El correo electrónico ya es colaborador del documento tipo',
    // ORIGINAL TEXT: Email is already an collaborator of the document type
    REQUIRED: 'Requerido.',
    // ORIGINAL TEXT: Required.
    CLAUSEFIELDREQUIRED:
      'Este campo de cláusula es necesario para esta operación',
    // ORIGINAL TEXT: This clause field is required for this operation
    CLAUSENOTDEFAULT:
      'No se puede añadir cláusula de la lista de cláusulas predeterminadas',
    // ORIGINAL TEXT: Cannot add clause from default clause list
    TAGFIELDREQUIRED: 'Este campo de etiqueta es necesario para esta operación',
    // ORIGINAL TEXT: This tag field is required for this operation
    TAGNOTDEFAULT:
      'No se pueden añadir etiquetas de la lista de etiquetas predeterminada',
    // ORIGINAL TEXT: Cannot add tags from default tag list

    PARAINDEXREQUIRED: 'Se requiere un índice de párrafos',
    // ORIGINAL TEXT: Paragraph index is required
    PARAINDEXINVALID: 'No existe el índice de párrafos',
    // ORIGINAL TEXT: Paragraph index does not exist
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'Guardar documento',
    OPENDOCUMENT: 'Abrir documento',
    // ORIGINAL TEXT: open document
    CLOSEDOCUMENT: 'cerrar documento',
    // ORIGINAL TEXT: close document
    ADDNOTE: 'añadir nota',
    // ORIGINAL TEXT: add note
    ADDCOLLABORATOR: 'añadir colaborador',
    // ORIGINAL TEXT: add collaborator
    DELETECOLLABORATOR: 'eliminar colaborador',
    // ORIGINAL TEXT: delete collaborator
    ADDNEGOTIATOR: 'añadir negociador',
    // ORIGINAL TEXT: add negotiator
    DELETENEGOTIATOR: 'eliminar negociador',
    // ORIGINAL TEXT: delete negotiator
    UPDATEARCHIVEDATA: 'actualizar los datos del archivo',
    // ORIGINAL TEXT: update archive data
    UPDATEDOCMETA: 'actualizar los metadatos del documento',
    // ORIGINAL TEXT: update document metadata
    ADDOWNER: 'añadir propietario',
    // ORIGINAL TEXT: add owner
    DELETEOWNER: 'eliminar propietario',
    // ORIGINAL TEXT: delete owner
    CHANGECOLLABORATION: 'cambios en la configuración de colaboración',
    // ORIGINAL TEXT: changed collaboration settings
    CHANGENEGOTIATION: 'cambios en la configuración de negociación',
    // ORIGINAL TEXT: changed negotiation settings
    SAVEPLAYBOOK: 'guardado como colección manual de procedimientos',
    // ORIGINAL TEXT: saved as playbook
    SAVETEMPLATE: 'guardado como plantilla',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: 'guardado como archivado',
    // ORIGINAL TEXT: saved as archived
    TRIGGERALERT: 'activar alerta',
    // ORIGINAL TEXT: trigger alert
    TRIGGERALLALERT: 'activar todas las alertas',
    // ORIGINAL TEXT: trigger all alerts
    TRIGGERCUSTOMALERT: 'activar alerta personalizada',
    // ORIGINAL TEXT: trigger custom alert
    ADDCOMMENT: 'añadir comentario',
    // ORIGINAL TEXT: add comment
    DELETECOMMENT: 'suprimir comentario',
    // ORIGINAL TEXT: delete comment
    ADDDOCEDIT: 'añadir docedit',
    // ORIGINAL TEXT: add docedit
    ADDAPPROVEDDOCEDIT: 'añadir la edición aprobada del documento',
    // ORIGINAL TEXT: add approved document edit
    APPROVEDDOCEDIT: 'aprobar la edición del documento',
    // ORIGINAL TEXT: approve document edit
    REJECTDOCEDIT: 'rechazar edición de documento',
    // ORIGINAL TEXT: reject document edit
    NEGOTIATORAPPROVEDDOCEDIT: 'el negociador ha aprobado el documento editado',
    // ORIGINAL TEXT: negotiator approved document edit
    NEGOTIATORREJECTEDDOCEDIT:
      'el negociador ha rechazado el documento editado',
    // ORIGINAL TEXT: negotiator rejected document edit
    DELETEDDOCEDIT: 'borrar el documento editado',
    // ORIGINAL TEXT: delete document edit
    ATTACHCONTRACT: 'adjunto versión final del contrato',
    // ORIGINAL TEXT: attach exec contract
    ADDTAG: 'añadir etiqueta',
    // ORIGINAL TEXT: add tag
    DELETETAG: 'eliminar etiqueta',
    // ORIGINAL TEXT: delete tag

    NEWSIGNATUREREQUEST: 'nueva firma solicitada',
    // ORIGINAL TEXT: new signature requested
    CANCELSIGNATUREREQUEST: 'solicitud de firma cancelada',
    // ORIGINAL TEXT: cancelled signature request

    CREATEREDLINEREQUEST: 'crear un nuevo documento de línea roja',
    // ORIGINAL TEXT: create new redline document
    CREATEFINALDOCREQUEST: 'crear un nuevo documento final para su firma',
    // ORIGINAL TEXT: create new final document for signature
    COPIEDDOCUMENT: 'copiado del documento',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: 'creado una versión limpia del documento',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: 'documento creado a partir de la plantilla',
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'crear documento tipo',
    // ORIGINAL TEXT: create document type
    DELETEDOCTYPE: 'eliminar el documento tipo',
    // ORIGINAL TEXT: delete document type
    ADDOWNERTODOCTYPE: 'propietario añadido al documento tipo',
    // ORIGINAL TEXT: added owner to document type
    DELETEOWNERFROMDOCTYPE: 'eliminado al propietario del documento tipo',
    // ORIGINAL TEXT: deleted owner to document type
    ADDCLAUSETODOCTYPE: 'añadir cláusula addl al documento tipo',
    // ORIGINAL TEXT: added addl clause to document type
    DELETECLAUSEDOCTYPE: 'suprimir la cláusula addl al documento tipo',
    // ORIGINAL TEXT: deleted addl clause to document type
    ADDTAGDOCTYPE: 'añadir etiqueta addl al documento tipo',
    // ORIGINAL TEXT: added addl tag to document type
    DELETETAGDOCTYPE: 'etiqueta addl borrada del documento tipo',
    // ORIGINAL TEXT: deleted addl tag from document type
    ADDCOLLABRULEDOCTYPE: 'reglas collab añadidas al documento tipo',
    // ORIGINAL TEXT: added collab rules to  document type
    DELETECOLLABRULEDOCTYPE: 'reglas collab eliminadas del documento tipo',
    // ORIGINAL TEXT: deleted collab rules from document type
    ADDNOTIFRULEDOCTYPE: 'normas de notificación añadidas al documento tipo',
    // ORIGINAL TEXT: added notification rules to  document type
    DELETENOTIFRULEDOCTYPE:
      'normas de notificación eliminadas del documento tipo',
    // ORIGINAL TEXT: deleted notification rules from document type
    ADDNEGOTRULEDOCTYPE: 'Normas de negociación añadidas al documento tipo',
    // ORIGINAL TEXT: added negotiation rules to  document type
    DELETENEGOTRULEDOCTYPE:
      'normas de negociación eliminadas al documento tipo',
    // ORIGINAL TEXT: deleted negotiation rules from document type

    // GROUPS
    CREATEGROUP: 'grupo creado',
    // ORIGINAL TEXT: created group
    DELETEGROUP: 'grupo suprimido',
    // ORIGINAL TEXT: deleted group
    ADDOWNERGROUP: 'propietario añadido al grupo',
    // ORIGINAL TEXT: added owner to group
    DELETEOWNERGROUP: 'El propietario borrado del grupo',
    // ORIGINAL TEXT: deleted Owner from group
    ADDCOLLABGROUP: 'colaborador añadido al grupo',
    // ORIGINAL TEXT: added collaborator to group
    DELETECOLLABGROUP: 'colaborador eliminado del grupo',
    // ORIGINAL TEXT: deleted collaborator from group
    ADDDOCTYPETOGROUP: 'tipo de documento añadido al grupo',
    // ORIGINAL TEXT: added document type to group
    DELETEDOCTYPEGROUP: 'tipo de documento eliminado del grupo',
    // ORIGINAL TEXT: deleted document type from group

    // ALERTS
    CREATEALERT: 'alerta creada',
    // ORIGINAL TEXT: created alert
    DELETEALERT: 'descripción eliminada',
    // ORIGINAL TEXT: deleted alert
    ADDOWNERALERT: 'propietario añadido a alerta',
    // ORIGINAL TEXT: added owner to alert
    DELETEOWNERALERT: 'propietario eliminado de la alerta',
    // ORIGINAL TEXT: deleted owner from alert
    ADDDOCTYPEALERT: 'tipologia de documento añadida a la alerta',
    // ORIGINAL TEXT: added doctype to alert
    DELETEDOCTYPEALERT: 'tipologia de documento eliminada de la alerta',
    // ORIGINAL TEXT: deleted doctype from alert
    ADDCLAUSETOPICALERT: 'añadida etiqueta de la cláusula a la alerta',
    // ORIGINAL TEXT: added clause topic to alert
    DELETECLAUSETOPICALERT: 'suprimida etiqueta de la cláusula de la alerta',
    // ORIGINAL TEXT: deleted clause topic from alert
    ADDRECIPIENTALERT: 'receptor añadido a la alerta',
    // ORIGINAL TEXT: added recipient to alert
    DELETERECIPIENTALERT: 'destinatario eliminado de la alerta',
    // ORIGINAL TEXT: deleted recipient from alert
    ADDNOTIFRULEALERT: 'normas de notificación añadidas a la alerta',
    // ORIGINAL TEXT: added notification rules to alert
    DELETENOTIFRULEALERT: 'normas de notificación suprimidas de la alerta',
    // ORIGINAL TEXT: deleted notification rules from alert
    SETSELECTIVITYALERT: 'determinar la sensibilidad de la alerta',
    // ORIGINAL TEXT: set selectivity to  alert
  },

  USEREVENTS: {
    USERSIGNON: 'Usuario iniciado',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT:
      'petición de restablecimiento de contraseña enviada',
    // ORIGINAL TEXT: password reset request sent
    CHANGEDPASSWORD: 'cambio de contraseña',
    // ORIGINAL TEXT: changed password
    DELETEDREGISTRATION: 'Registro de usuario eliminado',
    // ORIGINAL TEXT: deleted user registration
    UPDATEDACCOUNTINFO: 'información actualizada de la cuenta',
    // ORIGINAL TEXT: updated account information
    UPGRADEDACCOUNTTOREFERRAL: 'cuenta actualizada a cuenta referida',
    // ORIGINAL TEXT: upgraded account to referred account
    DELETEDUSERACCOUNT: 'cuenta de usuario eliminada',
    // ORIGINAL TEXT: deleted user account
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: 'ACCIONES DE DOCUMENTOS',
    // ORIGINAL TEXT: DOCUMENT ACTIONS
    COMMENTS: 'OBSERVACIONES',
    // ORIGINAL TEXT: COMMENTS
    DOCEDITS: 'DOCUMENTOS EDITADOS',
    // ORIGINAL TEXT: DOCUMENT EDITS
    LABELSRISK: 'ETIQUETAS Y RIESGO',
    // ORIGINAL TEXT: LABELS AND RISK
    TEXT: 'TEXTO',
    // ORIGINAL TEXT: TEXT
    MLE_DESC: 'DOCGILITY',
    // ORIGINAL TEXT: DOCGILITY
    CLAUSE: 'Clausula',
    // ORIGINAL TEXT: Clause

    // PRIVILEGES
    OWNER: 'propietario',
    // ORIGINAL TEXT: owner
    COLLABORATOR: 'colaborador',
    // ORIGINAL TEXT: collaborator
    NEGOTIATOR: 'negociador',
    // ORIGINAL TEXT: negotiator
    OWNERGROUP: 'grupo propietario',
    // ORIGINAL TEXT: owner group
    COLLABORATORGROUP: 'grupo de colaboradores',
    // ORIGINAL TEXT: collaborator group

    // TITLES
    SAVEDOCUMENT: 'GUARDAR DOCUMENTO',
    // ORIGINAL TEXT: SAVE DOCUMENT
    SAVEARCHIVE: 'ARCHIVAR DOCUMENTO',
    // ORIGINAL TEXT: SAVE ARCHIVE
    QUICKSTART: 'TUTORIAL DE INICIO RÁPIDO',
    // ORIGINAL TEXT: QUICK START TUTORIAL
    TUTORIALVIDEOS: 'VIDEOS TUTORIALES',
    // ORIGINAL TEXT: TUTORIAL VIDEOS

    MOVETOARCHIVEDOCUMENT: 'MOVER AL DOCUMENTO AL ARCHIVO',
    // ORIGINAL TEXT: MOVE TO ARCHIVE DOCUMENT
    OPENDOCUMENT: 'DOCUMENTO ABIERTO',
    // ORIGINAL TEXT: OPEN DOCUMENT
    COPYDOCUMENT: 'DOCUMENTO DE COPIA',
    // ORIGINAL TEXT: COPY DOCUMENT
    CREATEVERSION: 'CREAR UNA VERSIÓN LIMPIA',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: 'CREAR DOCUMENTO A PARTIR DE LA PLANTILLA',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: 'DOCUMENTO ABIERTO DEL GRUPO',
    // ORIGINAL TEXT: OPEN GROUP DOCUMENT
    OPENHUBDOCUMENT: 'DOCUMENTO HUB ABIERTO',
    // ORIGINAL TEXT: OPEN HUB DOCUMENT
    OPENARCHIVEDOCUMENT: 'DOCUMENTO DE ARCHIVO ABIERTO',
    // ORIGINAL TEXT: OPEN ARCHIVE DOCUMENT
    OPENPLAYBOOK: 'COLECCIÓN DE MANUAL DE PROCEDIMIENTOS',
    // ORIGINAL TEXT: OPEN PLAYBOOK
    OPENTEMPLATE: 'PLANTILLA ABIERTA',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'GUARDAR COMO PLANTILLA',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: 'COLABORACIÓN',
    // ORIGINAL TEXT: COLLABORATION
    NEGOTIATION: 'NEGOCIACIÓN',
    // ORIGINAL TEXT: NEGOTIATION
    ADDOWNER: 'ADD PROPIEDAD',
    // ORIGINAL TEXT: ADD OWNER
    ADDNEGOTIATOR: 'AÑADIR NEGOCIADOR',
    // ORIGINAL TEXT: ADD NEGOTIATOR
    ADDCOLLABORATOR: 'AÑADIR COLABORADOR',
    // ORIGINAL TEXT: ADD COLLABORATOR
    ERRORMESSAGE: 'MENSAJE DE ERRORES',
    // ORIGINAL TEXT: ERROR MESSAGE
    DOCUMENTINFO: 'INFORMACIÓN DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT INFORMATION
    DOCUMENTLOGS: 'LOGS DE DOCUMENTOS',
    // ORIGINAL TEXT: DOCUMENT LOGS
    USERLOGS: 'LOGS DEL USUARIO',
    // ORIGINAL TEXT: USER LOGS
    DOCUMENTNOTES: 'NOTAS DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT NOTES
    TRIGGERALERTS: 'ALERTAS ACTIVADAS',
    // ORIGINAL TEXT: TRIGGER ALERTS
    ALERTS: 'ALERTAS',
    // ORIGINAL TEXT: ALERTS
    DOCTYPES: 'TIPOS DE DOCUMENTOS',
    // ORIGINAL TEXT: DOCUMENT TYPES
    GROUPS: 'GRUPOS',
    // ORIGINAL TEXT: GROUPS
    NOTE: 'NOTA',
    // ORIGINAL TEXT: NOTE
    GOTOPARAGRAPH: 'IR AL PÁRRAFO',
    // ORIGINAL TEXT: GO TO PARAGRAPH
    ESIGNATURE: 'FIRMA E',
    // ORIGINAL TEXT: E-SIGNATURE
    DOWNLOADREDLINE: 'DESCARGAR REDLINE DOCX',
    // ORIGINAL TEXT: DOWNLOAD REDLINE DOCX

    // ACTIONS
    ADD: 'AÑADIR',
    // ORIGINAL TEXT: ADD
    BACK: 'VOLVER',
    // ORIGINAL TEXT: BACK
    DELETE: 'BORRAR',
    // ORIGINAL TEXT: DELETE
    SAVE: 'GUARDAR',
    // ORIGINAL TEXT: SAVE
    MOVETOARCHIVE: 'ARCHIVAR',
    // ORIGINAL TEXT: MOVE TO ARCHIVE
    COPY: 'COPIAR',
    // ORIGINAL TEXT: COPY
    CREATE: 'CREAR',
    // ORIGINAL TEXT: CREATE
    OPEN: 'ABIERTO',
    // ORIGINAL TEXT: OPEN
    CLOSE: 'CERRAR',
    // ORIGINAL TEXT: CLOSE
    UPDATE: 'ACTUALIZACIÓN',
    // ORIGINAL TEXT: UPDATE
    REGISTER: 'REGÍSTRATE',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'acción',
    // ORIGINAL TEXT: LOGIN
    LOGINSSO: 'acción SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: 'RESET PASWORD',
    // ORIGINAL TEXT: RESET PASSWORD
    CHANGEPASSWORD: 'CAMBIO DE CONTRASEÑA',
    // ORIGINAL TEXT: CHANGE PASSWORD
    CANCELACCOUNT: 'DAR DE BAJA LA CUENTA',
    // ORIGINAL TEXT: CANCEL ACCOUNT
    QUICKSTARTVIDEO: 'VÍDEO DE INICIACIÓN RÁPIDA',
    // ORIGINAL TEXT: WATCH QUICK START VIDEO

    CHARGEBASIC: 'ACTUALIZAR AL PLAN BÁSICO',
    // ORIGINAL TEXT: UPGRADE TO BASIC
    CHARGEPREMIUM: 'ACTUALIZAR AL PLAN PREMIUM',
    // ORIGINAL TEXT: UPGRADE TO PREMIUM
    CHARGECORPORATE: 'ACTUALIZAR AL PLAN CORPORATIVO',
    // ORIGINAL TEXT: UPGRADE TO CORPORATE
    UPGRADESUBSCRIPTION: 'ACTUALIZAR SUSCRIPCIÓN',
    // ORIGINAL TEXT: UPGRADE SUBSCRIPTION
    GO: 'Vamos.',
    // ORIGINAL TEXT: GO
    PREVIEW_FEEDBACK: 'REVISAR CAMBIOS',
    // ORIGINAL TEXT: REVIEW CHANGES
    ACTIONS: 'ACTO',
    // ORIGINAL TEXT: ACT
    REFRESH: 'ACTUALIZAR',
    // ORIGINAL TEXT: REFRESH
    ADDNEWGROUP: 'AÑADIR NUEVO GRUPO',
    // ORIGINAL TEXT: ADD NEW GROUP
    ADDNEWDOCTYPE: 'AÑADIR NUEVO DOCUMENTO TIPO',
    // ORIGINAL TEXT: ADD NEW DOCTYPE
    ADDNEWALERT: 'AÑADIR NUEVA ALERTA',
    // ORIGINAL TEXT: ADD NEW ALERT
    GROUPSLIST: 'REVISAR LISTA DE GRUPO',
    // ORIGINAL TEXT: REVIEW GROUP LIST

    DOWNLOADDOCUMENT: 'DOCUMENTO DE DESCARGO',
    // ORIGINAL TEXT: DOWNLOAD DOCUMENT

    DOCUMENTTITLE: 'Título del documento',
    // ORIGINAL TEXT: Document Title
    DOCUMENTSUBJECT: 'Objeto del documento',
    // ORIGINAL TEXT: Document Subject
    SIGNMESSAGE: 'Mensaje para firmar',
    // ORIGINAL TEXT: Message for Signing
    NAMEPRIMARYSIGNER: 'Nombre: Signatario primario',
    // ORIGINAL TEXT: Name: Primary Signer
    EMAILPRIMARYSIGNER: 'Correo electrónico: Signatario principal',
    // ORIGINAL TEXT: Email: Primary Signer
    NAMECOUNTERSIGNER: 'Nombre: Counter Signer',
    // ORIGINAL TEXT: Name: Counter Signer
    EMAILCOUNTERSIGNER: 'Correo electrónico: Counter Signer',
    // ORIGINAL TEXT: Email: Counter Signer
    SIGREQUESTED: 'FIRMA SOLICITADA',
    // ORIGINAL TEXT: SIGNATURE REQUESTED
    SIGREQUESTEDBY: 'SOLICITUD',
    // ORIGINAL TEXT: REQUESTED BY
    SIGREQUESTEDID: 'ID DE FIRMA',
    // ORIGINAL TEXT: SIGNATURE ID
    SIGREQUESTEDAT: 'SOLICITUD EN EL',
    // ORIGINAL TEXT: REQUESTED AT
    SUBMITSIG: 'AÑADIR PARA LA FIRMA',
    // ORIGINAL TEXT: SUBMIT FOR SIGNATURE
    CANCELSIG: 'SOLICITUD DE FIRMA DE CANCEL',
    // ORIGINAL TEXT: CANCEL SIGNATURE REQUEST

    // DATA LABELS
    NAME: 'NOMBRE',
    // ORIGINAL TEXT: NAME
    EMAIL: 'EMAIL',
    // ORIGINAL TEXT: EMAIL
    STATUS: 'ESTADO',
    // ORIGINAL TEXT: STATUS
    LASTVIEWED: 'ÚLTIMA VISIÓN',
    // ORIGINAL TEXT: LAST VIEWED
    LASTREVIEWED: 'ÚLTIMA REVISIÓN',
    // ORIGINAL TEXT: LAST REVIEWED
    SIGNEDAT: 'FIRMADO',
    // ORIGINAL TEXT: SIGNED
    OWNERAPPROVAL: 'APROBACIÓN DEL PROPIETARIO',
    // ORIGINAL TEXT: OWNER APPROVAL
    OWNERACTION: 'ACCIÓN DEL PROPIETARIO',
    // ORIGINAL TEXT: OWNER ACTION
    NEGOTIATORAPPROVAL: 'HOMOLOGACIÓN DEL NEGOCIADOR',
    // ORIGINAL TEXT: NEGOTIATOR APPROVAL
    NEGOTIATORACTION: 'ACCIÓN DEL NEGOCIADOR',
    // ORIGINAL TEXT: NEGOTIATOR ACTION
    ARCHIVENOTES: 'NOTAS DE ARCHIVO',
    // ORIGINAL TEXT: ARCHIVE NOTES
    EXECUTEDCONTRACT: 'REALIZADO',
    // ORIGINAL TEXT: EXECUTED
    EXECUTEDDATE: 'FECHA DE REALIZACIÓN',
    // ORIGINAL TEXT: EXECUTED DATE
    CONTRACTATTACHED: 'CONTRATO ADJUNTO',
    // ORIGINAL TEXT: CONTRACT ATTACHED
    STARTDATE: 'FECHA DE INICIACIÓN',
    // ORIGINAL TEXT: START DATE
    ENDDATE: 'FECHA DE FIN',
    // ORIGINAL TEXT: END DATE
    EDIT: 'EDITADO',
    // ORIGINAL TEXT: EDIT
    USER: 'USUARIO',
    // ORIGINAL TEXT: USER
    TIME: 'HORA',
    // ORIGINAL TEXT: TIME
    CONFIRM: 'CONFIRMADO',
    // ORIGINAL TEXT: CONFIRM
    CONFIRMACTION: 'CONFIRMAR ACCIÓN',
    // ORIGINAL TEXT: CONFIRM ACTION
    CURRENT_LABEL: 'ETIQUETA actual',
    // ORIGINAL TEXT: CURRENT LABEL
    NEW_LABEL: 'ETIQUETA NUEVA',
    // ORIGINAL TEXT: NEW LABEL
    DESCRIPTION: 'DESCRIPCIÓN',
    // ORIGINAL TEXT: DESCRIPTION
    TYPE: 'TIPO',
    // ORIGINAL TEXT: TYPE
    SENTENCE: 'SENTENCIA',
    // ORIGINAL TEXT: SENTENCE
    NUMBER: '#',
    // ORIGINAL TEXT: #
    PRIVILEGE: 'ROL',
    // ORIGINAL TEXT: ROLE
    OWNERID: 'PROPIEDADES',
    // ORIGINAL TEXT: OWNERID
    DOCNAME: 'NOMBRE DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT NAME
    DOCTYPE: 'TIPO DE DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT TYPE
    DOCDESCRIPTION: 'DESCRIPCIÓN DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT DESCRIPTION
    DOCNOTES: 'NOTAS DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT NOTES
    DOCPARTY: 'PARTE',
    // ORIGINAL TEXT: PARTY
    DOCCOUNTERPARTY: 'PARTE CONSULTIVA',
    // ORIGINAL TEXT: COUNTER PARTY
    DOCRAWFILENAME: 'NOMBRE DEL ARCHIVO DEL DOCUMENTO',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'TAGS',
    // ORIGINAL TEXT: TAGS
    COLLABORATIONSTATUS: 'ESTADO DE COLABORACIÓN',
    // ORIGINAL TEXT: COLLABORATION STATUS
    COLLABORATIONEND: 'FIN DE COLABORACIÓN',
    // ORIGINAL TEXT: COLLABORATION END
    NEGOTIATIONSTATUS: 'ESTADO DE NEGOCIACIÓN',
    // ORIGINAL TEXT: NEGOTIATION STATUS
    NEGOTIATIONEND: 'FIN DE NEGOCIACIÓN',
    // ORIGINAL TEXT: NEGOTIATION END
    DOCID: 'ID DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT ID
    PARAGRAPHID: 'PÁRRAFO ID',
    // ORIGINAL TEXT: PARAGRAPH ID
    ACTION: 'ACCIÓN LOGGED',
    // ORIGINAL TEXT: LOGGED ACTION
    DETAIL: 'DETALLACIÓN DE LA ACCIÓN',
    // ORIGINAL TEXT: ACTION DETAIL
    CONTEXT: 'CONTEXTO ADICIONAL',
    // ORIGINAL TEXT: ADDITIONAL CONTEXT
    GROUPNAME: 'NOMBRE DEL GRUPO',
    // ORIGINAL TEXT: GROUP NAME
    GROUPDESC: 'DESCRIPCIÓN DEL GRUPO',
    // ORIGINAL TEXT: GROUP DESCRIPTION
    DOCTYPEDESC: 'DESCRIPCIÓN DEL TIPO DE DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT TYPE DESCRIPTION
    ADDLCLAUSETYPES: 'ETIQUETAS DE CLÁUSULA PERSONALIZADA',
    // ORIGINAL TEXT: CUSTOM CLAUSE LABELS
    ADDLTAGS: 'DESCRIPCIÓN DE ETIQUETAS',
    // ORIGINAL TEXT: CUSTOM TAGS
    COLLABORATIONRULES: 'NORMAS DE COLABORACIÓN',
    // ORIGINAL TEXT: COLLABORATION RULES
    NEGOTIATIONRULES: 'NORMAS DE NEGOCIACIÓN',
    // ORIGINAL TEXT: NEGOTIATION RULES
    NOTIFICATIONRULES: 'NORMAS DE NOTIFICACIÓN',
    // ORIGINAL TEXT: NOTIFICATION RULES
    LOG: 'LOG',
    // ORIGINAL TEXT: LOG
    ALERTNAME: 'NOMBRE DE LA ALERTA',
    // ORIGINAL TEXT: ALERT NAME
    ALERTDESC: 'DESCRIPCIÓN DE LA ALERTA',
    // ORIGINAL TEXT: ALERT DESCRIPTION
    TOPICS: 'TEMAS',
    // ORIGINAL TEXT: TOPICS
    RECIPIENTS: 'DESTINATARIOS',
    // ORIGINAL TEXT: RECIPIENTS
    SELECTIVITY: 'SELECCIÓN',
    // ORIGINAL TEXT: SELECTIVITY
    FIELDNAME: 'NOMBRE DEL CAMPO',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: 'PEDIDO',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: 'EJEMPLO',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: 'DEFECTO',
    // ORIGINAL TEXT: DEFAULT
    VALUE: 'VALOR',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: 'DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: 'MANUAL DE PROCEDIMIENTO',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: 'PLANTILLA',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: 'DOCUMENTO ARCHIVADO',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: 'ORGANIZADO A PARTIR DE LA PLANTILLA:',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: 'COLABORACIÓN',
    // ORIGINAL TEXT: COLLABORATION
    NEGSHORT: 'NEGOCIACIÓN',
    // ORIGINAL TEXT: NEGOTIATION
    OWNERS: 'PROPIETARIOS',
    // ORIGINAL TEXT: OWNERS
    COLLABORATORS: 'COLABORADORES',
    // ORIGINAL TEXT: COLLABORATORS
    NEGOTIATORS: 'NEGOCIADORES',
    // ORIGINAL TEXT: NEGOTIATORS
    // ADDITION
    DOCUMENTDETAILS: 'DETALLES DEL DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT DETAILS
    CREATIONDATE: 'FECHA DE CREACIÓN',
    // ORIGINAL TEXT: CREATION DATE
    PARTICIPANTS: 'PARTICIPANTES',
    // ORIGINAL TEXT: PARTICIPANTS
    COLLABORATIONANDNEGOTIATION: 'COLABORACIÓN Y NEGOCIACIÓN',
    // ORIGINAL TEXT: COLLABORATION AND NEGOTIATION
    DOCTYPEANDGROUPACCESS: 'TIPO DE DOCUMENTO Y ACCESO DE GRUPOS',
    // ORIGINAL TEXT: DOCUMENT TYPE AND GROUP ACCESS
    GROUPOWNERS: 'PROPIETARIOS DE GRUPOS',
    // ORIGINAL TEXT: GROUP OWNERS
    GROUPCOLLABORATORS: 'COLABORADORES DE GRUPOS',
    // ORIGINAL TEXT: GROUP COLLABORATORS
    DOCUMENTSETTINGS: 'PROPIEDADES DE LOS DOCUMENTOS',
    // ORIGINAL TEXT: DOCUMENT SETTINGS
    ENABLECOLLABTOMODLABELS:
      'Habilitar colaboradores para modificar etiquetas de cláusulas',
    // ORIGINAL TEXT: Enable Collaborators to Modify Clause Labels
    ENABLENEGOTCAPABILITIES:
      'Habilitar las capacidades de negociación (no el modo de negociación)',
    // ORIGINAL TEXT: Enable Negotiation capabilities (Not Negotiation mode)
    KEEPDOCNOTES: 'Copiar notas de documentos con operación de copia',
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS:
      'Temas de cláusula revisados y modificados como necesarios',
    // ORIGINAL TEXT: Reviewed and Amended Clause Topics as Necessary
    NOEXECUTIONDATE: 'Sin fecha de ejecución',
    // ORIGINAL TEXT: No Execution Date
    EXECUTIONDATE: 'Fecha de ejecución',
    // ORIGINAL TEXT: Execution Date
    CONTRACTSTARTDATE: 'Fecha de inicio del contrato',
    // ORIGINAL TEXT: Contract Start Date
    CONTRACTENDDATE: 'Fecha de finalización del contrato',
    // ORIGINAL TEXT: Contract End Date
    EXECCONTRACTNAME: 'Nombre del archivo del contrato ejecutado',
    // ORIGINAL TEXT: Executed Contract File Name
    SELECTEXECCONTRACT: 'seleccionar archivo para cargar el contrato ejecutado',
    // ORIGINAL TEXT: select file to upload executed contract
    UPLOADEXECUTEDCONTRACT: 'CARGAR EL CONTRATO FINAL',
    // ORIGINAL TEXT: UPLOAD EXECUTED CONTRACT
    LOADDEFAULTS: 'ERROR DE CARGA',
    // ORIGINAL TEXT: LOAD DEFAULTS
    CLOSED: 'CERRADO',
    // ORIGINAL TEXT: CLOSED
    NONE: 'NINGUNA',
    // ORIGINAL TEXT: NONE
    NA: 'NA',
    // ORIGINAL TEXT: NA
    RESULTEXECUTEDCONTRACT: 'Resultado del contrato ejecutado',
    // ORIGINAL TEXT: Resulted in Executed Contract
    NOSTARTDATE: 'Sin fecha de inicio',
    // ORIGINAL TEXT: No Start Date
    NOENDDATE: 'Fecha sin fin',
    // ORIGINAL TEXT: No End Date
    EXCLUDECOLLABORATORSFROMACCESS:
      'Excluir a los colaboradores externos de acceso al documento',
    // ORIGINAL TEXT: Exclude External Collaborators From Accessing Document
    EXCLUDENEGOTIATORSFROMACCESS:
      'Excluir a los negociadores externos del acceso al documento',
    // ORIGINAL TEXT: Exclude External Negotiators From Accessing Document
    REVIEWEDAMENDEDCLAUSELABELS:
      'Etiquetas de cláusulas revisadas y modificadas como necesarias',
    // ORIGINAL TEXT: Reviewed and Amended Clause Labels as Necessary
    ENTERPARAGRAPHBETWEEN: 'Introduzca un número de párrafo entre:',
    // ORIGINAL TEXT: Enter a paragraph number between:
    GOTOPARAGRAPHINDEX: 'Ir al índice de párrafos',
    // ORIGINAL TEXT: Go to Paragraph Index
    INPUTDOCUMENTNOTEHERE: 'Nota del documento de entrada aquí',
    // ORIGINAL TEXT: Input Document Note here
    INPUTCOMMENTHERE: 'Comentario de entrada aquí',
    // ORIGINAL TEXT: Input Comment here
    SHOWPENDINGONLY: 'Mostrar sólo decisiones pendientes',
    // ORIGINAL TEXT: Show Pending Only
    COLLABORATIONSETTING: 'CONFIGURACIÓN DE LA COLABORACIÓN',
    // ORIGINAL TEXT: COLLABORATION SETTING
    NEGOTIATIONSETTING: 'CONFIGURACIÓN DE LA NEGOCIACION',
    // ORIGINAL TEXT: NEGOTIATION SETTING
    NONEGOTWHENAPPROVED:
      'No se puede activar la negociación si ya se ha aprobado',
    // ORIGINAL TEXT: Cannot turn on Negotiation when in Approved State
    EMAILTOADD: 'añadir Correo electrónico',
    // ORIGINAL TEXT: Email to add
    CLAUSETOADD: 'Cláusula que debe añadirse',
    // ORIGINAL TEXT: Clause to add
    TAGTOADD: 'Etiqueta para añadir',
    // ORIGINAL TEXT: Tag to add
    OPTIONSFORREDLINEINCLUDE: 'Opciones para Redlines a incluir',
    // ORIGINAL TEXT: Options for Redlines to Include
    ONLYINCLUDEOWNERAPPROVEDEDITS:
      'Sólo incluir ediciones de documentos aprobadas por el propietario',
    // ORIGINAL TEXT: Only Include Owner-approved Document Edits
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'Sólo incluye ediciones de documentos aprobadas por el propietario y el negociador',
    // ORIGINAL TEXT: Only Include Owner and Negotiator-approved Document Edits
    INCLUDEALLDOCEDITS:
      'Incluir todas las ediciones de documentos (independientemente de la aprobación)',
    // ORIGINAL TEXT: Include All Document Edits (regardless of approval)
    REDLINECHANGESSELECTED: 'Cambios readline seleccionados',
    // ORIGINAL TEXT: Redlined Changes Selected
    TOTALCHANGES: 'Total de cambios',
    // ORIGINAL TEXT: Total Changes
    REPLACEREDLINES: 'Reemplazar Redlines',
    // ORIGINAL TEXT: Replace Redlines
    DELETEREDLINES: 'Eliminar Redlines',
    // ORIGINAL TEXT: Delete Redlines
    ADDAFTERREDLINES: 'Añadir-después de Redlines',
    // ORIGINAL TEXT: Add-After Redlines
    OPTIONSFORPREVREDLINES: 'Opciones para líneas rojas anteriores',
    // ORIGINAL TEXT: Options for Previous Redlines
    OVERWRITEPREVREDLINES:
      'Sobrescribir todas las líneas rojas anteriores en el origen del documento',
    // ORIGINAL TEXT: Overwrite all Previous Redlines in Document Source
    KEEPPREVREDLINES:
      'Mantenga todas las líneas rojas anteriores en la fuente del documento',
    // ORIGINAL TEXT: Keep all Previous Redlines in Document Source
    GENERATEFINALDOC: 'Generar el documento final (sin Redlines)',
    // ORIGINAL TEXT: Generate Final Document (without Redlines)
    DEFAULTESIGNOTE:
      'Como se ha dicho, por favor proporcione su firma electrónica a este contrato.',
    // ORIGINAL TEXT: As discussed, please provide your eSignature to this contract.
    SUBMITTEDSIGMESSAGE: 'Solicito firmas.',
    // ORIGINAL TEXT: Submitted for signatures.  Please wait.
    UPLOADEDMESSAGE: 'Cargado',
    // ORIGINAL TEXT: Uploaded
    ADDADDLFILES:
      'Opcional - Añadir archivos adicionales a la solicitud de firma',
    // ORIGINAL TEXT: Optional - Adding additional files to signature request
    ADDFILESMSG: 'añadir archivos adicionales para la firma',
    // ORIGINAL TEXT: add additional files for signature
    ESIGPREPAIDPLANS:
      'La función de firma electrónica solo está disponible a través de nuestros planes prepagos.',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      'Contáctenos en billing@mylegaleinstein.com para obtener más detalles.',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: 'Más videos en el ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'El tipo de documento no es compatible. MLE sólo acepta formatos docx y pdf.',
    // ORIGINAL TEXT: Document type is not supported.  MLE only accepts docx and pdf formats.
    PDFSUPPORTEDFORPREMIUMONLY:
      'Para el escaneo de PDF, el usuario necesita actualizar a suscripción Premium o mejor.',
    // ORIGINAL TEXT: For PDF scanning, user needs to upgrade to Premium subscription or better.
    BADDOCFORMAT:
      'El formato de documento incorrecto se ha sometido a procesamiento.',
    // ORIGINAL TEXT: Bad Document format was passed to document processing.
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      'Usted no tiene permiso para hacer cambios en la configuración del documento. Se requiere ser propietario.',
    // ORIGINAL TEXT: You do not have the privilege to make changes to the Document Settings.  You are required to be an Owner.
    PRIVILEGSNOTAVAIL:
      'Los permisos para este usuario no están disponibles. Inténtalo de nuevo más tarde.',
    // ORIGINAL TEXT: The privileges for this user are not available.  Try again later.
    EXHAUSTEDTRIALLICENSE:
      "Usted ha agotado el número de documentos permitidos bajo su período de prueba. Por favor, seleccione 'Subscripción de actualización' en su perfil.",
    // ORIGINAL TEXT: You have exhausted the number of allowed documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    EXHAUSTEDMONTHLYLIMIT:
      "Usted ha agotado el número de documentos mensuales permitidos bajo su período de prueba. Por favor, seleccione 'Subscripción de actualización' en su perfil.",
    // ORIGINAL TEXT: You have exhausted the number of allowed monthly documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    PASSWORDNOTMATCHING:
      'Las contraseñas no coinciden. Compruebe las contraseñas de nuevo.',
    // ORIGINAL TEXT: Passwords do not match.  Check the passwords again.
    ABAMEMBERIDLENGTH:
      'Los ID de membresía de ABA tienen 7 caracteres de largo. Por favor revise su ID e introduzca la correcta.',
    // ORIGINAL TEXT: ABA Membership IDs are 7 characters long.  Please review and submit.
    TERMSNOTCHECKED:
      'Los Términos y Condiciones no han sido revisados y revisados.',
    // ORIGINAL TEXT: The Terms and Conditions have not been reviewed and checked.
    EMAILFORMATBAD:
      'El formato de correo electrónico no es válido. Revise la dirección de correo electrónico.',
    // ORIGINAL TEXT: Email format is not valid.  Please review email address.
    CANCELEDACTION:
      'Su última acción ha sido cancelada. La aplicación se volverá a cargar.',
    // ORIGINAL TEXT: Your last action have been canceled.  Application will now reload.
    SUCCESSACTION:
      'Su última acción ha tenido éxito. La aplicación ahora se recargará.',
    // ORIGINAL TEXT: Your last action has been successful.  Application will now reload.
    USERSUCCESSLOGIN: 'El usuario ha iniciado sesión con éxito.',
    // ORIGINAL TEXT: User has successfully logged on.
    USERLOGGEDOUT: 'El usuario ha cerrado su sesión',
    // ORIGINAL TEXT: The user has been logged out
    EMAILNOTVERIFIED:
      'Aún no se ha verificado el correo electrónico de la cuenta. Se le ha enviado una verificación por correo electrónico.',
    // ORIGINAL TEXT: Account email has not yet been verified.  An email verification has been sent to you.
    EMAILALREADYREGISTERED:
      'Este correo electrónico ya tiene una cuenta previamente registrada.',
    // ORIGINAL TEXT: This email already has a previously registered account.
    EMAILNOTVERIFIEDRESENT:
      'Su correo electrónico no ha sido verificado. Le hemos reenviado un email con un enlace de verificación para que reactive la nueva cuenta.',
    // ORIGINAL TEXT: Your email has not been verified.  We have resent you an email verification link to your email to activate the new account.
    USERLOGFILENOTEXISTS:
      'Error - no existe ningún archivo de usuario de registro.',
    // ORIGINAL TEXT: Error - no log user file exists.
    PASSWORDRESETSENT:
      'Se ha enviado un mensaje de restablecimiento de contraseña de correo electrónico para su cuenta.',
    // ORIGINAL TEXT: An email password reset message has been sent for your account.
    PASSWORDCHANGED: 'Su contraseña ha sido cambiada.',
    // ORIGINAL TEXT: Your password has been changed.
    REGISTRATIONDELETED: 'Registro de usuario eliminado',
    // ORIGINAL TEXT: deleted user registration
    USERACCOUNTUPDATED:
      'Ha actualizado la configuración de su cuenta de usuario. Los cambios entrarán en vigor la próxima vez que inicie sesión.',
    // ORIGINAL TEXT: You have updated your user account settings.  Changes will take into effect the next time you login.
    UPGRADEDTOREFERRED:
      'Su cuenta ha sido actualizada a la suscripción de prueba referida.',
    // ORIGINAL TEXT: Your account has been updated to Referred Trial subscription.
    REGISTEREDTRIALUSER:
      'Su nueva cuenta está registrada como suscripción de prueba.',
    // ORIGINAL TEXT: New account is registered as Trial subscription.
    DELETEDUSERACCOUNT: 'Cuenta de perfil de usuario eliminada',
    // ORIGINAL TEXT: Deleted user profile account
    EMAILALREADYREGISTEREDRESENT:
      'El correo electrónico aún no ha sido registrado con Docgility. Se ha enviado una invitación por correo electrónico al nuevo usuario.',
    // ORIGINAL TEXT: Email has not already been registered with Docgility.  An email invitation has been sent to the new user.
    UPGRADEEXTERNALPAYMENTS:
      'Gracias por la actualización. Usted será redirigido a un servicio de pago externo y después de completar su transacción, tendrá que iniciar sesión de nuevo para comenzar a disfrutar de su suscripción.',
    // ORIGINAL TEXT: Thanks for upgrading.  You will be rerouted to an external payment service and after completing your transaction, you will need to login again for subscription service to start.
    BILLINGEXTERNALPAYMENTS:
      'Por favor confirme que le gustaría ser dirigido a nuestro servicio de pago y facturación externo. Después de realizar cualquier cambio, tendrá que iniciar sesión de nuevo para que los cambios surtan efecto.',
    // ORIGINAL TEXT: Please confirm that you will like to be directed to our external payment and billing service.  After you make any changes, you will need to login again for changes to take effect.
    NOTABLETOLOGINATTHISTIME:
      'No se puede iniciar sesión en este momento. Intentelo de nuevo más tarde.',
    // ORIGINAL TEXT: Not able to login at this time.  Try again later.
    NOTREGISTERED:
      'El usuario no se ha registrado previamente. Regístrese antes de intentar iniciar sesión.',
    // ORIGINAL TEXT: User has not previously registered.  Register before trying to login.
    NAMETOOLONG:
      'El nombre que ha introducido es demasiado largo. Max tiene 60 caracteres.',
    // ORIGINAL TEXT: The name you entered is too long.  Max is 60 characters.
    COMPANYTOOLONG:
      'El nombre de compañía que ha introducido es demasiado larga, Max tiene 60 caracteres.',
    // ORIGINAL TEXT: The company you entered is too long.  Max is 60 characters.
    COMMENTTOOLONG:
      'Los comentarios que ha introducido son demasiado extensos. Max tiene 10000 caracteres.',
    // ORIGINAL TEXT: The comments you entered is too long.  Max is 10000 characters.
    DOCEDITTOOLONG:
      'El documento que ha introducido es demasiado largo. Max es 10000 caracteres.',
    // ORIGINAL TEXT: The document edits you entered is too long.  Max is 10000 characters.
    TITLETOOLONG:
      'El título que has introducido es demasiado largo. Max tiene 60 caracteres.',
    // ORIGINAL TEXT: The title you entered is too long.  Max is 60 characters.
    PASSWORDTOOSHORT:
      'La contraseña que ha introducido es demasiado corta. La contraseña debe tener al menos 6 caracteres.',
    // ORIGINAL TEXT: The password you entered is too short.  Password has to be at least 6 characters.
    CONGRATSNEWDOCUMENTPROCESSING:
      '¡Genial! Usted ha procesado un nuevo documento. Espere unos minutos y el panel se refrescará cuando haya finalizado el procesamiento del documento. Por favor espere unos minutos.',
    // ORIGINAL TEXT: Great! You have processed a new document.  It takes a few minutes and the panel should refresh when the document is completed processing.  Please wait a few minutes.

    // ADDITION
    NODOCUMENTLOADED: 'Ningún documento cargado',
    // ORIGINAL TEXT: No document loaded
    SELECTFILETOUPLOADDOCX: 'seleccionar archivo a cargar (*.docx)',
    // ORIGINAL TEXT: select file to upload (*.docx)
    SELECTFILETOUPLOADDOCXPDF:
      'seleccionar archivo para cargar (*.docx, *.pdf)',
    // ORIGINAL TEXT: select file to upload (*.docx, *.pdf)
    INPUTCOMMENTHERE: 'Introduzca aquí su comentario',
    // ORIGINAL TEXT: Input comment here
    AUTOAPPROVEASOWNER: 'Autoaprobación como propietario',
    // ORIGINAL TEXT: Auto Approve as Owner
    INPUTDOCEDITSHERE: 'Introduzca aquí el documento editado',
    // ORIGINAL TEXT: Input Document Edit here

    // ADDED
    TRIGGEREDALLALERTS:
      'Activado todas las alertas (si las hay) para este documento doctype.',
    // ORIGINAL TEXT: Triggered all (if any) Alerts for this doctype document.
    QSINTROMESSAGE:
      'MyLegalEinstein es una completa plataforma de colaboración contractual que analiza instantáneamente sus contratos, los categoríza por temas, y permite realizar comparaciones de hasta X documentos simultanemante.',
    // ORIGINAL TEXT: MyLegalEinstein instantly analyzes your contracts, indexes by legal topics, and allows side-by-side comparisons of legal language in a complete contract collaboration platform. We will demonstrate the capabilities in four simple steps (detailed instructions are provided in english but similar in all languages.)
    QSDOCMESSAGE:
      'Para su comodidad, aquí están algunos contratos de dominio público que usted puede descargar y utilizar para probar My Legal Einstei:',
    // ORIGINAL TEXT: For your convenience, here are some public domain contracts that you can download and try:
    QSHELPMESSAGE:
      'Aquí hay un enlace útil a la documentación del producto (con una versión prolija de Quick Start)',

    DOCUMENTFAILEDTOPROCESS:
      'Se produjo un error fatal al procesar el documento. Inténtelo de nuevo. Si el problema persiste, póngase en contacto con el servicio de atención al cliente en support@mylegaleinstein.com.',
    // ORIGINAL TEXT: A fatal error occurred while processing the document.  Please try again.  If problem persists, please contact customer support at support@mylegaleinstein.com.

    // ADDED
    DOCUMENTSUCCESSCOPIED: 'Su documento ahora ha sido copiado.',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      'Ahora se ha generado y guardado una versión limpia de este documento.',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      'Se generó un document a partir de esta plantilla y se guardó.',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE:
      'Un documento se ha convertido en una plantilla de documento.',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      'Confirme que desea borrar el documento. Una vez lo elimine, dejará de estar disponible. También puede probar a mover el documento al estado Archivo. \n\n Por favor confirme la operación borrar.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified document.  After document is deleted, it will no longer be available.  You may also consider moving the document to Archive status.  \n\n Please confirm delete operation.
    CONFIRMSAVETOPLAYBOOK:
      'Por favor, confirme que para convertir este documento en un manual de procedimiento. Esto hará que el documento sólo lo pueda modificar su propietario. \n\n Por favor confirme la operación de guardar en la colección de manuales de procedimientos.',
    // ORIGINAL TEXT: Please confirm that to convert this document to a playbook.  This would make this document unchangable except for the owner.  \n\n Please confirm the save to playbook operation.
    COLLABORATIONNOTE:
      'NOTA: Esta información se compartirá únicamente de forma interna con colaboradores designados y propietarios de documentos.',
    // ORIGINAL TEXT: NOTE: This information is shared INTERNALLY ONLY with designated collaborators and document owners only.
    NEGOTIATIONNOTE:
      'NOTA: Esta información se comparte EXTERNAMENTE con las partes de la negociación.',
    // ORIGINAL TEXT: NOTE: This information is shared EXTERNALLY with negotiation counter parties.
    CONFIRMDELETEALERT:
      'Confirme que desea borrar la alerta especificada. Después de que la alerta se elimine, ya no estará disponible. Confirme la operación de borrado.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified alert.  After alert is deleted, it will no longer be available.  Please confirm delete operation.
    CONFIRMDELETERECIPIENTALERT:
      'Confirme que ya no desea ser un destinatario de la alerta especificada. Después de eliminarse como destinatario, puede solicitar que se le añada de nuevo contactando con el propietario de la alerta. Confirme la operación de eliminación.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.
    CONFIRMDELETEGROUP:
      'Confirme que desea eliminar el grupo especificado. Después de que el grupo se elimine, ya no estará disponible. Sin embargo, reflejará la próxima vez que vuelva a iniciar sesión. Confirme la operación de eliminación.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified group.  After group is deleted, it will no longer be available.  However, it will reflected the next time you log back in.  Please confirm delete operation.
    CONFIRMDELETECOLLABORATORGROUP:
      'Por favor confirme que ya no desea ser colaborador del grupo especificado. Después de eliminarse como colaborador, puede solicitar que se le añada de nuevo contactando con el propietario del grupo. Confirme la operación de eliminación.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a collaborator for the specified group.  After removing yourself as a collaborator, you can request to be added back by contacting the owner of the group.  Please confirm delete operation.
    CONFIRMDELETEDOCTYPE:
      'Confirme que desea eliminar el documento tipo especificado. Después de eliminar el doctype, ya no estará disponible. Confirme la operación de borrado.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified doctype.  After doctype is deleted, it will no longer be available.  Please confirm delete operation.
    EXPIREDTRIAL:
      'Su licencia de prueba ha expirado. Por favor, actualice para continuar su servicio.',
    // ORIGINAL TEXT: Your trial license has expired.  Please upgrade to continue your service.
    EXPIREDSUBSCRIPTION:
      'Su licencia de suscripción ha expirado. Por favor, actualícela para continuar con su servicio o contacto con el departamento de facturación de Docgility.',
    // ORIGINAL TEXT: Your subscription license has expired.  Please upgrade to continue your service or contact billing at Docgility.
    SOURCEDOCNOTDOCX:
      'El documento fuente no es un documento DOCX. Las capacidades de Redline no están disponibles.',
    // ORIGINAL TEXT: The Source document is not a DOCX document.  Redline capabilities are not available.
  },
}

export default ES
