import store from '@state/store'

import SnackService from '@services/SnackService'

import {
  DBgetobjsync,
  DBupdateobjsync,
  DBsetonesync,
} from '@/src/state/io/iodb.js'

import {
  AUTHcreateUserEmailPassword,
  AUTHsendEmailVerification,
  AUTHsendPasswordReset,
  AUTHchangePassword,
  // AUTHdeleteUserReg,
} from '@/src/state/io/ioauth.js'

const USERSTABLENAME = 'users'
const REFERREDTABLENAME = 'referred'

// Supports both Firebase or Enterprise Authentication - logic for adjusting based on verification
const AUTHFIREBASE = process.env.VUE_APP_AUTH === 'FIREBASE'
// const AUTHENTERPRISE = process.env.VUE_APP_AUTH === 'ENTERPRISE'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  init({ commit }) {},

  // creates a new registration account
  createUserfromEmailPWReg({ commit, state }, payload) {
    DBgetobjsync(USERSTABLENAME, payload.uniqid).then(function(doc) {
      if (doc.exists) {
        SnackService.info('MESSAGES.EMAILALREADYREGISTERED')
      } else {
        store.dispatch('dbreg/completeRegistrationReg', payload)

        // added here to fix registration error
        // this still itermittantly does not get fired.  added a timer.  perhaps this would fix it
        // but need to roll out to see if it fixes the problem.  Can't reproduce error
        // For now, keep the Timeout.  Hope I don't see this problem again.
        setTimeout(store.dispatch('dbreg/createUserProfileReg', payload), 2000)
        // store.dispatch('dbreg/createUserProfileReg', payload)
      }
    })
  },

  // completes registration process - email authentication request and creates user profile
  completeRegistrationReg({ commit, state }, payload) {
    AUTHcreateUserEmailPassword(payload.uniqid, payload.password)
      .then((user) => {
        // if ENTERPRISE AUTH, auto send via the django environment.
        if (AUTHFIREBASE) {
          store.dispatch('dbreg/sendInitialEmailVerificationReqReg', {
            user: user,
            payload: {},
          })
        }
      })
      .catch((e) => {
        store.dispatch('dberror/logError', e)
      })

    // this does not always happen (registration bug)
    // gives a bug for write access.
    store.dispatch('dbreg/createUserProfileReg', payload)
  },

  // creates new registration account
  createUserProfileReg({ commit, state }, payload) {
    // date of creation and 30 days ahead
    let todaydate = new Date()
    let expdate = new Date()
    expdate.setDate(todaydate.getDate() + 30)

    const newuniqid = payload.uniqid.toLowerCase().trim()

    const obj = {
      name: payload.name.trim(),
      company: payload.company.trim(),
      title: payload.title,
      uniqid: newuniqid,
      groupid: [],
      subscriptionid:
        process.env.VUE_APP_INSTANCETYPE === 'ENTERPRISE' ? 'G5' : 'A',
      stripestatus: 'uninitialized',
      regstartdate: todaydate,
      trailexpdate: expdate,
      disablealerts: false,
      active: true,
      memberid: payload.memberid,
    }

    // also, create the log file for this user.
    store.dispatch('dblogdoc/writeNewLogUser', newuniqid)

    DBsetonesync(USERSTABLENAME, newuniqid, obj)
      .then(store.dispatch('dbreg/upgradetoBifReferred', newuniqid))
      .catch(function(e) {
        store.dispatch('dberror/logError', e)
      })
  },

  // sends an email verification request for a specific user
  sendInitialEmailVerificationReqReg({ commit, state }, obj) {
    AUTHsendEmailVerification(obj)
      .then(
        SnackService.info(
          'Email verification request has been sent to your email'
        )
      )
      .catch((e) => {
        store.dispatch('dberror/logError', e)
      })
  },

  // sends an email verification request for a specific user
  sendEmailVerificationReqReg({ commit, state }, obj) {
    AUTHsendEmailVerification(obj)
      .then(SnackService.info('MESSAGES.EMAILNOTVERIFIEDRESENT'))
      .catch((e) => {
        store.dispatch('dberror/logError', e)
      })
  },

  // sends an email to the user to reset the password.
  confirmPasswordResetReg({ commit, state }, uniqid) {
    AUTHsendPasswordReset(uniqid)
      .then(function() {
        SnackService.info('MESSAGES.PASSWORDRESETSENT')
      })
      .catch((e) => {
        store.dispatch('dberror/logError', e)
      })
    // triggers insufficient permissions.
    // log
    // store.dispatch('dblogdoc/addLogUserOnly', {
    //   uniqid: uniqid,
    //   logdetail: {
    //     action: 'USEREVENTS.PASSWORDRESETREQUESTSENT',
    //     detail: '',
    //     context: '',
    //   },
    // })
  },

  // within the app, reset the password to something else.
  updatePasswordinAppReg({ commit, state }, obj) {
    AUTHchangePassword(obj)
      .then(() => {
        SnackService.info('MESSAGES.PASSWORDCHANGED')
      })
      .catch((e) => {
        store.dispatch('dberror/logError', e)
      })
    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: obj.uniqid,
      logdetail: {
        action: 'USEREVENTS.CHANGEDPASSWORD',
        detail: '',
        context: '',
      },
    })
  },

  // Not used
  // cancelAccountReg({ commit, state }, uniqid) {
  //   AUTHdeleteUserReg()
  //     .then(() => {
  //       SnackService.info('MESSAGES.REGISTRATIONDELETED')
  //     })
  //     .catch((e) => {
  //       store.dispatch('dberror/logError', e)
  //     })

  //   store.dispatch('dbreg/deleteUserProfileReg', uniqid)
  //   // log
  //   store.dispatch('dblogdoc/addLogUserOnly', {
  //     uniqid: uniqid,
  //     logdetail: {
  //       action: 'USEREVENTS.DELETEDREGISTRATION',
  //       detail: '',
  //       context: '',
  //     },
  //   })
  // },

  updateAccountSettingsReg({ commit, state }, payload) {
    DBupdateobjsync(USERSTABLENAME, payload.uniqid, {
      name: payload.name,
      company: payload.company,
      title: payload.title,
      disablealerts: payload.disablealerts,
      language: payload.language,
    })
      .then(SnackService.info('MESSAGES.USERACCOUNTUPDATED'))
      .catch(function(e) {
        store.dispatch('dberror/logError', e)
      })
    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: payload.uniqid,
      logdetail: {
        action: 'USEREVENTS.UPDATEDACCOUNTINFO',
        detail: payload.name,
        context: payload.company,
      },
    })
  },

  // based on the whether this email has been previously referred, will update the subscription service to B.
  // later, change this to include corporate payment accounts.
  upgradetoBifReferred({ commit }, uniqid) {
    // check if it's referred list and under 30 days.
    // need to check on the 30 days or auto-purge referral list?

    DBgetobjsync(REFERREDTABLENAME, uniqid).then(function(doc) {
      if (doc.exists) {
        // Doing something always seems to force this code.  Not sure why but without this duplicate, it seems to skip it.
        SnackService.info('MESSAGES.UPGRADEDTOREFERRED')

        DBupdateobjsync(USERSTABLENAME, uniqid, {
          subscriptionid: 'B',
        })
          .then(SnackService.info('MESSAGES.UPGRADEDTOREFERRED'))
          .catch(function(e) {
            store.dispatch('dberror/logError', e)
          })
      } else {
        SnackService.info('MESSAGES.REGISTEREDTRIALUSER')
      }
    })
  },

  deleteUserProfileReg({ commit, state }, uniqid) {
    DBupdateobjsync(USERSTABLENAME, uniqid, {
      active: false,
      delete: new Date(),
    })
      .then(SnackService.info('MESSAGES.DELETEDUSERACCOUNT'))
      .catch(function(e) {
        store.dispatch('dberror/logError', e)
      })
    // log
    store.dispatch('dblogdoc/addLogUserOnly', {
      uniqid: uniqid,
      logdetail: {
        action: 'ADMINEVENTS.DELETEDUSERACCOUNT',
        detail: '',
        context: '',
      },
    })
  },

  // SYNCHRONOUS
  // write New User then do next step.
  writeNewUserReg({ commit, state }, payload) {
    return new Promise(function(resolve, reject) {
      DBsetonesync(USERSTABLENAME, payload.uniqid, payload)
        .then(resolve(true))
        .catch(function(e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },

  // get users profile from the DB
  getUserProfileReg({ commit, state }) {
    DBgetobjsync(USERSTABLENAME, state.dbauthuser.uniqid)
      .then((res) => {
        const obj = res.data
        obj.keyid = res.id
        commit('SET_DBAUTHPROFILE', obj)
      })
      .catch(function(e) {
        store.dispatch('dberror/logError', e)
      })
  },
}
