// Contains all application constants //

export const PT = {
  TOPMENU: {
    FILE: 'FILE',
    EDIT: 'EDIT',
    ACT: 'ACT',
    DOCS: 'DOCS',
  },

  MENU: {
    NEWDOCUMENT: 'Novo Documento',
    SAVEDOCUMENT: 'Salvar documento',
    COPYDOCUMENT: 'Copiar Documento',
    CREATEVERSION: 'Criar versão limpa',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: 'Criar documento a partir do modelo',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: 'Abrir Documento',
    OPENGROUPDOCUMENT: 'Abrir documento do grupo',
    OPENHUBDOCUMENT: 'Abrir HUB Documento',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: 'Abrir Documento de Arquivo',
    OPENPLAYBOOK: 'Abrir manual',
    OPENTEMPLATE: 'Abrir modelo',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: 'Guardar como modelo',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: 'Salvar como manual',
    MOVETOARCHIVE: 'Mover para o arquivo',
    CLOSEDOCUMENT: 'Fechar documento',
    DOCUMENTLOG: 'Document Log',

    GOTOPARAGRAPH: 'Vá para o parágrafo',
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'Notas do documento',
    COLLABCOMMENTS: 'Colaboração - Comentários',
    COLLABDOCEDITS: 'Colaboração - Edições de documentos',
    COLLABRISKANDLABELS: 'Colaboração - Risco e rótulos',
    NEGOTCOMMENTS: 'Negociação - Comentários',
    NEGOTDOCEDITS: 'Negociação - Edições de documentos',

    COLLABORATION: 'Colaboração',
    NEGOTIATION: 'Negociação',
    ADDOWNER: 'Adicionar proprietário',
    ADDCOLLABORATOR: 'Adicionar colaborador',
    ADDNEGOTIATOR: 'Adicionar negociador',
    TRIGGERALERTS: 'Trigger Alerts',
    EXPORTEXCEL: 'Exportar Excel',
    DOWNLOADSOURCE: 'Fonte de download',
    SENDTOCLM: 'Enviar para CLM',
    EXPORTJSON: 'Export JSON',

    ESIGNATURE: 'Enviar para assinatura',
    DOWNLOADREDLINE: 'Baixar Redline DocX',
    DOCUMENTSETTINGS: 'Configurações do documento',
    EXPAND: 'Expandir',
    COLLAPSE: 'Collapse',
    TRANSLATETOENGLISH: 'Traduzir para o inglês',
    ORIGINALLANGUAGE: 'Reverter para o original',

    ALLPARAGRAPHS: 'Todos os parágrafos',
    EDITEDPARAGRAPHSONLY: 'Somente parágrafos editados',

    NOEDITCLAUSELABELS: 'Sem rótulos de cláusula de edição',
    EDITCLAUSELABELS: 'Editar rótulos da cláusula',

    HIDEEDITS: 'Ocultar edições',
    SHOWEDITS: 'Mostrar edições',

    SHOWALLEDITS: 'Mostrar todas as edições',
    SHOWNEGOTEDITSONLY: 'Mostrar apenas edições de negociação',

    GROUPS: 'Grupos',
    DOCUMENTTYPES: 'Tipos de documentos',
    ALERTS: 'Alertas',
    QUICKSTARTTUTORIAL: 'Tutorial de início rápido',
    QUICKSTARTVIDEOS: 'Vídeos de início rápido',
    UPGRADESUBSCRIPTION: 'Assinatura de atualização',
    SUBSCRIPTIONBILLING: 'Faturamento de assinatura',
    ACCOUNTSETTINGS: 'Configurações da conta',
    USERLOG: 'Log do usuário',
    CHANGEPASSWORD: 'Alterar senha',
    LOGOUT: 'Logout',

    // TÍTULOS
    ADMIN: 'ADMIN',
    PROFILE: 'PROFILE',
    LPROFILE: 'Perfil',
    FILE: 'FILE',
    EDIT: 'EDIT',
    ACT: 'ACT',
    TOPIC: 'TOPIC',
    DOCS: 'DOCS',

    // Títulos de campo na barra de navegação
    NAME: 'Nome',
    ROLE: 'Função',
    STATE: 'Estado',
    GROUP: 'Grupo',
    TYPE: 'Tipo',
    SENSIT: 'Sensibilidade',
    LTOPIC: 'Tópico',
    USER: 'Usuário',

    // Outros títulos
    REGISTER: 'REGISTER',
    LOGIN: 'LOGIN',
    LREGISTER: 'Registrar',
    LLOGIN: 'Login',

    ADMINEXPLAIN: 'Capacidades administrativas',
    PROFILEEXPLAIN: 'Gerenciamento de contas, assinaturas, faturamento',

    // NÍVEIS AUTORAIS
    PREMIUM: 'Premium',
    PREMIUMPREPAID: 'Premium (pré-pago)',
    BASIC: 'Básico',
    BASICPREPAID: 'Básico (pré-pago)',
    VIPACCESS: 'Acesso VIP',
    VIPMEMBER: 'Membro VIP',
    UNLIMITED: 'Ilimitado',
    TRIALUSER: 'Usuário de teste',
    REFERREDUSER: 'Usuário Referido',
    SOURCEDOCUMENT: 'Documento Fonte',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: 'Documento de Comparação',
    // ORIGINAL TEXT: Comparison Document
    EXIT: 'SAÍDA',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: 'Parágrafo',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: 'MODO DE COMPARAÇÃO DE DOCUMENTOS',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: 'NONE',
    PREAMBLE: 'PREAMBLE',
    DEFN: 'DEFINIÇÃO',
    PAYMENT: 'PAYMENT',
    TAXES: 'TAXES',
    REPWARR: 'REPRESENTAÇÃO E GARANTIAS',
    PERIOD: 'PERÍODO',
    TERMINATION: 'TERMINATION',
    CONFID: 'CONFIDENCIALIDADE',
    INDEMNITY: 'INDEMNITY',
    LIABILITY: 'RESPONSABILIDADE',
    INTELPROP: 'PROP INTELECTUAL',
    SIGNATURE: 'SIGNATURE',
    OTHER: 'OUTROS',
    DATASECURITY: 'SEGURANÇA DE DADOS',
    ASSIGN: 'ASSIGNMENT',
    NOTICE: 'NOTICE',
    GOVERNLAW: 'GOVERNING LAW',
    DISPUTE: 'DISPUTE',
    ENTIREAGREE: 'CONTRATO INTEGRAL',
    SURVIVAL: 'SURVIVAL',
    RELATPART: 'RELAÇÃO ENTRE AS PARTES',
    THIRDPARTY: 'TERCEIRO',
    FORCEMAJEURE: 'FORCE MAJEURE',
    SEVERABILITY: 'SEPARABILIDADE',
    EXPORTCTRL: 'EXPORT CONTROLS',
    INTRO: 'INTRODUÇÃO',
    INSURANCE: 'SEGURO',
    HEADING: 'HEADING',
    ALL: 'ALL',
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'E-mail obrigatório',
    EMAILVALID: 'O e-mail deve ser válido',
    MAX20CHARS: 'Máx. 20 caracteres',
    MAX60CHARS: 'Máx. 60 caracteres',
    MAX80CHARS: 'Máx. 80 caracteres',
    MAX160CHARS: 'Máx. 160 caracteres',
    EMAILALREADYOWNER: 'O e-mail já é proprietário',
    EMAILALREADYCOLLABORATOR: 'Email já é colaborador',
    EMAILALREADYNEGOTIATOR: 'Email já é negociador',
    EMAILALREADYOWNERDOCTYPE: 'O e-mail já é proprietário do tipo de documento',
    EMAILALREADYCOLLABORATORDOCTYPE: 'Email já é colaborador do tipo documento',
    REQUIRED: 'Obrigatório.',
    CLAUSEFIELDREQUIRED:
      'Este campo de cláusula é obrigatório para esta operação',
    CLAUSENOTDEFAULT:
      'Não é possível adicionar cláusula da lista de cláusulas padrão',
    TAGFIELDREQUIRED: 'Este campo de tag é obrigatório para esta operação',
    TAGNOTDEFAULT: 'Não é possível adicionar tags da lista de tags padrão',

    PARAINDEXREQUIRED: 'O índice do parágrafo é obrigatório',
    PARAINDEXINVALID: 'Índice de parágrafo não existe',
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'salvar documento',
    OPENDOCUMENT: 'abrir documento',
    CLOSEDOCUMENT: 'fechar documento',
    ADDNOTE: 'adicionar nota',
    ADDCOLLABORATOR: 'adicionar colaborador',
    DELETECOLLABORATOR: 'excluir colaborador',
    ADDNEGOTIATOR: 'adicionar negociador',
    DELETENEGOTIATOR: 'excluir negociador',
    UPDATEARCHIVEDATA: 'atualizar dados do arquivo',
    UPDATEDOCMETA: 'atualizar metadados do documento',
    ADDOWNER: 'adicionar proprietário',
    DELETEOWNER: 'excluir proprietário',
    CHANGECOLLABORATION: 'alterou as configurações de colaboração',
    CHANGENEGOTIATION: 'configurações de negociação alteradas',
    SAVEPLAYBOOK: 'salvo como manual',
    SAVETEMPLATE: 'salvo como modelo',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: 'salvo como arquivado',
    TRIGGERALERT: 'alerta de gatilho',
    TRIGGERALLALERT: 'acionar todos os alertas',
    TRIGGERCUSTOMALERT: 'acionar alerta personalizado',
    ADDCOMMENT: 'adicionar comentário',
    DELETECOMMENT: 'excluir comentário',
    ADDDOCEDIT: 'add docedit',
    ADDAPPROVEDDOCEDIT: 'adicionar edição de documento aprovado',
    APPROVEDDOCEDIT: 'aprovar edição do documento',
    REJECTDOCEDIT: 'rejeitar edição de documento',
    NEGOTIATORAPPROVEDDOCEDIT: 'negociador aprovado edição do documento',
    NEGOTIATORREJECTEDDOCEDIT: 'negociador rejeitou edição do documento',
    DELETEDDOCEDIT: 'excluir edição do documento',
    ATTACHCONTRACT: 'anexar contrato exec',
    ADDTAG: 'adicionar tag',
    DELETETAG: 'excluir tag',
    NEWSIGNATUREREQUEST: 'nova assinatura solicitada',
    CANCELSIGNATUREREQUEST: 'solicitação de assinatura cancelada',
    CREATEREDLINEREQUEST: 'criar novo documento com linha vermelha',
    CREATEFINALDOCREQUEST: 'criar novo documento final para assinatura',
    COPIEDDOCUMENT: 'copiado do documento',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: 'criou uma versão limpa do documento',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: 'documento criado a partir do modelo',
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'criar tipo de documento',
    DELETEDOCTYPE: 'excluir tipo de documento',
    ADDOWNERTODOCTYPE: 'adicionado proprietário ao tipo de documento',
    DELETEOWNERFROMDOCTYPE: 'proprietário excluído do tipo de documento',
    ADDCLAUSETODOCTYPE: 'adicionada cláusula adicional ao tipo de documento',
    DELETECLAUSEDOCTYPE: 'excluída a cláusula addl para o tipo de documento',
    ADDTAGDOCTYPE: 'adicionado tag addl ao tipo de documento',
    DELETETAGDOCTYPE: 'tag addl excluída do tipo de documento',
    ADDCOLLABRULEDOCTYPE:
      'regras de colaboração adicionadas ao tipo de documento',
    DELETECOLLABRULEDOCTYPE:
      'excluiu regras de colaboração do tipo de documento',
    ADDNOTIFRULEDOCTYPE:
      'regras de notificação adicionadas ao tipo de documento',
    DELETENOTIFRULEDOCTYPE:
      'regras de notificação excluídas do tipo de documento',
    ADDNEGOTRULEDOCTYPE:
      'regras de negociação adicionadas ao tipo de documento',
    DELETENEGOTRULEDOCTYPE: 'excluiu regras de negociação do tipo de documento',

    // GRUPOS
    CREATEGROUP: 'grupo criado',
    DELETEGROUP: 'grupo excluído',
    ADDOWNERGROUP: 'proprietário adicionado ao grupo',
    DELETEOWNERGROUP: 'proprietário excluído do grupo',
    ADDCOLLABGROUP: 'colaborador adicionado ao grupo',
    DELETECOLLABGROUP: 'excluiu colaborador do grupo',
    ADDDOCTYPETOGROUP: 'tipo de documento adicionado ao grupo',
    DELETEDOCTYPEGROUP: 'tipo de documento excluído do grupo',

    // ALERTAS
    CREATEALERT: 'alerta criado',
    DELETEALERT: 'alerta excluído',
    ADDOWNERALERT: 'proprietário adicionado ao alerta',
    DELETEOWNERALERT: 'proprietário excluído do alerta',
    ADDDOCTYPEALERT: 'adicionado doctype ao alerta',
    DELETEDOCTYPEALERT: 'deletado doctype do alerta',
    ADDCLAUSETOPICALERT: 'tópico de cláusula adicionado ao alerta',
    DELETECLAUSETOPICALERT: 'tópico de cláusula excluído do alerta',
    ADDRECIPIENTALERT: 'destinatário adicionado ao alerta',
    DELETERECIPIENTALERT: 'destinatário excluído do alerta',
    ADDNOTIFRULEALERT: 'adicionadas regras de notificação para alertar',
    DELETENOTIFRULEALERT: 'regras de notificação excluídas do alerta',
    SETSELECTIVITYALERT: 'definir seletividade para alertar',
  },

  USEREVENTS: {
    USERSIGNON: 'Usuário conectado',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT: 'solicitação de redefinição de senha enviada',
    CHANGEDPASSWORD: 'senha alterada',
    DELETEDREGISTRATION: 'registro de usuário excluído',
    UPDATEDACCOUNTINFO: 'informações atualizadas da conta',
    UPGRADEDACCOUNTTOREFERRAL: 'conta atualizada para conta referida',
    DELETEDUSERACCOUNT: 'conta de usuário excluída',
  },

  // ETIQUETAS PARA O PRODUTO
  DIALOGS: {
    // OUTRAS ETIQUETAS DE DIÁLOGO
    ACTIONSDIALOGUE: 'AÇÕES DOCUMENTOS',
    COMMENTS: 'COMENTÁRIOS',
    DOCEDITS: 'EDIÇÃO DE DOCUMENTOS',
    LABELSRISK: 'LABELS AND RISK',
    TEXT: 'TEXT',
    MLE_DESC: 'DOCGILITY',
    CLAUSE: 'Cláusula',

    // PRIVILÉGIO
    OWNER: 'proprietário',
    COLLABORATOR: 'colaborador',
    NEGOTIATOR: 'negociador',
    OWNERGROUP: 'proprietário (grupo)',
    COLLABORATORGROUP: 'colaborador (grupo)',

    // TÍTULOS
    SAVEDOCUMENT: 'SALVAR DOCUMENTO',
    SAVEARCHIVE: 'SAVE ARCHIVE',
    QUICKSTART: 'QUICK START TUTORIAL',
    TUTORIALVIDEOS: 'TUTORIAL VIDEOS',

    MOVETOARCHIVEDOCUMENT: 'MOVE TO ARCHIVE DOCUMENT',
    OPENDOCUMENT: 'ABRIR DOCUMENTO',
    COPYDOCUMENT: 'COPY DOCUMENT',
    CREATEVERSION: 'CRIAR VERSÃO LIMPA',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: 'CRIAR DOCUMENTO A PARTIR DO MODELO',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: 'ABRIR DOCUMENTO DO GRUPO',
    OPENHUBDOCUMENT: 'ABRIR HUB DOCUMENTO',
    OPENARCHIVEDOCUMENT: 'ABRIR DOCUMENTO DE ARQUIVO',
    OPENPLAYBOOK: 'ABRIR PLAYBOOK',
    OPENTEMPLATE: 'ABRIR O MODELO',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'GUARDAR COMO MODELO',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: 'COLABORAÇÃO',
    NEGOTIATION: 'NEGOCIAÇÃO',
    ADDOWNER: 'ADICIONE PROPRIETÁRIO',
    ADDNEGOTIATOR: 'ADICIONE NEGOCIADOR',
    ADDCOLLABORATOR: 'ADICIONE COLABORADOR',
    ERRORMESSAGE: 'ERROR MESSAGE',
    DOCUMENTINFO: 'INFORMAÇÕES DO DOCUMENTO',
    DOCUMENTLOGS: 'REGISTROS DO DOCUMENTO',
    USERLOGS: 'USERLOGS',
    DOCUMENTNOTES: 'NOTAS DE DOCUMENTOS',
    TRIGGERALERTS: 'TRIGGER ALERTS',
    ALERTS: 'ALERTS',
    DOCTYPES: 'TIPOS DE DOCUMENTOS',
    GROUPS: 'GROUPS',
    NOTE: 'NOTA',
    GOTOPARAGRAPH: 'GO TO PARAGRAPH',
    ESIGNATURE: 'E-SIGNATURE',
    DOWNLOADREDLINE: 'DOWNLOAD REDLINE DOCX',

    // AÇÕES
    ADD: 'ADICIONE',
    BACK: 'VOLTAR',
    DELETE: 'DELETE',
    SAVE: 'SAVE',
    MOVETOARCHIVE: 'MOVE TO ARCHIVE',
    COPY: 'COPY',
    CREATE: 'CRIAR',
    // ORIGINAL TEXT: CREATE
    OPEN: 'OPEN',
    CLOSE: 'CLOSE',
    UPDATE: 'UPDATE',
    REGISTER: 'REGISTER',
    LOGIN: 'LOGIN',
    LOGINSSO: 'LOGIN SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: 'RESET PASSWORD',
    CHANGEPASSWORD: 'ALTERAR SENHA',
    CANCELACCOUNT: 'CANCEL ACCOUNT',
    QUICKSTARTVIDEO: 'ASSISTIR O VÍDEO DE INÍCIO RÁPIDO',

    CHARGEBASIC: 'ATUALIZAR PARA O BÁSICO',
    CHARGEPREMIUM: 'ATUALIZAR PARA PREMIUM',
    CHARGECORPORATE: 'ATUALIZAR PARA CORPORATIVO',
    UPGRADESUBSCRIPTION: 'ATUALIZAR ASSINATURA',
    GO: 'GO',
    PREVIEW_FEEDBACK: 'REVER ALTERAÇÕES',
    ACTIONS: 'ACT',
    REFRESH: 'ATUALIZAR',
    ADDNEWGROUP: 'ADICIONE NOVO GRUPO',
    ADDNEWDOCTYPE: 'ADICIONE NOVO DOCTYPE',
    ADDNEWALERT: 'ADICIONE NOVO ALERTA',
    GROUPSLIST: 'REVER LISTA DE GRUPOS',

    DOWNLOADDOCUMENT: 'DOWNLOAD DOCUMENT',
    DOCUMENTTITLE: 'Título do documento',
    DOCUMENTSUBJECT: 'Assunto do Documento',
    SIGNMESSAGE: 'Mensagem para assinatura',
    NAMEPRIMARYSIGNER: 'Name: Signatário Principal',
    EMAILPRIMARYSIGNER: 'Email: Signatário Principal',
    NAMECOUNTERSIGNER: 'Name: Contra Signatário',
    EMAILCOUNTERSIGNER: 'Email: Counter Signer',
    SIGREQUESTED: 'ASSINATURA SOLICITADA',
    SIGREQUESTEDBY: 'SOLICITADO POR',
    SIGREQUESTEDID: 'SIGNATURE ID',
    SIGREQUESTEDAT: 'SOLICITADO À',
    SUBMITSIG: 'ENVIAR PARA ASSINATURA',
    CANCELSIG: 'CANCEL SIGNATURE REQUEST',

    // ETIQUETAS DE DADOS
    NAME: 'NAME',
    EMAIL: 'EMAIL',
    STATUS: 'STATUS',
    LASTVIEWED: 'LAST VIEWED',
    LASTREVIEWED: 'ÚLTIMA REVISÃO',
    SIGNEDAT: 'ASSINADO',
    OWNERAPPROVAL: 'OWNER APPROVAL',
    OWNERACTION: 'OWNER ACTION',
    NEGOTIATORAPPROVAL: 'NEGOTIATOR APPROVAL',
    NEGOTIATORACTION: 'NEGOTIATORACTION',
    ARCHIVENOTES: 'ARQUIVO NOTAS',
    EXECUTEDCONTRACT: 'EXECUTADO',
    EXECUTEDDATE: 'EXECUTEDDATE',
    CONTRACTATTACHED: 'CONTRATO ANEXO',
    STARTDATE: 'START DATE',
    ENDDATE: 'END DATE',
    EDIT: 'EDIT',
    USER: 'USER',
    TIME: 'TIME',
    CONFIRM: 'CONFIRM',
    CONFIRMACTION: 'CONFIRM ACTION',
    CURRENT_LABEL: 'CURRENT LABEL',
    NEW_LABEL: 'NEW LABEL',
    DESCRIPTION: 'DESCRIPTION',
    TYPE: 'TYPE',
    SENTENCE: 'SENTENCE',
    NUMBER: '#',
    PRIVILEGE: 'ROLE',
    OWNERID: 'OWNERID',
    DOCNAME: 'NOME DO DOCUMENTO',
    DOCTYPE: 'TIPO DE DOCUMENTO',
    DOCDESCRIPTION: 'DESCRIÇÃO DO DOCUMENTO',
    DOCNOTES: 'NOTAS DE DOCUMENTOS',
    DOCPARTY: 'FESTA',
    DOCCOUNTERPARTY: 'CONTRA-PARTY',
    DOCRAWFILENAME: 'NOME DO ARQUIVO DO DOCUMENTO',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'TAGS',
    COLLABORATIONSTATUS: 'COLLABORATION STATUS',
    COLLABORATIONEND: 'COLLABORATION END',
    NEGOTIATIONSTATUS: 'NEGOTIATION STATUS',
    NEGOTIATIONEND: 'NEGOTIATION END',
    DOCID: 'ID DO DOCUMENTO',
    PARAGRAPHID: 'PARAGRAPH ID',
    ACTION: 'AÇÃO REGISTRADA',
    DETAIL: 'DETALHE DE AÇÃO',
    CONTEXT: 'CONTEXTO ADICIONAL',
    GROUPNAME: 'NOME DO GRUPO',
    GROUPDESC: 'GRUPO DESCRIÇÃO',
    DOCTYPEDESC: 'DESCRIÇÃO DO TIPO DE DOCUMENTO',
    ADDLCLAUSETYPES: 'CUSTOM CLAUSE LABELS',
    ADDLTAGS: 'ETIQUETAS PERSONALIZADAS',
    COLLABORATIONRULES: 'REGRAS DE COLABORAÇÃO',
    NEGOTIATIONRULES: 'NEGOTIATIONRULES',
    NOTIFICATIONRULES: 'NOTIFICATION RULES',
    LOG: 'LOG',
    ALERTNAME: 'NOME DO ALERTA',
    ALERTDESC: 'DESCRIÇÃO DO ALERTA',
    TOPICS: 'TOPICS',
    RECIPIENTS: 'RECIPIENTS',
    SELECTIVITY: 'SELETIVIDADE',
    FIELDNAME: 'NOME',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: 'PEDIDO',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: 'EXEMPLO',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: 'PREDEFINIÇÃO',
    // ORIGINAL TEXT: DEFAULT
    VALUE: 'VALOR',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: 'DOCUMENTO',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: 'PLAYBOOK',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: 'MODELO',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: 'DOCUMENTO ARQUIVADO',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: 'ORIGINADO DO MODELO:',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: 'COLABORAÇÃO',
    NEGSHORT: 'NEGOCIAÇÃO',
    OWNERS: 'OWNERS',
    COLLABORATORS: 'COLLABORATORS',
    NEGOTIATORS: 'NEGOCIADORES',
    // ADIÇÃO
    DOCUMENTDETAILS: 'DOCUMENT DETAILS',
    CREATIONDATE: 'CREATION DATE',
    PARTICIPANTS: 'PARTICIPANTS',
    COLLABORATIONANDNEGOTIATION: 'COLABORAÇÃO E NEGOCIAÇÃO',
    DOCTYPEANDGROUPACCESS: 'TIPO DE DOCUMENTO E ACESSO DE GRUPO',
    GROUPOWNERS: 'PROPRIETÁRIOS DO GRUPO',
    GROUPCOLLABORATORS: 'GROUP COLLABORATORS',
    DOCUMENTSETTINGS: 'CONFIGURAÇÕES DE DOCUMENTOS',
    ENABLECOLLABTOMODLABELS:
      'Permitir que os colaboradores modifiquem os rótulos das cláusulas',
    ENABLENEGOTCAPABILITIES:
      'Ativar recursos de negociação (modo não de negociação)',
    KEEPDOCNOTES: 'Copiar notas do documento com operação de cópia',
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS:
      'Tópicos de cláusulas revisadas e alteradas conforme necessário',
    NOEXECUTIONDATE: 'Sem data de execução',
    EXECUTIONDATE: 'Data de Execução',
    CONTRACTSTARTDATE: 'Data de início do contrato',
    CONTRACTENDDATE: 'Data de término do contrato',
    EXECCONTRACTNAME: 'Nome do arquivo de contrato executado',
    SELECTEXECCONTRACT:
      'selecione o arquivo para carregar o contrato executado',
    UPLOADEXECUTEDCONTRACT: 'CARREGAR CONTRATO EXECUTADO',
    LOADDEFAULTS: 'LOAD DEFAULTS',
    CLOSED: 'FECHADO',
    NONE: 'NONE',
    NA: 'NA',
    RESULTEXECUTEDCONTRACT: 'Resultou em Contrato Executado',
    NOSTARTDATE: 'Sem data de início',
    NOENDDATE: 'Sem data de término',
    EXCLUDECOLLABORATORSFROMACCESS:
      'Excluir Colaboradores Externos do Acesso ao Documento',
    EXCLUDENEGOTIATORSFROMACCESS:
      'Excluir Negociadores Externos do Acesso ao Documento',
    REVIEWEDAMENDEDCLAUSELABELS:
      'Rótulos de cláusulas revisadas e alteradas conforme necessário',
    ENTERPARAGRAPHBETWEEN: 'Digite um número de parágrafo entre :',
    GOTOPARAGRAPHINDEX: 'Vá para o índice do parágrafo',
    INPUTDOCUMENTNOTEHERE: 'Inserir nota do documento aqui',
    INPUTCOMMENTHERE: 'Insira o comentário aqui',
    SHOWPENDINGONLY: 'Mostrar apenas pendentes',
    COLLABORATIONSETTING: 'COLLABORATIONSETTING',
    NEGOTIATIONSETTING: 'NEGOTIATIONSETTING',
    NONEGOTWHENAPPROVED:
      'Não é possível ativar a negociação quando estiver no estado aprovado',
    EMAILTOADD: 'E-mail para adicionar',
    CLAUSETOADD: 'Cláusula a adicionar',
    TAGTOADD: 'Tag para adicionar',
    OPTIONSFORREDLINEINCLUDE: 'Opções de Redlines a Incluir',
    ONLYINCLUDEOWNERAPPROVEDEDITS:
      'Incluir apenas edições de documentos aprovadas pelo proprietário',
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'Incluir apenas edições de documentos aprovadas pelo proprietário e negociador',
    INCLUDEALLDOCEDITS:
      'Incluir todas as edições do documento (independentemente da aprovação)',
    REDLINECHANGESSELECTED: 'Alterações Redlinadas Selecionadas',
    TOTALCHANGES: 'Alterações totais',
    REPLACEREDLINES: 'Replace Redlines',
    DELETEREDLINES: 'Excluir linhas vermelhas',
    ADDAFTERREDLINES: 'Add-After Redlines',
    OPTIONSFORPREVREDLINES: 'Opções para linhas de marcação anteriores',
    OVERWRITEPREVREDLINES:
      'Substituir todas as linhas de marcação anteriores na origem do documento',
    KEEPPREVREDLINES:
      'Manter todas as linhas de marcação anteriores na origem do documento',
    GENERATEFINALDOC: 'Gerar Documento Final (sem Redlines)',
    DEFAULTESIGNOTE:
      'Conforme discutido, forneça sua assinatura eletrônica para este contrato.',
    SUBMITTEDSIGMESSAGE: 'Enviado para assinaturas. Por favor, espere. ',
    UPLOADEDMESSAGE: 'Carregado',
    ADDADDLFILES:
      'Opcional - Adicionar arquivos adicionais ao pedido de assinatura',
    ADDFILESMSG: 'adicionar arquivos adicionais para assinatura',
    ESIGPREPAIDPLANS:
      'O recurso de assinatura eletrônica está disponível apenas em nossos planos pré-pagos.',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      'Entre em contato conosco em billing@mylegaleinstein.com para obter mais detalhes.',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: 'Mais vídeos no ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MENSAGENS (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'O tipo de documento não é suportado. O MLE aceita apenas os formatos docx e pdf. ',
    PDFSUPPORTEDFORPREMIUMONLY:
      'Para digitalização de PDF, o usuário precisa atualizar para a assinatura Premium ou melhor.',
    BADDOCFORMAT:
      'O formato do documento foi passado para o processamento do documento incorreto.',
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      'Você não tem o privilégio de fazer alterações nas configurações do documento. Você deve ser um proprietário. ',
    PRIVILEGSNOTAVAIL:
      'Os privilégios para este usuário não estão disponíveis. Tente mais tarde.',
    EXHAUSTEDTRIALLICENSE:
      'Você esgotou o número de documentos permitidos durante o seu período de experiência. Selecione "Atualizar Assinatura" em seu perfil. ',
    EXHAUSTEDMONTHLYLIMIT:
      'Você esgotou o número de documentos mensais permitidos durante o seu período de teste. Selecione "Atualizar Assinatura" em seu perfil. ',
    PASSWORDNOTMATCHING:
      'As senhas não conferem. Verifique as senhas novamente. ',
    ABAMEMBERIDLENGTH:
      'Os IDs de membros da ABA têm 7 caracteres. Reveja e envie. ',
    TERMSNOTCHECKED:
      'Os Termos e Condições não foram revisados ​​e verificados.',
    EMAILFORMATBAD:
      'O formato do email não é válido. Por favor, revise o endereço de e-mail. ',
    CANCELEDACTION:
      'Sua última ação foi cancelada. O aplicativo agora será recarregado. ',
    SUCCESSACTION:
      'Sua última ação foi bem-sucedida. O aplicativo agora será recarregado. ',
    USERSUCCESSLOGIN: 'Conectado com sucesso como',
    USERLOGGEDOUT: 'O usuário foi desconectado',
    EMAILNOTVERIFIED:
      'O e-mail da conta ainda não foi verificado. Uma verificação de e-mail foi enviada para você. ',
    EMAILALREADYREGISTERED:
      'Este e-mail já possui uma conta registrada anteriormente.',
    EMAILNOTVERIFIEDRESENT:
      'O seu email não foi verificado. Reenviamos um link de verificação de e-mail para o seu e-mail para ativar a nova conta. ',
    USERLOGFILENOTEXISTS:
      'Erro - nenhum arquivo de log de usuário existe para:',
    PASSWORDRESETSENT:
      'Uma mensagem de redefinição de senha de e-mail foi enviada para sua conta.',
    PASSWORDCHANGED: 'Sua senha foi alterada.',
    REGISTRATIONDELETED: 'registro de usuário excluído',
    USERACCOUNTUPDATED:
      'Você atualizou as configurações da sua conta de usuário. As alterações entrarão em vigor na próxima vez que você fizer login. ',
    UPGRADEDTOREFERRED:
      'Sua conta foi atualizada para a assinatura de teste recomendado.',
    REGISTERTRIALUSER: 'Nova conta registrada como assinatura de teste.',
    DELETEDUSERACCOUNT: 'Conta de perfil de usuário excluída',
    EMAILALREADYREGISTEREDRESENT:
      'O e-mail ainda não foi registrado no Docgility. Um convite por e-mail foi enviado para ',
    UPGRADEEXTERNALPAYMENTS:
      'Obrigado por atualizar. Você será redirecionado para um serviço de pagamento externo e, após concluir a transação, precisará fazer o login novamente para que o serviço de assinatura seja iniciado. ',
    BILLINGEXTERNALPAYMENTS:
      'Por favor, confirme que você gostaria de ser direcionado para nosso serviço externo de pagamento e cobrança. Depois de fazer qualquer alteração, você precisará fazer o login novamente para que as alterações tenham efeito. ',
    NOTABLETOLOGINATTHISTIME:
      'Não é possível fazer o login no momento. Tente mais tarde.',
    NOTREGISTERED:
      'O usuário não se cadastrou anteriormente. Registre-se antes de tentar fazer o login. ',
    NAMETOOLONG:
      'O nome que você digitou é muito longo. O máximo é 60 caracteres. ',
    COMPANYTOOLONG:
      'A empresa que você inseriu é muito longa. O máximo é 60 caracteres. ',
    COMMENTTOOLONG:
      'Os comentários que você inseriu são muito longos. O máximo é 10.000 caracteres. ',
    DOCEDITTOOLONG:
      'As edições do documento que você inseriu são muito longas. O máximo é 10.000 caracteres. ',
    TITLETOOLONG:
      'O título que você inseriu é muito longo. O máximo é 60 caracteres. ',
    PASSWORDTOOSHORT:
      'A senha que você digitou é muito curta. A senha deve ter pelo menos 6 caracteres. ',
    CONGRATSNEWDOCUMENTPROCESSING:
      'Ótimo! Você processou um novo documento. Demora alguns minutos e o painel deve ser atualizado quando o processamento do documento for concluído. Aguarde alguns minutos. ',
    NODOCUMENTLOADED: 'Nenhum documento carregado',
    SELECTFILETOUPLOADDOCX: 'selecione o arquivo para enviar (* .docx)',
    SELECTFILETOUPLOADDOCXPDF:
      'selecione o arquivo para upload (* .docx, * .pdf)',
    INPUTCOMMENTHERE: 'Inserir comentário aqui',
    AUTOAPPROVEASOWNER: 'Aprovar automaticamente como proprietário',
    INPUTDOCEDITSHERE: 'Documento de entrada edite aqui',
    TRIGGEREDALLALERTS:
      'Acionou todos os alertas (se houver) para este documento doctype.',
    QSINTROMESSAGE:
      'MyLegalEinstein analisa instantaneamente seus contratos, índices por tópicos jurídicos e permite comparações lado a lado da linguagem jurídica em uma plataforma de colaboração de contrato completa.',
    QSDOCMESSAGE:
      'Para sua conveniência, aqui estão alguns contratos de domínio público que você pode baixar e experimentar:',
    QSHELPMESSAGE:
      'Aqui está um link útil para a documentação do produto (com a versão prolixo do Quick Start)',
    DOCUMENTFAILEDTOPROCESS:
      'Ocorreu um erro fatal durante o processamento do documento. Por favor, tente novamente. Se o problema persistir, entre em contato com o suporte ao cliente em support@mylegaleinstein.com. ',
    // ADDED
    DOCUMENTSUCCESSCOPIED: 'Seu documento foi copiado.',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      'Uma versão limpa deste documento agora foi gerada e salva.',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      'Um documento foi gerado a partir deste modelo e salvo.',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE:
      'Um documento foi convertido em um modelo de documento.',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      'Por favor, confirme que você deseja excluir o documento especificado. Depois que o documento for excluído, ele não estará mais disponível. Você também pode considerar mover o documento para o status Arquivo.  n  n Por favor, confirme a operação de exclusão. ',
    CONFIRMSAVETOPLAYBOOK:
      'Por favor, confirme isso para converter este documento em um manual. Isso tornaria este documento imutável, exceto para o proprietário.  n  n Confirme a operação de salvar no manual. ',
    COLLABORATIONNOTE:
      'NOTE: Estas informações são compartilhadas SOMENTE INTERNAMENTE com colaboradores designados e proprietários de documentos apenas.',
    NEGOTIATIONNOTE:
      'NOTE: Esta informação é compartilhada EXTERNAMENTE com as contrapartes da negociação.',
    CONFIRMDELETEALERT:
      'Por favor, confirme que você deseja excluir o alerta especificado. Depois que o alerta for excluído, ele não estará mais disponível. Confirme a operação de exclusão. ',
    CONFIRMDELETERECIPIENTALERT:
      'Por favor, confirme que você não deseja mais ser um destinatário para o alerta especificado. Depois de remover a si mesmo como destinatário, você pode solicitar para ser adicionado novamente entrando em contato com o proprietário do alerta. Confirme a operação de exclusão. ',
    CONFIRMDELETEGROUP:
      'Por favor, confirme que você deseja excluir o grupo especificado. Depois que o grupo for excluído, ele não estará mais disponível. No entanto, isso será refletido na próxima vez que você fizer login. Por favor, confirme a operação de exclusão. ',
    CONFIRMDELETECOLLABORATORGROUP:
      'Por favor, confirme que você não deseja mais ser um colaborador para o grupo especificado. Depois de remover a si mesmo como colaborador, você pode solicitar para ser adicionado novamente entrando em contato com o proprietário do grupo. Confirme a operação de exclusão. ',
    CONFIRMDELETEDOCTYPE:
      'Por favor, confirme que você deseja excluir o doctype especificado. Depois que doctype for excluído, ele não estará mais disponível. Confirme a operação de exclusão. ',
    EXPIREDTRIAL:
      'Sua licença de teste expirou. Atualize para continuar seu serviço. ',
    EXPIREDSUBSCRIPTION:
      'Sua licença de assinatura expirou. Faça upgrade para continuar seu serviço ou entre em contato com o faturamento em Docgility. ',
    SOURCEDOCNOTDOCX:
      'O documento fonte não é um documento DOCX. Os recursos do Redline não estão disponíveis. ',
  },
}

export default PT
