// import Login from '@src/router/views/Login.vue'
// import Register from '@src/router/views/Register.vue'
import success from '@src/router/views/success.vue'
import cancel from '@src/router/views/cancel.vue'

import panel from '@src/router/views/panel-processing.vue'

export default [
  {
    path: '/register',
    name: 'Register',
    component: () => lazyLoadView(import('@views/Register.vue')),
  },
  {
    path: '/ABAregister',
    name: 'RegisterABA',
    component: () => lazyLoadView(import('@views/RegisterABA.vue')),
  },
  {
    path: '/SSOLogin',
    name: 'SSOLogin',
    component: () => lazyLoadView(import('@views/SSOLogin.vue')),
  },

  // success after subscription completion
  {
    path: '/success',
    name: 'Success',
    component: success,
  },
  // no success after stripe subscription request
  {
    path: '/cancel',
    name: 'Cancel',
    component: cancel,
  },
  // Added for single sing on passing
  {
    path: '/sso/:userid/:keycode',
    name: 'sso',
    component: panel,
    props: true,
  },
  {
    path: '/',
    name: 'panel-processing',
    component: () => lazyLoadView(import('@views/panel-processing.vue')),
    meta: {
      // authentication via firebase
      requiresAuth: false,
      async beforeResolve(routeTo, routeFrom, next) {
        next()
      },
    },
  },

  {
    path: '/404',
    name: '404',
    component: require('@views/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout.vue').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
