<script>
import {
  panelComputed,
  dbauthComputed,
  dbcommentComputed,
} from '@state/helpers'

import {
  formatDateDialog,
  displayUserName,
  displayUserInfo,
  statusIndicator,
  docroute,
} from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'
import SnackService from '@/src/services/SnackService'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    dialogtype: {
      type: String,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    originaltext: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    comment: '',
    showDeleted: false,

    // dialogtype: 'collaboration',
  }),
  computed: {
    ...panelComputed,
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
        this.comment = ''
      },
    },

    cardtitle() {
      return this.dialogtype + ' : ' + this.$t('DIALOGS.COMMENTS')
    },

    allcomments() {
      if (
        Array.isArray(this.editComments[this.panelno]) &&
        this.editComments[this.panelno].length > 0
      ) {
        if (this.showDeleted) {
          return this.editComments[this.panelno].filter(
            (e) => e.type === this.dialogtype
          )
        } else {
          return this.editComments[this.panelno]
            .filter((e) => e.type === this.dialogtype)
            .filter((e) => e.active === true)
        }
      } else {
        return []
      }
    },

    sortedcomments() {
      let temparray = this.allcomments
      let returnArray = temparray.sort(function(a, b) {
        if (parseInt(a.paraid) > parseInt(b.paraid)) {
          return 1
        } else if (parseInt(a.paraid) < parseInt(b.paraid)) {
          return -1
        } else if (parseInt(a.paraid) === parseInt(b.paraid)) {
          return a.create > b.create ? 1 : -1
        } else {
          return 0
        }
      })
      return returnArray
    },
  },
  methods: {
    ...dbcommentComputed,
    addComment(index) {
      if (this.comment.length > 10000) {
        SnackService.info('MESSAGES.COMMENTTOOLONG')
        return
      }
      this.writeComment({
        docid: this.aPIDjobID[this.panelno],
        paraid: index,
        comment: this.comment,
        uniqid: this.dbauthuser.uniqid,
        type: this.dialogtype,
        active: true,
        ifHUBType: this.localpanelstate.ifHUBType,
      })
      this.comment = ''
      return true
    },
    // note - cannot change deleteCommentAct to deleteComment as that conflicts with functions.
    deleteCommentAct(comment) {
      this.deleteComment({
        docid: this.aPIDjobID[this.panelno],
        uniqid: this.dbauthuser.uniqid,
        commentid: comment.keyid,
        ifHUBType: this.localpanelstate.ifHUBType,
      })
      return true
    },

    setparagraphhighlight(index) {
      this.$emit('processnavall', index)
      this.$emit('close-dialog')
      // this unsyncs panel, sets topic to ALL, sets paragraph highlight
    },

    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    statusIndicator(bool) {
      return statusIndicator(bool)
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },
    docroute(d) {
      return docroute(d)
    },
    deleteok(uniqid) {
      // return true
      return this.dbauthuser.uniqid === uniqid
    },
  },
}
</script>

<template>
  <v-row>
    <v-dialog
      v-model="showDialog"
      persistent
      :content-class="$style.dialogWidth"
    >
      <v-card>
        <PanelCardTitle :cardtitle="cardtitle"></PanelCardTitle>
        <div>
          <v-form>
            <v-container>
              <v-row>
                <v-simple-table
                  class="sticky-header"
                  :class="$style.dialogTable"
                  fixed-header
                >
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                    >
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.USER') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogRowTextWidth"
                          >{{ $t('DIALOGS.COMMENTS') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.PARAGRAPHID') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.TIME') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellDelete"
                          >{{ $t('DIALOGS.ADD') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.STATUS') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in sortedcomments"
                        :key="item.keyid"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellWidth"
                          ><v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">{{
                                displayUserName(item.uniqid)
                              }}</span>
                            </template>
                            <span>{{ displayUserInfo(item.uniqid) }}</span>
                          </v-tooltip></td
                        >
                        <td :class="$style.dialogRowTextWidth">{{
                          item.comment
                        }}</td>
                        <td :class="$style.dialogCellWidth"
                          ><v-btn
                            x-small
                            text
                            outlined
                            class="py-5"
                            @click="setparagraphhighlight(item.paraid)"
                            >{{ item.paraid }}</v-btn
                          ></td
                        >

                        <td :class="$style.dialogCellWidth">{{
                          formatDateDialog(item.create)
                        }}</td>
                        <td :class="$style.dialogCellDelete">
                          <v-btn
                            v-if="!localpanelstate.ifArchivedState"
                            small
                            class="white--text black"
                            @click="addComment(item.paraid)"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="
                              deleteok(item.uniqid) &&
                                !localpanelstate.ifArchivedState
                            "
                            small
                            class="white--text black"
                            @click="deleteCommentAct(item)"
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <v-icon
                            large
                            color="statusIndicator(item.active).color"
                            >{{ statusIndicator(item.active).icon }}</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <div
          v-if="!localpanelstate.ifArchivedState && sortedcomments.length > 0"
        >
          <v-container align-center>
            <v-flex xs12 md12 lg12 xl12>
              <v-textarea
                v-model="comment"
                :placeholder="$t('DIALOGS.INPUTCOMMENTHERE')"
                outlined
                clearable
                solo
                :class="$style.dialogInputText"
                label="Comment"
              >
                ></v-textarea
              >
            </v-flex>
          </v-container>
        </div>
        <v-card-actions class="justify-end">
          <!-- <v-btn icon @click="docroute('edit/collabcomments')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn> -->

          <div v-if="dialogtype === 'COLLABORATION'" class="mx-5">
            <v-btn icon @click="docroute('edit/collabcomments')">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
            {{ $t('INFO_MESSAGES.COLLABORATIONNOTE') }}
          </div>
          <div v-if="dialogtype === 'NEGOTIATION'" class="mx-5">
            <v-btn icon @click="docroute('edit/negotcomments')">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
            {{ $t('INFO_MESSAGES.NEGOTIATIONNOTE') }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
