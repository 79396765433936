// Contains all application constants //

export const DE = {
  TOPMENU: {
    FILE: 'DATE',
    EDIT: 'BEAR',
    ACT: 'AKT',
    DOCS: 'DOCS',
  },

  MENU: {
    NEWDOCUMENT: 'Neues Dokument',
    // ORIGINAL TEXT: New Document
    SAVEDOCUMENT: 'Dokument speichern',
    // ORIGINAL TEXT: Save Document
    COPYDOCUMENT: 'Dokument kopieren',
    // ORIGINAL TEXT: Copy Document
    CREATEVERSION: 'Saubere Version erstellen',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: 'Dokument aus Vorlage erstellen',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: 'Dokument öffnen',
    // ORIGINAL TEXT: Open Document
    OPENGROUPDOCUMENT: 'Gruppendokument öffnen',
    // ORIGINAL TEXT: Open Group Document
    OPENHUBDOCUMENT: 'Dokument HUB öffnen',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: 'Archivdokument öffnen',
    // ORIGINAL TEXT: Open Archive Document
    OPENPLAYBOOK: 'Playbook öffnen',
    // ORIGINAL TEXT: Open Playbook
    OPENTEMPLATE: 'Vorlage öffnen',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: 'Als Vorlage speichern',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: 'Als Playbook speichern',
    // ORIGINAL TEXT: Save As Playbook
    MOVETOARCHIVE: 'Zum Archiv wechseln',
    // ORIGINAL TEXT: Move to Archive
    CLOSEDOCUMENT: 'Dokument schließen',
    // ORIGINAL TEXT: Close Document
    DOCUMENTLOG: 'Dokumentprotokoll',
    // ORIGINAL TEXT: Document Log

    GOTOPARAGRAPH: 'Gehe zu Absatz',
    // ORIGINAL TEXT: Go to Paragraph
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'Anmerkungen zum Dokument',
    // ORIGINAL TEXT: Document Notes
    COLLABCOMMENTS: 'Zusammenarbeit - Kommentare',
    // ORIGINAL TEXT: Collaboration - Comments
    COLLABDOCEDITS: 'Zusammenarbeit - Dokumentenbearbeitungen',
    // ORIGINAL TEXT: Collaboration - Document Edits
    COLLABRISKANDLABELS: 'Zusammenarbeit - Risiken und Labels',
    // ORIGINAL TEXT: Collaboration - Risk and Labels
    NEGOTCOMMENTS: 'Verhandlungen - Kommentare',
    // ORIGINAL TEXT: Negotiation - Comments
    NEGOTDOCEDITS: 'Verhandlungen - Dokumentenbearbeitungen',
    // ORIGINAL TEXT: Negotiation - Document Edits

    COLLABORATION: 'Zusammenarbeit',
    // ORIGINAL TEXT: Collaboration
    NEGOTIATION: 'Verhandlungen',
    // ORIGINAL TEXT: Negotiation
    ADDOWNER: 'Eigentümer hinzufügen',
    // ORIGINAL TEXT: Add Owner
    ADDCOLLABORATOR: 'Kollaborator hinzufügen',
    // ORIGINAL TEXT: Add Collaborator
    ADDNEGOTIATOR: 'Verhandlungsführer hinzufügen',
    // ORIGINAL TEXT: Add Negotiator
    TRIGGERALERTS: 'Warnungen auslösen',
    // ORIGINAL TEXT: Trigger Alerts
    EXPORTEXCEL: 'Excel exportieren',
    // ORIGINAL TEXT: Export Excel
    DOWNLOADSOURCE: 'Quelle herunterladen',
    // ORIGINAL TEXT: Download Source
    SENDTOCLM: 'An CLM senden',
    // ORIGINAL TEXT: Send to CLM
    EXPORTJSON: 'JSON exportieren',
    // ORIGINAL TEXT: Export JSON

    ESIGNATURE: 'Zur E-Unterschrift senden',
    // ORIGINAL TEXT: 'Send for Esignature', added
    DOWNLOADREDLINE: 'Redline DocX herunterladen',
    // ORIGINAL TEXT: Download Redline DocX,  added

    DOCUMENTSETTINGS: 'Dokumenteinstellungen',
    // ORIGINAL TEXT: Document Settings
    EXPAND: 'Erweitern',
    // ORIGINAL TEXT: Expand
    COLLAPSE: 'Zusammenbruch',
    // ORIGINAL TEXT: Collapse

    TRANSLATETOENGLISH: 'Ins Englische übersetzen',
    // ORIGINAL TEXT: Translate to English
    ORIGINALLANGUAGE: 'Zurück ins Original',
    // ORIGINAL TEXT: Revert to Original

    ALLPARAGRAPHS: 'Alle Absätze',
    // ORIGINAL TEXT: All Paragraphs
    EDITEDPARAGRAPHSONLY: 'Nur Absätze bearbeiten',
    // ORIGINAL TEXT: Edited Paragraphs Only

    NOEDITCLAUSELABELS: 'Keine Klauseln-Etiketten bearbeiten',
    // ORIGINAL TEXT: No Edit Clause Labels
    EDITCLAUSELABELS: 'Abschnitts-Etiketten bearbeiten',
    // ORIGINAL TEXT: Edit Clause Labels

    HIDEEDITS: 'Bearbeiten ausblenden',
    // ORIGINAL TEXT: Hide Edits
    SHOWEDITS: 'Bearbeiten anzeigen',
    // ORIGINAL TEXT: Show Edits

    SHOWALLEDITS: 'Alle Bearbeitungen anzeigen',
    // ORIGINAL TEXT: Show All Edits
    SHOWNEGOTEDITSONLY: 'Nur Verhandlungsbearbeitung anzeigen',
    // ORIGINAL TEXT: Show Negotiation Edits Only

    GROUPS: 'Gruppen',
    // ORIGINAL TEXT: Groups
    DOCUMENTTYPES: 'Dokumenttypen',
    // ORIGINAL TEXT: Document Types
    ALERTS: 'Ausschreibungen',
    // ORIGINAL TEXT: Alerts
    QUICKSTARTTUTORIAL: 'Schnellstart Tutorial',
    // ORIGINAL TEXT: Quick Start Tutorial
    QUICKSTARTVIDEOS: 'Schnellstart-Videos',
    // ORIGINAL TEXT: Quick Start Videos
    UPGRADESUBSCRIPTION: 'Abonnement aktualisieren',
    // ORIGINAL TEXT: Upgrade Subscription
    SUBSCRIPTIONBILLING: 'Zeichnungsabrechnung',
    // ORIGINAL TEXT: Subscription Billing
    ACCOUNTSETTINGS: 'Kontoeinstellungen',
    // ORIGINAL TEXT: Account Settings
    USERLOG: 'Benutzerprotokoll',
    // ORIGINAL TEXT: User Log
    CHANGEPASSWORD: 'Passwort ändern',
    // ORIGINAL TEXT: Change Password
    LOGOUT: 'Abmelden',
    // ORIGINAL TEXT: Logout

    // HEADINGS
    ADMIN: 'ADMIN',
    // ORIGINAL TEXT: ADMIN
    PROFILE: 'PROFIL',
    // ORIGINAL TEXT: PROFILE
    LPROFILE: 'Profil',
    // ORIGINAL TEXT: Profile
    FILE: 'DATEI',
    // ORIGINAL TEXT: FILE
    EDIT: 'BEARBEITEN',
    // ORIGINAL TEXT: EDIT
    ACT: 'RECHTSAKTE',
    // ORIGINAL TEXT: ACT
    TOPIC: 'THEMA',
    // ORIGINAL TEXT: TOPIC
    DOCS: 'DOKUMENTE',
    // ORIGINAL TEXT: DOCS

    // Field Headings in Navigation Bar
    NAME: 'Name',
    // ORIGINAL TEXT: Name
    ROLE: 'Rolle',
    // ORIGINAL TEXT: Role
    STATE: 'Mitgliedstaat',
    // ORIGINAL TEXT: State
    GROUP: 'Gruppe',
    // ORIGINAL TEXT: Group
    TYPE: 'Art',
    // ORIGINAL TEXT: Type
    SENSIT: 'Sensitivität',
    // ORIGINAL TEXT: Sensitivity
    LTOPIC: 'Thema',
    // ORIGINAL TEXT: Topic
    USER: 'Benutzer',
    // ORIGINAL TEXT: User

    // Other Headings
    REGISTER: 'REGISTRIEREN',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'LOGIN',
    // ORIGINAL TEXT: LOGIN
    LREGISTER: 'Register',
    // ORIGINAL TEXT: Register
    LLOGIN: 'Anmeldung',
    // ORIGINAL TEXT: Login

    ADMINEXPLAIN: 'Verwaltungskapazitäten',
    // ORIGINAL TEXT: Administrative capabilities
    PROFILEEXPLAIN: 'Kontoverwaltung, Abonnements, Rechnungswesen',
    // ORIGINAL TEXT: Account Management, Subscriptions, Billing

    // AUTH LEVELS
    PREMIUM: 'Prämie',
    // ORIGINAL TEXT: Premium
    PREMIUMPREPAID: 'Prämie (vorbezahlt)',
    // ORIGINAL TEXT: Premium (Prepaid)
    BASIC: 'Allgemeines',
    // ORIGINAL TEXT: Basic
    BASICPREPAID: 'Grund (vorbezahlt)',
    // ORIGINAL TEXT: Basic (Prepaid)
    VIPACCESS: 'VIP-Zugang',
    // ORIGINAL TEXT: VIP Access
    VIPMEMBER: 'VIP-Mitglied',
    // ORIGINAL TEXT: VIP Member
    UNLIMITED: 'Unbegrenzt',
    // ORIGINAL TEXT: Unlimited
    TRIALUSER: 'Testbenutzer',
    // ORIGINAL TEXT: Trial User
    REFERREDUSER: 'Bezogener Benutzer',
    // ORIGINAL TEXT: Referred User
    SOURCEDOCUMENT: 'Quelldokument',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: 'Vergleichsdokument',
    // ORIGINAL TEXT: Comparison Document
    EXIT: 'AUSFAHRT',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: 'ABSATZ',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: 'DOKUMENTENVERGLEICHSMODUS',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: 'NICHT',
    // ORIGINAL TEXT: NONE
    PREAMBLE: 'PRÄAMBEL',
    // ORIGINAL TEXT: PREAMBLE
    DEFN: 'BEGRIFFSDEFINITIONEN',
    // ORIGINAL TEXT: DEFINITION
    PAYMENT: 'ZAHLUNG',
    // ORIGINAL TEXT: PAYMENT
    TAXES: 'STEUERN',
    // ORIGINAL TEXT: TAXES
    REPWARR: 'VERTRETUNG UND GARANTIEN',
    // ORIGINAL TEXT: REPRESENTATION AND WARRANTIES
    PERIOD: 'DAUER',
    // ORIGINAL TEXT: PERIOD
    TERMINATION: 'KÜNDIGUNG',
    // ORIGINAL TEXT: TERMINATION
    CONFID: 'GEHEIMHALTUNG',
    // ORIGINAL TEXT: CONFIDENTIALITY
    INDEMNITY: 'SCHADENERSATZ',
    // ORIGINAL TEXT: INDEMNITY
    LIABILITY: 'HAFTUNG',
    // ORIGINAL TEXT: LIABILITY
    INTELPROP: 'GEISTIGES EIGENTUM',
    // ORIGINAL TEXT: INTELLECTUAL PROP
    SIGNATURE: 'UNTERSCHRIFT',
    // ORIGINAL TEXT: SIGNATURE
    OTHER: 'SONSTIGES',
    // ORIGINAL TEXT: OTHER
    DATASECURITY: 'DATENSCHUTZ',
    // ORIGINAL TEXT: DATA SECURITY
    ASSIGN: 'VOLLMACHTEN',
    // ORIGINAL TEXT: ASSIGNMENT
    NOTICE: 'FRISTEN',
    // ORIGINAL TEXT: NOTICE
    GOVERNLAW: 'GERICHTSSTAND',
    // ORIGINAL TEXT: GOVERNING LAW
    DISPUTE: 'RECHTSSTREIT',
    // ORIGINAL TEXT: DISPUTE
    ENTIREAGREE: 'VOLLSTÄNDIGE VEREINBARUNG',
    // ORIGINAL TEXT: ENTIRE AGREEMENT
    SURVIVAL: 'FORTBESTAND',
    // ORIGINAL TEXT: SURVIVAL
    RELATPART: 'BEZIEHUNGEN ZWISCHEN PARTEIEN',
    // ORIGINAL TEXT: RELATION BETWEEN PARTIES
    THIRDPARTY: 'DRITTE PERSONEN',
    // ORIGINAL TEXT: THIRD PARTY
    FORCEMAJEURE: 'HÖHERE GEWALT',
    // ORIGINAL TEXT: FORCE MAJEURE
    SEVERABILITY: 'SALVATORISCHE KLAUSEL',
    // ORIGINAL TEXT: SEVERABILITY
    EXPORTCTRL: 'EXPORTKONTROLLE',
    // ORIGINAL TEXT: EXPORT CONTROLS
    INTRO: 'EINLEITUNG',
    // ORIGINAL TEXT: INTRODUCTION
    INSURANCE: 'VERSICHERUNG',
    // ORIGINAL TEXT: INSURANCE
    HEADING: 'ÜBERSCHRIFT',
    // ORIGINAL TEXT: HEADING
    ALL: 'ALLE',
    // ORIGINAL TEXT: ALL
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'E-Mail ist erforderlich',
    // ORIGINAL TEXT: E-mail is required
    EMAILVALID: 'E-Mail muss gültig sein',
    // ORIGINAL TEXT: E-mail must be valid
    MAX20CHARS: 'Maximal 20 Zeichen',
    // ORIGINAL TEXT: Max 20 characters
    MAX60CHARS: 'Maximal 60 Zeichen',
    // ORIGINAL TEXT: Max 60 characters
    MAX80CHARS: 'Max. 80 Zeichen',
    // ORIGINAL TEXT: Max 80 characters
    MAX160CHARS: 'Max 160 Zeichen',
    // ORIGINAL TEXT: Max 160 characters
    EMAILALREADYOWNER: 'E-Mail ist bereits in Benutzung',
    // ORIGINAL TEXT: Email is already an owner
    EMAILALREADYCOLLABORATOR: 'Email ist bereits ein Mitarbeiter',
    // ORIGINAL TEXT: Email is already a collaborator
    EMAILALREADYNEGOTIATOR: 'E-Mail ist bereits ein Verhandlungsführer',
    // ORIGINAL TEXT: Email is already a negotiator
    EMAILALREADYOWNERDOCTYPE: 'E-Mail ist bereits Inhaber des Dokumenttyps',
    // ORIGINAL TEXT: Email is already an owner of the document type
    EMAILALREADYCOLLABORATORDOCTYPE:
      'E-Mail ist bereits ein Mitarbeiter des Dokumenttyps',
    // ORIGINAL TEXT: Email is already an collaborator of the document type
    REQUIRED: 'Erforderlich.',
    // ORIGINAL TEXT: Required.
    CLAUSEFIELDREQUIRED: 'Dieses Feld ist für diesen Vorgang erforderlich.',
    // ORIGINAL TEXT: This clause field is required for this operation
    CLAUSENOTDEFAULT:
      'Klausel aus der Standardklauselliste kann nicht hinzugefügt werden',
    // ORIGINAL TEXT: Cannot add clause from default clause list
    TAGFIELDREQUIRED: 'Dieses Tag-Feld ist für diese Operation erforderlich',
    // ORIGINAL TEXT: This tag field is required for this operation
    TAGNOTDEFAULT:
      'Tags aus der Standard-Tag-Liste können nicht hinzugefügt werden',
    // ORIGINAL TEXT: Cannot add tags from default tag list

    PARAINDEXREQUIRED: 'Absatzindex ist erforderlich',
    // ORIGINAL TEXT: Paragraph index is required
    PARAINDEXINVALID: 'Absatzindex existiert nicht',
    // ORIGINAL TEXT: Paragraph index does not exist
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'Dokument speichern',
    OPENDOCUMENT: 'Dokument öffnen',
    // ORIGINAL TEXT: open document
    CLOSEDOCUMENT: 'Dokument schließen',
    // ORIGINAL TEXT: close document
    ADDNOTE: 'Anmerkung hinzufügen',
    // ORIGINAL TEXT: add note
    ADDCOLLABORATOR: 'Mitarbeiter hinzufügen',
    // ORIGINAL TEXT: add collaborator
    DELETECOLLABORATOR: 'Mitarbeiter löschen',
    // ORIGINAL TEXT: delete collaborator
    ADDNEGOTIATOR: 'Unterhändler hinzufügen',
    // ORIGINAL TEXT: add negotiator
    DELETENEGOTIATOR: 'Unterhändler löschen',
    // ORIGINAL TEXT: delete negotiator
    UPDATEARCHIVEDATA: 'Archivdaten aktualisieren',
    // ORIGINAL TEXT: update archive data
    UPDATEDOCMETA: 'Metadaten des Dokuments aktualisieren',
    // ORIGINAL TEXT: update document metadata
    ADDOWNER: 'Eigentümer hinzufügen',
    // ORIGINAL TEXT: add owner
    DELETEOWNER: 'Eigentümer löschen',
    // ORIGINAL TEXT: delete owner
    CHANGECOLLABORATION: 'geänderte Einstellungen für die Zusammenarbeit',
    // ORIGINAL TEXT: changed collaboration settings
    CHANGENEGOTIATION: 'Änderung der Verhandlungseinstellungen',
    // ORIGINAL TEXT: changed negotiation settings
    SAVEPLAYBOOK: 'gespeichert als Playbook',
    // ORIGINAL TEXT: saved as playbook
    SAVETEMPLATE: 'als Vorlage gespeichert',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: 'gespeichert als archiviert',
    // ORIGINAL TEXT: saved as archived
    TRIGGERALERT: 'Alarm auslösen',
    // ORIGINAL TEXT: trigger alert
    TRIGGERALLALERT: 'Alle Warnungen auslösen',
    // ORIGINAL TEXT: trigger all alerts
    TRIGGERCUSTOMALERT: 'Benutzerdefinierte Warnung auslösen',
    // ORIGINAL TEXT: trigger custom alert
    ADDCOMMENT: 'Kommentar hinzufügen',
    // ORIGINAL TEXT: add comment
    DELETECOMMENT: 'Kommentar löschen',
    // ORIGINAL TEXT: delete comment
    ADDDOCEDIT: 'docedit hinzufügen',
    // ORIGINAL TEXT: add docedit
    ADDAPPROVEDDOCEDIT: 'Zugelassenes Dokument bearbeiten hinzufügen',
    // ORIGINAL TEXT: add approved document edit
    APPROVEDDOCEDIT: 'Dokument bearbeiten genehmigen',
    // ORIGINAL TEXT: approve document edit
    REJECTDOCEDIT: 'Dokument bearbeiten ablehnen',
    // ORIGINAL TEXT: reject document edit
    NEGOTIATORAPPROVEDDOCEDIT: 'Unterhändler genehmigt Dokument bearbeiten',
    // ORIGINAL TEXT: negotiator approved document edit
    NEGOTIATORREJECTEDDOCEDIT: 'Unterhändler abgelehnt Dokument bearbeiten',
    // ORIGINAL TEXT: negotiator rejected document edit
    DELETEDDOCEDIT: 'Dokument bearbeiten löschen',
    // ORIGINAL TEXT: delete document edit
    ATTACHCONTRACT: 'Anhang exec Vertrag',
    // ORIGINAL TEXT: attach exec contract
    ADDTAG: 'Tag hinzufügen',
    // ORIGINAL TEXT: add tag
    DELETETAG: 'Tag löschen',
    // ORIGINAL TEXT: delete tag

    NEWSIGNATUREREQUEST: 'Neue Unterschrift beantragen',
    // ORIGINAL TEXT: new signature requested ???
    CANCELSIGNATUREREQUEST: 'Antrag zur Unterschrift ist gelöscht',
    // ORIGINAL TEXT: cancelled signature request ???

    CREATEREDLINEREQUEST: 'Neues Redline-Dokument erstellen',
    // ORIGINAL TEXT: create new redline document ???
    CREATEFINALDOCREQUEST:
      'Erstelle neues endgültiges Dokument zur Unterschrift',
    // ORIGINAL TEXT: create new final document for signature ???

    COPIEDDOCUMENT: 'aus Dokument kopiert',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: 'saubere Version aus Dokument erstellt',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: 'Dokument aus Vorlage erstellt',
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'Dokumenttyp erstellen',
    // ORIGINAL TEXT: create document type
    DELETEDOCTYPE: 'Dokumenttyp löschen',
    // ORIGINAL TEXT: delete document type
    ADDOWNERTODOCTYPE: 'hinzugefügter Eigentümer des Dokumenttyps',
    // ORIGINAL TEXT: added owner to document type
    DELETEOWNERFROMDOCTYPE: 'gelöschter Eigentümer zum Dokumenttyp',
    // ORIGINAL TEXT: deleted owner to document type
    ADDCLAUSETODOCTYPE: 'addl-Klausel zum Dokumenttyp hinzugefügt',
    // ORIGINAL TEXT: added addl clause to document type
    DELETECLAUSEDOCTYPE: 'gestrichene addl-Klausel zum Dokumenttyp',
    // ORIGINAL TEXT: deleted addl clause to document type
    ADDTAGDOCTYPE: 'Addl-Tag zum Dokumenttyp hinzugefügt',
    // ORIGINAL TEXT: added addl tag to document type
    DELETETAGDOCTYPE: 'Addl-Tag vom Dokumenttyp gelöscht',
    // ORIGINAL TEXT: deleted addl tag from document type
    ADDCOLLABRULEDOCTYPE: 'Collab-Regeln zum Dokumenttyp hinzugefügt',
    // ORIGINAL TEXT: added collab rules to  document type
    DELETECOLLABRULEDOCTYPE: 'Gelöschte Collab-Regeln vom Dokumenttyp',
    // ORIGINAL TEXT: deleted collab rules from document type
    ADDNOTIFRULEDOCTYPE:
      'Benachrichtigungsregeln für den Dokumententyp hinzugefügt',
    // ORIGINAL TEXT: added notification rules to  document type
    DELETENOTIFRULEDOCTYPE:
      'gestrichene Benachrichtigungsregeln vom Dokumenttyp',
    // ORIGINAL TEXT: deleted notification rules from document type
    ADDNEGOTRULEDOCTYPE: 'Aufnahme von Verhandlungsregeln in den Dokumententyp',
    // ORIGINAL TEXT: added negotiation rules to  document type
    DELETENEGOTRULEDOCTYPE: 'gestrichene Verhandlungsregeln vom Dokumenttyp',
    // ORIGINAL TEXT: deleted negotiation rules from document type

    // GROUPS
    CREATEGROUP: 'erstellte Gruppe',
    // ORIGINAL TEXT: created group
    DELETEGROUP: 'Gelöschte Gruppe',
    // ORIGINAL TEXT: deleted group
    ADDOWNERGROUP: 'hinzugefügter Eigentümer zur Gruppe',
    // ORIGINAL TEXT: added owner to group
    DELETEOWNERGROUP: 'gelöscht Eigentümer aus der Gruppe',
    // ORIGINAL TEXT: deleted Owner from group
    ADDCOLLABGROUP: 'Zugehöriger Mitarbeiter zur Gruppe',
    // ORIGINAL TEXT: added collaborator to group
    DELETECOLLABGROUP: 'Gelöschter Mitarbeiter aus der Gruppe',
    // ORIGINAL TEXT: deleted collaborator from group
    ADDDOCTYPETOGROUP: 'Dokumenttyp zu Gruppe hinzugefügt',
    // ORIGINAL TEXT: added document type to group
    DELETEDOCTYPEGROUP: 'Gelöschter Dokumenttyp aus der Gruppe',
    // ORIGINAL TEXT: deleted document type from group

    // ALERTS
    CREATEALERT: 'erstellte Warnung',
    // ORIGINAL TEXT: created alert
    DELETEALERT: 'Gelöschte Ausschreibung',
    // ORIGINAL TEXT: deleted alert
    ADDOWNERALERT: 'hinzugefügter Eigner zur Warnung',
    // ORIGINAL TEXT: added owner to alert
    DELETEOWNERALERT: 'eigentümer aus der Ausschreibung gelöscht',
    // ORIGINAL TEXT: deleted owner from alert
    ADDDOCTYPEALERT: 'doctype zur Warnung hinzugefügt',
    // ORIGINAL TEXT: added doctype to alert
    DELETEDOCTYPEALERT: 'gelöscht doctype aus alarm',
    // ORIGINAL TEXT: deleted doctype from alert
    ADDCLAUSETOPICALERT: 'Zusätzliche Klausel Thema zu alarmieren',
    // ORIGINAL TEXT: added clause topic to alert
    DELETECLAUSETOPICALERT: 'Klausel Thema aus der Warnung gelöscht',
    // ORIGINAL TEXT: deleted clause topic from alert
    ADDRECIPIENTALERT: 'Empfänger zur Warnung zugefügt',
    // ORIGINAL TEXT: added recipient to alert
    DELETERECIPIENTALERT: 'Empfänger aus der Warnung gelöscht',
    // ORIGINAL TEXT: deleted recipient from alert
    ADDNOTIFRULEALERT: 'Benachrichtigungsregeln zur Warnung hinzugefügt',
    // ORIGINAL TEXT: added notification rules to alert
    DELETENOTIFRULEALERT: 'Benachrichtigungsregeln aus der Warnung gelöscht',
    // ORIGINAL TEXT: deleted notification rules from alert
    SETSELECTIVITYALERT: 'Selektivität auf Alarm setzen',
    // ORIGINAL TEXT: set selectivity to  alert
  },

  USEREVENTS: {
    USERSIGNON: 'Benutzer angemeldet',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT: 'Passwort Reset Anfrage gesendet',
    // ORIGINAL TEXT: password reset request sent
    CHANGEDPASSWORD: 'Passwort geändert',
    // ORIGINAL TEXT: changed password
    DELETEDREGISTRATION: 'Benutzerregistrierung gelöscht',
    // ORIGINAL TEXT: deleted user registration
    UPDATEDACCOUNTINFO: 'Kontoinformationen aktualisiert',
    // ORIGINAL TEXT: updated account information
    UPGRADEDACCOUNTTOREFERRAL: 'Konto auf das angegebene Konto angehoben',
    // ORIGINAL TEXT: upgraded account to referred account
    DELETEDUSERACCOUNT: 'Benutzerkonto gelöscht',
    // ORIGINAL TEXT: deleted user account
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: 'DOKUMENTIERUNGSAKTIONEN',
    // ORIGINAL TEXT: DOCUMENT ACTIONS
    COMMENTS: 'KOMMENTAR',
    // ORIGINAL TEXT: COMMENTS
    DOCEDITS: 'DOKUMENTEDITS',
    // ORIGINAL TEXT: DOCUMENT EDITS
    LABELSRISK: 'ETIKETT UND RISIKO',
    // ORIGINAL TEXT: LABELS AND RISK
    TEXT: 'TEXT',
    // ORIGINAL TEXT: TEXT
    MLE_DESC: 'DOCGILITY',
    // ORIGINAL TEXT: DOCGILITY
    CLAUSE: 'Abschnitt',
    // ORIGINAL TEXT: Clause

    // PRIVILEGES
    OWNER: 'Eigentümer',
    // ORIGINAL TEXT: owner
    COLLABORATOR: 'Mitarbeiter',
    // ORIGINAL TEXT: collaborator
    NEGOTIATOR: 'Verhandlungsführer',
    // ORIGINAL TEXT: negotiator
    OWNERGROUP: 'Eigentümergruppe',
    // ORIGINAL TEXT: owner group
    COLLABORATORGROUP: 'Mitarbeitergruppe',
    // ORIGINAL TEXT: collaborator group

    // TITLES
    SAVEDOCUMENT: 'SAVE DOKUMENT',
    // ORIGINAL TEXT: SAVE DOCUMENT
    SAVEARCHIVE: 'ARCHIV SICHERN',
    // ORIGINAL TEXT: SAVE ARCHIVE
    QUICKSTART: 'SCHNELLSTART LERNPROGRAMM',
    // ORIGINAL TEXT: QUICK START TUTORIAL
    TUTORIALVIDEOS: 'LERNPROGRAMM VIDEOS',
    // ORIGINAL TEXT: TUTORIAL VIDEOS

    MOVETOARCHIVEDOCUMENT: 'VERSCHIEBE ZUM ARCHIVDOKUMENT',
    // ORIGINAL TEXT: MOVE TO ARCHIVE DOCUMENT
    OPENDOCUMENT: 'DOKUMENT ÖFFNEN',
    // ORIGINAL TEXT: OPEN DOCUMENT
    COPYDOCUMENT: 'DOKUMENT KOPIEREN',
    // ORIGINAL TEXT: COPY DOCUMENT
    CREATEVERSION: 'SAUBERE VERSION ERSTELLEN',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: 'DOKUMENT AUS VORLAGE ERSTELLEN',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: 'GRUPPENDOKUMENT ÖFFNEN',
    // ORIGINAL TEXT: OPEN GROUP DOCUMENT
    OPENHUBDOCUMENT: 'DOKUMENT HUB ÖFFNEN',
    // ORIGINAL TEXT: OPEN HUB DOCUMENT
    OPENARCHIVEDOCUMENT: 'ARCHIV DOKUMENT ÖFFNEN',
    // ORIGINAL TEXT: OPEN ARCHIVE DOCUMENT
    OPENPLAYBOOK: 'PLAYBOOK ÖFFNEN',
    // ORIGINAL TEXT: OPEN PLAYBOOK
    OPENTEMPLATE: 'VORLAGE ÖFFNEN',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'ALS VORLAGE SPEICHERN',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: 'KOLLABORATION',
    // ORIGINAL TEXT: COLLABORATION
    NEGOTIATION: 'VERHANDLUNG',
    // ORIGINAL TEXT: NEGOTIATION
    ADDOWNER: 'BESITZER HINZUFÜGEN',
    // ORIGINAL TEXT: ADD OWNER
    ADDNEGOTIATOR: 'VERHANDLUNGSFÜHRER HINZUFÜGEN',
    // ORIGINAL TEXT: ADD NEGOTIATOR
    ADDCOLLABORATOR: 'MITARBEITER HINZUFÜGEN',
    // ORIGINAL TEXT: ADD COLLABORATOR
    ERRORMESSAGE: 'FEHLERMELDUNG',
    // ORIGINAL TEXT: ERROR MESSAGE
    DOCUMENTINFO: 'DOKUMENT INFORMATION',
    // ORIGINAL TEXT: DOCUMENT INFORMATION
    DOCUMENTLOGS: 'DOKUMENT LOG',
    // ORIGINAL TEXT: DOCUMENT LOGS
    USERLOGS: 'BENUTZER LOG',
    // ORIGINAL TEXT: USER LOGS
    DOCUMENTNOTES: 'DOKUMENT ANMERKUNGEN',
    // ORIGINAL TEXT: DOCUMENT NOTES
    TRIGGERALERTS: 'WARNUNG AUSLÖSEN',
    // ORIGINAL TEXT: TRIGGER ALERTS
    ALERTS: 'WARNUNG',
    // ORIGINAL TEXT: ALERTS
    DOCTYPES: 'DOKUMENTARTEN',
    // ORIGINAL TEXT: DOCUMENT TYPES
    GROUPS: 'GRUPPEN',
    // ORIGINAL TEXT: GROUPS
    NOTE: 'HINWEIS',
    // ORIGINAL TEXT: NOTE
    GOTOPARAGRAPH: 'Gehen Sie zu PARAGRAPH',
    // ORIGINAL TEXT: GO TO PARAGRAPH

    ESIGNATURE: 'E-Unterschrift',
    // ORIGINAL TEXT: E-SIGNATURE
    DOWNLOADREDLINE: 'Redline DOCX herunterladen',
    // ORIGINAL TEXT: DOWNLOAD REDLINE DOCX

    // ACTIONS
    ADD: 'ZUSÄTZLICH',
    // ORIGINAL TEXT: ADD
    BACK: 'ZURÜCK',
    // ORIGINAL TEXT: BACK
    DELETE: 'LÖSCHEN',
    // ORIGINAL TEXT: DELETE
    SAVE: 'SICHERN',
    // ORIGINAL TEXT: SAVE
    MOVETOARCHIVE: 'INS ARCHIV VERSCHIEBEN',
    // ORIGINAL TEXT: MOVE TO ARCHIVE
    COPY: 'KOPIEREN',
    // ORIGINAL TEXT: COPY
    CREATE: 'SCHAFFEN',
    // ORIGINAL TEXT: CREATE
    OPEN: 'ÖFFNEN',
    // ORIGINAL TEXT: OPEN
    CLOSE: 'SCHLIESSEN',
    // ORIGINAL TEXT: CLOSE
    UPDATE: 'AKTUALISIEREN',
    // ORIGINAL TEXT: UPDATE
    REGISTER: 'REGISTRIEREN',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'LOGIN',
    // ORIGINAL TEXT: LOGIN
    LOGINSSO: 'LOGIN SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: 'RESET PASSWORD',
    // ORIGINAL TEXT: RESET PASSWORD
    CHANGEPASSWORD: 'PASSWORT ÄNDERN',
    // ORIGINAL TEXT: CHANGE PASSWORD
    CANCELACCOUNT: 'ACCOUNT LÖSCHEN',
    // ORIGINAL TEXT: CANCEL ACCOUNT
    QUICKSTARTVIDEO: 'LERNOROGRAMM VIDEOS ANSEHEN',
    // ORIGINAL TEXT: WATCH QUICK START VIDEO

    CHARGEBASIC: 'AUF BASIC NACHRÜSTEN',
    // ORIGINAL TEXT: UPGRADE TO BASIC
    CHARGEPREMIUM: 'AUF PREMIUM NACHRÜSTEN',
    // ORIGINAL TEXT: UPGRADE TO PREMIUM
    CHARGECORPORATE: 'AUF CORPORATE NACHRÜSTEN',
    // ORIGINAL TEXT: UPGRADE TO CORPORATE
    UPGRADESUBSCRIPTION: 'ABBONEMENT NACHRÜSTEN',
    // ORIGINAL TEXT: UPGRADE SUBSCRIPTION
    GO: 'Gehen Sie.',
    // ORIGINAL TEXT: GO
    PREVIEW_FEEDBACK: 'VERÄNDERUNGEN UEBERPRÜFEN',
    // ORIGINAL TEXT: REVIEW CHANGES
    ACTIONS: 'RECHTSAKTE',
    // ORIGINAL TEXT: ACT
    REFRESH: 'AKTUALISIEREN',
    // ORIGINAL TEXT: REFRESH
    ADDNEWGROUP: 'NEUE GRUPPE HINZUFÜGEN',
    // ORIGINAL TEXT: ADD NEW GROUP
    ADDNEWDOCTYPE: 'NEUE DOKTYPE HINZUFÜGEN',
    // ORIGINAL TEXT: ADD NEW DOCTYPE
    ADDNEWALERT: 'NEUE WARNUNG HINZUFÜGEN',
    // ORIGINAL TEXT: ADD NEW ALERT
    GROUPSLIST: 'GRUPPENLISTE ÜBERPRÜFEN',
    // ORIGINAL TEXT: REVIEW GROUP LIST

    DOWNLOADDOCUMENT: 'DOKUMENT HERUNTERLADEN',
    // ORIGINAL TEXT: DOWNLOAD DOCUMENT
    DOCUMENTTITLE: 'Titel des Dokuments',
    // ORIGINAL TEXT: Document Title
    DOCUMENTSUBJECT: 'Thema des Dokuments',
    // ORIGINAL TEXT: Document Subject
    SIGNMESSAGE: 'Nachricht für die Unterzeichnung',
    // ORIGINAL TEXT: Message for Signing
    NAMEPRIMARYSIGNER: 'Name: Primärer Unterzeichner',
    // ORIGINAL TEXT: Name: Primary Signer
    EMAILPRIMARYSIGNER: 'E-Mail: Primärer Unterzeichner',
    // ORIGINAL TEXT: Email: Primary Signer
    NAMECOUNTERSIGNER: 'Name: Gegenunterzeichner',
    // ORIGINAL TEXT: Name: Counter Signer
    EMAILCOUNTERSIGNER: 'E-Mail: Gegenunterzeichner',
    // ORIGINAL TEXT: Email: Counter Signer
    SIGREQUESTED: 'UNTERSCHRIFT VERLANGT',
    // ORIGINAL TEXT: SIGNATURE REQUESTED
    SIGREQUESTEDBY: 'ANGEFORDERT VON',
    // ORIGINAL TEXT: REQUESTED BY
    SIGREQUESTEDID: 'UNTERSCHRIFTS ID',
    // ORIGINAL TEXT: SIGNATURE ID
    SIGREQUESTEDAT: 'ANGEFORDERT',
    // ORIGINAL TEXT: REQUESTED AT
    SUBMITSIG: 'ZUR UNTERSCHRIFT ÜBERMITTELN',
    // ORIGINAL TEXT: SUBMIT FOR SIGNATURE
    CANCELSIG: 'ANFRAGE ZUR UNTERSCHRIFT LÖSCHEN',
    // ORIGINAL TEXT: CANCEL SIGNATURE REQUEST

    // DATA LABELS
    NAME: 'NAME',
    // ORIGINAL TEXT: NAME
    EMAIL: 'EMAIL',
    // ORIGINAL TEXT: EMAIL
    STATUS: 'STATUS',
    // ORIGINAL TEXT: STATUS

    LASTVIEWED: 'ZULETZT ANGESEHEN',
    // ORIGINAL TEXT: LAST VIEWED
    LASTREVIEWED: 'ZULETZT ÜBERPRÜFT',
    // ORIGINAL TEXT: LAST REVIEWED
    SIGNEDAT: 'UNTERSCHRIEBEN',
    // ORIGINAL TEXT: SIGNED

    OWNERAPPROVAL: 'BESITZER GENEHMIGUNG',
    // ORIGINAL TEXT: OWNER APPROVAL
    OWNERACTION: 'BESITZER AKTION',
    // ORIGINAL TEXT: OWNER ACTION
    NEGOTIATORAPPROVAL: 'VERHÄNDLER GENEHMIGUNG',
    // ORIGINAL TEXT: NEGOTIATOR APPROVAL
    NEGOTIATORACTION: 'VERHÄNDLER AKTION',
    // ORIGINAL TEXT: NEGOTIATOR ACTION
    ARCHIVENOTES: 'ARCHIV ANMERKUNGEN',
    // ORIGINAL TEXT: ARCHIVE NOTES
    EXECUTEDCONTRACT: 'AUSGEFÜHRT',
    // ORIGINAL TEXT: EXECUTED
    EXECUTEDDATE: 'AUSFÜHRUNGS DATUM',
    // ORIGINAL TEXT: EXECUTED DATE
    CONTRACTATTACHED: 'VERTRAG ANGEHÄNGT',
    // ORIGINAL TEXT: CONTRACT ATTACHED
    STARTDATE: 'START DATUM',
    // ORIGINAL TEXT: START DATE
    ENDDATE: 'ENDE DATUM',
    // ORIGINAL TEXT: END DATE
    EDIT: 'EDIT',
    // ORIGINAL TEXT: EDIT
    USER: 'BENUTZER',
    // ORIGINAL TEXT: USER
    TIME: 'ZEIT',
    // ORIGINAL TEXT: TIME
    CONFIRM: 'BESTÄTIGUNG',
    // ORIGINAL TEXT: CONFIRM
    CONFIRMACTION: 'AKTION BESTÄTIGEN',
    // ORIGINAL TEXT: CONFIRM ACTION
    CURRENT_LABEL: 'LAUFENDES ETIKETT',
    // ORIGINAL TEXT: CURRENT LABEL
    NEW_LABEL: 'NEUES ETIKETT',
    // ORIGINAL TEXT: NEW LABEL
    DESCRIPTION: 'BESCHREIBUNG',
    // ORIGINAL TEXT: DESCRIPTION
    TYPE: 'Art',
    // ORIGINAL TEXT: TYPE
    SENTENCE: 'SATZ',
    // ORIGINAL TEXT: SENTENCE
    NUMBER: '#',
    // ORIGINAL TEXT: #
    PRIVILEGE: 'ROLLE',
    // ORIGINAL TEXT: ROLE
    OWNERID: 'OWNERID',
    // ORIGINAL TEXT: OWNERID
    DOCNAME: 'DOKUMENT NAME',
    // ORIGINAL TEXT: DOCUMENT NAME
    DOCTYPE: 'DOKUMENTART',
    // ORIGINAL TEXT: DOCUMENT TYPE
    DOCDESCRIPTION: 'DOKUMENT BESCHREIBUNG',
    // ORIGINAL TEXT: DOCUMENT DESCRIPTION
    DOCNOTES: 'DOKUMENTE ANMERKUNGEN',
    // ORIGINAL TEXT: DOCUMENT NOTES
    DOCPARTY: 'TEIL',
    // ORIGINAL TEXT: PARTY
    DOCCOUNTERPARTY: 'GEGENPARTEI',
    // ORIGINAL TEXT: COUNTER PARTY
    DOCRAWFILENAME: 'DOKUMENTENDATEINAME',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'ANHÄNGER',
    // ORIGINAL TEXT: TAGS
    COLLABORATIONSTATUS: 'COLLABORATION STATUS',
    // ORIGINAL TEXT: COLLABORATION STATUS
    COLLABORATIONEND: 'COLLABORATION ENDE',
    // ORIGINAL TEXT: COLLABORATION END
    NEGOTIATIONSTATUS: 'VERHANDLUNGSSTATUS',
    // ORIGINAL TEXT: NEGOTIATION STATUS
    NEGOTIATIONEND: 'VERHANDLUNGSENDE',
    // ORIGINAL TEXT: NEGOTIATION END
    DOCID: 'DOKUMENTID',
    // ORIGINAL TEXT: DOCUMENT ID
    PARAGRAPHID: 'PARAGRAPH ID',
    // ORIGINAL TEXT: PARAGRAPH ID
    ACTION: 'PROTOKOLLIERTE AKTIONEN',
    // ORIGINAL TEXT: LOGGED ACTION
    DETAIL: 'AKTIONSDETAIL',
    // ORIGINAL TEXT: ACTION DETAIL
    CONTEXT: 'ZUSÄTZLICHER KONTEXT',
    // ORIGINAL TEXT: ADDITIONAL CONTEXT
    GROUPNAME: 'NAME DER GRUPPE',
    // ORIGINAL TEXT: GROUP NAME
    GROUPDESC: 'BESCHREIBUNG DER GRUPPE',
    // ORIGINAL TEXT: GROUP DESCRIPTION
    DOCTYPEDESC: 'BESCHREIBUNG DES DOKUMENTS',
    // ORIGINAL TEXT: DOCUMENT TYPE DESCRIPTION
    ADDLCLAUSETYPES: 'BENUTZERDEFINIERTE KENNZEICHEN',
    // ORIGINAL TEXT: CUSTOM CLAUSE LABELS
    ADDLTAGS: 'ANÄHNGER',
    // ORIGINAL TEXT: CUSTOM TAGS
    COLLABORATIONRULES: 'COLLABORATIONSREGELN',
    // ORIGINAL TEXT: COLLABORATION RULES
    NEGOTIATIONRULES: 'VERHANDLUNGSREGELN',
    // ORIGINAL TEXT: NEGOTIATION RULES
    NOTIFICATIONRULES: 'BENACHRICHTIGUNGSREGELN',
    // ORIGINAL TEXT: NOTIFICATION RULES
    LOG: 'LOG',
    // ORIGINAL TEXT: LOG
    ALERTNAME: 'WARNUNGSNAME',
    // ORIGINAL TEXT: ALERT NAME
    ALERTDESC: 'WARNUNGSBESCHREIBUNG',
    // ORIGINAL TEXT: ALERT DESCRIPTION
    TOPICS: 'THEMEN',
    // ORIGINAL TEXT: TOPICS
    RECIPIENTS: 'EMPFÄNGER',
    // ORIGINAL TEXT: RECIPIENTS
    SELECTIVITY: 'SELEKTIVITÄT',
    // ORIGINAL TEXT: SELECTIVITY
    FIELDNAME: 'FELDNAME',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: 'AUFTRAG',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: 'BEISPIEL',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: 'URSPRÜNGLICH',
    // ORIGINAL TEXT: DEFAULT
    VALUE: 'WERT',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: 'DOKUMENTIEREN',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: 'PLAYBOOK',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: 'VORLAGE',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: 'ARCHIVIERTES DOKUMENT',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: 'HERGESTELLT AUS VORLAGE:',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: 'KOLLABORATION',
    // ORIGINAL TEXT: COLLABORATION
    NEGSHORT: 'VERHANDLUNG',
    // ORIGINAL TEXT: NEGOTIATION
    OWNERS: 'BESITZER',
    // ORIGINAL TEXT: OWNERS
    COLLABORATORS: 'MITARBEITER',
    // ORIGINAL TEXT: COLLABORATORS
    NEGOTIATORS: 'VERHANDLUNGEN',
    // ORIGINAL TEXT: NEGOTIATORS
    // ADDITION
    DOCUMENTDETAILS: 'DETAILS DES DOKUMENTS',
    // ORIGINAL TEXT: DOCUMENT DETAILS
    CREATIONDATE: 'ERSTELLUNGSDATUM',
    // ORIGINAL TEXT: CREATION DATE
    PARTICIPANTS: 'TEILNEHMER',
    // ORIGINAL TEXT: PARTICIPANTS
    COLLABORATIONANDNEGOTIATION: 'KOLLABORATION UND VERHANDLUNG',
    // ORIGINAL TEXT: COLLABORATION AND NEGOTIATION
    DOCTYPEANDGROUPACCESS: 'DOKUMENTTYP UND ZUGANG DER GRUPPE',
    // ORIGINAL TEXT: DOCUMENT TYPE AND GROUP ACCESS
    GROUPOWNERS: 'GRUPPENBETEILIGTE',
    // ORIGINAL TEXT: GROUP OWNERS
    GROUPCOLLABORATORS: 'GRUPPENMITARBEITER',
    // ORIGINAL TEXT: GROUP COLLABORATORS
    DOCUMENTSETTINGS: 'EINSTELLUNGEN',
    // ORIGINAL TEXT: DOCUMENT SETTINGS
    ENABLECOLLABTOMODLABELS:
      'Ermöglichen Sie Kollaboratoren, Clause-Labels zu ändern", Ermöglichen Sie Mitarbeitern Clause-Labels zu ändern',
    // ORIGINAL TEXT: Enable Collaborators to Modify Clause Labels
    ENABLENEGOTCAPABILITIES:
      'Verhandlungsfunktionen aktivieren (Nicht-Verhandlungsmodus)',
    // ORIGINAL TEXT: Enable Negotiation capabilities (Not Negotiation mode)
    KEEPDOCNOTES: 'Dokumentnotizen mit Kopiervorgang kopieren',
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS:
      'Überprüfte und geänderte Clause-Themen als notwendig',
    // ORIGINAL TEXT: Reviewed and Amended Clause Topics as Necessary
    NOEXECUTIONDATE: 'Kein Ausführungsdatum',
    // ORIGINAL TEXT: No Execution Date
    EXECUTIONDATE: 'Ausführungsdatum',
    // ORIGINAL TEXT: Execution Date
    CONTRACTSTARTDATE: 'Datum des Vertragsbeginns',
    // ORIGINAL TEXT: Contract Start Date
    CONTRACTENDDATE: 'Vertragsendedatum',
    // ORIGINAL TEXT: Contract End Date
    EXECCONTRACTNAME: 'Ausgeführter Vertragsdateiname',
    // ORIGINAL TEXT: Executed Contract File Name
    SELECTEXECCONTRACT:
      'Datei zum Hochladen des ausgeführten Vertrags auswählen',
    // ORIGINAL TEXT: select file to upload executed contract
    UPLOADEXECUTEDCONTRACT: 'AUSGEWÄHLTE VERTRAGSSTOFFE',
    // ORIGINAL TEXT: UPLOAD EXECUTED CONTRACT
    LOADDEFAULTS: 'VOREINSTELLUNGEN LADEN',
    // ORIGINAL TEXT: LOAD DEFAULTS
    CLOSED: 'GESCHLOSSEN',
    // ORIGINAL TEXT: CLOSED
    NONE: 'NICHT',
    // ORIGINAL TEXT: NONE
    NA: 'N.A.',
    // ORIGINAL TEXT: NA
    RESULTEXECUTEDCONTRACT: 'Resultat ist der ausgeführte Vertrag',
    // ORIGINAL TEXT: Resulted in Executed Contract
    NOSTARTDATE: 'Kein Startdatum',
    // ORIGINAL TEXT: No Start Date
    NOENDDATE: 'Kein Enddatum',
    // ORIGINAL TEXT: No End Date
    EXCLUDECOLLABORATORSFROMACCESS:
      'Externe Kollaboratoren vom Zugriff auf Dokument ausschließen',
    // ORIGINAL TEXT: Exclude External Collaborators From Accessing Document
    EXCLUDENEGOTIATORSFROMACCESS:
      'Externe Verhandlungsführer vom Zugriff auf Dokument ausschließen',
    // ORIGINAL TEXT: Exclude External Negotiators From Accessing Document
    REVIEWEDAMENDEDCLAUSELABELS:
      'Überarbeitete und geänderte Klauseln-Etiketten als notwendig',
    // ORIGINAL TEXT: Reviewed and Amended Clause Labels as Necessary
    ENTERPARAGRAPHBETWEEN: 'Geben Sie eine Absatznummer zwischen:',
    // ORIGINAL TEXT: Enter a paragraph number between:
    GOTOPARAGRAPHINDEX: 'Zum Absatzindex gehen',
    // ORIGINAL TEXT: Go to Paragraph Index
    INPUTDOCUMENTNOTEHERE: 'Eingabedokument Anmerkung hier',
    // ORIGINAL TEXT: Input Document Note here
    INPUTCOMMENTHERE: 'Input Kommentar hier',
    // ORIGINAL TEXT: Input Comment here
    SHOWPENDINGONLY: 'Nur noch ausstehende anzeigen',
    // ORIGINAL TEXT: Show Pending Only
    COLLABORATIONSETTING: 'MITARBEITER EINSTELLUNGEN',
    // ORIGINAL TEXT: COLLABORATION SETTING
    NEGOTIATIONSETTING: 'VERHANDLUNGS EINSTELLUGEN',
    // ORIGINAL TEXT: NEGOTIATION SETTING
    NONEGOTWHENAPPROVED:
      'Verhandlung kann nicht angeschaltet werden wenn im genehmigten Zustand.',
    // ORIGINAL TEXT: Cannot turn on Negotiation when in Approved State
    EMAILTOADD: 'E-Mail zum Hinzufügen',
    // ORIGINAL TEXT: Email to add
    CLAUSETOADD: 'Zu ergänzender Abschnitt',
    // ORIGINAL TEXT: Clause to add
    TAGTOADD: 'Zum Hinzufügen hinzufügen hinzufügen',
    // ORIGINAL TEXT: Tag to add
    OPTIONSFORREDLINEINCLUDE: 'Optionen für Redlines sollten beinhalten',
    // ORIGINAL TEXT: Options for Redlines to Include
    ONLYINCLUDEOWNERAPPROVEDEDITS:
      'Nur von Eigentümern genehmigte Veränderungen einbeziehen',
    // ORIGINAL TEXT: Only Include Owner-approved Document Edits
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'Nur von Eigentümern und Unterhändlern genehmigte Veränderungen einbeziehen',
    // ORIGINAL TEXT: Only Include Owner and Negotiator-approved Document Edits
    INCLUDEALLDOCEDITS:
      'Alle Änderungen einbeziehen (unabhängig von der Genehmigung)',
    // ORIGINAL TEXT: Include All Document Edits (regardless of approval)
    REDLINECHANGESSELECTED: 'Redlined Änderungen ausgewählt',
    // ORIGINAL TEXT: Redlined Changes Selected
    TOTALCHANGES: 'Alle Änderungen',
    // ORIGINAL TEXT: Total Changes
    REPLACEREDLINES: 'Redlines ersetzen',
    // ORIGINAL TEXT: Replace Redlines
    DELETEREDLINES: 'Redlines löschen',
    // ORIGINAL TEXT: Delete Redlines
    ADDAFTERREDLINES: 'Nach Redlines hinzufügen',
    // ORIGINAL TEXT: Add-After Redlines
    OPTIONSFORPREVREDLINES: 'Optionen für frühere Redlines',
    // ORIGINAL TEXT: Options for Previous Redlines
    OVERWRITEPREVREDLINES:
      'Alle vorherigen Redlines in Dokumentquelle überschreiben',
    // ORIGINAL TEXT: Overwrite all Previous Redlines in Document Source
    KEEPPREVREDLINES: 'Alle vorherigen Redlines in Dokument-Quelle beibehalten',
    // ORIGINAL TEXT: Keep all Previous Redlines in Document Source
    GENERATEFINALDOC: 'Schlussdokument generieren (ohne Redlines)',
    // ORIGINAL TEXT: Generate Final Document (without Redlines)
    DEFAULTESIGNOTE:
      'Wie besprochen, E-Unterschreiben Sie bitte diesen Vertrag.',
    // ORIGINAL TEXT: As discussed, please provide your eSignature to this contract.
    SUBMITTEDSIGMESSAGE: 'Bitte warten Sie.',
    // ORIGINAL TEXT: Submitted for signatures.  Please wait.
    UPLOADEDMESSAGE: 'Hochgeladen',
    // ORIGINAL TEXT: Uploaded
    ADDADDLFILES:
      'Optional - Hinzufügen zusätzlicher Dateien zur Unterschriftsanfrage',
    // ORIGINAL TEXT: Optional - Adding additional files to signature request
    ADDFILESMSG: 'Zusätzliche Dateien zur Unterschrift hinzufügen',
    // ORIGINAL TEXT: add additional files for signature
    ESIGPREPAIDPLANS:
      'Die eSignatur-Funktion ist nur über unsere Prepaid-Tarife verfügbar.',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      'Kontaktieren Sie uns unter billing@mylegaleinstein.com für weitere Details.',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: 'Weitere Videos auf der ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'Der Dokumententyp wird nicht unterstützt. MLE akzeptiert nur Docx- und PDF-Formate.',
    // ORIGINAL TEXT: Document type is not supported.  MLE only accepts docx and pdf formats.
    PDFSUPPORTEDFORPREMIUMONLY:
      'Für das PDF-Scannen muss der Benutzer ein Upgrade auf Premium-Abonnement oder besser durchführen.',
    // ORIGINAL TEXT: For PDF scanning, user needs to upgrade to Premium subscription or better.
    BADDOCFORMAT:
      'Schlechtes Dokumentenformat wurde an die Dokumentenverarbeitung übergeben.',
    // ORIGINAL TEXT: Bad Document format was passed to document processing.
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      'Sie haben nicht das Recht, Änderungen an den Dokumenteneinstellungen vorzunehmen. Sie müssen Eigentümer sein.',
    // ORIGINAL TEXT: You do not have the privilege to make changes to the Document Settings.  You are required to be an Owner.
    PRIVILEGSNOTAVAIL:
      'Die Rechte für diesen Benutzer sind nicht verfügbar. Versuchen Sie es später noch einmal.',
    // ORIGINAL TEXT: The privileges for this user are not available.  Try again later.
    EXHAUSTEDTRIALLICENSE:
      "Sie haben die Anzahl der erlaubten Dokumente in Ihrem Testzeitraum erschöpft. Bitte wählen Sie in Ihrem Profil 'Abo upgrade'.",
    // ORIGINAL TEXT: You have exhausted the number of allowed documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    EXHAUSTEDMONTHLYLIMIT:
      "Sie haben die Anzahl der erlaubten monatlichen Dokumente innerhalb Ihres Testzeitraums erschöpft. Bitte wählen Sie in Ihrem Profil 'Abo upgrade'.",
    // ORIGINAL TEXT: You have exhausted the number of allowed monthly documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    PASSWORDNOTMATCHING:
      'Passwörter stimmen nicht überein. Überprüfen Sie die Passwörter erneut.',
    // ORIGINAL TEXT: Passwords do not match.  Check the passwords again.
    ABAMEMBERIDLENGTH:
      'ABA Mitglieds-IDs sind 7 Zeichen lang. Bitte überprüfen und senden.',
    // ORIGINAL TEXT: ABA Membership IDs are 7 characters long.  Please review and submit.
    TERMSNOTCHECKED:
      'Die Allgemeinen Geschäftsbedingungen wurden nicht überprüft und überprüft.',
    // ORIGINAL TEXT: The Terms and Conditions have not been reviewed and checked.
    EMAILFORMATBAD:
      'E-Mail-Format ist nicht gültig. Bitte überprüfen Sie E-Mail-Adresse.',
    // ORIGINAL TEXT: Email format is not valid.  Please review email address.
    CANCELEDACTION:
      'Ihre letzte Aktion wurde abgebrochen. Die Anwendung wird jetzt neu geladen.',
    // ORIGINAL TEXT: Your last action have been canceled.  Application will now reload.
    SUCCESSACTION:
      'Ihre letzte Aktion war erfolgreich. Die Anwendung wird jetzt neu geladen.',
    // ORIGINAL TEXT: Your last action has been successful.  Application will now reload.
    USERSUCCESSLOGIN: 'Der Benutzer hat sich erfolgreich angemeldet.',
    // ORIGINAL TEXT: User has successfully logged on.
    USERLOGGEDOUT: 'Der Benutzer wurde ausgeloggt',
    // ORIGINAL TEXT: The user has been logged out
    EMAILNOTVERIFIED:
      'Konto-E-Mail wurde noch nicht überprüft. Eine E-Mail-Verifizierung wurde an Sie gesendet.',
    // ORIGINAL TEXT: Account email has not yet been verified.  An email verification has been sent to you.
    EMAILALREADYREGISTERED:
      'Diese E-Mail hat bereits ein zuvor registriertes Konto.',
    // ORIGINAL TEXT: This email already has a previously registered account.
    EMAILNOTVERIFIEDRESENT:
      'Ihre E-Mail wurde nicht verifiziert. Wir haben Ihnen einen neuen E-Mail-Verifizierungslink zur Aktivierung des neuen Kontos gesendet.',
    // ORIGINAL TEXT: Your email has not been verified.  We have resent you an email verification link to your email to activate the new account.
    USERLOGFILENOTEXISTS: 'Fehler - keine Log-Benutzerdatei existiert.',
    // ORIGINAL TEXT: Error - no log user file exists.
    PASSWORDRESETSENT:
      'Für Ihr Konto wurde eine E-Mail-Passwort-Reset-Nachricht gesendet.',
    // ORIGINAL TEXT: An email password reset message has been sent for your account.
    PASSWORDCHANGED: 'Ihr Passwort wurde geändert.',
    // ORIGINAL TEXT: Your password has been changed.
    REGISTRATIONDELETED: 'gelöschte Benutzerregistrierung',
    // ORIGINAL TEXT: deleted user registration
    USERACCOUNTUPDATED:
      'Sie haben die Einstellungen Ihres Benutzerkontos aktualisiert. Änderungen werden beim nächsten Login in Kraft treten.',
    // ORIGINAL TEXT: You have updated your user account settings.  Changes will take into effect the next time you login.
    UPGRADEDTOREFERRED:
      'Ihr Konto wurde auf das Abonnement Referred Trial aktualisiert.',
    // ORIGINAL TEXT: Your account has been updated to Referred Trial subscription.
    REGISTEREDTRIALUSER: 'Neues Konto ist als Trial-Abonnement registriert.',
    // ORIGINAL TEXT: New account is registered as Trial subscription.
    DELETEDUSERACCOUNT: 'Gelöschtes Benutzerprofil-Konto',
    // ORIGINAL TEXT: Deleted user profile account
    EMAILALREADYREGISTEREDRESENT:
      'E-Mail wurde noch nicht bei Docgility registriert. Eine E-Mail-Einladung wurde an den neuen Benutzer gesendet.',
    // ORIGINAL TEXT: Email has not already been registered with Docgility.  An email invitation has been sent to the new user.
    UPGRADEEXTERNALPAYMENTS:
      'Vielen Dank für das Upgrade. Sie werden auf einen externen Zahlungsdienst umgeleitet und nach Abschluss Ihrer Transaktion müssen Sie sich erneut anmelden, damit der Abo-Service startet.',
    // ORIGINAL TEXT: Thanks for upgrading.  You will be rerouted to an external payment service and after completing your transaction, you will need to login again for subscription service to start.
    BILLINGEXTERNALPAYMENTS:
      'Bitte bestätigen Sie, dass Sie gerne an unseren externen Zahlungs- und Abrechnungsservice weitergeleitet werden möchten. Nachdem Sie Änderungen vorgenommen haben, müssen Sie sich erneut anmelden, damit Änderungen wirksam werden.',
    // ORIGINAL TEXT: Please confirm that you will like to be directed to our external payment and billing service.  After you make any changes, you will need to login again for changes to take effect.
    NOTABLETOLOGINATTHISTIME:
      'Zur Zeit nicht in der Lage, sich anzumelden. Versuchen Sie es später noch einmal.',
    // ORIGINAL TEXT: Not able to login at this time.  Try again later.
    NOTREGISTERED:
      'Der Benutzer hat sich bisher nicht registriert. Registrieren Sie sich, bevor Sie sich anmelden.',
    // ORIGINAL TEXT: User has not previously registered.  Register before trying to login.
    NAMETOOLONG: 'Der Name ist zu lang. Max ist 60 Zeichen.',
    // ORIGINAL TEXT: The name you entered is too long.  Max is 60 characters.
    COMPANYTOOLONG:
      'Die Firma, die Sie eingegeben haben, ist zu lang. Max ist 60 Zeichen.',
    // ORIGINAL TEXT: The company you entered is too long.  Max is 60 characters.
    COMMENTTOOLONG:
      'Die Kommentare, die Sie eingegeben haben, sind zu lang. Max ist 10000 Zeichen.',
    // ORIGINAL TEXT: The comments you entered is too long.  Max is 10000 characters.
    DOCEDITTOOLONG:
      'Die Eingabe des Dokuments ist zu lang. Max ist 10000 Zeichen.',
    // ORIGINAL TEXT: The document edits you entered is too long.  Max is 10000 characters.
    TITLETOOLONG: 'Der eingegebene Titel ist zu lang. Max ist 60 Zeichen.',
    // ORIGINAL TEXT: The title you entered is too long.  Max is 60 characters.
    PASSWORDTOOSHORT:
      'Das eingegebene Passwort ist zu kurz. Passwort muss mindestens 6 Zeichen sein.',
    // ORIGINAL TEXT: The password you entered is too short.  Password has to be at least 6 characters.
    CONGRATSNEWDOCUMENTPROCESSING:
      'Toll! Sie haben ein neues Dokument bearbeitet. Es dauert ein paar Minuten und das Panel sollte aktualisieren, wenn das Dokument abgeschlossen ist. Bitte warten Sie ein paar Minuten.',
    // ORIGINAL TEXT: Great! You have processed a new document.  It takes a few minutes and the panel should refresh when the document is completed processing.  Please wait a few minutes.

    // ADDED
    NODOCUMENTLOADED: 'Kein Dokument geladen',
    // ORIGINAL TEXT: No document loaded
    SELECTFILETOUPLOADDOCX: 'Datei zum Hochladen auswählen (*.docx)',
    // ORIGINAL TEXT: select file to upload (*.docx)
    SELECTFILETOUPLOADDOCXPDF: 'Datei zum Hochladen auswählen (*.docx, *.pdf)',
    // ORIGINAL TEXT: select file to upload (*.docx, *.pdf)
    INPUTCOMMENTHERE: 'Eingabekommentar hier',
    // ORIGINAL TEXT: Input comment here
    AUTOAPPROVEASOWNER: 'Auto Approve als Eigentümer',
    // ORIGINAL TEXT: Auto Approve as Owner
    INPUTDOCEDITSHERE: 'Eingabedokument Bearbeiten hier',
    // ORIGINAL TEXT: Input Document Edit here
    TRIGGEREDALLALERTS:
      'Auslösung aller (falls vorhanden) Warnungen für dieses doctype Dokument.',
    // ORIGINAL TEXT: Triggered all (if any) Alerts for this doctype document.
    QSINTROMESSAGE:
      'MyLegalEinstein analysiert sofort Ihre Verträge, Indexe nach rechtlichen Themen und ermöglicht side-by-side Vergleiche von Rechtssprache in einer kompletten Vertragskooperationsplattform.',
    // ORIGINAL TEXT: MyLegalEinstein instantly analyzes your contracts, indexes by legal topics, and allows side-by-side comparisons of legal language in a complete contract collaboration platform. We will demonstrate the capabilities in four simple steps (detailed instructions are provided in english but similar in all languages.)
    QSDOCMESSAGE:
      'Für Ihre Bequemlichkeit, hier sind einige öffentliche Aufträge, die Sie herunterladen und versuchen können:',
    // ORIGINAL TEXT: For your convenience, here are some public domain contracts that you can download and try:
    QSHELPMESSAGE:
      'Hier ein praktischer Link zur Produktdokumentation (mit wortgetreuer Version von Quick Start)',
    // ORIGINAL TEXT: Heres a handy link to product documentation (with wordy version of Quick Start)
    DOCUMENTFAILEDTOPROCESS:
      'Bei der Bearbeitung des Dokuments ist ein fataler Fehler aufgetreten. Bitte versuchen Sie es erneut. Falls das Problem anhält, kontaktieren Sie bitte den Kundendienst unter support@mylegaleeinstein.com.',
    // ORIGINAL TEXT: A fatal error occurred while processing the document.  Please try again.  If problem persists, please contact customer support at support@mylegaleinstein.com.

    // ADDED
    DOCUMENTSUCCESSCOPIED: 'Ihr Dokument wurde nun kopiert.',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      'Eine saubere Version dieses Dokuments wurde nun generiert und gespeichert.',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      'Aus dieser Vorlage wurde ein Dokument generiert und gespeichert.',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE:
      'Ein Dokument wurde in eine Dokumentvorlage umgewandelt.',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      'Bitte bestätigen Sie, dass Sie das angegebene Dokument löschen möchten. Nachdem das Dokument gelöscht wurde, steht es nicht mehr zur Verfügung. Sie können auch erwägen, das Dokument in den Archivstatus zu verschieben. \n\n Bitte bestätigen Sie den Löschvorgang.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified document.  After document is deleted, it will no longer be available.  You may also consider moving the document to Archive status.  \n\n Please confirm delete operation.
    CONFIRMSAVETOPLAYBOOK:
      'Bitte bestätigen Sie, dass dieses Dokument in ein Playbook konvertiert werden soll. Dies würde dieses Dokument außer für den Eigentümer nicht änderbar  machen. \n\n Bitte bestätigen Sie das Speichern in den Playbook-Betrieb.',
    // ORIGINAL TEXT: Please confirm that to convert this document to a playbook.  This would make this document unchangable except for the owner.  \n\n Please confirm the save to playbook operation.
    COLLABORATIONNOTE:
      'HINWEIS: Diese Informationen werden nur INTERNALLY an bestimmte Mitarbeiter und Dokumentinhaber weitergegeben.',
    // ORIGINAL TEXT: NOTE: This information is shared INTERNALLY ONLY with designated collaborators and document owners only.
    NEGOTIATIONNOTE:
      'HINWEIS: Diese Informationen werden EXTERNALLY mit Verhandlungspartnern geteilt.',
    // ORIGINAL TEXT: NOTE: This information is shared EXTERNALLY with negotiation counter parties.
    CONFIRMDELETEALERT:
      'Bitte bestätigen Sie, dass Sie die angegebene Ausschreibung löschen möchten. Nach der Löschung wird diese nicht mehr verfügbar sein. Bitte bestätigen Sie den Löschvorgang.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified alert.  After alert is deleted, it will no longer be available.  Please confirm delete operation.
    CONFIRMDELETERECIPIENTALERT:
      'Bitte bestätigen Sie, dass Sie nicht mehr Empfänger für die angegebene Ausschreibung sein möchten. Nachdem Sie sich als Empfänger entfernt haben, können Sie verlangen, durch Kontaktaufnahme mit dem Besitzer der Ausschreibung wieder aufgenommen zu werden. Bitte bestätigen Sie die Löschung.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.
    CONFIRMDELETEGROUP:
      'Bitte bestätigen Sie, dass Sie die angegebene Gruppe löschen möchten. Nachdem die Gruppe gelöscht wurde, steht sie nicht mehr zur Verfügung. Sie spiegelt sich jedoch beim nächsten Login wieder. Bitte bestätigen Sie den Löschvorgang.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified group.  After group is deleted, it will no longer be available.  However, it will reflected the next time you log back in.  Please confirm delete operation.
    CONFIRMDELETECOLLABORATORGROUP:
      'Bitte bestätigen Sie, dass Sie kein Mitarbeiter für die angegebene Gruppe mehr sein möchten. Nachdem Sie sich als Mitarbeiter entfernt haben, können Sie verlangen, durch Kontaktaufnahme mit dem Eigentümer der Gruppe wieder aufgenommen zu werden. Bitte bestätigen Sie den Löschvorgang.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a collaborator for the specified group.  After removing yourself as a collaborator, you can request to be added back by contacting the owner of the group.  Please confirm delete operation.
    CONFIRMDELETEDOCTYPE:
      'Bitte bestätigen Sie, dass Sie den angegebenen doctype löschen möchten. Nachdem doctype gelöscht wurde, steht dieser nicht mehr zur Verfügung. Bitte bestätigen Sie den Löschvorgang.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified doctype.  After doctype is deleted, it will no longer be available.  Please confirm delete operation.
    EXPIREDTRIAL:
      'Ihre Testlizenz ist abgelaufen. Bitte aktualisieren Sie, um Ihren Service fortzusetzen.',
    // ORIGINAL TEXT: Your trial license has expired.  Please upgrade to continue your service.
    EXPIREDSUBSCRIPTION:
      'Ihre Abonnement-Lizenz ist abgelaufen. Bitte aktualisieren Sie Ihren Service oder kontaktieren Sie die Abrechnung unter Docgility.',
    // ORIGINAL TEXT: Your subscription license has expired.  Please upgrade to continue your service or contact billing at Docgility.
    SOURCEDOCNOTDOCX:
      'Das Quelldokument ist kein DOCX-Dokument. Redline-Funktionen sind nicht verfügbar.',
    // ORIGINAL TEXT: The Source document is not a DOCX document.  Redline capabilities are not available.
  },
}

export default DE
