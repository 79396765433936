import DocumentProcessingService from '@services/DocumentProcessingService'

import store from '@state/store'

import { DBgetobjsync } from '@/src/state/io/iodb.js'

const TABLENAME = 'sigrequests'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  // Adding the services for Signing to panels
  async signNewRequest({ commit }, args = {}) {
    // Upload the document (sync call, wait for job id to come back)

    const { data } = await DocumentProcessingService.signNewRequest({
      docid: args.docid,
      uniqid: args.uniqid,
      doctitle: args.doctitle,
      docsubject: args.docsubject,
      docmessage: args.docmessage,
      docsigner1name: args.docsigner1name,
      docsigner1email: args.docsigner1email,
      docsigner2name: args.docsigner2name,
      docsigner2email: args.docsigner2email,
      dirname: args.dirname,
    })

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: args.docid,
      uniqid: args.uniqid,
      logdetail: {
        action: 'DOCEVENTS.NEWSIGNATUREREQUEST',
        detail: args.doctitle,
        context: '',
        Z5: true,
        // Z5 will be the count of the number of signature requests from user.
      },
    })

    return data.job_id
  },

  // Adding the services for Signing to panels
  async signGetRequestStatus({ commit }, args = {}) {
    // Upload the document (sync call, wait for job id to come back)

    DocumentProcessingService.signGetRequestStatus({
      sigrequestid: args.sigrequestid,
    })
  },

  async signCancelRequest({ commit }, args = {}) {
    DocumentProcessingService.signCancelRequest({
      docid: args.docid,
      uniqid: args.uniqid,
      sigrequestid: args.sigrequestid,
    })

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: args.docid,
      uniqid: args.uniqid,
      logdetail: {
        action: 'DOCEVENTS.CANCELSIGNATUREREQUEST',
        detail: args.doctitle,
        context: '',
      },
    })
  },
  // SYNCHRONOUS
  // gets document and returns it
  signGetRequestSig({ commit, state }, payload) {
    return new Promise(function(resolve, reject) {
      DBgetobjsync(TABLENAME, payload.docid)
        .then(function(doc) {
          if (doc.exists) {
            resolve(doc)
          } else {
            resolve(false)
          }
        })
        .catch(function(e) {
          store.dispatch('dberror/logError', e)
        })
    })
  },
}
