import AIhttpService from './AIHttpService'

class AIApiService {
  constructor() {
    this.http = AIhttpService
    this.apiClient = AIhttpService.client
  }
}

export default AIApiService
