<script>
import { getNegotiationOptions } from '@utils/workflowMapping'
import { docroute } from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },

    // FYI - Need to pass each field in individually - VuejS objects to mutability of objects and arrays.
    negotiationstatus: {
      type: String,
      required: true,
    },
    negotiationend: {
      type: String,
      required: true,
    },
    negotiationrules: {
      type: Array,
      required: true,
    },
  },
  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  data: function() {
    return {
      lnegotiationstatus: this.negotiationstatus,
      lnegotiationend: this.negotiationend,
      lnegotiationrules: this.negotiationrules,
      lselected: this.negotiationrules,

      FOREVER: 'NONE',
      errormsg: '',

      noenddate: true,
      // get global lists of options
      negotiationOptions: getNegotiationOptions(),

      // options for collaboration
      allowscomments: 'ALLOWCOMMENTS' in this.negotiationrules,
      showapprovedcommentsonly:
        'SHOWAPPROVEDCOMMENTSONLY' in this.negotiationrules,
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
    compileOptions() {
      let returnOptions = []
      var i
      for (i = 0; i < this.lselected.length; i++) {
        if (this.lselected[i]) {
          returnOptions.push(this.negotiationOptions[i])
        }
      }
      return returnOptions
    },
  },

  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
  },
  methods: {
    setInitialValues() {
      this.lnegotiationstatus = this.negotiationstatus
      // this.lnegotiationend = this.negotiationend

      this.lnegotiationend =
        this.negotiationend === this.FOREVER ? '' : this.negotiationend
      this.noenddate =
        this.negotiationend === this.FOREVER || this.negotiationend === ''

      // for defaults, if specified in the doctype, use those instead.
      if (this.localpanelstate.ifDocType) {
        this.lnegotiationrules = this.localpanelstate.docTypeDetailofDoc.negotiationrules
      } else {
        this.lnegotiationrules = this.negotiationrules
      }
    },
    setNullValues() {
      // reset all the local forms for the next time it's accessed.
      this.lnegotiationstatus = 'CLOSED'
      this.lnegotiatonend = ''
      this.noenddate = false
      if (this.localpanelstate.ifDocType) {
        this.lnegotiationrules = this.localpanelstate.docTypeDetailofDoc.negotiationrules
      } else {
        this.lnegotiationrules = this.negotiationrules
      }
    },
    saveNegotiationCheck() {
      if (this.localpanelstate.ifArchivedState) {
        this.errormsg = this.$t('DIALOGS.NONEGOTWHENAPPROVED')
      } else {
        this.saveNegotiation()
      }
    },

    saveNegotiation() {
      const formdata = {
        negotiationstatus: this.lnegotiationstatus,
        negotiationend: this.noenddate ? this.FOREVER : this.lnegotiationend,
        negotiationrules: this.compileOptions,
      }
      this.$emit('save-negotiation', formdata)
    },
    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <PanelCardTitle :cardtitle="$t('DIALOGS.NEGOTIATION')"></PanelCardTitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="5" sm="5" class="py-2">
                <p>{{ $t('DIALOGS.NEGOTIATIONSETTING') }}</p>

                <v-btn-toggle
                  v-model="lnegotiationstatus"
                  tile
                  color="red"
                  group
                >
                  <v-btn value="CLOSED">
                    {{ $t('DIALOGS.CLOSED') }}
                  </v-btn>
                  <v-btn value="OPEN">
                    {{ $t('DIALOGS.OPEN') }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col v-if="lnegotiationstatus === 'OPEN'" cols="7" sm="7">
                <p> {{ $t('DIALOGS.NEGOTIATIONEND') }}: </p>
                <v-checkbox v-model="noenddate" label="No End Date">
                </v-checkbox>
                <v-date-picker
                  v-if="!noenddate"
                  v-model="lnegotiationend"
                  color="deep-orange accent-4"
                ></v-date-picker>
              </v-col>
              <v-col cols="12">
                <div
                  v-if="
                    lnegotiationstatus === 'OPEN' &&
                      localpanelstate.ifCorporateState
                  "
                >
                  <v-layout row wrap>
                    <v-flex
                      v-for="(option, item) in negotiationOptions"
                      :key="option"
                      xs4
                    >
                      <v-checkbox
                        v-model="lselected[item]"
                        light
                        :label="option"
                      >
                      </v-checkbox>
                    </v-flex>
                  </v-layout>
                </div>
              </v-col>
            </v-row>
            {{ errormsg }}
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('act/negotiation')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn large outlined class="mx-4" @click="saveNegotiationCheck">{{
            $t('DIALOGS.SAVE')
          }}</v-btn>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
