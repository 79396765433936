<script>
import { dbauthComputed } from '@state/helpers'

import { docroute } from '@utils/dialogutils'

// https://vuejsfeed.com/blog/vue-js-wrapper-for-vimeo-embed-player
import { vueVimeoPlayer } from 'vue-vimeo-player'
import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { vueVimeoPlayer, PanelCardTitle },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data: function() {
    return {
      videoID: '510933333',
      height: 5000,
      options: {},
      playerReady: false,
      ltopic: {
        title: 'How to Navigate My Legal  Einstein',
        id: '614211902',
        note: 'descr2',
      },

      titlelist: [
        {
          title: '1. How to Navigate My Legal  Einstein',
          id: '614211902',
          note: 'descr2',
        },
        {
          title: '2. How to Navigate and Control Panels',
          id: '614212027',
          note: 'descr2',
        },
        {
          title: '3. How to Process a New Document',
          id: '614212072',
          note: 'descr2',
        },
        {
          title: '4. How to Navigate by Topics and Deep-Dive',
          id: '614212096',
          note: 'descr2',
        },
        {
          title: '5. How to Process Non-English Contracts',
          id: '614212185',
          note: 'descr2',
        },
        {
          title: '6. How to Use Sync to Compare Legal Topics',
          id: '614212266',
          note: 'descr2',
        },
        {
          title: '7. How to Move Contract to Archive Contracts',
          id: '630201397',
          note: 'descr2',
        },
        {
          title: '8. How to Comment on Document And Access Notes',
          id: '630207910',
          note: 'descr2',
        },
        {
          title: '9. How to Suggest Document Edits',
          id: '630208574',
          note: 'descr2',
        },
        {
          title: '10. How to Mark Sentences with Labels',
          id: '634771645',
          note: 'descr2',
        },

        {
          title: '11. How to Adjust Clause Labels for Paragraph',
          id: '630209375',
          note: 'descr2',
        },
        {
          title: '12. How to Download Redlined DocX',
          id: '630201463',
          note: 'descr2',
        },
        {
          title: '13. How to Generate Excel Export',
          id: '630205590',
          note: 'descr2',
        },
        {
          title: '14. How to Create Clean Versions',
          id: '643980679',
          note: 'descr2',
        },
        {
          title: '15. How to Review and Approve Doc Edits',
          id: '634771097',
          note: 'descr3',
        },
        {
          title: '16. How to Negotiate Contracts',
          id: '634771908',
          note: 'descr4',
        },

        {
          title: '17. How to Send for eSignatures',
          id: '630201536',
          note: 'descr4',
        },
      ],
    }
  },

  computed: {
    ...dbauthComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
        this.pause()
      },
    },
  },

  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
    ltopic(newValue, oldValue) {
      if (this.playReady) {
        this.play()
      }
    },
    playerReady(newValue, oldValue) {
      if (newValue) {
        this.play()
      }
    },
  },
  methods: {
    setInitialValues() {
      // this.ltopic = { title: 'quickstart', id: '517547128', note: 'descr' }
      // this.ltopic = this.titlelist.find(
      //   (element) => element.title === this.topic
      // )
      this.play()
    },

    setTopic(item) {
      this.ltopic = item
      this.play()
    },

    // relay
    docroute(d) {
      return docroute(d)
    },

    // vimeo methods
    onReady() {
      this.playerReady = true
    },
    play() {
      if (this.$refs.player !== null && this.$refs.player !== undefined) {
        this.$refs.player.play()
      }
    },
    pause() {
      this.$refs.player.pause()
    },
    update(videoID) {
      this.$refs.player.update(videoID)
    },
    mute() {
      this.$refs.player.mute()
    },
    unmute() {
      this.$refs.player.unmute()
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <PanelCardTitle
        :cardtitle="$t('DIALOGS.TUTORIALVIDEOS')"
      ></PanelCardTitle>
      <v-container
        ><v-row>
          <v-col cols="12" sm="9">
            <vueVimeoPlayer
              ref="player"
              :video-id="ltopic.id"
              :player-width="800"
              :player-height="600"
              @ready="onReady"
            >
            </vueVimeoPlayer>
          </v-col>
          <v-col cols="12" sm="3">
            <div>
              <h5>AVAILABLE VIDEOS: </h5>
              <v-list>
                <v-list-item v-for="item in titlelist" :key="item.id">
                  <v-btn
                    small
                    outlined
                    :class="{
                      'blue lighten-2': ltopic.id === item.id,
                      'text-none': true,
                    }"
                    @click="setTopic(item)"
                  >
                    {{ item.title }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </div>
            {{ $t('DIALOGS.MOREVIDEOSAT') }}
            <a href="https://www.mylegaleinstein.com/learn">Learning Center</a>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="justify-end">
        <v-btn icon @click="docroute('')">
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn large outlined class="mx-8" @click="play">
          PLAY <v-icon>mdi-play</v-icon>
        </v-btn>
        <v-btn large outlined class="mx-8" @click="pause">
          STOP <v-icon>mdi-stop</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          large
          outlined
          class="mx-4 grey lighten-2"
          @click="showDialog = false"
          >{{ $t('DIALOGS.BACK') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" module>
@import '@design';
</style>
