<script>
import { panelComputed, dbauthComputed, dbtagComputed } from '@state/helpers'

import {
  splitSentences,
  formatDateDialog,
  statusIndicator,
  displayUserName,
  displayUserInfo,
  docroute,
} from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

// for now, assume that taglist is for default and only contains high and low risk.
// const taglist = ['highrisk', 'lowrisk']

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    originaltext: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    showDeleted: false,
  }),
  computed: {
    ...panelComputed,
    ...dbauthComputed,

    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },

    alltags() {
      if (
        Array.isArray(this.editTags[this.panelno]) &&
        this.editTags[this.panelno].length > 0
      ) {
        if (this.showDeleted) {
          return this.editTags[this.panelno]
        } else {
          return this.editTags[this.panelno].filter((e) => e.active === true)
        }
      } else {
        return []
      }
    },

    sortedtags() {
      let temparray = this.alltags
      let returnarray = temparray.sort(function(a, b) {
        if (parseInt(a.paraid) > parseInt(b.paraid)) {
          return 1
        } else if (parseInt(a.paraid) < parseInt(b.paraid)) {
          return -1
        } else if (parseInt(a.paraid) === parseInt(b.paraid)) {
          return a.create > b.create ? 1 : -1
        } else {
          return 0
        }
      })
      return returnarray
    },
  },
  methods: {
    ...dbtagComputed,

    setparagraphhighlight(index) {
      this.$emit('processnavall', index)
      this.$emit('close-dialog')
      // this unsyncs panel, sets topic to ALL, sets paragraph highlight
    },

    // these functions call the utils - not sure why it's not picking up immediately.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    statusIndicator(bool) {
      return statusIndicator(bool)
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },
    docroute(d) {
      return docroute(d)
    },

    sentenceContext(paraid, sentid) {
      // check for null condition
      if (this.docRawData[this.panelno] === null) {
        return []
      }
      if (
        this.originaltext[paraid] === null ||
        this.originaltext[paraid] === undefined
      ) {
        return ''
      }

      let paratext = this.originaltext[paraid].OT
      let sentences = splitSentences(paratext)

      return sentences.find((e) => e.key === parseInt(sentid)).sentence
      // return sentid
    },
  },
}
</script>

<template>
  <v-row>
    <v-dialog
      v-model="showDialog"
      persistent
      :content-class="$style.dialogWidth"
    >
      <v-card>
        <PanelCardTitle :cardtitle="$t('DIALOGS.LABELSRISK')"></PanelCardTitle>
        <div>
          <v-form>
            <v-container>
              <v-row>
                <v-simple-table :class="$style.dialogTable" fixed-header>
                  <template>
                    <thead :class="$style.dialogHead">
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.PARAGRAPHID') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.SENTENCE') }}</th
                        >
                        <th style="font-size:16px" :class="$style.dialogTags">{{
                          $t('DIALOGS.LABELSRISK')
                        }}</th>
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.TIME') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellDelete"
                          >{{ $t('DIALOGS.USER') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.STATUS') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in sortedtags"
                        :key="item.key"
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellWidth"
                          ><v-btn
                            x-small
                            text
                            outlined
                            class="py-5"
                            @click="setparagraphhighlight(item.paraid)"
                            >{{ item.paraid }}</v-btn
                          ></td
                        >
                        <td :class="$style.dialogCellStatus">{{
                          sentenceContext(item.paraid, item.sentid)
                        }}</td>

                        <td :class="$style.dialogTags">
                          {{ item.tag }}
                        </td>
                        <td :class="$style.dialogCellWidth">{{
                          formatDateDialog(item.create)
                        }}</td>
                        <td :class="$style.dialogCellDelete">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">{{
                                displayUserName(item.uniqid)
                              }}</span>
                            </template>
                            <span>{{ displayUserInfo(item.uniqid) }}</span>
                          </v-tooltip>
                        </td>
                        <td :class="$style.dialogCellStatus">
                          <v-icon
                            large
                            color="statusIndicator(item.active).color"
                            >{{ statusIndicator(item.active).icon }}</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('edit/collabriskandlabels')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <div class="mx-5">
            {{ $t('INFO_MESSAGES.COLLABORATIONNOTE') }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
