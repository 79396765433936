<script>
import { docroute } from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    availKeys: {
      type: Array,
      required: true,
    },
  },
  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  data: function() {
    return {
      numdef: 0,
      valid: false,

      pararules: [
        (v) => !!v || this.$t('VALIDMSGS.PARAINDEXREQUIRED'),
        (v) =>
          (!isNaN(parseFloat(v)) && v >= 0 && v <= this.highestpara) ||
          this.$t('VALIDMSGS.PARAINDEXINVALID'),
      ],
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
        this.email = ''
      },
    },
    highestpara() {
      return this.availKeys.length - 1
    },
  },
  methods: {
    gotopara() {
      if (this.valid) {
        this.$emit('gotopara', this.numdef)
      }
    },
    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.GOTOPARAGRAPH')"
        ></PanelCardTitle>
        <div class="mx-4">
          {{ $t('DIALOGS.ENTERPARAGRAPHBETWEEN') }} {{ availKeys[0] }} ...
          {{ highestpara }}

          <v-col cols="10" sm="10">
            <v-form v-model="valid">
              <v-text-field
                v-model="numdef"
                class="mx-4"
                :rules="pararules"
                :label="$t('DIALOGS.GOTOPARAGRAPHINDEX')"
              ></v-text-field>
            </v-form>
          </v-col>
        </div>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('edit/gotoparagraph')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer
          ><v-btn large outlined class="mx-4" @click="gotopara">{{
            $t('DIALOGS.GO')
          }}</v-btn>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
