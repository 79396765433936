import store from '@state/store'

import { DBinsertonesync, DBdeactivate } from '@/src/state/io/iodb.js'

import {
  DBHUBadddocchange,
  DBHUBdeletedocchange,
} from '@/src/state/io/iohub.js'

const TABLENAME = 'tags'

export const state = {
  // debug
  dbtagid: 0,
}

export const getters = {
  dbtagid(state) {
    return state.dbtagid
  },
}

export const mutations = {
  SET_ID(state, id) {
    state.dbtagid = id
  },
}

export const actions = {
  init({ commit }) {},

  writeTag({ commit, state }, payload) {
    var writeTagEx = {
      docid: payload.docid,
      paraid: payload.paraid,
      tag: payload.tag,
      sentid: payload.sentid,
      senttext: payload.senttext,
      uniqid: payload.uniqid,
      active: payload.active,
      create: new Date(),
      delete: '',
    }

    var returnval

    if (payload.ifHUBType) {
      returnval = DBHUBadddocchange(TABLENAME, writeTagEx)
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.ADDTAG',
          detail: payload.tag,
          context:
            'docid:' + payload.docid + ',' + 'paragraph:' + payload.paraid,
        },
      })
    } else {
      returnval = DBinsertonesync(TABLENAME, writeTagEx)

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.ADDTAG',
          detail: payload.tag,
          context: 'paragraph:' + payload.paraid,
        },
      })
    }
    return returnval
  },

  deleteTag({ commit, state }, payload) {
    if (payload.ifHUBType) {
      DBHUBdeletedocchange(TABLENAME, payload, payload.tagid)
      // log
      store.dispatch('dblogdoc/addLogUserOnly', {
        uniqid: payload.uniqid,
        logdetail: {
          action: 'HUB.DOCEVENTS.DELETETAG',
          detail: payload.tagid,
          context: 'docid:' + payload.docid,
        },
      })
    } else {
      DBdeactivate(TABLENAME, payload.tagid)

      // log
      store.dispatch('dblogdoc/addLogDoc', {
        docid: payload.docid,
        uniqid: payload.uniqid,
        logdetail: {
          action: 'DOCEVENTS.DELETETAG',
          detail: payload.tagid,
          context: '',
        },
      })
    }
  },
}
