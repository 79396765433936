<script>
export default {
  props: {
    panelno: {
      type: Number,
      default: () => -1,
    },
    ind: {
      type: Number,
      default: () => -1,
    },
    compressed: {
      type: Boolean,
      required: true,
    },
    englishtranslate: {
      type: Boolean,
      required: true,
    },
    doctranslation: {
      type: Object,
      required: true,
    },
    originaltext: {
      type: Object,
      required: true,
    },
    showcollaborativeedits: {
      type: Boolean,
      required: true,
    },
    shownegotiationedits: {
      type: Boolean,
      required: true,
    },
    docedits: {
      type: Array,
      required: true,
    },
    lsearchon: {
      type: Boolean,
      required: true,
    },
    lsearchcapson: {
      type: Boolean,
      required: true,
    },
    lsearchtext: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    showMenu: false,
  }),
  computed: {
    // ...panelComputed,

    approvedDocEdits() {
      var returnedEdits = this.docedits
      if (this.showcollaborativeedits) {
        returnedEdits = returnedEdits.filter(
          (e) =>
            e.approved &&
            // e.negotiatorapproved &&
            e.delete === '' &&
            e.paraid === this.ind.toString()
        )
      }
      if (this.shownegotiationedits) {
        returnedEdits = returnedEdits.filter(
          (e) =>
            e.approved &&
            e.negotiatorapproved &&
            e.delete === '' &&
            e.paraid === this.ind.toString()
        )
      }

      // if nothing turned on, return empty.
      if (!this.showcollaborativeedits) {
        return []
      }

      return returnedEdits
    },

    rawtext() {
      if (
        this.originaltext === null ||
        this.originaltext === undefined ||
        this.originaltext[this.ind] === undefined
      ) {
        return ''
      }

      var filtertabsouttext = this.originaltext[this.ind].OT

      // }

      if (
        this.compressed &&
        filtertabsouttext.length > 320 &&
        this.approvedDocEdits.length === 0
      ) {
        // first 300 characters with whole words + extender
        return filtertabsouttext.replace(/^(.{300}[^\s]*).*/, '$1') + ' ...'
      }
      return filtertabsouttext
    },

    origtext() {
      if (
        this.originaltext !== null &&
        this.originaltext !== undefined &&
        this.originaltext[this.ind] !== undefined
      ) {
        return this.originaltext[this.ind].OT
      }
      return ''
    },

    text() {
      if (this.englishtranslate) {
        // null check
        if (
          this.doctranslation !== null &&
          this.doctranslation !== undefined &&
          this.doctranslation[this.ind] !== undefined
        ) {
          return this.doctranslation[this.ind].TT
        }
        return ''
      }

      var newtext = this.rawtext
      for (var index = 0; index < this.approvedDocEdits.length; index++) {
        var docchange = this.approvedDocEdits[index]

        const accentcolor = docchange.negotiatorapproved ? 'green' : 'blue'

        if (docchange.docedittype === 'REPLACE') {
          newtext = newtext.replace(
            docchange.senttext,
            '<font color="red"><del>' +
              docchange.senttext +
              '</del></font>' +
              '<font color="' +
              accentcolor +
              '">' +
              docchange.docedittext +
              '</font>'
          )
        } else if (docchange.docedittype === 'DELETE') {
          newtext = newtext.replace(
            docchange.senttext,
            '<font color="' +
              accentcolor +
              '"><del>' +
              docchange.senttext +
              '</del></font>'
          )
        } else if (docchange.docedittype === 'ADD-AFTER') {
          newtext = newtext.replace(
            docchange.senttext,
            docchange.senttext +
              '<font color="' +
              accentcolor +
              '">' +
              docchange.docedittext +
              '</font>'
          )
          // make the substitutions in the text.
        } else {
          this.$store.dispatch(
            'dberror/logError',
            'error detected when applying document changes.'
          )
        }
      }

      return newtext
    },

    // does additional postprocessing to highlight the search text if that's on.
    textwithsearch() {
      if (!this.lsearchon) {
        return this.text
      }
      if (this.lsearchcapson) {
        return this.text.replace(
          this.lsearchtext,
          '<font color="red">' + this.lsearchtext + '</font>'
        )
      }
      // case insensitive search
      const re = new RegExp(this.lsearchtext, 'gi')

      return this.text.replace(re, '<font color="red">$&</font>')
    },
  },

  methods: {
    setLocalParaContext() {
      this.$emit('setLocalParaContext', this.originaltext[this.ind])
    },

  },
}
// <del> test </del>
</script>

<template text-align: left center vertical-align: middle>
  <div :class="$style.clauseText" @click.stop="setLocalParaContext">
    <!-- eslint-disable-next-line -->
    <span @click="setLocalParaContext" v-html="textwithsearch">
      <!-- <div v-html="text"></div> -->
    </span>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
