<script>
// state
import { panelComputed } from '@state/helpers'

export default {
  components: {},

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      // showClauseIndicator: true, // show the Clause Indicators (should be excluded if it's A)
    }
  },

  computed: {
    ...panelComputed,
    text() {
      return 'huh'
    },
    ottype() {
      return this.item.result === 'addedOT'
    },
    cttype() {
      return this.item.result === 'addedCT'
    },
  },

  watch: {},
  methods: {
    // test
    gotoParaOT(para) {
      this.$emit('compareGotoParaOT', para)
    },
    gotoParaCT(para) {
      this.$emit('compareGotoParaCT', para)
    },
  },
}
</script>

<template>
  <div :class="$style.paragraph">
    <v-container v-if="true" class="container">
      <v-layout row>
        <v-flex xs2 md2 lg2 xl2>
          <v-btn
            v-if="ottype"
            class="mx-3"
            small
            @click="gotoParaOT(item.otpara)"
          >
            {{ item.otpara }} ---
          </v-btn>
        </v-flex>
        <v-flex xs8 md8 lg8 xl8>
          <div :class="$style.clauseText">
            <span>{{ item.text }} </span>
          </div>
        </v-flex>
        <v-flex xs2 md2 lg2 xl2>
          <v-btn
            v-if="cttype"
            class="mx-3"
            small
            @click="gotoParaCT(item.ctpara)"
          >
            {{ item.ctpara }} +++
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
