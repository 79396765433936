// Contains all application constants //

export const TR = {
  TOPMENU: {
    FILE: 'FILE',
    EDIT: 'EDIT',
    ACT: 'ACT',
    DOCS: 'DOCS',
  },

  MENU: {
    NEWDOCUMENT: 'Жаңа құжат',
    // ORIGINAL TEXT: New Document
    SAVEDOCUMENT: 'Құжатты сақтау',
    // ORIGINAL TEXT: Save Document
    COPYDOCUMENT: 'Құжатты nusxa olish',
    // ORIGINAL TEXT: Copy Document
    CREATEVERSION: 'Temiz Sürüm Oluştur',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: 'Create Doc from Template',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: 'Құжатты Aç',
    // ORIGINAL TEXT: Open Document
    OPENGROUPDOCUMENT: 'Құжатты ашу',
    // ORIGINAL TEXT: Open Group Document
    OPENHUBDOCUMENT: 'Құжатты HUB Aç',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: 'arxivni Aç',
    // ORIGINAL TEXT: Open Archive Document
    OPENPLAYBOOK: 'Atributni ochish',
    // ORIGINAL TEXT: Open Playbook
    OPENTEMPLATE: 'Open Template',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: 'Save As Template',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: 'Ойноту sifatida saqlash',
    // ORIGINAL TEXT: Save As Playbook
    MOVETOARCHIVE: 'Архивтер',
    // ORIGINAL TEXT: Move to Archive
    CLOSEDOCUMENT: 'Құжатты жабу',
    // ORIGINAL TEXT: Close Document
    DOCUMENTLOG: 'Құжат журналы',
    // ORIGINAL TEXT: Document Log

    GOTOPARAGRAPH: 'Абзацқа өту',
    // ORIGINAL TEXT: Go to Paragraph
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: 'Құжаттар',
    // ORIGINAL TEXT: Document Notes
    COLLABCOMMENTS: 'Конфигурация - аралық',
    // ORIGINAL TEXT: Collaboration - Comments
    COLLABDOCEDITS: 'Collaborma - Құжаттың düzenleру',
    // ORIGINAL TEXT: Collaboration - Document Edits
    COLLABRISKANDLABELS: 'Talakan - Taskavuri ve etiket.',
    // ORIGINAL TEXT: Collaboration - Risk and Labels
    NEGOTCOMMENTS: 'Аяқтамалары - түсініктеме',
    // ORIGINAL TEXT: Negotiation - Comments
    NEGOTDOCEDITS: 'Актерация - құжаттың düzenleулары',
    // ORIGINAL TEXT: Negotiation - Document Edits

    COLLABORATION: 'Talaqa',
    // ORIGINAL TEXT: Collaboration
    NEGOTIATION: 'гүя',
    // ORIGINAL TEXT: Negotiation
    ADDOWNER: 'Egasini қосу',
    // ORIGINAL TEXT: Add Owner
    ADDCOLLABORATOR: 'Talaqa',
    // ORIGINAL TEXT: Add Collaborator
    ADDNEGOTIATOR: 'Negodratheader қосу',
    // ORIGINAL TEXT: Add Negotiator
    TRIGGERALERTS: 'Belllikler',
    // ORIGINAL TEXT: Trigger Alerts
    EXPORTEXCEL: 'Excel eksport',
    // ORIGINAL TEXT: Export Excel
    DOWNLOADSOURCE: 'Yozib olish davom ettrildi',
    // ORIGINAL TEXT: Download Source
    SENDTOCLM: 'CLM-қа жіберу',
    // ORIGINAL TEXT: Send to CLM
    EXPORTJSON: 'JSON экспорттау',
    // ORIGINAL TEXT: Export JSON
    ESIGNATURE: 'Template хат жөнөтілсін',
    // ORIGINAL TEXT: Send for Esignature
    DOWNLOADREDLINE: 'Redc DocX indir',
    // ORIGINAL TEXT: Download Redline DocX
    DOCUMENTSETTINGS: 'Құжаттың параметрлері',
    // ORIGINAL TEXT: Document Settings
    EXPAND: 'Yoyish',
    // ORIGINAL TEXT: Expand
    COLLAPSE: 'бүктеу',
    // ORIGINAL TEXT: Collapse
    TRANSLATETOENGLISH: 'Ingilizlere аудару',
    // ORIGINAL TEXT: Translate to English
    ORIGINALLANGUAGE: 'Özgünden başlap, aslynda tiklash',
    // ORIGINAL TEXT: Revert to Original

    ALLPARAGRAPHS: 'Hamma параграф',
    // ORIGINAL TEXT: All Paragraphs
    EDITEDPARAGRAPHSONLY: 'Diňe bir параграфlarni өңдеу',
    // ORIGINAL TEXT: Edited Paragraphs Only

    NOEDITCLAUSELABELS: '2: 1 — 3, 12, 13). No 3',
    // ORIGINAL TEXT: No Edit Clause Labels
    EDITCLAUSELABELS: 'Claus белгилерді өңдеу',
    // ORIGINAL TEXT: Edit Clause Labels

    HIDEEDITS: 'Tahrirlash',
    // ORIGINAL TEXT: Hide Edits
    SHOWEDITS: 'Oxiri',
    // ORIGINAL TEXT: Show Edits

    SHOWALLEDITS: 'Hammasini koʻrsatish',
    // ORIGINAL TEXT: Show All Edits
    SHOWNEGOTEDITSONLY: 'Edit örnüklarini тек қана көрсету',
    // ORIGINAL TEXT: Show Negotiation Edits Only

    GROUPS: 'Топтар',
    // ORIGINAL TEXT: Groups
    DOCUMENTTYPES: 'Құжаттың түрі',
    // ORIGINAL TEXT: Document Types
    ALERTS: 'Gnotski alarm',
    // ORIGINAL TEXT: Alerts
    QUICKSTARTTUTORIAL: 'Comment',
    // ORIGINAL TEXT: Quick Start Tutorial
    QUICKSTARTVIDEOS: 'Тез видеоларды бастау',
    // ORIGINAL TEXT: Quick Start Videos
    UPGRADESUBSCRIPTION: 'Abunelik tasvir',
    // ORIGINAL TEXT: Upgrade Subscription
    SUBSCRIPTIONBILLING: 'Muvaffaqiyatli',
    // ORIGINAL TEXT: Subscription Billing
    ACCOUNTSETTINGS: '%s баптаулары',
    // ORIGINAL TEXT: Account Settings
    USERLOG: 'Пайдаланушы журналы',
    // ORIGINAL TEXT: User Log
    CHANGEPASSWORD: 'Maxfiy soʻz',
    // ORIGINAL TEXT: Change Password
    LOGOUT: 'Шығу',
    // ORIGINAL TEXT: Logout

    // HEADINGS
    ADMIN: 'ADMIN',
    // ORIGINAL TEXT: ADMIN
    PROFILE: 'FAYL',
    // ORIGINAL TEXT: PROFILE
    LPROFILE: 'Profil',
    // ORIGINAL TEXT: Profile
    FILE: 'FAYL',
    // ORIGINAL TEXT: FILE
    EDIT: 'WED',
    // ORIGINAL TEXT: EDIT
    ACT: 'TAPK',
    // ORIGINAL TEXT: ACT
    TOPIC: 'TIRC',
    // ORIGINAL TEXT: TOPIC
    DOCS: 'DOCS',
    // ORIGINAL TEXT: DOCS

    // Field Headings in Navigation Bar
    NAME: 'Аты',
    // ORIGINAL TEXT: Name
    ROLE: 'Rol',
    // ORIGINAL TEXT: Role
    STATE: 'Күйі',
    // ORIGINAL TEXT: State
    GROUP: 'Топ',
    // ORIGINAL TEXT: Group
    TYPE: 'Turi',
    // ORIGINAL TEXT: Type
    SENSIT: 'Tuhaflık',
    // ORIGINAL TEXT: Sensitivity
    LTOPIC: 'Нақыш',
    // ORIGINAL TEXT: Topic
    USER: 'Пайдаланушы',
    // ORIGINAL TEXT: User

    // Other Headings
    REGISTER: 'TRUE',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'LOGIN',
    // ORIGINAL TEXT: LOGIN
    LREGISTER: '_Devuble',
    // ORIGINAL TEXT: Register
    LLOGIN: 'Giriş',
    // ORIGINAL TEXT: Login

    ADMINEXPLAIN: 'Administration мүмкіндіктері',
    // ORIGINAL TEXT: Administrative capabilities
    PROFILEEXPLAIN: 'Abunelik Yönetimi, Abunelik Market',
    // ORIGINAL TEXT: Account Management, Subscriptions, Billing

    // AUTH LEVELS
    PREMIUM: 'Premium',
    // ORIGINAL TEXT: Premium
    PREMIUMPREPAID: 'Premium ( Premium)',
    // ORIGINAL TEXT: Premium (Prepaid)
    BASIC: 'Esasy',
    // ORIGINAL TEXT: Basic
    BASICPREPAID: 'Негізгі (kiçik)',
    // ORIGINAL TEXT: Basic (Prepaid)
    VIPACCESS: 'VIPS қатынау',
    // ORIGINAL TEXT: VIP Access
    VIPMEMBER: 'VIPS мүшеsi',
    // ORIGINAL TEXT: VIP Member
    UNLIMITED: 'Шектелмеген',
    // ORIGINAL TEXT: Unlimited
    TRIALUSER: 'Пайдаланушының пайдаланушысы',
    // ORIGINAL TEXT: Trial User
    REFERREDUSER: 'Хыянаттандыру пайдаланушы',
    // ORIGINAL TEXT: Referred User
    SOURCEDOCUMENT: 'Kaynak dosyası',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: 'Karşılaştırma Belgesi',
    // ORIGINAL TEXT: Comparison Document
    EXIT: 'ÇIKIŞ',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: 'PARAGRAPH',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: 'BELGE KARŞILAŞTIRMA MODU',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: 'Change',
    // ORIGINAL TEXT: NONE
    PREAMBLE: 'MALDBE',
    // ORIGINAL TEXT: PREAMBLE
    DEFN: 'ETНИКАХИ ПАРАПЕТ',
    // ORIGINAL TEXT: DEFINITION
    PAYMENT: 'ОКУЯЛЫШ',
    // ORIGINAL TEXT: PAYMENT
    TAXES: 'DEKASULLAR',
    // ORIGINAL TEXT: TAXES
    REPWARR: 'MÜQƏDDƏS KİTAB HER ÜÇIN EDÝÄR',
    // ORIGINAL TEXT: REPRESENTATION AND WARRANTIES
    PERIOD: 'EREROD',
    // ORIGINAL TEXT: PERIOD
    TERMINATION: 'TOERMI Zorluk',
    // ORIGINAL TEXT: TERMINATION
    CONFID: 'FAYDALANYŞI',
    // ORIGINAL TEXT: CONFIDENTIALITY
    INDEMNITY: 'INDEMINTY',
    // ORIGINAL TEXT: INDEMNITY
    LIABILITY: 'LİA BELLE',
    // ORIGINAL TEXT: LIABILITY
    INTELPROP: 'INTEKTUAL PROUP',
    // ORIGINAL TEXT: INTELLECTUAL PROP
    SIGNATURE: 'SIGABERSION',
    // ORIGINAL TEXT: SIGNATURE
    OTHER: 'YEKDEP',
    // ORIGINAL TEXT: OTHER
    DATASECURITY: 'DATA SECURTY',
    // ORIGINAL TEXT: DATA SECURITY
    ASSIGN: 'ASSIONN',
    // ORIGINAL TEXT: ASSIGNMENT
    NOTICE: 'NOTLANMA',
    // ORIGINAL TEXT: NOTICE
    GOVERNLAW: 'GÖZEMEK WE',
    // ORIGINAL TEXT: GOVERNING LAW
    DISPUTE: 'GÖCÜLÇ',
    // ORIGINAL TEXT: DISPUTE
    ENTIREAGREE: 'DANIÝEL ОРГАНИМДИЦИЯ',
    // ORIGINAL TEXT: ENTIRE AGREEMENT
    SURVIVAL: 'SIRAQLAR',
    // ORIGINAL TEXT: SURVIVAL
    RELATPART: 'АСАТТАРЫ БОЛОРНАТТАРЫConstellation name (optional)',
    // ORIGINAL TEXT: RELATION BETWEEN PARTIES
    THIRDPARTY: 'THIVRTY',
    // ORIGINAL TEXT: THIRD PARTY
    FORCEMAJEURE: 'БУШМАНЫҢ ҚОРЫНЫ',
    // ORIGINAL TEXT: FORCE MAJEURE
    SEVERABILITY: 'ОҢТҮСТІК ГОШТО КАНДАЙ К',
    // ORIGINAL TEXT: SEVERABILITY
    EXPORTCTRL: 'EXP ТАСҚАРУ',
    // ORIGINAL TEXT: EXPORT CONTROLS
    INTRO: 'INTERALETMEK',
    // ORIGINAL TEXT: INTRODUCTION
    INSURANCE: 'URIANIANI',
    // ORIGINAL TEXT: INSURANCE
    HEADING: 'БАШТАЛЫШЫ',
    // ORIGINAL TEXT: HEADING
    ALL: 'MODUL',
    // ORIGINAL TEXT: ALL
  },

  VALIDMSGS: {
    EMAILREQUIRED: 'E-poçta -%s',
    // ORIGINAL TEXT: E-mail is required
    EMAILVALID: 'E-poçtu hökmlü пулмалла.',
    // ORIGINAL TEXT: E-mail must be valid
    MAX20CHARS: '20 karakter xaric maf',
    // ORIGINAL TEXT: Max 20 characters
    MAX60CHARS: '60 belginiň jiddiy',
    // ORIGINAL TEXT: Max 60 characters
    MAX80CHARS: '80 karakterleriň maksimal soni',
    // ORIGINAL TEXT: Max 80 characters
    MAX160CHARS: '160 karakterin макс.',
    // ORIGINAL TEXT: Max 160 characters
    EMAILALREADYOWNER: 'Эл. пошта artıq бар ғой',
    // ORIGINAL TEXT: Email is already an owner
    EMAILALREADYCOLLABORATOR: 'Эл. пошта eýýäm bir хезмәттәше бар ғой',
    // ORIGINAL TEXT: Email is already a collaborator
    EMAILALREADYNEGOTIATOR: 'Email инде special otory',
    // ORIGINAL TEXT: Email is already a negotiator
    EMAILALREADYOWNERDOCTYPE: 'Құжаттың түрінде эл. поштаның иесі инде бар ғой',
    // ORIGINAL TEXT: Email is already an owner of the document type
    EMAILALREADYCOLLABORATORDOCTYPE:
      'Эл. пошта eýýäm құжаттың түрін колдогонdür.',
    // ORIGINAL TEXT: Email is already an collaborator of the document type
    REQUIRED: 'Керек.',
    // ORIGINAL TEXT: Required.
    CLAUSEFIELDREQUIRED: 'Bu операция uchun vositane compente toʻxtating',
    // ORIGINAL TEXT: This clause field is required for this operation
    CLAUSENOTDEFAULT: 'Andoza nupdatevy of the update',
    // ORIGINAL TEXT: Cannot add clause from default clause list
    TAGFIELDREQUIRED: 'Bu әрекет үшін teşəkkür topuly қажет',
    // ORIGINAL TEXT: This tag field is required for this operation
    TAGNOTDEFAULT:
      "URI' teg list' ga тег qoʻshish мүмкін емесSocket error code RemotelyDisconnect",
    // ORIGINAL TEXT: Cannot add tags from default tag list

    PARAINDEXREQUIRED: 'Абзацтың индексі қажет',
    // ORIGINAL TEXT: Paragraph index is required
    PARAINDEXINVALID: 'Абзац индексі жоқ',
    // ORIGINAL TEXT: Paragraph index does not exist
  },

  DOCEVENTS: {
    SAVEDOCUMENT: 'Құжатты сақтау',
    OPENDOCUMENT: 'östäl qısası',
    // ORIGINAL TEXT: open document
    CLOSEDOCUMENT: 'function',
    // ORIGINAL TEXT: close document
    ADDNOTE: 'Редактор',
    // ORIGINAL TEXT: add note
    ADDCOLLABORATOR: 'Комментарийді қосу',
    // ORIGINAL TEXT: add collaborator
    DELETECOLLABORATOR: 'Комментарийді өшіру',
    // ORIGINAL TEXT: delete collaborator
    ADDNEGOTIATOR: 'panord қосулыdictionary variant',
    // ORIGINAL TEXT: add negotiator
    DELETENEGOTIATOR: 'Convertory sil',
    // ORIGINAL TEXT: delete negotiator
    UPDATEARCHIVEDATA: 'arxiv maʼlumotін жаңарту',
    // ORIGINAL TEXT: update archive data
    UPDATEDOCMETA: 'Құжатты yangilash метадеректерi',
    // ORIGINAL TEXT: update document metadata
    ADDOWNER: 'минимум',
    // ORIGINAL TEXT: add owner
    DELETEOWNER: 'түбірді өшіру',
    // ORIGINAL TEXT: delete owner
    CHANGECOLLABORATION: 'иҫкесте статистика параметрлері',
    // ORIGINAL TEXT: changed collaboration settings
    CHANGENEGOTIATION: 'Klipart moslamalari өзгертілген@ info: whatsthis',
    // ORIGINAL TEXT: changed negotiation settings
    SAVEPLAYBOOK: 'oynanan intoolbar',
    // ORIGINAL TEXT: saved as playbook
    SAVETEMPLATE: 'saved as template',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: 'сакталганdictionary variant',
    // ORIGINAL TEXT: saved as archived
    TRIGGERALERT: 'ескерту',
    // ORIGINAL TEXT: trigger alert
    TRIGGERALLALERT: 'пур тактала кооптуулар',
    // ORIGINAL TEXT: trigger all alerts
    TRIGGERCUSTOMALERT: 'шартты үнділігі',
    // ORIGINAL TEXT: trigger custom alert
    ADDCOMMENT: 'Пайымдама',
    // ORIGINAL TEXT: add comment
    DELETECOMMENT: 'Унта izoh бар',
    // ORIGINAL TEXT: delete comment
    ADDDOCEDIT: 'doceed',
    // ORIGINAL TEXT: add docedit
    ADDAPPROVEDDOCEDIT: 'Құжатты qoʻshish',
    // ORIGINAL TEXT: add approved document edit
    APPROVEDDOCEDIT: 'Құжатты түзету',
    // ORIGINAL TEXT: approve document edit
    REJECTDOCEDIT: 'Құжат өңдегіші',
    // ORIGINAL TEXT: reject document edit
    NEGOTIATORAPPROVEDDOCEDIT: 'Termoff faalse құжатын өңдеу',
    // ORIGINAL TEXT: negotiator approved document edit
    NEGOTIATORREJECTEDDOCEDIT: 'pantor hujjatini ret etdi',
    // ORIGINAL TEXT: negotiator rejected document edit
    DELETEDDOCEDIT: 'Дәптерді өшіру',
    // ORIGINAL TEXT: delete document edit
    ATTACHCONTRACT: 'rubtiject',
    // ORIGINAL TEXT: attach exec contract
    ADDTAG: 'тег қосуLocation date',
    // ORIGINAL TEXT: add tag
    DELETETAG: 'Tegni oʻchirish@ action: button',
    // ORIGINAL TEXT: delete tag
    NEWSIGNATUREREQUEST: 'Жаңа қолтаңбалар:',
    // ORIGINAL TEXT: new signature requested
    CANCELSIGNATUREREQUEST: 'CA куәліктерін өшіру% 1',
    // ORIGINAL TEXT: cancelled signature request
    CREATEREDLINEREQUEST: 'Qaytaruv roʻyxati yaratish',
    // ORIGINAL TEXT: create new redline document
    CREATEFINALDOCREQUEST: 'Қолтаңба üçin yeni құжатты құру',
    // ORIGINAL TEXT: create new final document for signature
    COPIEDDOCUMENT: 'belgeden kopyalandı',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: 'belgeden temiz sürüm oluşturuldu',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: 'created document from template',
    // ORIGINAL TEXT: created document from template
  },

  ADMINEVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: 'Құжаттың түрі',
    // ORIGINAL TEXT: create document type
    DELETEDOCTYPE: 'Құжаттың түрі жойылсын',
    // ORIGINAL TEXT: delete document type
    ADDOWNERTODOCTYPE: 'USB (nokia) türüne eksersiz калган',
    // ORIGINAL TEXT: added owner to document type
    DELETEOWNERFROMDOCTYPE: 'QIODevice (US)%s',
    // ORIGINAL TEXT: deleted owner to document type
    ADDCLAUSETODOCTYPE: 'Құжаттың turine بولىدىغان clave ekleri əlavə et',
    // ORIGINAL TEXT: added addl clause to document type
    DELETECLAUSEDOCTYPE: 'docut lendate bayt verb',
    // ORIGINAL TEXT: deleted addl clause to document type
    ADDTAGDOCTYPE: 'Dolduruv түріне ekl айнымалыны қосу',
    // ORIGINAL TEXT: added addl tag to document type
    DELETETAGDOCTYPE: 'Құжаттың түрінен silinған тегтен қосуLabel in type',
    // ORIGINAL TEXT: deleted addl tag from document type
    ADDCOLLABRULEDOCTYPE:
      'draw separator, table türüна laýyklykda, kolay kuralları eklendi',
    // ORIGINAL TEXT: added collab rules to  document type
    DELETECOLLABRULEDOCTYPE: 'құжаттың түріdan olib tashlashBanner page',
    // ORIGINAL TEXT: deleted collab rules from document type
    ADDNOTIFRULEDOCTYPE: 'çerçeve türünext current',
    // ORIGINAL TEXT: added notification rules to  document type
    DELETENOTIFRULEDOCTYPE: 'Құжаттың түрінен өшіруcolor- set- preview',
    // ORIGINAL TEXT: deleted notification rules from document type
    ADDNEGOTRULEDOCTYPE: 'domen himoyalangan domen:',
    // ORIGINAL TEXT: added negotiation rules to  document type
    DELETENEGOTRULEDOCTYPE: 'Құжаттың түрінен silinған Христос document name',
    // ORIGINAL TEXT: deleted negotiation rules from document type

    // GROUPS
    CREATEGROUP: 'Zümre',
    // ORIGINAL TEXT: created group
    DELETEGROUP: 'ушкӑнтыdictionary variant',
    // ORIGINAL TEXT: deleted group
    ADDOWNERGROUP: 'Qlob тобуна ek',
    // ORIGINAL TEXT: added owner to group
    DELETEOWNERGROUP: '% 1 ушкӑнтан өшірілген',
    // ORIGINAL TEXT: deleted Owner from group
    ADDCOLLABGROUP: 'Координаторлар тобуна ekser',
    // ORIGINAL TEXT: added collaborator to group
    DELETECOLLABGROUP: 'Group тобымен біріктіру',
    // ORIGINAL TEXT: deleted collaborator from group
    ADDDOCTYPETOGROUP: 'qidiruv түрі:',
    // ORIGINAL TEXT: added document type to group
    DELETEDOCTYPEGROUP: 'domenu item',
    // ORIGINAL TEXT: deleted document type from group

    // ALERTS
    CREATEALERT: 'diqqat',
    // ORIGINAL TEXT: created alert
    DELETEALERT: 'synçy',
    // ORIGINAL TEXT: deleted alert
    ADDOWNERALERT: 'тақырыпталганmenu item',
    // ORIGINAL TEXT: added owner to alert
    DELETEOWNERALERT: 'synçy% 1',
    // ORIGINAL TEXT: deleted owner from alert
    ADDDOCTYPEALERT: 'cublocation',
    // ORIGINAL TEXT: added doctype to alert
    DELETEDOCTYPEALERT: 'burchakttype',
    // ORIGINAL TEXT: deleted doctype from alert
    ADDCLAUSETOPICALERT: 'twentialseActtern upply',
    // ORIGINAL TEXT: added clause topic to alert
    DELETECLAUSETOPICALERT: 'tvnettaplay package',
    // ORIGINAL TEXT: deleted clause topic from alert
    ADDRECIPIENTALERT: 'reZound',
    // ORIGINAL TEXT: added recipient to alert
    DELETERECIPIENTALERT: 'transfer state: %s',
    // ORIGINAL TEXT: deleted recipient from alert
    ADDNOTIFRULEALERT: 'artewares',
    // ORIGINAL TEXT: added notification rules to alert
    DELETENOTIFRULEALERT: 'ескерту ережесін өшіруText in percent message',
    // ORIGINAL TEXT: deleted notification rules from alert
    SETSELECTIVITYALERT: 'animasiyaны таңдау',
    // ORIGINAL TEXT: set selectivity to  alert
  },

  USEREVENTS: {
    USERSIGNON: 'Kullanıcı Oturum Açtı',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT: 'Maxfiy soʻzni teleskari soʻzla yiberildi',
    // ORIGINAL TEXT: password reset request sent
    CHANGEDPASSWORD: 'Паролі өзгертілген',
    // ORIGINAL TEXT: changed password
    DELETEDREGISTRATION: 'Пайдаланушының тіркеуі silinды',
    // ORIGINAL TEXT: deleted user registration
    UPDATEDACCOUNTINFO: "Hisob mə'lumatı",
    // ORIGINAL TEXT: updated account information
    UPGRADEDACCOUNTTOREFERRAL: 'esap esaplar kütük',
    // ORIGINAL TEXT: upgraded account to referred account
    DELETEDUSERACCOUNT: 'колдонуучунун тіркелгісі',
    // ORIGINAL TEXT: deleted user account
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: 'DOKTERİKLAR',
    // ORIGINAL TEXT: DOCUMENT ACTIONS
    COMMENTS: '[ 25 - мӗш страницӑри ҫырса хуни]',
    // ORIGINAL TEXT: COMMENTS
    DOCEDITS: 'DOKTLAR',
    // ORIGINAL TEXT: DOCUMENT EDITS
    LABELSRISK: 'SEВЕНТ ҺӘМ ISK',
    // ORIGINAL TEXT: LABELS AND RISK
    TEXT: 'TEXT',
    // ORIGINAL TEXT: TEXT
    MLE_DESC: 'DOCGILITY',
    // ORIGINAL TEXT: DOCGILITY
    CLAUSE: 'Claus',
    // ORIGINAL TEXT: Clause

    // PRIVILEGE
    OWNER: 'Egasi',
    // ORIGINAL TEXT: owner
    COLLABORATOR: 'Координатор',
    // ORIGINAL TEXT: collaborator
    NEGOTIATOR: 'Fikirləntor',
    // ORIGINAL TEXT: negotiator
    OWNERGROUP: 'xotira(group)',
    // ORIGINAL TEXT: owner(group)
    COLLABORATORGROUP: 'Goodor(group)',
    // ORIGINAL TEXT: collaborator(group)

    // TITLES
    SAVEDOCUMENT: 'DOVE DOC TAPET',
    // ORIGINAL TEXT: SAVE DOCUMENT
    SAVEARCHIVE: 'ARVE АРХИВ',
    // ORIGINAL TEXT: SAVE ARCHIVE
    QUICKSTART: 'QUCK TUTORIAL',
    // ORIGINAL TEXT: QUICK START TUTORIAL
    TUTORIALVIDEOS: 'TURILIÝA VIDOS',
    // ORIGINAL TEXT: TUTORIAL VIDEOS

    MOVETOARCHIVEDOCUMENT: 'MARHOVE DOCUK ÜÇIN',
    // ORIGINAL TEXT: MOVE TO ARCHIVE DOCUMENT
    OPENDOCUMENT: 'OPEN DOCUTLIK',
    // ORIGINAL TEXT: OPEN DOCUMENT
    COPYDOCUMENT: 'COPE DOCLANUŞ',
    // ORIGINAL TEXT: COPY DOCUMENT
    CREATEVERSION: 'TEMİZ VERSİYON OLUŞTUR',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: 'CREATE DOCUMENT FROM TEMPLATE',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: 'OPEN PROUP DOC UŞAK',
    // ORIGINAL TEXT: OPEN GROUP DOCUMENT
    OPENHUBDOCUMENT: 'OPEN HUB DOCUTLIK',
    // ORIGINAL TEXT: OPEN HUB DOCUMENT
    OPENARCHIVEDOCUMENT: 'ARŞIW DOCUMEN',
    // ORIGINAL TEXT: OPEN ARCHIVE DOCUMENT
    OPENPLAYBOOK: 'OPEN PLAY Kılavuzu',
    // ORIGINAL TEXT: OPEN PLAYBOOK
    OPENTEMPLATE: 'OPEN TEMPLATE',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: 'SAVE AS TEMPLATE',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: 'KÖMEK ÜÇ KELLEMEK',
    // ORIGINAL TEXT: COLLABORATION
    NEGOTIATION: 'DELILEDIŇIZE TURAT',
    // ORIGINAL TEXT: NEGOTIATION
    ADDOWNER: 'ADD OWNER',
    // ORIGINAL TEXT: ADD OWNER
    ADDNEGOTIATOR: 'ADD NEEATATLYK',
    // ORIGINAL TEXT: ADD NEGOTIATOR
    ADDCOLLABORATOR: 'ADDKABRAKRAK',
    // ORIGINAL TEXT: ADD COLLABORATOR
    ERRORMESSAGE: 'MESSEÝABI',
    // ORIGINAL TEXT: ERROR MESSAGE
    DOCUMENTINFO: 'DOKLARIN TENEKETMƏK',
    // ORIGINAL TEXT: DOCUMENT INFORMATION
    DOCUMENTLOGS: 'DOCTOTY LOG',
    // ORIGINAL TEXT: DOCUMENT LOGS
    USERLOGS: 'USER LOGS',
    // ORIGINAL TEXT: USER LOGS
    DOCUMENTNOTES: 'DOCTERMƏK ETMƏK',
    // ORIGINAL TEXT: DOCUMENT NOTES
    TRIGGERALERTS: 'TROGERRILE StratejiName',
    // ORIGINAL TEXT: TRIGGER ALERTS
    ALERTS: 'ALARSHERUS',
    // ORIGINAL TEXT: ALERTS
    DOCTYPES: 'DOCTEN TYPE',
    // ORIGINAL TEXT: DOCUMENT TYPES
    GROUPS: 'TOPARLAR',
    // ORIGINAL TEXT: GROUPS
    NOTE: 'ЕСКЕРТУ',
    // ORIGINAL TEXT: NOTE
    GOTOPARAGRAPH: 'БАСМАЛЫҚ ДАЯҚ',
    // ORIGINAL TEXT: GO TO PARAGRAPH
    ESIGNATURE: 'E-ГАЛбуй',
    // ORIGINAL TEXT: E-SIGNATURE
    DOWNLOADREDLINE: 'GOLVADDES ÝAZGYLAR',
    // ORIGINAL TEXT: DOWNLOAD REDLINE DOCX

    // ACTIONS
    ADD: 'ADD',
    // ORIGINAL TEXT: ADD
    BACK: 'BAKDE',
    // ORIGINAL TEXT: BACK
    DELETE: 'TEETED',
    // ORIGINAL TEXT: DELETE
    SAVE: 'SVE',
    // ORIGINAL TEXT: SAVE
    MOVETOARCHIVE: 'MARHIVE',
    // ORIGINAL TEXT: MOVE TO ARCHIVE
    COPY: 'COPY',
    // ORIGINAL TEXT: COPY
    CREATE: 'OLUŞTURMAK',
    // ORIGINAL TEXT: CREATE
    OPEN: 'OPEN',
    // ORIGINAL TEXT: OPEN
    CLOSE: 'ЖАБЫҚ',
    // ORIGINAL TEXT: CLOSE
    UPDATE: 'DATE',
    // ORIGINAL TEXT: UPDATE
    REGISTER: 'TRUE',
    // ORIGINAL TEXT: REGISTER
    LOGIN: 'LOGIN',
    // ORIGINAL TEXT: LOGIN
    LOGINSSO: 'LOGIN SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: 'RESET PASUS',
    // ORIGINAL TEXT: RESET PASSWORD
    CHANGEPASSWORD: 'PASS шоғырды',
    // ORIGINAL TEXT: CHANGE PASSWORD
    CANCELACCOUNT: 'CANCEL ACOUNT',
    // ORIGINAL TEXT: CANCEL ACCOUNT
    QUICKSTARTVIDEO: 'STARKK VIDEO',
    // ORIGINAL TEXT: WATCH QUICK START VIDEO

    CHARGEBASIC: 'BASICASİ PIONER',
    // ORIGINAL TEXT: UPGRADE TO BASIC
    CHARGEPREMIUM: 'UMPRADAMIŞIMA',
    // ORIGINAL TEXT: UPGRADE TO PREMIUM
    CHARGECORPORATE: 'DURMUŞОНЕР ДӨРЕСТИНЧЕ ТУРГАНДА',
    // ORIGINAL TEXT: UPGRADE TO CORPORATE
    UPGRADESUBSCRIPTION: 'DURDATE SUBSCIPT',
    // ORIGINAL TEXT: UPGRADE SUBSCRIPTION
    GO: 'GO',
    // ORIGINAL TEXT: GO
    PREVIEW_FEEDBACK: 'ENEKE XIVE TAPET',
    // ORIGINAL TEXT: REVIEW CHANGES
    ACTIONS: 'TAPK',
    // ORIGINAL TEXT: ACT
    REFRESH: 'REFRESH',
    // ORIGINAL TEXT: REFRESH
    ADDNEWGROUP: 'ADDNo CROUP',
    // ORIGINAL TEXT: ADD NEW GROUP
    ADDNEWDOCTYPE: 'DDDNJITYPE',
    // ORIGINAL TEXT: ADD NEW DOCTYPE
    ADDNEWALERT: 'ADDNome CERT',
    // ORIGINAL TEXT: ADD NEW ALERT
    GROUPSLIST: 'ТИВЕЙ ЧЕЛЕ БУЛМАЯ',
    // ORIGINAL TEXT: REVIEW GROUP LIST

    DOWNLOADDOCUMENT: 'DOVE DOKTLAR',
    // ORIGINAL TEXT: DOWNLOAD DOCUMENT
    DOCUMENTTITLE: 'Құжаттың айдары',
    // ORIGINAL TEXT: Document Title
    DOCUMENTSUBJECT: 'Құжат нақышы',
    // ORIGINAL TEXT: Document Subject
    SIGNMESSAGE: 'Xabarты қолтаңбалау',
    // ORIGINAL TEXT: Message for Signing
    NAMEPRIMARYSIGNER: 'Ad: Baş imzalayıcı',
    // ORIGINAL TEXT: Name: Primary Signer
    EMAILPRIMARYSIGNER: 'Email: Başda imzalayıcı',
    // ORIGINAL TEXT: Email: Primary Signer
    NAMECOUNTERSIGNER: 'İsim:',
    // ORIGINAL TEXT: Name: Counter Signer
    EMAILCOUNTERSIGNER: 'Email: El.pochta',
    // ORIGINAL TEXT: Email: Counter Signer
    SIGREQUESTED: 'SIGUSALN RESBINE KURBANLAR',
    // ORIGINAL TEXT: SIGNATURE REQUESTED
    SIGREQUESTEDBY: 'RESULUTE EDÝÄR',
    // ORIGINAL TEXT: REQUESTED BY
    SIGREQUESTEDID: 'SIGNIM ID',
    // ORIGINAL TEXT: SIGNATURE ID
    SIGREQUESTEDAT: 'TRUELIK ETMÄ',
    // ORIGINAL TEXT: REQUESTED AT
    SUBMITSIG: 'СИГНАРИЯЛЫ ИЗИЛДӨӨЛӨР:',
    // ORIGINAL TEXT: SUBMIT FOR SIGNATURE
    CANCELSIG: 'PANSIONER CMYKUNI REQUESTE',
    // ORIGINAL TEXT: CANCEL SIGNATURE REQUEST

    // DATA LABELS
    NAME: 'NOMI',
    // ORIGINAL TEXT: NAME
    EMAIL: 'Сайран КиккаринEMAIL OF TRANSLATORS',
    // ORIGINAL TEXT: EMAIL
    STATUS: 'СТАТ',
    // ORIGINAL TEXT: STATUS
    LASTVIEWED: 'SEN ÖZÜNÜŇIZI',
    // ORIGINAL TEXT: LAST VIEWED
    LASTREVIEWED: 'DOWADILLIK',
    // ORIGINAL TEXT: LAST REVIEWED
    SIGNEDAT: 'SIGN',
    // ORIGINAL TEXT: SIGNED
    OWNERAPPROVAL: 'OWNER APROVAL',
    // ORIGINAL TEXT: OWNER APPROVAL
    OWNERACTION: 'ОКУРАЛАРҒА САРАП ЧАРАК',
    // ORIGINAL TEXT: OWNER ACTION
    NEGOTIATORAPPROVAL: 'NETИТ - ДАЙЫМА ЗОМПТОР',
    // ORIGINAL TEXT: NEGOTIATOR APPROVAL
    NEGOTIATORACTION: 'НИКАХИКО ҮТЕРІЛГЕН САРАКТАР',
    // ORIGINAL TEXT: NEGOTIATOR ACTION
    ARCHIVENOTES: 'ARŞIWESY',
    // ORIGINAL TEXT: ARCHIVE NOTES
    EXECUTEDCONTRACT: 'GÜSÜRSÜ',
    // ORIGINAL TEXT: EXECUTED
    EXECUTEDDATE: 'GENÇEÝÄN DURMUŞ',
    // ORIGINAL TEXT: EXECUTED DATE
    CONTRACTATTACHED: 'CONTATLANŞY ALANDANMAK',
    // ORIGINAL TEXT: CONTRACT ATTACHED
    STARTDATE: 'START DATE',
    // ORIGINAL TEXT: START DATE
    ENDDATE: 'DATE',
    // ORIGINAL TEXT: END DATE
    EDIT: 'WED',
    // ORIGINAL TEXT: EDIT
    USER: 'FOYDALANUVCHI',
    // ORIGINAL TEXT: USER
    TIME: 'TIME',
    // ORIGINAL TEXT: TIME
    CONFIRM: 'КОНФРИМ',
    // ORIGINAL TEXT: CONFIRM
    CONFIRMACTION: 'АСЛЫҠШ - АРАКЕТЕГЕҘ',
    // ORIGINAL TEXT: CONFIRM ACTION
    CURRENT_LABEL: 'CURRENT ABEL',
    // ORIGINAL TEXT: CURRENT LABEL
    NEW_LABEL: 'НЬЮЛЬ',
    // ORIGINAL TEXT: NEW LABEL
    DESCRIPTION: 'ESCIPT',
    // ORIGINAL TEXT: DESCRIPTION
    TYPE: 'TYPE',
    // ORIGINAL TEXT: TYPE
    SENTENCE: 'WAGYZ',
    // ORIGINAL TEXT: SENTENCE
    NUMBER: '#',
    // ORIGINAL TEXT: #
    PRIVILEGE: 'ROLE',
    // ORIGINAL TEXT: ROLE
    OWNERID: 'OWERID',
    // ORIGINAL TEXT: OWNERID
    DOCNAME: 'DOCTOTMİK',
    // ORIGINAL TEXT: DOCUMENT NAME
    DOCTYPE: 'DOCTEN TYPE',
    // ORIGINAL TEXT: DOCUMENT TYPE
    DOCDESCRIPTION: 'DOCTLAR ESCIPTLAR',
    // ORIGINAL TEXT: DOCUMENT DESCRIPTION
    DOCNOTES: 'DOCTERMƏK ETMƏK',
    // ORIGINAL TEXT: DOCUMENT NOTES
    DOCPARTY: 'ARTI',
    // ORIGINAL TEXT: PARTY
    DOCCOUNTERPARTY: 'COUNTLANY',
    // ORIGINAL TEXT: COUNTER PARTY
    DOCRAWFILENAME: 'BELGE DOSYA ADI',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: 'ToTGS',
    // ORIGINAL TEXT: TAGS
    COLLABORATIONSTATUS: 'КОЛЬША БОЛОШТА',
    // ORIGINAL TEXT: COLLABORATION STATUS
    COLLABORATIONEND: 'KÖMEK ÜÇIN WEPALY',
    // ORIGINAL TEXT: COLLABORATION END
    NEGOTIATIONSTATUS: 'MUSADA ETMÄNIŇIZI KURBAT',
    // ORIGINAL TEXT: NEGOTIATION STATUS
    NEGOTIATIONEND: 'ELE MAŞGA OTMAKUN',
    // ORIGINAL TEXT: NEGOTIATION END
    DOCID: 'DOCT 2: ID',
    // ORIGINAL TEXT: DOCUMENT ID
    PARAGRAPHID: 'PARGRAH ID- і',
    // ORIGINAL TEXT: PARAGRAPH ID
    ACTION: 'OGGEKIRAK',
    // ORIGINAL TEXT: LOGGED ACTION
    DETAIL: 'ДӘРҖЕМӘИП ШАНДАР',
    // ORIGINAL TEXT: ACTION DETAIL
    CONTEXT: 'ТАРИХИ ПЕРЦИЯ',
    // ORIGINAL TEXT: ADDITIONAL CONTEXT
    GROUPNAME: 'PROUP ATA',
    // ORIGINAL TEXT: GROUP NAME
    GROUPDESC: 'RROUP ESCURT',
    // ORIGINAL TEXT: GROUP DESCRIPTION
    DOCTYPEDESC: 'DOCTENTYESCUR',
    // ORIGINAL TEXT: DOCUMENT TYPE DESCRIPTION
    ADDLCLAUSETYPES: 'НИКАҪА БЕЛЛЕК',
    // ORIGINAL TEXT: CUSTOM CLAUSE LABELS
    ADDLTAGS: 'GOWY TARAP BERLANDI',
    // ORIGINAL TEXT: CUSTOM TAGS
    COLLABORATIONRULES: 'MAKSAHATLY BELLEMEK',
    // ORIGINAL TEXT: COLLABORATION RULES
    NEGOTIATIONRULES: 'ELLEE MURATY MASLAHATY',
    // ORIGINAL TEXT: NEGOTIATION RULES
    NOTIFICATIONRULES: 'NÄME ÜÇINI MARHAT',
    // ORIGINAL TEXT: NOTIFICATION RULES
    LOG: 'LOG',
    // ORIGINAL TEXT: LOG
    ALERTNAME: 'AЕРTMA',
    // ORIGINAL TEXT: ALERT NAME
    ALERTDESC: 'ALETMASCIPT',
    // ORIGINAL TEXT: ALERT DESCRIPTION
    TOPICS: 'TOPESC',
    // ORIGINAL TEXT: TOPICS
    RECIPIENTS: 'GUSİSUSALLAR',
    // ORIGINAL TEXT: RECIPIENTS
    SELECTIVITY: 'SEЕТІҢЗИ ОРЫНДАГЫ',
    // ORIGINAL TEXT: SELECTIVITY
    FIELDNAME: 'FIELD NAME',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: 'ORDER',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: 'EXAMPLE',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: 'DEFAULT',
    // ORIGINAL TEXT: DEFAULT
    VALUE: 'VALUE',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: 'DOCUMENT',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: 'PLAYBOOK',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: 'TEMPLATE',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: 'ARCHIVED DOCUMENT',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: 'ORIGINATED FROM TEMPLATE:',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: 'KÖMEK ÜÇ KELLEMEK',
    // ORIGINAL TEXT: COLLABORATION
    NEGSHORT: 'DELILEDIŇIZE TURAT',
    // ORIGINAL TEXT: NEGOTIATION
    OWNERS: 'OLDAŞLAR',
    // ORIGINAL TEXT: OWNERS
    COLLABORATORS: 'OLRABRAK',
    // ORIGINAL TEXT: COLLABORATORS
    NEGOTIATORS: 'НИКАДЕР ДЕРЕКЛӘНДЕР',
    // ORIGINAL TEXT: NEGOTIATORS
    // ADDITION
    DOCUMENTDETAILS: 'DOCTOTIŞELIK',
    // ORIGINAL TEXT: DOCUMENT DETAILS
    CREATIONDATE: 'CURATIÝA',
    // ORIGINAL TEXT: CREATION DATE
    PARTICIPANTS: 'ÄhtimalKELENS',
    // ORIGINAL TEXT: PARTICIPANTS
    COLLABORATIONANDNEGOTIATION: 'ÜÇINJI KELLED BELLEMEK ÜÇINDE',
    // ORIGINAL TEXT: COLLABORATION AND NEGOTIATION
    DOCTYPEANDGROUPACCESS: 'DOC TYPE ҺӘМ TROKUP HISS',
    // ORIGINAL TEXT: DOCUMENT TYPE AND GROUP ACCESS
    GROUPOWNERS: 'OLUP OLMAK',
    // ORIGINAL TEXT: GROUP OWNERS
    GROUPCOLLABORATORS: 'ROUP KOLLAKRAM',
    // ORIGINAL TEXT: GROUP COLLABORATORS
    DOCUMENTSETTINGS: 'DOWAK EDEN BAŞLANLAR',
    // ORIGINAL TEXT: DOCUMENT SETTINGS
    ENABLECOLLABTOMODLABELS:
      '4: 12). Talaviçilere Klaus Etiketlerini өзгертуге қосу',
    // ORIGINAL TEXT: Enable Collaborators to Modify Clause Labels
    ENABLENEGOTCAPABILITIES:
      'Наукалық мүмкіндіктерді (Notountation) рұқсат ету',
    // ORIGINAL TEXT: Enable Negotiation capabilities (Not Negotiation mode)
    KEEPDOCNOTES: 'Kopyalama İşlemi ile Belge Notlarını Kopyalama',
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS:
      'Onuňлаблаб, Antuan Klausse темасы Necesaric деп атала.',
    // ORIGINAL TEXT: Reviewed and Amended Clause Topics as Necessary
    NOEXECUTIONDATE: 'غىن% 1 بولسا date',
    // ORIGINAL TEXT: No Execution Date
    EXECUTIONDATE: 'Bajarish sanasi',
    // ORIGINAL TEXT: Execution Date
    CONTRACTSTARTDATE: 'Değiştirme tarihi',
    // ORIGINAL TEXT: Contract Start Date
    CONTRACTENDDATE: 'KInedographis sanasi',
    // ORIGINAL TEXT: Contract End Date
    EXECCONTRACTNAME: 'God Express файлын орындау',
    // ORIGINAL TEXT: Executed Contract File Name
    SELECTEXECCONTRACT: 'restricted iflas iflas qidiradi',
    // ORIGINAL TEXT: select file to upload executed contract
    UPLOADEXECUTEDCONTRACT: 'MÜSƏHƏT KÖMEK EDÝÄR',
    // ORIGINAL TEXT: UPLOAD EXECUTED CONTRACT
    LOADDEFAULTS: 'LOAD DEFATSS',
    // ORIGINAL TEXT: LOAD DEFAULTS
    CLOSED: 'ЖАБЫҚ',
    // ORIGINAL TEXT: CLOSED
    NONE: 'Change',
    // ORIGINAL TEXT: NONE
    NA: 'NA',
    // ORIGINAL TEXT: NA
    RESULTEXECUTEDCONTRACT: 'Skript startrackground',
    // ORIGINAL TEXT: Resulted in Executed Contract
    NOSTARTDATE: 'Басlıq Berilmegen',
    // ORIGINAL TEXT: No Start Date
    NOENDDATE: 'Аяқтау saati',
    // ORIGINAL TEXT: No End Date
    EXCLUDECOLLABORATORSFROMACCESS: 'Talabalı Çevrimiçi Talabatorlar',
    // ORIGINAL TEXT: Exclude External Collaborators From Accessing Document
    EXCLUDENEGOTIATORSFROMACCESS: 'Çeşitli countip Document Document forward',
    // ORIGINAL TEXT: Exclude External Negotiators From Accessing Document
    REVIEWEDAMENDEDCLAUSELABELS:
      'Gözegçileri карабай (Clausse Claussar) Talk (Icesar)',
    // ORIGINAL TEXT: Reviewed and Amended Clause Labels as Necessary
    ENTERPARAGRAPHBETWEEN: 'Bir абзацты келтіріңіз:',
    // ORIGINAL TEXT: Enter a paragraph number between:
    GOTOPARAGRAPHINDEX: 'Абзацтың индексyna git',
    // ORIGINAL TEXT: Go to Paragraph Index
    INPUTDOCUMENTNOTEHERE: 'Енгізу урӑхки',
    // ORIGINAL TEXT: Input Document Note here
    INPUTCOMMENTHERE: 'Кіріс җавапы',
    // ORIGINAL TEXT: Input Comment here
    SHOWPENDINGONLY: 'Show will to view',
    // ORIGINAL TEXT: Show Pending Only
    COLLABORATIONSETTING: 'BELLEMEK ÜÇIN PATYŞA MÖK',
    // ORIGINAL TEXT: COLLABORATION SETTING
    NEGOTIATIONSETTING: 'MUSADAMAT EDEN ETMÄGE Ü',
    // ORIGINAL TEXT: NEGOTIATION SETTING
    NONEGOTWHENAPPROVED: 'Мақұлда razy болғанда Neotegoritation (optional)',
    // ORIGINAL TEXT: Cannot turn on Negotiation when in Approved State
    EMAILTOADD: 'Эл. поштаны қосу үшін эл. пошта',
    // ORIGINAL TEXT: Email to add
    CLAUSETOADD: 'Claus ile eklemek üçin',
    // ORIGINAL TEXT: Clause to add
    TAGTOADD: 'Teg қосуStencils',
    // ORIGINAL TEXT: Tag to add
    OPTIONSFORREDLINEINCLUDE: 'Zero2d hatдарды dahil etmek için  Kızıl satır',
    // ORIGINAL TEXT: Options for Redlines to Include
    ONLYINCLUDEOWNERAPPROVEDEDITS: 'Daxil Tasvirli Құжатты diňe Tasvir Et',
    // ORIGINAL TEXT: Only Include Owner-approved Document Edits
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      'Sayt ve NeotepatorDefrate ДолбоорcularEditor',
    // ORIGINAL TEXT: Only Include Owner and Negotiator-approved Document Edits
    INCLUDEALLDOCEDITS: 'Vesiqa düzenlerini (fonegsiz rəzillik) daxil et',
    // ORIGINAL TEXT: Include All Document Edits (regardless of approval)
    REDLINECHANGESSELECTED: 'Red Rodline',
    // ORIGINAL TEXT: Redlined Changes Selected
    TOTALCHANGES: 'Oʻzgarishlar',
    // ORIGINAL TEXT: Total Changes
    REPLACEREDLINES: 'Qıl-mizi алмастыру',
    // ORIGINAL TEXT: Replace Redlines
    DELETEREDLINES: 'Qылтырмаларды өшіру',
    // ORIGINAL TEXT: Delete Redlines
    ADDAFTERREDLINES: 'Red-REd hatlarnı qoʻshish',
    // ORIGINAL TEXT: Add-After Redlines
    OPTIONSFORPREVREDLINES: 'Oldingi filtriler үшін параметр',
    // ORIGINAL TEXT: Options for Previous Redlines
    OVERWRITEPREVREDLINES:
      'Құжат Көзінде бүкіл əvvəlki pal-setırları қайта жаз',
    // ORIGINAL TEXT: Overwrite all Previous Redlines in Document Source
    KEEPPREVREDLINES: 'Vesiqa Çeşmesinde бар əvvəlki hatlar',
    // ORIGINAL TEXT: Keep all Previous Redlines in Document Source
    GENERATEFINALDOC: 'Соңғы құжаттың құрылымы ( Red- жолдарсыз)',
    // ORIGINAL TEXT: Generate Final Document (without Redlines)
    DEFAULTESIGNOTE: 'Ýokarda әйтелгәнчә, bu келишимe ilat jemmonunuzy verin.',
    // ORIGINAL TEXT: As discussed, please provide your eSignature to this contract.
    SUBMITTEDSIGMESSAGE: 'Tekis imzolar bar. Lütfen bekleyin.',
    // ORIGINAL TEXT: Submitted for signatures.  Please wait.
    UPLOADEDMESSAGE: '_Gedişatlar',
    // ORIGINAL TEXT: Uploaded
    ADDADDLFILES: 'Қолтаңбалауына қосымша қосымша файлдарды қосу',
    // ORIGINAL TEXT: Optional - Adding additional files to signature request
    ADDFILESMSG: 'Қолтаңба үшін қосымша файлдарды қосу',
    // ORIGINAL TEXT: add additional files for signature
    ESIGPREPAIDPLANS:
      'eİmza özelliği yalnızca ön ödemeli planlarımızda mevcuttur.',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      'Daha fazla ayrıntı için billing@mylegaleinstein.com adresinden bize ulaşın.',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: 'More videos at the ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      'Құжат түрі desteklenmey. MLE тек docx ve pdf пішімдерін ғана қабылдайды.',
    // ORIGINAL TEXT: Document type is not supported.  MLE only accepts docx and pdf formats.
    PDFSUPPORTEDFORPREMIUMONLY:
      'PDF-ні taramak için пайдаланушы Premium Жазылысу не gowulanыу үшін жаңарту керек.',
    // ORIGINAL TEXT: For PDF scanning, user needs to upgrade to Premium subscription or better.
    BADDOCFORMAT: 'Құжат өңдеу үшін қате құжат пішімі жіберілді.',
    // ORIGINAL TEXT: Bad Document format was passed to document processing.
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      'Сізде құжаттың баптауларын өзгерту мүмкін емес. Siz Басқару Хуҫалынын іске асырmalıсыз.',
    // ORIGINAL TEXT: You do not have the privilege to make changes to the Document Settings.  You are required to be an Owner.
    PRIVILEGSNOTAVAIL:
      'Bu пайдаланушының االله نىڭ иконтитималі шанчӑкы жоқ. Кейінірек қайталап көріңіз.',
    // ORIGINAL TEXT: The privileges for this user are not available.  Try again later.
    EXHAUSTEDTRIALLICENSE:
      'Sınaу вӑхӑтӗнче құжаттардың sayısı muvaffaqiyatli boldy. Профилиңерде "Auto recurrow" дегенді таңдаңыз.',
    // ORIGINAL TEXT: You have exhausted the number of allowed documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    EXHAUSTEDMONTHLYLIMIT:
      'Сынаулар учурунда her айләргә rugsat berilýän документlaryň саны tükendi. Профилиңерде "Auto report>"" дегенді таңдаңыз.',
    // ORIGINAL TEXT: You have exhausted the number of allowed monthly documents under your trial period.  Please select "Upgrade Subscription" in your profile.
    PASSWORDNOTMATCHING: 'Парольдер çavke емес. Паролін қайта тексеріңіз.',
    // ORIGINAL TEXT: Passwords do not match.  Check the passwords again.
    ABAMEMBERIDLENGTH:
      "ABAB Access ID'si 7 karakterdir. Xahiş edirik,ниненинениненине ve teslim olun.",
    // ORIGINAL TEXT: ABA Membership IDs are 7 characters long.  Please review and submit.
    TERMSNOTCHECKED: 'Планетасемпе шарттарын тикшерергә саналмаған.',
    // ORIGINAL TEXT: The Terms and Conditions have not been reviewed and checked.
    EMAILFORMATBAD:
      'эл. пошта пішімі дұрыс емес. Iltimos эл. пошта адресін қайта жазыңыз.',
    // ORIGINAL TEXT: Email format is not valid.  Please review email address.
    CANCELEDACTION:
      'юлашки amalыңыз басылған. Қолданба täzeden qayta жүктеу керек.',
    // ORIGINAL TEXT: Your last action have been canceled.  Application will now reload.
    SUCCESSACTION:
      'Өткен әрекет сәтті орындалды. KDE Басқару үшін кайра жүктеледі.',
    // ORIGINAL TEXT: Your last action has been successful.  Application will now reload.
    USERSUCCESSLOGIN: 'Жүйеге кіргенде сәтті өтті',
    // ORIGINAL TEXT: Successfully logged on as
    USERLOGGEDOUT: 'Пайдаланушы devre-tışı',
    // ORIGINAL TEXT: The user has been logged out
    EMAILNOTVERIFIED:
      'E- poçta енді тексерілмеген. Sizə эл. пошта тексеру тексеруі жіберілген.',
    // ORIGINAL TEXT: Account email has not yet been verified.  An email verification has been sent to you.
    EMAILALREADYREGISTERED: 'Bu эл. пошта öňдан резолюцияланған hesap endi.',
    // ORIGINAL TEXT: This email already has a previously registered account.
    EMAILNOTVERIFIEDRESENT:
      'Эл. поштаңыз тексеруден өтпеді. Жаңа тіркелгіні белсендету үшін, Сізден эл. пошта тексеруіне кайнап жатырсыз.',
    // ORIGINAL TEXT: Your email has not been verified.  We have resent you an email verification link to your email to activate the new account.
    USERLOGFILENOTEXISTS: 'Қате: Log файл файлы жоқ:',
    // ORIGINAL TEXT: Error - no log user file exists for:
    PASSWORDRESETSENT: 'count formulañız uchun эл. пошта хабары yiberildi.',
    // ORIGINAL TEXT: An email password reset message has been sent for your account.
    PASSWORDCHANGED: 'Паролігез üýtgedilen.',
    // ORIGINAL TEXT: Your password has been changed.
    REGISTRATIONDELETED: 'Пайдаланушының тіркеуі silinды',
    // ORIGINAL TEXT: deleted user registration
    USERACCOUNTUPDATED:
      'Сиз пайдаланушының тіркелгі ayarlarıмен yangiladınız.димki içeri кіргенде өзгерістер күшіне енеді.',
    // ORIGINAL TEXT: You have updated your user account settings.  Changes will take into effect the next time you login.
    UPGRADEDTOREFERRED:
      'Siziň hesabatňyz täzeden « synaga жазу » güýçlendirildi.',
    // ORIGINAL TEXT: Your account has been updated to Referred Trial subscription.
    REGISTEREDTRIALUSER:
      'Жаңа тіркелгі «масархия » журналына ҫырӑнса davam edýär.',
    // ORIGINAL TEXT: New account is registered as Trial subscription.
    DELETEDUSERACCOUNT: 'Пайдаланушының profili olib tashlash',
    // ORIGINAL TEXT: Deleted user profile account
    EMAILALREADYREGISTEREDRESENT:
      'Eposta менің Leyk Einstein бұндай ilişdirilмеген. Anvitsions for the mail is the completeination',
    // ORIGINAL TEXT: Email has not already been registered with Docgility.  An email invitiation has been sent to
    UPGRADEEXTERNALPAYMENTS:
      'Rejim etdigiňiz üçin teşekkürler. Безnene bir talant төлемini gaýtararys we reklamaňyzy doly ýerine ýetirenden soň, хат алышу үшін ýene - de жүйеге кіруli bolarsyňyz.',
    // ORIGINAL TEXT: Thanks for upgrading.  You will be rerouted to an external payment service and after completing your transaction, you will need to login again for subscription service to start.
    BILLINGEXTERNALPAYMENTS:
      'Lütfen bu аҡса концепциясы мен reklam gullugymyza kibicle етіледі. Берілген өзгерістерді үзөлдеру үшін қайта içeri imzalanуың керек.',
    // ORIGINAL TEXT: Please confirm that you will like to be directed to our external payment and billing service.  After you make any changes, you will need to login again for changes to take effect.
    NOTABLETOLOGINATTHISTIME:
      'Бұл вӑхӑт арқылы жүйеге кіре алмайсыз. Кейінірек қайталап көріңіз.',
    // ORIGINAL TEXT: Not able to login at this time.  Try again later.
    NOTREGISTERED:
      'Жүйеге кіруden бұрын foydalanuvchi nomina tasvir etmedi. Fitching is email açmadan oldin.',
    // ORIGINAL TEXT: User has not previously registered.  Register before trying to login.
    NAMETOOLONG: 'Келтірген аты өте uzun. Макс. 60 karakter.',
    // ORIGINAL TEXT: The name you entered is too long.  Max is 60 characters.
    COMPANYTOOLONG: 'Келтірген компания çox uzun. Макс. 60 таңба.',
    // ORIGINAL TEXT: The company you entered is too long.  Max is 60 characters.
    COMMENTTOOLONG: 'Denewler çox uzun. Макс. 0000 karakterler soni.',
    // ORIGINAL TEXT: The comments you entered is too long.  Max is 10000 characters.
    DOCEDITTOOLONG:
      'Өңдеуdagi hujjatler çok uzun. Макс. 100000 таңбаdan ybarat.',
    // ORIGINAL TEXT: The document edits you entered is too long.  Max is 10000 characters.
    TITLETOOLONG: 'Келтірілген аты өте uzun. Макс. 60 таңбалы.',
    // ORIGINAL TEXT: The title you entered is too long.  Max is 60 characters.
    PASSWORDTOOSHORT:
      'Келтірген пароль тым көп. Паролі кемінде 6 таңбадан асқан болу керек.',
    // ORIGINAL TEXT: The password you entered is too short.  Password has to be at least 6 characters.
    CONGRATSNEWDOCUMENTPROCESSING:
      'Həqiqətən, täze құжат yaratылуыңыз җитә. Дүмбтің аяқталған кезде, ул темиҫе минутка және панель жаңартылмақ. Lütfen темиҫе минутын күтүңүз.',
    // ORIGINAL TEXT: Great! You have processed a new document.  It takes a few minutes and the panel should refresh when the document is completed processing.  Please wait a few minutes.
    NODOCUMENTLOADED: 'desktap fraými',
    // ORIGINAL TEXT: No document loaded
    SELECTFILETOUPLOADDOCX: '(*.docx) дегенді жүктеп беру үшін файл таңдау',
    // ORIGINAL TEXT: select file to upload (*.docx)
    SELECTFILETOUPLOADDOCXPDF: '(*.docx, *.pdf) yükleйтін файлды таңдау',
    // ORIGINAL TEXT: select file to upload (*.docx, *.pdf)
    INPUTCOMMENTHERE: 'Кіріс комментарий',
    // ORIGINAL TEXT: Input comment here
    AUTOAPPROVEASOWNER: 'Egasi ретінде автоматылу',
    // ORIGINAL TEXT: Auto Approve as Owner
    INPUTDOCEDITSHERE: 'Енгізу құжатын өңдеу',
    // ORIGINAL TEXT: Input Document Edit here
    TRIGGEREDALLALERTS: 'Біткен (ihtiyarda) bunlar üçün Uyarılar (ihtiyarda).',
    // ORIGINAL TEXT: Triggered all (if any) Alerts for this doctype document.
    QSINTROMESSAGE:
      'LegalEinstein biraz сәләмәтлеріңізді, судьяларыңызның indeksleri арқылы анализәләненей техникалалық штрафраттарыңызды тикшереп береді.',
    // ORIGINAL TEXT: MyLegalEinstein instantly analyzes your contracts, indexes by legal topics, and allows side-by-side comparisons of legal language in a complete contract collaboration platform. We will demonstrate the capabilities in four simple steps (detailed instructions are provided in english but similar in all languages.)
    QSDOCMESSAGE: 'Хамӑр ömrüňize gowy plan güýmenjeler geçirсе gerek.',
    // ORIGINAL TEXT: For your convenience, here are some public domain contracts that you can download and try:
    QSHELPMESSAGE: 'Buda құжат құжаттамасымен boglama (Stencils) Stencils',
    // ORIGINAL TEXT: Heres a handy link to product documentation (with wordy version of Quick Start)
    DOCUMENTFAILEDTOPROCESS:
      'Құжатты өңдеу кезінде бір қате пайда болды. Қайталап көріңіз. Егер проблема support@einstein.com',
    // ORIGINAL TEXT: A fatal error occurred while processing the document.  Please try again.  If problem persists, please contact customer support at support@mylegaleinstein.com.

    // ADDED
    DOCUMENTSUCCESSCOPIED: 'Belgeniz şimdi kopyalandı.',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      'Bu belgenin temiz bir sürümü şimdi oluşturuldu ve kaydedildi.',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      'A document was generated from this template and saved.',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE:
      'A document has been converted into a document template.',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },
  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      'Версия өшірілген хыҫҫӑн оны бұзылған жоқ. она оналбандағы күй күй- жайіне жылжыта аласыз.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified document.  After document is deleted, it will no longer be available.  You may also consider moving the document to Archive status.  \n\n Please confirm delete operation.
    CONFIRMSAVETOPLAYBOOK:
      'Гыйбадәт кылып осы құжатты pleyer newbook- тан тан тандрузияға çeviring. @ action: button Remotely from the draw',
    // ORIGINAL TEXT: Please confirm that to convert this document to a playbook.  This would make this document unchangable except for the owner.  \n\n Please confirm the save to playbook operation.
    COLLABORATIONNOTE:
      'ЕСКЕРТУ: Bu bilgi 1: АРАLYLY BARLADIÝA, SÖZ GÖNÜŞLI YAXINLIK.',
    // ORIGINAL TEXT: NOTE: This information is shared INTERNALLY ONLY with designated collaborators and document owners only.
    NEGOTIATIONNOTE: 'ЕСКЕРТУ: Bu barada bilgi çap edildi.',
    // ORIGINAL TEXT: NOTE: This information is shared EXTERNALLY with negotiation counter parties.
    CONFIRMDELETEALERT:
      'Вертикалдык тақырыптадан өнерге болады. СердиSize Policy power from the shared',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified alert.  After alert is deleted, it will no longer be available.  Please confirm delete operation.
    CONFIRMDELETERECIPIENTALERT:
      'Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a recipient for the specified alert.  After removing yourself as a recipient, you can request to be added back by contacting the owner of the alert.  Please confirm delete operation.
    CONFIRMDELETEGROUP:
      'Жүйеден өшірілгеннен кейін система өшіріледі. Катысушы күшіне енбейт. Колдонылған тастау Гвинеясы болбойт.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified group.  After group is deleted, it will no longer be available.  However, it will reflected the next time you log back in.  Please confirm delete operation.
    CONFIRMDELETECOLLABORATORGROUP:
      'Группаның жардамчысы булып, 18, 1976 фантастиканы алып тастаңыз. Операциялық минем максатыма жеткіліксіз.',
    // ORIGINAL TEXT: Please confirm that you no longer want to be a collaborator for the specified group.  After removing yourself as a collaborator, you can request to be added back by contacting the owner of the group.  Please confirm delete operation.
    CONFIRMDELETEDOCTYPE:
      'ҐәденBack фантастиканы өшіргіңіз килә. Документ өшірілгеннен кейін аның күші жойылады. Операцияны растаңыз.',
    // ORIGINAL TEXT: Please confirm that you want to delete the specified doctype.  After doctype is deleted, it will no longer be available.  Please confirm delete operation.
    EXPIREDTRIAL:
      'Сынау лицензиясының мерзімі өтіп кеткен. Lütfen gullugyňy жалғастырыңыз.',
    // ORIGINAL TEXT: Your trial license has expired.  Please upgrade to continue your service.
    EXPIREDSUBSCRIPTION:
      'About lisenziyaңызның мерзімі өтіп кеткен. Ризалашақпен ең ең < about Ensteinstein',
    // ORIGINAL TEXT: Your subscription license has expired.  Please upgrade to continue your service or contact billing at Docgility.
    SOURCEDOCNOTDOCX:
      'Bu құжат DOCX құжаты емес. Redline Mətta мүмкіндігі жоқ.',
    // ORIGINAL TEXT: The Source document is not a DOCX document.  Redline capabilities are not available.
  },
}

export default TR
