<script>
// state
import { panelComputed } from '@state/helpers'

export default {
  components: {},

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      // showClauseIndicator: true, // show the Clause Indicators (should be excluded if it's A)
    }
  },

  computed: {
    ...panelComputed,
    text() {
      return 'huh'
    },

  },

  watch: {},
  methods: {
    dosomething(){
      console.log('here')
    },
    setLocalParaContext() {
      this.$emit('setLocalParaContext', {OT:this.item.value})
    },


  },
}
</script>

<template>
  <div :class="$style.paragraph">
    <v-container v-if="true" class="container">
      <v-layout row>
        <div class="mx-4 my-2">
          <div v-if="item.title!=''"><b>{{ item.title }}</b></div>
          <div v-if="item.descr!=''" :class="mx-10"><div class="ma-2"><i>{{ item.descr }}</i></div></div>
          <div class="ma-2" :class="$style.dialogInputParagraph" @click.stop="setLocalParaContext">
            <!-- eslint-disable-next-line -->
            <span @click="setLocalParaContext" v-html="item.value">
              <!-- <div v-html="text"></div> -->
            </span>
          </div>
          <div v-if="item.link!=''" align="right"><a :href="item.link" target="_blank">{{ item.link }}</a></div>
        </div>

      </v-layout>
    </v-container>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
