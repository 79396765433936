import axios from 'axios'
import foreach from 'lodash/each'

import getEnv from '@src/utils/env'

class AIHttpService {
  constructor(clientConfig = {}) {
    this.client = axios.create(clientConfig)

    this.responseInterceptors = []
    this.badResponseInterceptors = []
    this.initializeResponseInterceptors()
  }

  attachHeaders({ headers = {} }) {
    Object.assign(this.client.defaults.headers, headers)
  }

  removeHeaders({ headers = [] }) {
    headers.forEach((key) => delete this.client.defaults.headers[key])
  }

  attachResponseInterceptor({ callback }) {
    this.responseInterceptors.push(callback)
  }

  attachBadResponseInterceptor({ callback }) {
    this.badResponseInterceptors.push(callback)
  }

  initializeResponseInterceptors() {
    this.client.interceptors.response.use(
      (response) => {
        foreach(this.responseInterceptors, (i) => {
          i(response)
        })

        return response
      },
      (error) => {
        if (error.response && error.response.status) {
          foreach(this.badResponseInterceptors, (i) => {
            i(error)
          })
        }

        return Promise.reject(error)
      }
    )
  }
}

// address the RC env variable hardcoded for RC with aiapi
export function prependhttps(link) {
  if (link.startsWith('http')) {
    return link
  }
  return 'https://aiapi.' + link
}

const clientConfig = {
  baseURL: prependhttps(
    getEnv('VUE_APP_AIBACKENDSERVERENV') ||
      process.env.VUE_APP_AIBACKENDSERVER ||
      getEnv('VUE_APP_BACKENDSERVERENV') ||
      process.env.VUE_APP_BACKENDSERVER
  ),
}

export default new AIHttpService(clientConfig)
