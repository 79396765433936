<script>
import { docroute } from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
  },

  data: function() {
    return {
      larchivenotes: '',
      lexecutedcontract: false,
      lexecuteddate: '',
      lstartdate: '',
      lenddate: '',
      lclausetopics: false,
      lexcludecollaborators: false,
      lexcludenegotiators: false,

      // flags
      noexecdate: false,
      nostartdate: false,
      noenddate: false,

      // validation rules for data entry
      rules: {
        counter160: (value) =>
          value.length <= 160 || this.$t('VALIDMSGS.MAX160CHARS'),
      },
      valid: false, // check for validation rules
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
  },

  methods: {
    setInitialValues() {
      this.larchivenotes = ''
      this.lexecutedcontract = false
      this.lexecuteddate = ''
      this.lstartdate = ''
      this.lenddate = ''
      this.lclausetopics = false
      this.lexcludecollaborators = false
      this.lexcludenegotiators = false
    },

    movetoArchive() {
      const formdata = {
        archivenotes: this.larchivenotes,
        executedcontract: this.lexecutedcontract,

        executeddate:
          this.noexecdate || !this.lexecutedcontract ? '' : this.lexecuteddate,
        startdate:
          this.nostartdate || !this.lexecutedcontract ? '' : this.lstartdate,
        enddate: this.noenddate || !this.lexecutedcontract ? '' : this.lenddate,

        clausetopics: this.lclausetopics,
        excludecollaborators: this.lexcludecollaborators,
        excludenegotiators: this.lexcludenegotiators,
      }

      this.$emit('archive-document', formdata)

      // reset the form values.
      this.setInitialValues()

      this.showDialog = false
    },
  },
  docroute(d) {
    return docroute(d)
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.MOVETOARCHIVEDOCUMENT')"
        ></PanelCardTitle>
        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-container align-center>
                  <v-flex xs12 md12 lg12 xl12>
                    <v-text-field
                      v-model="larchivenotes"
                      :rules="[rules.counter160]"
                      :class="$style.dialogInputText"
                      :label="$t('DIALOGS.ARCHIVENOTES')"
                    >
                      ></v-text-field
                    >
                  </v-flex>
                </v-container>

                <v-col cols="12">
                  <v-checkbox
                    v-model="lexecutedcontract"
                    :label="$t('DIALOGS.RESULTEXECUTEDCONTRACT')"
                  ></v-checkbox>

                  <div v-if="lexecutedcontract">
                    <v-row>
                      <v-col cols="4" sm="4">
                        <p> Execution Date: </p>
                        <v-checkbox
                          v-model="noexecdate"
                          :label="$t('DIALOGS.NOEXECUTIONDATE')"
                        >
                        </v-checkbox>
                        <v-date-picker
                          v-if="!noexecdate"
                          v-model="lexecuteddate"
                          :label="$t('DIALOGS.EXECUTIONDATE')"
                          color="green lighten-1"
                        ></v-date-picker>
                      </v-col>
                      <v-col cols="4" sm="4">
                        <p> {{ $t('DIALOGS.CONTRACTSTARTDATE') }} : </p>
                        <v-checkbox
                          v-model="nostartdate"
                          :label="$t('DIALOGS.NOSTARTDATE')"
                        >
                        </v-checkbox>
                        <v-date-picker
                          v-if="!nostartdate"
                          v-model="lstartdate"
                          :label="$t('DIALOGS.CONTRACTSTARTDATE')"
                          color="green lighten-1"
                        ></v-date-picker>
                      </v-col>

                      <v-col cols="4" sm="4">
                        <p> {{ $t('DIALOGS.CONTRACTENDDATE') }}: </p>
                        <v-checkbox
                          v-model="noenddate"
                          :label="$t('DIALOGS.NOENDDATE')"
                        >
                        </v-checkbox>
                        <v-date-picker
                          v-if="!noenddate"
                          v-model="lenddate"
                          :label="$t('DIALOGS.CONTRACTENDDATE')"
                          color="green lighten-1"
                        ></v-date-picker>
                      </v-col>
                    </v-row>
                  </div>

                  <v-checkbox
                    v-model="lexcludecollaborators"
                    :label="$t('DIALOGS.EXCLUDECOLLABORATORSFROMACCESS')"
                  ></v-checkbox>

                  <v-checkbox
                    v-model="lexcludenegotiators"
                    :label="$t('DIALOGS.EXCLUDENEGOTIATORSFROMACCESS')"
                  ></v-checkbox>

                  <v-checkbox
                    v-model="lclausetopics"
                    :label="$t('DIALOGS.REVIEWEDAMENDEDCLAUSELABELS')"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('file/movetoarchive')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            large
            outlined
            :disabled="!valid"
            class="mx-4"
            @click="movetoArchive"
            >{{ $t('DIALOGS.MOVETOARCHIVE') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
