import ApiService from './ApiService'
import { camelCaseRecursive } from '@utils/json'

const ENDPOINTS = {
  CLAUSES: '/clause-categories',
  METADATA: '/metadata-extraction/metadata-type',
  PROCESS_DOCUMENT: 'process-document/',
  PROCESS_DOCUMENT_PDF: 'process-pdf-document/',
  PROC_DOC: 'proc-doc/',
  PROC_DOC_PDF: 'proc-pdf-doc/',
  ALERT_EMAIL_GENERATION: 'alert-email-generation/',
  DOCUMENT_TYPES: 'document-type',
  STRIPE_GET_LATEST: 'stripe-get-latest/',
  STRIPE_GET_BILLINGSESSION: 'stripe-get-billingsession/',
  SIGN_NEWREQUEST: 'sign-newrequest',
  SIGN_GETREQUESTSTATUS: 'sign-getrequeststatus',
  SIGN_CANCELREQUEST: 'sign-cancelrequest',
  DOCX_REDLINECREATE: 'docx-redlinecreate',
  DOCX_FINALCREATE: 'docx-finalcreate',
  MONGO_INSERTONE: 'mongo-insertone',
  // MONGO_SETONE: 'mongo-setone',
  MONGO_SETOBJIDONE: 'mongo-setobjidone',
  MONGO_DEACTIVATE: 'mongo-deactivate',
  MONGO_UPDATEOBJ: 'mongo-updateobjonly',
  MONGO_UPDATEOBJARRAY: 'mongo-updateobjonarray',
  MONGO_UPDATEOBJARRAYUNION: 'mongo-updateobjarrayUnion',
  MONGO_UPDATEOBJARRAYREMOVE: 'mongo-updateobjarrayRemove',
  MONGO_UPDATEOBJARRAYOBJUNION: 'mongo-updateobjarrayObjUnion',
  MONGO_UPDATEOBJARRAYOBJREMOVE: 'mongo-updateobjarrayObjRemove',
  MONGO_UPDATEOBJARRAYINCREMENT: 'mongo-updateobjarrayIncrement',
  MONGO_GETOBJSYNC: 'mongo-getobjsync',
  MONGO_GETBYOBJIDSYNC: 'mongo-getbyobjidsync',
  MONGO_INSERTONESYNC: 'mongo-insertsyncone',
  MONGO_SETONESYNC: 'mongo-setsyncone',
  MONGO_SETOBJIDSYNC: 'mongo-setobjidsync',
  MONGO_UPDATEOBJSYNC: 'mongo-updateobjsync',
  MONGO_OWNERGROUP: 'mongo-ownergroup',
  MONGO_COLLABORATORGROUP: 'mongo-collaboratorgroup',
  MONGO_DOCTYPESQUERY1: 'mongo-doctypesquery1',
  MONGO_ALERTSQUERY1: 'mongo-alertsquery1',
  MONGO_ALERTSQUERY2: 'mongo-alertsquery2',
  MONGO_ALERTSQUERY3: 'mongo-alertsquery3',
  MONGO_DOCMETAQUERY1: 'mongo-docmetaquery1',
  MONGO_DOCMETAQUERY2: 'mongo-docmetaquery2',
  MONGO_DOCMETAQUERY3: 'mongo-docmetaquery3',
  MONGO_DOCMETAQUERY4: 'mongo-docmetaquery4',
  MONGO_DOCMETAQUERY5: 'mongo-docmetaquery5',
  MONGO_DOCMETAQUERY6: 'mongo-docmetaquery6',
  MONGO_LISTENERCOMMENTS: 'mongo-listenercomments',
  MONGO_LISTENERDOCEDITS: 'mongo-listenerdocedits',
  MONGO_LISTENERTAGS: 'mongo-listenertags',

  // Authentication
  AUTH_REGISTRATION: 'auth/users/',
  AUTH_LOGIN: 'auth/token/login/',
  AUTH_LOGOUT: 'auth/token/logout/',
  AUTH_REGRESETPASSWORD: 'auth/users/set_password/',
  AUTH_REGRESENDEMAIL: 'auth/users/resend_activation/',
  AUTH_PASSWORDRESET: 'auth/users/reset_password/',

  // Minio interactions
  MINIO_PRESIGNEDURL: 'minio-presignedurl',
  MINIO_COPYOBJECT: 'minio-copyobject',
  MINIO_FPUTOBJECT: 'minio-fputobject',
  MINIO_GETJSONFILE: 'minio-getjsonfile',
  MINIO_GETURLFILE: 'minio-geturlfile',

  // SSO
  SSO_INITIATESIGNIN: 'ssoinitiatesignin',
  // SSO_SIGNIN: 'sso-signin',
  SSO_VALIDATESIGNINKEYCODE: 'ssovalidatesigninkeycode',

  BATCH_ALERTEMAILGENBATCH: 'alert-email-gen-batch',
  BATCH_ALERTEMAILSENDBATCH: 'alert-email-send-batch',
  BATCH_REFERRALEMAILSENDBATCH: 'referral-email-send-batch',

  // INTEGRATIONS
  INTEG_GETGLOBALS: 'integ-getglobals',
  INTEG_RETURNLIST: 'integ-returnlist',
}

class DocumentProcessingService extends ApiService {
  // processDocument({ documentDebug }) {

  //   // this is null - not passed correctly

  //   const document = camelCaseRecursive(documentDebug)
  //   Object.keys(document.clauseId).forEach((paragraphIdx) => {
  //     const clause = document.clauseId[paragraphIdx]
  //     clause.id = `clause-${paragraphIdx}`
  //     // clause.text = clause.clause
  //     delete clause.clause
  //   })
  //   return document
  // }

  // this is the previous format
  // processDocument = (documentDebug) => {
  //   const document = camelCaseRecursive(documentDebug)

  //   Object.keys(document.clauseId).forEach((paragraphIdx) => {
  //     const clause = document.clauseId[paragraphIdx]
  //     clause.id = `clause-${paragraphIdx}`
  //     delete clause.clause
  //   })

  //   return document
  // }

  // uploadDocument({
  //   document,
  //   documentTypeId = 1,
  //   docid,
  //   urlname,
  //   storagefilename,
  //   processingOption = 0,
  // }) {
  //   const formData = new FormData()
  //   // formData.append('document', document)
  //   formData.append('document_type_id', documentTypeId)
  //   formData.append('docid', docid)
  //   formData.append('urlname', urlname)
  //   formData.append('processing_option', processingOption)
  //   return this.apiClient.post(ENDPOINTS.PROCESS_DOCUMENT, formData)
  // }

  // New - move autosaving logic to backend
  procDoc({
    document,
    documentTypeId = 1,
    docid,
    urlname,
    docname,
    owner,
    downloadURL,
    dbauthprivilege,
    processingOption = 0,
  }) {
    const formData = new FormData()
    // formData.append('document', document)
    formData.append('document_type_id', documentTypeId)
    formData.append('docid', docid)
    formData.append('urlname', urlname)
    formData.append('processing_option', processingOption)
    // added to UploadObject
    formData.append('docname', docname)
    formData.append('owner', owner)
    formData.append('downloadURL', downloadURL)
    formData.append('dbauthprivilege', dbauthprivilege)
    return this.apiClient.post(ENDPOINTS.PROC_DOC, formData)
  }

  // uploadPDFDocument({
  //   document,
  //   documentTypeId = 1,
  //   docid,
  //   urlname,
  //   storagefilename,
  //   processingOption = 0,
  // }) {
  //   const formData = new FormData()
  //   // formData.append('document', document)
  //   formData.append('document_type_id', documentTypeId)
  //   formData.append('docid', docid)
  //   formData.append('urlname', urlname)
  //   formData.append('processing_option', processingOption)
  //   return this.apiClient.post(ENDPOINTS.PROCESS_DOCUMENT_PDF, formData)
  // }

  // new
  procPDFDoc({
    document,
    documentTypeId = 1,
    docid,
    urlname,
    processingOption = 0,
    docname,
    owner,
    downloadURL,
    dbauthprivilege,
  }) {
    const formData = new FormData()
    // formData.append('document', document)
    formData.append('document_type_id', documentTypeId)
    formData.append('docid', docid)
    formData.append('urlname', urlname)
    formData.append('processing_option', processingOption)
    // added to UploadObject
    formData.append('docname', docname)
    formData.append('owner', owner)
    formData.append('downloadURL', downloadURL)
    formData.append('dbauthprivilege', dbauthprivilege)

    return this.apiClient.post(ENDPOINTS.PROC_DOC_PDF, formData)
  }

  alertEmailGeneration({ key }) {
    const formData = new FormData()
    formData.append('key', key)
    return this.apiClient.post(ENDPOINTS.ALERT_EMAIL_GENERATION, formData)
  }

  stripeGetLatest({ stripecustid }) {
    const formData = new FormData()
    formData.append('stripecustid', stripecustid)

    return this.apiClient.post(ENDPOINTS.STRIPE_GET_LATEST, formData)
  }
  stripeGetBillingSession({ stripecustid }) {
    const formData = new FormData()
    formData.append('stripecustid', stripecustid)

    return this.apiClient.post(ENDPOINTS.STRIPE_GET_BILLINGSESSION, formData)
  }
  signNewRequest({
    docid,
    uniqid,
    doctitle,
    docsubject,
    docmessage,
    docsigner1name,
    docsigner1email,
    docsigner2name,
    docsigner2email,
    dirname,
  }) {
    const formData = new FormData()
    formData.append('docid', docid)
    formData.append('uniqid', uniqid)
    formData.append('doctitle', doctitle)
    formData.append('docsubject', docsubject)
    formData.append('docmessage', docmessage)
    formData.append('docsigner1name', docsigner1name)
    formData.append('docsigner1email', docsigner1email)
    formData.append('docsigner2name', docsigner2name)
    formData.append('docsigner2email', docsigner2email)
    formData.append('dirname', dirname)
    formData.append('create', new Date())
    return this.apiClient.post(ENDPOINTS.SIGN_NEWREQUEST, formData)
  }
  signGetRequestStatus({ sigrequestid }) {
    const formData = new FormData()
    formData.append('sigrequestid', sigrequestid)
    return this.apiClient.post(ENDPOINTS.SIGN_GETREQUESTSTATUS, formData)
  }

  signCancelRequest({ docid, uniqid, sigrequestid }) {
    const formData = new FormData()
    formData.append('docid', docid)
    formData.append('uniqid', uniqid)
    formData.append('sigrequestid', sigrequestid)
    return this.apiClient.post(ENDPOINTS.SIGN_CANCELREQUEST, formData)
  }

  docxCreateRedline({
    urlname,
    paramods,
    modsource,
    modtarget,
    inputName,
    inputDate,
    overwriteprevredlines,
    outputFileName,
    outputurlname,
  }) {
    const formData = new FormData()
    formData.append('urlname', urlname)
    formData.append('paramods', paramods)
    formData.append('modsource', modsource)
    formData.append('modtarget', modtarget)
    formData.append('inputName', inputName)
    formData.append('inputDate', inputDate)
    formData.append('overwriteprevredlines', overwriteprevredlines)
    formData.append('outputFileName', outputFileName)
    formData.append('outputurlname', outputurlname)

    return this.apiClient.post(ENDPOINTS.DOCX_REDLINECREATE, formData)
  }

  docxCreateFinal({
    urlname,
    paramods,
    modsource,
    modtarget,
    outputFileName,
    outputurlname,
  }) {
    const formData = new FormData()
    formData.append('urlname', urlname)
    formData.append('paramods', paramods)
    formData.append('modsource', modsource)
    formData.append('modtarget', modtarget)
    formData.append('outputFileName', outputFileName)
    formData.append('outputurlname', outputurlname)

    return this.apiClient.post(ENDPOINTS.DOCX_FINALCREATE, formData)
  }

  mongoinsertone({ tablename, obj }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('obj', JSON.stringify(obj))
    return this.apiClient.post(ENDPOINTS.MONGO_INSERTONE, formData)
  }

  // mongosetone({ tablename, id, obj }) {
  //   const formData = new FormData()
  //   formData.append('tablename', tablename)
  //   formData.append('id', id)
  //   formData.append('obj', JSON.stringify(obj))
  //   return this.apiClient.post(ENDPOINTS.MONGO_SETONE, formData)
  // }

  // new
  mongosetobjidone({ tablename, id, obj }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('obj', JSON.stringify(obj))
    return this.apiClient.post(ENDPOINTS.MONGO_SETOBJIDONE, formData)
  }

  mongodeactivate({ tablename, id }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    return this.apiClient.post(ENDPOINTS.MONGO_DEACTIVATE, formData)
  }

  mongoupdateobj({ tablename, id, modobj }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('modobj', JSON.stringify(modobj))
    return this.apiClient.post(ENDPOINTS.MONGO_UPDATEOBJ, formData)
  }

  mongoupdateobjarray({ tablename, id, modobj }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('modobj', JSON.stringify(modobj))
    return this.apiClient.post(ENDPOINTS.MONGO_UPDATEOBJARRAY, formData)
  }

  mongoupdateobjarrayUnion({ tablename, id, field, value }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('field', field)
    formData.append('value', value)
    return this.apiClient.post(ENDPOINTS.MONGO_UPDATEOBJARRAYUNION, formData)
  }

  mongoupdateobjarrayRemove({ tablename, id, field, value }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('field', field)
    formData.append('value', value)
    return this.apiClient.post(ENDPOINTS.MONGO_UPDATEOBJARRAYREMOVE, formData)
  }

  mongoupdateobjarrayObjUnion({ tablename, id, field, value }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('field', field)
    formData.append('value', JSON.stringify(value))
    return this.apiClient.post(ENDPOINTS.MONGO_UPDATEOBJARRAYOBJUNION, formData)
  }

  mongoupdateobjarrayObjRemove({ tablename, id, field, value }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('field', field)
    formData.append('value', JSON.stringify(value))
    return this.apiClient.post(
      ENDPOINTS.MONGO_UPDATEOBJARRAYOBJREMOVE,
      formData
    )
  }

  mongoupdateobjarrayIncrement({ tablename, id, field }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('field', field)
    return this.apiClient.post(
      ENDPOINTS.MONGO_UPDATEOBJARRAYINCREMENT,
      formData
    )
  }

  // sync calls below
  mongogetobjsync({ tablename, id }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    return this.apiClient.post(ENDPOINTS.MONGO_GETOBJSYNC, formData)
  }
  // new
  mongogetbyobjidsync({ tablename, id }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    return this.apiClient.post(ENDPOINTS.MONGO_GETBYOBJIDSYNC, formData)
  }

  mongoinsertonesync({ tablename, obj }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('obj', JSON.stringify(obj))
    return this.apiClient.post(ENDPOINTS.MONGO_INSERTONESYNC, formData)
  }

  mongosetonesync({ tablename, id, obj }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('obj', JSON.stringify(obj))
    return this.apiClient.post(ENDPOINTS.MONGO_SETONESYNC, formData)
  }

  mongosetobjidsync({ tablename, id, obj }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('obj', JSON.stringify(obj))
    return this.apiClient.post(ENDPOINTS.MONGO_SETOBJIDSYNC, formData)
  }

  mongoupdateobjsync({ tablename, id, modobj }) {
    const formData = new FormData()
    formData.append('tablename', tablename)
    formData.append('id', id)
    formData.append('modobj', JSON.stringify(modobj))
    return this.apiClient.post(ENDPOINTS.MONGO_UPDATEOBJSYNC, formData)
  }

  mongoownergroup({ ownerid, gid, action }) {
    const formData = new FormData()
    formData.append('ownerid', ownerid)
    formData.append('gid', gid)
    formData.append('action', action)
    return this.apiClient.post(ENDPOINTS.MONGO_OWNERGROUP, formData)
  }

  mongocollaboratorgroup({ uniqid, gid, action }) {
    const formData = new FormData()
    formData.append('uniqid', uniqid)
    formData.append('gid', gid)
    formData.append('action', action)
    return this.apiClient.post(ENDPOINTS.MONGO_COLLABORATORGROUP, formData)
  }
  mongodoctypesquery1({ uniqid }) {
    const formData = new FormData()
    formData.append('uniqid', uniqid)
    return this.apiClient.post(ENDPOINTS.MONGO_DOCTYPESQUERY1, formData)
  }
  mongoalertsquery1({ uniqid }) {
    const formData = new FormData()
    formData.append('uniqid', uniqid)
    return this.apiClient.post(ENDPOINTS.MONGO_ALERTSQUERY1, formData)
  }
  mongoalertsquery2({ uniqid }) {
    const formData = new FormData()
    formData.append('uniqid', uniqid)
    return this.apiClient.post(ENDPOINTS.MONGO_ALERTSQUERY2, formData)
  }
  mongoalertsquery3({ doctypeid }) {
    const formData = new FormData()
    formData.append('doctypeid', doctypeid)
    return this.apiClient.post(ENDPOINTS.MONGO_ALERTSQUERY3, formData)
  }
  mongodocmetaquery1({ uniqid }) {
    const formData = new FormData()
    formData.append('uniqid', uniqid)
    return this.apiClient.post(ENDPOINTS.MONGO_DOCMETAQUERY1, formData)
  }
  mongodocmetaquery2({ uniqid }) {
    const formData = new FormData()
    formData.append('uniqid', uniqid)
    return this.apiClient.post(ENDPOINTS.MONGO_DOCMETAQUERY2, formData)
  }
  mongodocmetaquery3({ uniqid }) {
    const formData = new FormData()
    formData.append('uniqid', uniqid)
    return this.apiClient.post(ENDPOINTS.MONGO_DOCMETAQUERY3, formData)
  }
  mongodocmetaquery4({ doctypeid, doccategory }) {
    const formData = new FormData()
    formData.append('doctypeid', doctypeid)
    formData.append('doccategory', doccategory)
    return this.apiClient.post(ENDPOINTS.MONGO_DOCMETAQUERY4, formData)
  }
  mongodocmetaquery5({ doctypeid }) {
    const formData = new FormData()
    formData.append('doctypeid', doctypeid)
    return this.apiClient.post(ENDPOINTS.MONGO_DOCMETAQUERY5, formData)
  }
  mongodocmetaquery6({ uniqid, relationship }) {
    const formData = new FormData()
    formData.append('uniqid', uniqid)
    formData.append('relationship', relationship)
    return this.apiClient.post(ENDPOINTS.MONGO_DOCMETAQUERY6, formData)
  }
  mongolistenerComments({ docid }) {
    const formData = new FormData()
    formData.append('docid', docid)
    return this.apiClient.post(ENDPOINTS.MONGO_LISTENERCOMMENTS, formData)
  }
  mongolistenerDocEdits({ docid }) {
    const formData = new FormData()
    formData.append('docid', docid)
    return this.apiClient.post(ENDPOINTS.MONGO_LISTENERDOCEDITS, formData)
  }
  mongolistenerTags({ docid }) {
    const formData = new FormData()
    formData.append('docid', docid)
    return this.apiClient.post(ENDPOINTS.MONGO_LISTENERTAGS, formData)
  }

  // registration
  authRegistration({ username, email, password }) {
    const formData = new FormData()
    formData.append('username', username)
    formData.append('email', email)
    formData.append('password', password)

    return this.apiClient.post(ENDPOINTS.AUTH_REGISTRATION, formData)
  }

  // authentication calls
  authLogin({ username, email, password }) {
    const formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)
    return this.apiClient.post(ENDPOINTS.AUTH_LOGIN, formData)
  }

  //  Change Password
  authRegResetPassword({ current, newpass, token }) {
    const formData = new FormData()
    formData.append('current_password', current)
    formData.append('new_password', newpass)

    this.http.attachHeaders({ Authorization: `Token ${token}` })
    return this.apiClient.post(ENDPOINTS.AUTH_REGRESETPASSWORD, formData)
  }

  // Reset Password (via Email)
  authPasswordReset({ email }) {
    const formData = new FormData()
    formData.append('email', email)

    return this.apiClient.post(ENDPOINTS.AUTH_PASSWORDRESET, formData)
  }

  // Resent Auth Email
  authRegResendEmail({ email }) {
    const formData = new FormData()
    formData.append('email', email)

    return this.apiClient.post(ENDPOINTS.AUTH_REGRESENDEMAIL, formData)
  }

  // Minio - presignedul
  miniopresignedurl({ filename }) {
    const formData = new FormData()
    formData.append('filename', filename)
    return this.apiClient.post(ENDPOINTS.MINIO_PRESIGNEDURL, formData)
  }

  // DocumentProcessingService.miniopresignedurl({
  //   filename: filename,
  // }).then(function(data) {
  //   resolve(data.url)
  // })

  // Minio - copyobject
  miniocopyobject({ to, from }) {
    const formData = new FormData()
    formData.append('to', to)
    formData.append('from', from)
    return this.apiClient.post(ENDPOINTS.MINIO_COPYOBJECT, formData)
  }

  // DocumentProcessingService.miniocopyobject({
  //   to: to,
  //   from: from,
  // })

  // Minio - fputobject
  miniofputobject({ filename, e }) {
    const formData = new FormData()
    formData.append('filename', filename)
    formData.append('e', e)
    return this.apiClient.post(ENDPOINTS.MINIO_FPUTOBJECT, formData)
  }

  miniogetjsonfile({ filename }) {
    const formData = new FormData()
    formData.append('filename', filename)
    return this.apiClient.post(ENDPOINTS.MINIO_GETJSONFILE, formData)
  }

  miniogeturlfile({ urlname }) {
    const formData = new FormData()
    formData.append('urlname', urlname)
    // try this.
    // this.http.attachHeaders({ responseType: 'arraybuffer' })
    // adding the header as a third variable seems to work now.
    return this.apiClient.post(ENDPOINTS.MINIO_GETURLFILE, formData, {
      responseType: 'arraybuffer',
    })
  }

  // SSO
  SSOinitiatesignin({}) {
    const formData = new FormData()
    return this.apiClient.post(ENDPOINTS.SSO_INITIATESIGNIN, formData)
  }
  // SSOsignin({ tablename, id, obj }) {
  //   const formData = new FormData()
  //   formData.append('tablename', tablename)
  //   formData.append('id', id)
  //   formData.append('obj', JSON.stringify(obj))
  //   return this.apiClient.post(ENDPOINTS.SSO_SIGNIN, formData)
  // }
  SSOvalidatesigninkeycode({ userid, keycode }) {
    const formData = new FormData()
    formData.append('userid', userid)
    formData.append('keycode', keycode)
    return this.apiClient.post(ENDPOINTS.SSO_VALIDATESIGNINKEYCODE, formData)
  }

  // BATCH JOBS (triggered from UX in enterprise)
  BATCHalertemailgenbatch({}) {
    const formData = new FormData()
    return this.apiClient.post(ENDPOINTS.BATCH_ALERTEMAILGENBATCH, formData)
  }
  BATCHalertemailsendbatch({}) {
    const formData = new FormData()
    return this.apiClient.post(ENDPOINTS.BATCH_ALERTEMAILSENDBATCH, formData)
  }
  BATCHreferralemailsendbatch({}) {
    const formData = new FormData()
    return this.apiClient.post(ENDPOINTS.BATCH_REFERRALEMAILSENDBATCH, formData)
  }

  INTEGgetglobals({}) {
    const formData = new FormData()
    return this.apiClient.post(ENDPOINTS.INTEG_GETGLOBALS, formData)
  }

  INTEGreturnlist({
    integname,
    integtype,
    integcreds,
    integparas,
    integspecs,
    paratype,
    paratext,
    paratopic,
  }) {
    const formData = new FormData()
    formData.append('integname', integname)
    formData.append('integtype', integtype)
    formData.append('integcreds', integcreds)
    formData.append('integparas', integparas)
    formData.append('integspecs', integspecs)
    formData.append('paratype', paratype)
    formData.append('paratext', paratext)
    formData.append('paratopic', paratopic)
    return this.apiClient.post(ENDPOINTS.INTEG_RETURNLIST, formData)
  }

  // DocumentProcessingService.miniofputobject({
  //   filename: filename,
  //   e: e,
  // }).then(function (data) {
  //   resolve(data.url)
  // })

  // probably the same as RegResendEmail
  // authRegVerifyEmail({ key }) {
  //   const formData = new FormData()
  //   formData.append('key', key)
  //   return this.apiClient.post(ENDPOINTS.AUTH_REGVERIFYEMAIL, formData)
  // }
  // authTokenVerify({ token }) {
  //   const formData = new FormData()
  //   formData.append('token', token)
  //   return this.apiClient.post(ENDPOINTS.AUTH_TOKENVERIFY, formData)
  // }
  // authTokenRefresh({ token }) {
  //   const formData = new FormData()
  //   formData.append('token', token)

  //   return this.apiClient.post(ENDPOINTS.AUTH_TOKENREFRESH, formData)
  // }

  // not used
  // authLogout() {
  //   const formData = new FormData()
  //   // Nothing is sent
  //   return this.apiClient.post(ENDPOINTS.AUTH_LOGOUT, formData)
  // }
}

export default new DocumentProcessingService()
