import DocumentProcessingService from '@services/DocumentProcessingService'

import store from '@state/store'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  // Adding the services for Signing to panels
  async docxCreateRedline({ commit }, args = {}) {
    // Upload the document (sync call, wait for job id to come back)
    const { data } = await DocumentProcessingService.docxCreateRedline({
      urlname: args.urlname,
      paramods: args.paramods,
      modsource: args.modsource,
      modtarget: args.modtarget,
      inputName: args.inputName,
      inputDate: args.inputDate,
      overwriteprevredlines: args.overwriteprevredlines,
      outputFileName: args.outputFileName,
      outputurlname: args.outputurlname,
    })

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: args.docid,
      uniqid: args.uniqid,
      logdetail: {
        action: 'DOCEVENTS.CREATEREDLINEREQUEST',
        detail: '',
        // detail: data.fileName,
        context: '',
      },
    })

    return data.fileName
  },

  async docxCreateFinal({ commit }, args = {}) {
    const { data } = await DocumentProcessingService.docxCreateFinal({
      urlname: args.urlname,
      paramods: args.paramods,
      modsource: args.modsource,
      modtarget: args.modtarget,
      outputFileName: args.outputFileName,
      outputurlname: args.outputurlname,
    })

    // log
    store.dispatch('dblogdoc/addLogDoc', {
      docid: args.docid,
      uniqid: args.uniqid,
      logdetail: {
        action: 'DOCEVENTS.CREATEFINALDOCREQUEST',
        detail: '',
        context: '',
      },
    })

    return data.fileName
  },
}
