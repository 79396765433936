<script>
import { panelComputed } from '@state/helpers'

import '@design'

export default {
  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    authlevel: {
      type: String,
      default: () => 'A',
    },
    // data: {
    //   type: Object,
    //   required: true,
    // },
    ind: {
      type: Number,
      default: () => 0,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    originaltext: {
      type: Object,
      required: true,
    },
    compressed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    showMenu: false,
    turnOn: false,
  }),

  computed: {
    ...panelComputed,

    dataInput() {
      return this.docRawData[this.panelno].clauseId[this.ind]
    },

    ifNegotiationAvail() {
      return this.localpanelstate.NegotiationAvail
    },

    collabcommentn() {
      if (
        Array.isArray(this.editComments[this.panelno]) &&
        this.editComments[this.panelno].length > 0
      ) {
        return this.editComments[this.panelno]
          .filter((e) => e.type === 'COLLABORATION')
          .filter((e) => e.paraid === this.ind.toString())
          .filter((e) => e.active === true).length
      } else {
        return 0
      }
    },

    collabdoceditn() {
      if (
        Array.isArray(this.editDocedits[this.panelno]) &&
        this.editDocedits[this.panelno].length > 0
      ) {
        return this.editDocedits[this.panelno]
          .filter((e) => e.type === 'COLLABORATION')
          .filter((e) => e.paraid === this.ind.toString())
          .filter((e) => e.active === true).length
      } else {
        return 0
      }
    },

    negotcommentn() {
      if (
        Array.isArray(this.editComments[this.panelno]) &&
        this.editComments[this.panelno].length > 0
      ) {
        return this.editComments[this.panelno]
          .filter((e) => e.type === 'NEGOTIATION')
          .filter((e) => e.paraid === this.ind.toString())
          .filter((e) => e.active === true).length
      } else {
        return 0
      }
    },

    negotdoceditn() {
      if (
        Array.isArray(this.editDocedits[this.panelno]) &&
        this.editDocedits[this.panelno].length > 0
      ) {
        return this.editDocedits[this.panelno]
          .filter(
            (e) =>
              e.type === 'NEGOTIATION' ||
              (e.type === 'COLLABORATION' && e.approved)
          )
          .filter((e) => e.paraid === this.ind.toString())
          .filter((e) => e.active === true).length
      } else {
        return 0
      }
    },

    labeln() {
      if (
        Array.isArray(this.editTags[this.panelno]) &&
        this.editTags[this.panelno].length > 0
      ) {
        return this.editTags[this.panelno]
          .filter((e) => e.paraid === this.ind.toString())
          .filter((e) => e.tag !== 'HIGH-RISK')
          .filter((e) => e.active === true).length
      } else {
        return 0
      }
    },

    riskn() {
      if (
        Array.isArray(this.editTags[this.panelno]) &&
        this.editTags[this.panelno].length > 0
      ) {
        return this.editTags[this.panelno]
          .filter((e) => e.paraid === this.ind.toString())
          .filter((e) => e.tag === 'HIGH-RISK')
          .filter((e) => e.active === true).length
      } else {
        return 0
      }
    },

    resolven() {
      if (
        Array.isArray(this.editTags[this.panelno]) &&
        this.editTags[this.panelno].length > 0
      ) {
        return this.editTags[this.panelno]
          .filter((e) => e.paraid === this.ind.toString())
          .filter((e) => e.tag === 'RESOLVED')
          .filter((e) => e.active === true).length
      } else {
        return 0
      }
    },

    collabAvailPriv() {
      // added ifSaved to logic to turn on buttons.
      return (
        !this.localpanelstate.negotiatorPriv && this.localpanelstate.ifSaved
      )
    },

    // based on subscription access, this may not be turned on.
    tagsAvailPriv() {
      return this.authlevel >= 'G' && this.collabAvailPriv
    },
    icontype() {
      if (this.compressed) {
        return 'mdi-arrow-expand'
      } else {
        return 'mdi-arrow-collapse'
      }
    },
    // include the index in the para.
    wrappedActionPara() {
      return {
        OT: this.originaltext[this.ind].OT,
        ind: this.ind.toString(),
      }
    },
  },
  mounted: function() {
    this.$nextTick(function() {
      setTimeout(this.activateTags, 3000)
    })
  },

  methods: {
    activateTags() {
      this.turnOn = true
    },
    show(e) {
      this.showMenu = false
    },
    showCollabCommentsPanel() {
      this.$emit('showCollabCommentsPanel', this.wrappedActionPara)
    },
    showCollabDocEditsPanel() {
      this.$emit('showCollabDocEditsPanel', this.wrappedActionPara)
    },

    showNegotCommentsPanel() {
      this.$emit('showNegotCommentsPanel', this.wrappedActionPara)
    },
    showNegotDocEditsPanel() {
      this.$emit('showNegotDocEditsPanel', this.wrappedActionPara)
    },

    showTagsPanel() {
      this.$emit('showTagsPanel', this.wrappedActionPara)
    },
    changelcompressed() {
      this.$emit('changelcompressed')
    },
  },
}
</script>
<template>
  <div class="text-center">
    <v-btn x-small text class="py-3" @click="changelcompressed">
      <v-icon size="15">{{ icontype }}</v-icon>
    </v-btn>
    <v-chip
      v-if="turnOn && collabAvailPriv && !(compressed && collabcommentn === 0)"
      text-color="white"
      :class="{
        'grey lighten-2': collabcommentn === 0,
        'blue darken-3': collabcommentn > 0,
      }"
      @click="showCollabCommentsPanel"
    >
      <v-icon color="white" left>mdi-comment-text-multiple-outline</v-icon>
      {{ collabcommentn }}
    </v-chip>
    <v-chip
      v-if="turnOn && collabAvailPriv && !(compressed && collabdoceditn === 0)"
      text-color="white"
      :class="{
        'grey lighten-2': collabdoceditn === 0,
        'green darken-3': collabdoceditn > 0,
      }"
      @click="showCollabDocEditsPanel"
    >
      <v-icon color="white" left>mdi-account-edit-outline</v-icon>
      {{ collabdoceditn }}
    </v-chip>
    <v-chip
      v-if="turnOn && tagsAvailPriv && !(compressed && labeln + riskn === 0)"
      text-color="white"
      :class="{
        'grey lighten-2': labeln === 0 && riskn === 0,
        'green darken-1': resolven > 0,
        'red darken-2': riskn > 0 && resolven === 0,
        'yellow darken-2': labeln > 0 && riskn === 0 && resolven === 0,
      }"
      @click="showTagsPanel"
    >
      <v-icon color="white" left>mdi-alert-circle-outline</v-icon>
      {{ labeln + riskn }}
    </v-chip>
    <v-chip
      v-if="
        turnOn && !(compressed && negotcommentn === 0) && ifNegotiationAvail
      "
      label
      text-color="white"
      :class="{
        'grey lighten-2': negotcommentn === 0,
        'blue darken-3': negotcommentn > 0,
        'mt-1': true,
      }"
      @click="showNegotCommentsPanel"
    >
      <v-icon color="white" left>mdi-comment-text-multiple-outline</v-icon>
      {{ negotcommentn }}
    </v-chip>
    <v-chip
      v-if="
        turnOn && !(compressed && negotdoceditn === 0) && ifNegotiationAvail
      "
      label
      text-color="white"
      :class="{
        'grey lighten-2': negotdoceditn === 0,
        'green darken-3': negotdoceditn > 0,
      }"
      @click="showNegotDocEditsPanel"
    >
      <v-icon color="white" left>mdi-account-edit-outline</v-icon>
      {{ negotdoceditn }}
    </v-chip>
  </div>
</template>
<style lang="scss" module>
@import '@design';
</style>
