<script>
import { docroute } from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },

    //
    // FYI - Need to pass each field in individually - VuejS objects to mutability of objects and arrays.
    docname: {
      type: String,
      required: true,
    },
    docdescription: {
      type: String,
      required: true,
    },
    docparty: {
      type: String,
      required: true,
    },
    doccounterparty: {
      type: String,
      required: true,
    },
    docallowcollclauselabels: {
      type: Boolean,
      required: true,
    },
    docenableNegotiation: {
      type: Boolean,
      required: true,
    },
  },

  data: function() {
    return {
      // this never works to pass defaults to local variables/
      // previously, this was just saved in the form, it didn't necessarily retrieve it.
      // when this instantiated, it needs to populated with the right defaults for the panel.
      // save operation works but not the local copy to a default variable.

      // this does not seem to work at all to set the initial defaults.  instead, use setInitialValues()
      ldocname: this.docname,
      ldocdescription: this.docdescription,
      ldocparty: this.docparty,
      ldoccounterparty: this.doccounterparty,
      ldocallowcollclauselabels: this.docallowcollclauselabels,
      ldocenableNegotiation: this.docenableNegotiation,

      lkeepdocnotes: false,

      // validation rules for data entry
      rules: {
        required: (value) => !!value || this.$t('VALIDMSGS.REQUIRED'),
        counter80: (value) =>
          value.length <= 80 || this.$t('VALIDMSGS.MAX80CHARS'),
        counter160: (value) =>
          value.length <= 160 || this.$t('VALIDMSGS.MAX160CHARS'),
      },
      valid: false, // check for validation rules
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
    cdocname() {
      return this.docname
    },
  },
  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  // for some reason, values are passed correctly, but values are not always passed to local variables.
  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
  },
  methods: {
    setInitialValues() {
      this.ldocname = this.docname
      this.ldocdescription = this.docdescription
      this.ldocparty = this.docparty
      this.ldoccounterparty = this.doccounterparty
      this.ldocallowcollclauselabels = this.docallowcollclauselabels
      this.ldocenableNegotiation = this.docenableNegotiation
      this.lkeepdocnotes = false
    },

    setNullValues() {
      // reset all the local forms for the next time it's accessed.
      this.ldocname = ''
      this.ldocdescription = ''
      this.ldocparty = ''
      this.ldoccounterparty = ''
      this.ldocallowcollclauselabels = false
      this.ldocenableNegotiation = false
      this.lkeepdocnotes = false
    },

    copyDocument() {
      const formdata = {
        docname: this.ldocname,
        docdescription: this.ldocdescription,
        docparty: this.ldocparty,
        doccounterparty: this.ldoccounterparty,
        docother: '',
        docallowcollclauselabels: this.ldocallowcollclauselabels,
        docenableNegotiation: this.ldocenableNegotiation,
        keepdocnotes: this.lkeepdocnotes,
      }

      this.$emit('copy-document', formdata)
      this.setNullValues()
    },
    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.COPYDOCUMENT')"
        ></PanelCardTitle>
        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="ldocname"
                    :rules="[rules.required, rules.counter80]"
                    :label="$t('DIALOGS.DOCNAME')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldocdescription"
                    :rules="[rules.counter160]"
                    :label="$t('DIALOGS.DOCDESCRIPTION')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldocparty"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.DOCPARTY')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ldoccounterparty"
                    :rules="[rules.counter80]"
                    :label="$t('DIALOGS.DOCCOUNTERPARTY')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="ldocallowcollclauselabels"
                    :label="$t('DIALOGS.ENABLECOLLABTOMODLABELS')"
                  ></v-checkbox>
                  <!-- </v-col>
              <v-col cols="12"> -->
                  <v-checkbox
                    v-model="ldocenableNegotiation"
                    :label="$t('DIALOGS.ENABLENEGOTCAPABILITIES')"
                  ></v-checkbox>

                  <v-checkbox
                    v-model="lkeepdocnotes"
                    :label="$t('DIALOGS.KEEPDOCNOTES')"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('file/copydocument')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            large
            outlined
            :disabled="!valid"
            class="mx-4"
            @click="copyDocument"
            >{{ $t('DIALOGS.COPY') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
