var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('PanelCardTitle',{attrs:{"cardtitle":_vm.$t('DIALOGS.DOCUMENTINFO') + ' - ' + _vm.documentTypeDisplay}}),_c('v-card-text',[_c('v-container',[(!_vm.localpanelstate.ifDocLoaded)?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('MESSAGES.NODOCUMENTLOADED'))+" ")])]):_vm._e(),(_vm.localpanelstate.ifDocLoaded)?_c('div',[_c('hr'),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.DOCUMENTDETAILS')))])]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.NAME'))+":")]),_vm._v(" "+_vm._s(_vm.docmetaobj.docname)+" ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.DOCDESCRIPTION'))+":")]),_vm._v(" "+_vm._s(_vm.docmetaobj.docdescription)+" ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.DOCPARTY'))+":")]),_vm._v(" "+_vm._s(_vm.docmetaobj.docparty)+" ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.DOCCOUNTERPARTY'))+":")]),_vm._v(" "+_vm._s(_vm.docmetaobj.doccounterparty)+" ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.DOCID'))+":")]),_vm._v(" "+_vm._s(_vm.panelstated.docidD)+" ")]),(_vm.localpanelstate.ifSaved)?_c('div',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.CREATIONDATE'))+":")]),_vm._v(" "+_vm._s(_vm.formatDateDialog(_vm.docmetaobj.create))+" ")])]):_vm._e(),(_vm.docFromTemplate)?_c('div',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.TEMPLATEORIGDOCNAME')))]),_vm._v(" "+_vm._s(_vm.templateorigdocname)+" ")])]):_vm._e(),_c('hr'),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.PARTICIPANTS')))])]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.OWNERS'))+":")])]),_vm._l((_vm.docmetaobj.ownerid),function(owner){return _c('li',{key:owner},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.displayUserName(owner)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.displayUserInfo(owner)))])])],1)}),_c('p'),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.COLLABORATORS'))+":")])]),_vm._l((_vm.docmetaobj.externalcollaborators),function(collaborator){return _c('li',{key:collaborator},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.displayUserName(collaborator)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.displayUserInfo(collaborator)))])])],1)}),_c('p'),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.NEGOTIATORS'))+":")])]),_c('ul',_vm._l((_vm.docmetaobj.externalnegotiators),function(negotiator){return _c('li',{key:negotiator},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.displayUserName(negotiator)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.displayUserInfo(negotiator)))])])],1)}),0),_c('hr'),_c('p'),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.COLLABORATIONANDNEGOTIATION')))])]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.PRIVILEGE'))+":")]),_vm._v(" "+_vm._s(_vm.displayPrivilege(_vm.panelstated.privilegeD)))]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.STATUS'))+":")]),_vm._v(" "+_vm._s(_vm.displayDocState(_vm.panelstated.docStateD)))]),(!_vm.localpanelstate.ifArchivedState)?_c('div',[_c('p'),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.COLLABORATIONSTATUS'))+":")]),_vm._v(" "+_vm._s(_vm.docmetaobj.collabstatus))]),(_vm.docmetaobj.collabstatus === 'OPEN')?_c('div',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.COLLABORATIONEND'))+":")]),_vm._v(" "+_vm._s(_vm.docmetaobj.collabend)+" ")])]):_vm._e(),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.NEGOTIATIONSTATUS'))+":")]),_vm._v(" "+_vm._s(_vm.docmetaobj.negotiationstatus))]),(_vm.docmetaobj.negotiationstatus === 'OPEN')?_c('div',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.NEGOTIATIONEND'))+":")]),_vm._v(" "+_vm._s(_vm.docmetaobj.negotiationend)+" ")])]):_vm._e(),_c('hr')]):_vm._e(),_c('hr'),(
                _vm.localpanelstate.ifDocType &&
                  !_vm.localpanelstate.negotiatorPriv &&
                  _vm.localpanelstate.docTypeofDoc !== null
              )?_c('div',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.DOCTYPEANDGROUPACCESS')))])]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.GROUPNAME'))+":")]),_vm._v(" "+_vm._s(_vm.localpanelstate.docTypeofDoc.group)+" ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.GROUPDESC'))+":")]),_vm._v(" "+_vm._s(_vm.localpanelstate.docTypeofDoc.description)+" ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.DOCTYPE'))+":")]),_vm._v(" "+_vm._s(_vm.localpanelstate.docTypeDetailofDoc.doctype)+" ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.DOCTYPEDESC'))+":")]),_vm._v(" "+_vm._s(_vm.localpanelstate.docTypeDetailofDoc.description)+" ")]),_c('p'),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.GROUPOWNERS'))+":")])]),_c('ul',_vm._l((_vm.localpanelstate.docTypeofDoc.ownerid),function(owner){return _c('li',{key:owner},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.displayUserName(owner)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.displayUserInfo(owner)))])])],1)}),0),_c('p'),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('DIALOGS.GROUPCOLLABORATORS'))+":")])]),_c('ul',_vm._l((_vm.localpanelstate.docTypeofDoc.uniqid),function(collaborator){return _c('li',{key:collaborator},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.displayUserName(collaborator)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.displayUserInfo(collaborator)))])])],1)}),0)]):_vm._e(),_c('hr'),(_vm.showDebug)?_c('div',[_c('p',[_vm._v("DERIVED STATES:")]),_c('p',[_vm._v(" ---- docmetaobj: "+_vm._s(_vm.docmetaobj)+" ")]),_c('p',[_vm._v(" ---- localpanelstate: "+_vm._s(_vm.localpanelstate)+" ")]),_c('p',[_vm._v(" ---- panelstated: "+_vm._s(_vm.panelstated)+" ")]),_c('p',[_vm._v("AUTH STATES:")]),_c('p',[_vm._v(" ---- dbauthuser: "+_vm._s(_vm.dbauthuser)+" ")]),_c('p',[_vm._v(" ---- dbauthProfile: "+_vm._s(_vm.dbauthProfile)+" ")]),_c('p',[_vm._v(" ---- dbauthGroupsAccess: "+_vm._s(_vm.dbauthGroupsAccess)+" ")]),_c('p',[_vm._v("---- dbauthPrivileges: "+_vm._s(_vm.dbauthPrivileges)+" ")]),_c('p',[_vm._v("---- dbauthPrivUsage: "+_vm._s(_vm.dbauthPrivUsage)+" ")]),_c('p',[_vm._v("---- dbauthDocTypes: "+_vm._s(_vm.dbauthDocTypes)+" ")])]):_vm._e()],2):_vm._e()])],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.docroute('#profile')}}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1),_c('v-spacer'),(_vm.Debug)?_c('v-btn',{staticClass:"mx-4",attrs:{"large":"","outlined":""},on:{"click":function($event){_vm.showDebug = !_vm.showDebug}}},[_vm._v("DEBUG")]):_vm._e(),_c('v-btn',{staticClass:"mx-4 grey lighten-2",attrs:{"large":"","outlined":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(_vm._s(_vm.$t('DIALOGS.BACK')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }