<script>
import { dbauthComputed } from '@state/helpers'

import { docroute } from '@utils/dialogutils'

// Not recognizing this as a function
import { downloadCloudFile, genWord } from '@utils/panel-actions.js'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data: function() {
    return {
      doc1urlname: 'sampledocs/MSA.docx',
      doc1filename: 'MSA.docx',
      doc2urlname: 'sampledocs/Sales.docx',
      doc2filename: 'Sales.docx',
      doc3urlname: 'sampledocs/Purchase.docx',
      doc3filename: 'Purchase.docx',
      doc4urlname: 'sampledocs/License.docx',
      doc4filename: 'License.docx',
    }
  },

  computed: {
    ...dbauthComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
  },

  watch: {},

  methods: {
    // relay
    docroute(d) {
      return docroute(d)
    },
    downloadCloudFile(d, e) {
      return downloadCloudFile(d, e)
    },
    genWordAct(d) {
      return genWord(d)
    },

    download1() {
      // this.genWordAct('0IQZf2tLNV2aK3KHsIoQ')
      this.downloadCloudFileAct(this.doc1urlname, this.doc1filename)
    },
    download2() {
      // downloadCloudFile(urlname, filename)
    },
    launchQuickStart() {
      this.$emit('launchquickstart')
      this.showDialog = false
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="1200px">
    <v-card>
      <PanelCardTitle :cardtitle="$t('DIALOGS.QUICKSTART')"></PanelCardTitle>
      <v-container fluid>
        <div class="mx-4 mt-4">
          <v-container fluid>
            <h4> {{ $t('MESSAGES.QSINTROMESSAGE') }} </h4>
            <v-card-actions class="justify-center">
              <div class="mx-4 mt-4">
                <h4>
                  {{ $t('MESSAGES.QSDOCMESSAGE') }}
                </h4>
              </div>

              <v-btn
                class="mx-3"
                @click="downloadCloudFile(doc1urlname, doc1filename)"
              >
                MSA
              </v-btn>
              <v-btn
                class="mx-3"
                @click="downloadCloudFile(doc2urlname, doc2filename)"
              >
                Sales
              </v-btn>

              <v-btn
                class="mx-3"
                @click="downloadCloudFile(doc3urlname, doc3filename)"
              >
                Purchase
              </v-btn>
              <v-btn
                class="mx-3"
                @click="downloadCloudFile(doc4urlname, doc4filename)"
              >
                License
              </v-btn>
            </v-card-actions>
          </v-container>
        </div>

        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <div class="mt-4">
            <p>{{ $t('MESSAGES.QSHELPMESSAGE') }} </p>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="launchQuickStart"
            >{{ $t('DIALOGS.QUICKSTARTVIDEO') }}</v-btn
          >
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" module>
@import '@design';
</style>
