<script>
import { dbauthComputed } from '@state/helpers'
// import { StripeCheckout } from 'vue-stripe-checkout'

// promotion codes do not work via Checkout (client-only install)
// below does not work.
//             :allow-promotion-codes="true"
// To recap, Client-only Checkout does not support promo codes. It only works for Server-client integration. As an alternative, you can use Payment Links. It supports promo codes:

// https://stripe.com/docs/payments/payment-links

// If you wish to use the Server-Client Checkout, you can refer to this guide:

// https://stripe.com/docs/checkout/quickstart
// https://stripe.com/docs/payments/checkout
// logs are available here
// https://dashboard.stripe.com/logs?method[0]=post&method[1]=delete&direction[0]=connect_in&direction[1]=self
// Vue stripe checkout plug-in
// https://docs.vuestripe.com/vue-stripe/stripe-checkout/getting-started
//

import { StripeCheckout } from '@vue-stripe/vue-stripe'

import { formatDateDialog } from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: {
    StripeCheckout,
    PanelCardTitle,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data: function() {
    return {
     lineitemsBasic: [
        {
          price: process.env.VUE_APP_STRIPEBASICPRICE,
          quantity: 1,
        },
      ],
      lineitemsPremium: [
        {
          price: process.env.VUE_APP_STRIPEPREMIUMPRICE,
          quantity: 1,
        },
      ],

      mode: 'subscription',

      successUrl: 'http://app.docgility.com/success',
      cancelUrl: 'http://app.docgility.com/cancel',
    }
  },

  computed: {
    ...dbauthComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },

    customeremail() {
      if (this.dbauthuser !== null) {
        return this.dbauthuser.uniqid
      } else {
        return ''
      }
    },

    subscriptionStatusType() {
      if (this.dbauthPrivileges !== null && this.dbauthProfile !== null) {
        return 'Current Subscription: ' + this.dbauthPrivileges.name
      }
      return ''
    },
    subscriptionStatusEnd() {
      // if it's an enterprise instance, don't consider trial dates
      if (process.env.VUE_APP_INSTANCETYPE === 'ENTERPRISE') {
        return ''
      }
      if (this.dbauthPrivileges !== null && this.dbauthProfile !== null) {
        if (this.dbauthPrivileges.type >= 'C') {
          return 'Next Billing Cycle: ' + this.dbauthProfile.subscriptionend
        } else {
          return (
            'Trial Expiration: ' +
            formatDateDialog(this.dbauthProfile.trailexpdate)
          )
        }
      }
      return ''
    },

    memberCode() {
      if (
        this.dbauthProfile !== null &&
        this.dbauthProfile.memberid !== null &&
        this.dbauthProfile.memberid !== undefined &&
        this.dbauthProfile.memberid !== ''
      ) {
        if (this.dbauthProfile.memberid.substring(0, 3) === 'ABA') {
          return 'ABA Member - 20% off'
        }
      }
      return ''
    },

    // auto-populates the discount code if they filled out a subscription id.
    // still not correctly triggering the checkout to use the discount code.
    // discountCode() {
    //   if (this.memberCode === 'ABA Member - 20% off') {
    //     return [
    //       {
    //         coupon: 'ABAMEMBER',
    //       },
    //     ]
    //   }
    //   return ''
    // },
    // changed STRIPE KEY to computed
    stripepubkey() {
      return process.env.VUE_APP_STRIPEPUBKEY
    },
    currency() {
      if (process.env.VUE_APP_PRICECURRENCY === 'dollar') {
        return '$'
      } else {
        return '€'
      }
    },
  },

  watch: {},

  methods: {
    checkoutBasic() {
      this.$refs.checkoutRefBasic.redirectToCheckout()
    },
    checkoutCollaborator() {
      this.$refs.checkoutRefPremium.redirectToCheckout()
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="1200px">
    <v-card>
      <PanelCardTitle
        :cardtitle="$t('DIALOGS.UPGRADESUBSCRIPTION')"
      ></PanelCardTitle>
      <v-container fluid>
        <v-row dense>
          <StripeCheckout
            v-show="false"
            ref="checkoutRefBasic"
            :pk="stripepubkey"
            :line-items="lineitemsBasic"
            :success-url="successUrl"
            :cancel-url="cancelUrl"
            :mode="mode"

            :client-reference-id="customeremail"
            :customer-email="customeremail"
          >
          </StripeCheckout>

          <StripeCheckout
            v-show="false"
            ref="checkoutRefPremium"
            :pk="stripepubkey"
            :line-items="lineitemsPremium"
            :success-url="successUrl"
            :cancel-url="cancelUrl"
            :mode="mode"

            :client-reference-id="customeremail"
            :customer-email="customeremail"
          >
          </StripeCheckout>
          <v-col col="4">
            <v-card class="mx-auto" max-width="360">
              <v-img src="small-group.png" height="300px"></v-img>

              <v-card-title>
                <h3>Basic Subscription</h3>
              </v-card-title>

              <v-card-subtitle>
                <p> </p>
                <p> </p>
                <ul
                  ><b>
                    <li>Save and Retrieve Documents </li>
                    <li>Compare 3 documents side-by-side </li>
                    <li>Process up 25 docs per month with Legal AI</li>
                    <li>Basic Collaboration and Negotiation</li>
                  </b>
                </ul>
                <p> </p>
                <p> </p>
                <h3
                  ><b>Price: {{ currency }}99 per month</b></h3
                >
              </v-card-subtitle>

              <v-card-actions>
                <v-btn large outlined class="mx-auto" @click="checkoutBasic">{{
                  $t('DIALOGS.CHARGEBASIC')
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="mx-auto" max-width="360">
              <v-img src="group.png" height="300px"></v-img>

              <v-card-title>
                <h3>Premium Subscription</h3>
              </v-card-title>

              <v-card-subtitle>
                <p> </p>
                <p> </p>
                <ul>
                  <b>
                    <li>Legal Groups, Document Types, and Alerts</li>
                    <li>Compare up to 6 documents side-by-side</li>
                    <li>Process up to 100 docs/month with Legal AI</li>
                    <li>Enhanced Collaboration and Negotiation</li>
                    <p> </p>
                  </b>
                </ul>
                <h3
                  ><b>Price: {{ currency }}299 per month</b></h3
                >
              </v-card-subtitle>

              <v-card-actions>
                <v-btn
                  large
                  outlined
                  class="mx-auto"
                  @click="checkoutCollaborator"
                  >{{ $t('DIALOGS.CHARGEPREMIUM') }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="mx-auto" max-width="360">
              <v-img src="corporate.png" height="300px"></v-img>

              <v-card-title>
                <h3>Corporate Subscription</h3>
              </v-card-title>

              <v-card-subtitle>
                <p> </p>
                <ul
                  ><b>
                    <li>Corporate Branding, Custom Workflows</li>
                    <li>Unlimited use of AI predictive models</li>
                    <li>Intelligent Self-learning Legal AI</li>
                    <li>Extensible Collaboration and Negotiation</li>
                    <p> </p> </b
                ></ul>
                <h3><b>Price: TBD, (invite only) </b></h3>
              </v-card-subtitle>

              <v-card-actions>
                <v-btn large outlined class="mx-auto" disabled
                  >{{ $t('DIALOGS.CHARGECORPORATE') }}-invite only
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <div class="mx-4 mt-4">
          <v-divider></v-divider>
          <v-divider></v-divider>
          <h4>{{ subscriptionStatusType }}</h4>
          <h4>{{ subscriptionStatusEnd }} </h4>
          <h4>{{ memberCode }} </h4>
          <v-divider></v-divider>
          <v-divider></v-divider>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" module>
@import '@design';
</style>
