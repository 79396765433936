// Contains all application constants //
// Reviewed by Luna and Jangsik/Como //

export const KO = {
  TOPMENU: {
    FILE: '파일',
    EDIT: '편집',
    ACT: '시행',
    DOCS: '문서',
  },

  MENU: {
    NEWDOCUMENT: '새 문서',
    SAVEDOCUMENT: '문서 저장',
    COPYDOCUMENT: '문서 복사',
    CREATEVERSION: '깨끗한 버전 만들기',
    // ORIGINAL TEXT: Create Clean Version
    CREATEDOCFROMTEMPLATE: '템플릿에서 문서 만들기',
    // ORIGINAL TEXT: Create Doc from Template
    OPENDOCUMENT: '문서 열기',
    OPENGROUPDOCUMENT: '그룹 문서 열기',
    OPENHUBDOCUMENT: '문서 HUB 열기',
    // ORIGINAL TEXT: Open HUB Document
    OPENARCHIVEDOCUMENT: '보관 문서 열기',
    OPENPLAYBOOK: '플레이북 열기',
    OPENTEMPLATE: '템플릿 열기',
    // ORIGINAL TEXT: Open Template
    COMPAREDOCUMENT: 'Compare Document',
    // ORIGINAL TEXT: Compare Document
    SAVEASTEMPLATE: '템플릿으로 저장',
    // ORIGINAL TEXT: Save As Template
    SAVEASPLAYBOOK: '플레이북으로 저장',
    MOVETOARCHIVE: '보관 파일로 이동',
    CLOSEDOCUMENT: '문서 닫기',
    DOCUMENTLOG: '문서 로그',

    GOTOPARAGRAPH: '단락으로 이동',
    EXPANDCOLLAPSE: 'Expand/Collapse',
    ENGLISHTRANSLATE: 'EnglishTranslate',
    EDITSONLYVIEW: 'EditsOnlyView',
    SHOWCOLLABORATIVEEDITS: 'ShowCollaborativeEdits',
    SHOWNEGOTIATIONEDITS: 'ShowNegotiationEdits',
    CLAUSETOPICS: 'ClauseTopics',
    DOCUMENTNOTES: '문서 메모',
    COLLABCOMMENTS: '협업 - 댓글',
    COLLABDOCEDITS: '협업 - 문서 편집',
    COLLABRISKANDLABELS: '협업 - 위험 및 표기',
    NEGOTCOMMENTS: '협상 - 의견',
    NEGOTDOCEDITS: '협상 - 문서 편집',

    COLLABORATION: '협업',
    NEGOTIATION: '협상',
    ADDOWNER: '소유자 추가',
    ADDCOLLABORATOR: '협업자 추가',
    ADDNEGOTIATOR: '협상자 추가',
    TRIGGERALERTS: '경보 알림',
    EXPORTEXCEL: '엑셀 내보내기',
    DOWNLOADSOURCE: '소스 다운로드',
    SENDTOCLM: 'CLM으로 보내기',
    EXPORTJSON: 'JSON 내보내기',

    ESIGNATURE: '전자 서명을 위해 보내기',
    DOWNLOADREDLINE: '레드라인 DocX 다운로드',
    DOCUMENTSETTINGS: '문서 설정',
    EXPAND: '확장',
    COLLAPSE: '축소',
    TRANSLATETOENGLISH: '영어로 번역',
    ORIGINALLANGUAGE: '원본으로 되돌리기',

    ALLPARAGRAPHS: '모든 단락',
    EDITEDPARAGRAPHSONLY: '편집된 단락만',

    NOEDITCLAUSELABELS: '조항표기 편집 없음',
    EDITCLAUSELABELS: '조항표기 편집',

    HIDEEDITS: '편집 숨기기',
    SHOWEDITS: '수정 사항 표시',

    SHOWALLEDITS: '모든 수정 사항 표시',
    SHOWNEGOTEDITSONLY: '협상 수정 사항만 표시',

    GROUPS: '그룹',
    DOCUMENTTYPES: '문서 유형',
    ALERTS: '경고',
    QUICKSTARTTUTORIAL: '빠른 시작 지도서',
    QUICKSTARTVIDEOS: '빠른 시작 동영상',
    UPGRADESUBSCRIPTION: '구독 업그레이드',
    SUBSCRIPTIONBILLING: '구독 결제',
    ACCOUNTSETTINGS: '계정 설정',
    USERLOG: '사용자 로그',
    CHANGEPASSWORD: '비밀번호 변경',
    LOGOUT: '로그아웃',

    // HEADINGS
    ADMIN: '관리자',
    PROFILE: '프로필',
    LPROFILE: '프로필',
    FILE: '파일',
    EDIT: '편집',
    ACT: '시행',
    TOPIC: '주제',
    DOCS: '문서',

    // Field Headings in Navigation Bar
    NAME: '이름',
    ROLE: '역할',
    STATE: '상태',
    GROUP: '그룹',
    TYPE: '유형',
    SENSIT: '감도',
    LTOPIC: '주제',
    USER: '사용자',

    // Other Headings
    REGISTER: '등록',
    LOGIN: '로그인',
    LREGISTER: '등록',
    LLOGIN: '로그인',

    ADMINEXPLAIN: '관리자 기능',
    PROFILEEXPLAIN: '사용자계정 관리기능',

    // AUTH LEVELS
    PREMIUM: '프리미엄',
    PREMIUMPREPAID: '프리미엄(선불)',
    BASIC: '기본',
    BASICPREPAID: '기본(선불)',
    VIPACCESS: 'VIP 액세스',
    VIPMEMBER: 'VIP 회원',
    UNLIMITED: '무제한',
    TRIALUSER: '시험 사용자',
    REFERREDUSER: '추천 사용자',
    SOURCEDOCUMENT: '소스 문서',
    // ORIGINAL TEXT: Source Document
    COMPARISONDOCUMENT: '비교 문서',
    // ORIGINAL TEXT: Comparison Document
    EXIT: '출구',
    // ORIGINAL TEXT: EXIT
    PARAGRAPH: '절',
    // ORIGINAL TEXT: PARAGRAPH
    DOCCOMPMODE: '문서 비교 모드',
    // ORIGINAL TEXT: DOCUMENT COMPARISON MODE
  },

  TOPIC: {
    NONE: '없음',
    PREAMBLE: '서문',
    DEFN: '정의',
    PAYMENT: '결제',
    TAXES: '세금',
    REPWARR: '대표 및 보증',
    PERIOD: '기간',
    TERMINATION: '종료',
    CONFID: '기밀준수',
    INDEMNITY: '면책',
    LIABILITY: '책임',
    INTELPROP: '지적재산권',
    SIGNATURE: '서명',
    OTHER: '기타',
    DATASECURITY: '데이터 보안',
    ASSIGN: '양도',
    NOTICE: '공지사항',
    GOVERNLAW: '준거법',
    DISPUTE: '분쟁',
    ENTIREAGREE: '전체 합의',
    SURVIVAL: '존속조항',
    RELATPART: '당사자 간의 관계',
    THIRDPARTY: '제삼자',
    FORCEMAJEURE: '불가항력',
    SEVERABILITY: '분리성',
    EXPORTCTRL: '수출 통제',
    INTRO: '소개',
    INSURANCE: '보험',
    HEADING: '제목',
    ALL: '전체',
  },

  VAIDMSGS: {
    EMAILREQUIRED: '이메일이 필요합니다',
    EMAILVALID: '이메일은 유효해야 합니다',
    MAX20CHARS: '최대 20자',
    MAX60CHARS: '최대 60자',
    MAX80CHARS: '최대 80자',
    MAX160CHARS: '최대 160자',
    EMAILALREADYOWNER: '이메일은 이미 소유자입니다',
    EMAILALREADYCOLLABORATOR: '이메일은 이미 공동협업자입니다',
    EMAILALREADYNEGOTIATOR: '이메일은 이미 협상자입니다',
    EMAILALREADYOWNERDOCTYPE: '이메일은 이미 문서 유형의 소유자입니다.',
    EMAILALREADYCOLLABORATORDOCTYPE:
      '이메일은 이미 문서 유형의 공동 협업자입니다.',
    REQUIRED: '필수',
    CLAUSEFIELDREQUIRED: '이 조항 값은 이 작업에 필요합니다',
    CLAUSENOTDEFAULT: '기본조항목록에서 조항을 추가할 수 없습니다',
    TAGFIELDREQUIRED: '이 작업에는 이 태그 필드가 필요합니다',
    TAGNOTDEFAULT: '기본 태그 목록에서 태그를 추가할 수 없음',

    PARAINDEXREQUIRED: '단락 인덱스가 필요합니다',
    PARAINDEXINVALID: '단락 인덱스가 존재하지 않습니다',
  },

  DOCEVENTS: {
    SAVEDOCUMENT: '문서 저장',
    OPENDOCUMENT: '문서 열기',
    CLOSEDOCUMENT: '문서 닫기',
    ADDNOTE: '노트 추가',
    ADDCOLLABORATOR: '협력자 추가',
    DELETECOLLABORATOR: '협력자 삭제',
    ADDNEGOTIATOR: '협상자 추가',
    DELETENEGOTIATOR: '협상자 삭제',
    UPDATEARCHIVEDATA: '보관 데이터 업데이트',
    UPDATEDOCMETA: '문서 메타데이터 업데이트',
    ADDOWNER: '소유자 추가',
    DELETEOWNER: '소유자 삭제',
    CHANGECOLLABORATION: '협업 설정 변경',
    CHANGENEGOTIATION: '협상 설정 변경',
    SAVEPLAYBOOK: '플레이북으로 저장',
    SAVETEMPLATE: '템플릿으로 저장',
    // ORIGINAL TEXT: saved as template
    SAVEARCHIVE: '보관된 것으로 저장됨',
    TRIGGERALERT: '경고 알림',
    TRIGGERALLALERT: '모든 경고 알림',
    TRIGGERCUSTOMALERT: '맞춤 경고 알림',
    ADDCOMMENT: '댓글 추가',
    DELETECOMMENT: '댓글 삭제',
    ADDDOCEDIT: '문서 편집 추가',
    ADDAPPROVEDDOCEDIT: '승인된 문서 편집 추가',
    APPROVEDDOCEDIT: '문서 편집 승인',
    REJECTDOCEDIT: '문서 편집 거부',
    NEGOTIATORAPPROVEDDOCEDIT: '협상자가 문서 편집을 승인했습니다',
    NEGOTIATORREJECTEDDOCEDIIT: '협상자가 문서 편집을 거부했습니다.',
    DELETEDDOCEDIT: '문서 편집 삭제',
    ATTACHCONTRACT: '임원 계약서 첨부',
    ADDTAG: '태그 추가',
    DELETETAG: '태그 삭제',
    NEWSIGNATUREREQUEST: '새 서명이 요청되었습니다',
    CANCELSIGNATUREREQUEST: '서명 요청 취소되었습니다',
    CREATEREDLINEREQUEST: '새 수정 지시 문서 생성',
    CREATEFINALDOCREQUEST: '서명을 위한 새 최종 문서 생성',
    COPIEDDOCUMENT: '문서에서 복사',
    // ORIGINAL TEXT: copied from document
    CREATECLEANVERSION: '문서에서 깨끗한 버전을 만들었습니다.',
    // ORIGINAL TEXT: created clean version from document
    CREATEDOCFROMTEMPLATE: '템플릿에서 생성된 문서',
    // ORIGINAL TEXT: created document from template
  },

  ADMINVENTS: {
    // DOCTYPES
    CREATEDOCTYPE: '문서 유형 만들기',
    DELETEDOCTYPE: '문서 유형 삭제',
    ADDOWNERTODOCTYPE: '문서 유형에 소유자 추가',
    DELETEOWNERFROMDOCTYPE: '문서 유형에 대한 소유자 삭제',
    ADDCLAUSETODOCTYPE: '문서 유형에 조항 추가',
    DELETECLAUSEDOCTYPE: '문서 유형에 대한 조항 삭제',
    ADDTAGDOCTYPE: '문서 유형에 태그 추가',
    DELETETAGDOCTYPE: '문서 유형에서 태그 삭제',
    ADDCOLLABRULEDOCTYPE: '문서 유형에 협업 작업 규칙 추가',
    DELETECOLLABRULEDOCTYPE: '문서 유형에서 협업 작업 규칙 삭제',
    ADDNOTIFRULEDOCTYPE: '문서 유형에 알림 규칙 추가',
    DELETENOTIFRULEDOCTYPE: '문서 유형에서 알림 규칙 삭제',
    ADDNEGOTRULEDOCTYPE: '문서 유형에 협상 규칙 추가',
    DELETENEGOTRULEDOCTYPE: '문서 유형에서 삭제된 협상 규칙',

    // GROUPS
    CREATEGROUP: '그룹 생성',
    DELETEGROUP: '그룹 삭제',
    ADDOWNERGROUP: '그룹에 소유자 추가',
    DELETEOWNERGROUP: '그룹에서 소유자 삭제',
    ADDCOLLABGROUP: '그룹에 공동 작업자 추가',
    DELETECOLLABGROUP: '그룹에서 공동 작업자 삭제',
    ADDDOCTYPETOGROUP: '그룹에 문서 유형 추가',
    DELETEDOCTYPEGROUP: '그룹에서  문서 유형 삭제',

    // ALERTS
    CREATEALERT: '생성 경고',
    DELETEALERT: '삭제 경고',
    ADDOWNERALERT: '경고할 소유자 추가',
    DELETEOWNERALERT: '경고에서 소유자 삭제',
    ADDDOCTYPEALERT: '경고에 문서 유형 추가',
    DELETEDOCTYPEALERT: '경고에서 문서 유형 삭제',
    ADDCLAUSETOPICALERT: '경고에 조항 주제 추가',
    DELETECLAUSETOPICALERT: '경고에서 조항 주제 삭제',
    ADDRECIPIENTALERT: '경고할 수신자 추가',
    DELETERECIPIENTALERT: '경고에서 수신자 삭제',
    ADDNOTIFRULEALERT: '경고에 알림 규칙 추가',
    DELETENOTIFRULEALERT: '경고에서 알림 규칙 삭제',
    SETSELECTIVITYALERT: '선택성을 경고로 설정',
  },

  USEREVENTS: {
    USERSIGNON: '로그인한 사용자',
    // ORIGINAL TEXT: User Signed In
    PASSWORDRESETREQUESTSENT: '비밀번호 재설정 요청을 보냈습니다',
    CHANGEDPASSWORD: '비밀번호 변경',
    DELETEDREGISTRATION: '사용자 등록 삭제',
    UPDATEDACCOUNTINFO: '업데이트된 계정 정보',
    UPGRADEDACCOUNTTOREFERRAL: '추천 계정으로 업그레이드된 계정',
    DELETEDUSERACCOUNT: '사용자 계정 삭제',
  },

  // LABELS FOR PRODUCT
  DIALOGS: {
    // OTHER DIALOG LABELS
    ACTIONSDIALOGUE: '문서 작업',
    COMMENTS: '댓글',
    DOCEDITS: '문서 편집',
    LABELSRISK: '라벨 및 위험',
    TEXT: '텍스트',
    MLE_DESC: 'DOCGILITY',
    CLAUSE: '조항',

    // PRIVILEGE
    OWNER: '소유자',
    COLLABORATOR: '협력자',
    NEGOTIATOR: '협상가',
    OWNERGROUP: '소유자(그룹)',
    COLLABORATORGROUP: '협력자(그룹)',

    // TITLES
    SAVEDOCUMENT: '문서 저장',
    SAVEARCHIVE: '보관 저장',
    QUICKSTART: '빠른 시작 자습서',
    TUTORIALVIDEOS: '사용가이드 비디오',

    MOVETOARCHIVEDOCUMENT: '보관 문서로 이동',
    OPENDOCUMENT: '문서 열기',
    COPYDOCUMENT: '문서 복사',
    CREATEVERSION: '깨끗한 버전 만들기',
    // ORIGINAL TEXT: CREATE CLEAN VERSION
    CREATEDOCFROMTEMPLATE: '템플릿에서 문서 만들기',
    // ORIGINAL TEXT: CREATE DOCUMENT FROM TEMPLATE
    OPENGROUPDOCUMENT: '그룹 문서 열기',
    OPENHUBDOCUMENT: '문서 HUB 열기',
    OPENARCHIVEDOCUMENT: '보관 문서 열기',
    OPENPLAYBOOK: '플레이북 열기',
    OPENTEMPLATE: '템플릿 열기',
    // ORIGINAL TEXT: OPEN TEMPLATE
    COMPAREDOCUMENT: 'COMPARE DOCUMENT',
    // ORIGINAL TEXT: COMPARE DOCUMENT
    SAVEASTEMPLATE: '템플릿으로 저장',
    // ORIGINAL TEXT: SAVE AS TEMPLATE
    COLLABORATION: '협업',
    NEGOTIATION: '협상',
    ADDOWNER: '소유자 추가',
    ADDNEGOTIATOR: '협상자 추가',
    ADDCOLLABORATOR: '협력자 추가',
    ERRORMESSAGE: '오류 메시지',
    DOCUMENTINFO: '문서 정보',
    DOCUMENTLOGS: '문서 로그',
    USERLOGS: '사용자 로그',
    DOCUMENTNOTES: '문서 메모',
    TRIGGERALERTS: '경고 위험',
    ALERTS: '경고',
    DOCTYPES: '문서 유형',
    GROUPS: '그룹',
    NOTE: '참고',
    GOTOPARAGRAPH: '텍스트로 이동',
    ESIGNATURE: '전자 서명',
    DOWNLOADREDLINE: 'REDLINE DOCX 다운로드',

    // 액션
    ADD: '추가',
    BACK: '뒤로',
    DELETE: '삭제',
    SAVE: '저장',
    MOVETOARCHIVE: '보관으로 이동',
    COPY: '복사',
    CREATE: '창조하다',
    // ORIGINAL TEXT: CREATE
    OPEN: '열다',
    CLOSE: '닫기',
    UPDATE: '업데이트',
    REGISTER: '등록',
    LOGIN: '로그인',
    LOGINSSO: '로그인 SSO',
    // ORIGINAL TEXT: LOGIN SSO
    RESETPASSWORD: '비밀번호 재설정',
    CHANGEPASSWORD: '비밀번호 변경',
    CANCELACCOUNT: '계정 취소',
    QUICKSTARTVIDEO: '빠른 시작 동영상 보기',

    CHARGEBASIC: '기본으로 비용청구',
    CHARGEPREMIUM: '프리미엄으로 비용청구',
    CHARGECORPORATE: '기업으로 비용청구',
    UPGRADESUBSCRIPTION: '구독 업그레이드',
    GO: 'GO',
    PREVIEW_FEEDBACK: '고객후기 검토',
    ACTIONS: '시행',
    REFRESH: '새로고침',
    ADDNEWGROUP: '새 그룹 추가',
    ADDNEWDOCTYPE: '새 문서 유형 추가',
    ADDNEWALERT: '새 경고 추가',
    GROUPSLIST: '그룹 목록 검토',

    DOWNLOADDOCUMENT: '문서 다운로드',
    DOCUMENTTITLE: '문서 제목',
    DOCUMENTSUBJECT: '문서 제목',
    SIGNMESSAGE: '서명을 위한 메시지',
    NAMEPRIMARYSIGNER: '이름: 기본 ​​서명자',
    EMAILPRIMARYSIGNER: '이메일: 기본 ​​서명자',
    NAMECOUNTERSIGNER: '이름: 상대방 서명자',
    EMAILCOUNTERSIGNER: '이메일: 상대방 서명자',
    SIGREQUESTED: '서명 요청',
    SIGREQUESTEDBY: '서명 요청자',
    SIGREQUESTEDID: '서명 아이디',
    SIGREQUESTEDAT: '요청된 시각',
    SUBMITSIG: '서명 요청',
    CANCELSIG: '서명 요청 취소',

    // DATA LABELS
    NAME: '이름',
    EMAIL: '이메일',
    STATUS: '상태',
    LASTVIEWED: '마지막으로 봄',
    LASTREVIEWED: '마지막 검토',
    SIGNEDAT: '서명됨',
    OWNERAPPROVAL: '소유자 승인',
    OWNERACTION: '소유자 조치',
    NEGOTIATORAPPROVAL: '협상자 승인',
    NEGOTIATORACTION: '협상자 조치',
    ARCHIVENOTES: '보관 노트',
    EXECUTEDCONTRACT: '체결됨',
    EXECUTEDDATE: '체결 날짜',
    CONTRACTATTACHED: '계약이 첨부되었습니다',
    STARTDATE: '시작 날짜',
    ENDDATE: '종료 날짜',
    EDIT: '편집',
    USER: '사용자',
    TIME: '시간',
    CONFIRM: '확인',
    CONFIRMACTION: '작업 확인',
    CURRENT_LABEL: '현재 표기',
    NEW_LABEL: '새 표기',
    DESCRIPTION: '설명',
    TYPE: '유형',
    SENTENCE: '문장',
    NUMBER: '#',
    PRIVILEGE: '권한',
    OWNERID: '소유자 아이디',
    DOCNAME: '문서 이름',
    DOCTYPE: '문서 유형',
    DOCDESCRIPTION: '문서 설명',
    DOCNOTES: '문서 메모',
    DOCPARTY: '당사자',
    DOCCOUNTERPARTY: '상대방',
    DOCRAWFILENAME: '문서 파일 이름',
    // ORIGINAL TEXT: DOC FILE NAME
    TAGS: '태그',
    COLLABORATIONSTATUS: '협업 상태',
    COLLABORATIONEND: '협업 종료',
    NEGOTIATIONSTATUS: '협상 상태',
    NEGOTIATIONEND: '협상 종료',
    DOCID: '문서 아이디',
    PARAGRAPHID: '단락 아이디',
    ACTION: '시행',
    DETAIL: '액션 세부 정보',
    CONTEXT: '추가 컨텍스트',
    GROUPNAME: '그룹 이름',
    GROUPDESC: '그룹 설명',
    DOCTYPEDESC: '문서 유형 설명',
    ADDLCLAUSETYPES: '조항 유협표기 추가',
    ADDLTAGS: '태그 추가',
    협업규칙: '협업 규칙',
    NEGOTIATIONRULES: '협상 규칙',
    NOTIFICATIONRULES: '알림 규칙',
    LOG: '로그',
    ALERTNAME: '경보 이름',
    ALERTDESC: '경고 설명',
    TOPICS: '토픽',
    RECIPIENTS: '수신자',
    SELECTIVITY: '선택성',
    FIELDNAME: '분야 명',
    // ORIGINAL TEXT: FIELD NAME
    ORDER: '주문하다',
    // ORIGINAL TEXT: ORDER
    EXAMPLE: '예시',
    // ORIGINAL TEXT: EXAMPLE
    DEFAULT: '기본',
    // ORIGINAL TEXT: DEFAULT
    VALUE: '값',
    // ORIGINAL TEXT: VALUE

    // DOCUMENT TYPES
    DOCUMENT: '문서',
    // ORIGINAL TEXT: DOCUMENT
    PLAYBOOK: '플레이북',
    // ORIGINAL TEXT: PLAYBOOK
    TEMPLATE: '주형',
    // ORIGINAL TEXT: TEMPLATE
    ARCHIVEDOC: '보관된 문서',
    // ORIGINAL TEXT: ARCHIVED DOCUMENT

    TEMPLATEORIGDOCNAME: '템플릿에서 생성:',
    // ORIGINAL TEXT: ORIGINATED FROM TEMPLATE:

    COLSHORT: '콜라보레이션',
    NEGSHORT: '협상',
    OWNERS: '소유자',
    COLLABORATORS: '협력자',
    NEGOTIATORS: '협상가',
    // ADDITION
    DOCUMENTDETAILS: '문서 세부정보',
    CREATIONDATE: '생성 날짜',
    PARTICIPANTS: '참가자',
    COLLABORATIONANDNEGOTIATION: '협업 및 협상',
    DOCTYPEANDGROUPACCESS: '문서 유형 및 그룹 접근권',
    GROUPOWNERS: '그룹 소유자',
    GROUPCOLLABORATORS: '그룹 협력자',
    DOCUMENTSETTINGS: '문서 설정',
    ENABLECOLLABTOMODLABELS: '공동 작업자가 조항표기를 수정할 수 있도록 설정',
    ENABLENEGOTCAPABILITIES: '협상 기능 사용(협상 모드 아님)',
    KEEPDOCNOTES: '복사 작업으로 문서 메모 복사',
    // ORIGINAL TEXT: Copy Doc Notes with Copy Operation
    REVIEWEDCLAUSETOPICS: '필요한 경우 조항 항목을 검토 및 수정',
    NOEXECUTIONDATE: '체결 날짜 없음',
    EXECUTIONDATE: '체결 날짜',
    CONTRACTSTARTDATE: '계약 시작 날짜',
    CONTRACTENDDATE: '계약 종료 날짜',
    EXECCONTRACTNAME: '실행된 계약 파일 이름',
    SELECTEXECCONTRACT: '실행된 계약을 업로드할 파일 선택',
    UPLOADEXECUTEDCONTRACT: '실행된 계약 업로드',
    LOADDEFAULTS: '기본값 로드',
    CLOSED: '닫힘',
    NONE: '없음',
    NA: '비적용',
    RESULTEXECUTEDCONTRACT: '계약 체결 결과',
    NOSTARTDATE: '시작 날짜 없음',
    NOENDDATE: '종료 날짜 없음',
    EXCLUDECOLLABORATORSFROMACCESS: '문서 접근권에서 외부 공동 작업자 제외',
    EXCLUDENEGOTIATORSFROMACCESS: '문서 접근권에서 외부 협상자 제외',
    REVIEWEDAMENDEDCLAUSELABELS: '필요에 따라 조항 표기 검토 및 수정',
    ENTERPARAGRAPHBETWEEN: '사이에 단락 번호를 입력하세요.',
    GOTOPARAGRAPHINDEX: '단락 색인으로 이동',
    INPUTDOCUMENTNOTEHERE: '여기에 문서 메모 입력',
    INPUTCOMMENTHERE: '여기에 댓글 입력',
    SHOWPENDINGONLY: '보류 중인 항목만 표시',
    COLLABORATIONSETTING: '협업 설정',
    NEGOTIATIONSETTING: '협상 설정',
    NONEGOTWHENAPPROVED: '승인된 상태에서는 협상을 켤 수 없습니다',
    EMAILTOADD: '추가할 이메일',
    CLAUSETOADD: '추가할 조항',
    TAGTOADD: '추가할 태그',
    OPTIONSFORREDLINEINCLUDE: '레드라인 옵션 포함',
    ONLYINCLUDEOWNERAPPROVEDEDITS: '소유자가 승인한 문서 편집만 포함',
    ONLYINCLUDEOWNERNEGOTAPPROVEDEDITS:
      '소유자 및 협상자가 승인한 문서 편집만 포함',
    INCLUDEALLDOCEDITS: '모든 문서 편집 포함(승인 여부에 관계없이)',
    REDLINECHANGESSELECTED: '레드라인 변경 사항 선택',
    TOTALCHANGES: '총 변경 사항',
    REPLACEREDLINES: '레드라인 교체',
    DELETEREDLINES: '레드라인 삭제',
    ADDAFTERREDLINES: '레드라인 후 추가',
    OPTIONSFORPREVREDLINES: '이전 레드라인 옵션',
    OVERWRITEPREVREDLINES: '문서 원본의 모든 이전 레드라인 덮어쓰기',
    KEEPPREVREDLINES: '문서 소스의 모든 이전 레드라인 유지',
    GENERATEFINALDOC: '최종 문서 생성(수정 사항 없음)',
    DEFAULTESIGNOTE: '논의한 대로 이 계약서에 전자 서명을 제공하십시오.',
    SUBMITTEDSIGMESSAGE: '서명을 위해 제출했습니다. 기다리세요. ',
    UPLOADEDMESSAGE: '업로드됨',
    ADDADDLFILES: '선택 사항 - 서명 요청에 추가 파일 추가',
    ADDFILESMSG: '서명을 위한 추가 파일 추가',
    ESIGPREPAIDPLANS:
      '전자 서명 기능은 선불 요금제를 통해서만 사용할 수 있습니다.',
    // ORIGINAL TEXT: eSignature feature is only available through our Prepaid plans.
    ESIGPREPAIDPLANSCONTACT:
      '자세한 내용은 billing@mylegaleinstein.com으로 문의하십시오.',
    // ORIGINAL TEXT: Contact us at billing@mylegaleinstein.com for more details.
    MOREVIDEOSAT: '더 많은 동영상은 ',
    // ORIGINAL TEXT: Move videos at the
  },

  // MESSAGES (via SnackService)
  MESSAGES: {
    DOCTYPENOTSUPPORTED:
      '지원되지 않는 문서 유형입니다. MLE는 docx 및 pdf 형식만 허용합니다.',
    PDFSUPPORTEDFORPREMIUMONLY:
      'PDF 스캔의 경우 사용자는 프리미엄 구독 이상으로 업그레이드해야 합니다.',
    BADDOCFORMAT: '잘못된 문서 형식이 문서 처리에 전달되었습니다.',
    NOPRIVILEGSTOCHANGEDOCSETTINGS:
      '문서 설정을 변경할 권한이 없습니다. 소유자가 되어야 합니다.',
    PRIVILEGSNOTAVAIL:
      '이 사용자에 대한 권한을 사용할 수 없습니다. 나중에 다시 시도하십시오.',
    EXHAUSTEDTRIALLICENSE:
      '평가 기간 동안 허용된 문서 수를 모두 사용했습니다. 프로필에서 "구독 업그레이드"를 선택하십시오.',
    EXHAUSTEDMONTHLYLIMIT:
      '평가 기간 동안 허용된 월별 문서 수를 모두 사용했습니다. 프로필에서 "구독 업그레이드"를 선택하십시오.',
    PASSWORDNOTMATCHING:
      '비밀번호가 일치하지 않습니다. 비밀번호를 다시 확인하세요.',
    ABAMEMBERIDLENGTH: 'ABA 회원 아이디는 7자입니다. 검토하여 제출해 주십시오.',
    TERMSNOTCHECKED: '이용약관은 검토 및 확인되지 않았습니다.',
    EMAILFORMATBAD:
      '이메일 형식이 올바르지 않습니다. 이메일 주소를 검토하십시오.',
    CANCELEDACTION:
      '마지막 작업이 취소되었습니다. 이제 응용 프로그램이 다시 로드됩니다.',
    SUCCESSACTION:
      '당신의 마지막 작업은 성공적이었습니다. 이제 응용 프로그램이 다시 로드됩니다.',
    USERSUCCESSLOGIN: '사용자로 로그온 했습니다.',
    USERLOGGEDOUT: '사용자가 로그아웃되었습니다',
    EMAILNOTVERIFIED:
      '계정 이메일이 아직 확인되지 않았습니다. 확인 이메일이 전송되었습니다.',
    EMAILALREADY등록됨: '이 이메일에는 이미 이전에 등록된 계정이 있습니다.',
    EMAILNOTVERIFIEDRESENT:
      '이메일이 확인되지 않았습니다. 새 계정을 활성화하기 위해 귀하의 이메일에 대한 이메일 확인 링크를 다시 보냈습니다.',
    USERLOGFILENOTEXISTS: '오류 – 사용자 로그 파일이 없습니다:',
    PASSWORDRESETSENT:
      '귀하의 계정에 대한 이메일 비밀번호 재설정 메시지가 전송되었습니다.',
    PASSWORDCHANGED: '비밀번호가 변경되었습니다.',
    REGISTRATIONDELETED: '사용자 등록 삭제',
    USERACCOUNTUPDATED:
      '사용자 계정 설정을 업데이트했습니다. 변경 사항은 다음에 로그인할 때 적용됩니다.',
    UPGRADEDTOREFERRED:
      '귀하의 계정이 추천 평가판 구독으로 업데이트되었습니다.',
    REGISTEREDTRIALUSER: '새 계정이 시험테스트 구독으로 등록되었습니다.',
    DELETEDUSERACCOUNT: '사용자 프로필 계정 삭제',
    EMAILALREADYREGISTEREDRESENT:
      '이메일은 아직 Docgility에 등록되지 않았습니다. 이메일 초대장이 발송되었습니다.',
    UPGRADEEXTERNALPAYMENTS:
      '업그레이드해주셔서 감사합니다. 외부 결제 서비스로 다시 연결되며 거래 완료 후 구독 서비스를 시작하려면 다시 로그인해야 합니다.',
    BILLINGEXTERNALPAYMENTS:
      '외부 결제 및 청구 서비스로 이동하는 것을 원하는지 확인하십시오. 변경한 후 변경 사항을 적용하려면 다시 로그인해야 합니다.',
    NOTABLETOLOGINATTHISTIME:
      '지금은 로그인할 수 없습니다. 나중에 다시 시도하십시오.',
    NOTREGISTERED:
      '사용자는 이전에 등록하지 않았습니다. 로그인을 시도하기 전에 등록하십시오.',
    NAMETOOLONG: '입력한 이름이 너무 깁니다. 최대 60자입니다.',
    COMPANYTOOLONG: '입력한 회사가 너무 깁니다. 최대 60자입니다.',
    COMMENTTOOLONG: '입력하신 댓글이 너무 깁니다. 최대 10000자입니다.',
    DOCEDITTOOLONG: '입력한 문서 편집 내용이 너무 깁니다. 최대 10000자입니다.',
    TITLETOOLONG: '입력한 제목이 너무 깁니다. 최대 60자입니다.',
    PASSWORDTOOSHORT:
      '입력하신 비밀번호가 너무 짧습니다. 비밀번호는 6자 이상이어야 합니다.',
    CONGRATSNEWDOCUMENTPROCESSING:
      '엄청난! 새 문서를 처리했습니다. 몇 분 정도 소요되며 문서 처리가 완료되면 패널이 새로 고쳐집니다. 잠시만 기다려 주십시오.',
    NODOCUMENTLOADED: '로드된 문서 없음',
    SELECTFILETOUPLOADDOCX: '업로드할 파일 선택(*.docx)',
    SELECTFILETOUPLOADDOCXPDF: '업로드할 파일 선택(*.docx, *.pdf)',
    INPUTCOMMENTHERE: '여기에 댓글을 입력하세요',
    AUTOAPPROVEASOWNER: '소유자로 자동 승인',
    INPUTDOCEDITSHERE: '여기에 문서 편집 입력',
    TRIGGEREDALLALERTS: '이 문서 유형에 있는 모든 경고를 알려드렸습니다.',
    QSINTROMESSAGE:
      'MyLegalEinstein은 계약을 즉시 분석하고 법적 주제별로 색인을 생성하며 완전한 계약 협업 플랫폼에서 법적 언어를 나란히 비교할 수 있습니다.',
    QSDOCMESSAGE:
      '편의를 위해 다운로드하여 시도할 수 있는 일부 공개 도메인 계약이 있습니다.:',
    QSHELPMESSAGE: '제품 설명서에 대한 편리한 링크입니다(빠른 시작 버전 포함)',
    DOCUMENTFAILEDTOPROCESS:
      '문서를 처리하는 동안 치명적인 오류가 발생했습니다. 다시 시도해 주세요. 문제가 지속되면 support@mylegaleinstein.com으로 고객 지원에 문의하십시오.',
    // ADDED
    DOCUMENTSUCCESSCOPIED: '이제 문서가 복사되었습니다.',
    // ORIGINAL TEXT: Your document has now been copied.
    DOCUMENTSUCCESSCLEANVERSIONED:
      '이제 이 문서의 깨끗한 버전이 생성되어 저장되었습니다.',
    // ORIGINAL TEXT: A clean version of this document has now been generated and saved.
    DOCUMENTSUCCESSFROMTEMPLATE:
      '이 템플릿에서 문서가 생성되어 저장되었습니다.',
    // ORIGINAL TEXT: A document was generated from this template and saved.
    DOCUMENTCONVERTEDTOTEMPLATE: '문서가 문서 템플릿으로 변환되었습니다.',
    // ORIGINAL TEXT: A document has been converted into a document template.
    VIEWGROUPDOCS: 'Access Group Documents',
    // ORIGINAL TEXT: Access Group Documents
  },

  INFO_MESSAGES: {
    CONFIRMFILEDELETE:
      '지정된 문서를 삭제할 것인지 확인하십시오. 문서가 삭제되면 더 이상 사용할 수 없습니다. 문서를 보관 상태로 이동하는 것을 고려할 수도 있습니다. \n\n 삭제 작업을 확인하십시오.',
    CONFIRMSAVETOPLAYBOOK:
      '이 문서를 플레이북으로 변환하려면 확인하십시오. 이렇게 하면 소유자를 제외하고 이 문서를 변경할 수 없습니다. \n\n 플레이북에 저장 작업을 확인하십시오.',
    COLLABORATIONNOTE:
      '참고: 이 정보는 내부적으로만 지정된 공동 작업자 및 문서 소유자에게만 공유됩니다.',
    NEGOTIATIONNOTE: '참고: 이 정보는 협상 상대방과 외부적으로 공유됩니다.',
    CONFIRMDELETEALERT:
      '지정한 경고를 삭제할 것인지 확인하십시오. 경고가 삭제되면 더 이상 사용할 수 없습니다. 삭제 작업을 확인하십시오.',
    CONFIRMDELETERECIPIENTALERT:
      '지정된 경고의 수신자가 더 이상 되지 않음을 확인하십시오. 수신자에서 자신을 제거한 후 알림 소유자에게 연락하여 다시 추가를 요청할 수 있습니다. 삭제 작업을 확인하십시오.',
    CONFIRMDELETEGROUP:
      '지정된 그룹을 삭제할 것인지 확인하십시오. 그룹이 삭제되면 더 이상 사용할 수 없습니다. 단, 다음 로그인 시 반영됩니다. 삭제 작업을 확인해주세요.',
    CONFIRMDELETECOLLABORATORGROUP:
      '지정된 그룹의 공동 작업자가 더 이상 되지 않음을 확인하십시오. 공동작업자에서 자신을 제거한 후 그룹 소유자에게 연락하여 다시 추가를 요청할 수 있습니다. 삭제 작업을 확인하십시오.',
    CONFIRMDELETEDOCTYPE:
      '지정된 문서 유형을 삭제할 것인지 확인하십시오. doctype이 삭제되면 더 이상 사용할 수 없습니다. 삭제 작업을 확인하십시오.',
    EXPIREDTRIAL:
      '평가 구독 라이선스가 만료되었습니다. 서비스를 계속하려면 업그레이드하십시오.',
    EXPIREDSUBSCRIPTION:
      '구독 라이선스가 만료되었습니다. 서비스를 계속하려면 업그레이드하거나 Docgility에서 청구서에 문의하십시오.',
    SOURCEDOCNOTDOCX:
      '원본 문서는 DOCX 문서가 아닙니다. 레드라인 기능을 사용할 수 없습니다.',
  },
}

export default KO
