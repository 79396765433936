import { CLAUSESLIST } from '@constants/clauseslist'

const CATNAMES = getCategoryNames()
const IDNAMES = Object.keys(CLAUSESLIST)

// 1-C-10  ---> 10  - OK
export function getCatIDFromUID(uid) {
  return uid.split('-')[2]
}
// 1-C-10  ---> "LIMIT_OF_LIABILITY"  - OK
export function getCategoryNameFromUID(uid) {
  return CLAUSESLIST[getCatIDFromUID(uid)].long
}

// 1-C-10  ---> "LIAB"  - OK
export function getShortCategoryNameFromUID(uid) {
  return CLAUSESLIST[getCatIDFromUID(uid)].short
}

// 1-C-10  ---> "Description"  - OK
export function getCategoryNameDescriptionFromUID(uid) {
  return CLAUSESLIST[getCatIDFromUID(uid)].description
}

//   [ "UNKNOWN", "PREAMBLE",  '...']
export function getCategoryNames() {
  return Object.values(CLAUSESLIST).map((clause) => clause.long)
}
// [TERM", "CONF", "INDM"...]
export function getCategoryShortNames() {
  return Object.values(CLAUSESLIST).map((clause) => clause.short)
}
//   [ "UNKNOWN", "PREAMBLE",  '...']
export function getCategoryListNames() {
  return Object.values(CLAUSESLIST)
}

// 10 -> {id: '10', short: 'LIAB', long: 'LIMIT_OF_LIABILITY',description: '',}
export function getCategoryforCatID(catid) {
  return Object.values(CLAUSESLIST).find((e) => e.id === catid)
}

//   "10"  --> "LIMIT_OF_LIABILITY"
// extended to support custom names
export function getDisplayCategoryforCatID(catid) {
  if (IDNAMES.includes(catid)) {
    return Object.values(CLAUSESLIST).find((e) => e.id === catid).long
  } else {
    return catid
  }
}

export function getDisplayCategoryShortforCatID(catid) {
  if (IDNAMES.includes(catid)) {
    return Object.values(CLAUSESLIST).find((e) => e.id === catid).short
  } else {
    return catid
  }
}

//   "LIMIT_OF_LIABILITY"  --> '10'
// extended to support all strings beyond CATNAMES
export function getIDFromCategoryName(catname) {
  if (CATNAMES.includes(catname)) {
    return Object.values(CLAUSESLIST).find((e) => e.long === catname).id
  } else {
    return catname
  }
}

// (1, "LIMIT_OF_LIABILITY") ---> '1-C-10'
export function constructLabel(label) {
  return '0-0-' + getIDFromCategoryName(label)
}
