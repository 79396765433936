// Called from panel.vue to represent each paragraph // note - commenting out
the metadata and obligations.

<script>
// state
import { panelComputed } from '@state/helpers'

import ParaCompareUnit from './para-compareunit.vue'

export default {
  components: { ParaCompareUnit },

  props: {
    originaltext: {
      type: Object,
      default: () => {},
    },
    comparetext: {
      type: Object,
      default: () => {},
    },
    otdoc: {
      type: String,
      required: true,
    },
    ctdoc: {
      type: String,
      required: true,
    },
    compoptnonsequential: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      // showClauseIndicator: true, // show the Clause Indicators (should be excluded if it's A)
      lcompressed: this.compressedrec, /// this is the local preference to determine if it's compressed.
      lenglishtranslate: this.englishtranslaterec,
      lcompoptnonsequential: this.compoptnonsequential,

      NOTVIEWABLEPARATYPES: [
        '<< EMPTYTEXT >>',
        '<< TABLEROW >>',
        '<< EMPTY >>',
        '<< TAB >>',
      ],

      // options to adjust matching algorithm
      compoptshowunreadable: false,
      // compoptnonsequential: true,

      NULLV: -100000,
    }
  },

  computed: {
    ...panelComputed,

    ot() {
      // return this.originaltext.map((x) => x.OT)
      return Object.values(this.originaltext).map((item) => item.OT)
    },
    ct() {
      return Object.values(this.comparetext).map((item) => item.OT)
    },

    displaymatches() {
      var combined = []
      var lastMatch = -1
      var uniqindex = 0

      const matches = this.seqmatchotfunc2(this.ot, this.ct)

      const otmatches = matches.OT
      const ctmatches = matches.CT

      for (var otiter = 0; otiter < otmatches.length; otiter++) {
        if (otmatches[otiter] < 0) {
          combined.push({
            result: 'addedOT',
            text: this.ot[otiter],
            otpara: otiter,
            ctpara: -1,
            para: otiter,
            lastMatch: lastMatch,
            index: uniqindex++,
          })
          // } else {
          //   // register the latest lastMatch in order to sort.
          //   // normalized to get to the OT value.
          //   lastMatch = otmatches[otiter]
        }
      }

      lastMatch = -1

      for (var ctiter = 0; ctiter < ctmatches.length; ctiter++) {
        if (ctmatches[ctiter] < 0) {
          combined.push({
            result: 'addedCT',
            text: this.ct[ctiter],
            otpara: -1,
            ctpara: ctiter,
            para: ctiter,
            lastMatch: lastMatch,
            index: uniqindex++,
          })
          // } else {
          //   // register the latest lastMatch in order to sort.
          //   lastMatch = ctiter
        }
      }

      return combined
    },

    filteredmatches() {
      const returnmatches = this.displaymatches
        .filter((item) => item.result !== 'matched')
        .sort(this.compare('lastMatch', 'para'))

      if (this.compoptshowunreadable) {
        return returnmatches
      } else {
        return returnmatches.filter(
          (item) => !this.NOTVIEWABLEPARATYPES.includes(item.text)
        )
      }
    },
  },

  watch: {},
  methods: {
    stringTrim(str) {
      return str.replaceAll(' ', '').replaceAll('\t', '')
    },

    stringCompare(str1, str2) {
      return this.stringTrim(str1) === this.stringTrim(str2)
    },

    stringinArray(stringarray, substring) {
      for (var iter = 0; iter < stringarray.length; iter++) {
        // if (stringarray[iter].replace(' ', '') === substring.replace(' ', '')) {
        if (this.stringCompare(stringarray[iter], substring)) {
          return iter
        }
      }

      return -1
    },

    stringinArraywIndex(stringarray, substring, index) {
      for (var iter = index; iter < stringarray.length; iter++) {
        // if (stringarray[iter].replace(' ', '') === substring.replace(' ', '')) {
        if (this.stringCompare(stringarray[iter], substring)) {
          return iter
        }
      }

      return -1
    },

    // ascend changes the direction of the comparison
    compare(value, second) {
      return function (a, b) {
        if (a[value] > b[value]) {
          return 2
        } else if (a[value] < b[value]) {
          return -2
        } else {
          if (a[second] > b[second]) {
            return +1
          } else {
            return -1
          }
        }
      }
    },

    seqmatchotfunc2(ot, ct) {
      var matchOTArray = Array(ot.length).fill(this.NULLV)
      var matchCTArray = Array(ct.length).fill(this.NULLV)

      var lastIndex = 0
      var index = 0

      const trimot = ot.map((item) => item.trim())
      const trimct = ct.map((item) => item.trim())

      for (var iter = 0; iter < ot.length; iter++) {
        // nonsequential option.
        if (this.compoptnonsequential) {
          index = this.stringinArray(trimct, ot[iter].trim())
        } else {
          index = this.stringinArraywIndex(trimct, ot[iter].trim(), lastIndex)
        }

        // match
        if (index > -1) {
          matchOTArray[iter] = index
          matchCTArray[index] = iter
          lastIndex = index + 1 // ensures it's done sequential positional match.
        } else {
          matchOTArray[iter] = this.NULLV
        }
      }
      // if nonsequential, need to go through ct and see if it's in ot.
      if (this.compoptnonsequential) {
        for (var ctiter = 0; ctiter < ct.length; ctiter++) {
          index = this.stringinArray(trimot, ct[iter].trim())

          if (index > -1 && matchCTArray[ctiter] === this.NULLV) {
            matchCTArray[ctiter] = index
          }
        }
      }

      return { OT: matchOTArray, CT: matchCTArray }
    },

    compareGotoParaOT(para) {
      this.$emit('compareGotoPara', {
        comparedocid: this.otdoc,
        comparepara: para,
      })
    },
    compareGotoParaCT(para) {
      this.$emit('compareGotoPara', {
        comparedocid: this.ctdoc,
        comparepara: para,
      })
    },
  },
}
</script>

<template>
  <div :class="$style.paragraph">
    <div v-if="true">
      <ParaCompareUnit
        v-for="item in filteredmatches"
        :key="item.index"
        :item="item"
        @compareGotoParaOT="compareGotoParaOT"
        @compareGotoParaCT="compareGotoParaCT"
      >
      </ParaCompareUnit>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
