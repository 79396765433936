<script>
import { getCollaborationOptions } from '@utils/workflowMapping'

import { docroute } from '@utils/dialogutils'
import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },
  props: {
    panelno: {
      type: Number,
      default: () => 2,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },

    // FYI - Need to pass each field in individually - VuejS objects to mutability of objects and arrays.
    collabstatus: {
      type: String,
      required: true,
    },
    collabend: {
      type: String,
      required: true,
      // default: new Date(),
    },
    collabrules: {
      type: Array,
      required: true,
    },
  },
  // Need to transform it to local variables to be able to mutate.  must use the "function" syntax
  data: function() {
    return {
      lcollabstatus: this.collabstatus,
      lcollabend: this.collabend,
      lcollabrules: this.collabrules,
      lselected: this.collabrules,

      FOREVER: 'NONE',

      errormsg: '',

      noenddate: true,

      // get global lists of options
      collaborationOptions: getCollaborationOptions(),
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
    compileOptions() {
      let returnOptions = []
      var i
      for (i = 0; i < this.lselected.length; i++) {
        if (this.lselected[i]) {
          returnOptions.push(this.collaborationOptions[i])
        }
      }
      return returnOptions
    },
  },

  watch: {
    // when dialog changes to true, set initial values
    dialog(newValue, oldValue) {
      if (newValue !== false) {
        this.setInitialValues()
      }
    },
  },
  methods: {
    setInitialValues() {
      this.lcollabstatus = this.collabstatus

      this.lcollabend = this.collabend === this.FOREVER ? '' : this.collabend
      this.noenddate = this.collabend === this.FOREVER || this.collabend === ''

      // for defaults, if specified in the doctype, use those instead.
      if (this.localpanelstate.ifDocType) {
        this.lcollabrules = this.localpanelstate.docTypeDetailofDoc.collabrules
      } else {
        this.lcollabrules = this.collabrules
      }
    },
    setNullValues() {
      // reset all the local forms for the next time it's accessed.
      this.lcollabstatus = 'CLOSED'
      this.lcollabend = ''
      this.noenddate = false
      // for defaults, if specified in the doctype, use those instead.
      if (this.localpanelstate.ifDocType) {
        this.lcollabrules = this.localpanelstate.docTypeDetailofDoc.collabrules
      } else {
        this.lcollabrules = []
      }
    },
    saveCollaborationCheck() {
      if (this.localpanelstate.ifArchivedState) {
        this.errormsg = 'Cannot turn on Collaboration when in Archive State'
      } else {
        this.saveCollaboration()
      }
    },

    saveCollaboration() {
      // detect if collaboration changed from close to open, if doctype, trigger opencollab
      if (
        this.collabstatus === 'CLOSED' &&
        this.lcollabstatus === 'OPEN' &&
        this.localpanelstate.ifDocType
      ) {
        this.$emit('opencollab')
      }

      const formdata = {
        collabstatus: this.lcollabstatus,
        collabend: this.noenddate ? this.FOREVER : this.lcollabend,
        collabrules: this.compileOptions,
      }
      this.$emit('save-collaboration', formdata)
    },
    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.COLLABORATION')"
        ></PanelCardTitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="5" sm="5" class="py-2">
                <p>{{ $t('DIALOGS.COLLABORATIONSETTING') }}</p>
                <v-btn-toggle v-model="lcollabstatus" tile color="red" group>
                  <v-btn value="CLOSED">
                    {{ $t('DIALOGS.CLOSED') }}
                  </v-btn>
                  <v-btn value="OPEN">
                    {{ $t('DIALOGS.OPEN') }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col v-if="lcollabstatus === 'OPEN'" cols="7" sm="7">
                <p> {{ $t('DIALOGS.COLLABORATIONEND') }}: </p>
                <v-checkbox
                  v-model="noenddate"
                  :label="$t('DIALOGS.NOENDDATE')"
                >
                </v-checkbox>

                <v-date-picker
                  v-if="!noenddate"
                  v-model="lcollabend"
                  color="deep-orange accent-4"
                ></v-date-picker>
              </v-col>
              <v-col cols="12" sm="12">
                <div
                  v-if="
                    lcollabstatus === 'OPEN' && localpanelstate.ifCorporateState
                  "
                >
                  <v-layout row wrap>
                    <v-flex
                      v-for="(option, item) in collaborationOptions"
                      :key="option"
                      xs4
                    >
                      <v-checkbox
                        v-model="lselected[item]"
                        light
                        :label="option"
                      >
                      </v-checkbox>
                    </v-flex>
                  </v-layout>
                </div>
              </v-col>
            </v-row>
            {{ errormsg }}
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('act/collaboration')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn large outlined class="mx-4" @click="saveCollaborationCheck">{{
            $t('DIALOGS.SAVE')
          }}</v-btn>
          <v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
