<script>
// state
import { panelComputed } from '@state/helpers'

import ParaIntegrationUnit from './para-integrationunit.vue'

export default {
  components: { ParaIntegrationUnit },

  props: {

    datalist: {
      type: Object,
      default: () => {},
    },

  },

  data() {
    return {


    }
  },

  computed: {
    ...panelComputed,


  },

  watch: {},
  methods: {
    setLocalParaContext(data) {
      this.$emit('setLocalParaContext', data)
    },

  },
}
</script>

<template>
  <div :class="$style.paragraph">
    <div v-if="true">
      <ParaIntegrationUnit
        v-for="item in datalist"
        :key="item.index"
        :item="item"
        @setLocalParaContext="setLocalParaContext"
      >
      </ParaIntegrationUnit>
   </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
