<script>
import { dbauthComputed } from '@state/helpers'

import {
  formatDateDialog,
  displayUserName,
  displayUserInfo,
  docroute,
} from '@utils/dialogutils'

import PanelCardTitle from './panel-cardtitle.vue'

export default {
  components: { PanelCardTitle },

  props: {
    panelno: {
      type: Number,
      default: () => 0,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    localpanelstate: {
      type: Object,
      default: () => {},
    },
    doclogs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },

  computed: {
    ...dbauthComputed,
    showDialog: {
      get() {
        return this.dialog
      },
      set() {
        this.$emit('close-dialog')
      },
    },
  },
  methods: {
    // relay to use utility functions.
    formatDateDialog(d) {
      return formatDateDialog(d)
    },
    // get user info
    displayUserName(email) {
      return displayUserName(email, this.dbauthUserInfoCache)
    },
    displayUserInfo(email) {
      return displayUserInfo(email, this.dbauthUserInfoCache)
    },
    displayAction(action) {
      // backward compartible
      if (action.includes(' ')) {
        return action
      }
      if (action.includes('HUB.')) {
        return 'HUB--' + this.$t(action.substring(4))
      }
      return this.$t(action)
    },
    docroute(d) {
      return docroute(d)
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <PanelCardTitle
          :cardtitle="$t('DIALOGS.DOCUMENTLOGS')"
        ></PanelCardTitle>
        <div v-if="!localpanelstate.ifDocLoaded">
          <p>
            No document loaded!
          </p>
        </div>
        <div v-if="localpanelstate.ifDocLoaded && doclogs !== null">
          <v-form>
            <v-container>
              <v-row justify="center">
                <v-simple-table :class="$style.dialogOpenTable" fixed-header>
                  <template>
                    <thead
                      :class="$style.dialogHead"
                      class="sticky-header grey lighten-3"
                      fixed-header
                    >
                      <tr :class="$style.dialogHeader">
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.USER') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogBigCellStatus"
                          >{{ $t('DIALOGS.TIME') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.ACTION') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellStatus"
                          >{{ $t('DIALOGS.DETAIL') }}</th
                        >
                        <th
                          style="font-size:16px"
                          :class="$style.dialogCellWidth"
                          >{{ $t('DIALOGS.CONTEXT') }}</th
                        >
                      </tr>
                    </thead>
                    <tbody :class="$style.dialogBody">
                      <tr
                        v-for="item in doclogs.log"
                        :key="
                          item.uniqid + item.action + item.timestamp.toString()
                        "
                        :class="$style.dialogRow"
                      >
                        <td :class="$style.dialogCellStatus"
                          ><v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">{{
                                displayUserName(item.uniqid)
                              }}</span>
                            </template>
                            <span>{{ displayUserInfo(item.uniqid) }}</span>
                          </v-tooltip></td
                        >
                        <td :class="$style.dialogBigCellStatus">{{
                          formatDateDialog(item.timestamp)
                        }}</td>
                        <td :class="$style.dialogCellStatus">{{
                          displayAction(item.action)
                        }}</td>

                        <td :class="$style.dialogBigCellStatus">{{
                          item.detail
                        }}</td>
                        <td :class="$style.dialogCellWidth">{{
                          item.context
                        }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-container>
          </v-form>
        </div>

        <v-card-actions class="justify-end">
          <v-btn icon @click="docroute('file/documentlog')">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer
          ><v-btn
            large
            outlined
            class="mx-4 grey lighten-2"
            @click="showDialog = false"
            >{{ $t('DIALOGS.BACK') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
</style>
